/* eslint-disable import/no-extraneous-dependencies */
import { TagsOperator } from 'blues-corejs/dist/models/notifications/rule/criteria'
import { create } from 'zustand'

export interface Tag {
  id: string
  tagKey: string
  tagValue: string
}

export const readableTagsOperator = {
  [TagsOperator.AND]: 'AND',
  [TagsOperator.OR]: 'OR',
}

interface AssetTagSelectionActions {
  addTag: (tag: Omit<Tag, 'id'>) => void
  removeTag: (id: string) => void
  setTagsList: (tagsList: Array<Tag>) => void
  setTagOperator: (operator: TagsOperator) => void
  resetStore: () => void
}

export interface AssetTagSelectionState {
  tags: Array<Tag>
  tagsOperator: TagsOperator
}

export type AssetTagSelectionStore = AssetTagSelectionActions &
  AssetTagSelectionState

const INITIAL_STATE: AssetTagSelectionState = {
  tags: [],
  tagsOperator: TagsOperator.AND,
}

const useAssetTagSelectionStore = create<AssetTagSelectionStore>((set) => ({
  ...INITIAL_STATE,
  addTag: (tag) =>
    set((state) => {
      const isDuplicate = state.tags.some(
        (existing) =>
          existing.tagKey === tag.tagKey && existing.tagValue === tag.tagValue
      )
      if (isDuplicate) {
        return state
      }

      return {
        tags: [
          ...state.tags,
          {
            ...tag,
            // @ts-ignore
            id: crypto.randomUUID(),
          },
        ],
      }
    }),
  removeTag: (id) =>
    set((state) => {
      const tagToRemove = state.tags.find((tag) => tag.id === id)
      if (!tagToRemove) {
        return state
      }

      return {
        tags: state.tags.filter((tag) => tag.id !== id),
      }
    }),
  setTagsList: (tagsList) =>
    set(() => ({
      tags: tagsList,
    })),
  setTagOperator: (operator) =>
    set(() => ({
      tagsOperator: operator,
    })),
  resetStore: () => set({ ...INITIAL_STATE }),
}))

export const resetAssetTagSelectionStore = () => {
  useAssetTagSelectionStore.getState().resetStore()
}

export const useAssetTagSelectionState = () =>
  useAssetTagSelectionStore((state) => ({
    tags: state.tags,
    tagsOperator: state.tagsOperator,
  }))

export const useAssetTagSelectionActions = () =>
  useAssetTagSelectionStore((state) => ({
    addTag: state.addTag,
    removeTag: state.removeTag,
    setTagsList: state.setTagsList,
    setTagOperator: state.setTagOperator,
  }))
