/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IconButton, List, ListItem, Typography } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import Button from 'ui-v2/src/components/ui/inputs/button'
import ScanClusterForm from './scan-cluster-form'
import { FormProvider, useForm } from 'react-hook-form'
import { ClusterFormData } from './types'
import { useListVaultCreate } from 'ui-v2/src/hooks/queries/vault/list-vault-create'
import {
  CreateVaultInterface,
  UpdateVaultInterface,
} from 'ui-v2/src/lib/models/vault/vault'
import { useDeploymentStore } from '../../store/deployment-store'
import { useListVaultUpdate } from 'ui-v2/src/hooks/queries/vault/list-vault-update'

const ScanCluster = () => {
  const { action, id } = useParams()
  const { rowData } = useDeploymentStore()
  const isDrawerOpen = action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.SCAN_CLUSTER
  const navigate = useNavigate()
  const { mutate: requestCreateVault, isPending: createVaultPenidng } =
    useListVaultCreate()
  const { mutate: requestUpdateVault, isPending: updateVaultPending } =
    useListVaultUpdate()
  const isLoading = createVaultPenidng || updateVaultPending
  const method = useForm<ClusterFormData>({
    defaultValues: {
      name: '',
      description: '',
      vpcList: [],
      vpcListForApi: [],
      awsRegionId: '',
      vaultId: '',
    },
  })
  const { handleSubmit, reset } = method

  const [isCreate, setIsCreate] = useState(false)
  const modalTitle = isCreate
    ? 'Network Settings Setup'
    : 'Network Settings Update'
  const actionButtonText = isCreate ? 'Create' : 'Update'

  const handleCloseDrawer = () => {
    reset()
    setIsCreate(false)
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
  }

  const submitCreate = (data: ClusterFormData) => {
    let vpcId = ''
    let subnetIds: Array<string> = []
    const region = data.vpcListForApi[0]
    if (region) {
      const vpcList = region?.children
      if (vpcList) {
        vpcId = vpcList[0]?.id ?? ''
        const subnetList = vpcList[0]?.children
        if (subnetList) {
          subnetIds = subnetList.map((subnet) => subnet.id)
        }
      }
    }
    const requestData: CreateVaultInterface = {
      vaultName: data.name,
      accountId: id ?? '',
      safetyLock: false,
      vpcId: vpcId,
      subnetIdsList: subnetIds ?? [],
      redStackId: rowData?.regionWithRedStackId?.get(data.awsRegionId) ?? '',
    }
    requestCreateVault(requestData)
  }

  const submitUpdate = (data: ClusterFormData) => {
    let vpcId = ''
    let subnetIds: Array<string> = []
    const region = data.vpcListForApi[0]
    if (region) {
      const vpcList = region?.children
      if (vpcList) {
        vpcId = vpcList[0]?.id ?? ''
        const subnetList = vpcList[0]?.children
        if (subnetList) {
          subnetIds = subnetList.map((subnet) => subnet.id)
        }
      }
    }
    const requestData: UpdateVaultInterface = {
      safetyLock: false,
      vpcId: vpcId,
      subnetIdsList: subnetIds,
      description: data.description,
      vaultId: data.vaultId,
    }
    requestUpdateVault(requestData)
  }

  const submit = async (data: ClusterFormData) => {
    if (isCreate) {
      submitCreate(data)
    } else {
      submitUpdate(data)
    }
  }

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <DrawerContent>
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(submit)}>
            <List
              sx={{
                paddingX: 0,

                '& .MuiListItem-root': {
                  paddingX: 2.5,
                },
              }}
            >
              <ListItem
                sx={{
                  paddingRight: '70px',
                }}
                secondaryAction={[
                  <Button
                    variant="contained"
                    type="submit"
                    isLoading={isLoading}
                  >
                    {actionButtonText}
                  </Button>,
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      handleCloseDrawer()
                    }}
                  >
                    <Close />
                  </IconButton>,
                ]}
              >
                <Typography fontWeight={700} fontSize={22}>
                  {modalTitle}
                </Typography>
              </ListItem>
              {!isCreate ? (
                <ListItem>
                  <Typography fontWeight={500} fontSize={'14px'}>
                    Modify the subnet settings for your existing cloud
                    connector. This allows you to update the network
                    configuration without redeploying the connector, ensuring
                    compatibility with your AWS environment.
                  </Typography>
                </ListItem>
              ) : null}
            </List>
            {isDrawerOpen && <ScanClusterForm setIsCreate={setIsCreate} />}
          </form>
        </FormProvider>
      </DrawerContent>
    </Drawer>
  )
}

export default ScanCluster
