import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import {
  ASSET_FILTERS_MAP,
  HEALTH_STATE_MAP,
} from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const HealthFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const health = searchParams.get(ASSET_FILTERS_MAP.HEALTH)?.split(',') || []

  const healthOptions = useMemo(() => {
    return Object.entries(HEALTH_STATE_MAP).map(([key, value]) => ({
      label: value,
      value: key.toString(),
    }))
  }, [])

  const handleHealthChange = (
    newhealthList: Array<MultiSelectOption['value']>
  ) => {
    if (newhealthList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.HEALTH)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.HEALTH, newhealthList.join(','))
    }
    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={healthOptions}
      value={health}
      onChange={handleHealthChange}
      label={
        <SelectLabelText
          selectedValueLength={health?.length}
          optionsLength={healthOptions?.length}
          text="Health"
        />
      }
      width={110}
    />
  )
}

export default HealthFilter
