import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'

const WidgetCard = styled(Card)(({ theme }) => ({
  height: '100%',
  padding: '1rem',
  borderRadius: '8px',
  background: theme.palette.background.elevation3,
  boxShadow: 'none',
}))

export default WidgetCard
