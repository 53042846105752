/* eslint-disable import/no-extraneous-dependencies */
import { ThreatListTableRow } from 'ui-v2/src/lib/models/assets/infected-assets'
import dayjs from 'dayjs'
import ThreatAction from './threat-action'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { Asset, EC2 } from 'blues-corejs/dist'
import { Typography } from '@mui/material'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'

export const getThreatColumns = (
  asset: Asset
): Array<Column<ThreatListTableRow>> => [
  {
    key: 'threat',
    header: 'Threat',
    render: (row) => row.threat ?? '-',
  },
  {
    key: 'infectedFiles',
    header: 'Infected Files',
    render: (row) => (
      <LongTextTooltip
        text={row.infectedFiles ?? '-'}
        placement={'bottom-start'}
        maxLength={50}
      >
        <Typography whiteSpace={'normal'} sx={{ wordBreak: 'break-word' }}>
          {row.infectedFiles ?? '-'}
        </Typography>
      </LongTextTooltip>
    ),
    wrap: true,
  },
  ...(asset instanceof EC2
    ? [
        {
          key: 'volume' as keyof ThreatListTableRow,
          header: 'Volume',
          render: (row: ThreatListTableRow) => row.volume ?? '-',
        },
      ]
    : []),

  {
    key: 'detected',
    header: 'Detected',
    render: (row) => <>{dayjs(row.detected).format('hh:mm A, MM/DD/YYYY')}</>,
  },
  {
    key: 'action',
    header: 'Action',
    render: (row) => <ThreatAction row={row} />,
  },
]
