/* eslint-disable import/no-extraneous-dependencies */
import {
  AwsVaultCreate,
  CreateVaultRequest,
  GetVault,
  ListVaults,
  RepairVaultRequest,
  SetDefaultRequest,
  VaultPromiseClient,
} from '../grpc'
import { ClientConfig } from '../models/client'
import VaultModel from '../models/settings/policies/vault.model'
import {
  CreateVaultInterface,
  UpdateVaultInterface,
} from '../models/vault/vault'
import VaultFactory from '../models/vault/vault.factory'
import { GrpcClient } from './grpc'

//
import {
  ListAssetsForPolicyCoverage,
  ListAssetsForPolicyCoverageAttrs,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/index'

import VaultMetricsModel from '../models/vault/vault-metrics.model'

import { AssetsClient } from './assets'
import { LiveAssets } from '../models/assets'
import { EBS, EC2, EFS, S3Bucket } from 'blues-corejs/dist'
import EnumHelper from '../helpers/enum.helper'
import { ListEfsRequest } from '../grpc'
import { PolicyInfoWithSelectedAssets } from '../models/pechkin/pechkin'
import { PechkinClient } from './pechkin'
import {
  Asset,
  AssetKind,
  AssetsForPolicyCoverage,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { SelectedS3AssetComplex } from '../models/settings/policies/asset-s3.model'
import { SelectedEfsAssetComplex } from '../models/settings/policies/asset-efs.model'

import { RedStackModel } from '../models/base-model'
import { RexClient } from './rex'
import {
  RetentionPolicyModel,
  SelectCategory,
} from '../models/settings/retention-policy/retention-policy.model'
import { ValueInterface, VIRow } from '../engine-types'
import { defaultSelectedFilters } from '../constants/filter-names.constant'
import { getSelectedAssetsFiltersRow } from '../helpers/data.helper'
import ObjHelper from '../helpers/obj.helper'
import { RetentionPoliciesClient } from './retention-policy'
import { UpdateVaultRequest } from 'blue-stack-libs/vault-grpc-libs/js/vault/vault_pb'

export class VaultClient extends GrpcClient<VaultPromiseClient> {
  #valueClient: VaultPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#valueClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'VaultClient'
  }

  protected initClient(hostName = ''): VaultPromiseClient {
    return new VaultPromiseClient(hostName, null, null)
  }

  async getVault(id: string): Promise<VaultModel> {
    const request = new GetVault.Request()
    request.setVaultId(id)

    const result = await this.callGrpcService(
      () => this.#valueClient.getVault(request, this.metadata(this.#token)),
      {
        requestName: 'VaultPromiseClient/getVault',
      }
    )

    return VaultFactory.buildFromGrpc(result.toObject())
  }

  async updateVault(data: UpdateVaultInterface): Promise<any> {
    const { vaultId, safetyLock, vpcId, subnetIdsList, description } = data
    const request = new UpdateVaultRequest()
    request.setId(vaultId)
    request.setDescription(description)
    request.setVpcId(vpcId)
    request.setSubnetIdsList(subnetIdsList)
    request.setSafetyLock(safetyLock)
    const result = await this.callGrpcService(
      () => this.#valueClient.updateVault(request, this.metadata(this.#token)),
      {
        requestName: 'VaultPromiseClient/updateVault',
      }
    )
    return result.toObject()
  }

  async createVault(data: CreateVaultInterface): Promise<string> {
    const request = new CreateVaultRequest()
    const params = new AwsVaultCreate()
    params.setRedStackId(data.redStackId)
    params.setVaultName(data.vaultName)
    params.setVpcId(data.vpcId)
    params.setSubnetIdsList(data.subnetIdsList)
    params.setSafetyLock(data.safetyLock)
    request.setAwsVaultCreate(params)

    const result = await this.callGrpcService(
      () => this.#valueClient.createVault(request, this.metadata(this.#token)),
      {
        requestName: 'VaultPromiseClient/createVault',
      }
    )
    return result.toObject().jobId
  }

  async setDefault(data: Pick<VaultModel, 'innerId' | 'name'>): Promise<any> {
    const request = new SetDefaultRequest()
    request.setVaultId(data.innerId)
    const result = await this.callGrpcService(
      () => this.#valueClient.setDefault(request, this.metadata(this.#token)),
      {
        requestName: 'VaultPromiseClient/setDefault',
      }
    )

    return result.toObject()
  }

  async repairVault(
    vaultName: string,
    cloudConnectorId: string
  ): Promise<string> {
    const request = new RepairVaultRequest()
    request.setVaultName(vaultName)
    request.setRedStackId(cloudConnectorId)
    const result = await this.callGrpcService(
      () => this.#valueClient.repairVault(request, this.metadata(this.#token)),
      {
        requestName: 'VaultPromiseClient/repairVault',
      }
    )

    return result.toObject().jobId
  }

  async listVaults(accountId?: string | number): Promise<Array<VaultModel>> {
    const vaultFilters = new ListVaults.Request.Filters()
    if (accountId) {
      vaultFilters.setAccountIdsList([String(accountId)])
    }
    const request = new ListVaults.Request()
    request.setFilters(vaultFilters)
    const result = await this.callGrpcService(
      () => this.#valueClient.listVaults(request, this.metadata(this.#token)),
      {
        requestName: 'VaultPromiseClient/listVaults',
      }
    )

    return result
      .toObject()
      .vaultsList.filter((v) => !!v.redStackId)
      .map(VaultFactory.buildFromGrpc)
  }

  //

  async getVaultMetrics(vaultId: string): Promise<VaultMetricsModel> {
    const vault = await this.getVault(vaultId)

    return VaultFactory.buildVaultMetrics(vault)
  }

  async assetsLiveWithCanBeProtectedInventoryAttrs(): Promise<ListAssetsForPolicyCoverageAttrs> {
    const assetsClient = new AssetsClient({ token: this.#token })
    const liveAssets: LiveAssets = await assetsClient.listLiveAssets()
    const elastioBackups = liveAssets.lastElastioBackupsMap
    return {
      instances: liveAssets.ec2Instances,
      volumes: liveAssets.ebsVolumes,
      s3Buckets: liveAssets.s3Buckets,
      efs: liveAssets.efs,
      lastBackups: elastioBackups,
    }
  }

  async assetsAllWithCanBeProtectedInventoryAttrs(): Promise<{
    allAssets: ListAssetsForPolicyCoverageAttrs
    missingInLiveAssets: Array<string>
  }> {
    const assetsClient = new AssetsClient({ token: this.#token })
    const liveAssets: LiveAssets = await assetsClient.listLiveAssets()
    const liveAssetsList = [
      ...liveAssets.ec2Instances,
      ...liveAssets.ebsVolumes,
      ...liveAssets.s3Buckets,
      ...liveAssets.efs,
    ]

    const elastioBackups = liveAssets.lastElastioBackupsMap
    // FIXME: Do we really need to fetch 1000 assets?
    // all EC2 assets

    const resultListEc2 = await assetsClient.listEc2({ pageSize: 1000 })
    const listEc2: Array<EC2> = await resultListEc2.instancesList

    // all EBS assets
    const resultListEbs = await assetsClient.listEbs({ pageSize: 1000 })
    const listEbs: Array<EBS> = await resultListEbs.volumesList

    // all S3 assets
    const resultListS3 = await assetsClient.listS3({ pageSize: 1000 })
    const listS3: Array<S3Bucket> = await resultListS3.bucketsList
    // all EFS assets
    const resultListEfs = await assetsClient.listEfs({
      filesystemStatesList: EnumHelper.filterToEnumValues(
        [],
        ListEfsRequest.Initial.Filter.FilesystemState
      ),
      pageSize: 1000,
    })
    const listEfs: Array<EFS> = resultListEfs.filesystemsList

    const missingInLiveAssets: Array<string> = []
    const allAssetsList = [...listEc2, ...listEbs, ...listS3, ...listEfs]
    allAssetsList.map((asset) => {
      const foundAsset = liveAssetsList.find(
        (liveAsset) => liveAsset.id === asset.id
      )
      if (!foundAsset) {
        missingInLiveAssets.push(asset.id)
      }
    })

    return {
      allAssets: {
        instances: listEc2,
        volumes: listEbs,
        s3Buckets: listS3,
        efs: listEfs,
        lastBackups: elastioBackups,
      },
      missingInLiveAssets: missingInLiveAssets,
    }
  }

  async getPlanInfoWithSelectedAssets(
    id: string
  ): Promise<PolicyInfoWithSelectedAssets> {
    const pechkinClient = new PechkinClient({ token: this.#token })
    const policyInfo = await pechkinClient.getPlanInfo(id)

    // because filters work in "AND" way between groups
    // and "OR" in one group - for each group we have to do
    // a separate request
    const inventoryAttrs =
      await this.assetsLiveWithCanBeProtectedInventoryAttrs()
    const inventory = new ListAssetsForPolicyCoverage(inventoryAttrs)

    let ec2Assets: AssetsForPolicyCoverage = []
    if (policyInfo.ec2Filters.length > 0) {
      ec2Assets = inventory.execute({ instanceIds: policyInfo.ec2Filters })
    }

    let ebsAssets: AssetsForPolicyCoverage = []
    if (policyInfo.ebsFilters.length > 0) {
      ebsAssets = inventory.execute({ volumeIds: policyInfo.ebsFilters })
    }

    let s3Assets: AssetsForPolicyCoverage = []
    if (policyInfo.s3Filters?.length > 0) {
      s3Assets = inventory.execute({ instanceIds: policyInfo.s3Filters })
    }

    s3Assets?.map((s3: AssetWithRelatedAssets<Asset>) => {
      policyInfo.selectedS3AssetComplex.map(
        (s3SelectedAsset: SelectedS3AssetComplex) => {
          if (s3SelectedAsset.selectedAsset === s3.asset?.awsId) {
            s3SelectedAsset.assetModel = s3
          }
        }
      )
    })

    let efsAssets: AssetsForPolicyCoverage = []
    if (policyInfo.efsFilters?.length > 0) {
      efsAssets = inventory.execute({ instanceIds: policyInfo.efsFilters })
    }

    efsAssets?.map((efs: AssetWithRelatedAssets<Asset>) => {
      policyInfo.selectedEfsAssetComplex.map(
        (efsSelectedAsset: SelectedEfsAssetComplex) => {
          if (efsSelectedAsset.selectedAsset === efs.asset?.awsId) {
            efsSelectedAsset.assetModel = efs
          }
        }
      )
    })

    return {
      policyName: policyInfo.policyName,
      schedule: policyInfo.schedule,
      iscan: policyInfo.iscan,
      policyTags: policyInfo.policyTags,
      snapshotImportVariant: policyInfo.snapshotImport,
      isType: policyInfo.isType,
      integrityCheck: policyInfo.integrityCheck,
      protectNewImmediately: policyInfo.protectNewImmediately,
      vaultsList: policyInfo.vaultsList ?? [],
      skipEbsBackup: policyInfo.skipEbsBackup ?? false,
      accountRegionSelectorsList: policyInfo.accountRegionSelectorsList ?? [],
      // added extra
      selectedAssets: [...ec2Assets, ...ebsAssets],
      selectedS3Assets: s3Assets,
      selectedS3AssetComplex: policyInfo.selectedS3AssetComplex,
      selectedEfsAssets: efsAssets,
      selectedEfsAssetComplex: policyInfo.selectedEfsAssetComplex,
      integrityScanOption: policyInfo.integrityScanOption,
      keepDataCopy: policyInfo.keepDataCopy,
      scanWithTimestamps: policyInfo.scanWithTimestamps,
      isEntropyDetectionEnabled: policyInfo.isEntropyDetectionEnabled,
    }
  }

  async listVaultsForActiveRedStacks(
    accountId?: string | number
  ): Promise<Array<VaultModel>> {
    const rexClient = new RexClient({ token: this.#token })

    const listVaults = await this.listVaults(accountId)
    const listRedStacks = await rexClient.getAllRedstacks()

    const [vaults, redStacks]: [Array<VaultModel>, Array<RedStackModel>] =
      await Promise.all([listVaults, listRedStacks.filter((v) => v.isActive)])
    return vaults.filter((v) =>
      redStacks.some((rs) => rs.redStackId === v.redStackId)
    )
  }

  async listVaultsForAllRedStacks(
    accountId?: string | number
  ): Promise<Array<VaultModel>> {
    const rexClient = new RexClient({ token: this.#token })

    const listVaults = await this.listVaults(accountId)
    const listRedStacks = await rexClient.getAllRedstacks()

    const [vaults, redStacks]: [Array<VaultModel>, Array<RedStackModel>] =
      await Promise.all([listVaults, listRedStacks])
    return vaults.filter((v) =>
      redStacks.some((rs) => rs.redStackId === v.redStackId)
    )
  }

  async getRetentionPolicyWithSelectedAssets(id: string): Promise<{
    retentionPolicy: RetentionPolicyModel
    selectedAssets: Array<AssetWithRelatedAssets<Asset>>
    missingAssets: VIRow
    selectedAssetsFilters: VIRow
  }> {
    const retentionPoliciesClient = new RetentionPoliciesClient({
      token: this.#token,
    })

    const retentionPolicy = await retentionPoliciesClient.getRetentionPolicy(id)
    const vaultsList = await this.listVaultsForAllRedStacks()

    const filters = retentionPolicy.dataForSelectedAssets || []
    const recoveryPointTypes = retentionPolicy.dataForRecoveryPointTypes || []

    const ebsFilters: Array<string> = []
    const ec2Filters: Array<string> = []
    const s3Filters: Array<string> = []
    const genericFilters: Array<string> = []

    filters.map((filter: ValueInterface) => {
      if (filter.type === AssetKind.AWS_EBS) {
        ebsFilters.push(filter.name)
      }
      if (filter.type === AssetKind.AWS_EC2) {
        ec2Filters.push(filter.name)
      }
      if (filter.type === AssetKind.AWS_S3) {
        s3Filters.push(filter.name)
      }
      if (
        filter.type === AssetKind.GENERIC_HOST ||
        filter.type === AssetKind.GENERIC_FS
      ) {
        genericFilters.push(filter.name)
      }
    })

    // because filters work in "AND" way between groups
    // and "OR" in one group - for each group we have to do
    // a separate request
    const inventoryAttrs =
      await this.assetsLiveWithCanBeProtectedInventoryAttrs()
    const inventory = new ListAssetsForPolicyCoverage(inventoryAttrs)

    let ec2Assets: AssetsForPolicyCoverage = []
    if (ec2Filters.length > 0) {
      ec2Assets = inventory.execute({ instanceIds: ec2Filters })
    }

    let ebsAssets: AssetsForPolicyCoverage = []
    if (ebsFilters.length > 0) {
      ebsAssets = inventory.execute({ volumeIds: ebsFilters })
    }

    let s3Assets: AssetsForPolicyCoverage = []
    if (s3Filters?.length > 0) {
      s3Assets = inventory.execute({ instanceIds: s3Filters })
    }

    let genericAssets: AssetsForPolicyCoverage = []
    if (genericFilters.length > 0) {
      genericAssets = inventory.execute({ instanceIds: genericFilters })
    }

    // Find asset names which are selected in Retention policy
    // but already absent in Assets list
    const selectedEc2AssetsNames = ec2Assets.map((asset) => asset.asset.awsId)
    const selectedEbsAssetsNames = ebsAssets.map((asset) => asset.asset.awsId)
    const selectedS3AssetsNames = s3Assets.map((asset) => asset.asset.awsId)

    //TODO check selection of Generic assets (have no awsId)
    const selectedGenericAssetsNames = genericAssets.map(
      (asset) => asset.asset.id
    )

    const missingAssets: VIRow = []

    filters.map((filter: ValueInterface) => {
      if (filter.type === AssetKind.AWS_EC2) {
        if (!selectedEc2AssetsNames.includes(filter.name)) {
          missingAssets.push(filter)
        }
      }
      if (filter.type === AssetKind.AWS_EBS) {
        if (!selectedEbsAssetsNames.includes(filter.name)) {
          missingAssets.push(filter)
        }
      }
      if (filter.type === AssetKind.AWS_S3) {
        if (!selectedS3AssetsNames.includes(filter.name)) {
          missingAssets.push(filter)
        }
      }
      if (
        filter.type === AssetKind.GENERIC_HOST ||
        filter.type === AssetKind.GENERIC_FS
      ) {
        if (!selectedGenericAssetsNames.includes(filter.name)) {
          missingAssets.push(filter)
        }
      }
    })

    const selectedAssets = [
      ...ec2Assets,
      ...ebsAssets,
      ...s3Assets,
      ...genericAssets,
    ]

    // get preselected filters for Assets
    let selectedAssetsFilters: VIRow = defaultSelectedFilters()
    recoveryPointTypes.map((item) => {
      if (
        item.name === SelectCategory.ACCOUNT_IDS ||
        item.name === SelectCategory.REGIONS ||
        item.name === SelectCategory.TAGS
      ) {
        const currentFilter = getSelectedAssetsFiltersRow(
          item.name,
          item.options ?? []
        )
        const currentAllFilters = ObjHelper.cloneDeep(selectedAssetsFilters)
        selectedAssetsFilters = [...currentAllFilters, ...currentFilter]
      } else if (item.name === SelectCategory.VAULTS) {
        const itemOptions = ObjHelper.cloneDeep(item.options)
        itemOptions?.map((option) => {
          const vaultFromList = vaultsList.find(
            (v) =>
              v.name === String(option.value) &&
              v.region === String(option.extraValue) &&
              v.accountId === String(option.defaultValue)
          )
          // get vaultId for using in filters
          if (vaultFromList) {
            option.supplementalValue = vaultFromList.innerId
          }
        })

        const currentFilter = getSelectedAssetsFiltersRow(
          item.name,
          itemOptions ?? []
        )
        const currentAllFilters = ObjHelper.cloneDeep(selectedAssetsFilters)
        selectedAssetsFilters = [...currentAllFilters, ...currentFilter]
      }
    })

    return {
      retentionPolicy,
      selectedAssets,
      missingAssets,
      selectedAssetsFilters,
    }
  }
}
