import { useMemo } from 'react'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { INVENTORY_TABLES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import { useSearchParams } from 'react-router-dom'

export interface FilterComponentProps {
  options: Array<string>
}

const RegionFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsRegionList =
    searchParams.get(INVENTORY_TABLES_FILTERS_MAP.REGION)?.split(',') || []

  const awsRegionOptions = useMemo(() => {
    return options.map((awsRegion) => ({
      value: awsRegion,
      label: LangAwsRegionConstant.get(awsRegion)?.en?.single ?? awsRegion,
    }))
  }, [options])

  const handleAwsRegionChange = (
    newAwsRegionList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsRegionList.length === 0) {
      searchParams.delete(INVENTORY_TABLES_FILTERS_MAP.REGION)
    } else {
      searchParams.set(
        INVENTORY_TABLES_FILTERS_MAP.REGION,
        newAwsRegionList.join(',')
      )
    }

    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsRegionOptions}
      value={awsRegionList}
      onChange={handleAwsRegionChange}
      label="Region"
      width={200}
    />
  )
}

export default RegionFilter
