"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusCase = exports.DeletionFailed = exports.DeletionPending = exports.Linked = exports.InstallFailed = exports.InstallPending = void 0;
class ErrorMessage {
    #message;
    constructor(message) {
        this.#message = message;
    }
    get message() {
        return this.#message;
    }
}
class Pending {
    #jobId;
    constructor(jobId) {
        this.#jobId = jobId;
    }
    get jobId() {
        return this.#jobId;
    }
}
class InstallPending extends Pending {
}
exports.InstallPending = InstallPending;
class InstallFailed extends ErrorMessage {
}
exports.InstallFailed = InstallFailed;
class Linked {
}
exports.Linked = Linked;
class DeletionPending extends Pending {
}
exports.DeletionPending = DeletionPending;
class DeletionFailed extends ErrorMessage {
}
exports.DeletionFailed = DeletionFailed;
var StatusCase;
(function (StatusCase) {
    StatusCase[StatusCase["STATUS_NOT_SET"] = 0] = "STATUS_NOT_SET";
    StatusCase[StatusCase["INSTALL_PENDING"] = 1] = "INSTALL_PENDING";
    StatusCase[StatusCase["INSTALL_FAILED"] = 2] = "INSTALL_FAILED";
    StatusCase[StatusCase["LINKED"] = 3] = "LINKED";
    StatusCase[StatusCase["DELETION_PENDING"] = 4] = "DELETION_PENDING";
    StatusCase[StatusCase["DELETION_FAILED"] = 5] = "DELETION_FAILED";
})(StatusCase || (exports.StatusCase = StatusCase = {}));
