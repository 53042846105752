import { Column } from 'ui-v2/src/components/ui/data-table/base-table'

export interface EventsColumn {
  event: string
  details: string
  label: string
}

export const getEventsListColumns = (): Array<Column<EventsColumn>> => [
  {
    key: 'label',
    header: 'Event',
    cellSx: { paddingLeft: 0 },
  },
]
