/* eslint-disable import/no-cycle */
import { useState } from 'react'
import { Grid2, Typography } from '@mui/material'
import RadarChart, {
  ResilienceScoreColor,
} from '../dashboard-charts/radar-chart'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { RadarChartAxes } from 'ui-v2/src/lib/constants/ui.constant'
import AssetsScanned from './assets-scanned'
import FilesScanned from './files-sanned'
import ResilienceScoreDrawer from 'ui-v2/src/components/drawers/resilience-score'
import DataScanned from './data-scanned'

type RadarChartProps = {
  selectedSection: RadarChartAxes
  setSelectedSection: (section: RadarChartAxes) => void
}

export type ResilienceWeightData = {
  recoveryAssuranceWeight: number
  ransomwareSafetyWeight: number
  assetCoverageWeight: number
  encryptionIntegrityWeight: number
  storageHealthWeight: number
}

export type ResiliencePercentage = {
  assetCoveragePercentage: number
  ransomwareSafetyPercentage: number
  encryptionIntegrityPercentage: number
  recoveryAssurancePercentage: number
  storageHealthPercentage: number
}

const RadarChartCard = ({
  selectedSection,
  setSelectedSection,
}: RadarChartProps) => {
  const [resilienceScore, setResilienceScore] = useState<number>(0)
  const [resilienceScoreColor, setResilienceScoreColor] =
    useState<ResilienceScoreColor>(ResilienceScoreColor.GREEN)
  const [isResilienceScoreDrawerOpen, setIsResilienceScoreDrawerOpen] =
    useState(false)

  const closeResilienceScoreDrawer = () => {
    setIsResilienceScoreDrawerOpen(false)
  }

  const openResilienceScoreDrawer = () => {
    setIsResilienceScoreDrawerOpen(true)
  }

  return (
    <>
      <GradientCard>
        <Typography
          fontSize={32}
          fontWeight={700}
          mb={2}
          color="text.secondary"
        >
          360 Ransomware Risk Score:
          <Typography
            component="span"
            fontSize={32}
            fontWeight={700}
            sx={{
              color:
                resilienceScoreColor === ResilienceScoreColor.RED
                  ? 'rgba(255, 22, 84, 1)'
                  : resilienceScoreColor === ResilienceScoreColor.YELLOW
                    ? '#FFAB2D'
                    : resilienceScoreColor === ResilienceScoreColor.GREEN
                      ? '#45AA5B'
                      : 'text.secondary',
            }}
          >
            {' '}
            {Math.round(resilienceScore)}%
          </Typography>
        </Typography>
        <Grid2 container spacing={2.5}>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 3,
            }}
            sx={{
              display: {
                xs: 'block',
                sm: 'flex',
                md: 'flex',
                lg: 'block',
              },
              gap: {
                sm: 1.5,
                md: 1.5,
                lg: 0,
              },
            }}
          >
            <AssetsScanned />
            <FilesScanned />

            <DataScanned />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 9,
            }}
            justifyContent={'center'}
          >
            <RadarChart
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
              resilienceScore={resilienceScore}
              setResilienceScore={setResilienceScore}
              openResilienceScoreDrawer={openResilienceScoreDrawer}
              setResilienceScoreColor={setResilienceScoreColor}
              resilienceScoreColor={resilienceScoreColor}
            />
          </Grid2>
        </Grid2>
      </GradientCard>
      <ResilienceScoreDrawer
        key={isResilienceScoreDrawerOpen.toString()}
        isResilienceScoreDrawerOpen={isResilienceScoreDrawerOpen}
        closeResilienceScoreDrawer={closeResilienceScoreDrawer}
        resilienceScore={resilienceScore}
      />
    </>
  )
}

export default RadarChartCard
