import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AssetAccountsClient } from 'ui-v2/src/lib/clients/asset-accounts'
import { LinkAssetAccountResponse } from 'blue-stack-libs/rex-grpc-libs/js/rex/messages/asset_account/link_asset_account_pb'

export function useListAssetAccounts() {
  const assetAccountClient = useGrpcClient(AssetAccountsClient)

  return useQuery({
    queryKey: ['asset-accounts', 'list'],
    queryFn: () => {
      if (!assetAccountClient) {
        throw new Error('Asset Accounts Client not initialized')
      }
      return assetAccountClient?.listAssetAccounts()
    },
    enabled: !!assetAccountClient,
  })
}

export function useLinkAssetAccounts() {
  const assetAccountClient = useGrpcClient(AssetAccountsClient)

  return useMutation({
    mutationFn: async ({
      connectorAccountId,
      assetAccountId,
    }: {
      connectorAccountId: string
      assetAccountId: string
    }): Promise<LinkAssetAccountResponse> => {
      if (!assetAccountClient) {
        throw new Error('Asset Accounts Client not initialized')
      }
      return assetAccountClient?.linkAssetAccount(
        connectorAccountId,
        assetAccountId
      )
    },
  })
}
