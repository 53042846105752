/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Box } from '@mui/material'
import CreateRule from './create-rule'
import AlertsSearch from './alerts-search'
import AlertsTable from './alerts-table'
import AlertsAction from './alerts-action'
import DetailRule from './detail-rule'

const Alerts = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <AlertsSearch />
        <AlertsAction />
      </Box>

      <AlertsTable />
      <CreateRule />
      <DetailRule />
    </>
  )
}

export default Alerts
