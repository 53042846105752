/* eslint-disable import/no-extraneous-dependencies */
import { ChangeEvent, useEffect } from 'react'
import {
  Stack,
  Typography,
  FormControl,
  MenuItem,
  Tooltip,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import { Controller, useFormContext } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'
import RegexConstants from 'ui-v2/src/lib/constants/regex.constant'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { VIRow } from 'ui-v2/src/lib/engine-types'
import {
  CloudFormationStackButton,
  ReviewElastioButton,
} from '../aws-account-buttons'
import Box from '@mui/material/Box'
import CircleQuestionIcon from 'ui-v2/src/assets/icons/circle-question-icon'
import NumberedDivider from 'ui-v2/src/components/common/numbered-divider'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import { useTheme } from '@mui/material/styles'

interface AwsAccountProps {
  previousEmittedAccountId: string
  setPreviousEmittedAccountId: React.Dispatch<React.SetStateAction<string>>
  onAccountIdChange: (awsAccountId: string) => void
  onVPCAutoCreateValueChange: (value: boolean) => void
  listSupportedAwsRegionsData: VIRow | undefined
  disabledVpc: boolean
  isFinishedAccountLevelCFL: boolean
  shouldInstallCfn: boolean
  shouldDisableAccountRegion: boolean
  onCloudFormationClickHandler: () => void
  isCloudFormationLaunched: boolean
}

const AwsAccount = ({
  previousEmittedAccountId,
  setPreviousEmittedAccountId,
  onAccountIdChange,
  listSupportedAwsRegionsData,
  disabledVpc,
  isFinishedAccountLevelCFL,
  shouldInstallCfn,
  shouldDisableAccountRegion,
  onCloudFormationClickHandler,
  isCloudFormationLaunched,
  onVPCAutoCreateValueChange,
}: AwsAccountProps) => {
  const { control, setValue, watch, trigger } = useFormContext<SourceFormData>()
  const theme = useTheme()

  const awsAccountId = watch('awsAccountId')
  const awsRegionId = 'us-east-1'
  const vpcAutoCreate = watch('isVpcAutoCreate')

  const isShowAwsAccountButtons = isFinishedAccountLevelCFL && !vpcAutoCreate

  const isShowCloudFormationButtons =
    !shouldDisableAccountRegion &&
    shouldInstallCfn &&
    isShowAwsAccountButtons &&
    !!awsAccountId

  const handleAwsAccountId = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e.target.value
    const filteredValue = inputValue.replace(/[^0-9]/g, '')
    setValue('awsAccountId', filteredValue, { shouldValidate: true })
  }

  const handleAutomaticallyVpcChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    setValue('isVpcAutoCreate', value)
    onVPCAutoCreateValueChange(value)
  }

  const handleVpcRegionChange = (e: SelectChangeEvent<Array<string>>) => {
    const value = e.target.value as Array<string>
    setValue('vpcRegions', value)
  }

  useEffect(() => {
    if (awsAccountId && awsAccountId !== previousEmittedAccountId) {
      trigger('awsAccountId').then((isValid) => {
        if (isValid) {
          onAccountIdChange(awsAccountId)
          setPreviousEmittedAccountId(awsAccountId)
        }
      })
    }
  }, [awsAccountId, previousEmittedAccountId])

  return (
    <Stack maxWidth={700} fontSize={'1rem'}>
      <Typography mb={2} color="text.secondary" variant="body2">
        The Elastio Cloud Connector is deployed in a dedicated AWS account,
        enabling centralized scanning and management of resources across
        multiple protected accounts.
      </Typography>
      <Typography mb={3.25} color="text.secondary" variant="body2">
        On this page, you will:
        <br />✔ Enter the AWS account where the Cloud Connector will be
        deployed.
        <br /> ✔ Select the VPC and regions for deployment.
      </Typography>
      <Typography mb={2} color="text.secondary" variant="body2">
        Proceed to configure your deployment and add ransomware protection
        across your cloud environment.
      </Typography>
      <Grid container columnSpacing={4}>
        <Grid size={12}>
          <NumberedDivider number={1} />
          <Typography variant="body2" mb={1} mt={2} mr={'4px'} fontWeight={600}>
            Enter the AWS Account for the Cloud Connector
            <Tooltip
              title={
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === ThemeMode.DARK
                        ? '#F6F9FC'
                        : '#4e5665',
                    backgroundColor:
                      theme.palette.mode === ThemeMode.DARK
                        ? '#19232F'
                        : '#F6F9FC',
                    whiteSpace: 'pre-line',
                    borderRadius: '6px',
                    fontSize: '14px',
                    padding: '12px',
                    maxWidth: '400px',
                  }}
                >
                  Connector will be deployed. This is the target account where
                  Elastio's protection and scanning capabilities will be set up.
                  <br />
                  <br />
                  By default, the{' '}
                  <strong>CloudFormation deployment region</strong> is set to{' '}
                  <strong>US-East-1</strong>. However, this can be changed when
                  logging into the AWS Console by selecting a different region
                  from the dropdown in the <strong>top right corner</strong>.
                  <br />
                  <br />
                  <span style={{ color: '#007BFF' }}>
                    🔹 <strong>Important Note:</strong>
                  </span>{' '}
                  The CloudFormation region does <strong>not</strong> have to
                  match the region where the Cloud Connector is installed. It
                  simply determines where the CloudFormation stack deploys the
                  necessary permissions. You can select{' '}
                  <strong>any region</strong> for this step.
                </Typography>
              }
              placement={'bottom'}
              slotProps={{
                tooltip: {
                  sx: {
                    maxWidth: '400px',
                    backgroundColor:
                      theme.palette.mode === ThemeMode.DARK
                        ? '#19232F'
                        : '#F6F9FC',
                    border: 'none',
                    boxShadow: 'none',
                    padding: 0,
                  },
                },
              }}
            >
              <Typography ml={'4px'} component="span">
                <CircleQuestionIcon />
              </Typography>
            </Tooltip>
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid size={3}>
              <FormControl>
                <Controller
                  control={control}
                  rules={{
                    required: 'AWS account ID is required',
                    pattern: {
                      value: RegexConstants.ACCOUNT_ID,
                      message: 'Account id must contain 12 numbers',
                    },
                  }}
                  name="awsAccountId"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      size="small"
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                      onChange={handleAwsAccountId}
                      slotProps={{ htmlInput: { maxLength: 12 } }}
                      fullWidth
                      placeholder="AWS Account"
                      sx={{
                        '& .MuiInputBase-input': {
                          fontSize: '14px',
                          height: '23px',
                        },
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {awsRegionId &&
          awsAccountId.length === 12 &&
          !shouldDisableAccountRegion &&
          shouldInstallCfn && (
            <>
              <Grid size={12}>
                <NumberedDivider
                  number={2}
                  sx={{
                    marginTop: '16px',
                    marginBottom: '16px',
                  }}
                />
                <Typography variant="body2" fontWeight={600} mb={1}>
                  Select Auto-Create VPC (Recommended)
                  <Tooltip
                    title={
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === ThemeMode.DARK
                              ? '#F6F9FC'
                              : '#4e5665',
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? '#19232F'
                              : '#F6F9FC',
                          whiteSpace: 'pre-line',
                          borderRadius: '6px',
                          fontSize: '14px',
                          padding: '12px',
                          maxWidth: '400px',
                        }}
                      >
                        Enabling the <strong>AutoCreate VPC</strong> option
                        allows Elastio to automatically create a
                        <strong> new VPC</strong> with a{' '}
                        <strong>NAT gateway</strong> and{' '}
                        <strong>private subnets</strong> to support the
                        deployment of the Elastio Cloud Connector.
                        <br />
                        <br />
                        🔹 <strong>Important Note:</strong> This will{' '}
                        <strong>incur AWS charges</strong> for the NAT gateway.
                        If you prefer to use an existing VPC, leave this option{' '}
                        <strong>disabled</strong> and manually select the VPC's
                        in the wizard.
                      </Typography>
                    }
                    placement={'bottom'}
                    slotProps={{
                      tooltip: {
                        sx: {
                          maxWidth: '400px',
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? '#19232F'
                              : '#F6F9FC',
                          border: 'none',
                          boxShadow: 'none',
                          padding: 0,
                        },
                      },
                    }}
                  >
                    <Typography ml={'4px'} component="span">
                      <CircleQuestionIcon />
                    </Typography>
                  </Tooltip>
                </Typography>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid size={5}>
                    <FormControl>
                      <Controller
                        control={control}
                        name="isVpcAutoCreate"
                        disabled={shouldDisableAccountRegion}
                        render={({ field }) => {
                          return (
                            <Stack direction="row" alignItems="center">
                              <Checkbox
                                checked={field.value}
                                size="small"
                                disabled={disabledVpc}
                                onChange={handleAutomaticallyVpcChange}
                              />
                              <Typography variant="body1">
                                Automatically Create VPCs
                              </Typography>
                            </Stack>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {!isShowCloudFormationButtons && (
                <Grid size={12}>
                  <NumberedDivider
                    number={3}
                    sx={{
                      marginTop: '16px',
                      marginBottom: '16px',
                    }}
                  />
                  <Typography variant="body2" fontWeight={600} mb={1}>
                    Select Regions To Deploy The Cloud Connector Into{' '}
                    <Tooltip
                      title={
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === ThemeMode.DARK
                                ? '#F6F9FC'
                                : '#4e5665',
                            backgroundColor:
                              theme.palette.mode === ThemeMode.DARK
                                ? '#19232F'
                                : '#F6F9FC',
                            whiteSpace: 'pre-line',
                            borderRadius: '6px',
                            fontSize: '14px',
                            padding: '12px',
                            maxWidth: '400px',
                          }}
                        >
                          Choose the AWS regions where you want to deploy the
                          Elastio Cloud Connector. The Cloud Connector will be
                          installed in the selected regions to scan and protect
                          storage and backups in your protected accounts.
                        </Typography>
                      }
                      placement={'bottom'}
                      slotProps={{
                        tooltip: {
                          sx: {
                            maxWidth: '400px',
                            backgroundColor:
                              theme.palette.mode === ThemeMode.DARK
                                ? '#19232F'
                                : '#F6F9FC',
                            border: 'none',
                            boxShadow: 'none',
                            padding: 0,
                          },
                        },
                      }}
                    >
                      <Typography ml={'4px'} component="span">
                        <CircleQuestionIcon />
                      </Typography>
                    </Tooltip>
                  </Typography>
                  <Grid size={3}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="vpcRegions"
                        render={({ field }) => (
                          <Select
                            value={field.value}
                            onChange={handleVpcRegionChange}
                            size="small"
                            disabled={!vpcAutoCreate}
                            fullWidth
                            multiple
                            renderValue={(selected) => {
                              if (!selected) {
                                return 'Select Regions'
                              }
                              return selected
                            }}
                          >
                            {listSupportedAwsRegionsData?.map((region) => (
                              <MenuItem value={region.name}>
                                {region.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        {isShowCloudFormationButtons && (
          <Grid size={12}>
            <NumberedDivider
              number={3}
              sx={{
                marginTop: '16px',
                marginBottom: '16px',
              }}
            />
            <Box mb={2}>
              <CloudFormationStackButton
                onCloudFormationClickHandler={onCloudFormationClickHandler}
                isCloudFormationLaunched={isCloudFormationLaunched}
              />
            </Box>
            <Typography
              variant="body1"
              fontWeight={500}
              mb={2}
              fontSize={'0.875rem'}
            >
              For reference, the CloudFormation template is available here for
              your inspection.
            </Typography>
            <Box>
              <ReviewElastioButton />
            </Box>
          </Grid>
        )}
      </Grid>
    </Stack>
  )
}

export default AwsAccount
