import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Stack, styled, Typography } from '@mui/material'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { formatDate } from 'ui-v2/src/lib/helpers/time.helper'
import { AccessTime } from '@mui/icons-material'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import { ASSET_DETAILS_ACTIVE_TAB } from '../constants'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import { ScanJobData } from 'ui-v2/src/lib/models/backups'
import { formatTimeAgo, getKeyItemText, getStatus } from '../../utils'
import { getScanJobSource } from '../common/util'

type ScanListItemProps = {
  scanJob: ScanJobData
  defaultItemId: string | undefined
}

const ListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean; isKeyType?: boolean }>(
  ({ theme, isActive, isKeyType }) => ({
    borderBottom: `1px solid ${theme.palette.border.dark}`,
    padding: '0.5rem',
    backgroundColor: isActive
      ? theme.palette.background.highlight
      : theme.palette.background.elevation1,
    cursor: 'pointer',
    position: 'relative',
    width: '280px',
    minHeight: isKeyType ? '87px' : '40px',

    '&:hover': {
      backgroundColor: theme.palette.background.highlight,
    },

    '&::before': isActive
      ? {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          backgroundColor: theme.palette.primary.main,
        }
      : {},
  })
)

const ScanListItem: React.FC<ScanListItemProps> = ({
  scanJob,
  defaultItemId,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {
    assetKind,
    assetId,
    recoveryPointId,
    activeTab = ASSET_DETAILS_ACTIVE_TAB.scanLog,
  } = useParams<{
    assetKind: string
    assetId: string
    activeTab: string
    recoveryPointId?: string
  }>()

  const isActive = recoveryPointId
    ? recoveryPointId === scanJob.job_id
    : scanJob.job_id === defaultItemId

  const handleClick = () => {
    const pathParts = [assetKind, 'details', assetId, activeTab, scanJob.job_id]
      .filter(Boolean)
      .join('/')

    navigate(
      {
        pathname: `/${BASE_ROUTE_SEGMENTS.ASSETS}/${pathParts}`,
        search: searchParams ? `?${searchParams.toString()}` : '',
      },
      { replace: true }
    )
  }

  if (!recoveryPointId && scanJob.job_id === defaultItemId) {
    handleClick()
  }

  const ListItemProps = {
    isActive,
    onClick: handleClick,
    isKeyType: Boolean(scanJob?.key?.length),
  }

  if (scanJob?.key?.length) {
    return (
      <ListItem {...ListItemProps}>
        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          <AccessTime />
          <Stack gap={0.5}>
            <Box display={'flex'} alignItems={'center'} gap={0.5}>
              <StatusChip type={getStatus(scanJob.health)} />
              <Typography
                variant="body2"
                fontWeight={600}
                sx={(theme) => ({
                  color:
                    theme.palette.mode === ThemeMode.DARK
                      ? '#F6F9FC'
                      : '#4E5665',
                  fontSize: '14px',
                })}
              >
                {getKeyItemText(scanJob.key)}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={0.5}>
              <Typography variant="body2" fontWeight={600} fontSize={'14px'}>
                {formatTimeAgo(scanJob.timestamp)} |
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formatDate(scanJob.timestamp, 'hh:mm A, MM/DD/YY')}
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
              {getScanJobSource(scanJob.type, scanJob.backupType)}
            </Typography>
          </Stack>
        </Stack>
      </ListItem>
    )
  }
  return (
    <ListItem {...ListItemProps}>
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <Typography variant="body1" fontWeight={600}>
          {formatDate(scanJob.timestamp, 'hh:mm A, MM/DD/YYYY')}
        </Typography>
        <StatusChip type={getStatus(scanJob.health)} />
      </Box>
    </ListItem>
  )
}

export default ScanListItem
