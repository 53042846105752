import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import { TextField, Select } from '@mui/material'

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 20,
  height: 10,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 8,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(9px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        ...theme.applyStyles('dark', {}),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 6,
    height: 6,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 8 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}))

export const InputField = styled(TextField)(() => ({
  height: '32px',
  '& .MuiInputBase-root': {
    height: '32px',
    fontSize: '14px',
  },
  '& .MuiInputBase-input': {
    paddingLeft: '10px',
    '&::placeholder': {
      fontStyle: 'italic',
    },
  },
}))

export const SelectStyle = styled(Select)(() => ({
  height: '32px',
  '& .MuiInputBase-root': {
    height: '32px',
    fontSize: '14px',
    fontStyle: 'italic',
  },
  '& .MuiInputBase-input': {
    paddingLeft: '10px',
  },
}))

export const buttonStyle = {
  textTransform: 'none',
  height: 32,
  fontSize: 14,
  padding: '5px 16px',
}

export const MenuItemsStyle = {
  bgcolor: 'background.paper',
  fontSize: '14px',
  '&.Mui-selected': {
    backgroundColor: '#0076AC',
    '&:hover': {
      backgroundColor: '#0076AC',
    },
  },
  '&:hover': {
    backgroundColor: '#0076AC',
  },
}
