import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import { ThemeMode } from '../../common/theme-switcher'

export interface MenuInterface {
  name: string | React.ReactNode
  action: () => void
}

interface ActionMenuInterface {
  menuItems: Array<MenuInterface>
}

const ActionMenu = ({ menuItems }: ActionMenuInterface) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleItemClick = (action: () => void) => {
    action()
    setAnchorEl(null)
  }

  return (
    <Box sx={{ textAlign: 'right' }}>
      <IconButton
        onClick={handleClick}
        sx={() => ({
          background:
            theme.palette.mode === ThemeMode.DARK ? '#4E5665' : '#727883',
          width: '24px',
          height: '24px',
        })}
      >
        <MoreVertIcon
          sx={{
            fontSize: '16px',
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            padding: 0,
            minWidth: 203,
          },
        }}
      >
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <MenuItem
              onClick={() => handleItemClick(item.action)}
              sx={{
                fontSize: '14px',
                padding: '5px 11px',
              }}
            >
              {item.name}
            </MenuItem>
            {index !== menuItems.length - 1 && (
              <Divider
                sx={{
                  height: '2px',
                  margin: '0!important',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Menu>
    </Box>
  )
}

export default ActionMenu
