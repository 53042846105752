import React from 'react'
import { Checkbox as MuiCheckbox, CheckboxProps, styled } from '@mui/material'

const StyledCheckbox = styled(MuiCheckbox)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'rgba(204, 204, 220, 0.2)' : '#85888c',
  '&.Mui-checked': {
    color: '#1AA8EA',
  },
}))

const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  (props: CheckboxProps, ref) => {
    return <StyledCheckbox {...props} ref={ref} />
  }
)

export default Checkbox
