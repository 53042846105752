import React from 'react'
import { SyntheticEvent, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import AssetsEc2 from './ec2'
import AssetsEbs from './ebs'
import AssetsS3 from './s3'
import AssetsEfs from './efs'
import AssetsOvaServer from './ova-server'
import {
  ASSETS_ROUTE_SEGMENTS,
  BASE_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { useDispatch } from 'react-redux'
import { clearAssetDetailsSourcePath } from 'ui-v2/src/store/features/navigation/asset-details-source-path-slice'
import AwsLogoLightTheme from 'ui-v2/src/assets/images/aws-logo-light-theme'
import AwsLogoDarkTheme from 'ui-v2/src/assets/images/aws-logo-dark-theme'
import { Divider, useTheme } from '@mui/material'
import VMWareReportsIcon from 'ui-v2/src/assets/images/vmware-reports'
import { NEW_ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { getDefaultQueryParams } from './filters/config'

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  slug: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, slug, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== slug}
      id={`tabpanel-${slug}`}
      aria-labelledby={`tab-${slug}`}
      {...other}
    >
      {value === slug && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

const TAB_MAP = {
  ec2: {
    label: 'EC2',
    component: <AssetsEc2 />,
    slug: ASSETS_ROUTE_SEGMENTS.EC2,
  },
  ebs: {
    label: 'EBS',
    component: <AssetsEbs />,
    slug: ASSETS_ROUTE_SEGMENTS.EBS,
  },
  s3: {
    label: 'S3',
    component: <AssetsS3 />,
    slug: ASSETS_ROUTE_SEGMENTS.S3,
  },
  efs: {
    label: 'EFS',
    component: <AssetsEfs />,
    slug: ASSETS_ROUTE_SEGMENTS.EFS,
  },
  servers: {
    label: 'Servers',
    component: <AssetsOvaServer />,
    slug: ASSETS_ROUTE_SEGMENTS.SERVER,
  },
}

export default function NewAssets() {
  const navigate = useNavigate()
  const { assetKind: tab } = useParams<{
    assetKind: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
  }>()

  const theme = useTheme()

  const dispatch = useDispatch()

  // TODO: remove this after we migrate to the new assets page completely for the drilldowns
  useEffect(() => {
    dispatch(clearAssetDetailsSourcePath())
  }, [])

  useEffect(() => {
    if (!tab || !Object.values(TAB_MAP).some((t) => t.slug === tab)) {
      navigate(
        {
          pathname: `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EC2}`,
          search: getDefaultQueryParams(ASSETS_ROUTE_SEGMENTS.EC2).toString(),
        },
        { replace: true }
      )
      return
    }

    if (!location.search) {
      const defaultQueryParams = getDefaultQueryParams(tab)
      if (defaultQueryParams) {
        navigate(
          {
            pathname: `/${BASE_ROUTE_SEGMENTS.ASSETS}/${tab}`,
            search: defaultQueryParams.toString(),
          },
          {
            replace: true,
          }
        )
      }
    }
  }, [tab])

  if (!tab || !Object.values(TAB_MAP).some((t) => t.slug === tab)) {
    return null
  }

  const handleChange = (
    _: SyntheticEvent,
    newSlug: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
  ) => {
    const currentParams = new URLSearchParams(location.search)
    // stores params we want to carry over to the new tab
    const filteredParams = new URLSearchParams(getDefaultQueryParams(newSlug))

    const paramsToKeep = [
      NEW_ASSET_FILTERS_MAP.HEALTH,
      NEW_ASSET_FILTERS_MAP.LAST_SCAN,
      NEW_ASSET_FILTERS_MAP.POLICY_COVERAGE,
    ]

    paramsToKeep.forEach((param) => {
      if (currentParams.has(param)) {
        currentParams.getAll(param).forEach((value) => {
          filteredParams.append(param, value)
        })
      }
    })

    navigate({
      pathname: `/${BASE_ROUTE_SEGMENTS.ASSETS}/${newSlug}`,
      search: filteredParams.toString(),
    })
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '4px',
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="asset tabs"
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              paddingLeft: 2,
              paddingRight: 2,

              '& .MuiTab-root.Mui-selected': {
                color: theme.palette.text.primary,
              },

              '& .MuiTab-root': {
                marginRight: '2rem',
              },
            }}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              width={'24px'}
              marginRight={1}
            >
              {theme.palette.mode === 'light' ? (
                <AwsLogoLightTheme />
              ) : (
                <AwsLogoDarkTheme />
              )}
            </Box>

            {Object.values(TAB_MAP)
              .slice(0, 4)
              .map((tabItem) => (
                <Tab
                  key={tabItem.slug}
                  value={tabItem.slug}
                  label={tabItem.label}
                  sx={{ py: 0 }}
                  {...a11yProps(tabItem.slug)}
                />
              ))}
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              gap={1.25}
              marginRight={'14px'}
            >
              <Divider
                orientation="vertical"
                sx={{
                  height: 28,
                  alignSelf: 'center',
                }}
              />
              <Box width={'38px'} display={'flex'}>
                <VMWareReportsIcon />
              </Box>
            </Box>
            <Tab
              value={TAB_MAP.servers.slug}
              label={TAB_MAP.servers.label}
              sx={{ py: 0 }}
              {...a11yProps(TAB_MAP.servers.slug)}
            />
          </Tabs>
        </Box>
        {Object.entries(TAB_MAP).map(([key, value]) => (
          <CustomTabPanel key={key} value={tab} slug={value.slug}>
            {value.component}
          </CustomTabPanel>
        ))}
      </Box>
    </>
  )
}
