/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import { Card, CardContent, Stack, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { mapBackendFormatCodesToUI } from 'ui-v2/src/lib/helpers/reports/map-backend-format-codes-to-ui'
import Grid from '@mui/material/Grid2'
import {
  ovaBackupProviderOptions,
  reportLabelMapper,
  reportPeriodOptions,
} from 'ui-v2/src/lib/constants/reports.constant'
import { ReportPeriod } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/report_schedule/period_pb'
import dayjs from 'dayjs'
import { getScheduleDescription } from 'ui-v2/src/lib/helpers/reports/get-schedule-description'
import { useListUsersQuery } from 'ui-v2/src/hooks/queries/users'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerLabelValue,
  DrawerSubHeader,
} from 'ui-v2/src/components/ui/drawer'
import { useListOvaAccountsQuery } from 'ui-v2/src/hooks/queries/ova-accounts'
import { useReportScheduleListQuery } from 'ui-v2/src/hooks/queries/reports/report-schedule-list'
import {
  OvaBackupProvider,
  Schedule,
  UserProfile,
} from 'blues-corejs/dist/models/index'
import { OvaAccount } from 'blues-corejs/dist'
import { extractAllReportTypesAndParams } from 'ui-v2/src/lib/helpers/reports/extract-all-report-types-and-params'

const getReportPeriod = (period: ReportPeriod): string | undefined => {
  const periodKey = period ? Object.keys(period)[0] : ''

  return reportPeriodOptions.find((pItem: any) => pItem.value === periodKey)
    ?.label
}
type ReportType = keyof typeof reportLabelMapper

interface ScheduleReport {
  name: string
  reportType: string | undefined
  schedule: Schedule | undefined
  formatsList: Array<string>
  lastReportGenerationCompletedAt: Date
  nextReportGenerationAt: Date
  timezone: string
  description: string
  reportPeriod: string | undefined
  matchedRecipients: Array<UserProfile> | undefined
  matchedOvaAccounts: Array<OvaAccount> | undefined
  matchedBackupProviders: Array<{
    label: string
    value: OvaBackupProvider
  }>
}

const RreportDetailsDrawer = () => {
  const navigate = useNavigate()
  const { drawer, kindSlug, reportId } = useParams()
  const { data: scheduleList, isLoading: isScheduleListLoading } =
    useReportScheduleListQuery()
  const { data: recipients } = useListUsersQuery()
  const { data: ovaAccounts } = useListOvaAccountsQuery()

  const [scheduleReport, setScheduleReport] = useState<ScheduleReport>()

  const handleCloseReportDrawer = () => {
    navigate('/reports/schedules')
  }

  useEffect(() => {
    if (
      reportId &&
      scheduleList?.listReportSchedules &&
      scheduleList?.listReportSchedules?.length > 0
    ) {
      const foundScheduleReport = scheduleList?.listReportSchedules?.find(
        (schedule) => schedule.id === reportId
      )
      if (foundScheduleReport) {
        const name = foundScheduleReport?.name

        const { reportType, reportParams } = extractAllReportTypesAndParams(
          foundScheduleReport?.reportKindWithParams as any
        )

        const formatsList = mapBackendFormatCodesToUI(
          reportType as string,
          reportParams?.formatsList
        )

        const lastReportGenerationCompletedAt =
          foundScheduleReport?.lastReportGenerationCompletedAt
        const nextReportGenerationAt =
          foundScheduleReport?.nextReportGenerationAt
        const timezone = foundScheduleReport?.timezone
        const description = foundScheduleReport?.description

        const reportPeriod = getReportPeriod(reportParams?.period)

        const matchedRecipients = recipients?.userProfilesList?.filter((user) =>
          foundScheduleReport?.recipientIds.includes(user.id)
        )

        const matchedOvaAccounts = ovaAccounts?.filter((account) =>
          reportParams?.ovaAccountIdsList?.includes(account.id)
        )
        const matchedBackupProviders = ovaBackupProviderOptions.filter(
          (provider) =>
            reportParams?.ovaBackupProvidersList?.includes(provider.value)
        )

        setScheduleReport({
          name,
          reportType,
          schedule: foundScheduleReport?.schedule,
          formatsList,
          lastReportGenerationCompletedAt,
          nextReportGenerationAt,
          timezone,
          description,
          reportPeriod,
          matchedRecipients,
          matchedOvaAccounts: matchedOvaAccounts,
          matchedBackupProviders,
        })
      }
    }
  }, [reportId, scheduleList, recipients, ovaAccounts])
  return (
    <Drawer
      open={drawer === 'details' && !!kindSlug}
      onClose={handleCloseReportDrawer}
    >
      <DrawerHeader
        title={scheduleReport?.name ?? ''}
        handleCloseDrawer={handleCloseReportDrawer}
      />

      <DrawerContent
        isLoading={isScheduleListLoading}
        isEmpty={!scheduleReport}
      >
        <Stack
          spacing={3}
          direction="column"
          flexWrap={'wrap'}
          paddingX={2.5}
          paddingBottom={2.5}
        >
          <DrawerSubHeader>
            {reportLabelMapper?.[scheduleReport?.reportType as ReportType]}
          </DrawerSubHeader>
          <Grid container spacing={0}>
            <Grid spacing={12}>
              {scheduleReport?.description ? (
                <DrawerLabelValue
                  label="Description"
                  value={scheduleReport?.description}
                  labelWidth={0}
                />
              ) : null}
              {scheduleReport?.schedule ? (
                <DrawerLabelValue
                  label="Frequency"
                  value={getScheduleDescription(scheduleReport.schedule)}
                  labelWidth={0}
                />
              ) : null}

              <DrawerLabelValue
                label="Started"
                value={dayjs()
                  .set('date', scheduleReport?.schedule?.startsOn.day ?? 0)
                  .set('month', scheduleReport?.schedule?.startsOn.month ?? 0)
                  .set('year', scheduleReport?.schedule?.startsOn.year ?? 0)
                  .format('MM/DD/YYYY')}
                labelWidth={0}
              />
              {scheduleReport?.formatsList ? (
                <DrawerLabelValue
                  label="Format"
                  value={scheduleReport?.formatsList?.join(', ')}
                  labelWidth={0}
                />
              ) : null}
              {scheduleReport?.timezone ? (
                <DrawerLabelValue
                  label="Timezone"
                  value={scheduleReport?.timezone}
                  labelWidth={0}
                />
              ) : null}
              {scheduleReport?.lastReportGenerationCompletedAt ? (
                <DrawerLabelValue
                  label="Last Generated"
                  value={dayjs(
                    scheduleReport?.lastReportGenerationCompletedAt
                  ).format('hh:mm A, MM/DD/YYYY')}
                  labelWidth={0}
                />
              ) : null}
              {scheduleReport?.nextReportGenerationAt ? (
                <DrawerLabelValue
                  label="Next report due on"
                  value={dayjs(scheduleReport?.nextReportGenerationAt).format(
                    'hh:mm A, MM/DD/YYYY'
                  )}
                  labelWidth={0}
                />
              ) : null}
              {scheduleReport?.reportPeriod ? (
                <DrawerLabelValue
                  label="Report Period"
                  value={scheduleReport?.reportPeriod}
                  labelWidth={0}
                />
              ) : null}
            </Grid>
          </Grid>

          {scheduleReport?.matchedOvaAccounts &&
          scheduleReport?.matchedOvaAccounts?.length > 0 ? (
            <Grid spacing={12}>
              <Grid size={12}>
                <DrawerLabelValue label="Accounts" value={''} labelWidth={0} />
                <Card variant="outlined">
                  <CardContent>
                    {scheduleReport?.matchedOvaAccounts?.map((account) => (
                      <Typography key={account.id}>{account.name}</Typography>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : null}

          {scheduleReport?.matchedBackupProviders &&
          scheduleReport?.matchedBackupProviders?.length > 0 ? (
            <Grid spacing={12}>
              <Grid size={12}>
                <DrawerLabelValue
                  label="Backup Providers"
                  value={''}
                  labelWidth={0}
                />
                <Card variant="outlined">
                  <CardContent>
                    {scheduleReport?.matchedBackupProviders?.map((provider) => (
                      <Typography key={provider.value}>
                        {provider.label}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : null}

          {scheduleReport?.matchedRecipients &&
          scheduleReport?.matchedRecipients?.length > 0 ? (
            <Grid spacing={12}>
              <Grid size={12}>
                <DrawerLabelValue
                  label="Recipients"
                  value={''}
                  labelWidth={0}
                />
                <Card variant="outlined">
                  <CardContent>
                    {scheduleReport?.matchedRecipients?.map((recipient) => (
                      <Typography key={recipient.id}>
                        {recipient.email}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : null}
        </Stack>
      </DrawerContent>
    </Drawer>
  )
}

export default RreportDetailsDrawer
