import { useInfiniteQuery } from '@tanstack/react-query'
import { RecoveryPointsParams } from 'ui-v2/src/lib/models/backups'
import { useRecoveryPointsService } from '../../rest-service'

export function useListRecoveryPointsInfiniteQuery(
  assetId: string | undefined,
  params: RecoveryPointsParams
) {
  const {
    listRecoveryPoints,
    isReady,
    isLoading: isClientLoading,
  } = useRecoveryPointsService()

  return useInfiniteQuery({
    queryKey: ['assets', assetId, 'recovery-points', params],
    queryFn: async ({ pageParam }: { pageParam: string | undefined }) => {
      if (!assetId) {
        throw new Error('assetId is required')
      }

      const queryParams = pageParam
        ? {
            ...params,
            pageToken: pageParam,
          }
        : params

      return listRecoveryPoints(assetId, queryParams)
    },
    getNextPageParam: (lastPage) =>
      lastPage.pagination.next_page_token || undefined,
    initialPageParam: undefined,
    enabled: Boolean(assetId) && isReady && !isClientLoading,
  })
}
