import { useEffect, useMemo, useState } from 'react'
import { Box, Checkbox, Stack, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Controller, useFormContext } from 'react-hook-form'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import FormControl from '@mui/material/FormControl'
import { ModifyCloudConnectorForm } from './types'
import DrawerLoader from '../drawer-loader'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import { useListAwsVpcArr } from 'ui-v2/src/hooks/queries/red-stack/list-aws-vpc-arr'
import { useParams } from 'react-router-dom'
import { RegionOption, transformVpcListToNestedOptions } from '../../../utils'
import {
  NestedOption,
  NestedSelect,
} from 'ui-v2/src/components/ui/inputs/nested-select'
import { useDeploymentStore } from '../../store/deployment-store'
import { useGetConnectorAccountInstallFlow } from 'ui-v2/src/hooks/queries/red-stack'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import CircleQuestionIcon from 'ui-v2/src/assets/icons/circle-question-icon'
import SingleLevelSelect from './single-level-region-select'

export const ModifyCloudConnectorModal = () => {
  const { id } = useParams()
  const rexClient = useGrpcClient(RexClient)
  const { rowData } = useDeploymentStore()
  const { control, setValue } = useFormContext<ModifyCloudConnectorForm>()
  const [nestedOptions, setNestedOptions] = useState<Array<RegionOption>>([])
  const { mutate: requestVpcListArr, isPending: requestVpcListArrIsPending } =
    useListAwsVpcArr()
  const {
    data: connectorAccountInstallFlowData,
    isLoading: isConnectorAccountInstallFlowDataLoading,
  } = useGetConnectorAccountInstallFlow(id)
  const vpcPermissionsEnabled =
    connectorAccountInstallFlowData?.flow?.vpcPermissionsEnabled

  const isLoadingData =
    requestVpcListArrIsPending || isConnectorAccountInstallFlowDataLoading

  const getVpcListArr = () => {
    if (!id) {
      return
    }

    requestVpcListArr(id, {
      onSuccess: (vpcList) => {
        const transformedVpcList = transformVpcListToNestedOptions(vpcList)
        setNestedOptions([])
        setNestedOptions(transformedVpcList)
        const selectedRegions = rowData?.awsRegions || []
        const selectedSubnets: Array<string> = []
        vpcList.forEach((vpc) => {
          const { hasPublicSubnets, region, subnetsList } = vpc
          if (hasPublicSubnets && selectedRegions.includes(region.name)) {
            const subnetIds = subnetsList.map((subnet) => subnet.id)
            selectedSubnets.push(...subnetIds)
          }
        })

        setValue('vpcList', selectedSubnets)
        setValue(
          'vpcListForApi',
          transformedVpcList.filter((region) =>
            selectedRegions.includes(region.id)
          )
        )
      },
      onError: (error) => {
        console.log(error.message)
      },
    })
  }

  useEffect(() => {
    if (!rexClient || nestedOptions.length > 0) {
      return
    }
    getVpcListArr()
  }, [id, rexClient])

  const handleVpcSelect = (selectedData: Array<NestedOption>) => {
    setValue('vpcListForApi', selectedData)
  }

  const options = useMemo(() => {
    if (vpcPermissionsEnabled) {
      return nestedOptions.map((option) => ({
        ...option,
        children: undefined,
      }))
    }

    return nestedOptions
  }, [nestedOptions, vpcPermissionsEnabled])

  if (isLoadingData) {
    return <DrawerLoader />
  }

  return (
    <Box
      sx={{
        paddingX: 2.5,
        paddingBottom: 2.5,
      }}
    >
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid size={12}>
          <Typography variant="body2" mb={1} fontWeight={600}>
            Alias name
          </Typography>
          <FormControl fullWidth>
            <Controller
              control={control}
              name="aliasName"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  slotProps={{ htmlInput: { maxLength: 12 } }}
                  fullWidth
                  placeholder="Alias name"
                  rows="4"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '14px',
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '14px',
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid size={12}>
          <Typography variant="body2" mb={1} fontWeight={600}>
            Description
          </Typography>
          <FormControl fullWidth>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  slotProps={{ htmlInput: { maxLength: 12 } }}
                  fullWidth
                  placeholder="Description"
                  rows={4}
                  multiline
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '14px',
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '14px',
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {vpcPermissionsEnabled ? (
          <Grid size={12}>
            <Typography variant="body2" fontWeight={600} mb={1}>
              Select Auto-Create VPC (Recommended)
              <Tooltip
                title={
                  <Typography
                    sx={(theme) => ({
                      color:
                        theme.palette.mode === ThemeMode.DARK
                          ? '#F6F9FC'
                          : '#4e5665',
                      backgroundColor:
                        theme.palette.mode === ThemeMode.DARK
                          ? '#19232F'
                          : '#F6F9FC',
                      whiteSpace: 'pre-line',
                      borderRadius: '6px',
                      fontSize: '14px',
                      padding: '12px',
                      maxWidth: '400px',
                    })}
                  >
                    Enabling the <strong>AutoCreate VPC</strong> option allows
                    Elastio to automatically create a<strong> new VPC</strong>{' '}
                    with a <strong>NAT gateway</strong> and{' '}
                    <strong>private subnets</strong> to support the deployment
                    of the Elastio Cloud Connector.
                    <br />
                    <br />
                    🔹 <strong>Important Note:</strong> This will{' '}
                    <strong>incur AWS charges</strong> for the NAT gateway. If
                    you prefer to use an existing VPC, leave this option{' '}
                    <strong>disabled</strong> and manually select the VPC's in
                    the wizard.
                  </Typography>
                }
                placement={'bottom'}
                slotProps={{
                  tooltip: {
                    sx: (theme) => ({
                      maxWidth: '400px',
                      backgroundColor:
                        theme.palette.mode === ThemeMode.DARK
                          ? '#19232F'
                          : '#F6F9FC',
                      border: 'none',
                      boxShadow: 'none',
                      padding: 0,
                    }),
                  },
                }}
              >
                <Typography ml={'4px'} component="span">
                  <CircleQuestionIcon />
                </Typography>
              </Tooltip>
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid size={12}>
                <Stack direction="row" alignItems="center">
                  <Checkbox checked={true} size="small" disabled={true} />
                  <Typography variant="body1">
                    This deployment has auto-VPC enabled
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <Grid size={12}>
          <Typography variant="body2" fontWeight={600} mb={1}>
            Add New Regions
          </Typography>
          {vpcPermissionsEnabled ? (
            <SingleLevelSelect
              options={options}
              placeholder="Select a region"
              onSelect={handleVpcSelect}
            />
          ) : (
            <NestedSelect
              onSelect={handleVpcSelect}
              name={'vpcList'}
              options={options || []}
              isLoading={false}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ModifyCloudConnectorModal
