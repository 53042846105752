import { useEffect } from 'react'
import Grid from '@mui/material/Grid2'
import { FormControl, Stack, Typography } from '@mui/material'

import { Controller, useFormContext } from 'react-hook-form'
import {
  FrequencyType,
  RepeatPeriod,
  ReportForm,
  ReportPeriodValue,
} from 'ui-v2/src/lib/models/reports'

import Format from '../format'
import Frequency, { generateScheduleOptions } from '../frequency'
import dayjs from 'dayjs'
import RangeData from '../range-data'
import { getFrequencyType } from 'ui-v2/src/lib/helpers/reports/get-frequency-type'

import Recipients from '../recipients'

import { filterReportPeriodOptions } from 'ui-v2/src/lib/constants/reports.constant'
import { useParams } from 'react-router-dom'
import DatepickerInput from 'ui-v2/src/components/ui/inputs/datepicker-input'
import TimeZoneSelector from 'ui-v2/src/components/ui/inputs/time-zone-select'

const AwsForm = ({
  availableFormats,
  formattedValue,
  isCustomSchedule,
  handleOpenCustomScheduleModal,
}: AwsFormProps) => {
  const { reportId } = useParams()
  const { control, watch, setValue, trigger } = useFormContext<ReportForm>()
  const frequency = watch('frequency')
  const scheduleStartDate = watch('scheduleStartDate')
  const frequencyType = getFrequencyType(frequency?.toString())
  const customSchedule = watch('customSchedule')
  const filteredReportPeriodOptions = filterReportPeriodOptions(
    frequencyType,
    customSchedule?.periodValue || RepeatPeriod.DAY
  )

  useEffect(() => {
    if (scheduleStartDate) {
      if (frequencyType) {
        const frequnceyOptions = generateScheduleOptions(
          scheduleStartDate?.toISOString()
        )
        const frequnceyRange = frequnceyOptions.find((freq) =>
          freq.value.startsWith(frequencyType)
        )
        if (frequnceyRange) {
          setValue('frequency', frequnceyRange.value)
        }
      } else {
        const frequnceyRange = generateScheduleOptions(
          scheduleStartDate?.toISOString()
        )[0]
        setTimeout(() => {
          setValue('frequency', frequnceyRange?.value ?? '')
        }, 0)
      }
    }
  }, [scheduleStartDate])

  return (
    <Grid container spacing={2}>
      <Grid
        size={{
          xs: 12,
          sm: 4,
          md: 4,
        }}
      >
        <FormControl fullWidth size="small">
          <Typography variant="body1" mb={1}>
            Frequency
          </Typography>
          <Controller
            control={control}
            name="frequency"
            render={({ field }) => (
              <Frequency
                value={field.value ?? ''}
                onChange={(value) => {
                  field.onChange(value)

                  if (value === FrequencyType.CUSTOM) {
                    handleOpenCustomScheduleModal()
                  } else {
                    setValue('customSchedule', null)
                  }

                  const freqType = getFrequencyType(value?.toString())

                  const filteredOptions = filterReportPeriodOptions(freqType)

                  setValue(
                    'rangeOfData',
                    filteredOptions[0]?.value ?? ReportPeriodValue.CURRENT_DAY
                  )
                }}
                scheduleStartDate={scheduleStartDate}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 8,
          md: 8,
        }}
      >
        <Stack direction={'row'} spacing={2}>
          <FormControl fullWidth>
            <Typography variant="body1" mb={1}>
              Start
            </Typography>
            <Controller
              control={control}
              rules={{
                validate: (value) => {
                  if (!value) {
                    return 'Date  is required'
                  }

                  if (value && !dayjs(value).isValid()) {
                    return 'Date  is invalid'
                  }

                  if (!reportId && dayjs(value).isBefore(dayjs(), 'day')) {
                    return 'Date cannot be earlier than current date'
                  }

                  return true
                },
              }}
              name="scheduleStartDate"
              render={({ field, fieldState }) => (
                <DatepickerInput
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      field.onChange(value)
                    }
                    trigger('scheduleStartDate')
                  }}
                  disablePast={true}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography variant="body1" mb={1}>
              Range of Data
            </Typography>
            <Controller
              control={control}
              name="rangeOfData"
              render={({ field }) => (
                <RangeData
                  value={field.value ?? ''}
                  reportPeriodOptions={filteredReportPeriodOptions}
                  onChange={(value) => {
                    field.onChange(value)
                  }}
                />
              )}
            />
          </FormControl>
        </Stack>
      </Grid>
      {!!formattedValue && isCustomSchedule && (
        <Grid size={12}>
          <Typography
            variant="body2"
            className="custom-schedule-format"
            onClick={handleOpenCustomScheduleModal}
          >
            {formattedValue}
          </Typography>
        </Grid>
      )}
      <Grid
        size={{
          xs: 12,
          sm: 10,
          md: 9,
          lg: 8,
        }}
      >
        <FormControl fullWidth>
          <Typography variant="body1" mb={1}>
            Time zone:
          </Typography>
          <Controller
            control={control}
            name="timezone"
            render={({ field }) => (
              <TimeZoneSelector
                value={field.value}
                onChange={(tz) => {
                  field.onChange(tz.value)
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 10,
          md: 9,
          lg: 8,
        }}
      >
        <FormControl fullWidth size="small">
          <Typography variant="body1" mb={1}>
            Recipients
          </Typography>
          <Controller
            control={control}
            rules={{
              validate: (value) => {
                if (!value || value.length === 0) {
                  return 'Please select at least one recipient'
                }
                return true
              },
            }}
            name="recipients"
            render={({ field, fieldState }) => (
              <Recipients
                value={field.value}
                onChange={(options) => {
                  field.onChange(options)
                  trigger('recipients')
                }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid size={12}>
        <FormControl>
          <Typography variant="body1" mb={1}>
            Format:
          </Typography>
          <Controller
            control={control}
            rules={{
              validate: (value) => {
                if (!value || value.length === 0) {
                  return 'Please select at least one format'
                }
                return true
              },
            }}
            name="format"
            render={({ field, fieldState }) => (
              <Format
                options={availableFormats.map((format) => ({
                  label: format,
                  value: format,
                }))}
                selectedOptions={field.value}
                onChange={(selected) => {
                  field.onChange(selected)
                  trigger('format')
                }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
interface AwsFormProps {
  availableFormats: Array<string>
  formattedValue: string
  isCustomSchedule: boolean
  handleOpenCustomScheduleModal: () => void
}
export default AwsForm
