// import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const VolumeHealthFilter = () => {
  // const [searchParams, setSearchParams] = useSearchParams()

  const handleScanTypeChange = () => {}

  return (
    <FilterMultiSelect
      options={[
        {
          label: 'At Risk',
          value: 'At Risk',
        },
        {
          label: 'Clean',
          value: 'Clean',
        },
        {
          label: 'Infected',
          value: 'Infected',
        },
      ]}
      value={[]}
      onChange={handleScanTypeChange}
      label={
        <SelectLabelText
          selectedValueLength={0}
          optionsLength={1}
          text="Volume Health"
        />
      }
      width={169}
      size="xxs"
    />
  )
}

export default VolumeHealthFilter
