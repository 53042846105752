import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Typography,
} from '@mui/material'
import Button from 'ui-v2/src/components/ui/inputs/button'
import DatepickerInput from 'ui-v2/src/components/ui/inputs/datepicker-input'
import dayjs from 'dayjs'
import { SelectChangeEvent } from '@mui/material/Select'
import { buttonStyle, InputField, MenuItemsStyle, SelectStyle } from './styles'
import { ALERTS_KINDS_MAP } from '../utils'

interface RuleInformationProps {
  name: string
  setName: (v: string) => void
  description: string
  setDescription: (v: string) => void
  selectedEvents: Array<string>
  setSelectedEvents: (v: Array<string>) => void
  eventKinds?: Array<string>
  suppressUntil: dayjs.Dayjs
  setSuppressUntil: (v: dayjs.Dayjs) => void
  isSuppress: boolean
  setSuppress: (v: boolean) => void
  suppressReason: string
  setSuppressReason: (v: string) => void
}

const RuleInformation = ({
  name,
  setName,
  description,
  setDescription,
  selectedEvents,
  setSelectedEvents,
  eventKinds,
  suppressUntil,
  setSuppressUntil,
  isSuppress,
  setSuppress,
  suppressReason,
  setSuppressReason,
}: RuleInformationProps) => {
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const ruleNameInputRef = useRef<HTMLInputElement | null>(null)

  const filteredEventKinds = eventKinds?.filter((event: string) =>
    event.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const alertsKindsMapLength = Object.keys(ALERTS_KINDS_MAP).length

  const handleEventChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as Array<string>
    if (value.length === 1 && value[0] === undefined) {
      return
    }
    setSelectedEvents(value)
  }

  const handleSelectAll = () => {
    if (selectedEvents.length === alertsKindsMapLength) {
      setSelectedEvents([])
    } else {
      setSelectedEvents(Object.keys(ALERTS_KINDS_MAP) ?? [])
    }
  }

  const handleClearAll = () => {
    setSelectedEvents([])
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSuppress = (v: boolean) => {
    setSuppress(v)
    if (!v) {
      setSuppressReason('')
      setSuppressUntil(dayjs())
    }
  }

  const renderSelectValue = (selected: unknown) => {
    const selectedValues = selected as Array<string>
    return selectedValues.map((event) => ALERTS_KINDS_MAP[event]).join(', ')
  }

  useEffect(() => {
    if (ruleNameInputRef.current) {
      ruleNameInputRef.current.focus()
    }
  }, [])

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          mb: '6px',
          fontSize: '16px',
        }}
      >
        Define Name, Description & Rule Mode
      </Typography>

      <Typography
        variant="caption"
        color="textSecondary"
        sx={{ fontSize: '14px' }}
      >
        (Optionally, Pause the rule for a set period of time)
      </Typography>

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2">
          Rule Name
          <Typography component={'span'} color="error" pl={0.25}>
            *
          </Typography>
        </Typography>
        <InputField
          required
          inputRef={ruleNameInputRef}
          fullWidth
          placeholder="Rule Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={name === ''}
          size="small"
          sx={{
            mb: 2,
          }}
        />

        <Typography variant="subtitle2">Description (Optional)</Typography>
        <InputField
          fullWidth
          placeholder="Rule Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          size="small"
        />

        <Typography
          variant="subtitle2"
          sx={{
            mt: 2,
          }}
        >
          Select Events
          <Typography component={'span'} color="error" pl={0.25}>
            *
          </Typography>
        </Typography>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <SelectStyle
            multiple
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            size="small"
            value={selectedEvents}
            renderValue={renderSelectValue}
            onChange={handleEventChange}
            sx={{ mb: 1 }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 400,
                },
              },
              MenuListProps: {
                style: {
                  padding: 0,
                  background: 'background.paper',
                },
              },
            }}
          >
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                bgcolor: 'background.paper',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  p: '5px 8px ',
                  background: 'background.paper',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1.5,
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {selectedEvents.length} Selected
                  </Typography>
                  <Button
                    size="small"
                    onClick={handleSelectAll}
                    variant="contained"
                    color="inherit"
                    sx={buttonStyle}
                  >
                    All
                  </Button>
                  <Button
                    size="small"
                    onClick={handleClearAll}
                    variant="contained"
                    color="inherit"
                    sx={buttonStyle}
                  >
                    Clear
                  </Button>
                  <Box />
                  <Button
                    size="small"
                    onClick={handleClose}
                    variant="contained"
                    sx={{
                      ...buttonStyle,
                      ml: 'auto',
                    }}
                  >
                    Done
                  </Button>
                </Box>
                <InputField
                  size="small"
                  fullWidth
                  placeholder="Search events..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{ mb: 1 }}
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </Box>
            </Box>
            {filteredEventKinds?.map((event: string) => {
              if (ALERTS_KINDS_MAP[event]) {
                return (
                  <MenuItem key={event} value={event} sx={MenuItemsStyle}>
                    {ALERTS_KINDS_MAP[event]}
                  </MenuItem>
                )
              }
            })}
          </SelectStyle>
        </FormControl>

        <Typography
          variant="subtitle2"
          sx={{
            mb: 1,
            mt: 1,
          }}
        >
          Pause Rule (Optional)
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isSuppress}
                onChange={(e) => {
                  handleSuppress(e.target.checked)
                }}
              />
            }
            componentsProps={{
              typography: {
                fontSize: '14px',
              },
            }}
            label="Suppress until"
          />

          <DatepickerInput
            value={suppressUntil}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                setSuppressUntil(value)
              }
            }}
            disablePast={true}
            disabled={!isSuppress}
            maxDate={dayjs().add(30, 'day')}
            sx={{
              height: '40px',
              '& .MuiInputBase-root': {
                height: '40px',
                fontSize: '14px',
                width: '185px',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '24px',
              },
            }}
          />
          <Typography
            sx={{
              fontSize: '14px',
            }}
          >
            (Up to 30 days from now)
          </Typography>
        </Box>

        <InputField
          fullWidth
          placeholder="Reason for suppression (Required)"
          size="small"
          value={suppressReason}
          onChange={(e) => setSuppressReason(e.target.value)}
          error={isSuppress && suppressReason === ''}
          disabled={!isSuppress}
        />
      </Box>
    </>
  )
}

export default RuleInformation
