import React from 'react'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import ThreatsGraphWidget from './threat-graph'
import AwsAccountDetailsWidget from './aws-account-details'
import ScanDetailsWidget from './scan-details'
import PolicyDetailsWidget from './policy-details'
import LastCleanBackupWidget from './last-clean-backup-details'
import { BoxProps } from '@mui/material'
import BackupVendorWidget from './backup-vendor'

export const WIDGETS = {
  TREND_GRAPH: 'trend-graph',
  ACCOUNT_DETAILS: 'account-details',
  POLICY_DETAILS: 'policy-details',
  SCAN_DETAILS: 'scan-details',
  LAST_CLEAN_BACKUP: 'last-clean-backup',
  BACKUP_VENDOR: 'backup-vendor',
} as const

type AssetType =
  (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
type WidgetType = (typeof WIDGETS)[keyof typeof WIDGETS]

export const widgetComponents: Record<WidgetType, React.FC> = {
  [WIDGETS.TREND_GRAPH]: ThreatsGraphWidget,
  [WIDGETS.ACCOUNT_DETAILS]: AwsAccountDetailsWidget,
  [WIDGETS.POLICY_DETAILS]: PolicyDetailsWidget,
  [WIDGETS.SCAN_DETAILS]: ScanDetailsWidget,
  [WIDGETS.LAST_CLEAN_BACKUP]: LastCleanBackupWidget,
  [WIDGETS.BACKUP_VENDOR]: BackupVendorWidget,
}

type WidgetConfig = BoxProps & {
  key: WidgetType
}

const commonWidgets = (widgets: Array<WidgetConfig>): Array<WidgetConfig> => [
  {
    key: WIDGETS.TREND_GRAPH,
    width: '323px',
    flexShrink: 0,
  },
  ...widgets,
  {
    key: WIDGETS.SCAN_DETAILS,
    width: '209px',
    flexShrink: 0,
  },
  {
    key: WIDGETS.LAST_CLEAN_BACKUP,
    width: '322px',
    flexShrink: 0,
  },
]

const cloudSpecificWidgets = [
  {
    key: WIDGETS.ACCOUNT_DETAILS,
    width: '209px',
    flexShrink: 0,
  },
  {
    key: WIDGETS.POLICY_DETAILS,
    width: '209px',
    flexShrink: 0,
  },
]

const cloudAssetLayout: Array<WidgetConfig> =
  commonWidgets(cloudSpecificWidgets)

const serverSpecificWidgets: Array<WidgetConfig> = [
  {
    key: WIDGETS.BACKUP_VENDOR,
    width: '209px',
    flexShrink: 0,
  },
]

const serverLayout: Array<WidgetConfig> = commonWidgets(serverSpecificWidgets)

export const assetWidgetConfig: Record<AssetType, Array<WidgetConfig>> = {
  [ASSETS_ROUTE_SEGMENTS.EC2]: cloudAssetLayout,
  [ASSETS_ROUTE_SEGMENTS.EBS]: cloudAssetLayout,
  [ASSETS_ROUTE_SEGMENTS.S3]: cloudAssetLayout,
  [ASSETS_ROUTE_SEGMENTS.EFS]: cloudAssetLayout,
  [ASSETS_ROUTE_SEGMENTS.SERVER]: serverLayout,
  [ASSETS_ROUTE_SEGMENTS.GENERIC_HOST]: serverLayout,
}
