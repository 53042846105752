/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import {
  listEbsInventoryPossibleFilters,
  ListInventoryVolumes,
} from 'blues-corejs/dist'
import { useInventoryTablesFilters } from 'ui-v2/src/hooks/filters/inventory-tables'
import { useSearchParams } from 'react-router-dom'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import DataHelper from 'ui-v2/src/lib/helpers/data.helper'
import { initialPrioritySortForEBSTable } from './priority-sort'
import { getInventoryEbsColumns } from './column-config'
import { Stack } from '@mui/material'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import InventoryTablesFilters from '../filters'
import {
  INVENTORY_TABLES_FILTERS_MAP,
  INVENTORY_VIEW_PARAM,
  INVENTORY_VIEW_PARAM_VALUES,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'

const EbsInventory = () => {
  const filters = useInventoryTablesFilters()

  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)
  const view = searchParams.get(INVENTORY_VIEW_PARAM)

  const { data: assetsLiveData, isLoading } = useAssetsLiveQuery()

  const { possibleEbsFilters } = useMemo(() => {
    if (!assetsLiveData) {
      return { possibleEbsFilters: {} }
    }

    return new listEbsInventoryPossibleFilters({
      EbsList: assetsLiveData.ebsVolumes,
    }).execute()
  }, [assetsLiveData])

  const scans = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }

    return (
      assetsLiveData.scans.flatMap(
        (scan) =>
          scan.ransomwareScan ??
          scan.malwareScan ??
          scan.filesystemCheckScan ??
          []
      ) ?? []
    )
  }, [assetsLiveData])

  const volumes = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }

    const { volumes: inventoryVolumes } = new ListInventoryVolumes({
      instances: assetsLiveData.ec2Instances,
      volumes: assetsLiveData.ebsVolumes,
      firstBackups: assetsLiveData.firstBackups.backups,
      lastBackups: assetsLiveData.lastBackups.backups,
      lastEbsSnapshots: assetsLiveData.lastEbsSnapshots,
      threats: assetsLiveData.threats,
      policies: [],
      lastScans: scans,
    }).execute(DataHelper.getDataForDashboardFilter(filters, search ?? ''))

    if (view === INVENTORY_VIEW_PARAM_VALUES.CLEAN) {
      return inventoryVolumes.filter((volume) => !volume.isUnhealthy)
    }

    if (view === INVENTORY_VIEW_PARAM_VALUES.RPO) {
      return inventoryVolumes.filter((volume) => volume.rpo)
    }

    return inventoryVolumes
  }, [assetsLiveData, JSON.stringify(filters), search, view, scans])

  const sortedVolumes = useMemo(() => {
    return initialPrioritySortForEBSTable(
      volumes,
      view === INVENTORY_VIEW_PARAM_VALUES.RPO
    )
  }, [volumes, view])

  const columns = useMemo(getInventoryEbsColumns, [])

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems={'center'}>
        <SearchBar />
      </Stack>

      <InventoryTablesFilters
        filters={[
          INVENTORY_TABLES_FILTERS_MAP.ACCOUNT_ID,
          INVENTORY_TABLES_FILTERS_MAP.REGION,
          INVENTORY_TABLES_FILTERS_MAP.POLICY,
        ]}
        filterValues={{
          [INVENTORY_TABLES_FILTERS_MAP.REGION]: possibleEbsFilters.regions,
          [INVENTORY_TABLES_FILTERS_MAP.ACCOUNT_ID]:
            possibleEbsFilters.accountIds,
          [INVENTORY_TABLES_FILTERS_MAP.POLICY]:
            possibleEbsFilters.covered?.map((option) => option.toString()),
        }}
      />
      <ClientTable
        data={sortedVolumes}
        columns={columns}
        isLoading={isLoading}
      />
    </Stack>
  )
}

export default EbsInventory
