import { ENCRYPTION_ACTIVITY_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import RegionFilter from './region'
import AccountIdFilter from './account-id'
import PolicyCoverageFilter from './policy-coverage'

interface FilterComponentProps {
  options: Array<string>
}

const createFilterOption = (
  Component: React.ComponentType<FilterComponentProps>
) => {
  return Component
}

export const filterOptions = {
  [ENCRYPTION_ACTIVITY_FILTERS_MAP.ACCOUNT_ID]:
    createFilterOption(AccountIdFilter),
  [ENCRYPTION_ACTIVITY_FILTERS_MAP.REGION]: createFilterOption(RegionFilter),
  [ENCRYPTION_ACTIVITY_FILTERS_MAP.POLICY]:
    createFilterOption(PolicyCoverageFilter),
}
