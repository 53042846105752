import { useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import { useListAssetFilterQuery } from 'ui-v2/src/hooks/queries/assets/filters-list'

const AssetNameFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const instanceNameList =
    searchParams.get(ASSET_FILTERS_MAP.ASSET_NAME)?.split(',') || []

  const { assetKind } = useParams<{ assetKind: string | undefined }>()
  const { data: filterData } = useListAssetFilterQuery({ asset: assetKind })

  const assetNameOptions = useMemo(() => {
    if (!filterData || !filterData.data.data.name) {
      return []
    }

    return filterData.data.data.name.map((instance) => ({
      label: instance.name,
      value: instance.name,
    }))
  }, [filterData])

  const handleInstanceIdChange = (
    newVolumeNameList: Array<MultiSelectOption['value']>
  ) => {
    if (newVolumeNameList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.ASSET_NAME)
    } else {
      searchParams.set(
        ASSET_FILTERS_MAP.ASSET_NAME,
        newVolumeNameList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={assetNameOptions}
      value={instanceNameList}
      onChange={handleInstanceIdChange}
      label={
        <SelectLabelText
          selectedValueLength={instanceNameList?.length}
          optionsLength={assetNameOptions?.length}
          text="Name"
        />
      }
      width={113}
    />
  )
}

export default AssetNameFilter
