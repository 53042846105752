import React from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import RestoreForensic from '../common/restore-forensic'
import DownloadReport from '../common/download-report'
import { RecoveryPointBackupByIdResponse } from 'ui-v2/src/lib/models/backups'
import { getScannedStatus } from '../common/util'
import BackupSource from '../common/backup-source'
import ScanStatus from '../files-table/scan-status'

type RecoveryPointFilesTableHeaderProps = {
  isHealthy: boolean
  atRisk: boolean
  backup: RecoveryPointBackupByIdResponse
}

const RecoveryPointFilesTableHeader: React.FC<
  RecoveryPointFilesTableHeaderProps
> = ({ isHealthy, atRisk, backup }) => {
  const theme = useTheme()

  const scanStatus = getScannedStatus(isHealthy, atRisk, {
    fs_check_status: backup.fs_check_status,
    malware_check_status: backup.malware_check_status,
    ransomware_check_status: backup.ransomware_check_status,
  })

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.secondary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingX: '0',
        color: 'white',
      }}
    >
      <Stack
        spacing={1}
        sx={{ backgroundColor: theme.palette.background.secondary }}
      >
        <BackupSource
          source={backup.backupType}
          backupKind={backup.kind}
          timestamp={backup.timestamp}
        />

        <ScanStatus status={scanStatus} isHealthy={isHealthy} atRisk={atRisk} />
      </Stack>
      <Stack alignItems={'flex-end'} spacing={2}>
        <RestoreForensic />
        <DownloadReport />
      </Stack>
    </Box>
  )
}

export default RecoveryPointFilesTableHeader
