/* eslint-disable import/no-extraneous-dependencies */
import { InventoryVolumeOutput } from 'blues-corejs/dist/use_cases/list_inventory_volumes/types'

/**
 * Sorts to last scan first, last backup second key, prioritize last scan.
 * If both have no value, sort by volume name followed by ID.
 */
export function initialPrioritySortForEBSTable(
  volumes: Array<InventoryVolumeOutput>,
  sortByRPO = false
) {
  return volumes.sort((a, b) => {
    if (sortByRPO) {
      const aRPO = a.rpo || Number.MAX_SAFE_INTEGER
      const bRPO = b.rpo || Number.MAX_SAFE_INTEGER
      return aRPO - bRPO
    }

    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const aLastBackupTime = a.lastBackup?.timestamp.getTime() || 0
    const bLastBackupTime = b.lastBackup?.timestamp.getTime() || 0

    if (aLastBackupTime !== bLastBackupTime) {
      return bLastBackupTime - aLastBackupTime
    }

    const volumeAName = a.volumeName || a.volumeId
    const volumeBName = b.volumeName || b.volumeId

    return volumeAName.localeCompare(volumeBName)
  })
}
