import {
  NotificationEvent,
  Origin,
  Severity,
  Trigger,
} from '../../models/events'
import { Event } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/events_pb'
import TimeHelper from '@lib/helpers/time.helper'

export const eventListTransformer = (
  eventList: Array<Event.AsObject>
): Array<NotificationEvent> => {
  return eventList.map(
    (event) =>
      new NotificationEvent({
        origin: event.origin as Origin,
        kind: event.kind,
        labelsMap: event.labelsMap,
        severity: event.severity as Severity,
        description: event.description,
        eventTime: TimeHelper.secondsToDate(event?.eventTime?.seconds),
        trigger: event.trigger as Trigger,
      })
  )
}
