/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from './grpc'
import {
  NotificationsPromiseClient,
  ListEventKindsRequest,
  ListEventsRequest,
} from '../grpc'
import { ClientConfig } from '../models/client'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { eventListTransformer } from '../transformers/events'
import { NotificationEvent } from '../models/events'

const NOTIFICATIONS_CLIENT_NAME = 'NotificationsClient'

function createTimestamp(seconds: number) {
  const timestamp = new Timestamp()
  timestamp.setSeconds(seconds)
  return timestamp
}

export class EventsClient extends GrpcClient<NotificationsPromiseClient> {
  #notificationsClient: NotificationsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#notificationsClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return NOTIFICATIONS_CLIENT_NAME
  }

  protected initClient(hostName = ''): NotificationsPromiseClient {
    return new NotificationsPromiseClient(hostName, null, null)
  }

  async getListEventKinds(): Promise<Array<string>> {
    const request = new ListEventKindsRequest()

    const response = (
      await this.callGrpcService(
        () =>
          this.#notificationsClient.listEventKinds(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: `${NOTIFICATIONS_CLIENT_NAME}/ListEventKinds`,
        }
      )
    ).getEventKindsList()

    return response
  }

  async getListEvents({
    eventKinds,
    token,
    start,
    end,
  }: {
    eventKinds: Array<string>
    token: string
    start: Date
    end: Date
  }): Promise<{
    eventList: Array<NotificationEvent>
    prevPageToken: string
    nextPageToken: string
  }> {
    const startTime = createTimestamp(
      Number((new Date(start).getTime() / 1000).toFixed(0))
    )
    const endTime = createTimestamp(
      Number((new Date(end).getTime() / 1000).toFixed(0))
    )

    const request = new ListEventsRequest()
      .setPageToken(token)
      .setPageSize(15)
      .setKindsList(eventKinds)
      .setStartTime(startTime)
      .setEndTime(endTime)

    const response = (
      await this.callGrpcService(
        () =>
          this.#notificationsClient.listEvents(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: `${NOTIFICATIONS_CLIENT_NAME}/getNotificationRulesList`,
        }
      )
    ).toObject()

    return {
      eventList: eventListTransformer(response.eventsList),
      prevPageToken: response.prevPageToken,
      nextPageToken: response.nextPageToken,
    }
  }
}
