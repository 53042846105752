import { SyntheticEvent, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { MONITOR_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

import CloudConnector from './cloud-connector'
import Deployment from './deployment'
import System from './system'
import { useUserConfigQuery } from '../../hooks/queries/users'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { setUserConfig } from '../../store/features/user/user-slice'

const defaultFilters = {
  [MONITOR_ROUTE_SEGMENTS.CLOUD_CONNECTOR]: {},
  [MONITOR_ROUTE_SEGMENTS.DEPLOYMENT]: {},
  [MONITOR_ROUTE_SEGMENTS.SYSTEM]: {},
}

function getDefaultQueryParams(
  tabSlug: (typeof MONITOR_ROUTE_SEGMENTS)[keyof typeof MONITOR_ROUTE_SEGMENTS]
): string {
  const tabDefaultFilters = defaultFilters[tabSlug]
  if (!tabDefaultFilters) {
    return ''
  }

  const queryParams = new URLSearchParams()

  Object.entries(tabDefaultFilters).forEach(([filterKey, filterValues]) => {
    if (Array.isArray(filterValues)) {
      queryParams.set(filterKey, filterValues.join(','))
    }
  })

  const queryString = queryParams.toString()
  return queryString ? `?${queryString}` : ''
}

function tabProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

type TabKey = 'cloud-connector' | 'deployment' | 'system'

interface TabItem {
  label: string
  component: JSX.Element
  slug: string
}

const TAB_MAP: Record<TabKey, TabItem> = {
  'cloud-connector': {
    label: 'Scans',
    component: <CloudConnector />,
    slug: MONITOR_ROUTE_SEGMENTS.CLOUD_CONNECTOR,
  },
  deployment: {
    label: 'Deployment',
    component: <Deployment />,
    slug: MONITOR_ROUTE_SEGMENTS.DEPLOYMENT,
  },
  system: {
    label: 'System',
    component: <System />,
    slug: MONITOR_ROUTE_SEGMENTS.SYSTEM,
  },
}

export default function MonitorTab() {
  const navigate = useNavigate()
  const { tab } = useParams<{
    tab: (typeof MONITOR_ROUTE_SEGMENTS)[keyof typeof MONITOR_ROUTE_SEGMENTS]
  }>()
  const defaultPath = `/monitor/${MONITOR_ROUTE_SEGMENTS.CLOUD_CONNECTOR}${getDefaultQueryParams(MONITOR_ROUTE_SEGMENTS.CLOUD_CONNECTOR)}`

  const dispatch = useDispatch<AppDispatch>()
  const { data } = useUserConfigQuery()

  useEffect(() => {
    if (data) {
      dispatch(setUserConfig(data))
    }
  }, [data])

  useEffect(() => {
    if (!tab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(TAB_MAP).some((t) => t.slug === tab)) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!location.search) {
      const defaultQueryParams = getDefaultQueryParams(tab)
      if (defaultQueryParams) {
        navigate(`/monitor/${tab}${defaultQueryParams}`, { replace: true })
      }
    }
  }, [tab])

  if (!tab || !Object.values(TAB_MAP).some((t) => t.slug === tab)) {
    return null
  }

  const handleChange = (
    _: SyntheticEvent,
    newSlug: (typeof MONITOR_ROUTE_SEGMENTS)[keyof typeof MONITOR_ROUTE_SEGMENTS]
  ) => {
    const defaultQueryParams = getDefaultQueryParams(newSlug)
    navigate(`/monitor/${newSlug}${defaultQueryParams}`)
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="monitor tabs"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              paddingLeft: 3,
              paddingRight: 3,

              '& .MuiTab-root.Mui-selected': {
                color: theme.palette.text.primary,
              },
            })}
          >
            {Object.values(TAB_MAP).map((tabItem) => (
              <Tab
                key={tabItem.slug}
                value={tabItem.slug}
                label={tabItem.label}
                sx={{ py: 0 }}
                {...tabProps(tabItem.slug)}
              />
            ))}
          </Tabs>
        </Box>

        {!!TAB_MAP[tab as TabKey] && (
          <Box sx={{ py: 1 }}>{TAB_MAP[tab as TabKey]?.component}</Box>
        )}
      </Box>
    </>
  )
}
