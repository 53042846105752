import { useMemo } from 'react'
import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'

import { useFormContext } from 'react-hook-form'
import { getPolicyTagsListColumns } from './column-config'
import { PolicyTagsTableData } from 'ui-v2/src/lib/use-cases/settings/policies'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'

const TagLists = ({ reviewPolicy }: TagListsProps) => {
  const { watch } = useFormContext<PolicyFormData>()

  const policyTags = watch('policyTags')

  const columns = useMemo(getPolicyTagsListColumns, [])

  const data = useMemo(() => {
    return new PolicyTagsTableData({
      policyTags,
      reviewPolicy,
    }).getPolicyTagList()
  }, [policyTags])

  return <ClientTable checkable={false} data={data ?? []} columns={columns} />
}
interface TagListsProps {
  reviewPolicy?: boolean
}
export default TagLists
