import { SortConfig } from 'ui-v2/src/components/ui/data-table/base-table'
import { CheckStatusData } from './types'
import { CheckStatus, Scan } from 'ui-v2/src/lib/models/backups'

export const transformSortingConfig = (sortignConfig: Array<SortConfig>) => {
  if (!sortignConfig?.[0]) {
    return undefined
  }

  const firstCriteria = sortignConfig[0]
  return `${firstCriteria.key}_${firstCriteria.direction}`
}

export const getScannedStatus = (
  isHealthy: boolean,
  atRisk: boolean,
  data: CheckStatusData
): string => {
  if (isHealthy) {
    const scannedItems = []

    if (data.ransomware_check_status !== CheckStatus.UNCHECKED) {
      scannedItems.push('Ransomware')
    }
    if (data.malware_check_status !== CheckStatus.UNCHECKED) {
      scannedItems.push('Malware')
    }
    if (data.fs_check_status !== CheckStatus.UNCHECKED) {
      scannedItems.push('Insider Threats')
    }

    return `No Infected Files ${scannedItems.length === 0 ? '' : `Scanned for ${scannedItems.join(', ')}`}`
  } else if (atRisk) {
    const uncheckedItems = []
    if (data.ransomware_check_status !== CheckStatus.UNCHECKED) {
      uncheckedItems.push('Ransomware')
    }
    if (data.malware_check_status !== CheckStatus.UNCHECKED) {
      uncheckedItems.push('Malware')
    }
    return `No Scans ${uncheckedItems.length === 0 ? '' : ` for ${uncheckedItems.join(', ')}`}`
  } else {
    const threats = []

    if (data.ransomware_check_status !== CheckStatus.GOOD) {
      threats.push('Ransomware')
    }
    if (data.malware_check_status !== CheckStatus.GOOD) {
      threats.push('Malware')
    }
    if (data.fs_check_status !== CheckStatus.GOOD) {
      threats.push('Filesystem')
    }

    return threats.length > 0 ? `Active Threats: ${threats.join(' | ')}` : ''
  }
}

export function transformScanData(scans: Array<Scan>): {
  ransomwareScan?: { id: string; status: string; timestamp: string }
  fsScan?: { id: string; status: string; timestamp: string }
  malwareScan?: { id: string; status: string; timestamp: string }
} {
  const result: {
    ransomwareScan?: { id: string; status: string; timestamp: string }
    fsScan?: { id: string; status: string; timestamp: string }
    malwareScan?: { id: string; status: string; timestamp: string }
  } = {}

  scans.forEach((item) => {
    const scanData = {
      id: item.id,
      status: item.status,
      timestamp: item.created_at,
    }

    switch (item.kind) {
      case 'ransomware_scan':
        result.ransomwareScan = scanData
        break
      case 'fs_check':
        result.fsScan = scanData
        break
      case 'malware_scan':
        result.malwareScan = scanData
        break
    }
  })

  return result
}

export const getScanJobSource = (source: string, backupType?: string) => {
  switch (source) {
    case 'direct':
      return 'Direct Scan'
    case 'backup':
      return backupType ?? 'Backup Scan'
    case 'mixed':
      return backupType ?? 'Mixed Scan'
    default:
      return 'Scan'
  }
}
