/* eslint-disable import/no-extraneous-dependencies */
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import { navigateTo } from '../helpers/system.helper'
import {
  BASE_ROUTE_SEGMENTS,
  TENANT_ROUTE_SEGMENTS,
} from '../constants/route-segments.constant'

export interface RequestOptions {
  skipRetry?: boolean
  maxRetries?: number
  retryStatusCodes?: Array<number>
  initialRetryDelayMs?: number
  maxRetryDelayMs?: number
}

export class RestClient {
  private axiosInstance: AxiosInstance

  private authToken: string | null = null

  private readonly DEFAULT_INITIAL_RETRY_DELAY_MS = 100

  private readonly DEFAULT_MAX_RETRIES = 3

  private readonly DEFAULT_MAX_RETRY_DELAY_MS = 10000

  private DEFAULT_RETRY_STATUS_CODES = [500, 502, 503, 504]

  constructor({ baseUrl = '', timeout = 60 * 1000 } = {}) {
    const resolvedBaseUrl = this.getBaseUrl(baseUrl)

    this.axiosInstance = axios.create({
      baseURL: resolvedBaseUrl,
      timeout: timeout,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authority: this.getAuthority(resolvedBaseUrl),
      },
    })

    this.setupInterceptors()
  }

  private setupInterceptors(): void {
    // Request interceptor
    this.axiosInstance.interceptors.request.use(
      (config) => {
        if (this.authToken) {
          config.headers = config.headers ?? {}
          config.headers.Authorization = this.authToken
        }

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    // Response interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const config = error.config as any

        if (config.__skipRetry) {
          return Promise.reject(error)
        }

        config.__retryCount = config.__retryCount || 0
        const maxRetries = config.__maxRetries
        const retryStatusCodes = config.__retryStatusCodes
        const initialRetryDelayMs = config.__initialRetryDelayMs
        const maxRetryDelayMs = config.__maxRetryDelayMs

        const shouldRetry =
          error.response?.status &&
          retryStatusCodes.includes(error.response.status) &&
          config.__retryCount < maxRetries

        if (shouldRetry) {
          config.__retryCount += 1
          const backoffDuration = this.calculateBackoff({
            retryCount: config.__retryCount,
            initialRetryDelayMs,
            maxRetryDelayMs,
          })
          await new Promise((resolve) => setTimeout(resolve, backoffDuration))
          return this.axiosInstance(config)
        }

        if (error.response) {
          switch (error.response.status) {
            case 401:
              navigateTo(
                `/${BASE_ROUTE_SEGMENTS.TENANT}/${TENANT_ROUTE_SEGMENTS.UNAUTHENTICATED}`
              )
              break
            case 403:
              navigateTo(
                `/${BASE_ROUTE_SEGMENTS.TENANT}/${TENANT_ROUTE_SEGMENTS.ACCESS_DENIED}`
              )
              break
          }
        }
        return Promise.reject(error)
      }
    )
  }

  private applyRetryConfig(config: any, options?: RequestOptions): any {
    if (options?.skipRetry) {
      config.__skipRetry = true
      return config
    }

    config.__maxRetries = options?.maxRetries ?? this.DEFAULT_MAX_RETRIES

    config.__retryStatusCodes =
      options?.retryStatusCodes ?? this.DEFAULT_RETRY_STATUS_CODES

    config.__initialRetryDelayMs =
      options?.initialRetryDelayMs ?? this.DEFAULT_INITIAL_RETRY_DELAY_MS

    config.__maxRetryDelayMs =
      options?.maxRetryDelayMs ?? this.DEFAULT_MAX_RETRY_DELAY_MS
    return config
  }

  private calculateBackoff({
    retryCount,
    initialRetryDelayMs,
    maxRetryDelayMs,
  }: {
    retryCount: number
    initialRetryDelayMs: number
    maxRetryDelayMs: number
  }): number {
    const backoff =
      initialRetryDelayMs * Math.exp(retryCount) * (1.1 - Math.random() * 0.2)
    return Math.min(backoff, maxRetryDelayMs)
  }

  private getBaseUrl(url: string) {
    if (url) {
      return url
    }

    if (typeof window !== 'undefined') {
      return window.location.origin
    }

    return process.env.NEXT_PROXY_DOMAIN || 'http://localhost:3201'
  }

  private getAuthority(url: string): string {
    return url.replace(/^(https?:\/\/)/, '')
  }

  public async get<T>(
    url: string,
    params?: any,
    options?: RequestOptions
  ): Promise<T> {
    const config = this.applyRetryConfig({ params }, options)
    const response: AxiosResponse<T> = await this.axiosInstance.get(url, config)
    return response.data
  }

  public async post<T>(
    url: string,
    data: any,
    options?: RequestOptions
  ): Promise<T> {
    const config = this.applyRetryConfig({}, options)
    const response: AxiosResponse<T> = await this.axiosInstance.post(
      url,
      data,
      config
    )
    return response.data
  }

  public async put<T>(
    url: string,
    data: any,
    options?: RequestOptions
  ): Promise<T> {
    const config = this.applyRetryConfig({}, options)
    const response: AxiosResponse<T> = await this.axiosInstance.put(
      url,
      data,
      config
    )
    return response.data
  }

  public async delete<T>(url: string, options?: RequestOptions): Promise<T> {
    const config = this.applyRetryConfig({}, options)
    const response: AxiosResponse<T> = await this.axiosInstance.delete(
      url,
      config
    )
    return response.data
  }

  public setAuthToken(token: string): void {
    this.authToken = token
  }
}
