import { FieldErrors, UseFormRegister } from 'react-hook-form'
import Label from 'ui-v2/src/components/ui/data-display/label'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import { FormControl, Grid2 } from '@mui/material'
import { WebhookAuthType, WebhookFormData } from 'ui-v2/src/lib/models/webhooks'
import RegexConstants from 'ui-v2/src/lib/constants/regex.constant'

interface AuthenticationFieldProps {
  authType: WebhookAuthType
  register: UseFormRegister<WebhookFormData>
  errors: FieldErrors<WebhookFormData>
}

const AuthenticationFields = ({
  authType,
  register,
  errors,
}: AuthenticationFieldProps) => {
  switch (authType) {
    case 'basic_authorization':
      return (
        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <FormControl fullWidth>
              <Label>Login</Label>
              <TextField
                {...register('authentication.basic_auth.login.value', {
                  required:
                    authType === 'basic_authorization'
                      ? 'Username is required'
                      : false,
                  validate: (value: string) =>
                    (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
                      !RegexConstants.WITH_WHITESPACE.test(value)) ||
                    'Only ASCII characters are allowed',
                })}
                placeholder="Login"
                fullWidth
                error={
                  !!errors.authentication?.basic_auth?.login?.value?.message
                }
                helperText={
                  errors.authentication?.basic_auth?.login?.value?.message
                }
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl fullWidth>
              <Label>Password</Label>
              <TextField
                {...register('authentication.basic_auth.password.value', {
                  required:
                    authType === 'basic_authorization'
                      ? 'Password is required'
                      : false,
                  validate: (value: string) =>
                    (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
                      !RegexConstants.WITH_WHITESPACE.test(value)) ||
                    'Only ASCII characters are allowed',
                })}
                type="password"
                placeholder="Password"
                fullWidth
                error={
                  !!errors.authentication?.basic_auth?.password?.value?.message
                }
                helperText={
                  errors.authentication?.basic_auth?.password?.value?.message
                }
              />
            </FormControl>
          </Grid2>
        </Grid2>
      )

    case 'bearer':
      return (
        <FormControl fullWidth>
          <Label>Bearer Token</Label>
          <TextField
            {...register('authentication.bearer_token.token.value', {
              required:
                authType === 'bearer' ? 'Bearer token is required' : false,
              validate: (value: string) =>
                (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
                  !RegexConstants.WITH_WHITESPACE.test(value)) ||
                'Only ASCII characters are allowed',
            })}
            placeholder="Bearer Token"
            fullWidth
            error={!!errors.authentication?.bearer_token?.token?.value?.message}
            helperText={
              errors.authentication?.bearer_token?.token?.value?.message
            }
          />
        </FormControl>
      )

    case 'api_key':
      return (
        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <FormControl fullWidth>
              <Label>Key</Label>
              <TextField
                {...register('authentication.api_key.key.value', {
                  required:
                    authType === 'api_key' ? 'API key name is required' : false,
                  validate: (value: string) =>
                    (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
                      !RegexConstants.WITH_WHITESPACE.test(value)) ||
                    'Only ASCII characters are allowed',
                })}
                placeholder="API Key"
                fullWidth
                error={!!errors.authentication?.api_key?.key?.value?.message}
                helperText={errors.authentication?.api_key?.key?.value?.message}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl fullWidth>
              <Label>Value</Label>
              <TextField
                {...register('authentication.api_key.value', {
                  required:
                    authType === 'api_key' ? 'API key is required' : false,
                  validate: (value: string) =>
                    (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
                      !RegexConstants.WITH_WHITESPACE.test(value)) ||
                    'Only ASCII characters are allowed',
                })}
                placeholder="API Value"
                fullWidth
                error={!!errors.authentication?.api_key?.value?.message}
                helperText={errors.authentication?.api_key?.value?.message}
              />
            </FormControl>
          </Grid2>
        </Grid2>
      )

    default:
      return null
  }
}

export default AuthenticationFields
