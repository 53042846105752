import { useQuery } from '@tanstack/react-query'
import { useScanJobService } from '../../rest-service'

interface Options {
  enabled?: boolean
  staleTime?: number
}

export function useListScanJobEntitiesQuery(
  assetId: string | undefined,
  scanJobId: string | undefined,
  options?: Options
) {
  const {
    listScanJobEntities,
    isReady,
    isLoading: isClientLoading,
  } = useScanJobService()

  return useQuery({
    queryKey: ['assets', assetId, 'scan-jobs', scanJobId, 'entities'],
    queryFn: async () => {
      if (!assetId || !scanJobId) {
        throw new Error('assetId or scanJobId are required')
      }

      return listScanJobEntities(assetId, scanJobId)
    },

    enabled:
      Boolean(assetId) &&
      Boolean(scanJobId) &&
      isReady &&
      !isClientLoading &&
      (options?.enabled === undefined ? true : options.enabled),
    staleTime: options?.staleTime ?? 1 * 60 * 1000,
  })
}
