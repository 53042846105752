import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Label from 'ui-v2/src/components/ui/data-display/label'
import {
  useNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from 'ui-v2/src/hooks/queries/notifications'
import { useDisconnectSlackMutation } from 'ui-v2/src/hooks/queries/notifications/disconnect-slack'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid2,
  Stack,
} from '@mui/material'
import { NotificationsConfig } from 'ui-v2/src/lib/clients/notifications'
import Button from 'ui-v2/src/components/ui/inputs/button'

interface NotificationFormData {
  sendToEmail: boolean
  sendToSlack: boolean
}

const Alerts = () => {
  const { data: notificationsSettingsData } = useNotificationSettingsQuery()
  const {
    mutate: disconnectSlackMutate,
    isPending: isDisconnectSlackMutationPending,
  } = useDisconnectSlackMutation()
  const {
    mutate: updateSettingsMutate,
    isPending: isUpdateSettingsMutationPending,
  } = useUpdateNotificationSettingsMutation()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      sendToEmail: false,
      sendToSlack: false,
    },
  })

  const isSlackConnected =
    notificationsSettingsData?.slackNotificationSettings !== undefined

  useEffect(() => {
    if (notificationsSettingsData) {
      reset({
        sendToEmail:
          notificationsSettingsData.emailNotificationSettings?.sendErrors ||
          false,
        sendToSlack:
          notificationsSettingsData.slackNotificationSettings?.sendErrors ||
          false,
      })
    }
  }, [notificationsSettingsData, reset])

  const handleSlackButtonClick = () => {
    if (!isSlackConnected) {
      if (notificationsSettingsData) {
        window.open(notificationsSettingsData.slackSetupUrl, '_blank')
      }
    } else {
      disconnectSlackMutate()
      setValue('sendToSlack', false)
      reset(
        {
          ...watch(),
          sendToSlack: false,
        },
        { keepDirty: isDirty }
      )
    }
  }

  const onSubmit = (data: NotificationFormData) => {
    const updatedSettings: NotificationsConfig = {
      slack: {
        sendErrors: data.sendToSlack,
        sendWarnings: false,
        sendInfo: false,
        isDisconnected: !isSlackConnected,
      },
      email: {
        sendErrors: data.sendToEmail,
        sendWarnings: false,
        sendInfo: false,
      },
    }

    updateSettingsMutate(updatedSettings)
    reset(data) // Reset dirty state after submission
  }

  return (
    <Stack spacing={2.5} sx={{ paddingBottom: 2.5 }} maxWidth={824}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <FormControl>
            <Label>Send Critical Alerts</Label>
            <FormGroup>
              <Controller
                name="sendToEmail"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Send to email"
                    sx={(theme) => ({
                      color:
                        theme.palette.mode === 'dark'
                          ? 'rgba(211, 216, 222, 1)'
                          : '#1A1F28',
                    })}
                  />
                )}
              />
              <Controller
                name="sendToSlack"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        disabled={!isSlackConnected}
                      />
                    }
                    label="Send to Slack"
                    sx={(theme) => ({
                      color:
                        theme.palette.mode === 'dark'
                          ? 'rgba(211, 216, 222, 1)'
                          : '#1A1F28',
                    })}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
        </Grid2>
        <Grid2 size={12}>
          <Label>Send to:</Label>
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={handleSlackButtonClick}
            isLoading={isDisconnectSlackMutationPending}
          >
            {isSlackConnected
              ? 'Disconnect from Slack workspace'
              : 'Connect to Slack workspace'}
          </Button>
        </Grid2>
        <Grid2>
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty}
            isLoading={isUpdateSettingsMutationPending}
          >
            Save
          </Button>
        </Grid2>
      </Grid2>
    </Stack>
  )
}

export default Alerts
