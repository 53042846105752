import { Box, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import DNSIcon from 'ui-v2/src/assets/icons/dns-icon'
import { getRecoveryPointColumns } from './column-config'
import BaseTable from 'ui-v2/src/components/ui/data-table/base-table'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import RestoreForensic from 'ui-v2/src/views/asset-details/data-panel/common/restore-forensic'
import { RecoveryPointRow } from '../common/types'
import { formatDate } from 'ui-v2/src/lib/helpers/time.helper'
import { useToast } from 'ui-v2/src/hooks/toast'
import { useAssetDetails } from '../../widgets/common/use-asset-details'
import { AssetKindEnum } from 'ui-v2/src/lib/constants/assets.constant'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BackupSource from '../common/backup-source'

interface HeaderSectionParams {
  volumesListLength: number | undefined
  source: string | undefined
  timeStamp: string | undefined
  resourceName?: string
  backupKind?: string
  backupId: string | undefined
}

const HeaderSection: React.FC<HeaderSectionParams> = ({
  volumesListLength,
  source,
  timeStamp,
  resourceName = 'Volume',
  backupKind,
  backupId,
}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingX: '16px',
        color: 'white',
      }}
    >
      <Stack spacing={1}>
        <BackupSource
          source={source}
          backupKind={backupKind}
          timestamp={timeStamp}
          backupId={backupId}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <DNSIcon />
          <Typography
            gutterBottom
            style={{
              marginLeft: '4px',
              color:
                theme.palette.mode === ThemeMode.DARK ? '#F6F9FC' : '#4E5665',
            }}
          >
            {resourceName} ({volumesListLength})
          </Typography>
        </Box>
      </Stack>
      <Stack alignItems={'flex-end'} spacing={2}>
        <RestoreForensic />
      </Stack>
    </Box>
  )
}

interface VolumeTableProps {
  data: Array<RecoveryPointRow>
  source: string
  backupKind?: string
  timestamp: string
  backupId?: string
}

const VolumeTable: React.FC<VolumeTableProps> = ({
  data,
  source,
  timestamp,
  backupKind,
  backupId,
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const theme = useTheme()
  const toast = useToast()

  const { assetKind } = useAssetDetails()
  const resourceName = assetKind === AssetKindEnum.S3 ? 'Bucket' : 'Volume'

  const columns = useMemo(
    () => getRecoveryPointColumns(toast, resourceName),
    []
  )

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete('kind')
    newSearchParams.delete('suppressed')

    navigate(
      {
        search: `?${newSearchParams.toString()}`,
      },
      { replace: true }
    )
  }, [])

  return (
    <Stack
      sx={{
        height: '100%',
        backgroundColor: theme.palette.background.highlight,
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        padding: '16px',
      }}
      spacing={1}
    >
      <HeaderSection
        volumesListLength={data.length}
        source={source ?? ''}
        backupKind={backupKind}
        timeStamp={formatDate(timestamp, 'MM/DD/YY hh:mm A')}
        resourceName={resourceName}
        backupId={backupId}
      />

      <Box
        sx={{
          flex: 1,
          minHeight: 0,
        }}
      >
        <BaseTable
          data={data}
          columns={columns}
          styles={{
            root: {
              height: '100%',
              background: theme.palette.background.elevation3,
            },
            cell: {
              background: theme.palette.background.elevation3,
            },
            header: {
              '& .MuiTableCell-head': {
                backgroundColor: theme.palette.background.elevation2,
              },
            },
          }}
        />
      </Box>
    </Stack>
  )
}

export default VolumeTable
