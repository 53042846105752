// TODO: remove this after we migrate to the new assets page completely for the drilldowns
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  source: null,
}

const assetDetailsSourcePathSlice = createSlice({
  name: 'asset-details-source-path',
  initialState,
  reducers: {
    setAssetDetailsSourcePath: (state, action) => {
      state.source = action.payload
    },
    clearAssetDetailsSourcePath: (state) => {
      state.source = null
    },
  },
})

export const { setAssetDetailsSourcePath, clearAssetDetailsSourcePath } =
  assetDetailsSourcePathSlice.actions

export default assetDetailsSourcePathSlice.reducer
