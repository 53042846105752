import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { Box, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  ASSETS_ROUTE_SEGMENTS,
  BASE_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

type CircleBarChartProps = {
  data: Array<{ value: number; name: string }>
  total: number
}

interface LegendSelectChangedEvent {
  name: string
}

function CircleBarChart({ total, data }: CircleBarChartProps) {
  const theme = useTheme()
  const CircleBarchartRef = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    const CircleBarchart = echarts.init(CircleBarchartRef.current)

    const colorSchemes = ['#ADC95B', '#237099', '#A798FF', '#7EBFBC'] as const

    const series = data.map((item, index) => ({
      type: 'bar',
      data: [item.value],
      name: item.name,
      coordinateSystem: 'polar',
      roundCap: true,
      avoidLabelOverlap: false,
      barWidth: 8,
      barGap: '100%',
      barCategoryGap: '100%',
      itemStyle: {
        color: colorSchemes[index] ?? colorSchemes[0],
      },
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(255, 255, 255, 0.05)',
      },
      emphasis: {
        itemStyle: {
          color: 'inherit',
          opacity: 0.7,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    }))

    const option = {
      title: {
        subtext: `${total}%`,
        left: total > 99 ? '24%' : total > 9 ? '25.5%' : '26.5%',
        top: '36%',
        subtextStyle: {
          fontSize: 16,
          fontWeight: '500',
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
        },
      },
      polar: {
        radius: ['25%', '90%'],
        center: ['30%', '50%'],
      },
      angleAxis: {
        max: 100,
        show: false,
        startAngle: 90,
      },
      radiusAxis: {
        type: 'category',
        data: ['category'],
        z: 10,
        show: false,
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params: any) {
          return `${params.seriesName.split(' ')[1]}: ${params.value.toFixed(1)}%`
        },
      },
      series,
      legend: {
        show: true,
        orient: 'vertical',
        right: 10,
        top: 'center',
        itemGap: 10,
        itemWidth: 6,
        itemHeight: 6,
        data: data.map((_, index, array) => ({
          name: array[array.length - 1 - index]?.name,
          icon: 'circle',
          itemStyle: {
            color: colorSchemes[array.length - 1 - index],
          },
        })),
        textStyle: {
          fontSize: 12,
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
          rich: {
            bold: {
              fontWeight: 'bold',
            },
          },
        },
      },
      graphic: {
        type: 'group',
        left: '21.5%',
        top: '50%',
        children: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: 'Overall Coverage',
              fontSize: 9,
              fontWeight: '400',
              lineHeight: 14,
              textAlign: 'center',
              textVerticalAlign: 'middle',
              fill: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
            },
          },
        ],
      },
    }

    CircleBarchart.setOption(option)

    CircleBarchart.on('legendselectchanged', (event) => {
      const params = event as LegendSelectChangedEvent
      const selectedItem = params?.name

      if (selectedItem) {
        if (selectedItem.includes('EC2 Instances')) {
          navigate(
            `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EC2}`
          )
        }

        if (selectedItem.includes('S3 Buckets')) {
          navigate(`/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.S3}`)
        }
        if (selectedItem.includes('EBS Volumes')) {
          navigate(
            `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EBS}`
          )
        }
        if (selectedItem.includes('EFS Filesystem')) {
          navigate(
            `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EFS}`
          )
        }
      }
    })

    CircleBarchart.on('click', (params) => {
      switch (params.componentIndex) {
        case 0:
          navigate(
            `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EFS}`
          )
          break
        case 1:
          navigate(
            `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EBS}`
          )
          break
        case 2:
          navigate(`/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.S3}`)
          break
        case 3:
          navigate(
            `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EC2}`
          )
          break
        default:
      }
    })

    const handleResize = () => {
      CircleBarchart.resize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      CircleBarchart.dispose()
    }
  }, [data, theme.palette.mode])

  return (
    <Box
      ref={CircleBarchartRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '240px',
        maxWidth: 400,
        margin: '0 auto',
      }}
    />
  )
}

export default React.memo(CircleBarChart)
