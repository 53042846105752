/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_VULNERABILITIES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import { RiskLevel } from 'blues-corejs/dist'

export interface FilterComponentProps {
  options: Array<string>
}

const RISK_OPTIONS = [
  {
    value: RiskLevel.Low.toString(),
    label: 'Low',
  },
  {
    value: RiskLevel.Medium.toString(),
    label: 'Medium',
  },
  {
    value: RiskLevel.High.toString(),
    label: 'High',
  },
]

const RiskFilter: React.FC<FilterComponentProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsRiskList =
    searchParams.get(ASSETS_VULNERABILITIES_FILTERS_MAP.RISK)?.split(',') || []

  const handleAwsRiskChange = (
    newAwsRiskList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsRiskList.length === 0) {
      searchParams.delete(ASSETS_VULNERABILITIES_FILTERS_MAP.RISK)
    } else {
      searchParams.set(
        ASSETS_VULNERABILITIES_FILTERS_MAP.RISK,
        newAwsRiskList.join(',')
      )
    }
    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={RISK_OPTIONS}
      value={awsRiskList}
      onChange={handleAwsRiskChange}
      width={170}
      label={
        <SelectLabelText
          selectedValueLength={awsRiskList?.length}
          optionsLength={RISK_OPTIONS.length}
          text="Risk Type"
        />
      }
    />
  )
}

export default RiskFilter
