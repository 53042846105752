/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { CircularProgress, circularProgressClasses } from '@mui/material'

export const CIRCLE_STATUS_COLOR = {
  SUCCESS: '#27ae60',
  PROGRESS: '#fec53d',
  WARNING: '#f2994a',
  ERROR: '#eb5757',
  ABORTED: '#dfeaf5',
  NONE: '#dfeaf5',
}

export const STATUS_TO_STYLE_VARIANT_MAPPING = {
  [TenantJobStatus.SCHEDULED]: CIRCLE_STATUS_COLOR.NONE,
  [TenantJobStatus.STARTING]: CIRCLE_STATUS_COLOR.NONE,
  [TenantJobStatus.INPROGRESS]: CIRCLE_STATUS_COLOR.PROGRESS,
  [TenantJobStatus.FAILED]: CIRCLE_STATUS_COLOR.ERROR,
  [TenantJobStatus.SUCCEEDED]: CIRCLE_STATUS_COLOR.SUCCESS,
  [TenantJobStatus.ABORTED]: CIRCLE_STATUS_COLOR.ABORTED,
}

function mapTenantJobStatusToStyleVariant(status: TenantJobStatus) {
  return STATUS_TO_STYLE_VARIANT_MAPPING[status]
}

const JobProgressCell = ({ status }: JobProgressCellProps) => {
  return (
    <>
      <CircularProgress
        variant="determinate"
        sx={{ color: '#4E5665' }}
        size={20}
        thickness={8}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: mapTenantJobStatusToStyleVariant(status),
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={20}
        thickness={8}
        value={100}
      />
    </>
  )
}
interface JobProgressCellProps {
  status: TenantJobStatus
}
export default JobProgressCell
