import React, { SyntheticEvent, useMemo } from 'react'
import { Autocomplete, TextField, Chip } from '@mui/material'
import { Option } from 'ui-v2/src/lib/models/reports'

const MultiSelectChip: React.FC<MultiSelectChipProps> = ({
  options,
  selectedOptions,
  size = 'small',
  placeholder,
  helperText,
  error = false,
  setSelectedOptions,
}) => {
  const availableOptions = useMemo(
    () =>
      options.filter((option) =>
        selectedOptions.includes(option.value.toString())
      ),
    [options, selectedOptions]
  )

  const handleChange = (_: SyntheticEvent, newValue: Array<Option>) => {
    const selectedValues = newValue.map((option) => option.value.toString())
    setSelectedOptions(selectedValues)
  }

  return (
    <Autocomplete
      size={size}
      multiple
      options={options}
      getOptionLabel={(option: Option) => option.label}
      value={availableOptions}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) =>
        option.value.toString() === value.value.toString()
      }
      renderTags={(value: Array<Option>, getTagProps) =>
        value.map((option: Option, index: number) => (
          <Chip
            {...getTagProps({ index })}
            key={option.value}
            label={option.label}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={selectedOptions.length ? '' : placeholder}
          error={!!error}
          helperText={helperText}
        />
      )}
    />
  )
}

interface MultiSelectChipProps {
  options: Array<Option>
  selectedOptions: Array<string>
  size?: 'small' | 'medium'
  placeholder?: string
  helperText?: string
  error?: boolean
  setSelectedOptions: (options: Array<string>) => void
}

export default MultiSelectChip
