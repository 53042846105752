import { Theme } from '@mui/material'

export function lineBarOptions(
  barSeries: any,
  xAxisData: Array<string>,
  minFailure: number,
  maxSuccess: number,
  theme: Theme
) {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params: Array<any>) {
        const groupName = params[0].name
        let tooltip = `${groupName}<br/>`

        params.forEach(
          (param: {
            value: number
            seriesName: string | Array<string>
            marker: any
          }) => {
            const value = Math.abs(param.value)
            tooltip += `${param.marker} ${param.seriesName}: ${value}<br/>`
          }
        )

        return tooltip
      },
    },
    legend: {
      bottom: 0,
      data: barSeries.map((series: { name: any }) => series.name),
      textStyle: {
        color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#727883',
        fontSize: 12,
      },
      itemWidth: 10,
      itemHeight: 10,
      itemStyle: {
        borderRadius: 10,
      },
    },
    grid: {
      left: '3%',
      right: '3%',
      bottom: '15%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: xAxisData,
      axisLabel: {
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
      },
    },
    yAxis: {
      type: 'value',
      min: minFailure,
      max: maxSuccess,
      axisLabel: {
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
        formatter: function (value: number) {
          return Math.abs(value)
        },
      },
    },
    series: barSeries,
  }
  return option
}
