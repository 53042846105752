/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo } from 'react'
import { AssetsForPolicyCoverage } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { getEfsColumns } from './column-config'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { PolicyEfsTableData } from 'ui-v2/src/lib/use-cases/settings/policies/efs-table-data'
import {
  PolicyEfsTableRow,
  PolicyFormData,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const AssetsEfs = ({
  efsAssets,
  isLoading,
  handleAssetsSelect,
}: AssetsEfsProps) => {
  const { id } = useParams()
  const { watch, getValues, setValue, trigger } =
    useFormContext<PolicyFormData>()
  const selectedEfsAssetsRows = watch('selectedEfsAssetsRows')

  const data = useMemo(() => {
    if (!efsAssets) {
      return
    }

    return new PolicyEfsTableData({
      efsAssets,
    }).getEfsAssetsList()
  }, [efsAssets])

  useEffect(() => {
    if (id && data) {
      const selectedEfsAssets = getValues('selectedEfsAssets')
      const selectedEfsAssetsIds = selectedEfsAssets.map(
        (asset) => asset.asset.id
      )
      const selectedEfsRows = data.filter((row) =>
        selectedEfsAssetsIds.includes(row.id)
      )

      setValue('selectedEfsAssetsRows', selectedEfsRows)
    }
  }, [id, data])

  const columns = useMemo(getEfsColumns, [])

  return (
    <ClientTable
      checkable
      data={data ?? []}
      columns={columns}
      isLoading={isLoading}
      selectedRows={selectedEfsAssetsRows}
      onSelectRows={(rows) => {
        handleAssetsSelect(rows)
        trigger('tabAssetTypeSelectedNum')
      }}
      clearRows={false}
    />
  )
}
interface AssetsEfsProps {
  efsAssets: AssetsForPolicyCoverage | undefined
  isLoading: boolean
  handleAssetsSelect: (rows: Array<PolicyEfsTableRow>) => void
}
export default AssetsEfs
