import React, { StrictMode, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'
import { LDProvider } from 'launchdarkly-react-client-sdk'
// V1
import { useFetchLaunchDarklyApiKey } from '@components/context/feature-flags'
import Layout from '@components-complex/layouts/layout'
import { PageMeta } from '@lib/engine-types'

import store from '@store/store'
import theme from '@lib/theme/theme'
import { ThemeProvider } from '@mui/material/styles'
import { LoadingScreen } from '@components-simple/loading-screen'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { useFetchTenantSettings } from '@lib/zustand/tenant-settings'
import { getContext } from '@components-context/feature-flags/get-context'
import SystemHelper from '@lib/helpers/system.helper'

import {
  AuthProvider,
  AuthenticationSPARequired,
} from '@features/authentication'

import '../styles/loader.scss'
import '../styles/root.css'

// V2
import {
  QueryClientProvider as QueryClientProviderV2,
  RouterProvider as RouterProviderV2,
  StoreProvider as StoreProviderV2,
  ToastProvider as ToastProviderV2,
  ThemeProvider as ThemeProviderV2,
  LocalizationProvider as LocalizationProviderV2,
} from '../ui-v2/src/providers'
import RoutingStrategyProvider from '@lib/router/routing-strategy-provider'
import '../ui-v2/src/styles/index.scss'
import LoadingDisplay from '../ui-v2/src/components/common/loading-display'

interface ConditionalLayoutProps {
  children: React.ReactNode
  pageMeta: PageMeta
  excludedKeywords?: ReadonlyArray<string>
}

const DEFAULT_EXCLUDED_KEYWORDS: ReadonlyArray<string> = ['code']

const useShouldRenderLayout = (
  excludedKeywords: ReadonlyArray<string> = []
): boolean => {
  const location = useLocation()

  return useMemo(() => {
    const currentPath = location.pathname.toLowerCase()
    const shouldExclude = excludedKeywords.some((keyword) =>
      currentPath.includes(keyword.toLowerCase())
    )

    return !shouldExclude
  }, [location.pathname, excludedKeywords])
}

const ConditionalLayout: React.FC<ConditionalLayoutProps> = ({
  children,
  pageMeta,
  excludedKeywords = DEFAULT_EXCLUDED_KEYWORDS,
}) => {
  const shouldRenderLayout = useShouldRenderLayout(excludedKeywords)
  if (!shouldRenderLayout) {
    return null
  }

  return <Layout pageMeta={pageMeta}>{children}</Layout>
}

interface AppPropsExtended {
  Component: React.ComponentType<any>
  pageMeta: PageMeta
}

const LD_PROVIDER_OPTIONS = {
  bootstrap: 'localStorage',
} as const

function Main({ Component, pageMeta }: AppPropsExtended) {
  const { isInitialized, uiV2 } = useCustomFlags()
  const [stylesLoaded, setStylesLoaded] = useState(false)

  useEffect(() => {
    if (!uiV2 && uiV2 !== undefined) {
      //@ts-ignore
      import('../styles/styles.scss').then(() => {
        setStylesLoaded(true)
      })
    } else {
      setStylesLoaded(true)
    }
  }, [uiV2])

  if (!isInitialized || !stylesLoaded) {
    return <LoadingScreen />
  }

  if (uiV2) {
    return (
      <StrictMode>
        <QueryClientProviderV2>
          <ThemeProviderV2>
            <StoreProviderV2>
              <ToastProviderV2>
                <LocalizationProviderV2>
                  <RouterProviderV2 />
                </LocalizationProviderV2>
              </ToastProviderV2>
            </StoreProviderV2>
          </ThemeProviderV2>
        </QueryClientProviderV2>
      </StrictMode>
    )
  }

  return (
    <AuthProvider>
      <AuthenticationSPARequired pageMeta={pageMeta}>
        <RoutingStrategyProvider>
          <ThemeProvider theme={theme}>
            <ConditionalLayout pageMeta={pageMeta}>
              <Component pageMeta={pageMeta} />
            </ConditionalLayout>
          </ThemeProvider>
        </RoutingStrategyProvider>
      </AuthenticationSPARequired>
    </AuthProvider>
  )
}

function App(props: AppPropsExtended) {
  const { isLoading, ldApiKey } = useFetchLaunchDarklyApiKey()
  useFetchTenantSettings()

  if (isLoading || !ldApiKey) {
    return <LoadingDisplay />
  }

  return (
    <Provider store={store}>
      <LDProvider
        clientSideID={ldApiKey}
        context={getContext(SystemHelper.getCurrentTenantName())}
        options={LD_PROVIDER_OPTIONS}
      >
        <Main {...props} />
      </LDProvider>
    </Provider>
  )
}

export default App
