import React from 'react'
import { Close } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import Button from 'ui-v2/src/components/ui/inputs/button'
import NameAndSchedule from './name-and-schedule'
import ProtectionOptions from './protection-options'
import ReviewPolicy from './review-policy'
import Assets from './assets'
import { useFormContext, UseFormHandleSubmit } from 'react-hook-form'
import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'ui-v2/src/hooks/toast'
import { useCreatePlanMutation } from 'ui-v2/src/hooks/queries/pechkin/create-plan'
import { useUpdatePlanMutation } from 'ui-v2/src/hooks/queries/pechkin/update-plan'
import { IntegrityScanOptions } from 'ui-v2/src/lib/constants/policies.constant'
import Tooltip from '@mui/material/Tooltip'

interface PolicyFormProps {
  handleSubmit: UseFormHandleSubmit<PolicyFormData, undefined>
  activeStep: number
  steps: number
  id: string | undefined
  handleBack: () => void
  handleNext: () => void
  handleCloseDrawer: () => void
  existingPoliciesNames: Array<string>
}

const PolicyForm = ({
  handleSubmit,
  activeStep,
  steps,
  id,
  handleBack,
  handleNext,
  handleCloseDrawer,
  existingPoliciesNames,
}: PolicyFormProps) => {
  const { mutate: createPlanMutate, isPending: createPlanIsPending } =
    useCreatePlanMutation()

  const { watch } = useFormContext<PolicyFormData>()

  const { mutate: updatePlanMutate, isPending: updatePlanIsPending } =
    useUpdatePlanMutation()

  const queryClient = useQueryClient()
  const toast = useToast()

  const handleCreatePlan = (policyFormData: PolicyFormData) => {
    const policyName = policyFormData.policyName
    const schedule = policyFormData.schedule
    const selectedCloudConnectors = policyFormData.selectedCloudConnectors

    const selectedVariant = policyFormData.selectedVariant
    const selectedAssets = policyFormData.selectedAssets
    const selectedS3Assets = policyFormData.selectedS3Assets
    const selectedS3AllPath = policyFormData.selectedS3Assets.map(
      (s3Asset) => s3Asset.asset.id
    )
    const selectedEfsAssets = policyFormData.selectedEfsAssets
    const selectedEfsAllPath = policyFormData.selectedEfsAssets.map(
      (efsAsset) => efsAsset.asset.id
    )
    const integrityScan = policyFormData.integrityScan // Recovery Assurance settings
    const isEntropyDetectionEnabled = policyFormData.isEntropyDetectionEnabled
    const integrityCheck = policyFormData.integrityCheck

    const keepDataCopy = policyFormData.keepDataCopy // EC2 & EBS Settings
    const protectNewImmediately = policyFormData.protectNewImmediately

    const scanWithTimestamps = policyFormData.scanWithTimestamps // S3 Settings & EFS settings

    const policyTags = policyFormData.policyTags

    const snapshotImportVariant = policyFormData.snapshotImportVariant

    const skipEbsBackup = policyFormData.skipEbsBackup

    const executeImmediately = false

    createPlanMutate(
      {
        policyName,
        schedule,
        selectedCloudConnectors,
        selectedVariant,
        selectedAssets,
        selectedS3Assets,
        selectedS3AllPath,
        selectedEfsAssets,
        selectedEfsAllPath,
        iscan: integrityScan,
        isEntropyDetectionEnabled,
        integrityCheck,
        keepDataCopy,
        protectNewImmediately,
        scanWithTimestamps,
        policyTags,
        executeImmediately,
        skipEbsBackup: skipEbsBackup,
        snapshotImport: snapshotImportVariant,
        integrityScanOption: IntegrityScanOptions.SCAN_WITH_ELASTIO, // 0
      },
      {
        onSuccess: () => {
          toast.success('The Policy has been created')
          queryClient.invalidateQueries({
            queryKey: ['pechkin'],
          })
          handleCloseDrawer()
        },
        onError: (error) => {
          toast.error(error?.message ?? 'Error during the policy creation')
        },
      }
    )
  }

  const handleUpdatePlan = (policyFormData: PolicyFormData) => {
    const policyName = policyFormData.policyName
    const schedule = policyFormData.schedule
    const selectedCloudConnectors = policyFormData.selectedCloudConnectors

    const selectedVariant = policyFormData.selectedVariant
    const selectedAssets = policyFormData.selectedAssets
    const selectedS3Assets = policyFormData.selectedS3Assets
    const selectedS3AllPath = policyFormData.selectedS3Assets.map(
      (s3Asset) => s3Asset.asset.id
    )
    const selectedEfsAssets = policyFormData.selectedEfsAssets
    const selectedEfsAllPath = policyFormData.selectedEfsAssets.map(
      (efsAsset) => efsAsset.asset.id
    )

    const integrityScan = policyFormData.integrityScan // Recovery Assurance settings
    const isEntropyDetectionEnabled = policyFormData.isEntropyDetectionEnabled
    const integrityCheck = policyFormData.integrityCheck

    const keepDataCopy = policyFormData.keepDataCopy // EC2 & EBS Settings
    const protectNewImmediately = policyFormData.protectNewImmediately

    const scanWithTimestamps = policyFormData.scanWithTimestamps // S3 Settings & EFS settings

    const policyTags = policyFormData.policyTags

    const snapshotImportVariant = policyFormData.snapshotImportVariant

    const skipEbsBackup = policyFormData.skipEbsBackup

    const executeImmediately = false

    updatePlanMutate(
      {
        policyName,
        schedule,
        selectedCloudConnectors,
        selectedVariant,
        selectedAssets,
        selectedS3Assets,
        selectedS3AllPath,
        selectedEfsAssets,
        selectedEfsAllPath,
        iscan: integrityScan,
        isEntropyDetectionEnabled,
        integrityCheck,
        keepDataCopy,
        protectNewImmediately,
        scanWithTimestamps,
        policyTags,
        executeImmediately,
        skipEbsBackup: skipEbsBackup,
        snapshotImport: snapshotImportVariant,
        integrityScanOption: IntegrityScanOptions.SCAN_WITH_ELASTIO, // 0
      },
      {
        onSuccess: () => {
          toast.success('The Policy has been updated')
          queryClient.invalidateQueries({
            queryKey: ['pechkin'],
          })
          handleCloseDrawer()
        },
        onError: (error) => {
          toast.error(error?.message ?? 'Error during the policy update')
        },
      }
    )
  }

  const submit = (policyFormData: PolicyFormData) => {
    if (activeStep === steps - 1) {
      if (id) {
        handleUpdatePlan(policyFormData)
      } else {
        handleCreatePlan(policyFormData)
      }
    } else {
      handleNext()
    }
  }

  const selectedCCANdAssetsAccounts = watch('selectedCloudConnectors')

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        p={2.5}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Typography variant="h5">
            {id ? 'Modify' : 'Create'} Policy ({activeStep + 1}/{steps})
          </Typography>
        </Box>
        <Stack
          display={'flex'}
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          spacing={2}
        >
          {activeStep > 0 && (
            <Button type="button" color="inherit" onClick={handleBack}>
              Back
            </Button>
          )}
          <Tooltip
            title={
              activeStep === 1 && !selectedCCANdAssetsAccounts.length
                ? 'Choose Scope'
                : ''
            }
            arrow
            placement={'bottom-start'}
          >
            <span>
              <Button
                type="submit"
                variant="contained"
                isLoading={createPlanIsPending || updatePlanIsPending}
                disabled={
                  activeStep === 1 && !selectedCCANdAssetsAccounts.length
                }
              >
                {activeStep !== steps - 1 ? 'Next' : id ? 'Modify' : 'Create'}
              </Button>
            </span>
          </Tooltip>

          <IconButton edge="end" aria-label="close" onClick={handleCloseDrawer}>
            <Close />
          </IconButton>
        </Stack>
      </Box>

      <Stack
        spacing={2}
        sx={{
          paddingX: 2.5,
          paddingBottom: 2.5,
        }}
      >
        <Box
          sx={{
            width: '100%',
            padding: 0,
          }}
        >
          {activeStep === 0 && (
            <NameAndSchedule existingPoliciesNames={existingPoliciesNames} />
          )}
          {activeStep === 1 && <Assets />}
          {activeStep === 2 && <ProtectionOptions />}
          {activeStep === 3 && <ReviewPolicy />}
        </Box>
      </Stack>
    </form>
  )
}

export default PolicyForm
