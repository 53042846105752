/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from './grpc'
import { ClientConfig } from '../models/client'

import { ListRequest as UsersListRequest } from 'blue-stack-libs/users-grpc-libs/js/users/messages/users_pb'

import { UserModel } from '../models/user'
import { UserConfig, UserProfileRequest } from '../grpc'
import UserFactory from '../models/user/user.factory'

import { UserProfile, UserProfileStatus } from 'blues-corejs/dist/models/index'
import { ListUsersFilters } from '../models/users'
import {
  ActivateUserRequest,
  DeactivateUserRequest,
  DeleteUserRequest,
  InviteUserRequest,
  ListUsersRequest,
  UpdateMfaRequest,
  UsersPagination,
  UsersPromiseClient,
} from '../grpc'
import { maybeSecondsToDate } from '../helpers/time.helper'
import { Role } from 'blues-corejs/dist/models/users/user-profile'
import {
  USER_SETTINGS_VERSIONS,
  UserSettings,
} from '../constants/settings.constant'
import { base64EncodeObj } from '../helpers/string.helper'

interface SettingInterface {
  updatedAt: number
  version: number
  value: string
}

export class UsersClient extends GrpcClient<UsersPromiseClient> {
  #usersPromiseClient: UsersPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#usersPromiseClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'UsersClient'
  }

  protected initClient(hostName = ''): UsersPromiseClient {
    return new UsersPromiseClient(hostName, null, null)
  }

  async getUserProfile(): Promise<UserModel> {
    const request = new UserProfileRequest()

    const response: UserProfileRequest = await this.callGrpcService(
      () =>
        this.#usersPromiseClient.getUserProfile(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'UsersPromiseClient/getUserProfile',
      }
    )

    return UserFactory.buildFromGRPC(response.toObject())
  }

  async getUserConfig(): Promise<any> {
    const response = await this.callGrpcService(
      () =>
        this.#usersPromiseClient.getUserConfig(
          new UserConfig(),
          this.metadata(this.#token)
        ),
      {
        requestName: 'UsersPromiseClient/getUserConfig',
      }
    )

    return JSON.parse(response.getJson())
  }

  async getList(): Promise<Array<UserProfile>> {
    const response = await this.callGrpcService(
      () =>
        this.#usersPromiseClient.list(
          new UsersListRequest(),
          this.metadata(this.#token)
        ),
      {
        requestName: 'UsersPromiseClient/list',
      }
    )

    response.getUserProfilesList()

    return response.toObject().userProfilesList.map(
      (userProfile) =>
        new UserProfile({
          email: userProfile.email,
          id: userProfile.id,
          // @ts-ignore
          lastSeen: maybeSecondsToDate(userProfile.lastSeen?.seconds),
          locale: userProfile.locale,
          name: userProfile.name,
          nickname: userProfile.nickname,
          phoneNumber: userProfile.phoneNumber,
          // @ts-ignore
          picture: userProfile.picture,
          // @ts-ignore
          role: userProfile.role ?? {},
          // @ts-ignore
          status: userProfile.status,
        })
    )
  }

  async listUsers(filters: ListUsersFilters) {
    const request = new ListUsersRequest()
    const pagination = new UsersPagination()

    if (filters) {
      pagination.setLimit(filters.limit)
      pagination.setOffset(filters.offset)
      request.setPagination(pagination)
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#usersPromiseClient.list(request, this.metadata(this.#token)),
        {
          requestName: 'UsersPromiseClient/list',
        }
      )
    ).toObject()

    return {
      userProfilesList: response.userProfilesList.map(
        (userProfile) =>
          new UserProfile({
            email: userProfile.email,
            id: userProfile.id,
            // @ts-ignore
            lastSeen: maybeSecondsToDate(userProfile.lastSeen?.seconds),
            locale: userProfile.locale,
            name: userProfile.name,
            nickname: userProfile.nickname,
            phoneNumber: userProfile.phoneNumber,
            picture: userProfile.picture,
            role: (userProfile.role ?? {}) as Role,
            status: userProfile.status as UserProfileStatus,
          })
      ),
      totalCount: response.total,
    }
  }

  async inviteUser(userEmail: string, roleId?: string) {
    const request = new InviteUserRequest()
    request.setEmail(userEmail)
    if (roleId) {
      request.setRoleId(roleId)
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#usersPromiseClient.inviteUser(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'UsersPromiseClient/list',
        }
      )
    ).toObject()

    return response
  }

  async updateMfa(enabled: boolean) {
    const request = new UpdateMfaRequest()
    request.setEnabled(enabled)

    const response = (
      await this.callGrpcService(
        () =>
          this.#usersPromiseClient.updateMfaSettings(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'UsersPromiseClient/updateMfaSettings',
        }
      )
    ).toObject()

    return response
  }

  async updateSetting(name: UserSettings, value: any) {
    const settings: Record<string, SettingInterface> =
      await this.getUserConfig()

    settings[name] = {
      updatedAt: +Date.now(),
      version: USER_SETTINGS_VERSIONS[name] ?? 0,
      value: base64EncodeObj(value),
    }

    const request = new UserConfig()
    request.setJson(JSON.stringify(settings))

    const response = (
      await this.callGrpcService(
        () =>
          this.#usersPromiseClient.setUserConfig(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'UsersPromiseClient/setUserConfig',
        }
      )
    ).toObject()

    return response
  }

  async deactivateUser(userId: string) {
    const request = new DeactivateUserRequest()
    request.setUserId(userId)

    const response = (
      await this.callGrpcService(
        () =>
          this.#usersPromiseClient.deactivateUser(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'UsersPromiseClient/deactivateUser',
        }
      )
    ).toObject()

    return response
  }

  async activateUser(userId: string) {
    const request = new ActivateUserRequest()
    request.setUserId(userId)

    const response = (
      await this.callGrpcService(
        () =>
          this.#usersPromiseClient.activateUser(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'UsersPromiseClient/activateUser',
        }
      )
    ).toObject()

    return response
  }

  async deleteUser(userId: string) {
    const request = new DeleteUserRequest()
    request.setUserId(userId)

    const response = (
      await this.callGrpcService(
        () =>
          this.#usersPromiseClient.deleteUser(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'UsersPromiseClient/deleteUser',
        }
      )
    ).toObject()

    return response
  }
}
