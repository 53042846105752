import { useMemo } from 'react'
import { Typography } from '@mui/material'
import DoughnutChart from '../dashboard-charts/doughnut-chart'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { useOvaAssetsScannedQuery } from 'ui-v2/src/hooks/queries/ova-statistics'
import { useNavigate } from 'react-router-dom'
import {
  ASSETS_ROUTE_SEGMENTS,
  BASE_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { NEW_ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { getDefaultQueryParams } from 'ui-v2/src/views/new-assets/filters/config'

export const RansomwareSafety = () => {
  const { data: assetsLiveData } = useAssetsLiveQuery()
  const { data: ovaAssetsScannedData } = useOvaAssetsScannedQuery({})
  const navigate = useNavigate()

  const chartData = useMemo(() => {
    if (!assetsLiveData || !ovaAssetsScannedData) {
      return {
        totalCount: 0,
        data: [
          {
            name: 'Infected Assets',
            value: 0,
            itemStyle: { color: '#FF1654' },
          },
          {
            name: 'Clean Assets',
            value: 0,
            itemStyle: { color: '#7EBFBC' },
          },
        ],
      }
    }

    const totalLiveAssets =
      assetsLiveData.ebsVolumes.length +
      assetsLiveData.ec2Instances.length +
      assetsLiveData.efs.length +
      assetsLiveData.genericHosts.length +
      assetsLiveData.s3Buckets.length

    const assetsWithThreatsSet = new Set()
    assetsLiveData.threats.forEach((threat) => {
      if (threat.source.asset?.assetId) {
        assetsWithThreatsSet.add(threat.source.asset?.assetId)
      }
    })

    const infectedAwsAssetsCount = assetsWithThreatsSet.size
    const cleanAwsAssetsCount = totalLiveAssets - infectedAwsAssetsCount

    const infectedOvaServers = ovaAssetsScannedData.infectedAssets
    const cleanOvaServers = ovaAssetsScannedData.cleanAssets

    const totalInfected = infectedAwsAssetsCount + infectedOvaServers
    const totalClean = cleanAwsAssetsCount + cleanOvaServers
    const totalAssets = totalInfected + totalClean
    const infectedPercentage = Math.round((totalInfected / totalAssets) * 100)
    const cleanPercentage = Math.round((totalClean / totalAssets) * 100)

    return {
      totalCount: assetsWithThreatsSet.size + infectedOvaServers,
      data: [
        {
          name: `Infected Assets  {bold|${totalInfected}/${infectedPercentage}%}`,
          value: infectedPercentage,
          itemStyle: { color: '#FF1654' },
        },
        {
          name: `Clean Assets  {bold|${totalClean}/${cleanPercentage}%}`,
          value: cleanPercentage,
          itemStyle: { color: '#7EBFBC' },
        },
      ],
    }
  }, [assetsLiveData, ovaAssetsScannedData])

  const pathToInfectedAssets = `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EC2}?${getDefaultQueryParams(ASSETS_ROUTE_SEGMENTS.EC2).toString()}&${NEW_ASSET_FILTERS_MAP.HEALTH}=2`
  const pathToCleanAssets = `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EC2}?${getDefaultQueryParams(ASSETS_ROUTE_SEGMENTS.EC2).toString()}&${NEW_ASSET_FILTERS_MAP.HEALTH}=1`

  const onChartClick = (componentIndex: number) => {
    switch (componentIndex) {
      case 0:
        navigate(pathToInfectedAssets)
        break
      case 1:
        navigate(pathToCleanAssets)
        break
      default:
    }
  }

  const onChartLegendClick = (legendName: string | undefined) => {
    if (!legendName) {
      return
    }
    if (legendName.toLowerCase().includes('infected')) {
      navigate(pathToInfectedAssets)
    }
    if (legendName.toLowerCase().includes('clean')) {
      navigate(pathToCleanAssets)
    }
  }

  return (
    <GradientCard>
      <Typography
        fontSize={16}
        fontWeight={700}
        mb={1}
        color="text.secondary"
        sx={{ position: 'relative' }}
      >
        Ransomware Safety
      </Typography>
      <DoughnutChart
        title={'Infected Assets'}
        count={chartData?.totalCount ?? 0}
        data={chartData.data}
        onClick={onChartClick}
        onChartLegendClick={onChartLegendClick}
        graphicPosition={{
          top: '48%',
          left: '17%',
        }}
      />
    </GradientCard>
  )
}

export default RansomwareSafety
