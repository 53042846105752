/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerLabelValue,
} from 'ui-v2/src/components/ui/drawer'
import { FileCopyOutlined } from '@mui/icons-material'
import { Box, Button, Card, Stack, Typography, useTheme } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AwsLogoLightTheme from 'ui-v2/src/assets/images/aws-logo-light-theme'
import AwsLogoDarkTheme from 'ui-v2/src/assets/images/aws-logo-dark-theme'
import { buildRestoreCLICommandRestore } from 'ui-v2/src/lib/helpers/string.helper'
import { getAsset } from 'ui-v2/src/lib/helpers/assets.helper'
import { useAssetsListByIdQuery } from 'ui-v2/src/hooks/queries/assets'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { useLastCleanBackupQuery } from 'ui-v2/src/hooks/queries/backups'
import { GenericHost, OvaServer } from 'blues-corejs/dist'

const AssetRestoreCleanBackup = () => {
  const { assetId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()

  const isRestoreBackupPath = location.pathname.includes(
    'restore-last-clean-backup'
  )

  const {
    data: assetLastCleanBackup,
    isLoading: isAssetLastCleanBackupLoading,
    isError: isAssetLastCleanBackupError,
  } = useLastCleanBackupQuery(assetId)

  const {
    data: assetsData,
    isLoading: isAssetsDataLoading,
    isError: isAssetsDataError,
  } = useAssetsListByIdQuery(assetId ? [assetId] : [])

  const asset = useMemo(() => {
    if (!assetsData || !assetsData.assetsList.length) {
      return undefined
    }
    const firstAsset = assetsData.assetsList[0]
    return firstAsset ? getAsset(firstAsset) : undefined
  }, [assetsData])

  const closeDrawer = () => {
    const cleanedPath = location.pathname.replace(
      '/restore-last-clean-backup',
      ''
    )
    navigate(`${cleanedPath}${location.search}`)
  }
  const restoreScript = useMemo(() => {
    return buildRestoreCLICommandRestore(
      asset,
      assetLastCleanBackup?.elastioRps?.ccRpId as string
    )
  }, [assetLastCleanBackup, assetsData])

  const isLoading = isAssetLastCleanBackupLoading || isAssetsDataLoading
  const isError = isAssetLastCleanBackupError || isAssetsDataError

  return (
    <Drawer open={!!assetId && !!isRestoreBackupPath} onClose={closeDrawer}>
      <DrawerHeader
        title="Restore Last Clean Recovery Point"
        iconStart={
          theme.palette.mode === 'light' ? (
            <AwsLogoLightTheme />
          ) : (
            <AwsLogoDarkTheme />
          )
        }
        handleCloseDrawer={closeDrawer}
      />
      <DrawerContent
        isLoading={isLoading}
        isError={isError}
        isEmpty={!assetLastCleanBackup?.elastioRps}
        emptyMessage="This asset does not have a clean backup."
      >
        {!(asset instanceof GenericHost || asset instanceof OvaServer) ? (
          <Stack
            spacing={3}
            direction="column"
            sx={{
              flexWrap: 'wrap',
              paddingX: 2.5,
              paddingBottom: 2.5,
            }}
          >
            <Stack spacing={2}>
              <Box>
                <DrawerLabelValue
                  label={'AWS Account'}
                  value={asset?.awsAccountId ?? ''}
                  labelWidth={0}
                  size="lg"
                />

                <DrawerLabelValue
                  label={'Asset ID'}
                  value={asset?.awsId ?? ''}
                  labelWidth={0}
                  size="lg"
                />
              </Box>

              <Typography variant="body2" mt={2}>
                To restore to the last clean state of data, run the following
                script in the AWS Cloud Shell.
              </Typography>

              <Card sx={{ padding: 2.5 }}>
                <Typography variant="body2" mb={1}>
                  {restoreScript}
                </Typography>
              </Card>
            </Stack>
            <Stack spacing={2} direction="row" justifyContent={'space-between'}>
              <Button
                startIcon={<FileCopyOutlined />}
                variant="contained"
                onClick={() => {
                  copyToClipboard(restoreScript)
                }}
              >
                Restore Last Clean
              </Button>
              <Button variant="contained">Go to AWS Backup</Button>
            </Stack>
          </Stack>
        ) : null}
      </DrawerContent>
    </Drawer>
  )
}

export default AssetRestoreCleanBackup
