/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import { NotificationRule } from 'blues-corejs/dist/models/notifications/rule'
import ClientTable from 'ui-v2/src//components/ui/data-table/client-pagination-table'
import { getNotificationRulesColumns } from './column-config'
import { Paper } from '@mui/material'
import { useAlertsListStore } from './store/alerts-list-store'
import { useListNotificationsRulesQuery } from 'ui-v2/src//hooks/queries/alerts'
import { useAlertsFilterStore } from './store/alerts-filter-store'

const AlertsTable = () => {
  const { selectedRows, setSelectedRows, setTableData, tableData } =
    useAlertsListStore()
  const { ruleSearch } = useAlertsFilterStore()

  const { data: notificationsRules, isLoading } =
    useListNotificationsRulesQuery({
      search: ruleSearch,
    })

  useEffect(() => {
    if (!notificationsRules) {
      return
    }

    setTableData(notificationsRules.rulesList)
  }, [notificationsRules])

  const columns = getNotificationRulesColumns()

  const handleSelectRow = (rows: Array<NotificationRule>) => {
    setSelectedRows(rows)
  }

  useEffect(() => {
    return () => {
      setSelectedRows([])
    }
  }, [])

  return (
    <Paper
      elevation={1}
      sx={{
        backgroundColor: '#1E1E1E',
      }}
    >
      <ClientTable
        checkable
        data={tableData}
        columns={columns}
        onSelectRows={(rows) => {
          handleSelectRow(rows)
        }}
        selectedRows={selectedRows}
        isLoading={isLoading}
        loadingRowCount={3}
        styles={{
          cell: {
            height: '40px',
          },
          headerCell: {
            height: '40px',
          },
        }}
      />
    </Paper>
  )
}

export default AlertsTable
