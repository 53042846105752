/* eslint-disable import/no-extraneous-dependencies */
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
  OvaBackup,
} from 'blues-corejs/dist'
import { ThreatStatus } from 'ui-v2/src/views/asset-details/data-panel/common/types'

export interface BackupForAsset {
  awsbRps: AWSBRecoveryPoint
  ebsSnapshots: EBSSnapshot
  elastioRps: ElastioRecoveryPoint
  ovaBackup: OvaBackup
}

export interface BackupsForAsset {
  awsbRpsList: Array<AWSBRecoveryPoint>
  ebsSnapshotsList: Array<EBSSnapshot>
  elastioRpsList: Array<ElastioRecoveryPoint>
  ovaBackupList: Array<OvaBackup>
}

export interface ListBackupsForAssetFilters {
  assetIdList: Array<string>
  pageSize?: number
  pageToken?: string
}

export type Backup =
  | AWSBRecoveryPoint
  | EBSSnapshot
  | ElastioRecoveryPoint
  | OvaBackup

export interface RecoveryPointsParams {
  pageSize?: number
  pageToken?: string
  days?: number
  backupType?: 'malware_scan' | 'ransomware_scan' | any
  type?: string
}

export type RecoveryPointScan = {
  job_id: string
  timestamp: string
}

export type RecoveryPoint = {
  id: string
  timestamp: string
  provider: string
  kind: string
  health: string
  key?: Array<string>
  backupType?: string
  all_scans?: Array<RecoveryPointScan>
}

export type Entities = {
  id: string
  malware_status: ThreatStatus
  ransomware_status: ThreatStatus
  fs_check_status: ThreatStatus
  anomaly_status: ThreatStatus
  name: string
  entity_kind: 'asset' | 'asset_item'
  kind: string
  anomalies_count: number | null
  scans: Array<{
    id: string
    kind: 'ransomware_scan' | 'malware_scan' | 'fs_check'
    status: 'good' | 'bad'
  }>
}

export type RecoveryPointResponse = {
  items: Array<RecoveryPoint>
  pagination: {
    next_page_token: string
    prev_page_token: string
    total_count: number
  }
}

enum ThreatKind {
  Malware = 'malware',
  Ransomware = 'ransomware',
}

export type AssetThreat = {
  id: string
  path: string
  name: string
  kind: ThreatKind
  detected_at: string
  suppressed: boolean
}

export interface AssetThreatsParams {
  sorting?: string
}

export type AssetThreatsResponse = Array<AssetThreat>

type ScanKind = 'malware_scan' | 'ransomware_scan' | 'fs_check'

export interface ListScanJobParams {
  pageSize?: number
  pageToken?: string
  days?: number
  kind?: ScanKind
  type?: string
}

interface Resource {
  id: string
  kind_tag: string
  asset_id?: string
}

interface Target {
  id: string
  kind_tag: string
  resource: Resource
  asset_id?: string
}

export interface Scan {
  id: string
  job_id: string
  kind: ScanKind
  status: string
  type: string
  created_at: string
  target: Target
}

export interface ScanJobData {
  job_id: string
  timestamp: string
  health: string
  type: string
  backupType?: string
  backupKind?: string
  scans: Array<Scan>
  key?: Array<string>
}

export type ScanLogResponse = {
  items: Array<ScanJobData>
  pagination: {
    next_page_token: string
    prev_page_token: string
    total_count: number
  }
}

type RecoveryPointHealth = 'infected' | 'at_risk' | 'clean'

export enum CheckStatus {
  GOOD = 'good',
  BAD = 'bad',
  UNCHECKED = 'unchecked',
}

export type RecoveryPointBackupByIdResponse = {
  id: string
  backupType: string
  tenant_name: string
  timestamp: string
  provider: string
  kind: string
  source_asset_id: string
  protected_asset_ids: Array<string>
  protected_asset_item_ids: Array<string>
  fs_check_status: CheckStatus
  ransomware_check_status: CheckStatus
  malware_check_status: CheckStatus
  creation_status: string
  deletion_status: string | null
  replicated: boolean
  key?: Array<string>
  health: RecoveryPointHealth
  latest_ransomware_scan?: {
    id: string
    status: string
    timestamp: string
  }
  latest_fs_check?: {
    id: string
    status: string
    timestamp: string
  }
  latest_malware_scan?: {
    id: string
    status: string
    timestamp: string
  }
}
