import React from 'react'
import {
  SxProps,
  TablePagination,
  TablePaginationSlots,
  Theme,
} from '@mui/material'
import BaseTable, { BaseTableProps, BaseTableStyles } from './base-table'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import { mergeSx } from './util'

interface OffsetTableProps<T extends Record<string, any>>
  extends Omit<BaseTableProps<T>, 'footer'> {
  pageSize: number
  setPageSize?: React.Dispatch<React.SetStateAction<number>>
  offset: number
  setOffset?: React.Dispatch<React.SetStateAction<number>>
  totalCount: number
  styles?: BaseTableStyles & {
    pagination?: SxProps<Theme>
  }
  slots?: Partial<TablePaginationSlots>
  rowsPerPageOptions?: Array<number>
  multiSort?: boolean
}

function OffsetTable<T extends { id?: string | number }>({
  pageSize = TABLE_ROWS_PER_PAGE_OPTIONS[0],
  offset = 0,
  totalCount,
  setOffset,
  setPageSize,
  styles = {},
  slots,
  rowsPerPageOptions,
  ...baseProps
}: OffsetTableProps<T>) {
  const page = Math.floor(offset / pageSize)

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setOffset?.(newPage * pageSize)
    baseProps.onSelectRows?.([])
  }

  const onRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageSize = parseInt(e.target.value, 10)
    setOffset?.(0)
    setPageSize?.(newPageSize)
    baseProps.onSelectRows?.([])
  }

  const paginationComponent = (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions ?? TABLE_ROWS_PER_PAGE_OPTIONS}
      component="div"
      count={totalCount}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={onRowsPerPageChange}
      slots={slots}
      sx={mergeSx(
        {
          '& .MuiTablePagination-toolbar': {
            minHeight: '43px !important',
            height: '43px',
            backgroundColor: (theme) => theme.palette.background.paper,
          },
        },
        styles.pagination
      )}
    />
  )

  // eslint-disable-next-line
  const { pagination: _, ...baseTableStyles } = styles

  return (
    <BaseTable
      {...baseProps}
      footer={paginationComponent}
      styles={baseTableStyles}
    />
  )
}

export default OffsetTable
