/* eslint-disable import/no-extraneous-dependencies */
import { Job as ProtobufJob } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/cloud_connector/job_pb'
import { JobCloudConnectorKind } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'

export class CloudConnectorJobKindTransformer {
  #jobKind: ProtobufJob.Kind

  constructor(jobKind: ProtobufJob.Kind) {
    this.#jobKind = jobKind
  }

  transform(): JobCloudConnectorKind {
    const mapping: Record<ProtobufJob.Kind, JobCloudConnectorKind> = {
      [ProtobufJob.Kind.KIND_UNKNOWN]: JobCloudConnectorKind.UNKNOWN,
      [ProtobufJob.Kind.KIND_EBS_BACKUP]: JobCloudConnectorKind.EBS_BACKUP,
      [ProtobufJob.Kind.KIND_EBS_RESTORE]: JobCloudConnectorKind.EBS_RESTORE,
      [ProtobufJob.Kind.KIND_EC2_BACKUP]: JobCloudConnectorKind.EC2_BACKUP,
      [ProtobufJob.Kind.KIND_EC2_RESTORE]: JobCloudConnectorKind.EC2_RESTORE,
      [ProtobufJob.Kind.KIND_EFS_BACKUP]: JobCloudConnectorKind.EFS_BACKUP,
      [ProtobufJob.Kind.KIND_ROLLUP]: JobCloudConnectorKind.ROLLUP,
      [ProtobufJob.Kind.KIND_BLOCK_BACKUP]: JobCloudConnectorKind.BLOCK_BACKUP,
      [ProtobufJob.Kind.KIND_BLOCK_RESTORE]:
        JobCloudConnectorKind.BLOCK_RESTORE,
      [ProtobufJob.Kind.KIND_FILE_BACKUP]: JobCloudConnectorKind.FILE_BACKUP,
      [ProtobufJob.Kind.KIND_FILE_RESTORE]: JobCloudConnectorKind.FILE_RESTORE,
      [ProtobufJob.Kind.KIND_STREAM_BACKUP]:
        JobCloudConnectorKind.STREAM_BACKUP,
      [ProtobufJob.Kind.KIND_STREAM_RESTORE]:
        JobCloudConnectorKind.STREAM_RESTORE,
      [ProtobufJob.Kind.KIND_ISCAN]: JobCloudConnectorKind.ISCAN,
      [ProtobufJob.Kind.KIND_IMPORT_SNAPSHOTS]:
        JobCloudConnectorKind.IMPORT_SNAPSHOTS,
      [ProtobufJob.Kind.KIND_BACKUP_INTEGRITY_CHECK]:
        JobCloudConnectorKind.BACKUP_INTEGRITY_CHECK,
      [ProtobufJob.Kind.KIND_APPLY_RETENTION_POLICY]:
        JobCloudConnectorKind.APPLY_RETENTION_POLICY,
      [ProtobufJob.Kind.KIND_FS_CHECK]: JobCloudConnectorKind.FS_CHECK,
      [ProtobufJob.Kind.KIND_POSTGRES_BACKUP]:
        JobCloudConnectorKind.POSTGRES_BACKUP,
      [ProtobufJob.Kind.KIND_POSTGRES_RESTORE]:
        JobCloudConnectorKind.POSTGRES_RESTORE,
      [ProtobufJob.Kind.KIND_AWS_BACKUP_IMPORT]:
        JobCloudConnectorKind.AWS_BACKUP_IMPORT,
      [ProtobufJob.Kind.KIND_VAULT_CATALOG_RESTORE]:
        JobCloudConnectorKind.VAULT_CATALOG_RESTORE,
      [ProtobufJob.Kind.KIND_DATABASE_MAINTENANCE]:
        JobCloudConnectorKind.DATABASE_MAINTENANCE,
      [ProtobufJob.Kind.KIND_POSTGRES_RECOVERY_TEST]:
        JobCloudConnectorKind.POSTGRES_RECOVERY_TEST,
      [ProtobufJob.Kind.KIND_S3_BACKUP]: JobCloudConnectorKind.S3_BACKUP,
      [ProtobufJob.Kind.KIND_S3_RESTORE]: JobCloudConnectorKind.S3_RESTORE,
      [ProtobufJob.Kind.KIND_AWS_BACKUP_RP_ISCAN]:
        JobCloudConnectorKind.AWS_BACKUP_RP_ISCAN,
      [ProtobufJob.Kind.KIND_AWS_BACKUP_RP_EC2_SCAN]:
        JobCloudConnectorKind.AWS_BACKUP_RP_EC2_SCAN,
      [ProtobufJob.Kind.KIND_AWS_BACKUP_RP_EBS_SCAN]:
        JobCloudConnectorKind.AWS_BACKUP_RP_EBS_SCAN,
      [ProtobufJob.Kind.KIND_AWS_BACKUP_RP_EFS_SCAN]:
        JobCloudConnectorKind.AWS_BACKUP_RP_EFS_SCAN,
      [ProtobufJob.Kind.KIND_AWS_EFS_SCAN]: JobCloudConnectorKind.AWS_EFS_SCAN,
      [ProtobufJob.Kind.KIND_AWS_BACKUP_RP_EC2_IMPORT]:
        JobCloudConnectorKind.AWS_BACKUP_RP_EC2_IMPORT,
      [ProtobufJob.Kind.KIND_AWS_BACKUP_RP_EBS_IMPORT]:
        JobCloudConnectorKind.AWS_BACKUP_RP_EBS_IMPORT,

      [ProtobufJob.Kind.KIND_VAULT_REPLICATION]:
        JobCloudConnectorKind.VAULT_REPLICATION,
      [ProtobufJob.Kind.KIND_AWS_EC2_AMI_SCAN]:
        JobCloudConnectorKind.AWS_EC2_AMI_SCAN,
      [ProtobufJob.Kind.KIND_AWS_FSX_ONTAP_SCAN]:
        JobCloudConnectorKind.AWS_FSX_ONTAP_SCAN,
      [ProtobufJob.Kind.KIND_AWS_EBS_SCAN]: JobCloudConnectorKind.AWS_EBS_SCAN,
      [ProtobufJob.Kind.KIND_AWS_EC2_SCAN]: JobCloudConnectorKind.AWS_EC2_SCAN,
      [ProtobufJob.Kind.KIND_AWS_BACKUP_RP_FSX_ONTAP_SCAN]:
        JobCloudConnectorKind.AWS_BACKUP_RP_FSX_ONTAP_SCAN,
      [ProtobufJob.Kind.KIND_AWS_EC2_AMI_IMPORT]:
        JobCloudConnectorKind.AWS_EC2_AMI_IMPORT,
      [ProtobufJob.Kind.KIND_AWS_EBS_SNAPSHOT_SCAN]:
        JobCloudConnectorKind.AWS_EBS_SNAPSHOT_SCAN,
      [ProtobufJob.Kind.KIND_AWS_S3_SCAN]: JobCloudConnectorKind.AWS_S3_SCAN,
      [ProtobufJob.Kind.KIND_AWS_EBS_SNAPSHOTS_SCAN]:
        JobCloudConnectorKind.KIND_AWS_EBS_SNAPSHOTS_SCAN,
      [JobCloudConnectorKind.KIND_AWS_BACKUP_RP_S3_SCAN]:
        JobCloudConnectorKind.KIND_AWS_BACKUP_RP_S3_SCAN,
      [ProtobufJob.Kind.KIND_AWS_BACKUP_RP_VM_SCAN]:
        JobCloudConnectorKind.KIND_AWS_BACKUP_RP_VM_SCAN,
      [ProtobufJob.Kind.KIND_AWS_ASSET_REGION_STACK_DEPLOY]:
        JobCloudConnectorKind.KIND_AWS_ASSET_REGION_STACK_DEPLOY,
      [ProtobufJob.Kind.KIND_AWS_ASSET_REGION_STACK_DESTROY]:
        JobCloudConnectorKind.KIND_AWS_ASSET_REGION_STACK_DESTROY,
      [ProtobufJob.Kind.KIND_AWS_ASSET_REGION_STACK_UPDATE]:
        JobCloudConnectorKind.KIND_AWS_ASSET_REGION_STACK_UPDATE,
    }

    return mapping[this.#jobKind]
  }
}
