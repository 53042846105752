import { useQuery } from '@tanstack/react-query'
import { JobsClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { JOBS_REFETCH_INTERVAL } from 'ui-v2/src/lib/constants/query.constant'
import { TimeRange } from 'ui-v2/src/lib/models/types'

export function useTenantJobFiltersQuery(timeRange: TimeRange) {
  const jobsClient = useGrpcClient(JobsClient)

  return useQuery({
    queryKey: ['monitor', 'deployment-filters', 'list'],
    queryFn: () => {
      if (!jobsClient) {
        throw new Error('Jobs client not initialized')
      }
      return jobsClient.getTenantJobFilters(timeRange)
    },
    enabled: !!jobsClient,
    refetchInterval: JOBS_REFETCH_INTERVAL,
  })
}
