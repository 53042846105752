/* eslint-disable import/no-extraneous-dependencies */
import {
  AWSBRecoveryPoint,
  EBS,
  EBSSnapshot,
  EC2,
  EFS,
  ElastioRecoveryPoint,
  ListAssetsForPolicyCoverage,
  S3Bucket,
} from 'blues-corejs/dist'
import {
  ListPlansRequest,
  PechkinPromiseClient,
  ScheduleScansRequest,
  PenchkinSchedule,
  DeletePlanRequest,
  BulkUpdatePlanStatusesRequest,
  PlanStatusUpdate,
  Empty,
  ExecutePlanRequest,
  CreatePlanRequest,
  Plan,
  Selector,
  PlanMode,
  Backup,
  ScanOnly,
  IscanOptions,
  Vault,
  PlanAccountRegionSelector,
  UpdatePlanRequest,
  GetPlanRequest,
  SnapshotImport,
} from '../grpc'
import { ScheduleScanAsset } from '../models/assets'
import { ClientConfig } from '../models/client'
import { GrpcClient } from './grpc'
import PolicyModel from '../models/settings/policies/policy.model'
import ScheduleInterface from '../models/settings/policies/schedule'
import {
  EMPTY_SCAN_WITH_TIMESTAMPS,
  EMPTY_SCHEDULE,
} from '../constants/time.constant'
import FrequencyConstant from '../constants/frequency.constant'
import TimeFormatConstants from '../constants/time-format.constant'
import DayOfWeekConstant from '../constants/day-of-week.constant'
import { getDateWithTimezone, timestampToMoment } from '../helpers/time.helper'
import { getAssetType, scheduleNextRunLabel } from '../helpers/data.helper'
import PolicyFactory from '../models/settings/policies/policy.factory'
import {
  BuildRequestPolicyParams,
  PolicyDataResponse,
  PolicyRequestParams,
} from '../models/pechkin'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  Asset,
  AssetKind,
  AssetsForPolicyCoverage,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import {
  SelectedS3AssetComplex,
  SelectSpecificPathsInterface,
} from '../models/settings/policies/asset-s3.model'
import {
  AssetType,
  IntegrityScanInterface,
  VaultForPolicy,
} from '../models/settings/policies/policies'
import {
  IntegrityCheckOptions,
  IntegrityScanOptions,
  KeepDataCopyInterface,
  ProtectNewImmediately,
  ScanWithTimestampsInterface,
} from '../constants/policies.constant'
import { ValueInterface, VIRow } from '../engine-types'
import { SelectedEfsAssetComplex } from '../models/settings/policies/asset-efs.model'
import LangHelper from '../helpers/lang.helper'
import { PolicyInfoWithSelectedAssets } from '../models/pechkin/pechkin'
import { AssetsClient } from './assets'
import { RecoveryPointKind } from 'blues-corejs/dist/models/backups/aws/awsb/types'

export class PechkinClient extends GrpcClient<PechkinPromiseClient> {
  #pechkinClient: PechkinPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#pechkinClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'PechkinClient'
  }

  protected initClient(hostName = ''): PechkinPromiseClient {
    return new PechkinPromiseClient(hostName, null, null)
  }

  async scheduleAssetScans(assetList: Array<ScheduleScanAsset>) {
    const isEveryAssetCanBeDirectlyScanned = assetList.every(
      (asset) => asset.canBeDirectlyScanned
    )

    if (!isEveryAssetCanBeDirectlyScanned) {
      throw new Error('Asset cannot be directly scanned')
    }

    const request = new ScheduleScansRequest()

    const scansList: Array<ScheduleScansRequest.Scan> = []

    for (const asset of assetList) {
      const scan = new ScheduleScansRequest.Scan()
      const scanType = new ScheduleScansRequest.DirectScanOptions()
      scanType.setMalware(true)
      scanType.setRansomware(true)

      if (asset instanceof EC2) {
        const ec2Scan = new ScheduleScansRequest.AwsEc2Scan()
          .setAwsAccountId(asset.awsAccountId)
          .setAwsRegion(asset.awsRegion)
          .setAwsInstanceId(asset.awsId)
          .setScanType(scanType)
        scan.setEc2(ec2Scan)
        scansList.push(scan)
      } else if (asset instanceof EBS) {
        const ebsScan = new ScheduleScansRequest.AwsEbsScan()
          .setAwsAccountId(asset.awsAccountId)
          .setAwsRegion(asset.awsRegion)
          .setAwsVolumeId(asset.awsId)
          .setScanType(scanType)
        scan.setAwsEbs(ebsScan)
        scansList.push(scan)
      } else if (asset instanceof S3Bucket) {
        const s3BucketScan = new ScheduleScansRequest.AwsS3Scan()
          .setAwsAccountId(asset.awsAccountId)
          .setAwsRegion(asset.awsRegion)
          .setBucket(asset.awsId)
          .setScanType(scanType)
        scan.setAwsS3(s3BucketScan)
        scansList.push(scan)
      } else if (asset instanceof EFS) {
        const efsScan = new ScheduleScansRequest.AwsEfsScan()
          .setAwsAccountId(asset.awsAccountId)
          .setAwsRegion(asset.awsRegion)
          .setFsId(asset.awsId)
          .setScanType(scanType)
        scan.setAwsEfs(efsScan)
        scansList.push(scan)
      } else {
        console.error('Unhandled asset type')
      }
    }

    request.setScansList(scansList)

    const response = await this.callGrpcService(
      () =>
        this.#pechkinClient.scheduleScans(request, this.metadata(this.#token)),
      {
        requestName: 'PechkinPromiseClient/scheduleScans',
      }
    )
    return response.toObject().resultsList
  }

  async awsBackupRpScan(backup: AWSBRecoveryPoint): Promise<Array<string>> {
    const scan = new ScheduleScansRequest.Scan()
    let awsBackupRpScan:
      | ScheduleScansRequest.AwsBackupEc2RpScan
      | ScheduleScansRequest.AwsBackupEbsRpScan

    switch (backup.kind) {
      case RecoveryPointKind.EC2:
        awsBackupRpScan = new ScheduleScansRequest.AwsBackupEc2RpScan()
        scan.setAwsBackupEc2Rp(awsBackupRpScan)
        break
      case RecoveryPointKind.EBS:
        awsBackupRpScan = new ScheduleScansRequest.AwsBackupEbsRpScan()
        scan.setAwsBackupEbsRp(awsBackupRpScan)
        break

      default:
        throw new Error('Failed to schedule scans')
    }

    const directScanOptions = new ScheduleScansRequest.DirectScanOptions()
      .setMalware(true)
      .setRansomware(true)

    awsBackupRpScan
      .setArn(backup.arn)
      .setAwsVaultName(backup.vault.name)
      .setAwsRegion(backup.region)
      .setAwsAccountId(backup.accountId)
      .setScanType(directScanOptions)

    const requestWrapper = new ScheduleScansRequest().setScansList([scan])
    const resultsList = await this.callGrpcService(
      () =>
        this.#pechkinClient.scheduleScans(
          requestWrapper,
          this.metadata(this.#token)
        ),
      {
        requestName: 'PechkinPromiseClient/scheduleScans',
      }
    )
    return resultsList.getResultsList().map((s) => s.getJobId())
  }

  async awsEbsSnapshotScan(backup: EBSSnapshot): Promise<Array<string>> {
    const directScanOptions = new ScheduleScansRequest.DirectScanOptions()
      .setRansomware(true)
      .setMalware(true)
    const awsBackupEbsRpScanRequest =
      new ScheduleScansRequest.Scan().setAwsEbsSnapshot(
        new ScheduleScansRequest.AwsEbsSnapshotScan()
          .setAwsRegion(backup.awsRegion)
          .setSnapshotId(backup.awsId)
          .setAwsAccountId(backup.awsAccountId)
          .setScanType(directScanOptions)
      )

    const requestWrapper = new ScheduleScansRequest().setScansList([
      awsBackupEbsRpScanRequest,
    ])
    const resultsList = await this.callGrpcService(
      () =>
        this.#pechkinClient.scheduleScans(
          requestWrapper,
          this.metadata(this.#token)
        ),
      {
        requestName: 'PechkinPromiseClient/scheduleScans',
      }
    )
    return resultsList.getResultsList().map((s) => s.getJobId())
  }

  async scheduleRecoveryPointScan(
    backup: ElastioRecoveryPoint
  ): Promise<Array<string>> {
    const erpScanOptions = new ScheduleScansRequest.ErpScan.ErpScanOptions()
    const scheduleScansRequest = new ScheduleScansRequest()

    const scan = new ScheduleScansRequest.Scan()

    const erpScan = new ScheduleScansRequest.ErpScan()
    erpScan.setScanType(erpScanOptions.setMalware(true).setRansomware(true))

    scan.setErpScan(erpScan)

    const elastioRecoveryPoint =
      new ScheduleScansRequest.ErpScan.RecoveryPoint()
        .setRecoveryPointId(backup.ccRpId)
        .setCcId(backup.ccId)
        .setCcVaultId(backup.vaultName)
        .setShard(backup.vault.shard)

    erpScan.setRecoveryPointsList([elastioRecoveryPoint])

    scheduleScansRequest.setScansList([scan])

    const resultsList = await this.callGrpcService(
      () =>
        this.#pechkinClient.scheduleScans(
          scheduleScansRequest,
          this.metadata(this.#token)
        ),
      {
        requestName: 'PechkinPromiseClient/scheduleScans',
      }
    )

    return resultsList.getResultsList().map((s) => s.getJobId())
  }

  async listPlans(): Promise<Array<PolicyModel>> {
    const request = new ListPlansRequest()

    const result = await this.callGrpcService(
      () => this.#pechkinClient.listPlans(request, this.metadata(this.#token)),
      { requestName: 'PechkinPromiseClient/listPlans' }
    )
    return result.toObject().plansList.map((data) => {
      const responseSchedule = this.buildScheduleFromRequest(
        data.plan?.schedule
      )
      let status = 'Paused'
      let started = false
      const previousRun = data.lastRunAt?.seconds
        ? data.lastRunAt?.seconds * 1000
        : 0

      if (data.plan?.schedule?.enabled) {
        status = scheduleNextRunLabel(responseSchedule)
        started = true
      } else {
        if (data.plan?.schedule?.pausedUntil) {
          const pausedUntilMilliseconds =
            data.plan.schedule.pausedUntil.seconds * 1000
          const timeFormatted = getDateWithTimezone(
            pausedUntilMilliseconds
          ).format(TimeFormatConstants.SHORT_DATETIME_FORMAT)

          if (pausedUntilMilliseconds < Date.now()) {
            status = scheduleNextRunLabel(responseSchedule)
          } else {
            status = `Scheduled: ${timeFormatted}`
          }
        }
      }

      return PolicyFactory.build({
        ...data.plan,
        schedule: responseSchedule,
        assetsCount: data.assetsCount,
        sourcesCount: data.sourcesCount,
        status,
        started,
        previousRun,
        kind: data.kind,
      })
    })
  }

  async deletePlan(id: string): Promise<any> {
    const request = new DeletePlanRequest()
    request.setId(id)
    const result = await this.callGrpcService(
      () => this.#pechkinClient.deletePlan(request, this.metadata(this.#token)),
      { requestName: 'PechkinPromiseClient/deletePlan' }
    )

    return result.toObject()
  }

  async pausePlan(id: string): Promise<any> {
    const request = new BulkUpdatePlanStatusesRequest()
    const policyData = new PlanStatusUpdate()
    policyData.setPlanId(id)
    policyData.setDisabled(new Empty())
    request.setPlanStatusesList([policyData])

    const result = await this.callGrpcService(
      () =>
        this.#pechkinClient.bulkUpdatePlanStatuses(
          request,
          this.metadata(this.#token)
        ),
      { requestName: 'PechkinPromiseClient/pausePlan' }
    )
    return result.toObject()
  }

  async resumePlan(id: string): Promise<any> {
    const request = new BulkUpdatePlanStatusesRequest()
    const policyData = new PlanStatusUpdate()
    policyData.setPlanId(id)
    policyData.setEnabled(new Empty())
    request.setPlanStatusesList([policyData])

    const result = await this.callGrpcService(
      () =>
        this.#pechkinClient.bulkUpdatePlanStatuses(
          request,
          this.metadata(this.#token)
        ),
      { requestName: 'PechkinPromiseClient/resumePlan' }
    )
    return result.toObject()
  }

  async executePlan(id: string): Promise<any> {
    const request = new ExecutePlanRequest()
    request.setId(id)

    const result = await this.callGrpcService(
      () =>
        this.#pechkinClient.executePlan(request, this.metadata(this.#token)),
      { requestName: 'PechkinPromiseClient/executePlan' }
    )
    return result.toObject()
  }

  private buildScheduleFromRequest(
    responseSchedule?: PenchkinSchedule.AsObject
  ): ScheduleInterface {
    const schedule: ScheduleInterface = EMPTY_SCHEDULE()

    if (!responseSchedule) {
      return schedule
    }

    if (responseSchedule.every15Minutes) {
      schedule.frequency = FrequencyConstant.FREQUENCY_EVERY_15_MIN
      schedule.windowStart = {
        hour: responseSchedule.every15Minutes.window?.from?.hour ?? 0,
        minute: responseSchedule.every15Minutes.window?.from?.minute ?? 0,
        timezone:
          responseSchedule.every15Minutes.window?.from?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
      schedule.windowEnd = {
        hour: responseSchedule.every15Minutes.window?.to?.hour ?? 0,
        minute: responseSchedule.every15Minutes.window?.to?.minute ?? 0,
        timezone:
          responseSchedule.every15Minutes.window?.to?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
    }

    if (responseSchedule.every30Minutes) {
      schedule.frequency = FrequencyConstant.FREQUENCY_EVERY_30_MIN
      schedule.windowStart = {
        hour: responseSchedule.every30Minutes.window?.from?.hour ?? 0,
        minute: responseSchedule.every30Minutes.window?.from?.minute ?? 0,
        timezone:
          responseSchedule.every30Minutes.window?.from?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
      schedule.windowEnd = {
        hour: responseSchedule.every30Minutes.window?.to?.hour ?? 0,
        minute: responseSchedule.every30Minutes.window?.to?.minute ?? 0,
        timezone:
          responseSchedule.every30Minutes.window?.to?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
    }

    if (responseSchedule.everyHour) {
      schedule.frequency = FrequencyConstant.FREQUENCY_EVERY_HOUR
      schedule.windowStart = {
        hour: responseSchedule.everyHour.window?.from?.hour ?? 0,
        minute: responseSchedule.everyHour.window?.from?.minute ?? 0,
        timezone:
          responseSchedule.everyHour.window?.from?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
      schedule.windowEnd = {
        hour: responseSchedule.everyHour.window?.to?.hour ?? 0,
        minute: responseSchedule.everyHour.window?.to?.minute ?? 0,
        timezone:
          responseSchedule.everyHour.window?.to?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
    }

    if (responseSchedule.every12Hours) {
      schedule.frequency = FrequencyConstant.FREQUENCY_EVERY_12_HOURS
      schedule.windowStart = {
        hour: responseSchedule.every12Hours.time?.hour ?? 0,
        minute: responseSchedule.every12Hours.time?.minute ?? 0,
        timezone:
          responseSchedule.every12Hours.time?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
    }

    if (responseSchedule.daily) {
      schedule.frequency = FrequencyConstant.FREQUENCY_DAILY
      schedule.daysList = responseSchedule.daily
      schedule.windowStart = {
        hour: responseSchedule.daily.time?.hour ?? 0,
        minute: responseSchedule.daily.time?.minute ?? 0,
        timezone:
          responseSchedule.daily.time?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
    }

    if (responseSchedule.weekly) {
      schedule.frequency = FrequencyConstant.FREQUENCY_WEEKLY
      schedule.dayOfWeek = DayOfWeekConstant.getByValue(
        responseSchedule.weekly.day
      )

      schedule.windowStart = {
        hour: responseSchedule.weekly.time?.hour ?? 0,
        minute: responseSchedule.weekly.time?.minute ?? 0,
        timezone:
          responseSchedule.weekly.time?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
    }

    if (responseSchedule.monthly) {
      schedule.frequency = FrequencyConstant.FREQUENCY_MONTHLY
      schedule.dayOfMonth = responseSchedule.monthly.dayOfMonth ?? 0

      schedule.windowStart = {
        hour: responseSchedule.monthly.time?.hour ?? 0,
        minute: responseSchedule.monthly.time?.minute ?? 0,
        timezone:
          responseSchedule.monthly.time?.timezone ??
          TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
    }

    if (responseSchedule.pausedUntil) {
      const pausedUntilMilliseconds =
        responseSchedule.pausedUntil.seconds * 1000
      const time = timestampToMoment(pausedUntilMilliseconds)
      schedule.pausedUntil = pausedUntilMilliseconds

      if (pausedUntilMilliseconds < Date.now()) {
        schedule.firstRun.runNow = true
      } else {
        schedule.firstRun.runNow = false
        schedule.firstRun.runOn.day = time
          .clone()
          .startOf('day')
          .toDate()
          .getTime()
        schedule.firstRun.runOn.time = {
          hour: time.hour(),
          minute: time.minute(),
          timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
        }
      }
    } else {
      schedule.firstRun.runNow = !!responseSchedule.enabled
    }

    return schedule
  }

  private buildRequestPolicy(params: BuildRequestPolicyParams): Plan {
    const {
      policyName,
      schedule,
      iscan,
      snapshotImport,
      skipEbsBackup,
      selectedAssets,
      selectedS3Assets,
      selectedS3AllPath,
      selectedS3SpecificPath,
      policyTags,
      assetType,
      integrityCheck,
      protectNewImmediately,
      vaultLists,
      selectedCloudConnectors,
      selectedEfsAssets,
      selectedEfsAllPath,
      selectedEfsSpecificPath,
      integrityScanOption,
      keepDataCopy,
      scanWithTimestamps,
      isEntropyDetectionEnabled,
    } = params

    // time window
    const hourlyOrLess = new PenchkinSchedule.HourlyOrLess()
    const timeWindow = new PenchkinSchedule.TimeWindow()
    const from = new PenchkinSchedule.Time()
    from.setHour(schedule.windowStart.hour)
    from.setMinute(schedule.windowStart.minute)
    from.setTimezone(schedule.windowStart.timezone)
    const to = new PenchkinSchedule.Time()
    to.setHour(schedule.windowEnd.hour)
    to.setMinute(schedule.windowEnd.minute)
    to.setTimezone(schedule.windowEnd.timezone)
    timeWindow.setFrom(from)
    timeWindow.setTo(to)

    hourlyOrLess.setWindow(timeWindow)

    // for daily select on UI we don't have a select
    // so we select all days
    const daily = new PenchkinSchedule.Daily()
    daily.setSunday(true)
    daily.setMonday(true)
    daily.setTuesday(true)
    daily.setWednesday(true)
    daily.setThursday(true)
    daily.setFriday(true)
    daily.setSaturday(true)
    daily.setTime(from)

    const weekly = new PenchkinSchedule.Weekly()
    weekly.setDay(Number(schedule.dayOfWeek.value))
    weekly.setTime(from)

    const monthly = new PenchkinSchedule.Monthly()
    monthly.setDayOfMonth(schedule.dayOfMonth)
    monthly.setTime(from)

    // final schedule params assignment
    const requestSchedule = new PenchkinSchedule()
    switch (schedule.frequency.value) {
      case FrequencyConstant.FREQUENCY_EVERY_15_MIN.value:
        requestSchedule.setEvery15Minutes(hourlyOrLess)
        break
      case FrequencyConstant.FREQUENCY_EVERY_30_MIN.value:
        requestSchedule.setEvery30Minutes(hourlyOrLess)
        break
      case FrequencyConstant.FREQUENCY_EVERY_HOUR.value:
        requestSchedule.setEveryHour(hourlyOrLess)
        break
      case FrequencyConstant.FREQUENCY_EVERY_12_HOURS.value:
        const every12Hours = new PenchkinSchedule.Every12Hours()
        every12Hours.setTime(from)
        requestSchedule.setEvery12Hours(every12Hours)
        break
      case FrequencyConstant.FREQUENCY_DAILY.value:
        requestSchedule.setDaily(daily)
        break
      case FrequencyConstant.FREQUENCY_WEEKLY.value:
        requestSchedule.setWeekly(weekly)
        break
      case FrequencyConstant.FREQUENCY_MONTHLY.value:
        requestSchedule.setMonthly(monthly)
        break
    }

    // when to start the policy
    if (!schedule.firstRun.runNow) {
      const dayTime = timestampToMoment(schedule.firstRun.runOn.day)
      dayTime.set('hour', schedule.firstRun.runOn.time.hour)
      dayTime.set('minute', schedule.firstRun.runOn.time.minute)

      const timeToStart = new Timestamp()
      timeToStart.fromDate(dayTime.toDate())
      requestSchedule.setPausedUntil(timeToStart)
    } else {
      requestSchedule.setEnabled(new Empty())
    }

    // assets and tags

    const selectorMain: Array<Selector> = []
    const selector = new Selector()
    const criteria = new Selector.Criterion()
    const criteria2 = new Selector.Criterion()
    const criteriaTags = new Selector.Criterion()

    // ec2 and ebs
    const awsListAssets: Array<Selector.Criterion.AwsAsset> = []
    selectedAssets?.forEach((item: AssetWithRelatedAssets<Asset>) => {
      const assetToAdd = new Selector.Criterion.AwsAsset()
      if (item.type === AssetKind.AWS_EBS) {
        assetToAdd.setEbs(item.asset?.awsId)
      }
      if (item.type === AssetKind.AWS_EC2) {
        assetToAdd.setEc2(item.asset?.awsId)
      }
      assetToAdd.setAccountId(item.asset?.awsAccountId)
      assetToAdd.setRegion(item.asset?.awsRegion)
      awsListAssets.push(assetToAdd)
    })

    // s3

    // s3 with all path

    const selectedS3AllPathModels: Array<AssetWithRelatedAssets<Asset>> = []

    selectedS3Assets?.forEach((s3) => {
      selectedS3AllPath?.forEach((assetAllPath) => {
        if (assetAllPath === s3.asset?.id) {
          selectedS3AllPathModels.push(s3)
        }
      })
    })

    selectedS3AllPathModels?.map((s3asset: AssetWithRelatedAssets<Asset>) => {
      const selectorS3AllPath = new Selector()
      const criterionListS3AllPath: Array<Selector.Criterion> = []
      const criterionS3AllPath = new Selector.Criterion()
      const assetsS3 = new Selector.Criterion.Assets()
      const awsS3ListAssets: Array<Selector.Criterion.AwsAsset> = []
      const s3AssetToAdd = new Selector.Criterion.AwsAsset()

      if (s3asset.type === AssetKind.AWS_S3) {
        s3AssetToAdd.setS3(s3asset.asset?.awsId)
      }
      awsS3ListAssets.push(s3AssetToAdd)
      assetsS3.setAwsList(awsS3ListAssets)
      criterionS3AllPath.setAssets(assetsS3)
      criterionListS3AllPath.push(criterionS3AllPath)
      selectorS3AllPath.setCriteriaList(criterionListS3AllPath)
      selectorMain.push(selectorS3AllPath)
    })

    // s3 with specific path

    if (selectedS3SpecificPath && selectedS3SpecificPath?.length > 0) {
      selectedS3SpecificPath?.map((s3asset: SelectSpecificPathsInterface) => {
        const selectorS3SpecificPath = new Selector()
        const criterionS3SPList: Array<Selector.Criterion> = []
        const criterionNameS3SP = new Selector.Criterion()
        const criterionPathS3SP = new Selector.Criterion()

        const assetsS3SP = new Selector.Criterion.Assets()
        const awsAssetListS3SP: Array<Selector.Criterion.AwsAsset> = []
        const awsAssetS3SP = new Selector.Criterion.AwsAsset()

        const awsSubAssetsS3SP = new Selector.Criterion.AwsSubAssets()
        const awsSubAssetListS3SP: Array<Selector.Criterion.AwsSubAsset> = []

        s3asset.selectedPaths?.path?.map((pathName: string) => {
          const awsSubAsset = new Selector.Criterion.AwsSubAsset()
          const s3Object = new Selector.Criterion.AwsSubAsset.S3Object()
          const tree =
            new Selector.Criterion.AwsSubAsset.S3Object.TreeSelector()
          tree.setKey(pathName)
          s3Object.setTree(tree)
          awsSubAsset.setS3Object(s3Object)
          awsSubAssetListS3SP.push(awsSubAsset)
        })
        s3asset.selectedPaths?.prefix?.map((prefixName: string) => {
          const awsSubAsset = new Selector.Criterion.AwsSubAsset()
          const s3Object = new Selector.Criterion.AwsSubAsset.S3Object()
          const tree =
            new Selector.Criterion.AwsSubAsset.S3Object.TreeSelector()
          tree.setPrefix(prefixName)
          s3Object.setTree(tree)
          awsSubAsset.setS3Object(s3Object)
          awsSubAssetListS3SP.push(awsSubAsset)
        })
        s3asset.selectedPaths?.glob?.map((globName: string) => {
          const awsSubAsset = new Selector.Criterion.AwsSubAsset()
          const s3Object = new Selector.Criterion.AwsSubAsset.S3Object()
          const tree =
            new Selector.Criterion.AwsSubAsset.S3Object.TreeSelector()
          tree.setGlob(globName)
          s3Object.setTree(tree)
          awsSubAsset.setS3Object(s3Object)
          awsSubAssetListS3SP.push(awsSubAsset)
        })

        if (s3asset?.asset.asset?.id) {
          awsAssetS3SP.setS3(s3asset?.asset.asset.awsId)
        }

        awsAssetListS3SP.push(awsAssetS3SP)
        assetsS3SP.setAwsList(awsAssetListS3SP)
        criterionNameS3SP.setAssets(assetsS3SP)
        awsSubAssetsS3SP.setAwsList(awsSubAssetListS3SP)
        criterionPathS3SP.setAwsSubAssets(awsSubAssetsS3SP)
        criterionS3SPList.push(criterionNameS3SP)
        criterionS3SPList.push(criterionPathS3SP)
        selectorS3SpecificPath.setCriteriaList(criterionS3SPList)
        selectorMain.push(selectorS3SpecificPath)
      })
    }

    // EFS

    // EFS with all path

    const selectedEfsAllPathModels: Array<AssetWithRelatedAssets<Asset>> = []

    selectedEfsAssets?.forEach((efs) => {
      selectedEfsAllPath?.forEach((assetAllPath) => {
        if (assetAllPath === efs.asset?.id) {
          selectedEfsAllPathModels.push(efs)
        }
      })
    })

    selectedEfsAllPathModels?.map((efsAsset: AssetWithRelatedAssets<Asset>) => {
      const selectorEfsAllPath = new Selector()
      const criterionListEfsAllPath: Array<Selector.Criterion> = []
      const criterionEfsAllPath = new Selector.Criterion()
      const assetsEfs = new Selector.Criterion.Assets()
      const awsEfsListAssets: Array<Selector.Criterion.AwsAsset> = []
      const efsAssetToAdd = new Selector.Criterion.AwsAsset()

      if (efsAsset.type === AssetKind.AWS_EFS) {
        efsAssetToAdd.setEfs(efsAsset.asset?.awsId)
      }
      awsEfsListAssets.push(efsAssetToAdd)
      assetsEfs.setAwsList(awsEfsListAssets)
      criterionEfsAllPath.setAssets(assetsEfs)
      criterionListEfsAllPath.push(criterionEfsAllPath)
      selectorEfsAllPath.setCriteriaList(criterionListEfsAllPath)
      selectorMain.push(selectorEfsAllPath)
    })

    // EFS with specific path

    if (selectedEfsSpecificPath && selectedEfsSpecificPath?.length > 0) {
      selectedEfsSpecificPath?.map((efsAsset: SelectSpecificPathsInterface) => {
        const selectorEfsSpecificPath = new Selector()
        const criterionEfsSPList: Array<Selector.Criterion> = []
        const criterionNameEfsSP = new Selector.Criterion()
        const criterionPathEfsSP = new Selector.Criterion()

        const assetsEfsSP = new Selector.Criterion.Assets()
        const awsAssetListEfsSP: Array<Selector.Criterion.AwsAsset> = []
        const awsAssetEfsSP = new Selector.Criterion.AwsAsset()

        const awsSubAssetsEfsSP = new Selector.Criterion.AwsSubAssets()
        const awsSubAssetListEfsSP: Array<Selector.Criterion.AwsSubAsset> = []

        efsAsset.selectedPaths?.glob?.map((globName: string) => {
          const awsSubAsset = new Selector.Criterion.AwsSubAsset()
          const efsObject = new Selector.Criterion.AwsSubAsset.EfsObject()
          const tree =
            new Selector.Criterion.AwsSubAsset.EfsObject.TreeSelector()
          tree.setGlob(globName)
          efsObject.setTree(tree)
          awsSubAsset.setEfsObject(efsObject)
          awsSubAssetListEfsSP.push(awsSubAsset)
        })

        efsAsset.selectedPaths?.path?.map((pathName: string) => {
          const awsSubAsset = new Selector.Criterion.AwsSubAsset()
          const efsObject = new Selector.Criterion.AwsSubAsset.EfsObject()
          const tree =
            new Selector.Criterion.AwsSubAsset.EfsObject.TreeSelector()
          tree.setPath(pathName)
          efsObject.setTree(tree)
          awsSubAsset.setEfsObject(efsObject)
          awsSubAssetListEfsSP.push(awsSubAsset)
        })

        if (efsAsset?.asset.asset?.id) {
          awsAssetEfsSP.setEfs(efsAsset?.asset.asset.awsId)
        }

        awsAssetListEfsSP.push(awsAssetEfsSP)
        assetsEfsSP.setAwsList(awsAssetListEfsSP)
        criterionNameEfsSP.setAssets(assetsEfsSP)
        awsSubAssetsEfsSP.setAwsList(awsSubAssetListEfsSP)
        criterionPathEfsSP.setAwsSubAssets(awsSubAssetsEfsSP)
        criterionEfsSPList.push(criterionNameEfsSP)
        criterionEfsSPList.push(criterionPathEfsSP)
        selectorEfsSpecificPath.setCriteriaList(criterionEfsSPList)
        selectorMain.push(selectorEfsSpecificPath)
      })
    }

    // tags
    const requestTags = new Selector.Criterion.Tags()
    policyTags?.forEach((tag) =>
      requestTags.getTagsMap().set(tag.name, String(tag.value ?? ''))
    )

    // add assets to the Selector
    if (assetType !== undefined) {
      // all EC2/EBS
      if (assetType == AssetType.ASSET_TYPE_EC2_EBS) {
        selector.setCriteriaList([
          criteria.setIsType(Selector.Criterion.AssetType.ASSET_TYPE_EC2),
          criteria2.setIsType(Selector.Criterion.AssetType.ASSET_TYPE_EBS),
        ])
      }
      if (assetType == AssetType.ASSET_TYPE_EC2) {
        selector.setCriteriaList([
          criteria.setIsType(Selector.Criterion.AssetType.ASSET_TYPE_EC2),
        ])
      }
      if (assetType == AssetType.ASSET_TYPE_EBS) {
        selector.setCriteriaList([
          criteria.setIsType(Selector.Criterion.AssetType.ASSET_TYPE_EBS),
        ])
      }
      if (assetType === AssetType.ASSET_TYPE_S3 && !selectedS3Assets?.length) {
        selector.setCriteriaList([
          criteria.setIsType(Selector.Criterion.AssetType.ASSET_TYPE_S3),
        ])
      }
    } else if (awsListAssets.length > 0) {
      // add specific assets to the Selector
      const request = new Selector.Criterion.Assets()
      request.setAwsList(awsListAssets ?? [])
      criteria.setAssets(request)
      selector.setCriteriaList([criteria])
    }

    // add tags to the Selector
    if (policyTags && policyTags?.length > 0) {
      criteriaTags.setHasTags(requestTags)
      selector.setCriteriaList([...selector.getCriteriaList(), criteriaTags])
    }

    if (selector.getCriteriaList().length > 0) {
      selectorMain.push(selector)
    }

    const integrityCheckValue =
      integrityCheck != undefined
        ? integrityCheck
        : IntegrityCheckOptions.INTEGRITY_CHECK_DISABLED

    // create and adjust the policy
    const requestPolicy = new Plan()
    requestPolicy.setId(policyName)

    //set Scan option
    if (integrityScanOption !== undefined) {
      const planMode = new PlanMode()
      if (
        integrityScanOption === IntegrityScanOptions.BACKUP_WITH_ELASTIO ||
        integrityScanOption ===
          IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO
      ) {
        const backup = new Backup()
        planMode.setBackup(backup)
        //Scan only
      } else {
        const scanOnly = new ScanOnly()
        if (selectedS3Assets && selectedS3Assets?.length) {
          // S3 assets selected
          const s3Option = new ScanOnly.S3()
          // Scan objects with specific time stamps
          if (scanWithTimestamps && scanWithTimestamps.isScanObjects) {
            const dayTime = timestampToMoment(
              scanWithTimestamps.dateTimeData.day
            )
            dayTime.set('hour', scanWithTimestamps.dateTimeData.time.hour)
            dayTime.set('minute', scanWithTimestamps.dateTimeData.time.minute)
            const timeToScan = new Timestamp()
            timeToScan.fromDate(dayTime.toDate())
            s3Option.setUploadedAfter(timeToScan)
          }
          scanOnly.setS3(s3Option)
        } else if (selectedEfsAssets && selectedEfsAssets.length) {
          //EFS assets selected
          const efsOption = new ScanOnly.Efs()
          scanOnly.setEfs(efsOption)
        } else {
          const ec2OrEbs = new ScanOnly.Ec2OrEbs()
          if (keepDataCopy !== undefined) {
            ec2OrEbs.setKeepLastCleanCopy(keepDataCopy.keepLastClean)
            ec2OrEbs.setKeepLatestInfectedCopy(keepDataCopy.keepLatestInfected)
          }
          scanOnly.setEc2OrEbs(ec2OrEbs)
        }

        planMode.setScanOnly(scanOnly)
      }
      requestPolicy.setPlanMode(planMode)
    }

    requestPolicy.setIscan(
      this.setIscanOption({
        iscanOption: iscan,
        isEntropyDetectionEnabled: isEntropyDetectionEnabled,
      })
    )
    requestPolicy.setSnapshotImport(snapshotImport)

    requestPolicy.setSchedule(requestSchedule)
    requestPolicy.setSelectorsList(selectorMain)

    requestPolicy.setIntegrityCheck(integrityCheckValue)

    requestPolicy.setProtectNewImmediately(
      protectNewImmediately || ProtectNewImmediately.DISABLED
    )
    requestPolicy.setSkipEbsBackup(skipEbsBackup)

    if (vaultLists?.length) {
      const vaults = vaultLists.map((v) => {
        const vault = new Vault()
        vault.setRedStackId(v.redStackId)
        vault.setVaultName(v.vaultName)
        return vault
      })
      requestPolicy.setVaultsList(vaults)
    }

    //account/region pairs
    if (selectedCloudConnectors?.length) {
      const accountRegionSelectorsList: Array<PlanAccountRegionSelector> = []
      selectedCloudConnectors.map((cloudConnector: ValueInterface) => {
        const accountRegionSelector = new PlanAccountRegionSelector()
        accountRegionSelector.setAccountId(String(cloudConnector.value))
        accountRegionSelector.setRegion(String(cloudConnector.extraValue))
        accountRegionSelectorsList.push(accountRegionSelector)
      })

      requestPolicy.setAccountRegionSelectorsList(accountRegionSelectorsList)
    }

    return requestPolicy
  }

  async createPlan(params: PolicyRequestParams): Promise<any> {
    const {
      policyName,
      schedule,
      iscan,
      snapshotImport,
      skipEbsBackup,
      selectedAssets,
      selectedS3Assets,
      selectedS3AllPath,
      selectedS3SpecificPath,
      policyTags,
      integrityCheck,
      protectNewImmediately,
      selectedCloudConnectors,
      selectedEfsAssets,
      selectedEfsAllPath,
      selectedEfsSpecificPath,
      integrityScanOption,
      keepDataCopy,
      scanWithTimestamps,
      isEntropyDetectionEnabled,
      selectedVariant,
      vaultsList,
      executeImmediately,
    } = params

    const request = new CreatePlanRequest()
    const assetType = getAssetType(selectedVariant)
    const requestPolicy = this.buildRequestPolicy({
      policyName,
      schedule,
      iscan,
      snapshotImport,
      skipEbsBackup,
      selectedAssets,
      selectedS3Assets,
      selectedS3AllPath,
      selectedS3SpecificPath,
      policyTags,
      assetType,
      integrityCheck,
      protectNewImmediately,
      vaultLists: vaultsList,
      selectedCloudConnectors,
      selectedEfsAssets,
      selectedEfsAllPath,
      selectedEfsSpecificPath,
      integrityScanOption,
      keepDataCopy,
      scanWithTimestamps,
      isEntropyDetectionEnabled,
    })
    request.setPlan(requestPolicy)

    request.setExecuteImmediately(executeImmediately)

    const result = await this.callGrpcService(
      () => this.#pechkinClient.createPlan(request, this.metadata(this.#token)),
      { requestName: 'PechkinPromiseClient/createPlan' }
    )

    return result.toObject()
  }

  async updatePlan(params: PolicyRequestParams): Promise<any> {
    const {
      policyName,
      schedule,
      iscan,
      snapshotImport,
      skipEbsBackup,
      selectedAssets,
      selectedS3Assets,
      selectedS3AllPath,
      selectedS3SpecificPath,
      policyTags,
      integrityCheck,
      protectNewImmediately,
      selectedCloudConnectors,
      selectedEfsAssets,
      selectedEfsAllPath,
      selectedEfsSpecificPath,
      integrityScanOption,
      keepDataCopy,
      scanWithTimestamps,
      isEntropyDetectionEnabled,
      selectedVariant,
      vaultsList,
      executeImmediately,
    } = params

    const request = new UpdatePlanRequest()
    const assetType = getAssetType(selectedVariant)
    const requestPolicy = this.buildRequestPolicy({
      policyName,
      schedule,
      iscan,
      snapshotImport,
      skipEbsBackup,
      selectedAssets,
      selectedS3Assets,
      selectedS3AllPath,
      selectedS3SpecificPath,
      policyTags,
      assetType,
      integrityCheck,
      protectNewImmediately,
      vaultLists: vaultsList,
      selectedCloudConnectors,
      selectedEfsAssets,
      selectedEfsAllPath,
      selectedEfsSpecificPath,
      integrityScanOption,
      keepDataCopy,
      scanWithTimestamps,
      isEntropyDetectionEnabled,
    })

    request.setPlan(requestPolicy)
    request.setExecuteImmediately(executeImmediately)

    const result = await this.callGrpcService(
      () => this.#pechkinClient.updatePlan(request, this.metadata(this.#token)),
      { requestName: 'PechkinPromiseClient/updatePlan' }
    )

    return result.toObject()
  }

  async getPlanInfo(id: string): Promise<PolicyDataResponse> {
    const request = new GetPlanRequest()
    request.setId(id)

    const result = await this.callGrpcService(
      () => this.#pechkinClient.getPlan(request, this.metadata(this.#token)),
      { requestName: 'PechkinPromiseClient/getPlan' }
    )

    const policy = result.toObject()?.planDetail?.plan
    const schedule: ScheduleInterface = this.buildScheduleFromRequest(
      policy?.schedule
    )
    const policyName: string = policy?.id ?? ''

    // uncheck options
    const iscan: IntegrityScanInterface = {
      scanForMalware: false,
      scanForRansomware: false,
    }

    const isEntropyDetectionEnabled =
      policy?.iscan == IscanOptions.FULLANDENTROPYDETECTION ||
      policy?.iscan == IscanOptions.RANSOMWAREANDENTROPYDETECTION

    const snapshotImport = policy?.snapshotImport ?? SnapshotImport.LATEST

    if (policy?.iscan) {
      const shouldSetRansomwareOption =
        policy.iscan == IscanOptions.FULL ||
        policy.iscan == IscanOptions.FULLANDENTROPYDETECTION ||
        policy.iscan == IscanOptions.RANSOMWAREANDENTROPYDETECTION ||
        policy.iscan == IscanOptions.RANSOMWARE

      const shouldSetMalwareOption =
        policy.iscan == IscanOptions.FULL ||
        policy.iscan == IscanOptions.FULLANDENTROPYDETECTION ||
        policy.iscan == IscanOptions.MALWARE

      if (shouldSetRansomwareOption) {
        iscan.scanForRansomware = true
      }

      if (shouldSetMalwareOption) {
        iscan.scanForMalware = true
      }
    }

    let integrityCheck =
      policy?.integrityCheck != undefined
        ? policy.integrityCheck
        : IntegrityCheckOptions.INTEGRITY_CHECK_DISABLED

    // get Scan options
    let integrityScanOption = IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO
    const keepDataCopy: KeepDataCopyInterface = {
      keepLastClean: false,
      keepLatestInfected: false,
    }
    let scanWithTimestamps: ScanWithTimestampsInterface =
      EMPTY_SCAN_WITH_TIMESTAMPS()

    if (policy?.planMode) {
      if (policy?.planMode.backup) {
        if (policy?.iscan != IscanOptions.DISABLED || policy?.integrityCheck) {
          integrityScanOption =
            IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO
        } else {
          integrityScanOption = IntegrityScanOptions.BACKUP_WITH_ELASTIO
          //if only backup is selected, checks are disabled
          iscan.scanForMalware = false
          iscan.scanForRansomware = false
          integrityCheck = IntegrityCheckOptions.INTEGRITY_CHECK_DISABLED
        }
      }

      if (policy?.planMode.scanOnly) {
        const scanWithOptions = policy?.planMode.scanOnly
        integrityScanOption = IntegrityScanOptions.SCAN_WITH_ELASTIO

        if (scanWithOptions.ec2OrEbs) {
          if (scanWithOptions.ec2OrEbs.keepLastCleanCopy !== undefined) {
            keepDataCopy.keepLastClean =
              scanWithOptions.ec2OrEbs.keepLastCleanCopy
            keepDataCopy.keepLatestInfected =
              scanWithOptions.ec2OrEbs.keepLatestInfectedCopy
          }
        }

        if (scanWithOptions.s3 && scanWithOptions.s3.uploadedAfter) {
          scanWithTimestamps = this.buildUploadedAfterFromResponse(
            scanWithOptions.s3.uploadedAfter
          )
        }
      }
    }

    let isType: AssetType | undefined = undefined
    const typesList: Array<Selector.Criterion.AssetType> = []
    policy?.selectorsList.map((selector) => {
      selector.criteriaList.map((criteria) => {
        if (!criteria.hasTags && !criteria.assets && !criteria.awsSubAssets) {
          typesList.push(criteria.isType)
        }
      })
    })

    if (typesList?.length == 2) {
      isType = AssetType.ASSET_TYPE_EC2_EBS
    } else {
      isType = typesList[0] as unknown as AssetType | undefined
    }

    const policyTags: VIRow = []
    policy?.selectorsList.map((selector) => {
      selector.criteriaList.map((criteria) => {
        criteria.hasTags?.tagsMap.map((tag) => {
          policyTags.push({
            name: tag[0],
            value: tag[1] ?? '',
          })
        })
      })
    })

    // Existing policies with tags selectors should be shown as EC2 and EBS
    if (policyTags.length > 0 && isType === undefined) {
      isType = AssetType.ASSET_TYPE_EC2_EBS
    }

    // assets - represented as descriptors
    const ec2Filters: Array<string> = []
    const ebsFilters: Array<string> = []
    const s3Filters: Array<string> = []
    const selectedS3AssetComplex: Array<SelectedS3AssetComplex> = []
    const efsFilters: Array<string> = []
    const selectedEfsAssetComplex: Array<SelectedEfsAssetComplex> = []

    policy?.selectorsList.map((selector) => {
      const subAssets: SelectedS3AssetComplex = {
        selectedAsset: '',
        selectedAllPath: false,
        selectedSpecificPath: {
          path: [],
          prefix: [],
          glob: [],
        },
      }

      const subAssetsEfs: SelectedEfsAssetComplex = {
        selectedAsset: '',
        selectedAllPath: false,
        selectedSpecificPath: {
          path: [],
          prefix: [],
          glob: [],
        },
      }

      selector.criteriaList.map((criteria) => {
        const isSubAssetsExists = criteria.awsSubAssets !== undefined
        criteria.assets?.awsList?.map((aws) => {
          if (aws.ec2) {
            ec2Filters.push(aws.ec2)
          }
          if (aws.ebs) {
            ebsFilters.push(aws.ebs)
          }
          if (aws.s3) {
            s3Filters.push(aws.s3)
            subAssets.selectedAsset = aws.s3
            if (isSubAssetsExists) {
              subAssets.selectedAllPath = false
            } else {
              subAssets.selectedAllPath = true
            }
          }
          if (aws.efs) {
            efsFilters.push(aws.efs)
            subAssetsEfs.selectedAsset = aws.efs
            if (isSubAssetsExists) {
              subAssetsEfs.selectedAllPath = false
            } else {
              subAssetsEfs.selectedAllPath = true
            }
          }
        })

        criteria?.awsSubAssets?.awsList?.map((subAsset) => {
          subAssets.selectedAllPath = false
          subAssetsEfs.selectedAllPath = false

          // s3
          if (subAsset.s3Object?.tree?.key) {
            subAssets.selectedSpecificPath.path?.push(
              subAsset.s3Object?.tree?.key
            )
          }
          if (subAsset.s3Object?.tree?.prefix) {
            subAssets.selectedSpecificPath.prefix?.push(
              subAsset.s3Object?.tree?.prefix
            )
          }
          if (subAsset.s3Object?.tree?.glob) {
            subAssets.selectedSpecificPath.glob?.push(
              subAsset.s3Object?.tree?.glob
            )
          }
          // efs
          if (subAsset.efsObject?.tree?.path) {
            subAssetsEfs.selectedSpecificPath.path?.push(
              subAsset.efsObject?.tree?.path
            )
          }
          if (subAsset.efsObject?.tree?.glob) {
            subAssetsEfs.selectedSpecificPath.glob?.push(
              subAsset.efsObject?.tree?.glob
            )
          }
        })
      })
      selectedS3AssetComplex.push(subAssets)
      selectedEfsAssetComplex.push(subAssetsEfs)
    })

    const protectNewImmediately =
      policy?.protectNewImmediately != undefined
        ? policy.protectNewImmediately
        : ProtectNewImmediately.DISABLED

    const skipEbsBackup =
      policy?.skipEbsBackup !== undefined ? policy.skipEbsBackup : false

    const vaultsList: Array<VaultForPolicy> = policy?.vaultsList ?? []

    const accountRegionSelectorsList: VIRow = []
    policy?.accountRegionSelectorsList?.map((selector) => {
      const regionName = LangHelper.getAwsRegionSingleTranslation(
        selector.region
      )
      const accountRegionLabel = `${selector.accountId} (${regionName})`
      accountRegionSelectorsList.push({
        name: accountRegionLabel,
        label: accountRegionLabel,
        value: selector.accountId,
        extraValue: selector.region,
      })
      accountRegionSelectorsList.push()
    })

    return {
      policyName,
      schedule,
      iscan,
      policyTags,
      ec2Filters,
      ebsFilters,
      s3Filters,
      selectedS3AssetComplex,
      snapshotImport,
      isType,
      integrityCheck,
      protectNewImmediately,
      vaultsList,
      skipEbsBackup,
      accountRegionSelectorsList,
      integrityScanOption,
      keepDataCopy,
      scanWithTimestamps,
      efsFilters,
      selectedEfsAssetComplex,
      isEntropyDetectionEnabled,
    }
  }

  private setIscanOption({
    iscanOption,
    isEntropyDetectionEnabled,
  }: {
    iscanOption: IntegrityScanInterface
    isEntropyDetectionEnabled?: boolean
  }): IscanOptions {
    const runOnlyMalware = {
      condition:
        iscanOption.scanForMalware &&
        !iscanOption.scanForRansomware &&
        !isEntropyDetectionEnabled,
      value: IscanOptions.MALWARE,
    }
    const runOnlyRansomware = {
      condition:
        iscanOption.scanForRansomware &&
        !iscanOption.scanForMalware &&
        !isEntropyDetectionEnabled,
      value: IscanOptions.RANSOMWARE,
    }

    const runNoScan = {
      condition:
        !iscanOption.scanForMalware &&
        !iscanOption.scanForRansomware &&
        !isEntropyDetectionEnabled,
      value: IscanOptions.DISABLED,
    }

    const runScanWithoutEntropy = {
      condition:
        iscanOption.scanForMalware &&
        iscanOption.scanForRansomware &&
        !isEntropyDetectionEnabled,
      value: IscanOptions.FULL,
    }

    const runFullScanWithEntropy = {
      condition:
        iscanOption.scanForMalware &&
        iscanOption.scanForRansomware &&
        isEntropyDetectionEnabled,
      value: IscanOptions.FULLANDENTROPYDETECTION,
    }

    const runScanWithRansomwareAndEntropy = {
      condition:
        !iscanOption.scanForMalware &&
        iscanOption.scanForRansomware &&
        isEntropyDetectionEnabled,
      value: IscanOptions.RANSOMWAREANDENTROPYDETECTION,
    }

    return (
      [
        runOnlyMalware,
        runOnlyRansomware,
        runNoScan,
        runScanWithoutEntropy,
        runFullScanWithEntropy,
        runScanWithRansomwareAndEntropy,
      ].find((item) => item.condition)?.value ?? IscanOptions.DISABLED
    )
  }

  private buildUploadedAfterFromResponse(
    responseUploadedAfter?: Timestamp.AsObject
  ): ScanWithTimestampsInterface {
    const scanWithTimestamps: ScanWithTimestampsInterface =
      EMPTY_SCAN_WITH_TIMESTAMPS()

    if (!responseUploadedAfter) {
      return scanWithTimestamps
    }

    if (responseUploadedAfter) {
      scanWithTimestamps.isScanObjects = true
      const time = timestampToMoment(responseUploadedAfter.seconds * 1000)
      scanWithTimestamps.dateTimeData.day = time
        .clone()
        .startOf('day')
        .toDate()
        .getTime()
      scanWithTimestamps.dateTimeData.time = {
        hour: time.hour(),
        minute: time.minute(),
        timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
      }
    }

    return scanWithTimestamps
  }

  async getPlanInfoWithSelectedAssets(
    id: string
  ): Promise<PolicyInfoWithSelectedAssets> {
    const policyInfo = await this.getPlanInfo(id)

    const assetsClient = new AssetsClient({ token: this.#token })

    const liveAssets = await assetsClient.listLiveAssets()
    const elastioBackups = liveAssets.lastElastioBackupsMap
    const inventoryAttrs = {
      instances: liveAssets.ec2Instances,
      volumes: liveAssets.ebsVolumes,
      s3Buckets: liveAssets.s3Buckets,
      efs: liveAssets.efs,
      lastBackups: elastioBackups,
    }
    const inventory = new ListAssetsForPolicyCoverage(inventoryAttrs)

    let ec2Assets: AssetsForPolicyCoverage = []
    if (policyInfo.ec2Filters.length > 0) {
      ec2Assets = inventory.execute({ instanceIds: policyInfo.ec2Filters })
    }

    let ebsAssets: AssetsForPolicyCoverage = []
    if (policyInfo.ebsFilters.length > 0) {
      ebsAssets = inventory.execute({ volumeIds: policyInfo.ebsFilters })
    }

    let s3Assets: AssetsForPolicyCoverage = []
    if (policyInfo.s3Filters?.length > 0) {
      s3Assets = inventory.execute({ instanceIds: policyInfo.s3Filters })
    }

    s3Assets?.map((s3: AssetWithRelatedAssets<Asset>) => {
      policyInfo.selectedS3AssetComplex.map(
        (s3SelectedAsset: SelectedS3AssetComplex) => {
          if (s3SelectedAsset.selectedAsset === s3.asset?.awsId) {
            s3SelectedAsset.assetModel = s3
          }
        }
      )
    })

    let efsAssets: AssetsForPolicyCoverage = []
    if (policyInfo.efsFilters?.length > 0) {
      efsAssets = inventory.execute({ instanceIds: policyInfo.efsFilters })
    }

    efsAssets?.map((efs: AssetWithRelatedAssets<Asset>) => {
      policyInfo.selectedEfsAssetComplex.map(
        (efsSelectedAsset: SelectedEfsAssetComplex) => {
          if (efsSelectedAsset.selectedAsset === efs.asset?.awsId) {
            efsSelectedAsset.assetModel = efs
          }
        }
      )
    })

    return {
      policyName: policyInfo.policyName,
      schedule: policyInfo.schedule,
      iscan: policyInfo.iscan,
      policyTags: policyInfo.policyTags,
      snapshotImportVariant: policyInfo.snapshotImport,
      isType: policyInfo.isType,
      integrityCheck: policyInfo.integrityCheck,
      protectNewImmediately: policyInfo.protectNewImmediately,
      vaultsList: policyInfo.vaultsList ?? [],
      skipEbsBackup: policyInfo.skipEbsBackup ?? false,
      accountRegionSelectorsList: policyInfo.accountRegionSelectorsList ?? [],
      // added extra
      selectedAssets: [...ec2Assets, ...ebsAssets],
      selectedS3Assets: s3Assets,
      selectedS3AssetComplex: policyInfo.selectedS3AssetComplex,
      selectedEfsAssets: efsAssets,
      selectedEfsAssetComplex: policyInfo.selectedEfsAssetComplex,
      integrityScanOption: policyInfo.integrityScanOption,
      keepDataCopy: policyInfo.keepDataCopy,
      scanWithTimestamps: policyInfo.scanWithTimestamps,
      isEntropyDetectionEnabled: policyInfo.isEntropyDetectionEnabled,
    }
  }
}
