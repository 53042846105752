import React from 'react'
import ConfirmationDialog from 'ui-v2/src/components/dialogs/confirmation'
import { WebhookAction } from './column-config'
import {
  useDeleteWebhookMutation,
  useTestWebhookMutation,
} from 'ui-v2/src/hooks/queries/webhooks'
import { useToast } from 'ui-v2/src/hooks/toast'

interface WebhookActionConfirmationProps {
  action: WebhookAction | undefined
  setAction: React.Dispatch<React.SetStateAction<WebhookAction | undefined>>
}

type DialogConfig = {
  title: string
  message: string
  confirmText: string
  action: () => void
  isLoading: boolean
}

const WebhookActionConfirmation: React.FC<WebhookActionConfirmationProps> = ({
  action,
  setAction,
}) => {
  const toast = useToast()
  const { mutate: deleteWebhookMutate, isPending: isDeletingWebhook } =
    useDeleteWebhookMutation()
  const { mutate: testWebhookMutate, isPending: isTestingWebhook } =
    useTestWebhookMutation()

  const closeDialog = () => {
    setAction(undefined)
  }

  const getDialogConfig = (selectedAction: WebhookAction): DialogConfig => {
    const configs = {
      delete: {
        title: 'Delete Webhook',
        message: `Are you sure you want to delete ${selectedAction.row.name} webhook? This action cannot be undone.`,
        confirmText: 'Delete',
        action: () =>
          deleteWebhookMutate(selectedAction.row.id, {
            onSuccess: closeDialog,
          }),
        isLoading: isDeletingWebhook,
      },
      'test-webhook': {
        title: 'Test Webhook',
        message: `Are you sure you want to test this ${selectedAction.row.name} webhook?`,
        confirmText: 'Test Webhook',
        action: () =>
          testWebhookMutate(
            { id: selectedAction.row.id },
            {
              onError: (error) => {
                toast.error(
                  error.message ?? 'Something went wrong while testing webhook'
                )
              },
              onSuccess: (data) => {
                closeDialog()
                if (data.success) {
                  toast.success('Webhook test is successful')
                } else {
                  toast.error('Webhook test failed')
                }
              },
            }
          ),
        isLoading: isTestingWebhook,
      },
    }

    return configs[selectedAction.type]
  }

  if (!action) {
    return null
  }

  const config = getDialogConfig(action)

  return (
    <ConfirmationDialog
      open={!!action}
      onClose={closeDialog}
      onConfirm={config.action}
      title={config.title}
      confirmText={config.confirmText}
      isLoading={config.isLoading}
    >
      {config.message}
    </ConfirmationDialog>
  )
}

export default WebhookActionConfirmation
