/* eslint-disable import/no-extraneous-dependencies */
import { useAssetsGenericHostsQuery } from 'ui-v2/src/hooks/queries/assets'
import { Backup, GenericHost, Nullable, Threat } from 'blues-corejs/dist'
import { Scan } from '../scans'
import { AssetItemsData } from './asset-items'

export interface ListGenericHostFilters {
  hostnameList?: Array<string>
  search?: string
  pageSize?: number
  pageToken?: string
}

export type AssetsGenericHostData =
  | NonNullable<
      ReturnType<typeof useAssetsGenericHostsQuery>['data']
    >['hostsList']
  | undefined

export interface AssetsGenericHostAttrs {
  genericHosts: Array<GenericHost>
  threats: Array<Threat>
  assetItemsData: AssetItemsData
  scans: Array<Scan>
}

export interface AssetsGenericHostDrawerAttrs extends AssetsGenericHostAttrs {
  lastBackups: Map<string, Backup>
}

interface BaseGenericHostAttrs {
  id: string
  name: string
  threats: number
  isUnhealthy: boolean
}

export abstract class BaseGenericHost {
  readonly #id: string

  readonly #name: string

  readonly #threats: number

  readonly #isUnhealthy: boolean

  constructor(parameters: BaseGenericHostAttrs) {
    const { id, name, threats, isUnhealthy } = parameters

    this.#id = id
    this.#name = name
    this.#threats = threats
    this.#isUnhealthy = isUnhealthy
  }

  get id(): string {
    return this.#id
  }

  get name(): string {
    return this.#name
  }

  get threats(): number {
    return this.#threats
  }

  get isUnhealthy(): boolean {
    return this.#isUnhealthy
  }
}

export class GenericHostTableRow extends BaseGenericHost {
  readonly #lastScanDate: Nullable<Date>

  constructor(parameters: GenericHostTableRowAttrs) {
    const { lastScanDate, ...baseParams } = parameters
    super(baseParams)

    this.#lastScanDate = lastScanDate
  }

  get lastScanDate(): Nullable<Date> {
    return this.#lastScanDate
  }
}

export class GenericHostDrawer extends BaseGenericHost {
  readonly #lastScan: Nullable<Scan>

  readonly #lastBackup: Nullable<Backup>

  readonly assetKind: string = 'Generic Host'

  constructor(parameters: GenericHostDrawerAttrs) {
    const { lastScan, lastBackup, ...baseParams } = parameters
    super(baseParams)

    this.#lastScan = lastScan
    this.#lastBackup = lastBackup
  }

  get awsId(): undefined {
    return undefined
  }

  get awsAccountId(): undefined {
    return undefined
  }

  get awsRegion(): undefined {
    return undefined
  }

  get state(): undefined {
    return undefined
  }

  get lastScan(): Nullable<Scan> {
    return this.#lastScan
  }

  get lastBackup(): Nullable<Backup> {
    return this.#lastBackup
  }
}

interface GenericHostTableRowAttrs extends BaseGenericHostAttrs {
  lastScanDate: Nullable<Date>
}

interface GenericHostDrawerAttrs extends BaseGenericHostAttrs {
  lastScan: Nullable<Scan>
  lastBackup: Nullable<Backup>
}
