interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

const CircleRejectIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        x="2.33333"
        y="2.33333"
        width="19.3333"
        height="19.3333"
        rx="9.66667"
        fill="#BA0B0B"
      />
      <rect
        x="2.33333"
        y="2.33333"
        width="19.3333"
        height="19.3333"
        rx="9.66667"
        stroke="#BA0B0B"
        stroke-width="0.666667"
      />
      <path
        d="M12.8317 6.75781L12.6794 13.2769H11.3717L11.2131 6.75781H12.8317ZM11.1686 15.2764C11.1686 15.0436 11.2448 14.849 11.3971 14.6924C11.5537 14.5316 11.7695 14.4512 12.0446 14.4512C12.3154 14.4512 12.5291 14.5316 12.6857 14.6924C12.8423 14.849 12.9206 15.0436 12.9206 15.2764C12.9206 15.5007 12.8423 15.6932 12.6857 15.854C12.5291 16.0106 12.3154 16.0889 12.0446 16.0889C11.7695 16.0889 11.5537 16.0106 11.3971 15.854C11.2448 15.6932 11.1686 15.5007 11.1686 15.2764Z"
        fill="white"
      />
    </svg>
  )
}

export default CircleRejectIcon
