import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import { useMutation } from '@tanstack/react-query'

export function useDiscoverInstalledCfnQuery() {
  const rexClient = useGrpcClient(RexClient)

  return useMutation({
    mutationFn: async (accountId: string) => {
      if (!rexClient) {
        throw new Error('rexClient client not initialized')
      }
      return rexClient.discoverInstalledCfn(accountId)
    },
  })
}
