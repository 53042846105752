const CloudCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clipPath="url(#clip0_2636_7483)">
      <path
        d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM16.5 16.5H8C6.34 16.5 5 15.16 5 13.5C5 11.84 6.34 10.5 8 10.5H8.14C8.58 8.77 10.13 7.5 12 7.5C14.21 7.5 16 9.29 16 11.5H16.5C17.88 11.5 19 12.62 19 14C19 15.38 17.88 16.5 16.5 16.5Z"
        fill="#B2B5C1"
      />
    </g>
    <defs>
      <clipPath id="clip0_2636_7483">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default CloudCircleIcon
