import React, { useState, useMemo } from 'react'
import {
  TablePagination,
  SxProps,
  Theme,
  TablePaginationSlots,
  SlotProps,
} from '@mui/material'
import BaseTable, { BaseTableProps, BaseTableStyles } from './base-table'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import { mergeSx } from './util'
import { TableCellProps } from '@mui/material/TableCell'
import { ToolbarProps } from '@mui/material/Toolbar'
import { SelectProps } from '@mui/material/Select'
import { MenuItemProps } from '@mui/material/MenuItem'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import {
  TablePaginationActionsSlotPropsOverrides,
  TablePaginationDisplayedRowsSlotPropsOverrides,
  TablePaginationMenuItemSlotPropsOverrides,
  TablePaginationOwnerState,
  TablePaginationRootSlotPropsOverrides,
  TablePaginationSelectLabelSlotPropsOverrides,
  TablePaginationSelectSlotPropsOverrides,
  TablePaginationSpacerSlotPropsOverrides,
  TablePaginationToolbarSlotPropsOverrides,
} from '@mui/material/TablePagination/TablePagination'

interface SlotPropsInterface {
  root: SlotProps<
    React.ElementType<TableCellProps>,
    TablePaginationRootSlotPropsOverrides,
    TablePaginationOwnerState
  >
  toolbar: SlotProps<
    React.ElementType<ToolbarProps>,
    TablePaginationToolbarSlotPropsOverrides,
    TablePaginationOwnerState
  >
  spacer: SlotProps<
    'div',
    TablePaginationSpacerSlotPropsOverrides,
    TablePaginationOwnerState
  >
  selectLabel: SlotProps<
    'p',
    TablePaginationSelectLabelSlotPropsOverrides,
    TablePaginationOwnerState
  >
  select: Partial<SelectProps> & TablePaginationSelectSlotPropsOverrides
  menuItem: SlotProps<
    React.ElementType<MenuItemProps>,
    TablePaginationMenuItemSlotPropsOverrides,
    TablePaginationOwnerState
  >
  displayedRows: SlotProps<
    'p',
    TablePaginationDisplayedRowsSlotPropsOverrides,
    TablePaginationOwnerState
  >
  actions: TablePaginationActionsProps['slotProps'] &
    TablePaginationActionsSlotPropsOverrides
}

interface ClientTableProps<T extends Record<string, any>>
  extends Omit<BaseTableProps<T>, 'footer' | 'data'> {
  data: Array<T>
  initialPageSize?: number
  clearRows?: boolean
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
  styles?: BaseTableStyles & {
    pagination?: SxProps<Theme>
  }
  slots?: Partial<TablePaginationSlots>
  rowsPerPageOptions?: Array<number>
  multiSort?: boolean
  slotProps?: Partial<SlotPropsInterface>
}

function ClientTable<T extends Record<string, any>>({
  data,
  initialPageSize = TABLE_ROWS_PER_PAGE_OPTIONS[0],
  clearRows = true,
  onPageChange,
  onPageSizeChange,
  styles = {},
  slots,
  slotProps,
  rowsPerPageOptions,
  ...baseProps
}: ClientTableProps<T>) {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(initialPageSize)

  const paginatedData = useMemo(() => {
    const startIndex = page * pageSize
    return data.slice(startIndex, startIndex + pageSize)
  }, [data, page, pageSize])

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
    onPageChange?.(newPage)
    if (clearRows) {
      baseProps.onSelectRows?.([])
    }
  }

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageSize = parseInt(e.target.value, 10)
    setPage(0)
    setPageSize(newPageSize)
    onPageSizeChange?.(newPageSize)
    baseProps.onSelectRows?.([])
  }

  const paginationComponent = (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions ?? TABLE_ROWS_PER_PAGE_OPTIONS}
      component="div"
      count={data.length}
      page={page}
      onPageChange={handlePageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleRowsPerPageChange}
      slots={slots}
      slotProps={slotProps}
      sx={mergeSx(
        {
          '& .MuiTablePagination-toolbar': {
            minHeight: '43px !important',
            height: '43px',
            backgroundColor: (theme) => theme.palette.background.paper,
          },
        },
        styles.pagination
      )}
    />
  )

  // eslint-disable-next-line
  const { pagination: _, ...baseTableStyles } = styles

  return (
    <BaseTable
      {...baseProps}
      data={paginatedData}
      footer={paginationComponent}
      styles={baseTableStyles}
    />
  )
}

export default ClientTable
