/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { Asset, Threat } from 'blues-corejs/dist'
import { AssetByThreat } from 'ui-v2/src/lib/models/assets/asset-items'
import { useAssetsListByIdQuery } from 'ui-v2/src/hooks/queries/assets'

export function useThreatsMappedByAsset(threats: Array<Threat>) {
  const assetIds = useMemo(() => {
    const uniqueAssetIds = new Set<string>()
    threats.forEach(({ assetId }) => {
      if (assetId) {
        uniqueAssetIds.add(assetId)
      }
    })
    return [...uniqueAssetIds]
  }, [threats])

  const { data: assetsList, isLoading } = useAssetsListByIdQuery(assetIds)

  const assetByThreat = useMemo(() => {
    if (!assetsList) {
      return new Map<Threat, Asset>()
    }

    const assetByThreatMap: AssetByThreat = new Map()

    for (const threat of threats) {
      const assetFromResponse = assetsList.assetsList.find((assetFromList) => {
        const possibleAssets = [
          assetFromList.ebsVolume,
          assetFromList.ec2Instance,
          assetFromList.s3Bucket,
          assetFromList.efsFilesystem,
          assetFromList.genericHost,
          assetFromList.ovaServer,
        ]
        return possibleAssets.some(
          (asset): asset is Asset => asset?.id === threat.assetId
        )
      })

      if (!assetFromResponse) {
        continue
      }

      const asset = [
        assetFromResponse.ebsVolume,
        assetFromResponse.ec2Instance,
        assetFromResponse.s3Bucket,
        assetFromResponse.efsFilesystem,
        assetFromResponse.genericHost,
        assetFromResponse.ovaServer,
      ].find(
        (assetFromList): assetFromList is Asset =>
          assetFromList?.id === threat.assetId
      )

      if (asset) {
        assetByThreatMap.set(threat, asset)
      }
    }

    return assetByThreatMap
  }, [assetsList, threats])

  return {
    assetByThreat,
    isLoading,
  }
}
