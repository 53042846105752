import React from 'react'
import Box from '@mui/material/Box'

export interface ListItems {
  id: string
  text: string
  innerItems?: Array<ListItems>
}

interface ListComponentsProps {
  wrapperComponent: 'ol' | 'ul'
  items: Array<ListItems>
}

const ListComponents = ({ items, wrapperComponent }: ListComponentsProps) => {
  const listStyleType = wrapperComponent === 'ol' ? 'decimal' : 'disc'
  const fontWeight = wrapperComponent === 'ol' ? '700' : '500'
  const color = wrapperComponent === 'ol' ? 'var(--grey200)' : 'var(--grey400)'

  return (
    <Box
      component={wrapperComponent}
      sx={{
        listStyleType,
        fontWeight,
        color,
        fontSize: '14px',
      }}
      mb={1}
    >
      {items.map((item) => {
        return (
          <React.Fragment key={item.id}>
            <Box component={'li'} mb={1}>
              {item.text}
            </Box>
            {item?.innerItems && (
              <ListComponents wrapperComponent={'ul'} items={item.innerItems} />
            )}
          </React.Fragment>
        )
      })}
    </Box>
  )
}

export default ListComponents
