/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from './grpc'
import {
  AssetAccountPromiseClient,
  ListAssetAccountsRequest,
  GetAccountLevelCloudFormationLinkRequest,
} from '../grpc'
import { ClientConfig } from '../models/client'
import { AssetAccount } from 'blues-corejs/dist'
import { AssetAccountsTransformer } from '../transformers/rex/asset-accounts'
import {
  LinkAssetAccountRequest,
  LinkAssetAccountResponse,
} from 'blue-stack-libs/rex-grpc-libs/js/rex/messages/asset_account/link_asset_account_pb'

export class AssetAccountsClient extends GrpcClient<AssetAccountPromiseClient> {
  #assetAccountClient: AssetAccountPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#assetAccountClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'AssetAccountsClient'
  }

  protected initClient(hostName: string): AssetAccountPromiseClient {
    return new AssetAccountPromiseClient(hostName, null, null)
  }

  async listAssetAccounts(): Promise<Array<AssetAccount>> {
    const request = new ListAssetAccountsRequest()
    const response = await this.callGrpcService(
      () =>
        this.#assetAccountClient.listAssetAccounts(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'AssetAccountPromiseClient/listAssetAccounts',
      }
    )

    return response.getAssetAccountsList().map((assetAccount) => {
      return new AssetAccountsTransformer(assetAccount).transform()
    })
  }

  async getAccountLevelCloudFormationLinkRequest(
    awsAccountId: string
  ): Promise<string> {
    const request =
      new GetAccountLevelCloudFormationLinkRequest().setConnectorAwsAccountId(
        awsAccountId
      )
    const response = await this.callGrpcService(
      () =>
        this.#assetAccountClient.getAccountLevelCloudFormationLink(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName:
          'AssetAccountPromiseClient/getAccountLevelCloudFormationLink',
      }
    )

    return response.getLink()
  }

  async linkAssetAccount(
    connectorAccountId: string,
    assetAccountId: string
  ): Promise<LinkAssetAccountResponse> {
    const request = new LinkAssetAccountRequest()
      .setConnectorAccountId(connectorAccountId)
      .setAssetAccountId(assetAccountId)
    const response = await this.callGrpcService(
      () =>
        this.#assetAccountClient.linkAssetAccount(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'AssetAccountPromiseClient/linkAssetAccount',
      }
    )

    return response
  }
}
