import {
  Column,
  RowAction,
} from 'ui-v2/src/components/ui/data-table/base-table'
import {
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { WebhooksListItem } from 'ui-v2/src/lib/grpc'
import { Delete, Edit, PlayArrow } from '@mui/icons-material'
import { NavigateFunction } from 'react-router-dom'

export type ActionType = 'delete' | 'test-webhook'

export type WebhookAction = {
  type: ActionType
  row: WebhooksListItem.AsObject
}

export const getWebhookColumns = (): Array<
  Column<WebhooksListItem.AsObject>
> => [
  {
    key: 'name',
    header: 'Webhook Name',
  },
  {
    key: 'endpoint',
    header: 'Endpoint URL',
  },
  {
    key: 'description',
    header: 'Description',
  },
]

export const getWebhookRowActions = (
  setAction: React.Dispatch<React.SetStateAction<WebhookAction | undefined>>,
  navigate: NavigateFunction
): Array<RowAction<WebhooksListItem.AsObject>> => {
  const iconStyles = {
    color: 'icon.dark',
  }

  return [
    {
      label: 'Edit',
      icon: <Edit sx={iconStyles} />,
      onClick: (row) => {
        navigate(
          `/settings/${SETTINGS_ROUTE_SEGMENTS.WEBHOOKS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.EDIT_WEBHOOK}/${row.id}`
        )
      },
    },
    {
      label: 'Test Webhook',
      icon: <PlayArrow sx={iconStyles} />,
      onClick: (row) => {
        setAction({
          type: 'test-webhook',
          row,
        })
      },
    },
    {
      label: 'Delete',
      icon: <Delete sx={iconStyles} />,
      onClick: (row) => {
        setAction({
          type: 'delete',
          row,
        })
      },
    },
  ]
}
