import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/user/user-slice'
import assetDetailsSourceReducer from './features/navigation/asset-details-source-path-slice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    assetDetailsSource: assetDetailsSourceReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
