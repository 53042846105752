import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { Box, useTheme } from '@mui/material'

type ChartData = {
  value: number | string
  name: string
  itemStyle?: { color: string }
}

type DoughnutChartProps = {
  title: string
  count: number
  data: Array<ChartData>
  onClick?: (componentIndex: number) => void
  onChartLegendClick?: (legendName: string | undefined) => void
  graphicPosition?: {
    top?: string
    bottom?: string
    left?: string
    right?: string
  }
}

interface LegendSelectChangedEvent {
  name: string
}

function DoughnutChart({
  title,
  count,
  data,
  graphicPosition,
  onClick,
  onChartLegendClick,
}: DoughnutChartProps) {
  const theme = useTheme()
  const chartRef = useRef<echarts.ECharts | null>(null)
  const chartElementRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current && chartElementRef.current) {
      chartRef.current = echarts.init(chartElementRef.current)

      chartRef.current.on('click', (params) => {
        onClick?.(params.dataIndex)
      })

      chartRef.current.on('legendselectchanged', (event) => {
        const params = event as LegendSelectChangedEvent
        onChartLegendClick?.(params.name)
      })
    }

    const handleResize = () => {
      chartRef.current?.resize()
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      chartRef.current?.dispose()
      chartRef.current = null
    }
  }, [])

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const option = {
      title: {
        top: '25%',
        left:
          count < 10
            ? '26.5%'
            : count < 100
              ? '25%'
              : count < 1000
                ? '23%'
                : '21%',
        subtext: count ? `${count}` : '0',
        subtextStyle: {
          fontSize: 22,
          fontWeight: '600',
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
          textAlign: 'center',
          textVerticalAlign: 'middle',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params: ChartData) {
          return `${params.name.split(' ')[0]}: ${params.value}%`
        },
      },
      legend: {
        orient: 'vertical',
        right: '0%',
        top: 'center',
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#727883',
          fontSize: 12,
          overflow: 'truncate',
          width: 140,
          rich: {
            bold: {
              fontWeight: 'bold',
            },
          },
        },
        itemWidth: 6,
        itemHeight: 6,
        itemStyle: {
          borderRadius: 5,
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['68%', '60%'],
          center: ['30%', '50%'],
          avoidLabelOverlap: false,
          padAngle: 3,
          itemStyle: {
            borderRadius: 10,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            scale: false,
            label: {
              show: false,
              fontSize: 14,
              fontWeight: '500',
            },
            itemStyle: {
              color: 'inherit',
              opacity: 0.7,
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          labelLine: {
            show: false,
          },
          data,
        },
      ],
      graphic: [
        {
          type: 'group',
          left: graphicPosition?.left ?? '17%',
          top: graphicPosition?.top ?? '45%',
          children: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              style: {
                text: title,
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 18,
                textAlign: 'center',
                textVerticalAlign: 'middle',
                fill: theme.palette.mode === 'dark' ? '#b7bbc4' : '#727883',
              },
            },
          ],
        },
      ],
    }

    chartRef.current.setOption(option)
  }, [title, count, data, theme.palette.mode])

  return (
    <Box
      ref={chartElementRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '240px',
        maxWidth: 350,
        margin: '0 auto',
      }}
    ></Box>
  )
}

export default React.memo(DoughnutChart)
