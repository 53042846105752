/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import { CloudConnectorStatus } from 'blues-corejs/dist'
import { useNavigate, useParams } from 'react-router-dom'
import { List, ListItem, Typography, Box } from '@mui/material'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { useDeploymentStore } from '../store/deployment-store'
import { RexClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useToast } from 'ui-v2/src/hooks/toast'

const UnlinkCloudConnector = () => {
  const { action } = useParams()
  const isDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.UNLINK_CLOUD_CONNECTOR
  const navigate = useNavigate()
  const toast = useToast()
  const { cloudConnectors, rowData, setRowData } = useDeploymentStore()
  const rexClientGRPC = useGrpcClient(RexClient)
  const [isLoading, setIsLoading] = useState(false)

  const handleCloseDrawer = () => {
    setRowData(null)
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
  }

  const handleUnlink = async () => {
    if (!rexClientGRPC || !rowData) {
      return
    }
    const { redStackIds } = rowData
    const requests: Array<Promise<any>> = []

    setIsLoading(true)
    cloudConnectors.forEach((model) => {
      const { redStackId } = model

      if (redStackIds.includes(redStackId)) {
        let baseRequest = Promise.resolve(true)

        if (
          CloudConnectorStatus.ACTIVE === model.status ||
          CloudConnectorStatus.UPGRADE_REQUIRED === model.status
        ) {
          baseRequest = rexClientGRPC.deactivate(redStackId)
        }
        requests.push(baseRequest.then(() => rexClientGRPC.delete(redStackId)))
      }
    })

    Promise.all(requests)
      .then(() => {
        toast.success('Cloud Connector has been disconnected')
      })
      .catch((error) => {
        toast.error(error.message ?? 'Failed to unlink')
      })
      .finally(() => {
        setIsLoading(false)
        handleCloseDrawer()
      })
  }

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      style={{ maxWidth: '640px' }}
    >
      <DrawerContent>
        <List
          sx={{
            paddingX: 0,
          }}
        >
          <ListItem
            sx={{
              paddingX: '24px',
            }}
            secondaryAction={[
              <Button variant="contained" onClick={handleCloseDrawer}>
                Close
              </Button>,
            ]}
          >
            <Typography fontWeight={700} fontSize={22}>
              Disconnect Cloud Connector
            </Typography>
          </ListItem>
        </List>
        <Box
          sx={{
            paddingX: '24px',
            paddingBottom: 2.5,
            position: 'relative',
          }}
        >
          <Typography
            variant="body1"
            style={{
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '180%',
              marginBottom: '20px',
            }}
          >
            If you no longer wish to continue scanning in an AWS account, you
            can disconnect it from your Elastio tenant. This process removes the
            account’s association with Elastio, but all data within the account
            remains unchanged. If you want to remove all Elastio-related AWS
            configurations and data step 2 is required.
          </Typography>
          <Typography
            style={{
              fontSize: '14px',
              height: '29px',
              fontWeight: 600,
              lineHeight: '180%',
              marginBottom: '8px',
            }}
          >
            1. Disconnect Account
          </Typography>
          <Typography mb={'8px'} fontSize={'14px'}>
            Before proceeding, review the effects of disconnecting this AWS
            account:
          </Typography>
          <Typography
            component="ul"
            sx={{
              listStyleType: 'disc',
              pl: 2,
              fontSize: '14px',
            }}
          >
            <Typography component="li" fontSize={'14px'}>
              This account will no longer be linked to your Elastio console.
            </Typography>
            <Typography component="li" fontSize={'14px'}>
              Scanning and protection services will stop immediately for this
              account.
            </Typography>
            <Typography component="li" fontSize={'14px'}>
              No meta-data will be deleted—only the connection to Elastio is
              removed.
            </Typography>
          </Typography>
          <Box display="flex" justifyContent="space-between" m={'20px 0'}>
            <Button
              variant="contained"
              onClick={handleUnlink}
              isLoading={isLoading}
            >
              Disconnect
            </Button>
          </Box>
          <Typography
            style={{
              fontSize: '14px',
              height: '29px',
              fontWeight: 600,
              lineHeight: '180%',
              marginBottom: '8px',
            }}
          >
            2. Cleanup AWS Resources (Optional)
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '180%',
              marginBottom: '20px',
            }}
          >
            if you would like to remove all Elastio-related resources from your
            AWS account, create a ticket in our support portal,{' '}
            <a
              href="https://support.elastio.com/hc/en-us/requests/new"
              target="_blank"
              rel="noopener"
              style={{
                color: 'var(--blue500)',
                textDecoration: 'none',
              }}
            >
              support.elastio.com.
            </a>
          </Typography>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}

export default UnlinkCloudConnector
