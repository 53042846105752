/* eslint-disable import/no-extraneous-dependencies */
import { useAssetsOvaServerQuery } from 'ui-v2/src/hooks/queries/assets'
import { BACKUP_VENDOR_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { OvaBackupProviderProto } from 'ui-v2/src/lib/grpc'
import { HealthState } from 'blue-stack-libs/api-grpc-libs/js/api/search/filters/common_filter_pb'
import {
  Backup,
  Nullable,
  OvaAccount,
  OvaBackup,
  OvaServer,
} from 'blues-corejs/dist'
import { Scan } from '../scans'
import { LastScanState } from './common'

export interface ListOvaServerFilters {
  accountIdsList?: Array<string>
  backupProvidersList?: Array<OvaBackupProviderProto>
  statusList: Array<0 | 1>
  search?: string
  pageSize?: number
  pageToken?: string
  health?: Array<HealthState>
  lastScan?: Array<LastScanState>
}

export type AssetsOvaServerData =
  | NonNullable<ReturnType<typeof useAssetsOvaServerQuery>['data']>['servers']
  | undefined

export interface Server {
  hasMalware: boolean
  hasRansomware: boolean
  lastCleanBackup: OvaBackup | undefined
  server: OvaServer
}

export interface AssetsOvaServerAttrs {
  ovaAccounts: Array<OvaAccount>
}

export interface AssetsOvaServerTableAttrs extends AssetsOvaServerAttrs {
  servers: Array<OvaServer>
}

export interface AssetsOvaServerInventoryTableAttrs
  extends AssetsOvaServerAttrs {
  servers: Array<Server>
}

export interface AssetsOvaServerDrawerAttrs extends AssetsOvaServerAttrs {
  servers: Array<OvaServer>
  scans: Array<Scan>
  lastBackups: Map<string, Backup>
}

// Base interface for common attributes
interface BaseOvaServerAttrs {
  id: string
  name: string
  ovaAccountId: string
  backupProvider: (typeof BACKUP_VENDOR_MAP)[keyof typeof BACKUP_VENDOR_MAP]
}

// Base class with common properties and getters
export abstract class BaseOvaServer {
  readonly #id: string

  readonly #name: string

  readonly #ovaAccountId: string

  readonly #backupProvider: (typeof BACKUP_VENDOR_MAP)[keyof typeof BACKUP_VENDOR_MAP]

  constructor(parameters: BaseOvaServerAttrs) {
    const { id, name, ovaAccountId, backupProvider } = parameters

    this.#id = id
    this.#name = name
    this.#ovaAccountId = ovaAccountId
    this.#backupProvider = backupProvider
  }

  get id(): string {
    return this.#id
  }

  get name(): string {
    return this.#name
  }

  get ovaAccountId(): string {
    return this.#ovaAccountId
  }

  get backupProvider(): (typeof BACKUP_VENDOR_MAP)[keyof typeof BACKUP_VENDOR_MAP] {
    return this.#backupProvider
  }
}

export class OvaServerTableRow extends BaseOvaServer {}

export class OvaServerInventoryTableRow extends BaseOvaServer {
  readonly #lastCleanBackupDate: Nullable<Date>

  readonly #health: boolean

  readonly #lastScan: Nullable<Date>

  constructor(parameters: OvaServerInventoryTableRowAttrs) {
    const { lastCleanBackupDate, health, lastScan, ...baseParams } = parameters
    super(baseParams)

    this.#lastCleanBackupDate = lastCleanBackupDate
    this.#health = health
    this.#lastScan = lastScan
  }

  get lastCleanBackupDate(): Nullable<Date> {
    return this.#lastCleanBackupDate
  }

  get health(): boolean {
    return this.#health
  }

  get lastScan(): Nullable<Date> {
    return this.#lastScan
  }
}

export class OvaServerDrawer extends BaseOvaServer {
  readonly #lastScan: Nullable<Scan>

  readonly #lastBackup: Nullable<Backup>

  readonly #health: boolean

  readonly assetKind: string = 'Ova Server'

  constructor(parameters: OvaServerDrawerAttrs) {
    const { lastScan, lastBackup, health, ...baseParams } = parameters
    super(baseParams)

    this.#lastScan = lastScan
    this.#lastBackup = lastBackup
    this.#health = health
  }

  get awsId(): undefined {
    return undefined
  }

  get awsAccountId(): undefined {
    return undefined
  }

  get awsRegion(): undefined {
    return undefined
  }

  get state(): undefined {
    return undefined
  }

  get lastScan(): Nullable<Scan> {
    return this.#lastScan
  }

  get lastBackup(): Nullable<Backup> {
    return this.#lastBackup
  }

  get health(): boolean {
    return this.#health
  }
}

interface OvaServerDrawerAttrs extends BaseOvaServerAttrs {
  lastScan: Nullable<Scan>
  lastBackup: Nullable<Backup>
  health: boolean
}

interface OvaServerInventoryTableRowAttrs extends BaseOvaServerAttrs {
  lastCleanBackupDate: Nullable<Date>
  health: boolean
  lastScan?: Nullable<Date>
}
