/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { JobTenant } from 'blues-corejs/dist'
import { RedStackModel } from 'ui-v2/src/lib/models/base-model'
import { DeploymentTableData } from 'ui-v2/src/lib/use-cases/monitor/deployment-table-data'
import JobProgressCell from './job-progress-cell'
import dayjs from 'dayjs'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useUserTimezoneValue } from 'ui-v2/src/hooks/user'

dayjs.extend(utc)
dayjs.extend(timezone)

const CollapsibleDetailsDrawer = ({
  childrenList,
  redStackListData,
}: CollapsibleDetailsDrawerProps) => {
  const theme = useTheme()

  const userTimezone = useUserTimezoneValue()

  const childDeploymentData = useMemo(() => {
    return new DeploymentTableData({
      tenantJobs: childrenList,
      redStackListData: redStackListData,
    }).getDeploymentData()
  }, [childrenList, redStackListData])

  return (
    <Box
      sx={(t) => ({
        backgroundColor: t.palette.mode === 'dark' ? '#141218' : '#f7f9fc',
        paddingLeft: '68px',
      })}
    >
      <Box
        sx={(t) => ({
          borderLeft:
            t.palette.mode === 'dark'
              ? '1px solid rgba(204, 204, 220, 0.2)'
              : '1px solid rgba(204, 204, 220, 0.85)',
          paddingLeft: '26px',
        })}
      >
        <TableContainer>
          <Table>
            <TableBody
              sx={{
                '& .MuiTableCell-body': {
                  backgroundColor:
                    theme.palette.mode === 'dark' ? '#141218' : '#f8f8f8',
                },
              }}
            >
              {childDeploymentData.map((row) => (
                <React.Fragment key={row.id}>
                  <TableRow
                    sx={{
                      height: '60px',
                      '& .MuiTableCell-root': {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        display={'flex'}
                        sx={{
                          position: 'relative',
                          whiteSpace: 'nowrap',
                        }}
                        columnGap={1}
                      >
                        <JobProgressCell status={row.status} />

                        <Typography variant="body2" fontWeight={400}>
                          {dayjs(row.scheduledOn)
                            .tz(userTimezone)
                            .format('MM/DD/YY hh:mm A')}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight={400}>
                        {row.jobType}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {row.cloudConnector ? (
                        <>
                          <Typography variant="body2" fontWeight={400}>
                            {row.cloudConnector?.awsAccount}
                          </Typography>
                          <Typography variant="body2" fontWeight={400}>
                            {row.cloudConnector?.awsRegion}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="body2" fontWeight={400}>
                          -{' '}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <LongTextTooltip
                        text={row.message}
                        placement={'bottom-start'}
                        maxLength={50}
                        fontSize={'0.875rem'}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        wordBreak: 'break-word',
                        maxWidth: 200,
                      }}
                    >
                      <LongTextTooltip
                        text={row.task ?? '-'}
                        placement={'bottom-start'}
                        maxLength={50}
                        fontSize={'0.875rem'}
                      />
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <Typography variant="body2" fontWeight={400}>
                        {!!row.completedOn &&
                          dayjs(row.completedOn)
                            .tz(userTimezone)
                            .format('MM/DD/YY hh:mm A')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

interface CollapsibleDetailsDrawerProps {
  childrenList: Array<JobTenant>
  redStackListData: Array<RedStackModel>
}

export default CollapsibleDetailsDrawer
