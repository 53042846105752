import React from 'react'
import { Button, Stack } from '@mui/material'
import FilesFilter from './files'
import ThreatsFilter from './threats'
import { Delete } from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'

const FilesTableFilters: React.FC = () => {
  const [, setSearchParams] = useSearchParams()
  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams())
  }

  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction="row"
        flexWrap={'wrap'}
        rowGap={1}
        columnGap={1}
        alignItems={'center'}
        height={32}
      >
        <ThreatsFilter />
        <FilesFilter />
        <Button
          disableElevation
          startIcon={<Delete />}
          variant="contained"
          color="inherit"
          sx={(theme) => ({
            textTransform: 'capitalize',
            color: theme.palette.mode === 'dark' ? '#FFF' : '#1AA8EA',
            backgroundColor:
              theme.palette.mode === 'dark' ? '#1A1F28' : '#EFF3F6',
            height: 36,
            border: '1px solid',
            borderRadius: '4px',
            borderColor: theme.palette.mode === 'dark' ? '#29303C' : '#1AA8EA',

            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark' ? '#0076AC' : '#1AA8EA',
              color: '#FFF',
            },
          })}
          onClick={handleClearFilters}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  )
}

export default FilesTableFilters
