import { useMemo } from 'react'
import Box from '@mui/material/Box'
import BaseTable from 'ui-v2/src/components/ui/data-table/base-table'
import {
  getActiveDeploymentsColumn,
  getActiveDeploymentsExpandedColumn,
} from './column-config'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import { useListVaultAllRedstacksQuery } from 'ui-v2/src/hooks/queries/vault/list-vault-all-redstacks'
import { ActiveDeploymentTableData, AssetsAccountTableData } from './types'
import { getActiveDeploymentsTableData } from './utils'
import { useListAssetAccounts } from 'ui-v2/src/hooks/queries/red-stack/list-asset-accounts'
import { useDeploymentStore } from './store/deployment-store'

const ExpanderRow = ({
  accounts,
}: {
  accounts: Array<AssetsAccountTableData>
}) => {
  const columns = getActiveDeploymentsExpandedColumn()
  return (
    <Box
      sx={(theme) => ({
        paddingLeft: 7,
        backgroundColor: theme.palette.background.default,
      })}
    >
      <BaseTable data={accounts} columns={columns} />
    </Box>
  )
}

const ActiveDeploymentTable = () => {
  const { data: listAssetAccounts, isLoading: isLoadingAssetAccounts } =
    useListAssetAccounts()
  const { data: allActiveRedStacksData, isLoading: isLoadingActiveRSD } =
    useRedStackListQuery()
  const { data: vaultListData, isLoading: isLoadingVaultListData } =
    useListVaultAllRedstacksQuery()
  const { setCloudConnectors } = useDeploymentStore()
  const isLoadingData =
    isLoadingAssetAccounts || isLoadingActiveRSD || isLoadingVaultListData

  const tableData: Array<ActiveDeploymentTableData> = useMemo(() => {
    setCloudConnectors(allActiveRedStacksData ?? [])
    return getActiveDeploymentsTableData({
      allActiveRedStacksData,
      vaultListData,
      listAssetAccounts: listAssetAccounts,
    })
  }, [allActiveRedStacksData, vaultListData, listAssetAccounts])

  const columns = getActiveDeploymentsColumn()

  return (
    <BaseTable
      data={tableData}
      columns={columns}
      isLoading={isLoadingData}
      loadingRowCount={4}
      renderExpandedRow={(row) =>
        row.assetsAccount?.length ? (
          <ExpanderRow accounts={row.assetsAccount} />
        ) : null
      }
    />
  )
}

export default ActiveDeploymentTable
