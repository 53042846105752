/* eslint-disable import/no-extraneous-dependencies */
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import {
  NotificationRule,
  RuleStatus,
  RuleSuppressUntil,
} from 'blues-corejs/dist/models/notifications/rule'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { Box, Tooltip } from '@mui/material'
import {
  AlertsStateDisabledIcon,
  AlertsStateEnabledIcon,
  AlertsSuppressedIcon,
  AlertsUnsuppressedIcon,
} from 'ui-v2/src/assets/icons/alerts'
import AlertsCellActions from './table-cell/alerts-cell-actions'
import AlertsCellName from './table-cell/alerts-cell-name'
import LongTextTooltip from '../../../../components/ui/data-display/long-text-tooltip'
import React from 'react'
import { ALERTS_KINDS_MAP } from './utils'

export const getNotificationRulesColumns = (): Array<
  Column<NotificationRule>
> => [
  {
    key: 'name',
    header: 'Rule',
    cellSx: { paddingLeft: 0 },
    width: '190px',
    render: (row) => <AlertsCellName rule={row} />,
  },
  {
    key: 'status',
    header: 'State',
    width: '60px',
    cellSx: { padding: 0 },
    render: (row) => {
      const suppressTitle =
        row.mode instanceof RuleSuppressUntil ? 'Suppressed' : 'Unsuppressed'
      const statusTitle =
        row.status === RuleStatus.disabled ? 'Disabled' : 'Enabled'
      const toolTipTitle = `${suppressTitle} and ${statusTitle}`

      return (
        <Tooltip
          placement="top-start"
          title={toolTipTitle}
          slotProps={{
            tooltip: {
              sx: {
                bgcolor: 'rgba(0, 0, 0, 0.87)',
                color: '#fff',
                fontSize: '0.875rem',
              },
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <Box>
              {row.mode instanceof RuleSuppressUntil ? (
                <AlertsStateDisabledIcon />
              ) : (
                <AlertsStateEnabledIcon />
              )}
            </Box>
            <Box>
              {row.status === RuleStatus.disabled ? (
                <AlertsSuppressedIcon />
              ) : (
                <AlertsUnsuppressedIcon />
              )}
            </Box>
          </Box>
        </Tooltip>
      )
    },
  },
  {
    key: 'description',
    header: 'Description',
    width: '220px',
    render: (row) => {
      return (
        <LongTextTooltip
          fontSize="14px"
          text={row.description}
          maxLength={25}
        />
      )
    },
  },
  {
    key: 'eventTriggers',
    cellSx: { paddingLeft: 0 },
    width: '400px',
    header: 'Event Triggers',
    render: (row) => {
      return (
        <LongTextTooltip
          fontSize="14px"
          text={row.eventTriggers
            .map((eventTrigger) => ALERTS_KINDS_MAP[eventTrigger])
            .join(', ')}
          maxLength={50}
        />
      )
    },
  },
  {
    key: 'createdAt',
    header: 'Created',
    render: (row) => formatDateToUtc(row.createdAt),
  },
  {
    key: 'id',
    header: '',
    width: '40px',
    render: (row) => <AlertsCellActions rule={row} />,
  },
]
