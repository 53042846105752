import { useEffect, useMemo, useState } from 'react'
import {
  IconButton,
  Stack,
  Typography,
  Box,
  ListItem,
  List,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import AwsAccount from './aws-form/aws-account'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  MONITOR_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

import { FormProvider, useForm } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'
import {
  useDiscoverInstalledCfnQuery,
  useListSupportedAwsRegionsQuery,
} from 'ui-v2/src/hooks/queries/red-stack'

import { VpcList } from './aws-form/aws-account-elastio/vpc-list'

import Button from 'ui-v2/src/components/ui/inputs/button'
import AwsAccountElastio from './aws-form/aws-account-elastio'
import { useUpdateConnectorAccountInstallFlow } from 'ui-v2/src/hooks/queries/red-stack/update-level-cloud-formation-link'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import { toast } from 'react-toastify'
import { CfnStatusConstant } from 'ui-v2/src/lib/constants/cfn-status.constant'
import { useConnectorAccountInstallFlow } from 'ui-v2/src/hooks/queries/red-stack/connector-account-install-flow'

const AwsDrawer = () => {
  const rexClientGRPC = useGrpcClient(RexClient)
  const [isDeploymentLoading, setDeploymentLoading] = useState(false)
  const [isInitialFlow, setInitialFlow] = useState(true)
  const { data: listSupportedAwsRegionsData } =
    useListSupportedAwsRegionsQuery()
  const { action } = useParams()
  const isDrawerOpen = action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_SOURCE
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState<number>(0)
  const [previousEmittedAccountId, setPreviousEmittedAccountId] =
    useState<string>('')
  const [disabledVpc, setDisabledVpc] = useState(true)
  const [maxSteps, setMaxSteps] = useState<number>(2)
  const [shouldInstallCfn, setShouldInstallCfn] = useState(false)
  const [isCloudFormationLaunched, setIsCloudFormationLaunched] =
    useState(false)
  const [shouldDisableAccountRegion, setShouldDisableAccountRegion] =
    useState(true)

  const method = useForm<SourceFormData>({
    defaultValues: {
      awsAccountId: '',
      awsRegionId: 'us-east-1',
      isVpcAutoCreate: false,
      vpcRegions: [],
      vpcList: [],
      vpcListForApi: [],
    },
  })
  const isVPCAutoCreateValue = method.watch('isVpcAutoCreate')
  const vpcRegionsValue = method.watch('vpcRegions')
  const awsAccountIdValue = method.watch('awsAccountId')

  const {
    mutate: discoverInstalledCfnMutate,
    isPending: discoverInstalledCfnIsPending,
  } = useDiscoverInstalledCfnQuery()
  useConnectorAccountInstallFlow()
  // const isFinishedAccountLevelCFL =
  //   isError || (isSuccess && !isPendingAccountLevelCFL)

  const { mutate: updateConnectorAccountInstallFlow } =
    useUpdateConnectorAccountInstallFlow()

  const { handleSubmit, reset } = method

  const handleCloseDrawer = () => {
    setActiveStep(0)
    setPreviousEmittedAccountId('')
    reset()
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleCreate = (data: SourceFormData) => {
    if (!rexClientGRPC) {
      return
    }

    setDeploymentLoading(true)
    const { awsAccountId, vpcListForApi } = data
    const requests: Array<Promise<string>> = []

    vpcListForApi.forEach((region) => {
      region.children?.forEach((vpc) => {
        const subnetsIds = vpc.children?.map((subnet) => subnet.id) ?? []
        requests.push(
          rexClientGRPC.installRedstack(
            awsAccountId,
            vpc.id,
            region.id,
            false,
            subnetsIds
          )
        )
      })
    })

    Promise.all(requests)
      .then(() => {
        toast.success('Deployment success')
      })
      .catch((error) => {
        toast.error(error.message ?? 'Cannot perform deployment')
      })
      .finally(() => {
        setDeploymentLoading(false)
        handleCloseDrawer()
      })
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const onVPCAutoCreateValueChange = (value: boolean) => {
    const { awsAccountId, vpcRegions } = method.getValues()
    updateConnectorAccountInstallFlow({
      awsAccountId,
      isVpcAutoCreate: value,
      vpcRegions,
    })
  }

  useEffect(() => {
    if (isVPCAutoCreateValue) {
      setMaxSteps(2)
    } else if (!isInitialFlow) {
      setMaxSteps(3)
    } else {
      setMaxSteps(3)
    }
  }, [isVPCAutoCreateValue])

  const handleFormSubmit = async (sourceFormData: SourceFormData) => {
    if (activeStep === 0) {
      const { awsAccountId, isVpcAutoCreate, vpcRegions } = method.getValues()
      updateConnectorAccountInstallFlow({
        awsAccountId,
        isVpcAutoCreate,
        vpcRegions,
      })
    }

    if (activeStep === 2 && maxSteps === 3) {
      handleCreate(sourceFormData)
    }

    if (activeStep === maxSteps - 1) {
      setActiveStep(0)
      setPreviousEmittedAccountId('')
      reset()
      navigate(
        `/${BASE_ROUTE_SEGMENTS.MONITOR}/${MONITOR_ROUTE_SEGMENTS.DEPLOYMENT}`
      )
    } else {
      handleNext()
    }
  }

  const disabled = useMemo(() => {
    const requiredFieldsSelected =
      !isVPCAutoCreateValue && !vpcRegionsValue.length
    if (requiredFieldsSelected) {
      return true
    }

    if (
      shouldInstallCfn &&
      (!isVPCAutoCreateValue || !vpcRegionsValue.length)
    ) {
      return true
    }

    if (isCloudFormationLaunched) {
      return true
    }

    if (shouldInstallCfn && isCloudFormationLaunched) {
      return true
    }

    if (activeStep === 0) {
      return false
    }
    return false
  }, [activeStep, shouldInstallCfn, isVPCAutoCreateValue, vpcRegionsValue])

  const onCloudFormationClickHandler = () => {
    setIsCloudFormationLaunched(true)
    discoverInstalledCfnMutate(awsAccountIdValue, {
      onSuccess: (cfnData) => {
        if (
          CfnStatusConstant.NOTINSTALLED === cfnData.status ||
          CfnStatusConstant.INSTALLING === cfnData.status
        ) {
          setShouldInstallCfn(true)
          setDisabledVpc(false)
          // method.setValue('isVpcAutoCreate', true)
          setInitialFlow(false)
          setTimeout(() => {
            onCloudFormationClickHandler()
          }, 5000)
        } else {
          setIsCloudFormationLaunched(false)
          setShouldInstallCfn(false)
        }
      },
      onError: (error) => {
        setShouldInstallCfn(true)
        setDisabledVpc(true)
        // method.setValue('isVpcAutoCreate', true)
        console.log(error.message)
        onCloudFormationClickHandler()
        setInitialFlow(false)
      },
    })
  }

  const onAccountIdChange = (awsAccountId: string) => {
    if (!awsAccountId || awsAccountId.length < 12) {
      setShouldDisableAccountRegion(true)
    }

    // requestAccountLevelCloudFormationLink(awsAccountId, {
    //   onSuccess: () => {
    //     setDisabledVpc(true)
    //     method.setValue('isVpcAutoCreate', false)
    //     method.setValue('vpcRegions', [])
    //     requestCfn(awsAccountId)
    //     setInitialFlow(false)
    //   },
    //   onError: () => {
    //     setDisabledVpc(false)
    //     method.setValue('isVpcAutoCreate', true)
    //     setInitialFlow(true)
    //   },
    // })

    discoverInstalledCfnMutate(awsAccountId, {
      onSuccess: (cfnData) => {
        if (CfnStatusConstant.NOTINSTALLED === cfnData.status) {
          setShouldInstallCfn(true)
          setDisabledVpc(false)
          method.setValue('isVpcAutoCreate', true)
          setInitialFlow(false)
          setShouldDisableAccountRegion(false)
          return
        } else {
          setShouldInstallCfn(false)
          setShouldDisableAccountRegion(false)
        }
      },
      onError: (error) => {
        setShouldInstallCfn(true)
        setDisabledVpc(true)
        method.setValue('isVpcAutoCreate', true)
        console.log(error.message)
        setInitialFlow(false)
        setShouldDisableAccountRegion(false)
      },
    })
  }

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <DrawerContent>
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <List sx={{ paddingX: 0 }}>
              <ListItem
                sx={{
                  paddingX: 2.5,
                  paddingRight: '220px',
                }}
                secondaryAction={
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    {activeStep > 0 && (
                      <Button
                        type="button"
                        color="inherit"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={disabled}
                      isLoading={
                        // isPendingAccountLevelCFL ||
                        isDeploymentLoading || discoverInstalledCfnIsPending
                      }
                    >
                      {activeStep !== maxSteps - 1 ? 'Next' : 'Close'}
                    </Button>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={handleCloseDrawer}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                }
              >
                <Typography fontWeight={700} fontSize={22}>
                  Elastio Deployment ({activeStep + 1}/{maxSteps})
                </Typography>
              </ListItem>
            </List>
            <Stack
              spacing={2}
              sx={{
                paddingX: 2.5,
                paddingBottom: 2.5,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <Box>
                  {activeStep === 0 && (
                    <AwsAccount
                      previousEmittedAccountId={previousEmittedAccountId}
                      setPreviousEmittedAccountId={setPreviousEmittedAccountId}
                      onAccountIdChange={onAccountIdChange}
                      listSupportedAwsRegionsData={listSupportedAwsRegionsData}
                      disabledVpc={disabledVpc}
                      isFinishedAccountLevelCFL={true}
                      shouldInstallCfn={shouldInstallCfn}
                      shouldDisableAccountRegion={shouldDisableAccountRegion}
                      onCloudFormationClickHandler={
                        onCloudFormationClickHandler
                      }
                      onVPCAutoCreateValueChange={onVPCAutoCreateValueChange}
                      isCloudFormationLaunched={isCloudFormationLaunched}
                    />
                  )}

                  {activeStep === 1 && maxSteps === 2 && (
                    <AwsAccountElastio
                      onCloudFormationClickHandler={
                        onCloudFormationClickHandler
                      }
                      isCloudFormationLaunched={isCloudFormationLaunched}
                    />
                  )}
                  {activeStep === 1 && maxSteps === 3 && (
                    <VpcList
                      awsAccountId={method.getValues('awsAccountId')}
                      shouldInstallCfn={shouldInstallCfn}
                      onCloudFormationClickHandler={
                        onCloudFormationClickHandler
                      }
                      isCloudFormationLaunched={isCloudFormationLaunched}
                    />
                  )}
                  {activeStep === 2 && (
                    <AwsAccountElastio
                      isHideCfnButtons={true}
                      onCloudFormationClickHandler={
                        onCloudFormationClickHandler
                      }
                      isCloudFormationLaunched={isCloudFormationLaunched}
                    />
                  )}
                </Box>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </DrawerContent>
    </Drawer>
  )
}

export default AwsDrawer
