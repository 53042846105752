import {
  Stack,
  Box,
  Typography,
  FormGroup,
  List,
  ListItem,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import PolicLabelValue from './policy-label-value'
import Ec2EbsRecoverAssuranceSettings from '../protection-options/ec2-ebs/ec2-ebs-recover-assurance-settings'
import S3RecoverAssuranceSettings from '../protection-options/s3/s3-recover-assurance-settings'
import EfsRecoverAssuranceSettings from '../protection-options/efs/efs-recover-assurance-settings'
import Ec2EbsRecoverySettings from '../protection-options/ec2-ebs/ec2-ebs-recovery-settings'
import TagLists from '../assets/ec2-ebs/tags/tag-lists'
import { useFormContext } from 'react-hook-form'
import {
  AssetVariants,
  PolicyFormData,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import ReviewSchedule from './review-schedule'
import dayjs from 'dayjs'
import { format12HourTime } from 'ui-v2/src/lib/helpers/time.helper'
import { SelectedVariant } from 'ui-v2/src/lib/constants/policies.constant'
import Ec2EbsScanTriggers from '../protection-options/ec2-ebs/ec2-ebs-scan-triggers'
import ListItemText from '@mui/material/ListItemText'

const ReviewPolicy = () => {
  const { watch } = useFormContext<PolicyFormData>()
  const policyName = watch('policyName')

  const scheduleData = watch('schedule')

  const selectedTypeVariant = watch('selectedVariant')

  const scanWithTimestamps = watch('scanWithTimestamps')

  const selectedCloudConnectors = watch('selectedCloudConnectors')
  const policyTags = watch('policyTags')

  const tabAssetTypeSelectedNum = watch('tabAssetTypeSelectedNum')

  const shouldRenderEc2EbsProtectionOptions =
    tabAssetTypeSelectedNum === AssetVariants.EBS

  const shouldRenderS3BucketProtectionOptions =
    tabAssetTypeSelectedNum === AssetVariants.S3

  const shouldRenderEfsProtectionOptions =
    tabAssetTypeSelectedNum === AssetVariants.EFS

  const tabAssetTypeValue =
    tabAssetTypeSelectedNum === AssetVariants.EBS
      ? 'EC2/EBS'
      : tabAssetTypeSelectedNum === AssetVariants.S3
        ? 'S3 Bucket'
        : tabAssetTypeSelectedNum === AssetVariants.EFS
          ? 'EFS'
          : ''
  const selectedVariantValue =
    selectedTypeVariant === SelectedVariant.AllEC2EBSVariant
      ? 'All Assets'
      : selectedTypeVariant === SelectedVariant.AssetsVariant
        ? 'Specific Assets'
        : selectedTypeVariant === SelectedVariant.TagsVariant
          ? 'Asset Tags'
          : ''

  return (
    <Stack maxWidth={1120}>
      <Typography
        mb={2.5}
        variant="body1"
        fontWeight={700}
        color="text.secondary"
      >
        Review Policy
      </Typography>
      <Grid container spacing={0}>
        <Grid
          size={{
            md: 6,
            lg: 6,
          }}
        >
          <Box
            borderRight={{
              sm: 0,
              md: 1,
            }}
            paddingRight={{
              sm: 0,
              md: 2,
            }}
            minHeight={'calc(100vh - 200px)'}
          >
            <Box mb={2}>
              <PolicLabelValue label="Name:" value={policyName} />
              {/* TODO: NOT AVAILABLE */}
              {/* <PolicLabelValue label="Description:" value={description} /> */}
              <PolicLabelValue
                label="Schedule Frequency:"
                value={scheduleData?.frequency?.name}
              />
              <ReviewSchedule />

              <PolicLabelValue
                label="Activates:"
                value={`${scheduleData?.firstRun?.runNow ? 'Immediately' : `${dayjs(scheduleData?.firstRun?.runOn?.day).format('MMM D, YYYY')} - ${format12HourTime(scheduleData?.firstRun?.runOn?.time?.hour, scheduleData?.firstRun?.runOn?.time?.minute)}`}`}
              />
            </Box>
            <Stack spacing={2}>
              <FormGroup>
                <Stack spacing={2}>
                  <Typography
                    variant="body2"
                    mb={2.5}
                    fontWeight={700}
                    color="text.secondary"
                  >
                    Data Integrity Settings
                  </Typography>
                  {shouldRenderEc2EbsProtectionOptions && (
                    <Ec2EbsRecoverAssuranceSettings reviewPolicy />
                  )}
                  {shouldRenderS3BucketProtectionOptions && (
                    <S3RecoverAssuranceSettings reviewPolicy />
                  )}
                  {shouldRenderEfsProtectionOptions && (
                    <EfsRecoverAssuranceSettings reviewPolicy />
                  )}
                </Stack>
              </FormGroup>
              <FormGroup>
                <Stack spacing={2}>
                  {shouldRenderEc2EbsProtectionOptions && (
                    <>
                      <Typography
                        variant="body2"
                        mb={2.5}
                        fontWeight={700}
                        color="text.secondary"
                      >
                        EC2 & EBS Settings
                      </Typography>
                      <Ec2EbsRecoverySettings reviewPolicy />
                      <Typography
                        variant="body2"
                        mb={2.5}
                        fontWeight={700}
                        color="text.secondary"
                      >
                        Scan Triggers
                      </Typography>
                      <Ec2EbsScanTriggers reviewPolicy />
                    </>
                  )}
                  {shouldRenderS3BucketProtectionOptions &&
                    scanWithTimestamps?.isScanObjects && (
                      <>
                        <Typography
                          variant="body2"
                          mb={2.5}
                          fontWeight={700}
                          color="text.secondary"
                        >
                          S3 Settings
                        </Typography>

                        <PolicLabelValue
                          label="Start:"
                          value={`${`${dayjs(scanWithTimestamps?.dateTimeData?.day).format('MMM D, YYYY')} - ${format12HourTime(scanWithTimestamps?.dateTimeData?.time?.hour, scanWithTimestamps?.dateTimeData?.time?.minute)}`}`}
                        />
                      </>
                    )}
                  {shouldRenderEfsProtectionOptions &&
                    scanWithTimestamps?.isScanObjects && (
                      <>
                        <Typography
                          variant="body2"
                          mb={2.5}
                          fontWeight={700}
                          color="text.secondary"
                        >
                          EFS Settings
                        </Typography>
                        <PolicLabelValue
                          label="Start:"
                          value={`${`${dayjs(scanWithTimestamps?.dateTimeData?.day).format('MMM D, YYYY')} - ${format12HourTime(scanWithTimestamps?.dateTimeData?.time?.hour, scanWithTimestamps?.dateTimeData?.time?.minute)}`}`}
                        />
                      </>
                    )}
                </Stack>
              </FormGroup>
            </Stack>
          </Box>
        </Grid>
        <Grid
          size={{
            md: 6,
            lg: 6,
          }}
        >
          <Stack
            spacing={2}
            paddingLeft={{
              sm: 0,
              md: 2,
            }}
            minHeight={'calc(100vh - 200px)'}
          >
            <Box mb={0} fontSize={'14px'}>
              {selectedCloudConnectors.length > 0 && (
                <>
                  <Typography sx={{ fontSize: '14px !important' }}>
                    Accounts and Regions:
                  </Typography>
                  <List sx={{ pl: 2 }}>
                    {selectedCloudConnectors?.map((region, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          display: 'list-item',
                          listStyleType: 'disc',
                          padding: '2px 0',
                        }}
                      >
                        <ListItemText
                          sx={{
                            '& .MuiTypography-root': {
                              fontSize: '14px !important',
                              color: '#FFFFFF',
                            },
                          }}
                          primary={region.name}
                        />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
              <PolicLabelValue label="Asset:" value={tabAssetTypeValue} />
              {selectedVariantValue && (
                <PolicLabelValue
                  label={`${tabAssetTypeValue}:`}
                  value={selectedVariantValue}
                />
              )}
            </Box>

            {policyTags.length > 0 && <TagLists reviewPolicy />}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ReviewPolicy
