import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form'
import {
  Box,
  FormControl,
  Grid2,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import Button from '../../../../../components/ui/inputs/button'
import { industryOptions } from './industry-options'
import { createDollarFormatter, RoiData } from '../util'

interface RoiFormProps {
  handleSubmit: UseFormHandleSubmit<RoiData, undefined>
  onSubmit: (data: RoiData) => void
  roiData: RoiData
  control: Control<RoiData, any>
  isSaveDisabled: boolean
}

const RoiForm = ({
  handleSubmit,
  onSubmit,
  roiData,
  control,
  isSaveDisabled,
}: RoiFormProps) => {
  const handleDowntimeCost = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: Array<any>) => void
  ) => {
    const inputValue = e.target.value
    const numericValue = inputValue ? Number(inputValue) : 0
    if (numericValue >= 0) {
      onChange(numericValue)
    }
  }

  const commonInputStyles = (theme: Theme) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#19232F' : '#FFFFFF',
    color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',
    minHeight: '48px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor:
        theme.palette.mode === 'dark' ? '#4E5665' : 'rgba(228, 219, 233, 0.1',
    },
  })

  const dollarFormatter = createDollarFormatter()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={3}
        sx={{
          paddingX: 2.5,
          paddingY: 3,
          maxWidth: '321px',
          marginX: 'auto',
        }}
      >
        <Typography variant="body2" fontWeight={600} textAlign={'center'}>
          Input Your Organization's Data
        </Typography>
        <Grid2 container spacing={3}>
          <Grid2 size={12}>
            <FormControl fullWidth>
              <Typography variant="body2" color="text.secondary" mb={1}>
                Your Industry
              </Typography>
              <Controller
                name="selectedIndustry"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="small"
                    fullWidth
                    {...field}
                    sx={commonInputStyles}
                  >
                    {industryOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={12}>
            <FormControl fullWidth>
              <Typography variant="body2" color="text.secondary" mb={1}>
                Annual Revenue
              </Typography>
              <Controller
                name="revenueInput"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    {...field}
                    // Format the value when displaying
                    value={value ? value.toLocaleString('en-US') : ''}
                    // Parse the input when changing
                    onChange={(e) => {
                      // Remove commas and non-numeric characters (except decimal)
                      const rawValue = e.target.value.replace(/[^0-9.]/g, '')
                      // Convert to number
                      const val = rawValue ? parseFloat(rawValue) : null
                      onChange(val)
                    }}
                    size="small"
                    // Change type to "text" instead of "number" to allow comma formatting
                    type="text"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      },
                    }}
                    sx={commonInputStyles}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={12}>
            <FormControl fullWidth>
              <Typography variant="body2" color="text.secondary" mb={1}>
                Data Protected by Elastio
              </Typography>
              <Controller
                name="downtimeCost"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    {...field}
                    value={value ?? ''}
                    onChange={(e) => {
                      handleDowntimeCost(e, onChange)
                    }}
                    size="small"
                    type="number"
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="start">TB</InputAdornment>
                        ),
                      },
                    }}
                    sx={commonInputStyles}
                  />
                )}
              />
            </FormControl>
          </Grid2>
        </Grid2>
      </Stack>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'44px'}
      >
        <Button
          type="submit"
          size="small"
          variant="contained"
          sx={{
            textTransform: 'capitalize',
            fontSize: 13,
            paddingX: '1.5rem',
          }}
          disabled={isSaveDisabled}
        >
          Calculate
        </Button>
        <Box
          sx={{
            backgroundColor: '#1AA8EA',
            borderRadius: '8px',
          }}
          paddingX={2.5}
          paddingY={0}
        >
          <Typography fontSize={32} fontWeight={700} color="#fff">
            {dollarFormatter.format(parseInt(roiData.calculatedRoi))}
          </Typography>
        </Box>
      </Box>
    </form>
  )
}

export default RoiForm
