import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { INVENTORY_TABLES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { ValueInterface } from 'ui-v2/src/lib/engine-types'

type FilterMapKeys =
  (typeof INVENTORY_TABLES_FILTERS_MAP)[keyof typeof INVENTORY_TABLES_FILTERS_MAP]

const SEARCH_PARAM_TO_FILTER_NAME_MAP: Partial<Record<FilterMapKeys, string>> =
  {}

export const useInventoryTablesFilters = () => {
  const [searchParams] = useSearchParams()

  return useMemo(() => {
    const filters: Array<ValueInterface> = []

    Object.values(INVENTORY_TABLES_FILTERS_MAP).forEach((filterKey) => {
      const values = searchParams.getAll(filterKey)

      if (values.length > 0) {
        filters.push({
          name: SEARCH_PARAM_TO_FILTER_NAME_MAP[filterKey] ?? filterKey,
          options: values
            .join(',')
            .split(',')
            .filter(Boolean)
            .map((value) => ({
              name: value.trim(),
            })),
        })
      }
    })

    return filters
  }, [searchParams])
}
