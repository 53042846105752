import { base64Encode } from 'ui-v2/src/lib/helpers/string.helper'
import { Option } from '../reports'

export type TestWebhookFilters =
  | { id: string }
  | { id: undefined; name: string; endpoint: string }

export enum Severity {
  ERROR,
  WARNING,
  INFO,
  TRACE,
}

export interface WebhookFormInterface {
  name: string
  endpoint: string
  authorization: string
  login: string
  password: string
  apiKey: string
  apiValue: string
  token: string
  description: string
  eventType: string
  version: string
  severity: string
}

export enum EventTypeConstant {
  ACCOUNT_LEVEL_STACK = 'account-level-stack',
  ASSET = 'asset',
  BACKUP_POLICY = 'backup-policy',
  CLOUD_CONNECTOR = 'cloud-connector',
  LOCAL_PATH = 'local-path',
  RECOVERY_POINT = 'recovery-point',
  SNAPSHOTS = 'snapshots',
  VAULT = 'vault',
  USER = 'user',
  ROLE = 'role',
}

enum AuthorizationOptions {
  NONE = 'none',
  BASIC = 'basic_authorization',
  BEARER = 'bearer',
  API_KEY = 'api_key',
}

export interface FormFieldInterface {
  name: string
  value?: number | string | boolean
  placeholder?: string
  label?: string
  validationRules?: any
  validationErrors?: any
  hasError?: boolean
  errorText?: string
  options?: Array<Option>
}

export interface EventType {
  name: string
}

export interface WebhookModelAttributes {
  readonly id: string

  name: string

  endpoint: string

  authentication: WebhookAuthentication

  description: string

  event_subscriptions: Array<WebhookEventSubscription>
}

export type AuthType = BasicAuth | BearerToken | ApiKey

export class Secret {
  private readonly secret: string

  private readonly encrypted: boolean

  constructor({ secret, encrypted }: { secret: string; encrypted: boolean }) {
    this.secret = secret
    this.encrypted = encrypted
  }

  getSecret(): string {
    return this.secret
  }

  isEncrypted(): boolean {
    return this.encrypted
  }
}

export class BasicAuth {
  private login: Secret

  private password: Secret

  constructor(login: Secret, password: Secret) {
    this.login = login
    this.password = password
  }

  getLogin(): string {
    return this.login.getSecret()
  }

  isLoginEncrypted(): boolean {
    return this.login.isEncrypted()
  }

  getPassword(): string {
    return this.password.getSecret()
  }

  isPasswordEncrypted(): boolean {
    return this.password.isEncrypted()
  }

  setLogin(login: Secret) {
    this.login = login
  }

  setPassword(password: Secret) {
    this.password = password
  }
}

export class BearerToken {
  private token: Secret

  constructor(token: Secret) {
    this.token = token
  }

  getToken(): string {
    return this.token.getSecret()
  }

  isTokenEncrypted(): boolean {
    return this.token.isEncrypted()
  }

  setToken(token: Secret) {
    this.token = token
  }
}

export class ApiKey {
  private key: Secret

  private value: Secret

  constructor(key: Secret, value: Secret) {
    this.key = key
    this.value = value
  }

  getKey(): string {
    return this.key.getSecret()
  }

  isKeyEncrypted(): boolean {
    return this.key.isEncrypted()
  }

  setKey(key: Secret) {
    this.key = key
  }

  getValue(): string {
    return this.value.getSecret()
  }

  isValueEncrypted(): boolean {
    return this.value.isEncrypted()
  }

  setValue(value: Secret) {
    this.value = value
  }
}

export class WebhookAuthentication {
  private auth?: AuthType

  constructor(auth?: AuthType) {
    this.auth = auth || undefined
  }

  intoInner(): BasicAuth | BearerToken | ApiKey | undefined {
    const result = this.auth
    // Its for TS, because it doesn't know that we check it in constructor
    if (result) {
      return result
    }
    return
  }

  getAuthTypeToString(): string {
    if (this.auth instanceof BasicAuth) {
      return AuthorizationOptions.BASIC
    }
    if (this.auth instanceof BearerToken) {
      return AuthorizationOptions.BEARER
    }
    if (this.auth instanceof ApiKey) {
      return AuthorizationOptions.API_KEY
    }
    return AuthorizationOptions.NONE
  }
}

export class WebhookEventSubscription {
  private eventType: string

  private version: string

  private severities: Array<Severity>

  constructor(event: string, version: string, severities: Array<Severity>) {
    this.eventType = event
    this.version = version
    this.severities = severities
  }

  getEventType() {
    return this.eventType
  }

  getVersion() {
    return this.version
  }

  getSeverities(): Array<Severity> {
    return this.severities
  }
}

export type WebhookAuthType =
  | 'none'
  | 'basic_authorization'
  | 'bearer'
  | 'api_key'
export type VersionType = 'v0'

export type AuthenticationType = {
  basic_auth?: {
    login: {
      old_value: string | null
      value: string
    }
    password: {
      old_value: string | null
      value: string
    }
  }
  bearer_token?: {
    token: {
      old_value: string | null
      value: string
    }
  }
  api_key?: {
    key: {
      old_value: string | null
      value: string
    }
    value: string
  }
}

export type EventSubscriptionsType = Array<{
  event_type: string
  version: string
  severities: Array<string>
}>

export interface WebhookFormData {
  name: string
  endpoint: string
  authentication: AuthenticationType
  description?: string
  event_subscriptions: EventSubscriptionsType
}

export interface EventList {
  versionType: string
  severityList: Array<string>
  eventType: string
}

class WebhookModel {
  private readonly id: string

  private name: string

  private endpoint: string

  private authentication: WebhookAuthentication

  private description: string

  // @ts-ignore
  private event_subscriptions: Array<WebhookEventSubscription>

  constructor(params: WebhookModelAttributes) {
    this.id = params.id
    this.name = params.name
    this.endpoint = params.endpoint
    this.authentication = params.authentication
    this.description = params.description

    this.setEventSubscriptions(params.event_subscriptions)
  }

  getId(): string {
    return this.id
  }

  getEncodedId(): string {
    return base64Encode(this.id)
  }

  getName(): string {
    return this.name
  }

  setName(name: string): void {
    this.name = name
  }

  getEndpoint(): string {
    return this.endpoint
  }

  setEndpoint(endpoint: string): void {
    this.endpoint = endpoint
  }

  getAuthentication(): WebhookAuthentication {
    return this.authentication
  }

  getDescription(): string {
    return this.description
  }

  setDescription(description: string): void {
    this.description = description
  }

  getEventSubscriptions(): Array<WebhookEventSubscription> {
    return this.event_subscriptions
  }

  setEventsSubscription(events: Array<WebhookEventSubscription>): void {
    this.event_subscriptions = events
  }

  changeAuthTypeToBasicAuth(): void {
    if (this.authentication.intoInner() instanceof BasicAuth) {
      return
    }

    this.authentication = new WebhookAuthentication(
      new BasicAuth(
        new Secret({
          secret: '',
          encrypted: false,
        }),
        new Secret({
          secret: '',
          encrypted: false,
        })
      )
    )
  }

  updateLogin(login: string): void {
    const auth = this.getAuthentication().intoInner()
    if (auth instanceof BasicAuth) {
      auth.setLogin(
        new Secret({
          secret: login,
          encrypted: false,
        })
      )
    }
  }

  updatePassword(password: string): void {
    const auth = this.getAuthentication().intoInner()
    if (auth instanceof BasicAuth) {
      auth.setPassword(
        new Secret({
          secret: password,
          encrypted: false,
        })
      )
    }
  }

  changeAuthTypeToBearerToken(): void {
    if (this.authentication.intoInner() instanceof BearerToken) {
      return
    }

    this.authentication = new WebhookAuthentication(
      new BearerToken(
        new Secret({
          secret: '',
          encrypted: false,
        })
      )
    )
  }

  updateToken(token: string): void {
    const auth = this.getAuthentication().intoInner()

    if (auth instanceof BearerToken) {
      auth.setToken(
        new Secret({
          secret: token,
          encrypted: false,
        })
      )
    }
  }

  changeAuthTypeToApiKey(): void {
    if (this.authentication.intoInner() instanceof ApiKey) {
      return
    }

    this.authentication = new WebhookAuthentication(
      new ApiKey(
        new Secret({
          secret: '',
          encrypted: false,
        }),
        new Secret({
          secret: '',
          encrypted: false,
        })
      )
    )
  }

  updateApiKey(key: string): void {
    const auth = this.getAuthentication().intoInner()

    if (auth instanceof ApiKey) {
      auth.setKey(
        new Secret({
          secret: key,
          encrypted: false,
        })
      )
    }
  }

  updateApiValue(value: string): void {
    const auth = this.getAuthentication().intoInner()

    if (auth instanceof ApiKey) {
      auth.setValue(
        new Secret({
          secret: value,
          encrypted: false,
        })
      )
    }
  }

  private setEventSubscriptions(
    eventSubscriptions: Array<WebhookEventSubscription>
  ) {
    const events = eventSubscriptions.map((event) => event.getEventType())
    const duplicates = events.filter(
      (event, index) => events.indexOf(event) !== index
    )

    if (duplicates.length > 0) {
      throw new Error(`Event types ${duplicates.join(', ')} already exist`)
    }

    this.event_subscriptions = eventSubscriptions
  }
}

export default WebhookModel
