import { useEffect, useState } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'
import { useListAwsVpcArr } from 'ui-v2/src/hooks/queries/red-stack/list-aws-vpc-arr'
import {
  NestedOption,
  NestedSelect,
} from 'ui-v2/src/components/ui/inputs/nested-select'
import MaxWidthFormControl from './max-width-form-control'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import { CloudFormationStackButton } from '../../aws-account-buttons'
import { transformVpcListToNestedOptions, RegionOption } from '../../../utils'

interface VpcListProps {
  awsAccountId: string
  shouldInstallCfn: boolean
  onCloudFormationClickHandler: any
  isCloudFormationLaunched: boolean
}

const VpcList = ({
  awsAccountId,
  shouldInstallCfn,
  onCloudFormationClickHandler,
  isCloudFormationLaunched,
}: VpcListProps) => {
  const rexClient = useGrpcClient(RexClient)
  const [formData, setFormData] = useState<Array<RegionOption>>([])

  const { mutate: requestVpcListArr, isPending: requestVpcListArrIsPending } =
    useListAwsVpcArr()

  const { setValue } = useFormContext<SourceFormData>()

  const getVpcListArr = (accountId: string) => {
    requestVpcListArr(accountId, {
      onSuccess: (data) => {
        setFormData([])
        setFormData(transformVpcListToNestedOptions(data))
      },
      onError: (error) => {
        console.log(error.message)
      },
    })
  }

  useEffect(() => {
    if (!rexClient) {
      return
    }
    getVpcListArr(awsAccountId)
  }, [awsAccountId, rexClient])

  const handleVpcSelect = (selectedData: Array<NestedOption>) => {
    setValue('vpcListForApi', selectedData)
  }

  return (
    <Stack spacing={4} maxWidth={700}>
      <Box pt={2}>
        <Typography color="text.secondary" fontSize={'14px'}>
          Deploy the Elastio Cloud Connector in your Dedicated Account. The
          Cloud Connector processes ransomware integrity scans for all resources
          in the connected Protected Accounts directly within the Dedicated
          Account, without requiring any operations to run on the Protected
          Accounts themselves.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" fontWeight={600} mb={1}>
          Select Regions
        </Typography>
        <Stack direction="row" spacing={1}>
          <MaxWidthFormControl maxWidth="25%">
            <NestedSelect
              onSelect={handleVpcSelect}
              name={'vpcList'}
              options={formData || []}
              isLoading={requestVpcListArrIsPending}
            />
          </MaxWidthFormControl>
          {shouldInstallCfn && !requestVpcListArrIsPending && (
            <CloudFormationStackButton
              onCloudFormationClickHandler={onCloudFormationClickHandler}
              isCloudFormationLaunched={isCloudFormationLaunched}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  )
}

export default VpcList
