import { Close } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  IconButton,
  Typography,
  TypographyProps,
} from '@mui/material'

interface DrawerHeaderProps extends BoxProps {
  title: string
  handleCloseDrawer: React.MouseEventHandler<HTMLButtonElement>
  iconStart?: React.ReactNode | undefined
  typographySx?: TypographyProps['sx']
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  title,
  handleCloseDrawer,
  iconStart = undefined,
  typographySx = {},
  ...rest
}) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      p={2.5}
      {...rest}
    >
      <Box display={'flex'} alignItems={'center'} gap={2}>
        {iconStart ? iconStart : null}
        <Typography variant="h5" sx={typographySx}>
          {title}
        </Typography>
      </Box>
      <IconButton edge="end" aria-label="close" onClick={handleCloseDrawer}>
        <Close sx={{ color: 'icon.dark' }} />
      </IconButton>
    </Box>
  )
}

export default DrawerHeader
