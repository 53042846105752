import { SyntheticEvent, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import {
  BASE_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import AssetsThreatsEc2 from './ec2'
import AssetsDetailsDrawer from 'ui-v2/src/components/drawers/asset-details'
import AssetsThreatsEbs from './ebs'
import AssetsThreatsS3 from './s3'
import AssetsThreatsEfs from './efs'
import AssetRestoreCleanBackup from 'ui-v2/src/components/drawers/restore-clean-backup'
import { AssetThreatsTabs } from './filters/config'
import { ASSETS_THREATS_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  slug: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, slug, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== slug}
      id={`tabpanel-${slug}`}
      aria-labelledby={`tab-${slug}`}
      {...other}
    >
      {value === slug && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

const TAB_MAP: Record<
  string,
  {
    label: string
    component: JSX.Element
    slug: AssetThreatsTabs
  }
> = {
  ec2: {
    label: 'EC2',
    component: <AssetsThreatsEc2 />,
    slug: AssetThreatsTabs.INSTANCES,
  },
  ebs: {
    label: 'EBS',
    component: <AssetsThreatsEbs />,
    slug: AssetThreatsTabs.VOLUMES,
  },
  s3: {
    label: 'S3 Bucket',
    component: <AssetsThreatsS3 />,
    slug: AssetThreatsTabs.S3,
  },
  efs: {
    label: 'EFS',
    component: <AssetsThreatsEfs />,
    slug: AssetThreatsTabs.EFS,
  },
}

export default function AssetsThreats() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const tab = searchParams.get('t') as AssetThreatsTabs | null
  const defaultTab = AssetThreatsTabs.INSTANCES
  const defaultPath = `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.ASSET_THREATS}?t=${defaultTab}`

  useEffect(() => {
    if (!tab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(AssetThreatsTabs).includes(tab)) {
      navigate(defaultPath, { replace: true })
      return
    }
  }, [tab, navigate, defaultPath])

  if (!tab || !Object.values(AssetThreatsTabs).includes(tab)) {
    return null
  }

  const handleChange = (_: SyntheticEvent, newSlug: AssetThreatsTabs) => {
    const newSearchParams = new URLSearchParams()
    newSearchParams.set('t', newSlug)

    const filters = Object.values(ASSETS_THREATS_FILTERS_MAP)

    filters.forEach((filter) => {
      const currentValue = searchParams.get(filter)

      if (!currentValue) {
        return
      }

      if (
        filter === ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID ||
        filter === ASSETS_THREATS_FILTERS_MAP.REGION
      ) {
        newSearchParams.set(filter, currentValue)
      } else {
        newSearchParams.set(filter, 'all')
      }
    })

    setSearchParams(newSearchParams)
  }
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="asset tabs"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              '& .MuiTab-root.Mui-selected': {
                color: theme.palette.text.primary,
              },
            })}
            className="dashboard-asset-tabs"
          >
            {Object.values(TAB_MAP).map((tabItem) => (
              <Tab
                key={tabItem.slug}
                value={tabItem.slug}
                label={tabItem.label}
                sx={{ py: 0 }}
                {...a11yProps(tabItem.slug)}
              />
            ))}
          </Tabs>
        </Box>
        {Object.entries(TAB_MAP).map(([key, value]) => (
          <CustomTabPanel key={key} value={tab} slug={value.slug}>
            {value.component}
          </CustomTabPanel>
        ))}
      </Box>
      <AssetsDetailsDrawer />
      <AssetRestoreCleanBackup />
    </>
  )
}
