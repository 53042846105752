/* eslint-disable import/no-extraneous-dependencies */
import {
  EBSState,
  EC2State,
  OvaBackupProvider,
  RiskLevel,
} from 'blues-corejs/dist'
import { PolicyCoverageState } from '../models/assets'
import { S3BucketState } from 'blues-corejs/dist/models/assets/aws/s3-bucket/types'
import { EfsState } from 'blues-corejs/dist/models/assets/aws/efs/types'
import { HealthState, ListEbsRequest } from '../grpc'
import { LastScanState } from '../models/assets/common'

export const EC2_STATE_MAP = {
  [EC2State.RUNNING]: 'Running',
  [EC2State.STOPPED]: 'Stopped',
  [EC2State.TERMINATED]: 'Terminated',
  [EC2State.UNKNOWN]: 'Replicated',
} as const

export const EBS_STATE_MAP = {
  [EBSState.CREATING]: 'Creating',
  [EBSState.AVAILABLE]: 'Available',
  [EBSState.INUSE]: 'In Use',
  [EBSState.DELETING]: 'Deleting',
  [EBSState.DELETED]: 'Deleted',
  [EBSState.ERROR]: 'Error',
  [EBSState.UNKNOWN]: 'Replicated',
} as const

export const VOLUME_STATE_FILTER_MAP = {
  [ListEbsRequest.Initial.Filter.VolumeState.CREATING]: 'Creating',
  [ListEbsRequest.Initial.Filter.VolumeState.AVAILABLE]: 'Available',
  [ListEbsRequest.Initial.Filter.VolumeState.IN_USE]: 'In Use',
  [ListEbsRequest.Initial.Filter.VolumeState.ERROR]: 'Error',
  [ListEbsRequest.Initial.Filter.VolumeState.DELETING]: 'Deleting',
  [ListEbsRequest.Initial.Filter.VolumeState.DELETED]: 'Deleted',
  [ListEbsRequest.Initial.Filter.VolumeState.UNKNOWN]: 'Replicated',
} as const

export const BUCKET_STATE_MAP = {
  [S3BucketState.IN_USE]: 'In Use',
  [S3BucketState.DELETED]: 'Deleted',
  [S3BucketState.UNKNOWN]: 'Replicated',
} as const

export const BUCKET_STATE_FILTER_MAP = {
  [S3BucketState.IN_USE]: 'In Use',
  [S3BucketState.DELETED]: 'Deleted',
  [S3BucketState.UNKNOWN]: 'Replicated',
} as const

export const EFS_STATE_MAP = {
  [EfsState.IN_USE]: 'In Use',
  [EfsState.DELETED]: 'Deleted',
  [EfsState.UNKNOWN]: 'Replicated',
} as const

export const FILESYSTEM_STATE_FILTER_MAP = {
  [EfsState.IN_USE]: 'In Use',
  [EfsState.DELETED]: 'Deleted',
  [EfsState.UNKNOWN]: 'Replicated',
} as const

export const POLICY_COVERAGE_STATE_MAP = {
  [PolicyCoverageState.COVERED]: 'Covered',
  [PolicyCoverageState.NOT_COVERED]: 'Not Covered',
} as const

//TODO: remove when we clean up old assets page
export const ASSET_FILTERS_MAP = {
  INSTANCE_ID: 'instance',
  INSTANCE_NAME: 'instance-name',
  INSTANCE_STATE: 'instance-state',
  VOLUME_ID: 'volume',
  VOLUME_NAME: 'volume-name',
  VOLUME_STATE: 'volume-state',
  ACCOUNT_ID: 'account',
  BUCKET_ID: 'bucket',
  BUCKET_NAME: 'bucket-name',
  BUCKET_STATE: 'bucket-state',
  FILESYSTEM_ID: 'filesystem',
  FILESYSTEM_NAME: 'filesystem-name',
  FILESYSTEM_STATE: 'filesystem-state',
  BACKUP_VENDOR: 'backup-vendor',
  OVA_ACCOUNT_ID: 'ova-account',
  HOST: 'host',
  REGION: 'region',
  POLICY_COVERAGE: 'policy',
  LAST_SCAN: 'last-scan',
  HEALTH: 'health',
  ASSET_ID: 'id',
  ASSET_NAME: 'name',
} as const

//TODO: rename this after we cleanup old assets page
export const NEW_ASSET_FILTERS_MAP = {
  ACCOUNT_ID: 'account',
  BACKUP_VENDOR: 'backup-vendor',
  REGION: 'region',
  POLICY_COVERAGE: 'policy',
  LAST_SCAN: 'last-scan',
  HEALTH: 'health',
  ASSET_ID: 'id',
  ASSET_NAME: 'name',
  INSTANCE_STATE: 'instance-state',
  VOLUME_STATE: 'volume-state',
  BUCKET_STATE: 'bucket-state',
  FILESYSTEM_STATE: 'filesystem-state',
} as const

export const SERVER_STATUS_SEARCH_PARAM = 'server-status'

export const ASSETS_THREATS_FILTERS_MAP = {
  ACCOUNT_ID: 'accountId',
  REGION: 'regions',
  RANSOMWARE: 'ransomware',
  MALWARE: 'malware',
  FILE_SYSTEM_INTEGRITY: 'filesystem',
} as const

export const INVENTORY_TABLES_FILTERS_MAP = {
  ACCOUNT_ID: 'accountId',
  REGION: 'regions',
  POLICY: 'covered',
} as const

export const ENCRYPTION_ACTIVITY_FILTERS_MAP = {
  ACCOUNT_ID: 'accountId',
  REGION: 'regions',
  POLICY: 'covered',
} as const

export const SEARCH_PARAM = 'search' as const

export const INVENTORY_VIEW_PARAM = 'view' as const

export const INVENTORY_VIEW_PARAM_VALUES = {
  CLEAN: 'clean',
  RPO: 'rpo',
} as const

export const NAME_TAG_KEY = 'Name' as const

export const BACKUP_VENDOR_MAP = {
  [OvaBackupProvider.VEEAM]: 'Veeam',
  [OvaBackupProvider.COMMVAULT]: 'Commvault',
  [OvaBackupProvider.RUBRIK]: 'Rubrik',
  [OvaBackupProvider.COHESITY]: 'Cohesity',
  [OvaBackupProvider.VERITAS_NET_BACKUP]: 'VeritasNetBackup',
  [OvaBackupProvider.AWS_BACKUP_VMWARE]: 'AwsBackupVmware',
} as const

export const ASSETS_VULNERABILITIES_FILTERS_MAP = {
  ACCOUNT_ID: 'accountId',
  REGION: 'regions',
  VOLUME_ID: 'volumeAwsIds',
  INSTANCE_ID: 'attachedInstancesAwsIds',
  MISCONFIGURATION: 'snapshotVulnerabilityTypes',
  INSTANCE_MISCONFIGURATION: 'ebsVulnerabilities',
  RISK: 'riskType',
} as const

export const RISK_LEVEL = {
  [RiskLevel.High]: 'High',
  [RiskLevel.Medium]: 'Medium',
  [RiskLevel.Low]: 'Low',
} as const

export enum AssetKind {
  UNDEFINED = -1,
  AWS_EC2 = 0,
  AZURE_VM = 1,
  VM_WARE_VM = 2,
  AWS_EBS = 3,
  AWS_EFS = 4,
  GENERIC_HOST = 5,
  GENERIC_FS = 6,
  AWS_S3 = 7,
  AWS_S3_OBJECTS = 8,
}

export const ASSET_KIND_MAP = {
  EC2: 'EC2',
  EBS: 'EBS',
  S3: 'S3',
  EFS: 'EFS',
  SERVER: 'Servers',
  HOST: 'Generic Host',
} as const

export const ASSET_DETAILS_FILTERS_MAP = {
  SCAN_TYPE: 'scan-type',
  SCAN_TIME: 'scan-time',
  DIVIDER: 'divider',
  VOLUME_HEALTH: 'volume-health',
  VOLUME_ID: 'volume-id',
  THREATS: 'threats',
} as const

export enum TimePeriod {
  OneDay = '1',
  SevenDays = '7',
  ThirtyDays = '30',
  NinetyDays = '90',
  OneYear = '365',
}

export const TIME_PERIODS = [
  {
    label: '1 Day',
    value: TimePeriod.OneDay,
  },
  {
    label: '7 Days',
    value: TimePeriod.SevenDays,
  },
  {
    label: '30 Days',
    value: TimePeriod.ThirtyDays,
  },
  {
    label: '90 Days',
    value: TimePeriod.NinetyDays,
  },
  {
    label: '1 Year',
    value: TimePeriod.OneYear,
  },
]

export enum RecoveryPointsType {
  KeyScans = 'key',
  AWSBackup = 'aws_ami_backup',
  AWSBackupLAGVault = 'AWS Backup LAG Vault',
  AWSBackupRestoreTest = 'AWS Backup Restore Test',
  AWSBackupReplicatedSnapshot = 'AWS Backup Replicated Snapshot',
  AWSDRSFuture = 'AWS DRS ( FUTURE )',
  AWSSnapshot = 'AWS Snapshot',
  ElastioManagedSnapshots = 'Elastio Managed Snapshots',
}

export enum AssetKindEnum {
  EC2 = 'EC2',
  EBS = 'EBS',
  S3 = 'S3',
  EFS = 'EFS',
  SERVER = 'SERVER',
}

export const LAST_SCAN_STATE_MAP = {
  [LastScanState.ONE_DAY]: '1 day',
  [LastScanState.ONE_WEEK]: '7 days',
  [LastScanState.THIRTY_DAY]: '30 days',
  [LastScanState.SIXTY_DAY]: '60 days',
  [LastScanState.NINETY_DAY]: '90 days',
  [LastScanState.ONE_YEAR]: '1 year',
} as const

export const HEALTH_STATE_MAP = {
  [HealthState.AT_RISK]: 'At Risk',
  [HealthState.CLEAN]: 'Clean',
  [HealthState.INFECTED]: 'Infected',
} as const

export enum Provider {
  VEEAM = 'veeam',
  COMMVAULT = 'commvault',
  RUBRIK = 'rubrik',
  COHESITY = 'cohesity',
  VERITAS_NET_BACKUP = 'veritas_net_backup',
  AWS_BACKUP_VMWARE = 'aws_backup_vmware',
}

export const BACKUP_PROVIDER = {
  [Provider.VEEAM]: OvaBackupProvider.VEEAM,
  [Provider.COMMVAULT]: OvaBackupProvider.COMMVAULT,
  [Provider.RUBRIK]: OvaBackupProvider.RUBRIK,
  [Provider.COHESITY]: OvaBackupProvider.COHESITY,
  [Provider.VERITAS_NET_BACKUP]: OvaBackupProvider.VERITAS_NET_BACKUP,
  [Provider.AWS_BACKUP_VMWARE]: OvaBackupProvider.AWS_BACKUP_VMWARE,
}
