/* eslint-disable import/no-extraneous-dependencies */
import { Asset, OvaServer } from 'blues-corejs/dist'
import { useDispatch, useSelector } from 'react-redux'
import { isAwsAsset } from 'ui-v2/src/lib/helpers/assets.helper'
import { RootState } from 'ui-v2/src/store'
import { clearAssetDetailsSourcePath } from 'ui-v2/src/store/features/navigation/asset-details-source-path-slice'
import { ASSET_DETAILS_ACTIVE_TAB } from 'ui-v2/src/views/asset-details/data-panel/constants'

export interface BreadcrumbItem {
  label: string | undefined
  link?: string
  onClick?: () => void
}

enum AssetKind {
  ec2 = 'EC2',
  ebs = 'EBS',
  s3 = 'S3',
  efs = 'EFS',
  server = 'Servers',
}

export interface BreadcrumbRoute {
  path: string
  getBreadcrumbs: (params: { [key: string]: string }) => Array<BreadcrumbItem>
}

const useGetBreadCrumbsRoutes = ({
  parentAsset,
  drilldownAsset,
}: {
  parentAsset: Asset | undefined
  drilldownAsset: Asset | undefined
}) => {
  const dispatch = useDispatch()
  // TODO: remove this after we migrate to the new assets page completely for the drilldowns
  const assetDetailsSource = useSelector(
    (state: RootState) => state.assetDetailsSource.source
  )

  if (!parentAsset) {
    return []
  }
  const getAssetKindLabel = (params: any) => {
    return params.assetKind
      ? AssetKind[params.assetKind.toLowerCase() as keyof typeof AssetKind] ||
          params.assetKind
      : ''
  }

  const parentAssetAwsId = isAwsAsset(parentAsset)
    ? parentAsset.awsId
    : undefined
  const parentAssetLabel =
    parentAsset instanceof OvaServer ? parentAsset.name : parentAssetAwsId

  const drilldownAssetAwsId =
    !!drilldownAsset && isAwsAsset(drilldownAsset)
      ? drilldownAsset.awsId
      : undefined
  const drilldownAssetLabel =
    drilldownAsset instanceof OvaServer
      ? drilldownAsset.name
      : drilldownAssetAwsId

  const drillDownLabel =
    drilldownAssetLabel && drilldownAssetAwsId !== parentAssetAwsId
      ? [{ label: drilldownAssetLabel }]
      : []

  return [
    {
      path: `/assets/:assetKind/details/:assetId/${ASSET_DETAILS_ACTIVE_TAB.recoveryPoint}/:recoveryPointId/${ASSET_DETAILS_ACTIVE_TAB.scanLog}/:scanJobId`,
      getBreadcrumbs: (params: any) => [
        {
          label: getAssetKindLabel(params),
          link: assetDetailsSource
            ? assetDetailsSource
            : `/assets/${params.assetKind}`,
          onClick: () => {
            // TODO: remove this after we migrate to the new assets page completely for the drilldowns
            dispatch(clearAssetDetailsSourcePath())
          },
        },
        {
          label: parentAssetLabel || parentAssetAwsId,
        },
      ],
    },
    {
      path: `/assets/:assetKind/details/:assetId/:activeTab?/:recoveryPointId/${ASSET_DETAILS_ACTIVE_TAB.scanLog}/:scanJobId/:volumeKind?/:volumeId?`,
      getBreadcrumbs: (params: any) => [
        {
          label: getAssetKindLabel(params),
          link: assetDetailsSource
            ? assetDetailsSource
            : `/assets/${params.assetKind}`,
          onClick: () => {
            // TODO: remove this after we migrate to the new assets page completely for the drilldowns
            dispatch(clearAssetDetailsSourcePath())
          },
        },
        {
          label: parentAssetLabel || parentAssetAwsId,
          link: `/assets/${params.assetKind}/details/${params.assetId}/${ASSET_DETAILS_ACTIVE_TAB.recoveryPoint}/${params.recoveryPointId}/${ASSET_DETAILS_ACTIVE_TAB.scanLog}/${params.scanJobId}${location.search}`,
        },
        ...drillDownLabel,
      ],
    },
    {
      path: '/assets/:assetKind/details/:assetId/:activeTab?/:recoveryPointId?',
      getBreadcrumbs: (params: any) => [
        {
          label: getAssetKindLabel(params),
          link: assetDetailsSource
            ? assetDetailsSource
            : `/assets/${params.assetKind}`,
          onClick: () => {
            // TODO: remove this after we migrate to the new assets page completely for the drilldowns
            dispatch(clearAssetDetailsSourcePath())
          },
        },
        {
          label: parentAssetLabel || parentAssetAwsId,
        },
      ],
    },
    {
      path: '/assets/:assetKind/details/:assetId/:activeTab?/:recoveryPointId?/:volumeKind?/:volumeId?',
      getBreadcrumbs: (params: any) => [
        {
          label: getAssetKindLabel(params),
          link: assetDetailsSource
            ? assetDetailsSource
            : `/assets/${params.assetKind}`,
          onClick: () => {
            // TODO: remove this after we migrate to the new assets page completely for the drilldowns
            dispatch(clearAssetDetailsSourcePath())
          },
        },
        {
          label: parentAssetLabel || parentAssetAwsId,
          link: `/assets/${params.assetKind}/details/${params.assetId}/${params.activeTab}/${params.recoveryPointId}${location.search}`,
        },
        ...drillDownLabel,
      ],
    },
  ]
}

export default useGetBreadCrumbsRoutes
