const getPreservedPath = (to: string): string => {
  const currentPath = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname

  const targetPath = to.startsWith('/') ? to.slice(1) : to
  return `${currentPath}/${targetPath}`
}

const mergeSearchParams = (path: string): string => {
  const currentSearchParams = new URLSearchParams(location.search)
  const targetSearchParams = new URLSearchParams(
    path.includes('?') ? path.split('?')[1] : ''
  )

  currentSearchParams.forEach((value, key) => {
    if (!targetSearchParams.has(key)) {
      targetSearchParams.append(key, value)
    }
  })

  const basePath = path.split('?')[0] ?? ''
  const searchString = targetSearchParams.toString()
  return searchString ? `${basePath}?${searchString}` : basePath
}

export const getTargetPath = (
  to: string,
  preservePath: boolean,
  preserveSearchParams: boolean,
  search: string
): string => {
  if (!to) {
    console.warn('The "to" prop is required for Link component.')
    return ''
  }

  let path = to

  if (preservePath) {
    path = getPreservedPath(to)
  }

  if (preserveSearchParams && search) {
    return mergeSearchParams(path)
  }

  return path
}
