/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetAccount as AssetAccountPB,
  RegionStack as RegionStackPB,
  RegionStackStatus,
} from 'blue-stack-libs/rex-grpc-libs/js/rex/messages/asset_account_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  AssetAccount,
  RegionStacksList,
  StatusCase,
  DeletionFailed,
  DeletionPending,
  InstallFailed,
  InstallPending,
  Linked,
} from 'blues-corejs/dist'

export class AssetAccountsTransformer {
  #assetAccountsProto: AssetAccountPB

  constructor(assetAccountsProto: AssetAccountPB) {
    this.#assetAccountsProto = assetAccountsProto
  }

  transform(): AssetAccount {
    return this.#transformAssetAccounts(this.#assetAccountsProto)
  }

  #transformAssetAccounts(assetAccountPB: AssetAccountPB) {
    const assetAccount = assetAccountPB.toObject()

    return new AssetAccount({
      id: assetAccount.id,
      awsAccountId: assetAccount.awsAccountId,
      alias: assetAccount.alias,
      version: assetAccount.version,
      connectorAwsAccountId: assetAccount.connectorAwsAccountId,
      description: assetAccount.description,
      stackRegion: assetAccount.stackRegion,
      regionStacksList: this.#transformRegionStacksList(
        assetAccountPB.getRegionStacksList()
      ),
      updatedAt: this.#transformTimestamp(assetAccount.updatedAt),
      createdAt: this.#transformTimestamp(assetAccount.createdAt),
    })
  }

  #transformRegionStacksList(
    regionStacksList: Array<RegionStackPB>
  ): Array<RegionStacksList> {
    const test: Array<RegionStacksList> = regionStacksList.map(
      (regionStack) => {
        const regionStackAsObject = regionStack.toObject()
        return {
          id: regionStackAsObject.id,
          awsRegion: regionStackAsObject.awsRegion,
          version: regionStackAsObject.version,
          cloudConnectorId: regionStackAsObject.cloudConnectorId,
          status: {
            statusCase: this.#transformStatusCase(
              regionStack.getStatus()?.getStatusCase()
            ),
            status: this.#transformStatus(regionStack.getStatus()?.toObject()),
          },
          updatedAt: this.#transformTimestamp(regionStackAsObject.updatedAt),
          createdAt: this.#transformTimestamp(regionStackAsObject.createdAt),
        }
      }
    )

    return test
  }

  #transformStatusCase(statusCase?: RegionStackStatus.StatusCase): StatusCase {
    if (!statusCase) {
      return StatusCase.STATUS_NOT_SET
    }

    const mapping = {
      [RegionStackStatus.StatusCase.INSTALL_PENDING]:
        StatusCase.INSTALL_PENDING,
      [RegionStackStatus.StatusCase.INSTALL_FAILED]: StatusCase.INSTALL_FAILED,
      [RegionStackStatus.StatusCase.LINKED]: StatusCase.LINKED,
      [RegionStackStatus.StatusCase.DELETION_PENDING]:
        StatusCase.DELETION_PENDING,
      [RegionStackStatus.StatusCase.DELETION_FAILED]:
        StatusCase.DELETION_FAILED,
      [RegionStackStatus.StatusCase.STATUS_NOT_SET]: StatusCase.STATUS_NOT_SET,
    }

    return mapping[statusCase]
  }

  #transformStatus(status?: RegionStackStatus.AsObject) {
    if (!status) {
      return undefined
    }

    if (status?.linked) {
      return new Linked()
    }

    if (status?.installPending) {
      return new InstallPending(status.installPending.jobId)
    }

    if (status?.installFailed) {
      return new InstallFailed(status.installFailed.errorMessage)
    }

    if (status?.deletionPending) {
      return new DeletionPending(status.deletionPending.jobId)
    }

    if (status?.deletionFailed) {
      return new DeletionFailed(status.deletionFailed.errorMessage)
    }
  }

  #transformTimestamp(timestamp?: Timestamp.AsObject): Date | undefined {
    if (!timestamp) {
      return undefined
    }

    return new Date(timestamp.seconds * 1000)
  }
}
