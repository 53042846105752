import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useToast } from 'ui-v2/src/hooks/toast'
import { WebhooksClient } from 'ui-v2/src/lib/clients'
import WebhookModel from 'ui-v2/src/lib/models/webhooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export function useCreateWebhookMutation() {
  const webhooksClient = useGrpcClient(WebhooksClient)
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (webhook: WebhookModel) => {
      if (!webhooksClient) {
        throw new Error('Webhooks client not initialized')
      }
      return webhooksClient.createWebhook(webhook)
    },
    onError: (error) => {
      toast.error(
        error.message ?? 'Something went wrong while creating webhook'
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['webhooks', 'list-webhooks'] })
      toast.success('Webhook has been created')
    },
  })
}
