import * as echarts from 'echarts'
import { Theme } from '@mui/material'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'

export function baseRadarChartOptions(theme: Theme) {
  const indicators = [
    {
      name: '',
      max: 100,
    },
    {
      name: '',
      max: 100,
    },
    {
      name: '',
      max: 100,
    },
    {
      name: '',
      max: 100,
    },
    {
      name: '',
      max: 100,
    },
  ]

  return {
    title: {
      text: '',
    },

    radar: {
      shape: 'circle',
      splitNumber: 1,
      indicator: indicators,
      axisName: {
        color: '#B2B5C1',
        fontSize: 14,
        fontWeight: 400,

        rich: {
          a: {
            fontSize: 16,
            fontWeight: 400,
            align: 'center',
            cursor: 'pointer',
          },
        },
      },

      splitArea: {
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0.1, 0, 0.1, 1, [
            {
              offset: 0,
              color: 'rgba(117, 125, 132, 0.1)',
            },
            {
              offset: 1,
              color: 'rgba(117, 125, 132, 0.1)',
            },
            {
              offset: 0,
              color: 'rgba(117, 125, 132, 0.1)',
            },
          ]),
        },
      },
      splitLine: {
        lineStyle: {
          color:
            theme.palette.mode === ThemeMode.DARK
              ? 'rgba(255,255,255,0.2)'
              : '#b0b0b0',
        },
      },
      axisLine: {
        lineStyle: {
          color:
            theme.palette.mode === ThemeMode.DARK
              ? 'rgba(255,255,255,0.2)'
              : '#b0b0b0',
        },
      },
    },
    axisPointer: {
      show: true,
      lineStyle: {
        color: '#aaa',
        width: 1,
        type: 'dashed',
      },
    },
  }
}

export function setRadarChartSeriesAndGraphicOptions(
  recoveryAssurancePercentage: number,
  assetCoveragePercentage: number,
  ransomwareSafetyPercentage: number,
  encryptionIntegrityPercentage: number,
  storageHealthPercentage: number,
  isLoading: boolean,
  resilienceScoreColor: string,
  theme: Theme
) {
  const areaStyle =
    resilienceScoreColor === 'red'
      ? {
          areaStyle: {
            normal: {
              color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.5, [
                {
                  offset: 0,
                  color: 'rgba(0, 0, 0, 0)',
                },
                {
                  offset: 1,
                  color: 'rgba(41, 133, 220, 0.45)',
                },
              ]),
            },
            emphasis: {
              color: '#87c0de',
              opacity: 0.9,
            },
          },
        }
      : resilienceScoreColor === 'yellow'
        ? {
            areaStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.5, [
                  {
                    offset: 0,
                    color: 'rgba(255, 171, 45, 0.71)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(115, 115, 115, 0.10)',
                  },
                ]),
              },
            },
          }
        : {
            areaStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.5, [
                  {
                    offset: 0,
                    color: 'rgba(49, 146, 70, 0.70)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(115, 115, 115, 0.10)',
                  },
                ]),
              },
            },
          }

  return {
    ...baseRadarChartOptions(theme),
    series: [
      {
        type: 'radar',
        data: [
          {
            value: isLoading
              ? [0, 0, 0, 0, 0]
              : [
                  recoveryAssurancePercentage || 0,
                  storageHealthPercentage || 0,
                  encryptionIntegrityPercentage || 0,
                  ransomwareSafetyPercentage || 0,
                  assetCoveragePercentage || 0,
                ],
            name: '360 Resilience',
            ...areaStyle,
            lineStyle: {
              color: 'rgba(0, 100, 193, 0.40)',
              width: 2,
            },
            symbol: 'circle',
            symbolSize: 0,
          },
        ],
      },
    ],
  }
}
