/* eslint-disable import/no-extraneous-dependencies */
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import {
  computeBackupName,
  computeThreatStatus,
} from 'ui-v2/src/lib/helpers/backup.helper'
import {
  formatDate,
  formatTimeDifference,
} from 'ui-v2/src/lib/helpers/time.helper'
import {
  EbsDrawer,
  Ec2Drawer,
  EfsDrawer,
  GenericHostDrawer,
  OvaServerDrawer,
  S3Drawer,
} from 'ui-v2/src/lib/models/assets'
import {
  Box,
  Button,
  Card,
  Grid2,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { Asset } from 'blues-corejs/dist'
import ThreatSummary from './threat-summary'
import CardContent from '@mui/material/CardContent'
import AssetsDrawerEntropyTrend from './entropy-trend'
import { Anomaly } from 'ui-v2/src/lib/models/anomalies'
import { Close } from '@mui/icons-material'
import {
  computeAssetKind,
  computeAssetKindColor,
} from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'

interface AssetDrawerDetailsProps {
  assetDetails:
    | Ec2Drawer
    | EbsDrawer
    | EfsDrawer
    | S3Drawer
    | OvaServerDrawer
    | GenericHostDrawer
  asset: Asset
  data: Array<Anomaly>
  handleCloseDrawer: () => void
}

const AssetDrawerDetails: React.FC<AssetDrawerDetailsProps> = ({
  assetDetails,
  asset,
  data,
  handleCloseDrawer,
}) => {
  const theme = useTheme()

  const templateColumns = assetDetails.lastBackup
    ? '2.7fr 2.7fr 3.2fr 4.4fr'
    : '4fr 4fr 8fr'

  const assetKind = computeAssetKind(asset)
  const assetKindColor = computeAssetKindColor(asset)

  const detailsBlockStyleByThemeType = {
    color: theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#727883',
    backgroundColor:
      theme.palette.mode === ThemeMode.DARK ? '#152635' : '#F6F9FC',
    border:
      theme.palette.mode === ThemeMode.DARK
        ? '1px solid #4E5665'
        : '1px solid #D3D8DE',
    borderRadius: '8px',
    padding: '16px',
  }

  if (!assetDetails) {
    return null
  }

  return (
    <>
      <Grid2 container spacing={0} marginTop={'16px'} sx={{ width: '100%' }}>
        <Grid2 container alignItems="center" gap={2}>
          <Button
            variant="contained"
            sx={{
              ...assetKindColor,
              color: '#ffffff',
              borderRadius: '16px',
              fontWeight: 'bold',
              fontSize: '14px',
              height: '32px',
              padding: '0 16px',
              textTransform: 'none',
              minWidth: 'unset',
            }}
          >
            {assetKind}
          </Button>

          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              marginRight: '16px',
              marginBottom: 0,
            }}
            gutterBottom
          >
            {assetDetails?.awsId}
          </Typography>
        </Grid2>
        <Grid2
          sx={{
            display: 'flex',
            gap: 1,
            flexGrow: 1,
            minWidth: '150px',
          }}
          size={6}
        >
          <ThreatSummary asset={asset} />
        </Grid2>
        <Grid2
          size={2}
          display="flex"
          justifyContent="flex-end"
          minWidth={'40px'}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              edge="end"
              aria-label="close"
              onClick={handleCloseDrawer}
            >
              <Close />
            </IconButton>
          </Box>
        </Grid2>
      </Grid2>
      <Grid2 container spacing={0}>
        <Box
          display="grid"
          gridTemplateColumns={templateColumns}
          gap={2}
          maxHeight={235}
          sx={{
            padding: '16px 0',
          }}
        >
          {/* Account Block */}
          <Card
            sx={{
              ...detailsBlockStyleByThemeType,
            }}
          >
            <CardContent sx={{ padding: 0 }}>
              {' '}
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                }}
                gutterBottom
              >
                Account
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  lineHeight: 1.5,
                  fontSize: '14px',
                }}
              >
                Account ID:
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                {assetDetails.awsAccountId}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginTop: '8px',
                  fontSize: '14px',
                  lineHeight: 1.5,
                }}
              >
                Region:
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                {LangAwsRegionConstant?.get(assetDetails?.awsRegion as string)
                  ?.en?.single ?? ''}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginTop: '8px',
                  fontSize: '14px',
                  lineHeight: 1.5,
                }}
              >
                Asset Kind:
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                {assetDetails.assetKind}
              </Typography>
            </CardContent>
          </Card>
          {/* Most Recent Scan Block */}
          <Card
            sx={{
              ...detailsBlockStyleByThemeType,
            }}
          >
            <CardContent sx={{ padding: 0 }}>
              {' '}
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                }}
                gutterBottom
              >
                Most Recent Scan
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '14px',
                }}
              >
                Scheduled on:
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                {formatDate(assetDetails?.lastScan?.createdAt)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginTop: '8px',
                  fontSize: '14px',
                }}
              >
                Completed on:
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                {formatDate(assetDetails?.lastScan?.completedAt)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginTop: '8px',
                  fontSize: '14px',
                }}
              >
                Duration:
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                {assetDetails?.lastScan?.createdAt &&
                assetDetails?.lastScan?.completedAt
                  ? formatTimeDifference(
                      assetDetails.lastScan.createdAt,
                      assetDetails.lastScan.completedAt
                    )
                  : 'N/A'}
              </Typography>
            </CardContent>
          </Card>
          {/* Most Recent Recovery Point Block */}
          {assetDetails.lastBackup ? (
            <Card
              sx={{
                ...detailsBlockStyleByThemeType,
              }}
            >
              <CardContent sx={{ padding: 0 }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '16px',
                  }}
                  gutterBottom
                >
                  Most Recent Recovery Point
                </Typography>

                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  Created On:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  {formatDate(assetDetails?.lastBackup?.timestamp)}
                </Typography>

                <Typography
                  sx={{
                    fontWeight: 600,
                    marginTop: '8px',
                    fontSize: '14px',
                  }}
                >
                  Provider:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  {computeBackupName(assetDetails?.lastBackup)}
                </Typography>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ marginTop: '8px' }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '14px',
                      }}
                    >
                      Ransomware:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {computeThreatStatus(
                        assetDetails?.lastBackup,
                        assetDetails?.lastBackup?.ransomwareCheckStatus
                      )}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '14px',
                      }}
                    >
                      Malware:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {computeThreatStatus(
                        assetDetails?.lastBackup,
                        assetDetails?.lastBackup?.malwareCheckStatus
                      )}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ) : null}
          {/* Encryption Activity Block */}
          <Card
            sx={{
              ...detailsBlockStyleByThemeType,
              padding: 0,
            }}
          >
            <CardContent>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                }}
                gutterBottom
              >
                Insider Threat
              </Typography>
              <AssetsDrawerEntropyTrend
                data={data}
                styles={detailsBlockStyleByThemeType}
              />
            </CardContent>
          </Card>
        </Box>
      </Grid2>
    </>
  )
}

export default AssetDrawerDetails
