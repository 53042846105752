import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useQuery } from '@tanstack/react-query'
import { RexClient } from 'ui-v2/src/lib/clients'

export function useGetConnectorAccountInstallFlow(accountId?: string) {
  const rexClient = useGrpcClient(RexClient)

  return useQuery({
    queryKey: ['rex', 'get-cloud-connector-account-install-flow', accountId],
    queryFn: async () => {
      if (!rexClient) {
        throw new Error('Rex client not initialized')
      }
      if (!accountId) {
        throw new Error('Account Id is required')
      }
      return rexClient.getConnectorAccountInstallFlow(accountId)
    },
    enabled: !!rexClient && !!accountId,
  })
}
