/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import { useAssetsListByIdQuery } from 'ui-v2/src/hooks/queries/assets'
import SuccessContent from './success'
import LoadingContent from '../common/loading'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import { EBS } from 'blues-corejs/dist'
import WidgetCard from '../common/widget-card'
import { useAssetDetails } from '../common/use-asset-details'

const AwsAccountDetailsWidget = () => {
  const { asset, isAssetsDataLoading, isAssetsDataError } = useAssetDetails()

  // run query if the asset is EBS and has an instance attached to it
  const shouldFetchInstance =
    asset instanceof EBS && !!asset?.attachedInstanceIds?.[0]
  const instanceId = shouldFetchInstance ? asset.attachedInstanceIds[0] : ''

  const {
    data: instanceData,
    isLoading: isInstanceDataLoading,
    isError: isInstanceDataError,
  } = useAssetsListByIdQuery(instanceId ? [instanceId] : [], {
    enabled: shouldFetchInstance,
  })

  const attachedInstance = useMemo(() => {
    if (!instanceData?.assetsList?.[0]) {
      return undefined
    }

    return instanceData.assetsList[0].ec2Instance
  }, [instanceData])

  const renderContent = () => {
    if (isAssetsDataLoading || isInstanceDataLoading) {
      return <LoadingContent />
    }
    if (isAssetsDataError || isInstanceDataError) {
      return (
        <ErrorDisplay message="Failed to load widget, please try again later." />
      )
    }
    return <SuccessContent asset={asset} attachedInstance={attachedInstance} />
  }

  return <WidgetCard>{renderContent()}</WidgetCard>
}

export default AwsAccountDetailsWidget
