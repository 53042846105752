import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const DrawerLoader = () => {
  return (
    <Box
      sx={{
        paddingX: 2.5,
        paddingBottom: 2.5,
        minHeight: '200px',
        position: 'relative',
      }}
    >
      <Backdrop
        sx={(theme) => ({
          color: 'var(--grey500)',
          zIndex: theme.zIndex.drawer + 1,
          position: 'absolute',
          background: 'transparent',
        })}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default DrawerLoader
