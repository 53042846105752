import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import {
  ASSET_FILTERS_MAP,
  LAST_SCAN_STATE_MAP,
} from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const LastScanFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const scan = searchParams.get(ASSET_FILTERS_MAP.LAST_SCAN)?.split(',') || []

  const scanOptions = useMemo(() => {
    return Object.entries(LAST_SCAN_STATE_MAP).map(([key, value]) => ({
      label: value,
      value: key.toString(),
    }))
  }, [])

  const handleScanChange = (newScanList: Array<MultiSelectOption['value']>) => {
    if (newScanList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.LAST_SCAN)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.LAST_SCAN, newScanList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={scanOptions}
      value={scan}
      onChange={handleScanChange}
      label={
        <SelectLabelText
          selectedValueLength={scan?.length}
          optionsLength={scanOptions?.length}
          text="Last Scan"
        />
      }
      width={130}
    />
  )
}

export default LastScanFilter
