/* eslint-disable import/no-extraneous-dependencies */
import { useMemo, useState } from 'react'
import { useAssetsListBackupsQuery } from 'ui-v2/src/hooks/queries/backups'
import { RecoveryTableData } from 'ui-v2/src/lib/use-cases/assets/infected-assets/recovery-table-data'
import { Asset } from 'blues-corejs/dist'
import { getRecoveryPointsColumns } from './column-config'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'

const RecoveryPoints = ({ asset }: RecoveryPointsProps) => {
  const [pageSize, setPageSize] = useState<number>(
    TABLE_ROWS_PER_PAGE_OPTIONS[0]
  )
  const [pageToken, setPageToken] = useState<string | undefined>(undefined)

  const { data: backupData, isLoading } = useAssetsListBackupsQuery({
    assetIdList: asset ? [asset.id] : [],
    pageSize: pageSize,
    pageToken: pageToken,
  })
  const sortedBackups = useMemo(() => {
    if (!backupData) {
      return
    }

    return [
      ...backupData.awsbRpsList,
      ...backupData.elastioRpsList,
      ...backupData.ebsSnapshotsList,
      ...backupData.ovaBackupList,
    ].sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
  }, [backupData])

  const data = useMemo(() => {
    return new RecoveryTableData({
      recoveryList: sortedBackups ?? [],
    }).getRecoveryList()
  }, [sortedBackups])

  const columns = useMemo(getRecoveryPointsColumns, [])

  return (
    <CursorTable
      checkable={false}
      data={data}
      columns={columns}
      isLoading={isLoading}
      pageSize={pageSize}
      pageToken={pageToken}
      paginationCursors={{
        nextPageToken: backupData?.pagination?.pageToken,
        prevPageToken: backupData?.pagination?.prevPageToken,
      }}
      setPageSize={setPageSize}
      setPageToken={setPageToken}
    />
  )
}
interface RecoveryPointsProps {
  asset: Asset
}
export default RecoveryPoints
