import { MenuItem, Select, styled } from '@mui/material'
import { MultiSelectOption } from 'ui-v2/src/components/ui/inputs/multiple-select-filter'

export const StyledSelect = styled(Select<Array<MultiSelectOption['value']>>)(
  ({ theme }) => ({
    color: theme.palette.text.muted,
    backgroundColor: theme.palette.background.paper,

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.background.paper,
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      // borderColor: theme.palette.primary.main,
      borderColor: 'red',
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.main,
    },

    '&:hover .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiSelect-select': {
      paddingRight: '48px !important',
    },
  })
)

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  transition: 'all 0.2s ease-in-out',

  '&.Mui-selected': {
    backgroundColor: theme.palette.background.secondary,

    '&:hover': {
      backgroundColor: theme.palette.background.hover,
    },

    '& .MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },

    '& .MuiListItemText-primary': {
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
  },

  '&:hover': {
    backgroundColor: theme.palette.background.hover,

    '& .MuiCheckbox-root': {
      backgroundColor: 'transparent',
    },

    '& .MuiListItemText-primary': {
      color: theme.palette.text.primary,
    },
  },

  '&:active': {
    backgroundColor: theme.palette.background.secondary,

    '& .MuiCheckbox-root': {
      backgroundColor: 'transparent',
    },
  },
}))
