import { useQuery } from '@tanstack/react-query'
import { useScanJobService } from '../../rest-service'

interface Options {
  enabled?: boolean
}

export function useGetScanJobByIdQuery(
  assetId: string | undefined,
  scanJobId: string | undefined,
  options?: Options
) {
  const {
    getScanJobById,
    isReady,
    isLoading: isClientLoading,
  } = useScanJobService()

  return useQuery({
    queryKey: ['assets', assetId, 'scan-job', scanJobId],
    queryFn: async () => {
      if (!assetId || !scanJobId) {
        throw new Error('assetId or scanJobId are required')
      }

      return getScanJobById(assetId, scanJobId)
    },
    enabled:
      Boolean(assetId) &&
      Boolean(scanJobId) &&
      isReady &&
      !isClientLoading &&
      (options?.enabled === undefined ? true : options.enabled),
  })
}
