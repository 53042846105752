import { Dispatch, SetStateAction } from 'react'
import {
  FieldErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import {
  ApiKey,
  BasicAuth,
  BearerToken,
  Secret,
  WebhookAuthentication,
  WebhookAuthType,
  WebhookFormData,
  Severity,
  EventList,
  VersionType,
} from 'ui-v2/src/lib/models/webhooks'
import { MultiSelectOption } from 'ui-v2/src/components/ui/inputs/multiple-select-filter'

export function setWebhookAuthType(
  webhookAuthentication: WebhookAuthentication,
  setAuthType: Dispatch<SetStateAction<WebhookAuthType>>,
  setValue: UseFormSetValue<WebhookFormData>
) {
  const fillDefaultAuthDataForm = () => {
    const webhookAuthType = webhookAuthentication.intoInner()
    if (webhookAuthType instanceof BasicAuth) {
      const basic = new BasicAuth(
        new Secret({
          secret: webhookAuthType.getLogin(),
          encrypted: true,
        }),
        new Secret({
          secret: webhookAuthType.getPassword(),
          encrypted: true,
        })
      )

      return {
        login: basic.getLogin(),
        password: basic.getPassword(),
      }
    }
    if (webhookAuthType instanceof ApiKey) {
      const apiKey = new ApiKey(
        new Secret({
          secret: webhookAuthType.getKey(),
          encrypted: true,
        }),
        new Secret({
          secret: webhookAuthType.getValue(),
          encrypted: true,
        })
      )
      return {
        apiKey: apiKey.getKey(),
        apiValue: apiKey.getValue(),
      }
    }
    if (webhookAuthType instanceof BearerToken) {
      const bearer = new BearerToken(
        new Secret({
          secret: webhookAuthType.getToken(),
          encrypted: true,
        })
      )
      return {
        token: bearer.getToken(),
      }
    }
    return {}
  }

  switch (webhookAuthentication.getAuthTypeToString()) {
    case 'basic_authorization':
      setAuthType('basic_authorization')
      setValue(
        'authentication.basic_auth.login.value',
        fillDefaultAuthDataForm().login as string
      )
      setValue(
        'authentication.basic_auth.password.value',
        fillDefaultAuthDataForm().password as string
      )
      break
    case 'bearer':
      setAuthType('bearer')
      setValue(
        'authentication.bearer_token.token.value',
        fillDefaultAuthDataForm().token as string
      )
      break
    case 'api_key':
      setAuthType('api_key')
      setValue(
        'authentication.api_key.key.value',
        fillDefaultAuthDataForm().apiKey as string
      )
      setValue(
        'authentication.api_key.value',
        fillDefaultAuthDataForm().apiValue as string
      )
      break
    case 'none':
      setAuthType('none')
      break
    default:
      setAuthType('none')
  }
}

export const mapSeverityToEnum = (severity: string): Severity => {
  switch (severity.toLowerCase()) {
    case 'error':
      return Severity.ERROR
    case 'warning':
      return Severity.WARNING
    case 'info':
      return Severity.INFO
    case 'trace':
      return Severity.TRACE
    default:
      return Severity.ERROR
  }
}

export const webhookFormDefaultValue = {
  name: '',
  endpoint: '',
  authentication: {
    basic_auth: {
      login: {
        old_value: null,
        value: '',
      },
      password: {
        old_value: null,
        value: '',
      },
    },
    bearer_token: {
      token: {
        old_value: null,
        value: '',
      },
    },
    api_key: {
      key: {
        old_value: null,
        value: '',
      },
      value: '',
    },
  },
  description: '',
  event_subscriptions: [],
}

export const stripProtocol = (url: string) => {
  return url.replace(/^\s*https?:\/\//, '').trim()
}

export interface WebhookFormProps {
  handleSubmit: UseFormHandleSubmit<WebhookFormData, undefined>
  onSubmit: (data: WebhookFormData) => void
  register: UseFormRegister<WebhookFormData>
  errors: FieldErrors<WebhookFormData>
  setValue: UseFormSetValue<WebhookFormData>
  setAuthType: Dispatch<SetStateAction<WebhookAuthType>>
  authType: WebhookAuthType
  eventList: Array<EventList>
  handleSeverityChange: (
    newSeverityList: Array<MultiSelectOption['value']>
  ) => void
  handleAddEvent: () => void
  handleDeleteEvent: (index: number) => void
  eventType: string
  setEventType: Dispatch<SetStateAction<string>>
  versionType: string
  setVersionType: Dispatch<SetStateAction<VersionType>>
  action: string | undefined
  isSubmitDisabled: boolean
  severityList: Array<string>
  isAddDisabled: boolean
  getValues: UseFormGetValues<WebhookFormData>
  watch: UseFormWatch<WebhookFormData>
  trigger: UseFormTrigger<WebhookFormData>
  webHookName: Array<string>
}
