import { useEffect } from 'react'
import Grid from '@mui/material/Grid2'
import { FormControl, Typography } from '@mui/material'

import { Controller, useFormContext } from 'react-hook-form'

import ReportPeriod from '../report-period'
import { DEFAULT_TIME_ZONE } from 'ui-v2/src/lib/constants/time.constant'
import { getReportDateRangeByPeriod } from 'ui-v2/src/lib/helpers/time.helper'
import ReportDateRangeConstant, {
  REPORT_DEFAULT_POSSIBLE_PERIODS,
} from 'ui-v2/src/lib/constants/report-date-range.constant'
import { DateRangeTuple } from 'ui-v2/src/lib/engine-types'
import Format from '../format'
import { ReportForm } from 'ui-v2/src/lib/models/reports'
import dayjs from 'dayjs'
import DateRangePicker from 'ui-v2/src/components/ui/inputs/date-range-picker'

const AwsForm = ({ availableFormats }: AwsFormProps) => {
  const timezone = DEFAULT_TIME_ZONE().value
  const { control, watch, setValue, trigger } = useFormContext<ReportForm>()
  const reportPeriod = watch('reportPeriod')
  const selectDateRange = watch('dateRange')

  const selectedDateRange = getReportDateRangeByPeriod(
    {
      label: reportPeriod.label,
      value: +reportPeriod.value,
    },
    timezone
  )
  const dateRange: DateRangeTuple =
    +reportPeriod.value === ReportDateRangeConstant.CustomDate
      ? selectDateRange
      : selectedDateRange

  useEffect(() => {
    setValue('dateRange', dateRange)
  }, [reportPeriod])

  return (
    <Grid container spacing={2}>
      <Grid
        size={{
          xs: 12,
          sm: 4,
          md: 4,
        }}
      >
        <FormControl fullWidth size="small">
          <Typography variant="body1" mb={1}>
            Report Period
          </Typography>
          <Controller
            control={control}
            rules={{ required: 'Report Period is required' }}
            name="reportPeriod"
            render={({ field, fieldState }) => (
              <ReportPeriod
                value={field.value.value.toString()}
                onChange={(period) => {
                  field.onChange(period)
                }}
                error={!!fieldState.error?.message}
                isSelectedObj
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 8,
          md: 8,
        }}
      >
        <FormControl fullWidth size="small">
          <Typography variant="body1" mb={1}>
            Date Range
          </Typography>
          <Controller
            control={control}
            name="dateRange"
            render={({ field }) => (
              <DateRangePicker
                key={
                  (field.value[0]?.toString() ?? '') +
                  (field.value[1]?.toString() ?? '')
                }
                initialDateRange={{
                  startDate: dayjs(field.value[0]),
                  endDate: dayjs(field.value[1]),
                }}
                onChange={(range) => {
                  field.onChange([range.startDate, range.endDate])
                  trigger('dateRange')
                  const customReportPeriod =
                    REPORT_DEFAULT_POSSIBLE_PERIODS.find(
                      (period) =>
                        period.value === ReportDateRangeConstant.CustomDate
                    )

                  if (customReportPeriod) {
                    setValue('reportPeriod', {
                      label: customReportPeriod.name,
                      value: customReportPeriod.value,
                    })
                  }
                }}
                label={false}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid size={12}>
        <FormControl>
          <Typography variant="body1" mb={1}>
            Format:
          </Typography>
          <Controller
            control={control}
            rules={{
              validate: (value) => {
                if (!value || value.length === 0) {
                  return 'Please select at least one format'
                }
                return true
              },
            }}
            name="format"
            render={({ field, fieldState }) => (
              <Format
                options={availableFormats.map((format) => ({
                  label: format,
                  value: format,
                }))}
                selectedOptions={field.value}
                onChange={(selected) => {
                  field.onChange(selected)
                  trigger('format')
                }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
interface AwsFormProps {
  availableFormats: Array<string>
}
export default AwsForm
