const NavigateNextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_2881_1190)">
        <path
          d="M9.39357 6.71002C9.00357 7.10002 9.00357 7.73002 9.39357 8.12002L13.2736 12L9.39357 15.88C9.00357 16.27 9.00357 16.9 9.39357 17.29C9.78357 17.68 10.4136 17.68 10.8036 17.29L15.3936 12.7C15.7836 12.31 15.7836 11.68 15.3936 11.29L10.8036 6.70002C10.4236 6.32002 9.78357 6.32002 9.39357 6.71002Z"
          fill="#727883"
        />
      </g>
      <defs>
        <clipPath id="clip0_2881_1190">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.103516)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NavigateNextIcon
