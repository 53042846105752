/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import {
  Box,
  Typography,
  Divider,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  circularProgressClasses,
} from '@mui/material'
import { useCloudConnectorJobQuery } from 'ui-v2/src/hooks/queries/jobs'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import RedStackModel from 'ui-v2/src/lib/models/base-model/red-stack.model'
import {
  getCloudConnectorJobStatusMessage,
  STATUS_TO_STYLE_VARIANT_MAPPING,
} from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job'
import dayjs from 'dayjs'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import ProgressCell from 'ui-v2/src/components/ui/data-display/progress-cell'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useUserTimezoneValue } from 'ui-v2/src/hooks/user'

dayjs.extend(utc)
dayjs.extend(timezone)

interface CollapsibleDetailsDrawerProps {
  monitorId: string
}

const CollapsibleDetailsDrawer = ({
  monitorId,
}: CollapsibleDetailsDrawerProps) => {
  const { data: monitorDetailsData, isLoading: isMonitorDetailsLoading } =
    useCloudConnectorJobQuery(monitorId)

  const { data: redStackListQuery } = useRedStackListQuery()
  const userTimezone = useUserTimezoneValue()

  const redStackListData = useMemo(() => {
    return redStackListQuery?.map(
      (data) =>
        new RedStackModel({
          redStackId: data.redStackId,
          accountAlias: data.accountAlias,
          awsRegion: data.awsRegion,
          awsAccount: data.awsAccountId,
          status: data.status,
          iamRole: data.iamRole,
          accountDescription: data.accountDescription,
          lastErrorMessage: data.lastErrorMessage,
          version: data.version,
          lastWarningMessage: data.lastWarningMessage,
          awsAccountCfnVersion: data.awsAccountCfnVersion,
          lastIntegrityCheckErrorsList: data.lastIntegrityCheckErrors,
          cfnUpgradeRequired: data.cfnUpgradeRequired,
        })
    )
  }, [redStackListQuery])

  const redstack = useMemo(() => {
    if (!redStackListData || !monitorDetailsData) {
      return undefined
    }

    return redStackListData.find(
      (r) => String(r.redStackId) === monitorDetailsData.ccId
    )
  }, [redStackListData, monitorDetailsData])

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#141218' : '#f7f9fc',
        paddingLeft: '68px',
      })}
    >
      {isMonitorDetailsLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          p={4}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            borderLeft:
              theme.palette.mode === 'dark'
                ? '1px solid rgba(204, 204, 220, 0.2)'
                : '1px solid rgba(204, 204, 220, 0.85)',
            paddingLeft: '26px',
          })}
        >
          <Box display={'flex'} flexWrap={'wrap'} columnGap={6} paddingY={1}>
            <Typography
              gap={0.5}
              mb={0.5}
              display={'flex'}
              alignItems={'center'}
              variant="body2"
              fontWeight={400}
            >
              <Typography variant="body2" fontWeight={400}>
                Account:
              </Typography>
              {redstack?.awsAccount ?? ''}
            </Typography>
            <Typography
              gap={0.5}
              mb={0.5}
              display={'flex'}
              alignItems={'center'}
              variant="body2"
              fontWeight={400}
            >
              <Typography variant="body2" fontWeight={400}>
                Region:
              </Typography>
              {redstack?.awsRegion ?? ''}
            </Typography>
          </Box>
          {monitorDetailsData?.childrenList?.length ? <Divider /> : null}
          {!!monitorDetailsData?.childrenList &&
            monitorDetailsData?.childrenList?.length > 0 &&
            monitorDetailsData?.childrenList?.map((childJob) => (
              <TableContainer>
                <Table sx={{ tableLayout: 'fixed' }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent !important',
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        <Box
                          display={'flex'}
                          sx={{
                            position: 'relative',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {STATUS_TO_STYLE_VARIANT_MAPPING[childJob.status] ===
                          'Failed' ? (
                            <>
                              <CircularProgress
                                variant="determinate"
                                sx={{ color: '#4E5665' }}
                                size={20}
                                thickness={8}
                                value={0}
                              />
                              <CircularProgress
                                variant="determinate"
                                sx={{
                                  color: '#eb5757',
                                  position: 'absolute',
                                  left: 0,
                                  [`& .${circularProgressClasses.circle}`]: {
                                    strokeLinecap: 'round',
                                  },
                                }}
                                size={20}
                                thickness={8}
                                value={100}
                              />
                              <Typography
                                marginLeft={1}
                                variant="body2"
                                whiteSpace={'nowrap'}
                              >
                                Job Failed
                              </Typography>
                            </>
                          ) : childJob.status ===
                            CloudConnectorJobStatus.ABORTED ? (
                            <Typography
                              marginLeft={1}
                              variant="body2"
                              whiteSpace="nowrap"
                            >
                              Aborted
                            </Typography>
                          ) : STATUS_TO_STYLE_VARIANT_MAPPING[
                              childJob.status
                            ] === 'Success' ? (
                            <>
                              <CircularProgress
                                variant="determinate"
                                sx={{ color: '#4E5665' }}
                                size={20}
                                thickness={8}
                                value={0}
                              />
                              <CircularProgress
                                variant="determinate"
                                sx={{
                                  color: '#27ae60',
                                  position: 'absolute',
                                  left: 0,
                                  [`& .${circularProgressClasses.circle}`]: {
                                    strokeLinecap: 'round',
                                  },
                                }}
                                size={20}
                                thickness={8}
                                value={100}
                              />

                              <Typography
                                marginLeft={1}
                                variant="body2"
                                whiteSpace={'nowrap'}
                              >
                                {dayjs(childJob?.finishedAt)
                                  .tz(userTimezone)
                                  .format('MM/DD/YY hh:mm A')}
                              </Typography>
                            </>
                          ) : (
                            <ProgressCell
                              progress={
                                Number(childJob?.progress?.toFixed(2)) || 0
                              }
                              status={childJob.status}
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent !important',
                          textAlign: 'left',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        <LongTextTooltip
                          text={childJob?.name.split(',')?.[0] ?? ''}
                          placement={'bottom-start'}
                          maxLength={50}
                          fontSize={'0.875rem'}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent !important',
                          textAlign: 'left',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        <LongTextTooltip
                          text={getCloudConnectorJobStatusMessage(
                            childJob?.statusMessage,
                            childJob?.status
                          )}
                          placement={'bottom-start'}
                          maxLength={50}
                          fontSize={'0.875rem'}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
        </Box>
      )}
    </Box>
  )
}

export default CollapsibleDetailsDrawer
