import { useRestClient } from 'ui-v2/src/hooks/rest-client'
import {
  ScanLogResponse,
  ListScanJobParams,
  Entities,
  ScanJobData,
  AssetThreatsParams,
  AssetThreatsResponse,
} from 'ui-v2/src/lib/models/backups'

export const useScanJobService = () => {
  const { isReady, isLoading, get } = useRestClient()

  const listScanLog = async (assetId: string, params: ListScanJobParams) => {
    if (!isReady) {
      throw new Error('REST client for scan log is not initialized')
    }

    const url = `api/v1/assets/${assetId}/scanjobs`
    return get<ScanLogResponse>(url, params)
  }

  const listScanJobEntities = async (assetId: string, scanJobId: string) => {
    if (!isReady) {
      throw new Error(
        'REST client is not initialized for recovery points backup entities'
      )
    }

    const url = `api/v1/assets/${assetId}/scanjobs/${scanJobId}/entities`
    return get<Array<Entities>>(url)
  }

  const getScanJobById = async (assetId: string, scanJobId: string) => {
    if (!isReady) {
      throw new Error(
        'REST client is not initialized for recovery points backup'
      )
    }

    const url = `api/v1/assets/${assetId}/scanjobs/${scanJobId}`
    return get<ScanJobData>(url)
  }

  const listAssetThreatsForScanJob = async (
    assetId: string,
    scanJobId: string,
    params: AssetThreatsParams
  ) => {
    if (!isReady) {
      throw new Error('REST client is not initialized')
    }

    const url = `api/v1/assets/${assetId}/scanjobs/${scanJobId}/entities/assets/${assetId}/threats`
    return get<AssetThreatsResponse>(url, params)
  }

  return {
    listScanLog,
    listScanJobEntities,
    getScanJobById,
    listAssetThreatsForScanJob,
    isReady,
    isLoading,
  }
}
