// Define drawer widths in rem
export const DRAWER_WIDTH = '4.5rem' // 72px equivalent
export const MOBILE_DRAWER_WIDTH = '16rem' // 256px equivalent

//Define the navbar height in rem
export const TOOLBAR_HEIGHT = '3.75rem' // 60px equivalent

export const TABLE_ROWS_PER_PAGE_OPTIONS = [15, 50, 100] as const

export enum RadarChartAxes {
  RECOVERY_ASSURANCE = 'recovery-assurance',
  ASSET_COVERAGE = 'asset-coverage',
  RANSOMWARE_SAFETY = 'ransomware-safety',
  ENCRYPTION_INTEGRITY = 'encryption-integrity',
  STORAGE_HEALTH = 'storage-health',
}

export const DEFAULT_RPO_HOURS = 48
export const RPO_HOURS_LOCAL_STORAGE_KEY = 'rpoHours'
