/* eslint-disable import/no-extraneous-dependencies */
import {
  AllKinds,
  EmailChannel,
  ExactEvents,
  NotificationChannels,
  RuleAwsAccountRegion,
  RuleCriteria,
  RuleMode,
  RuleNotify,
  RuleOvaBackupProvider,
  RuleStatus,
  RuleSuppressUntil,
  TenantMemberEmailChannel,
  WebhookChannel,
} from 'blues-corejs/dist/models/notifications/rule'
import { RedStackModel } from 'ui-v2/src/lib/models/base-model'
import { OvaAccount, UserProfile } from 'blues-corejs/dist/models'
import { CreateNotificationRuleCriteria } from 'ui-v2/src/lib/clients/alerts/types'
import { WebhooksListItem } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'
import dayjs from 'dayjs'
import {
  Tag,
  TagsOperator,
} from 'blues-corejs/dist/models/notifications/rule/criteria'
import { NestedOption } from 'ui-v2/src/components/ui/inputs/nested-select'
import { HUMAN_READABLE_OVA_BACKUP_PROVIDER } from '@lib/constants/reports/consts'

export const processRedStacksData = (
  data: Array<RedStackModel> | undefined
) => {
  if (!data) {
    return { accounts: [] }
  }

  const accountMap = new Map<string, Set<string>>()

  data.forEach((item) => {
    const accountId = item.awsAccount
    const region = item.awsRegion

    if (!accountId || !region) {
      return
    }

    if (!accountMap.has(accountId)) {
      accountMap.set(accountId, new Set())
    }
    accountMap.get(accountId)?.add(region)
  })

  const accounts = Array.from(accountMap)
    .map(([accountId, regions]) => ({
      id: accountId,
      regions: Array.from(regions).sort(),
    }))
    .sort((a, b) => a.id.localeCompare(b.id))

  return { accounts }
}

const getMembersEmails = ({
  emails,
  userProfilesList,
}: {
  emails: Set<string>
  userProfilesList?: Array<UserProfile>
}): {
  tenantEmailsList: Array<TenantMemberEmailChannel>
  emailList: Array<EmailChannel>
} => {
  const emailsList = emails

  const tenantEmails =
    userProfilesList?.filter((profile) => {
      const result = emails.has(profile.email)
      if (result) {
        emailsList.delete(profile.email)
      }
      return result
    }) || []

  return {
    emailList: [...emailsList].map((email) => ({
      email,
    })),
    tenantEmailsList: tenantEmails.map((profile) => ({
      customer_id: profile.id,
    })),
  }
}

const getWebhooks = ({
  webhooks,
  webhooksList,
}: {
  webhooks: Set<string>
  webhooksList?: Array<WebhooksListItem.AsObject>
}): Array<WebhookChannel> => {
  const filteredList =
    webhooksList?.filter((hook) => webhooks.has(hook.name)) || []

  return filteredList.map((hook) => ({
    webhookId: hook.id,
  }))
}

const getRuleMode = ({
  isSuppress,
  suppressReason,
  suppressUntil,
}: {
  isSuppress: boolean
  suppressUntil: dayjs.Dayjs
  suppressReason: string
}): RuleMode => {
  if (isSuppress) {
    return new RuleSuppressUntil(suppressUntil.toDate(), suppressReason)
  }

  return new RuleNotify()
}

const getStatus = (isEnabled: boolean): RuleStatus => {
  return isEnabled ? RuleStatus.enabled : RuleStatus.disabled
}

const getCriteria = ({
  tags,
  tagOperator,
  selectedEvents,
  isAllEventsSelected,
  selectedRegions,
  selectedAccounts,
  ovaAccountsList,
}: {
  tags: Array<Tag>
  tagOperator: TagsOperator
  selectedEvents: Array<string>
  isAllEventsSelected: boolean
  selectedRegions: Array<RuleAwsAccountRegion>
  selectedAccounts: Set<string>
  ovaAccountsList?: Array<OvaAccount>
}): RuleCriteria => {
  const eventKinds = new ExactEvents(selectedEvents)
  const selectedAccountsIds = new Set(
    Array.from(selectedAccounts).map((account) => {
      const [accountId] = account.split('||')
      return accountId
    })
  )

  const filteredOvaAccountsList =
    ovaAccountsList?.filter((ova) => selectedAccountsIds.has(ova.id)) || []

  return {
    tags: {
      tags: tags,
      operator: tagOperator,
    },
    eventKinds: isAllEventsSelected ? new AllKinds() : eventKinds,
    ovaAccountProviders: filteredOvaAccountsList.map((ova) => ({
      accountId: ova.id,
      provider: ova.backupProvidersList as unknown as RuleOvaBackupProvider,
    })),
    awsAccountRegions: selectedRegions,
    assetIds: [],
  }
}

interface RequestCriteria {
  name: string
  description: string
  emails: Array<string>
  userProfilesList?: Array<UserProfile>
  slack: boolean
  webhooks: Set<string>
  webhooksList?: Array<WebhooksListItem.AsObject>
  isSuppress: boolean
  suppressUntil: dayjs.Dayjs
  suppressReason: string
  isEnabled: boolean
  tags: Array<Tag>
  tagOperator: TagsOperator
  selectedEvents: Array<string>
  isAllEventsSelected: boolean
  selectedRegions: Array<RuleAwsAccountRegion>
  selectedAccounts: Set<string>
  ovaAccountsList?: Array<OvaAccount>
}

export const getCreateNotificationCriteria = ({
  name,
  description,
  emails,
  userProfilesList,
  slack,
  webhooksList,
  webhooks,
  suppressReason,
  suppressUntil,
  isSuppress,
  isEnabled,
  ovaAccountsList,
  selectedAccounts,
  selectedEvents,
  isAllEventsSelected,
  selectedRegions,
  tagOperator,
  tags,
}: RequestCriteria): CreateNotificationRuleCriteria => {
  const { emailList, tenantEmailsList } = getMembersEmails({
    emails: new Set([...emails]),
    userProfilesList,
  })
  const channels: NotificationChannels = {
    tenantMemberEmails: tenantEmailsList,
    emails: emailList,
    webhooks: getWebhooks({
      webhooks,
      webhooksList,
    }),
    slack,
  }
  const mode = getRuleMode({
    isSuppress,
    suppressReason,
    suppressUntil,
  })

  const status = getStatus(isEnabled)
  const criteria = getCriteria({
    selectedAccounts,
    ovaAccountsList,
    isAllEventsSelected,
    selectedRegions,
    selectedEvents,
    tagOperator,
    tags,
  })

  return {
    name,
    description,
    mode,
    channels,
    status,
    criteria: criteria,
    assetsIds: [],
  }
}

export const transformOvaAccountsToNestedOptions = (
  ovaAccountsList?: Array<OvaAccount>
): Array<NestedOption> => {
  const nestedOptions = new Map<string, Set<string>>()
  const accountsName = new Map<string, string>()

  ovaAccountsList?.forEach((ova) => {
    const { backupProvidersList, id, name } = ova
    const convertedSet = new Set(backupProvidersList.map(String))
    nestedOptions.set(name, convertedSet)
    accountsName.set(name, id)
  })

  const sampleOptions: Array<any> = []

  nestedOptions.forEach((backupProviders, accountName) => {
    const accountId = accountsName.get(accountName)
    const children = Array.from(backupProviders).map((providerKey) => {
      const readableName =
        // @ts-ignore
        HUMAN_READABLE_OVA_BACKUP_PROVIDER[providerKey]?.toUpperCase() ||
        providerKey
      return {
        id: `${accountId}||${readableName}`,
        name: readableName,
      }
    })

    sampleOptions.push({
      id: accountId,
      name: accountName,
      children,
    })
  })

  return sampleOptions
}
