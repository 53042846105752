import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { getOvaServerColumns } from './column-config'
import AssetFilters from '../filters'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useSearchParams } from 'react-router-dom'
import { useAssetsOvaServerFilters } from 'ui-v2/src/hooks/filters/assets'
import { useListOvaAccountsQuery } from 'ui-v2/src/hooks/queries/ova-accounts'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useNewAssetsSearchQuery } from 'ui-v2/src/hooks/queries/search'
import { useSortParams } from 'ui-v2/src/hooks/sort-params'
import { convertServerSortConfigsToProto } from './sort-mapping'

const AssetsOvaServer = () => {
  const { sortConfigs } = useSortParams()

  const [searchParams] = useSearchParams()

  const { filters, setPageSize, setPageToken } = useAssetsOvaServerFilters()

  const { data: assetsData, isLoading: isAssetsDataLoading } =
    useNewAssetsSearchQuery({
      servers: {
        ...filters,
        ...convertServerSortConfigsToProto(sortConfigs),
      },
      pageToken: filters.pageToken,
      pageSize: filters.pageSize,
    })

  const { data: ovaAccountsData, isLoading: isOvaAccountsLoading } =
    useListOvaAccountsQuery()

  const ovaAccountMap = useMemo(() => {
    if (!ovaAccountsData) {
      return new Map<string, string>()
    }
    return ovaAccountsData.reduce((map, ovaAccount) => {
      map.set(ovaAccount.id, ovaAccount.name)
      return map
    }, new Map<string, string>())
  }, [ovaAccountsData])

  const columns = useMemo(() => {
    return getOvaServerColumns(ovaAccountMap)
  }, [ovaAccountMap])

  const isLoading = isAssetsDataLoading || isOvaAccountsLoading

  return (
    <Stack spacing={1}>
      <AssetFilters
        showSearch
        filters={[
          ASSET_FILTERS_MAP.HEALTH,
          ASSET_FILTERS_MAP.ASSET_ID,
          ASSET_FILTERS_MAP.BACKUP_VENDOR,
          ASSET_FILTERS_MAP.LAST_SCAN,
        ]}
      />
      <CursorTable
        key={searchParams.toString()}
        data={assetsData?.servers}
        columns={columns}
        isLoading={isLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsData?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default AssetsOvaServer
