/* eslint-disable import/no-extraneous-dependencies */
import { Asset, JobCloudConnector } from 'blues-corejs/dist'
import {
  MonitorTableDataAttrs,
  MonitorTableRow,
} from 'ui-v2/src/lib/models/monitor'
import {
  computeAssetID,
  computeAssetKind,
  getCloudConnectorJobStatusMessage,
  getHumanReadableCloudConnectorJobKind,
  getNotEmptyAsset,
} from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job'
import RedStackModel from 'ui-v2/src/lib/models/base-model/red-stack.model'

export class MonitorTableData {
  readonly #monitors: Array<JobCloudConnector>

  readonly #assetsList: Map<string, Asset>

  readonly #redStackListData: Array<RedStackModel>

  constructor(parameters: MonitorTableDataAttrs) {
    this.#monitors = parameters.monitors
    this.#assetsList = parameters.assetsList.reduce((map, assetList) => {
      const asset = getNotEmptyAsset(assetList)
      if (!asset) {
        return map
      }
      map.set(asset.id, asset)
      return map
    }, new Map<string, Asset>())
    this.#redStackListData = parameters.redStackListData
  }

  getMonitorData = (): Array<MonitorTableRow> => {
    return this.#monitors.map((monitor) => {
      const asset = this.#assetsList.get(monitor.targetAssetId)
      const assetId = computeAssetID(asset)
      const jobKind = getHumanReadableCloudConnectorJobKind(monitor.kind)
      const assetKind = computeAssetKind(asset)

      const redstack = this.#redStackListData.find(
        (r) => String(r.redStackId) === monitor.ccId
      )

      const message = getCloudConnectorJobStatusMessage(
        monitor.statusMessage,
        monitor.status
      )

      return new MonitorTableRow({
        id: monitor.id,
        accountId: redstack?.awsAccount ?? '',
        assetId: assetId,
        status: monitor.status,
        jobType: jobKind,
        scanStartTime: monitor.startedAt,
        scanEndTime: monitor.finishedAt,
        isFinished: monitor.isFinished,
        duration: '',
        progress: monitor.progress ?? 0,
        awsRegion: redstack?.awsRegion ?? '',
        assetKind: assetKind,
        createdAt: monitor.createdAt,
        message: message,
        name: monitor.name,
        realAssetId: asset?.id ?? '',
      })
    })
  }
}
