import { ASSETS_THREATS_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import RegionFilter from './region'
import AccountIdFilter from './account-id'
import RansomwareFilter from './ransomware'
import FileSystemErrorFilter from './file-system-integrity'
import MalwareFilter from './malware'

interface FilterComponentProps {
  options: Array<string>
}

const createFilterOption = (
  Component: React.ComponentType<FilterComponentProps>
) => {
  return Component
}

export const filterOptions = {
  [ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID]: createFilterOption(AccountIdFilter),
  [ASSETS_THREATS_FILTERS_MAP.REGION]: createFilterOption(RegionFilter),
  [ASSETS_THREATS_FILTERS_MAP.RANSOMWARE]: createFilterOption(RansomwareFilter),
  [ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY]: createFilterOption(
    FileSystemErrorFilter
  ),
  [ASSETS_THREATS_FILTERS_MAP.MALWARE]: createFilterOption(MalwareFilter),
}

export enum AssetThreatsTabs {
  INSTANCES = '0',
  VOLUMES = '1',
  S3 = '2',
  EFS = '3',
  OVA_SERVERS = '4',
}

export const TAB_FILTERS = {
  [AssetThreatsTabs.INSTANCES]: [
    ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID,
    ASSETS_THREATS_FILTERS_MAP.REGION,
    ASSETS_THREATS_FILTERS_MAP.MALWARE,
    ASSETS_THREATS_FILTERS_MAP.RANSOMWARE,
    ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY,
  ],
  [AssetThreatsTabs.VOLUMES]: [
    ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID,
    ASSETS_THREATS_FILTERS_MAP.REGION,
    ASSETS_THREATS_FILTERS_MAP.MALWARE,
    ASSETS_THREATS_FILTERS_MAP.RANSOMWARE,
    ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY,
  ],
  [AssetThreatsTabs.S3]: [
    ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID,
    ASSETS_THREATS_FILTERS_MAP.REGION,
    ASSETS_THREATS_FILTERS_MAP.MALWARE,
  ],
  [AssetThreatsTabs.EFS]: [
    ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID,
    ASSETS_THREATS_FILTERS_MAP.REGION,
    ASSETS_THREATS_FILTERS_MAP.RANSOMWARE,
    ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY,
  ],
}
