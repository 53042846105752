import React from 'react'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { Delete } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { filterOptions } from './config'

interface AssetFiltersProps {
  filters: Array<(typeof ASSET_FILTERS_MAP)[keyof typeof ASSET_FILTERS_MAP]>
  showSearch?: boolean
}

const AssetFilters: React.FC<AssetFiltersProps> = ({
  filters,
  showSearch = false,
}) => {
  const [, setSearchParams] = useSearchParams()

  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams())
  }

  return (
    <Stack direction="column" spacing={1}>
      {showSearch ? <SearchBar /> : null}

      <Stack
        direction="row"
        flexWrap={'wrap'}
        rowGap={1}
        columnGap={1}
        alignItems={'center'}
      >
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={600}
          paddingLeft={'0.5rem'}
        >
          Filter
        </Typography>

        {filters.map((filter) => (
          //@ts-ignore
          <React.Fragment key={filter}>{filterOptions[filter]}</React.Fragment>
        ))}

        <Button
          disableElevation
          startIcon={<Delete />}
          variant="contained"
          color="inherit"
          sx={(theme) => ({
            textTransform: 'capitalize',
            color: theme.palette.mode === 'dark' ? '#FFF' : '#1AA8EA',
            backgroundColor:
              theme.palette.mode === 'dark' ? '#1A1F28' : '#EFF3F6',
            height: 36,
            border: '1px solid',
            borderRadius: '4px',
            borderColor: theme.palette.mode === 'dark' ? '#29303C' : '#1AA8EA',

            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark' ? '#0076AC' : '#1AA8EA',
              color: '#FFF',
            },
          })}
          onClick={handleClearFilters}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  )
}

export default AssetFilters
