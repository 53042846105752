import React, { useState } from 'react'
import {
  SxProps,
  TablePagination,
  TablePaginationSlots,
  Theme,
} from '@mui/material'
import BaseTable, { BaseTableProps, BaseTableStyles } from './base-table'
import { PaginationCursors } from 'ui-v2/src/lib/models/client'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import { mergeSx } from './util'

interface CursorTableProps<T extends Record<string, any>>
  extends Omit<BaseTableProps<T>, 'footer'> {
  pageSize?: number
  setPageSize?: React.Dispatch<React.SetStateAction<number>>
  pageToken?: string
  paginationCursors?: PaginationCursors
  setPageToken?: React.Dispatch<React.SetStateAction<string | undefined>>
  styles?: BaseTableStyles & {
    pagination?: SxProps<Theme>
  }
  slots?: Partial<TablePaginationSlots>
  rowsPerPageOptions?: Array<number>
  multiSort?: boolean
}

function CursorTable<T extends { id?: string | number }>({
  pageSize = TABLE_ROWS_PER_PAGE_OPTIONS[0],
  pageToken,
  paginationCursors,
  setPageToken,
  setPageSize,
  styles = {},
  slots,
  rowsPerPageOptions,
  ...baseProps
}: CursorTableProps<T>) {
  const [page, setPage] = useState(0)
  const [previousPageTokens, setPreviousPageTokens] = useState<
    Array<string | undefined>
  >([])

  const handleNextPage = () => {
    if (paginationCursors?.nextPageToken) {
      setPreviousPageTokens((prev) => [...prev, pageToken])
      setPageToken?.(paginationCursors.nextPageToken)
    }
  }

  const handlePreviousPage = () => {
    if (previousPageTokens.length > 0) {
      const lastPreviousToken =
        previousPageTokens[previousPageTokens.length - 1]
      setPreviousPageTokens((prev) => prev.slice(0, -1))
      setPageToken?.(lastPreviousToken)
    }
  }

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      handleNextPage()
    } else {
      handlePreviousPage()
    }
    setPage(newPage)
    baseProps.onSelectRows?.([])
  }

  const onRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(0)
    setPreviousPageTokens([])
    setPageToken?.(undefined)
    setPageSize?.(parseInt(e.target.value, 10))
    baseProps.onSelectRows?.([])
  }

  const paginationComponent = (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions ?? TABLE_ROWS_PER_PAGE_OPTIONS}
      component="div"
      count={-1}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={onRowsPerPageChange}
      slots={slots}
      slotProps={{
        actions: {
          nextButton: {
            disabled: !paginationCursors?.nextPageToken,
          },
          previousButton: {
            disabled: previousPageTokens.length === 0,
          },
        },
      }}
      sx={mergeSx(
        {
          '& .MuiTablePagination-toolbar': {
            minHeight: '43px !important',
            height: '43px',
            backgroundColor: (theme) => theme.palette.background.paper,
          },
        },
        styles.pagination
      )}
    />
  )

  // eslint-disable-next-line
  const { pagination: _, ...baseTableStyles } = styles

  return (
    <BaseTable
      {...baseProps}
      footer={paginationComponent}
      styles={baseTableStyles}
    />
  )
}

export default CursorTable
