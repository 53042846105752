import React, { useMemo } from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import RestoreForensic from '../common/restore-forensic'
import DownloadReport from '../common/download-report'
import { CheckStatus, ScanJobData } from 'ui-v2/src/lib/models/backups'
import {
  getScanJobSource,
  getScannedStatus,
  transformScanData,
} from '../common/util'
import { ThreatStatus, threatStatusToCheckStatusMap } from '../common/types'
import BackupSource from '../common/backup-source'
import ScanStatus from '../files-table/scan-status'
import { useGetScanJobByIdQuery } from 'ui-v2/src/hooks/queries/scan-log'
import { useParams } from 'react-router-dom'

type ScanLogFilesTableHeaderProps = {
  isHealthy: boolean
  atRisk: boolean
  scanJob: ScanJobData
}

const ScanLogFilesTableHeader: React.FC<ScanLogFilesTableHeaderProps> = ({
  isHealthy,
  atRisk,
  scanJob,
}) => {
  const {
    assetId,
    recoveryPointId: scanId,
    scanJobId: recoveryPointScanId,
  } = useParams<{
    assetId: string | undefined
    recoveryPointId: string | undefined
    scanJobId: string | undefined
  }>()

  const { data: scanJobByIdData } = useGetScanJobByIdQuery(assetId, scanId, {
    enabled: !recoveryPointScanId,
  })

  const backupId = useMemo(() => {
    if (!scanJobByIdData) {
      return undefined
    }

    const backupScan = scanJobByIdData.scans.find(
      (scan) => scan.type === 'backup'
    )

    if (!backupScan) {
      return undefined
    }

    if (backupScan.target.kind_tag === 'backup') {
      return backupScan.target.id
    }

    return undefined
  }, [scanJobByIdData])

  const theme = useTheme()

  const scanData = transformScanData(scanJob.scans)

  const source = getScanJobSource(scanJob.type, scanJob.backupType)

  const scanStatus = getScannedStatus(isHealthy, atRisk, {
    fs_check_status:
      threatStatusToCheckStatusMap[scanData.fsScan?.status as ThreatStatus] ??
      CheckStatus.UNCHECKED,
    malware_check_status:
      threatStatusToCheckStatusMap[
        scanData.malwareScan?.status as ThreatStatus
      ] ?? CheckStatus.UNCHECKED,
    ransomware_check_status:
      threatStatusToCheckStatusMap[
        scanData.ransomwareScan?.status as ThreatStatus
      ] ?? CheckStatus.UNCHECKED,
  })

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.secondary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingX: '0',
        color: 'white',
      }}
    >
      <Stack
        spacing={1}
        sx={{ backgroundColor: theme.palette.background.secondary }}
      >
        <BackupSource
          source={source}
          backupKind={scanJob.backupKind}
          timestamp={scanJob.timestamp}
          backupId={backupId}
        />

        <ScanStatus status={scanStatus} isHealthy={isHealthy} atRisk={atRisk} />
      </Stack>
      <Stack alignItems={'flex-end'} spacing={2}>
        {/* TODO: Forensice for scan log  */}
        <RestoreForensic />
        <DownloadReport />
      </Stack>
    </Box>
  )
}

export default ScanLogFilesTableHeader
