import { Option } from 'ui-v2/src/lib/models/reports'
import { MenuItem, Select, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledBasicSelectField = styled(Select)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',

  '& .MuiSelect-select': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1A1F28' : '#FFFFFF',
    paddingY: '8.5px',
  },

  '& .MuiSelect-icon': {
    color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor:
      theme.palette.mode === 'dark' ? '#1A1F28' : 'rgba(228, 219, 233, 0.1)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.3)',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.5)',
  },

  '& .MuiMenu-paper': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1A1F28' : '#FFFFFF',
  },
  '& .MuiMenuItem-root': {
    color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',
  },
}))

const BasicSelect = ({
  value,
  options,
  size = 'small',
  error,
  placeholder,
  disabled,
  isSelectedObj,
  onChange,
  endAdornment,
}: BasicSelectProps) => {
  return (
    <StyledBasicSelectField
      size={size}
      value={value}
      onChange={(e) => {
        const selectedValue = e.target.value
        const selectedObj = options.find(
          (option) => option.value === selectedValue
        )
        if (!selectedObj) {
          return
        }
        if (isSelectedObj) {
          onChange(selectedObj)
        } else {
          onChange(selectedObj.value.toString())
        }
      }}
      error={!!error}
      displayEmpty
      renderValue={(selectedValue) => {
        if (!selectedValue) {
          return (
            <Typography
              component="em"
              sx={(theme) => ({
                color: theme.palette.text.disabled,
                fontSize: theme.typography.body2.fontSize,
              })}
            >
              {placeholder}
            </Typography>
          )
        }
        const selectedOption = options.find(
          (option) => option.value === selectedValue
        )
        return selectedOption ? selectedOption.label : (selectedValue as string)
      }}
      disabled={!!disabled}
      endAdornment={endAdornment}
      IconComponent={value && endAdornment ? () => null : undefined}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledBasicSelectField>
  )
}
interface BasicSelectProps {
  value: string | number
  options: Array<Option>
  size?: 'medium' | 'small'
  error?: boolean
  placeholder?: string
  isSelectedObj?: boolean
  onChange: (value: Option | string) => void
  disabled?: boolean
  endAdornment?: React.ReactElement | null
}
export default BasicSelect
