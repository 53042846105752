import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useQuery } from '@tanstack/react-query'
import { AssetAccountsClient } from 'ui-v2/src/lib/clients/asset-accounts'

export function useAccountLevelCloudFormationLink(awsAccountId?: string) {
  const assetAccountClient = useGrpcClient(AssetAccountsClient)

  return useQuery({
    queryKey: ['asset-accounts', 'cloud-formation-link'],
    queryFn: () => {
      if (!assetAccountClient) {
        throw new Error('Asset Accounts Client not initialized')
      }
      if (!awsAccountId) {
        return undefined
      }

      return assetAccountClient?.getAccountLevelCloudFormationLinkRequest(
        awsAccountId
      )
    },
    enabled: !!assetAccountClient,
  })
}
