/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from '../grpc'

import { NotificationRule } from 'blues-corejs/dist/models/notifications/rule'
import { convertRules } from '@lib/clients/notifications/transform-grpc'
import { ClientConfig } from 'ui-v2/src/lib/models/client'
import {
  DeleteNotificationRulesRequest,
  DisableNotificationRulesRequest,
  ListNotificationRulesRequest,
  EnableNotificationRulesRequest,
  NotificationRulesPromiseClient,
} from '../../grpc'
import {
  CreateNotificationRuleCriteria,
  UpdateNotificationRuleCriteria,
} from './types'
import { buildCreateNotificationRuleRequest } from './create-notification-rule'
import { buildUpdateNotificationRuleRequest } from './update-notification-rule'

const NOTIFICATIONS_RULES_CLIENT_NAME = 'NotificationRulesPromiseClient'

export class NotificationsRulesClient extends GrpcClient<NotificationRulesPromiseClient> {
  #notificationsRulesClient: NotificationRulesPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#notificationsRulesClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return NOTIFICATIONS_RULES_CLIENT_NAME
  }

  protected initClient(hostName = ''): NotificationRulesPromiseClient {
    return new NotificationRulesPromiseClient(hostName, null, null)
  }

  async getNotificationRulesList({ search }: { search: string }): Promise<{
    rulesList: Array<NotificationRule>
    pageToken?: string
  }> {
    const request = new ListNotificationRulesRequest()
    const initial = new ListNotificationRulesRequest.Initial()
      .setTextSearch(search)
      .setLimit(1000)
    request.setInitial(initial)

    const response = (
      await this.callGrpcService(
        () =>
          this.#notificationsRulesClient.listNotificationRules(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/getNotificationRulesList`,
        }
      )
    ).toObject()

    return {
      rulesList: convertRules(response.rulesList),
      pageToken: response.nextPageToken,
    }
  }

  async enableNotificationRule({ ids }: { ids: Array<string> }): Promise<void> {
    const request = new EnableNotificationRulesRequest().setIdsList(ids)
    await this.callGrpcService(
      () =>
        this.#notificationsRulesClient.enableNotificationRules(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/enableNotificationRule`,
      }
    )
  }

  async disableNotificationRule({
    ids,
  }: {
    ids: Array<string>
  }): Promise<void> {
    const request = new DisableNotificationRulesRequest().setIdsList(ids)

    await this.callGrpcService(
      () =>
        this.#notificationsRulesClient.disableNotificationRules(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/disableNotificationRule`,
      }
    )
  }

  async deleteNotificationRule({ ids }: { ids: Array<string> }): Promise<void> {
    const request = new DeleteNotificationRulesRequest().setIdsList(ids)

    await this.callGrpcService(
      () =>
        this.#notificationsRulesClient.deleteNotificationRules(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/deleteNotificationRule`,
      }
    )
  }

  async createNotificationRule(
    createNotificationRuleCriteria: CreateNotificationRuleCriteria
  ): Promise<NotificationRule | undefined> {
    const request = buildCreateNotificationRuleRequest(
      createNotificationRuleCriteria
    )

    const response = (
      await this.callGrpcService(
        () =>
          this.#notificationsRulesClient.createNotificationRule(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/createNotificationRule`,
        }
      )
    ).toObject()

    if (response.rule) {
      const notificationRule = convertRules([response.rule])[0]
      if (notificationRule) {
        return notificationRule
      }
      return
    }

    return
  }

  async updateNotificationRule(
    updateNotificationRuleCriteria: UpdateNotificationRuleCriteria
  ): Promise<NotificationRule | undefined> {
    const request = buildUpdateNotificationRuleRequest(
      updateNotificationRuleCriteria
    )

    const response = (
      await this.callGrpcService(
        () =>
          this.#notificationsRulesClient.updateNotificationRule(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/updateNotificationRule`,
        }
      )
    ).toObject()

    if (response.rule) {
      const notificationRule = convertRules([response.rule])[0]
      if (notificationRule) {
        return notificationRule
      }
      return
    }

    return
  }
}
