import { useState } from 'react'
import { Box, Modal, Button, Stack } from '@mui/material'
import CustomSchedule from './custom-schedule'
import { CustomScheduleState } from 'ui-v2/src/lib/models/reports'

const CustomScheduleModal = ({
  open,
  initialValues,
  handleSaveCustomSchedule,
  handleCloseCustomScheduleModal,
}: CustomScheduleModalProps) => {
  const [customScheduleState, setCustomScheduleState] =
    useState<CustomScheduleState | null>(null)

  const handleCreate = () => {
    if (customScheduleState) {
      handleSaveCustomSchedule(customScheduleState)
    }
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{ bgcolor: 'background.paper' }}
        component={'div'}
        className="custom-schedule-modal"
      >
        <CustomSchedule
          initialValues={initialValues}
          getStateCallback={(state) => setCustomScheduleState(state)}
        />
        <Stack
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          columnGap={2}
          mt={3}
        >
          <Button
            onClick={handleCloseCustomScheduleModal}
            variant="text"
            sx={{ textTransform: 'capitalize' }}
            size="large"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreate}
            sx={{ textTransform: 'capitalize' }}
            size="large"
          >
            Create
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
interface CustomScheduleModalProps {
  open: boolean
  initialValues: CustomScheduleState | null
  handleSaveCustomSchedule: (customScheduleState: CustomScheduleState) => void
  handleCloseCustomScheduleModal: () => void
}
export default CustomScheduleModal
