import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { PolicyTagsTableRow } from 'ui-v2/src/lib/models/settings/policies/policies'
import TagAction from './tag-action'

export const getPolicyTagsListColumns = (): Array<
  Column<PolicyTagsTableRow>
> => [
  {
    key: 'tagKey',
    header: 'Tag Key',
    render: (row) => row.tagKey,
  },
  {
    key: 'tagValue',
    header: 'Tag Value',
    render: (row) => row.tagValue,
  },
  {
    key: 'action',
    header: '',
    render: (row) => <TagAction row={row} />,
  },
]
