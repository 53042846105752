/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { Backup } from 'ui-v2/src/lib/models/backups'
import { formatDurationFromDate } from 'ui-v2/src/lib/helpers/time.helper'
import LabeledValue from '../common/labeled-value'
import RestoreLastCleanBackupDrawer from './restore-last-clean-backup-drawer'
import {
  Asset,
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
  OvaBackup,
} from 'blues-corejs/dist'
import { buildAwsBackupDetailsUrl } from './util'
import { RecoveryPointKind } from 'blues-corejs/dist/models/backups/aws/awsb/types'

type CleanBackupDetailsProps = {
  asset: Asset | undefined
  lastCleanBackup: Backup
  isLoading: boolean
  isError: boolean
}

const CleanBackupDetails: React.FC<CleanBackupDetailsProps> = ({
  asset,
  lastCleanBackup,
  isLoading,
  isError,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }

  const age = lastCleanBackup
    ? formatDurationFromDate(lastCleanBackup.timestamp)
    : '-'

  const path = useMemo(() => {
    if (
      lastCleanBackup instanceof AWSBRecoveryPoint ||
      lastCleanBackup instanceof EBSSnapshot
    ) {
      return lastCleanBackup.arn
    }

    if (lastCleanBackup instanceof ElastioRecoveryPoint) {
      return lastCleanBackup.ccRpId
    }

    if (lastCleanBackup instanceof OvaBackup) {
      return lastCleanBackup.backupProviderBackupId
    }

    return ''
  }, [lastCleanBackup])

  const isButtonVisible = (): boolean => {
    if (lastCleanBackup instanceof OvaBackup) {
      return false
    }

    if (lastCleanBackup instanceof AWSBRecoveryPoint) {
      return (
        lastCleanBackup.kind !== RecoveryPointKind.EFS &&
        lastCleanBackup.kind !== RecoveryPointKind.S3
      )
    }

    if (lastCleanBackup instanceof ElastioRecoveryPoint) {
      return false
    }

    return true
  }

  const restoreRecoveryPointHandler = () => {
    if (lastCleanBackup instanceof AWSBRecoveryPoint) {
      window.open(
        buildAwsBackupDetailsUrl(
          lastCleanBackup.region,
          lastCleanBackup.vault.name,
          lastCleanBackup.arn
        ),
        '_blank'
      )
    } else {
      setIsDrawerOpen(true)
    }
  }

  return (
    <>
      <Stack spacing={1}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <StatusChip type="success" sx={{ minWidth: '36px' }} />
            <Typography variant="body2" fontWeight={600}>
              Last Known Clean
            </Typography>
          </Box>
          {isButtonVisible() ? (
            <Button
              variant="contained"
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                textTransform: 'capitalize',
                fontSize: 12,
                fontWeight: 600,
                padding: '2px 0px',
                borderRadius: '2px',
                width: '74px',
              })}
              disabled={!lastCleanBackup}
              onClick={restoreRecoveryPointHandler}
            >
              Restore
            </Button>
          ) : null}
        </Box>
        <LabeledValue label="Age" value={age} />
        <LabeledValue
          label="Path"
          value={path}
          maxLength={55}
          showClipboardCopy
        />
      </Stack>
      <RestoreLastCleanBackupDrawer
        isOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        asset={asset}
        lastCleanBackup={lastCleanBackup}
        isLoading={isLoading}
        isError={isError}
      />
    </>
  )
}

export default CleanBackupDetails
