/* eslint-disable import/no-extraneous-dependencies */
import { Asset, EBS, EC2, Nullable } from 'blues-corejs/dist'
import { EngineObject, StringConvertible } from '../engine-types'

export function buildRestoreCLICommandRestore(
  asset: Nullable<Asset>,
  routerRecoveryPointId: string
): string {
  if (!asset) {
    return ''
  }

  const isEC2 = asset instanceof EC2
  const isEBS = asset instanceof EBS

  if (!isEC2 && !isEBS) {
    return ''
  }

  let kindLabel = ''
  let protectedObject = ''

  if (isEC2) {
    kindLabel = 'ec2'
    protectedObject = 'volume.getParsedCloudProviderVolumeId()'
  } else if (isEBS) {
    kindLabel = 'ebs'
  }

  return `elastio ${kindLabel} restore --rp ${routerRecoveryPointId} ${protectedObject} --monitor`
}
export const capitalize = (value: string) => {
  if (!value) {
    return ''
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
}
export const buildHumanLabel = (value: string): string => {
  return value
    .toLowerCase()
    .split(/[\s\-_]+/)
    .map((v) => v.trim())
    .map(capitalize)
    .join(' ')
}
export const base64Encode = (str: any): string => {
  if (!str) {
    return ''
  }
  return btoa(String(str))
}

export const base64Decode = (str: any): string => {
  if (!str) {
    return ''
  }

  return atob(String(str))
}
export const base64EncodeObj = (obj: EngineObject): string => {
  return base64Encode(JSON.stringify(obj))
}

export const base64DecodeObj = (str: string): EngineObject => {
  try {
    const decoded = base64Decode(str)
    if (!decoded) {
      throw new Error('Decoded string is empty')
    }
    return JSON.parse(decoded)
  } catch (error) {
    console.error('Error decoding Base64 or parsing JSON:', error)
    throw new Error('Invalid Base64 string or JSON input')
  }
}
export const realFileSize = (
  bytes: number,
  emptyResult = '0 bytes'
): string => {
  if (!bytes) {
    return emptyResult
  }

  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return (
    (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'iB'
  )
}
export const buildWithOrdinalSuffix = (n: number): string => {
  const s: [string, string, string, string] = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}
export const isSubstring = <T extends StringConvertible>(
  main: T,
  sub: string,
  subInLowerCase = false
): boolean => {
  const subVal = subInLowerCase ? sub : sub.toLowerCase()
  return main.toString().toLowerCase().trim().includes(subVal.trim())
}
export const getTitleString = (path: string) => {
  return path
    .split('/')
    ?.filter(Boolean)
    ?.pop()
    ?.replace(/-/g, ' ')
    ?.replace(/\b\w/g, (c) => c.toUpperCase())
}
