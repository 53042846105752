import { useMemo, useState } from 'react'
import { Box, Card, FormControl, Stack, Typography } from '@mui/material'
import {
  getWebhooksDateRangeFilters,
  WEBHOOK_MAP,
} from './manage-webhook-drawer/data'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { useSearchParams } from 'react-router-dom'
import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import { useWebhookInvocationsListQuery } from 'ui-v2/src/hooks/queries/webhooks/list-webhook-invocations'
import { generateDateRangeTuples } from './utils'
import LineBarChart from './line-bar-chart'

const WebhookInvocations = (props: any) => {
  const data = props.data
  const dateRangeFilters = getWebhooksDateRangeFilters()
  const [selectedDateRange, setSelectedDateRange] =
    useState<string>('Past 3 hours')

  const [searchParams, setSearchParams] = useSearchParams()
  const webhook = searchParams.get(WEBHOOK_MAP.WEBHOOKS)?.split(',') || []

  const timeRange = useMemo(() => {
    return generateDateRangeTuples(selectedDateRange)
  }, [selectedDateRange])

  const webhooksList = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map((webhooks: { name: any; id: any }) => ({
      label: webhooks.name,
      value: webhooks.id,
    }))
  }, [data])

  const webhookInvocations = useWebhookInvocationsListQuery(
    webhook.length
      ? webhook
      : webhooksList.map((options: { value: any }) => options.value),
    timeRange
  )

  const handleWebhookListChange = (
    newWebhookList: Array<MultiSelectOption['value']>
  ) => {
    if (newWebhookList.length === 0) {
      searchParams.delete(WEBHOOK_MAP.WEBHOOKS)
    } else {
      searchParams.set(WEBHOOK_MAP.WEBHOOKS, newWebhookList.join(','))
    }
    setSearchParams(searchParams)
  }

  const dateFilterList = useMemo(() => {
    if (!dateRangeFilters) {
      return []
    }
    return dateRangeFilters.options.map((filter) => ({
      label: filter.label,
      value: filter.name,
    }))
  }, [dateRangeFilters])

  return (
    <Card
      sx={{
        paddingX: 2,
        paddingY: 1.5,
        marginTop: 2,
        bgcolor: 'background.paper',
      }}
    >
      <Stack
        spacing={1}
        direction="row"
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={1}
        paddingTop={1}
        paddingX={1}
      >
        <Typography fontWeight={600} variant="body1" color="text.secondary">
          Sync events
        </Typography>
        <Box display={'flex'} alignItems={'center'} columnGap={1.5}>
          <FormControl fullWidth size="small" sx={{ minWidth: 150 }}>
            <FilterMultiSelect
              options={webhooksList}
              value={webhook}
              onChange={handleWebhookListChange}
              label="Webhooks"
              sx={(theme) => ({
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#1A1F28' : '#FFFFFF',
                color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor:
                    theme.palette.mode === 'dark'
                      ? '#1A1F28'
                      : 'rgba(228, 219, 233, 0.25',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '& .MuiInputBase-inputSizeSmall': {
                  paddingY: '6.5px',
                },
              })}
              width={150}
            />
          </FormControl>
          <FormControl fullWidth size="small" sx={{ minWidth: 150 }}>
            <BasicSelect
              options={dateFilterList}
              onChange={(value) => {
                setSelectedDateRange(value as string)
              }}
              value={selectedDateRange}
            />
          </FormControl>
        </Box>
      </Stack>

      <LineBarChart
        data={data}
        webhook={webhook}
        timeRange={timeRange}
        webhookInvocations={webhookInvocations}
      />
    </Card>
  )
}

export default WebhookInvocations
