import { ovaBackupProviderOptions } from 'ui-v2/src/lib/constants/reports.constant'
import MultiSelectChip from 'ui-v2/src/components/ui/inputs/multi-select-chip'
import { ReportForm } from 'ui-v2/src/lib/models/reports'
import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import { useListOvaAccountsQuery } from 'ui-v2/src/hooks/queries/ova-accounts'

const BackupProvider = ({
  value,
  helperText,
  error,
  onChange,
}: BackupProviderProps) => {
  const { watch } = useFormContext<ReportForm>()
  const selectedOvaAccountIds = watch('ovaAccounts')

  const { data: ovaAccountsData } = useListOvaAccountsQuery()

  const options = useMemo(() => {
    if (!ovaAccountsData || selectedOvaAccountIds.length === 0) {
      return ovaBackupProviderOptions.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      }))
    }

    const backupProvidersToDisplaySet = new Set(
      ovaAccountsData
        .filter((ovaAccount) => selectedOvaAccountIds.includes(ovaAccount.id))
        .flatMap((ovaAccount) => ovaAccount.backupProvidersList)
    )

    return ovaBackupProviderOptions
      .filter((provider) => backupProvidersToDisplaySet.has(provider.value))
      .map((option) => ({
        label: option.label,
        value: option.value.toString(),
      }))
  }, [ovaAccountsData, selectedOvaAccountIds, ovaBackupProviderOptions])

  return (
    <MultiSelectChip
      size="small"
      options={options}
      selectedOptions={value}
      placeholder="Select"
      setSelectedOptions={onChange}
      helperText={helperText}
      error={error}
    />
  )
}
interface BackupProviderProps {
  value: Array<string>
  helperText?: string
  error?: boolean
  onChange: (options: Array<string>) => void
}
export default BackupProvider
