import { SxProps, Theme } from '@mui/material'

interface SortConfig {
  key: string
  direction: 'asc' | 'desc'
}

export const sortConfigsToUrlParams = (
  sortConfigs: Array<SortConfig>,
  tableId?: string
) => {
  const prefix = tableId ? `${tableId}_` : ''
  return sortConfigs.reduce((params, config) => {
    params.append(`${prefix}sort`, `${config.key}:${config.direction}`)
    return params
  }, new URLSearchParams())
}

export const urlParamsToSortConfigs = (
  params: URLSearchParams,
  tableId?: string
): Array<SortConfig> => {
  const prefix = tableId ? `${tableId}_` : ''
  const sortParams = params.getAll(`${prefix}sort`)
  return sortParams
    .map((param) => {
      const [key, direction] = param.split(':')
      if (key && (direction === 'asc' || direction === 'desc')) {
        return {
          key,
          direction,
        }
      }
      return null
    })
    .filter((config): config is SortConfig => config !== null)
}

export const mergeSx = (
  ...styles: Array<SxProps<Theme> | undefined>
): SxProps<Theme> => {
  return Object.assign({}, ...styles.filter(Boolean))
}
