import { useRestClient } from 'ui-v2/src/hooks/rest-client'

type RansomwareItem = {
  title: string
  first_seen: string
  first_url?: string
  elastio_url: string
}

type RansomwareResponse = {
  date: string
  ransomware: Array<RansomwareItem>
}

export const useRansomwareService = () => {
  const { isReady, isLoading, get } = useRestClient()

  const getRansomwareData = async () => {
    if (!isReady) {
      throw new Error('REST client is not initialized')
    }

    const url = '/api/v1/ransomware-short'
    return get<RansomwareResponse>(url)
  }

  return {
    getRansomwareData,
    isReady,
    isLoading,
  }
}
