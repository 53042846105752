import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { EntropyDetectionClient } from 'ui-v2/src/lib/clients'
import { GetEntropyTrendsHistoryRequestAttrs } from 'ui-v2/src/lib/models/anomalies'

export function useGetEntropyTrendHistoryQuery(
  filters: GetEntropyTrendsHistoryRequestAttrs
) {
  const entropyDetectionClient = useGrpcClient(EntropyDetectionClient)

  return useQuery({
    queryKey: ['entropy-detection', 'entropy-trend-history', filters],
    queryFn: async () => {
      if (!entropyDetectionClient) {
        throw new Error('Entropy detection client not initialized')
      }
      return entropyDetectionClient.getEntropyTrendsHistory(filters)
    },
    enabled: !!entropyDetectionClient,
    refetchOnWindowFocus: false,
  })
}
