/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'

import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import {
  RansomwareScan,
  MalwareScan,
  FilesystemScanCheck,
} from 'blues-corejs/dist'

import { useAssetsListByIdQuery } from 'ui-v2/src/hooks/queries/assets'
import { getAsset } from 'ui-v2/src/lib/helpers/assets.helper'

import {
  FilesystemScanBackup,
  GenericHost,
  OvaServer,
} from 'blues-corejs/dist/models'

function getAssetIdFromScanTarget(scan: FilesystemScanCheck): string {
  if (scan.scanTarget.target instanceof FilesystemScanBackup) {
    return (scan.scanTarget?.target?.source?.asset?.backupAssetId ||
      scan.scanTarget?.target?.source?.assetItem?.backupAssetId) as string
  }
  // Direct scan
  if ('assetId' in scan.scanTarget.target) {
    return scan.scanTarget.target.assetId
  }

  throw new Error('Invalid scan target')
}

function getAssetWithRelatedScanIds(scans: Array<FilesystemScanCheck>) {
  const assetWithRelatedScanIds = new Map<string, FilesystemScanCheck>()

  for (const scan of scans) {
    const scanTargetAssetId = getAssetIdFromScanTarget(scan)
    const currentScan = assetWithRelatedScanIds.get(scanTargetAssetId)

    if (!currentScan) {
      assetWithRelatedScanIds.set(scanTargetAssetId, scan)
    }
  }

  return assetWithRelatedScanIds
}

export function useFsCheckScan(
  lastScanData: Array<FilesystemScanCheck | MalwareScan | RansomwareScan>,
  displayName: string
) {
  const fsCheckScan = lastScanData.filter(
    (scan) => scan instanceof FilesystemScanCheck
  ) as Array<FilesystemScanCheck>

  const assetWithRelatedScanIds = getAssetWithRelatedScanIds(fsCheckScan)
  const assetIds = fsCheckScan.map(getAssetIdFromScanTarget)

  const { data: assetsData } = useAssetsListByIdQuery(assetIds)

  const assetList = useMemo(() => {
    if (!assetsData) {
      return []
    }

    const assetListScans: Array<{
      displayName: string
      id: string
    }> = []

    assetsData?.assetsList.forEach((asset) => {
      const assetInstance = getAsset(asset)

      if (assetInstance instanceof AWSAsset) {
        assetListScans.push({
          displayName: assetInstance.awsId,
          id: assetInstance.id,
        })
      }
      if (assetInstance instanceof GenericHost) {
        assetListScans.push({
          displayName: assetInstance.hostname,
          id: assetInstance.id,
        })
      }
      if (assetInstance instanceof OvaServer) {
        assetListScans.push({
          displayName: assetInstance.name,
          id: assetInstance.id,
        })
      }
    })

    return assetListScans
  }, [assetsData])

  const scanOptions = useMemo(() => {
    if (!assetList) {
      return []
    }

    const options: Array<{
      scanId: string
      displayName: string
    }> = []

    Array.from(assetWithRelatedScanIds, async ([assetId, scan]) => {
      const displayedAssetName =
        assetList.find((asset) => asset.id === assetId)?.displayName || ''

      options.push({
        scanId: scan.id,
        displayName: `${displayName} ${displayedAssetName}`,
      })
    })

    return options
  }, [assetWithRelatedScanIds, assetList])

  return scanOptions
}
