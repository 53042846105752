import AppShell from 'ui-v2/src/components/layout/app-shell'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Outlet } from 'react-router-dom'
import SiteLoader from '../common/site-loader'

const App = () => (
  <AppShell>
    <Outlet />
  </AppShell>
)

/*
  When you wrap your components in this Higher Order Component and an anonymous user 
  visits your component they will be redirected to the login page; after login they 
  will be returned to the page they were redirected from.
  
  The `onRedirecting` callback displays a loader during the redirection process. 
  
  The `returnTo` function returns the current URL (pathname, search, and hash) to 
  redirect the user back to the same page after they successfully log in.
 */

const ProtectedApp = withAuthenticationRequired(App, {
  onRedirecting: () => <SiteLoader />,
  returnTo: () => {
    const { pathname, search, hash } = window.location
    return pathname + search + hash
  },
})

const RootLayout = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <SiteLoader />
  }

  return <ProtectedApp />
}

export default RootLayout
