/* eslint-disable import/no-extraneous-dependencies */
import { CSSProperties, useMemo } from 'react'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerLabelValue,
} from 'ui-v2/src/components/ui/drawer'
import { FileCopyOutlined } from '@mui/icons-material'
import { Box, Button, Card, Stack, Typography, useTheme } from '@mui/material'
import AwsLogoLightTheme from 'ui-v2/src/assets/images/aws-logo-light-theme'
import AwsLogoDarkTheme from 'ui-v2/src/assets/images/aws-logo-dark-theme'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import {
  Asset,
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
  GenericHost,
  OvaBackup,
  OvaServer,
} from 'blues-corejs/dist'
import SyntaxHighlighter from 'react-syntax-highlighter'
import {
  a11yDark,
  a11yLight,
} from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import { buildRestoreCLICommandRestore } from 'ui-v2/src/lib/helpers/string.helper'
import { restoreLastCleanSnapshotsScript } from './util'

type RestoreLastCleanBackupDrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
  asset: Asset | undefined
  lastCleanBackup:
    | AWSBRecoveryPoint
    | EBSSnapshot
    | ElastioRecoveryPoint
    | OvaBackup
    | undefined
  isLoading: boolean
  isError: boolean
}

const RestoreLastCleanBackupDrawer: React.FC<
  RestoreLastCleanBackupDrawerProps
> = ({ isOpen, closeDrawer, asset, lastCleanBackup, isLoading, isError }) => {
  const theme = useTheme()

  const codeSnippetStyles: CSSProperties = {
    margin: '0 0 16px 0',
    padding: '12px',
    overflowY: 'auto',
    fontSize: '14px',
    flex: 1,
    background: 'transparent',
  }

  // TODO: figure out the rest of the restore scripts
  const restoreScript = useMemo(() => {
    if (lastCleanBackup instanceof ElastioRecoveryPoint) {
      return buildRestoreCLICommandRestore(
        asset,
        lastCleanBackup.ccRpId as string
      )
    }

    if (lastCleanBackup instanceof EBSSnapshot) {
      return restoreLastCleanSnapshotsScript(lastCleanBackup.awsId)
    }

    return ''
  }, [lastCleanBackup, asset])

  return (
    <Drawer open={isOpen} onClose={closeDrawer}>
      <DrawerHeader
        title="Restore Last Clean Recovery Point"
        iconStart={
          theme.palette.mode === 'light' ? (
            <AwsLogoLightTheme />
          ) : (
            <AwsLogoDarkTheme />
          )
        }
        handleCloseDrawer={closeDrawer}
      />
      <DrawerContent
        isLoading={isLoading}
        isError={isError}
        isEmpty={!isLoading && !asset}
        emptyMessage="This asset does not have a clean backup."
      >
        {!(asset instanceof GenericHost || asset instanceof OvaServer) ? (
          <Stack
            spacing={3}
            direction="column"
            sx={{
              flexWrap: 'wrap',
              paddingX: 2.5,
              paddingBottom: 2.5,
            }}
          >
            <Stack spacing={2}>
              <Box>
                <DrawerLabelValue
                  label={'AWS Account'}
                  value={asset?.awsAccountId ?? ''}
                  labelWidth={0}
                  size="lg"
                />

                <DrawerLabelValue
                  label={'Asset ID'}
                  value={asset?.awsId ?? ''}
                  labelWidth={0}
                  size="lg"
                />
              </Box>

              <Typography variant="body2" mt={2}>
                To restore to the last clean state of data, run the following
                script in the AWS Cloud Shell.
              </Typography>

              <Card
                sx={{
                  padding: 2.5,
                  overflow: 'auto',
                  width: '41.25rem',
                  height: '50vh',
                }}
              >
                <SyntaxHighlighter
                  language="bash"
                  style={
                    theme.palette.mode === ThemeMode.DARK ? a11yDark : a11yLight
                  }
                  customStyle={codeSnippetStyles}
                >
                  {restoreScript}
                </SyntaxHighlighter>
              </Card>
            </Stack>
            <Stack spacing={2} direction="row" justifyContent={'space-between'}>
              <Button
                startIcon={<FileCopyOutlined />}
                variant="contained"
                onClick={() => {
                  copyToClipboard(restoreScript)
                }}
              >
                Restore Last Clean
              </Button>
              {lastCleanBackup instanceof AWSBRecoveryPoint ? (
                <Button variant="contained">Go to AWS Backup</Button>
              ) : null}
            </Stack>
          </Stack>
        ) : null}
      </DrawerContent>
    </Drawer>
  )
}

export default RestoreLastCleanBackupDrawer
