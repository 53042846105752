/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetsEc2TableDataAttrs,
  Ec2TableRow,
} from 'ui-v2/src/lib/models/assets'
import { EBS, EC2, Threat } from 'blues-corejs/dist'
import { AssetsEc2Base } from './ec2-base'
import { Scan } from 'ui-v2/src/lib/models/scans'

export class AssetsEc2TableData extends AssetsEc2Base {
  protected readonly instances: Array<EC2>

  protected readonly volumes: Map<string, EBS>

  protected readonly threats: Array<Threat>

  protected readonly scans: Array<Scan>

  protected readonly volumesWithScans: Map<string, Array<Scan>>

  constructor(parameters: AssetsEc2TableDataAttrs) {
    super()
    this.instances = parameters.instances
    this.volumes = parameters.volumes.reduce((map, volume) => {
      map.set(volume.id, volume)
      return map
    }, new Map<string, EBS>())
    this.threats = parameters.threats
    this.scans = parameters.scans
    this.volumesWithScans = this.getVolumeWithScans()
  }

  getEc2TableData(): Array<Ec2TableRow> {
    return this.instances.map(
      (instance) =>
        new Ec2TableRow({
          id: instance.id,
          name: instance.name,
          awsId: instance.awsId,
          awsRegion: instance.awsRegion,
          awsAccountId: instance.awsAccountId,
          state: AssetsEc2Base.convertEc2State(instance.state),
          backupPolicies: instance.backupPolicies,
          isUnhealthy: this.isUnhealthyInstance(instance),
          createdAt: instance.createdAt,
          lastScan: this.getLastScanForInstance(instance),
        })
    )
  }
}
