export interface AnomalyDataPoint {
  timestamp: Date
  anomalyFindingCount: number
  anomalyIdsList: Array<string> // UUID format
}

export class EntropyTrendsHistory {
  #id: number

  #anomalyDataPoints: Array<AnomalyDataPoint>

  constructor(anomalyDataPoints: Array<AnomalyDataPoint>) {
    this.#id = Math.floor(Math.random() * 1000)
    this.#anomalyDataPoints = anomalyDataPoints
  }

  get anomalyDataPoints(): Array<AnomalyDataPoint> {
    return this.#anomalyDataPoints
  }

  get id(): number {
    return this.#id
  }

  get totalAnomalyFindingsCount(): number {
    return this.#anomalyDataPoints.reduce(
      (total, dataPoint) => total + dataPoint.anomalyFindingCount,
      0
    )
  }

  nonZeroAnomalyDataPointsNumber(): number {
    return this.#anomalyDataPoints.filter(
      (dataPoint) => dataPoint.anomalyFindingCount > 0
    ).length
  }
}

export enum TimeGranularity {
  TIME_GRANULARITY_HOUR = 0,
  TIME_GRANULARITY_DAY = 1,
}

export interface GetEntropyTrendsHistoryRequestAttrs {
  startAt: number
  endAt: number
  timeGranularity: TimeGranularity
  assetIdsList?: Array<string>
}
