import { PolicyDataResponse } from 'ui-v2/src/lib/models/pechkin'
import ScheduleInterface from 'ui-v2/src/lib/models/settings/policies/schedule'

export const getFrequencyDisplay = (schedule: ScheduleInterface) => {
  if (!schedule?.frequency) {
    return ''
  }

  const { frequency, daysList } = schedule

  const getDaysDisplay = () => {
    if (!daysList) {
      return ''
    }

    const days = []
    if (daysList.sunday) {
      days.push('Suday')
    }
    if (daysList.monday) {
      days.push('Monday')
    }
    if (daysList.tuesday) {
      days.push('Tuesday')
    }
    if (daysList.wednesday) {
      days.push('Wednesday')
    }
    if (daysList.thursday) {
      days.push('Thursday')
    }
    if (daysList.friday) {
      days.push('Friday')
    }
    if (daysList.saturday) {
      days.push('Saturday')
    }

    if (days.length === 7) {
      return 'Daily'
    }
    return `Every ${days.join(', ')}`
  }

  switch (frequency.name) {
    case 'Daily':
      return getDaysDisplay()
    case 'Weekly':
      return `Weekly on ${schedule.dayOfWeek.name}`
    case 'Monthly': {
      return 'Monthly'
    }
    default:
      return frequency.name
  }
}

export const getScanTypes = (policy: PolicyDataResponse) => {
  const { iscan, isEntropyDetectionEnabled } = policy
  const scanTypes = []

  if (iscan?.scanForRansomware) {
    scanTypes.push('Ransomware')
  }

  if (isEntropyDetectionEnabled) {
    scanTypes.push('Insider')
  }

  if (iscan?.scanForMalware) {
    scanTypes.push('Malware')
  }

  return scanTypes.length > 0 ? scanTypes.join(', ') : ''
}
