import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import ActiveDeployments from './active-deployments'
import Button from 'ui-v2/src/components/ui/inputs/button'

const SUB_TAB_MAP = {
  tenant: {
    label: 'Active Deployments',
    component: <ActiveDeployments />,
    slug: GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS,
  },
}

const Sources = () => {
  const navigate = useNavigate()
  const { subTab } = useParams<{
    subTab: (typeof GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS)[keyof typeof GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS]
  }>()

  const defaultPath = `/settings/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`

  useEffect(() => {
    if (!subTab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(SUB_TAB_MAP).some((t) => t.slug === subTab)) {
      navigate(defaultPath, { replace: true })
      return
    }
  }, [])

  if (!subTab || !Object.values(SUB_TAB_MAP).some((t) => t.slug === subTab)) {
    return null
  }

  const handleLinkSource = () => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_SOURCE}`
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'capitalize',
            height: '30px',
            marginTop: '5px',
            marginBottom: '5px',
          }}
          onClick={handleLinkSource}
        >
          Deploy
        </Button>
      </Box>
      <ActiveDeployments />
    </Box>
  )
}

export default Sources
