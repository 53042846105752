import { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  DEFAULT_RPO_HOURS,
  RPO_HOURS_LOCAL_STORAGE_KEY,
} from 'ui-v2/src/lib/constants/ui.constant'
import useRpoStore from './rpo-store'

type ResilienceScoreDrawerProps = {
  isResilienceScoreDrawerOpen: boolean
  closeResilienceScoreDrawer: () => void
  resilienceScore: number
}

type RadarChartDimensionsDescriptionProps = {
  title: string
  description: string
}

const RadarChartDimensionsDescription: React.FC<
  RadarChartDimensionsDescriptionProps
> = ({ title, description }) => (
  <Stack>
    <Typography variant="body2" fontWeight={700}>
      {title}
    </Typography>
    <Typography variant="body2">{description}</Typography>
  </Stack>
)

const ResilienceScoreDrawer: React.FC<ResilienceScoreDrawerProps> = ({
  isResilienceScoreDrawerOpen,
  closeResilienceScoreDrawer,
  resilienceScore,
}) => {
  const [isDrawerReady, setIsDrawerReady] = useState(false)
  const { rpoHours: rpoHoursStore, setRpoHours: setRpoHoursStore } =
    useRpoStore()
  const [rpoHours, setRpoHours] = useState<number>(rpoHoursStore)

  useEffect(() => {
    setTimeout(() => {
      setIsDrawerReady(true)
    }, 0)
  }, [])

  const handleRpoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newHours = Number(event.target.value)
    setRpoHours(newHours)
  }

  const saveRpoDaysData = () => {
    setRpoHoursStore(rpoHours)
    closeResilienceScoreDrawer()
  }

  const resetRpoDaysData = () => {
    const storedValue =
      Number(localStorage.getItem(RPO_HOURS_LOCAL_STORAGE_KEY)) ||
      DEFAULT_RPO_HOURS
    setRpoHours(storedValue)
    setRpoHoursStore(storedValue)
  }

  return (
    <Drawer
      anchor="right"
      open={isResilienceScoreDrawerOpen}
      onClose={closeResilienceScoreDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: '466px',
        },
      }}
    >
      <DrawerContent isEmpty={false}>
        <List
          sx={{
            paddingX: 0,
            paddingBottom: 0,
            paddingTop: 2,
          }}
        >
          <ListItem
            sx={{ paddingX: 2.5 }}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={closeResilienceScoreDrawer}
              >
                <Close />
              </IconButton>
            }
          >
            <Typography fontWeight={700} fontSize={22}>
              360 Ransomware Risk Score Calculation
            </Typography>
          </ListItem>
        </List>
        <Stack
          spacing={2}
          sx={{
            paddingX: 2.5,
            paddingY: 2,
            minHeight: 'calc(100vh - 120px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(128, 128, 128, 0.5)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'rgba(128, 128, 128, 0.7)',
            },
          }}
        >
          <Box>
            <Typography variant="body2" fontWeight={400}>
              The Elastio Resilience dashboard represents five pillars of
              ransomware resilience.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#1AA8EA',
              borderRadius: '8px',
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            columnGap={2}
            padding={1}
          >
            <Typography
              maxWidth={170}
              lineHeight={'20px'}
              fontSize={16}
              fontWeight={700}
              color="#fff"
            >
              Your Current Score
            </Typography>
            <Typography fontSize={32} fontWeight={700} color="#fff">
              {Math.round(resilienceScore)}%
            </Typography>
          </Box>

          {isDrawerReady && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={2}
              width="100%"
              sx={{
                backgroundColor: '#152635',
                height: '59px',
                borderRadius: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                RPO Hours
              </Typography>
              <TextField
                type="number"
                value={rpoHours}
                onChange={handleRpoChange}
                inputProps={{
                  min: 1,
                  style: {
                    textAlign: 'center',
                    appearance: 'textfield',
                  },
                }}
                variant="outlined"
                sx={{
                  width: '72px',
                  height: '40px',
                  borderRadius: '6px',
                  textAlign: 'center',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: '#121212',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                    borderRadius: '6px',
                    paddingRight: '0px',
                    paddingLeft: '10px',
                    fontSize: '16px',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      borderColor: 'rgba(255, 255, 255, 0.5)',
                    },
                    '&.Mui-focused': {
                      borderColor: '#1AA8EA',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                  },
                  '& input': {
                    padding: '6px 4px',
                    color: '#E3E8ED',
                    fontSize: '16px',
                    textAlign: 'center',
                  },

                  '& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button':
                    {
                      width: '16px',
                      height: '100%',
                      background: '#1A1A1A',
                      borderRadius: '4px',
                      border: 'none',
                      opacity: 1,
                    },
                  '& input::-webkit-inner-spin-button:hover, & input::-webkit-outer-spin-button:hover':
                    {
                      background: '#333',
                    },
                  '& input::-webkit-inner-spin-button:active, & input::-webkit-outer-spin-button:active':
                    {
                      background: '#555',
                    },
                }}
              />
            </Box>
          )}
          <Stack spacing={4}>
            <RadarChartDimensionsDescription
              title="Recovery Assurance"
              description="Verifies the availability and integrity of clean recovery points within set SLAs for reliable restoration."
            />
            <RadarChartDimensionsDescription
              title="Asset Coverage"
              description="Ensures comprehensive scanning of assets, which is critical for ransomware resilience."
            />
            <RadarChartDimensionsDescription
              title="Ransomware Safety"
              description="Measures 'cleanliness' against ransomware, detecting threats pre- and post-detonation in backups and storage."
            />
            <RadarChartDimensionsDescription
              title="Encryption Safety"
              description="Monitors encryption activity to detect unauthorized data encryption in backups and storage."
            />
            <RadarChartDimensionsDescription
              title="Storage Health"
              description="Assesses secure configurations for storage assets to minimize vulnerabilities."
            />
          </Stack>
        </Stack>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          gap={2}
          sx={{
            paddingX: 2.5,
            paddingY: 2,
          }}
        >
          <Button
            size="small"
            onClick={resetRpoDaysData}
            sx={{
              textTransform: 'capitalize',
              fontSize: 14,
              background: '#4E5665',
              color: '#fff',
              minWidth: '6.563rem',
            }}
          >
            Clear
          </Button>
          <Button
            type="submit"
            size="small"
            variant="contained"
            sx={{
              textTransform: 'capitalize',
              fontSize: 14,
              color: '#fff',
              minWidth: '6.563rem',
            }}
            onClick={saveRpoDaysData}
          >
            Save
          </Button>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}

export default ResilienceScoreDrawer
