import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import ProtectionPolicies from './protection-policies/index'

import {
  SETTINGS_ROUTE_SEGMENTS,
  POLICIES_SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

const SUB_TAB_MAP = {
  protection: {
    label: 'Protection Policies',
    component: <ProtectionPolicies />,
    slug: POLICIES_SETTINGS_ROUTE_SEGMENTS.PROTECTION_POLICIES,
  },
}

export default function Policies() {
  const navigate = useNavigate()
  const { subTab } = useParams<{
    subTab: (typeof POLICIES_SETTINGS_ROUTE_SEGMENTS)[keyof typeof POLICIES_SETTINGS_ROUTE_SEGMENTS]
  }>()

  const defaultPath = `/settings/${SETTINGS_ROUTE_SEGMENTS.POLICIES}/${POLICIES_SETTINGS_ROUTE_SEGMENTS.PROTECTION_POLICIES}`

  React.useEffect(() => {
    if (!subTab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(SUB_TAB_MAP).some((t) => t.slug === subTab)) {
      navigate(defaultPath, { replace: true })
      return
    }
  }, [])

  if (!subTab || !Object.values(SUB_TAB_MAP).some((t) => t.slug === subTab)) {
    return null
  }

  return (
    <Box sx={{ width: '100%' }}>
      <ProtectionPolicies />
    </Box>
  )
}
