import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import StyledButton from './styled-button'
import RestoreLastCleanBackupDrawer from './restore-forensic-drawer'
import { useListRecoveryPointsInfiniteQuery } from 'ui-v2/src/hooks/queries/recovery-points'
import { useBackupByIdQuery } from 'ui-v2/src/hooks/queries/backups'
import { buildAwsBackupDetailsUrl } from '../../widgets/last-clean-backup-details/util'

type ForensicTypes = {
  region: string
  vault: {
    name: string
  }
  arn: string
}

const RestoreForensic = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [scanIds, setScanIds] = useState<Array<string>>([])
  const [forensic, setForensic] = useState<ForensicTypes>({
    region: '',
    vault: { name: '' },
    arn: '',
  })

  const { assetId } = useParams<{
    assetId: string | undefined
  }>()

  const { data } = useListRecoveryPointsInfiniteQuery(assetId, {
    type: 'key',
  })

  const items = data?.pages?.flatMap((page) => page.items) || []
  const forensicItem =
    items &&
    items?.find((item) => {
      return (
        Array.isArray(item?.key) &&
        item?.key?.find((keyItem) => keyItem === 'earliest_infected_backup')
      )
    })

  const { data: forensicData } = useBackupByIdQuery(
    forensicItem?.id ? [forensicItem.id] : []
  )

  useEffect(() => {
    if (forensicData && forensicData.backupsList) {
      forensicData.backupsList?.forEach((item: any) => {
        const itemKeys = item && Object.keys(item)
        itemKeys?.forEach((key: any) => {
          if (
            item[key] &&
            item[key].id === forensicItem?.id &&
            item[key].scanIds
          ) {
            const newScanIds = Array.isArray(item[key].scanIds)
              ? item[key].scanIds
              : []
            setScanIds(newScanIds)
            setForensic(item[key] as ForensicTypes)
          }
        })
      })
    }
  }, [forensicData])

  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }

  const openDrawer = () => {
    setIsDrawerOpen(true)
  }

  const restoreRecoveryPointHandler = () => {
    if (
      forensicItem?.kind === 'awsb_ec2' ||
      forensicItem?.kind === 'awsb_ebs'
    ) {
      window.open(
        buildAwsBackupDetailsUrl(
          forensic?.region,
          forensic?.vault.name,
          forensic?.arn
        ),
        '_blank'
      )
    } else {
      openDrawer()
    }
  }

  if (
    (scanIds && scanIds.length && forensicItem?.kind === 'aws_ebs_snapshot') ||
    forensicItem?.kind === 'awsb_ec2' ||
    forensicItem?.kind === 'awsb_ebs'
  ) {
    return (
      <>
        <StyledButton
          variant={'outlined'}
          onClick={restoreRecoveryPointHandler}
        >
          Forensic Restore
        </StyledButton>
        <RestoreLastCleanBackupDrawer
          isOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
          scanIds={scanIds}
        />
      </>
    )
  } else {
    return <div style={{ minHeight: '23px' }} />
  }
}

export default RestoreForensic
