import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { PolicyS3BucketTableRow } from 'ui-v2/src/lib/models/settings/policies/policies'

export const getS3BucketColumns = (): Array<Column<PolicyS3BucketTableRow>> => [
  {
    key: 's3Bucket',
    header: 'S3 Bucket',
    render: (row) => row.s3Bucket,
  },
  {
    key: 'account',
    header: 'Account',
    render: (row) => row.account,
  },
  {
    key: 'policy',
    header: 'Policy',
    render: (row) => row.policy,
  },
]
