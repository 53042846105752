/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { useAssetsThreatsFilters } from 'ui-v2/src/hooks/filters/assets-threats'
import {
  ASSETS_THREATS_FILTERS_MAP,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { ValueInterface } from 'ui-v2/src/lib/engine-types'
import DataHelper from 'ui-v2/src/lib/helpers/data.helper'
import {
  listS3BucketsWithActiveThreatsPossibleFilters,
  ListS3BucketsWithThreats,
} from 'blues-corejs/dist'
import { useSearchParams } from 'react-router-dom'
import {
  initialPrioritySortForS3Table,
  useLiveAssetsDataForAssetsThreat,
} from '../utils'
import { Stack } from '@mui/material'
import AssetsThreatsFilters from '../filters'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { getS3ThreatColumns } from './column-config'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import { AssetThreatsTabs, TAB_FILTERS } from '../filters/config'
import ThreatList from '../threat-list'

const AssetsThreatsS3 = () => {
  const filters = useAssetsThreatsFilters()
  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)

  const { data: liveAssetsData, isLoading } = useLiveAssetsDataForAssetsThreat()

  const data = useMemo(() => {
    if (!liveAssetsData) {
      return undefined
    }

    const { s3buckets, threats, lastMalwareScans, lastBackups } = liveAssetsData

    if (s3buckets.length) {
      return {
        s3buckets,
        threats,
        lastBackups,
        lastScans: lastMalwareScans,
      }
    }
  }, [liveAssetsData])

  const possibleS3BucketsWithActiveThreatsFilters = useMemo(() => {
    if (!data) {
      return undefined
    }

    return new listS3BucketsWithActiveThreatsPossibleFilters({
      s3BucketsList: data.s3buckets,
      threats: data.threats,
    }).execute().possibleS3BucketsWithActiveThreatsFilters
  }, [data])

  const s3FilteredData = useMemo(() => {
    if (!data) {
      return undefined
    }

    const parseFilters = (selectedFilters: Array<ValueInterface>) => {
      return DataHelper.getDataForDashboardFilter(selectedFilters, search ?? '')
    }

    return initialPrioritySortForS3Table(
      new ListS3BucketsWithThreats(data).execute(parseFilters(filters))
    )
  }, [data, filters])

  const columns = useMemo(getS3ThreatColumns, [])

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems={'center'}>
        <SearchBar />
        {!isLoading && <ThreatList filters={filters} />}
      </Stack>
      <AssetsThreatsFilters
        filters={TAB_FILTERS[AssetThreatsTabs.S3]}
        filterValues={{
          [ASSETS_THREATS_FILTERS_MAP.REGION]:
            possibleS3BucketsWithActiveThreatsFilters?.regions,
          [ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID]:
            possibleS3BucketsWithActiveThreatsFilters?.accountIds,
          [ASSETS_THREATS_FILTERS_MAP.MALWARE]:
            possibleS3BucketsWithActiveThreatsFilters?.malwaresList,
        }}
      />
      <ClientTable
        idField="s3Id"
        data={s3FilteredData ?? []}
        columns={columns}
        isLoading={isLoading}
      />
    </Stack>
  )
}

export default AssetsThreatsS3
