import { useEffect } from 'react'
import {
  FormControl,
  Grid2,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'

import Timezone from './timezone'
import { useUserProfileQuery } from 'ui-v2/src/hooks/queries/users'
import { AppDispatch, RootState } from 'ui-v2/src/store'
import { setUserProfile } from 'ui-v2/src/store/features/user/user-slice'
import Label from 'ui-v2/src/components/ui/data-display/label'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'

const AccountProfile = () => {
  const { data } = useUserProfileQuery()

  const userProfile = useSelector((state: RootState) => state.user.userProfile)

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (data) {
      dispatch(setUserProfile(data))
    }
  }, [data])

  return (
    <Stack spacing={2.5} sx={{ paddingBottom: 2.5 }} maxWidth={824}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <FormControl fullWidth>
            <Label>Profile</Label>
            <TextField
              placeholder="Profile Email"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography
                        variant="body2"
                        fontWeight={400}
                        sx={(theme) => ({
                          color:
                            theme.palette.mode === 'dark'
                              ? 'rgba(211, 216, 222, 1)'
                              : '#1A1F28',
                        })}
                      >
                        Profile name :
                      </Typography>
                    </InputAdornment>
                  ),
                },
              }}
              value={userProfile?.email}
              disabled
            />
          </FormControl>
        </Grid2>
        <Grid2 size={12}>
          <Label>Basic Information</Label>
        </Grid2>
        <Grid2 size={12}>
          <FormControl
            fullWidth
            sx={{
              maxWidth: {
                sm: '100%',
                md: 438,
              },
            }}
          >
            <Label>Name of Account</Label>
            <TextField value={userProfile?.name} disabled />
          </FormControl>
        </Grid2>
      </Grid2>

      <Timezone />
    </Stack>
  )
}

export default AccountProfile
