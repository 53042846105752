import Link from 'ui-v2/src/components/ui/data-display/link'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import ThreatIndicator from '../common/threat-indicator'
import { RecoveryPointRow } from '../common/types'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { FileCopy } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { ToastUtils } from 'ui-v2/src/hooks/toast'

export const getRecoveryPointColumns = (
  toast: ToastUtils,
  resourceName: string
): Array<Column<RecoveryPointRow>> => [
  {
    key: 'health',
    header: 'Health',
    render: (row) => {
      return (
        <StatusChip
          type={row.health as 'disabled' | 'error' | 'success' | 'warning'}
        />
      )
    },
    width: 80,
    align: 'center',
    sortable: false,
  },
  {
    key: 'name',
    header: `${resourceName} ID`,
    render: (row) => (
      <>
        <Link
          to={
            row.entity_kind
              ? `/${row.entity_kind}/${row.id}`
              : `/asset/${row.id}`
          }
          preservePath
          preserveSearchParams
        >
          {row.name}
        </Link>
        <IconButton
          onClick={() => {
            copyToClipboard(row.name).then(() => {
              toast.success('Copied to clipboard')
            })
          }}
          size="small"
          sx={{ padding: '3px' }}
        >
          <FileCopy
            sx={{
              fontSize: '16px',
              color: '#B2B5C1',
            }}
          />
        </IconButton>
      </>
    ),
    sortable: false,
  },
  {
    key: 'ransomware_status',
    header: 'Ransomware',
    render: (row) => <ThreatIndicator type={row.ransomware_status} />,
    sortable: false,
  },
  {
    key: 'anomaly_status',
    header: 'Insider Threats',
    render: (row) => <ThreatIndicator type={row.anomaly_status} />,
    sortable: false,
  },
  {
    key: 'malware_status',
    header: 'Malware',
    render: (row) => <ThreatIndicator type={row.malware_status} />,
    sortable: false,
  },
  {
    key: 'fs_check_status',
    header: 'Filesystem Integrity',
    render: (row) => <ThreatIndicator type={row.fs_check_status} />,
    sortable: false,
  },
]
