import {
  AssetsPromiseClient,
  Empty,
  ListAssetsByIdsRequest,
  ListAssetsItemsRequest,
  ListEbsRequest,
  ListEc2Request,
  ListEfsRequest,
  ListGenericHostsRequest,
  ListOvaServerRequest,
  ListS3Request,
  Pagination,
} from 'ui-v2/src/lib/grpc'
import { GrpcClient } from './grpc'
import { DEFAULT_PAGE_SIZE } from 'ui-v2/src/lib/constants/api.constant'
import { ClientConfig } from '../models/client'
import {
  EC2Transformer,
  EBSTransformer,
  S3BucketTransformer,
  EFSTransformer,
  OvaServerTransformer,
  GenericHostTransformer,
  ListLiveAssetsTransformer,
  FileTransformer,
  StreamTransformer,
  VolumeTransformer,
  DiskTransformer,
} from '../transformers/assets'
import {
  ListAssetItemsFilters,
  ListEbsFilters,
  ListEc2Filters,
  ListEfsFilters,
  ListGenericHostFilters,
  ListOvaServerFilters,
  ListS3Filters,
} from '../models/assets'
import { OvaBackupTransformer } from '../transformers/recovery'

export class AssetsClient extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#assetsClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'AssetsClient'
  }

  protected initClient(hostName = ''): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  async listEc2(filters: ListEc2Filters) {
    const request = new ListEc2Request()
    const initial = new ListEc2Request.Initial()
    const filter = new ListEc2Request.Initial.Filter()

    if (filters.assetIdList && filters.assetIdList.length) {
      filter.setAssetIdList(filters.assetIdList)
    }

    if (filters.accountIdsList && filters.accountIdsList.length) {
      filter.setAccountIdsList(filters.accountIdsList)
    }

    if (filters.instanceStatesList && filters.instanceStatesList.length) {
      filter.setInstanceStatesList(filters.instanceStatesList)
    }

    if (filters.regionsList && filters.regionsList.length) {
      filter.setRegionsList(filters.regionsList)
    }

    if (
      filters.protectionPolicyCoverageStatesList &&
      filters.protectionPolicyCoverageStatesList.length
    ) {
      filter.setProtectionPolicyCoverageStatesList(
        filters.protectionPolicyCoverageStatesList
      )
    }

    if (filters.search) {
      filter.setSearch(filters.search)
    }

    initial.setPageSize(filters.pageSize ?? DEFAULT_PAGE_SIZE)
    initial.setFilter(filter)

    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(new Pagination().setPageToken(filters.pageToken))
    }

    const response = await this.callGrpcService(
      () => this.#assetsClient.listEC2(request, this.metadata(this.#token)),
      {
        requestName: 'AssetsPromiseClient/listEc2',
      }
    )

    const responseObject = response.toObject()

    return {
      instancesList: responseObject.instancesList.map((item) =>
        new EC2Transformer(item).transform()
      ),
      pagination: responseObject.pagination,
    }
  }

  async listEbs(filters: ListEbsFilters) {
    const request = new ListEbsRequest()
    const initial = new ListEbsRequest.Initial()
    const filter = new ListEbsRequest.Initial.Filter()

    if (filters.assetIdList && filters.assetIdList.length) {
      filter.setAssetIdList(filters.assetIdList)
    }

    if (filters.tagsList && filters.tagsList.length) {
      const tagsList = filters.tagsList.map((tag) => {
        const grpcTag = new ListEc2Request.Initial.Filter.Tag()
        grpcTag.setKey(tag.key)
        grpcTag.setValue(tag.value)
        return grpcTag
      })
      filter.setTagsList(tagsList)
    }

    if (filters.accountIdsList && filters.accountIdsList.length) {
      filter.setAccountIdsList(filters.accountIdsList)
    }

    if (
      filters.protectionPolicyCoverageStatesList &&
      filters.protectionPolicyCoverageStatesList.length
    ) {
      filter.setProtectionPolicyCoverageStatesList(
        filters.protectionPolicyCoverageStatesList
      )
    }

    if (filters.search) {
      filter.setSearch(filters.search)
    }

    if (filters.volumeStatesList) {
      filter.setVolumeStatesList(filters.volumeStatesList)
    }

    initial.setPageSize(filters.pageSize ?? DEFAULT_PAGE_SIZE)
    initial.setFilter(filter)

    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(new Pagination().setPageToken(filters.pageToken))
    }

    const response = await this.callGrpcService(
      () => this.#assetsClient.listEBS(request, this.metadata(this.#token)),
      {
        requestName: 'AssetsPromiseClient/listEbs',
      }
    )

    const responseObject = response.toObject()

    return {
      volumesList: responseObject.volumesList.map((item) =>
        new EBSTransformer(item).transform()
      ),
      pagination: responseObject.pagination,
    }
  }

  async listS3(filters: ListS3Filters) {
    const request = new ListS3Request()
    const initial = new ListS3Request.Initial()
    const filter = new ListS3Request.Initial.Filter()

    if (filters.assetIdList && filters.assetIdList.length) {
      filter.setAssetIdList(filters.assetIdList)
    }

    if (filters.tagsList && filters.tagsList.length) {
      const tagsList = filters.tagsList.map((tag) => {
        const grpcTag = new ListEc2Request.Initial.Filter.Tag()
        grpcTag.setKey(tag.key)
        grpcTag.setValue(tag.value)
        return grpcTag
      })
      filter.setTagsList(tagsList)
    }

    if (filters.accountIdsList && filters.accountIdsList.length) {
      filter.setAccountIdsList(filters.accountIdsList)
    }

    if (filters.regionsList && filters.regionsList.length) {
      filter.setRegionsList(filters.regionsList)
    }

    if (
      filters.protectionPolicyCoverageStatesList &&
      filters.protectionPolicyCoverageStatesList.length
    ) {
      filter.setProtectionPolicyCoverageStatesList(
        filters.protectionPolicyCoverageStatesList
      )
    }

    if (filters.search) {
      filter.setSearch(filters.search)
    }

    if (filters.bucketStatesList) {
      filter.setBucketStatesList(filters.bucketStatesList)
    }

    initial.setPageSize(filters.pageSize ?? DEFAULT_PAGE_SIZE)
    initial.setFilter(filter)

    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(new Pagination().setPageToken(filters.pageToken))
    }

    const response = await this.callGrpcService(
      () => this.#assetsClient.listS3(request, this.metadata(this.#token)),
      {
        requestName: 'AssetsPromiseClient/listS3',
      }
    )

    const responseObject = response.toObject()

    return {
      bucketsList: responseObject.bucketsList.map((item) =>
        new S3BucketTransformer(item).transform()
      ),
      pagination: responseObject.pagination,
    }
  }

  async listEfs(filters: ListEfsFilters) {
    const request = new ListEfsRequest()
    const initial = new ListEfsRequest.Initial()
    const filter = new ListEfsRequest.Initial.Filter()

    if (filters.assetIdList && filters.assetIdList.length) {
      filter.setAssetIdList(filters.assetIdList)
    }

    if (filters.tagsList && filters.tagsList.length) {
      const tagsList = filters.tagsList.map((tag) => {
        const grpcTag = new ListEc2Request.Initial.Filter.Tag()
        grpcTag.setKey(tag.key)
        grpcTag.setValue(tag.value)
        return grpcTag
      })
      filter.setTagsList(tagsList)
    }

    if (
      filters.protectionPolicyCoverageStatesList &&
      filters.protectionPolicyCoverageStatesList.length
    ) {
      filter.setProtectionPolicyCoverageStatesList(
        filters.protectionPolicyCoverageStatesList
      )
    }

    if (filters.regionsList && filters.regionsList.length) {
      filter.setRegionsList(filters.regionsList)
    }

    if (filters.accountIdsList && filters.accountIdsList.length) {
      filter.setAccountIdsList(filters.accountIdsList)
    }

    if (filters.search) {
      filter.setSearch(filters.search)
    }

    if (filters.filesystemStatesList) {
      filter.setFilesystemStatesList(filters.filesystemStatesList)
    }

    initial.setPageSize(filters.pageSize ?? DEFAULT_PAGE_SIZE)
    initial.setFilter(filter)

    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(new Pagination().setPageToken(filters.pageToken))
    }

    const response = await this.callGrpcService(
      () => this.#assetsClient.listEFS(request, this.metadata(this.#token)),
      {
        requestName: 'AssetsPromiseClient/listEfs',
      }
    )

    const responseObject = response.toObject()

    return {
      filesystemsList: responseObject.filesystemsList.map((item) =>
        new EFSTransformer(item).transform()
      ),
      pagination: responseObject.pagination,
    }
  }

  async listOvaServers(filters: ListOvaServerFilters) {
    const request = new ListOvaServerRequest()
    const initial = new ListOvaServerRequest.Initial()
    const filter = new ListOvaServerRequest.Initial.Filters()

    if (filters.accountIdsList && filters.accountIdsList.length) {
      filter.setAccountIdsList(filters.accountIdsList)
    }

    if (filters.backupProvidersList && filters.backupProvidersList.length) {
      filter.setBackupProvidersList(filters.backupProvidersList)
    }

    if (filters.statusList && filters.statusList.length) {
      filter.setStatusesList(filters.statusList)
    }

    if (filters.search) {
      filter.setTextSearch(filters.search)
    }

    initial.setPageSize(filters.pageSize ?? DEFAULT_PAGE_SIZE)
    initial.setFilters(filter)

    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(new Pagination().setPageToken(filters.pageToken))
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#assetsClient.listOvaServers(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AssetsPromiseClient/listOvaServers',
        }
      )
    ).toObject()

    return {
      pageToken: response.pagination?.nextPageToken,
      totalAccounts: response.totalAccounts,
      totalServers: response.totalServers,
      totalProviders: response.totalProviders,
      servers: response.serversList.map(
        ({ hasMalware, hasRansomware, lastCleanBackup, server }) => ({
          hasMalware,
          hasRansomware: hasRansomware,
          lastCleanBackup:
            lastCleanBackup &&
            new OvaBackupTransformer(lastCleanBackup).transform(),
          server: new OvaServerTransformer(server!).transform(),
        })
      ),
      pagination: response.pagination,
    }
  }

  async listGenericHosts(filters: ListGenericHostFilters) {
    const request = new ListGenericHostsRequest()
    const initial = new ListGenericHostsRequest.Initial()
    const filter = new ListGenericHostsRequest.Initial.Filter()

    if (filters.hostnameList && filters.hostnameList.length) {
      filter.setHostNameList(filters.hostnameList)
    }

    initial.setPageSize(filters.pageSize ?? DEFAULT_PAGE_SIZE)
    initial.setFilter(filter)

    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(new Pagination().setPageToken(filters.pageToken))
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#assetsClient.listGenericHosts(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AssetsPromiseClient/listGenericHosts',
        }
      )
    ).toObject()

    return {
      hostsList: response.hostsList.map((item) =>
        new GenericHostTransformer(item).transform()
      ),
      pagination: response.pagination,
    }
  }

  async listLiveAssets() {
    const response = await this.callGrpcService(
      () =>
        this.#assetsClient.listLiveAssets(
          new Empty(),
          this.metadata(this.#token)
        ),
      {
        requestName: 'AssetsPromiseClient/listLiveAssets',
      }
    )

    return new ListLiveAssetsTransformer().transformResponse(response)
  }

  async listAssetsByIds(assetIds: Array<string>) {
    const request = new ListAssetsByIdsRequest()
    request.setAssetIdsList(assetIds)

    const response = (
      await this.callGrpcService(
        () =>
          this.#assetsClient.listAssetsByIds(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AssetsPromiseClient/listAssetsByIds',
        }
      )
    ).toObject()

    return {
      assetsList: response.assetsList.map(
        ({
          ec2Instance,
          ebsVolume,
          s3Bucket,
          efsFilesystem,
          genericHost,
          ovaServer,
        }) => ({
          ec2Instance:
            ec2Instance && new EC2Transformer(ec2Instance).transform(),
          ebsVolume: ebsVolume && new EBSTransformer(ebsVolume).transform(),
          s3Bucket: s3Bucket && new S3BucketTransformer(s3Bucket).transform(),
          efsFilesystem:
            efsFilesystem && new EFSTransformer(efsFilesystem).transform(),
          genericHost:
            genericHost && new GenericHostTransformer(genericHost).transform(),
          ovaServer:
            ovaServer && new OvaServerTransformer(ovaServer).transform(),
        })
      ),
    }
  }

  async listAssetItems(filters: ListAssetItemsFilters) {
    const request = new ListAssetsItemsRequest()
    const initial = new ListAssetsItemsRequest.Initial()
    const filter = new ListAssetsItemsRequest.Initial.Filter()

    if (filters.assetIdList && filters.assetIdList.length) {
      filter.setAssetIdList(filters.assetIdList)
    }

    if (filters.itemIdList && filters.itemIdList.length) {
      filter.setItemIdList(filters.itemIdList)
    }

    if (filters.itemNameList && filters.itemNameList.length) {
      filter.setItemNameList(filters.itemNameList)
    }

    if (
      filters.retentionPolicyNameList &&
      filters.retentionPolicyNameList.length
    ) {
      filter.setRetentionPolicyNameList(filters.retentionPolicyNameList)
    }

    if (filters.kindList && filters.kindList.length) {
      filter.setKindList(filters.kindList)
    }

    initial.setPageSize(filters.pageSize ?? DEFAULT_PAGE_SIZE)
    initial.setFilter(filter)

    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(new Pagination().setPageToken(filters.pageToken))
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#assetsClient.listAssetItems(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AssetsPromiseClient/listAssetItems',
        }
      )
    ).toObject()

    return {
      filesList: response.filesList.map((file) =>
        new FileTransformer(file).transform()
      ),
      streamsList: response.streamsList.map((stream) =>
        new StreamTransformer(stream).transform()
      ),
      volumesList: response.volumesList.map((volume) =>
        new VolumeTransformer(volume).transform()
      ),
      disksList: response.disksList.map((disk) =>
        new DiskTransformer(disk).transform()
      ),
    }
  }
}
