/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import {
  BackupVariant,
  InstanceOutput,
  RiskLevel,
  SnapshotOutput,
  VolumeOutput,
  VulnerabilitiesDetectionStrategy,
} from 'blues-corejs/dist'
import { Scan } from 'ui-v2/src/lib/models/scans'
import {
  AwsSnapshotVulnerability,
  AwsVolumeVulnerability,
} from '@lib/clients/aws-statistics/types'
import { VIRow } from 'ui-v2/src/lib/engine-types'

export interface FilterOptions {
  [key: string]: Array<string>
}

function sortByLastBackupAndName<T>(
  items: Array<T>,
  getLastBackupTime: (item: T) => number,
  getName: (item: T) => string
) {
  return items.sort((a, b) => {
    const aLastBackupTime = getLastBackupTime(a)
    const bLastBackupTime = getLastBackupTime(b)

    if (aLastBackupTime !== bLastBackupTime) {
      return bLastBackupTime - aLastBackupTime
    }

    return getName(a).localeCompare(getName(b))
  })
}

export function initialPrioritySortForEC2Table(
  instances: Array<InstanceOutput>
) {
  return sortByLastBackupAndName(
    instances,
    (instance) => instance.lastBackup?.backup.timestamp.getTime() || 0,
    (instance) => instance.instanceName || instance.instanceAwsId
  )
}

export function initialPrioritySortForEBSVolumeTable(
  volumes: Array<VolumeOutput>
) {
  return sortByLastBackupAndName(
    volumes,
    (volume) => volume.lastBackup?.backup.timestamp.getTime() || 0,
    (volume) => volume.volumeName || volume.volumeAwsId
  )
}

export function initialPrioritySortForEBSSnapshotsTable(
  snapshots: Array<SnapshotOutput>
) {
  return sortByLastBackupAndName(
    snapshots,
    (snapshot) => snapshot.riskVulnerabilities || 0,
    (snapshot) => snapshot.volumeId || snapshot.volumeAwsId
  )
}

export const useLiveAssetsDataForAssetsVulnerabilities = () => {
  const { data: liveAssetsData, isLoading } = useAssetsLiveQuery()
  return useMemo(() => {
    if (!liveAssetsData) {
      return {
        data: undefined,
        isLoading,
      }
    }

    const { ec2Instances, ebsVolumes, scans, lastBackups, lastEbsSnapshots } =
      liveAssetsData

    const lastBackupsMap = new Map<string, BackupVariant>()
    for (const [key, backup] of lastBackups.backups) {
      lastBackupsMap.set(key, { backup })
    }

    const lastScan: Array<Scan> = scans.flatMap(
      (scan) =>
        scan.ransomwareScan ||
        scan.malwareScan ||
        scan.filesystemCheckScan ||
        []
    )

    return {
      data: {
        volumes: ebsVolumes,
        instances: ec2Instances,
        snapshots: lastEbsSnapshots,
        lastBackups: lastBackupsMap,
        scans: lastScan,
      },
      isLoading,
    }
  }, [liveAssetsData, isLoading])
}

export function getRiskNameFormQuery(
  risk?: string | Array<string>
): RiskLevel | undefined {
  switch (risk) {
    case '0':
      return RiskLevel.Low
    case '1':
      return RiskLevel.Medium
    case '2':
      return RiskLevel.High

    default:
      return undefined
  }
}

export function calculateTotalVulnerabilitiesByRisk({
  volumeVulnerabilitiesList,
  snapshotVulnerabilitiesList,
}: {
  volumeVulnerabilitiesList: Array<AwsVolumeVulnerability>
  snapshotVulnerabilitiesList: Array<AwsSnapshotVulnerability>
}) {
  const summaryVulnerabilities = [
    ...volumeVulnerabilitiesList,
    ...snapshotVulnerabilitiesList,
  ]

  return summaryVulnerabilities.reduce(
    (total, vulnerability) => {
      const riskLevel =
        new VulnerabilitiesDetectionStrategy().identifyRiskLevel(
          vulnerability.kind
        )

      switch (riskLevel) {
        case RiskLevel.High:
          return {
            ...total,
            high: total.high + vulnerability.total,
          }
        case RiskLevel.Medium:
          return {
            ...total,
            medium: total.medium + vulnerability.total,
          }
        case RiskLevel.Low:
          return {
            ...total,
            low: total.low + vulnerability.total,
          }
        default:
          return total
      }
    },
    {
      high: 0,
      medium: 0,
      low: 0,
    }
  )
}

export function getRiskName(risk?: string | Array<string>): string {
  switch (risk) {
    case '0':
      return 'Low'
    case '1':
      return 'Medium'
    case '2':
      return 'High'
    default:
      return 'High'
  }
}

export function getRiskStatus(riskVulnerabilities: number) {
  let status
  switch (riskVulnerabilities) {
    case 0:
      status = {
        type: 'success' as const,
        label: 'Low',
      }
      break
    case 1:
      status = {
        type: 'warning' as const,
        label: 'Medium',
      }
      break
    case 2:
      status = {
        type: 'error' as const,
        label: 'High',
      }
      break
    default:
      status = {
        type: 'error' as const,
        label: 'High',
      }
      break
  }
  return status
}

export const filterOptions = (filters: VIRow) => {
  const viOptions: FilterOptions = filters.reduce(
    (acc, { name, options }) => ({
      ...acc,
      [name]:
        options
          ?.map(({ name: optionName }) => optionName)
          .filter((value): value is string => typeof value === 'string') || [],
    }),
    {} as FilterOptions
  )

  return viOptions
}
