import React from 'react'
import { useParams } from 'react-router-dom'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { useScheduleBackupScansMutation } from 'ui-v2/src/hooks/queries/pechkin'
import { useToast } from 'ui-v2/src/hooks/toast'

const StartBackupScan = () => {
  const { recoveryPointId } = useParams<{
    recoveryPointId: string | undefined
  }>()
  const toast = useToast()

  const { mutate, isPending } = useScheduleBackupScansMutation()

  const startBackupScan = async () => {
    if (!recoveryPointId) {
      return
    }

    mutate([recoveryPointId], {
      onSuccess: () => {
        toast.success('Backup scan is scheduled')
      },
      onError: (error) => {
        toast.error(error.message ?? 'Failed to schedule backup scan')
      },
    })
  }

  return (
    <Button
      variant="text"
      sx={(theme) => ({
        padding: 0,
        fontWeight: 400,
        color: theme.palette.link.main,

        '&:hover': {
          textDecoration: 'underline',
          background: 'transparent',
        },
      })}
      onClick={startBackupScan}
      isLoading={isPending}
    >
      Start Scan
    </Button>
  )
}

export default StartBackupScan
