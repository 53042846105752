/* eslint-disable import/no-extraneous-dependencies */
import { InventoryVolumeOutput } from 'blues-corejs/dist/use_cases/list_inventory_volumes/types'
import { AssetDetailsLink } from 'ui-v2/src/components/ui/data-display/asset-details-link'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import Link from 'ui-v2/src/components/ui/data-display/link'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import {
  formatDateToUtc,
  formatMilliseconds,
} from 'ui-v2/src/lib/helpers/time.helper'

export const getEbsEncryptionActivityColumns = (): Array<
  Column<InventoryVolumeOutput>
> => [
  {
    key: 'isUnhealthy',
    header: 'Health',
    render: (row) => (
      <StatusChip type={row.isUnhealthy ? 'error' : 'success'} />
    ),
    width: 80,
    align: 'center',
  },
  {
    key: 'awsId',
    header: 'Volume ID',
    render: (row) => (
      <AssetDetailsLink
        assetId={row.uuid}
        assetKind={ASSETS_ROUTE_SEGMENTS.EBS}
      >
        {row.awsId}
      </AssetDetailsLink>
    ),
  },
  {
    key: 'volumeName',
    header: 'Volume',
    render: (row) => (
      <Link to={`/${row.uuid}`} preservePath preserveSearchParams>
        {row.volumeName}
      </Link>
    ),
  },
  {
    key: 'accountId',
    header: 'Account ID',
  },
  {
    key: 'region',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.region)
      return region?.en?.single ?? ''
    },
  },
  {
    key: 'lastBackup',
    header: 'Last Backup',
    render: (row) => formatDateToUtc(row.lastBackup?.createdAt),
  },
  {
    key: 'lastScan',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(row.lastScan),
  },
  {
    key: 'rpo',
    header: 'RPO',
    render: (row) => (row.rpo ? formatMilliseconds(row.rpo) : ''),
  },
  {
    key: 'policies',
    header: 'Policy Coverage',
    render: (row) =>
      Array.isArray(row.policies) ? row.policies.join(', ') : '',
  },
]
