/* eslint-disable import/no-extraneous-dependencies */
import { Drawer, DrawerHeader } from 'ui-v2/src/components/ui/drawer'
import { FileCopyOutlined } from '@mui/icons-material'
import { Box, Button, Card, Typography, useTheme } from '@mui/material'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import SyntaxHighlighter from 'react-syntax-highlighter'
import {
  a11yDark,
  a11yLight,
} from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import { restoreLastCleanSnapshotsScript } from '../../utils'
import { useToast } from 'ui-v2/src/hooks/toast'

type RestoreLastCleanBackupDrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
  scanIds: Array<string>
}

const RestoreLastCleanBackupDrawer: React.FC<
  RestoreLastCleanBackupDrawerProps
> = ({ isOpen, closeDrawer, scanIds }) => {
  const theme = useTheme()
  const toast = useToast()

  return (
    <Drawer
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DrawerHeader
        title="Restore Forensics Copy"
        handleCloseDrawer={closeDrawer}
      />

      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          paddingX: 2.5,
          paddingBottom: 2.5,
        }}
      >
        <Typography variant="body2" mt={2}>
          To restore to the last clean state of the instance, run the following
          script in the AWS Cloud Shell.
        </Typography>

        <Card
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            width: '41.25rem',
          }}
        >
          <SyntaxHighlighter
            language="bash"
            style={theme.palette.mode === ThemeMode.DARK ? a11yDark : a11yLight}
            customStyle={{
              margin: '0 0 16px 0',
              padding: '12px',
              overflowY: 'auto',
              fontSize: '14px',
              flex: 1,
              background: 'transparent',
            }}
          >
            {restoreLastCleanSnapshotsScript(scanIds)}
          </SyntaxHighlighter>
        </Card>
      </Box>

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          width: '100%',
          padding: 2,
          backgroundColor: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button
          startIcon={<FileCopyOutlined />}
          variant="contained"
          onClick={() => {
            copyToClipboard(restoreLastCleanSnapshotsScript(scanIds))
            toast.success('Restore Last Clean Copied to Clipboard')
          }}
        >
          Copy Restore Forensincs Script
        </Button>
      </Box>
    </Drawer>
  )
}

export default RestoreLastCleanBackupDrawer
