import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { BackupsClient, PechkinClient } from 'ui-v2/src/lib/clients'
import { useMutation } from '@tanstack/react-query'

export function useScheduleBackupScansMutation() {
  const pechkinClient = useGrpcClient(PechkinClient)
  const backupsClient = useGrpcClient(BackupsClient)

  return useMutation({
    mutationFn: async (backupIdList: Array<string>) => {
      if (!pechkinClient || !backupsClient) {
        throw new Error('Client not initialized')
      }

      if (!backupIdList.length) {
        throw new Error('No backup IDs provided')
      }

      const backupsData = await backupsClient.getBackupsByIds(backupIdList)

      if (!backupsData.backupsList?.length) {
        throw new Error('No backups found for the provided IDs')
      }

      const promiseList = backupsData.backupsList.map((backup) => {
        if (backup.ebsSnapshot) {
          return pechkinClient.awsEbsSnapshotScan(backup.ebsSnapshot)
        }
        if (backup.awsbRp) {
          return pechkinClient.awsBackupRpScan(backup.awsbRp)
        }
        if (backup.elastioRp) {
          return pechkinClient.scheduleRecoveryPointScan(backup.elastioRp)
        }
        return Promise.reject(new Error('OVA backups cannot be scanned'))
      })

      return Promise.all(promiseList)
    },
  })
}
