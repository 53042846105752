import React from 'react'
import { PriorityHigh } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { ThreatStatus, ThreatStatusMap } from './types'

type ThreatIndicatorProps = {
  type: ThreatStatus
}

const THREAT_COLOR_MAP = {
  [ThreatStatus.CLEAN]: '#319246',
  [ThreatStatus.DETECTED]: '#BA0B0B',
  [ThreatStatus.NOT_COVERED]: 'transparent',
}

const ThreatIndicator: React.FC<ThreatIndicatorProps> = ({ type }) => {
  return (
    <Stack direction={'row'} spacing={1}>
      <Box
        sx={(theme) => ({
          width: '20px',
          aspectRatio: 1,
          borderRadius: '50px',
          background: THREAT_COLOR_MAP[type],
          border:
            type === ThreatStatus.NOT_COVERED
              ? `1px solid ${theme.palette.text.secondary}`
              : 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        {type === ThreatStatus.DETECTED ? (
          <PriorityHigh
            sx={{
              fontSize: '14px',
              color: '#fff',
            }}
          />
        ) : null}
      </Box>

      <Typography variant="body2">{ThreatStatusMap[type]}</Typography>
    </Stack>
  )
}

export default ThreatIndicator
