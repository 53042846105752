/* eslint-disable import/no-extraneous-dependencies */
import { createColumn, Table } from '@components/table'
import { Box } from '@mui/material'
import {
  Tag,
  TagsOperator,
} from 'blues-corejs/dist/models/notifications/rule/criteria'
import React from 'react'
import { readableTagsOperator } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope/asset-tag-selection/asset-tag-selection-store'
import { KeyValueDisplay } from '@features/alerts/modals/rule-details/rule-details'

const getColumns = () => {
  return [
    createColumn<TableDataInterface>('key', {
      header: 'Tags (Key : Value)',
      enableSorting: false,
      meta: {
        width: '40%',
      },
      cell: ({ cell }) => cell.getValue(),
    }),
    createColumn<TableDataInterface>('value', {
      meta: {
        width: '60%',
      },
      enableSorting: false,
      cell: ({ cell }) => cell.getValue(),
    }),
  ]
}

interface TableDataInterface {
  key: string
  value: string
}

export const computeTags = (tags: Array<Tag>, tagsOperator: TagsOperator) => {
  const tableData = tags.map(({ key, value }) => ({
    key,
    value,
  }))

  if (tableData.length === 0) {
    return null
  }

  return (
    <Box mb={2}>
      <KeyValueDisplay
        title="Tags Operator"
        value={readableTagsOperator[tagsOperator]}
      />
      <Table
        data={tableData}
        columns={getColumns()}
        generalProps={{
          noDataMessage: 'No tags found',
          isLoading: false,
        }}
      />
    </Box>
  )
}
