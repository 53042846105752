/* eslint-disable */
import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import StatsCards from './stats'
import CoverageCards from './coverage'
import LoadingActionCard from './loading-action-card'

export const CARD_TYPES = {
  STATS_CARDS: 'stats-cards',
  COVERAGE_CARDS: 'coverage-cards',
} as const

export type CardType = (typeof CARD_TYPES)[keyof typeof CARD_TYPES]

export interface CardLoadingState {
  [CARD_TYPES.STATS_CARDS]: boolean
  [CARD_TYPES.COVERAGE_CARDS]: boolean
}

const ActionCards = () => {
  const [cardLoadingState, setCardLoadingState] = useState<CardLoadingState>({
    [CARD_TYPES.STATS_CARDS]: true,
    [CARD_TYPES.COVERAGE_CARDS]: true,
  })

  const isLoading = Object.values(cardLoadingState).some((value) => value)

  return (
    <Stack gap={2} className="action-cards">
      <Typography
        fontWeight={700}
        sx={(theme) => ({
          position: 'sticky',
          top: 0,
          backgroundColor: theme.palette.background.default,
          zIndex: 1,
        })}
      >
        Scan Results
      </Typography>
      <div style={{ position: 'relative' }}>
        {isLoading ? (
          <Stack gap={2}>
            {Array.from({ length: 6 }).map((_, index) => (
              <LoadingActionCard key={index} />
            ))}
          </Stack>
        ) : null}
        <Stack gap={2}>
          <StatsCards
            cardLoadingState={cardLoadingState}
            setCardLoadingState={setCardLoadingState}
          />
          <CoverageCards
            cardLoadingState={cardLoadingState}
            setCardLoadingState={setCardLoadingState}
          />
        </Stack>
      </div>
    </Stack>
  )
}

export default ActionCards
