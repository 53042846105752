import React from 'react'
import { Delete } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import DateRangePicker from 'ui-v2/src/components/ui/inputs/date-range-picker'
import { TimeRangeInSeconds } from 'ui-v2/src/lib/models/types'
import JobKindDropdown from './job-kind-dropdown'
import JobStatusDropdown from './job-status-dropdown'
import { useTenantJobFiltersQuery } from 'ui-v2/src/hooks/queries/jobs'

const Filters = ({ startDate, endDate, timeRange }: FiltersProps) => {
  const { data: filterData } = useTenantJobFiltersQuery(timeRange)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleDateSearchParams = (sDate: Dayjs, eDate: Dayjs) => {
    if (!dayjs(sDate).isValid() || !dayjs(eDate).isValid()) {
      return
    }

    searchParams.set('start', sDate.format('MM-DD-YYYY'))
    searchParams.set('end', eDate.format('MM-DD-YYYY'))

    setSearchParams(searchParams)
  }

  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams())
  }

  return (
    <Stack
      direction="row"
      flexWrap={'wrap'}
      rowGap={1}
      columnGap={1}
      alignItems={'center'}
    >
      <Typography
        variant="body1"
        fontSize={14}
        fontWeight={600}
        paddingLeft={'0.5rem'}
      >
        Filter
      </Typography>
      <JobKindDropdown kindsList={filterData?.kindsList ?? []} />
      <JobStatusDropdown statusesList={filterData?.statusesList ?? []} />
      <DateRangePicker
        key={startDate.toString() + endDate.toString()}
        initialDateRange={{
          startDate: dayjs(startDate),
          endDate: dayjs(endDate),
        }}
        onChange={(range) => {
          handleDateSearchParams(range.startDate, range.endDate)
        }}
      />

      <Button
        disableElevation
        startIcon={<Delete />}
        variant="contained"
        color="inherit"
        sx={(theme) => ({
          textTransform: 'capitalize',
          color: theme.palette.mode === 'dark' ? '#FFF' : '#1AA8EA',
          backgroundColor:
            theme.palette.mode === 'dark' ? '#1A1F28' : '#EFF3F6',
          height: 36,
          border: '1px solid',
          borderRadius: '4px',
          borderColor: theme.palette.mode === 'dark' ? '#29303C' : '#1AA8EA',

          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark' ? '#0076AC' : '#1AA8EA',
            color: '#FFF',
          },
        })}
        onClick={handleClearFilters}
      >
        Clear
      </Button>
    </Stack>
  )
}

interface FiltersProps {
  startDate: Dayjs
  endDate: Dayjs
  timeRange: TimeRangeInSeconds
}

export default Filters
