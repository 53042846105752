import { useMemo } from 'react'
import { Box, Skeleton } from '@mui/material'
import { useListRansomwareQuery } from 'ui-v2/src/hooks/queries/ransomware'
import NewsMarquee from './news-marquee'
import NewsFeedItem from './news-feed-item'
import { NEWS_FEED_FALLBACK } from 'ui-v2/src/lib/constants/news-feed.constant'

function NewsFeed() {
  const { data, status } = useListRansomwareQuery()

  const newsFeedData = useMemo(() => {
    if (!data || !data.ransomware) {
      return []
    }

    const date = new Date(data.date)
    const formattedDate = `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`

    return data.ransomware.map((item, index) => ({
      id: index + 1,
      name: item.title,
      updatedAt: formattedDate,
      link: item.elastio_url,
    }))
  }, [data])

  if (status === 'pending') {
    return (
      <NewsMarquee>
        {Array.from({ length: 6 }, (_, index) => (
          <Box key={index} sx={{ minWidth: 150 }}>
            <Skeleton variant="text" width="80%" height={28} sx={{ mb: 0.5 }} />
            <Skeleton variant="text" width="60%" height={20} />
          </Box>
        ))}
      </NewsMarquee>
    )
  }

  if (status === 'error') {
    return (
      <NewsMarquee>
        {NEWS_FEED_FALLBACK.map((newsFeedItem) => (
          <NewsFeedItem key={newsFeedItem.id} newsFeedItem={newsFeedItem} />
        ))}
      </NewsMarquee>
    )
  }

  return (
    <NewsMarquee>
      {newsFeedData.map((newsFeedItem) => (
        <NewsFeedItem key={newsFeedItem.id} newsFeedItem={newsFeedItem} />
      ))}
    </NewsMarquee>
  )
}

export default NewsFeed
