import { ColorSystemOptions } from '@mui/material'

export const darkColorSystem: ColorSystemOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#1AA8EA',
      contrastText: '#fff',
    },
    accent: {
      main: '#FF1654',
    },
    background: {
      default: '#141218',
      // default: '#000000',
      secondary: '#152635', // Used for navbar and sidebar
      tertiary: '#181B1F', //Used for table row body
      paper: '#1A1F28',
      drawer: '#1A1A1A',
      dialog: '#2A2C38',
      hover: '#2F3745',
      elevation1: '#051019',
      elevation2: '#071621',
      elevation3: '#091C2A',
      highlight: '#0C273B',
    },
    text: {
      primary: '#D3D8DE',
      secondary: '#B2B5C1',
      muted: '#9ca0ad',
    },
    icon: {
      main: '#E3E8ED',
      dark: '#999CAA',
    },
    border: {
      main: '#e4dbe940',
      dark: '#29303C',
    },
    grey: {
      700: '#ccccdc33',
      800: '#f9f9fb',
    },
    link: {
      main: '#32B6F3',
    },
  },
}
