import React, { useMemo } from 'react'
import { Stack } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useAssetsOvaServerFilters } from 'ui-v2/src/hooks/filters/assets'
import { useAssetsOvaServerQuery } from 'ui-v2/src/hooks/queries/assets'
import { useListOvaAccountsQuery } from 'ui-v2/src/hooks/queries/ova-accounts'
import {
  ASSET_FILTERS_MAP,
  INVENTORY_VIEW_PARAM,
  INVENTORY_VIEW_PARAM_VALUES,
} from 'ui-v2/src/lib/constants/assets.constant'
import { AssetsOvaServerInventoryTableData } from 'ui-v2/src/lib/use-cases/assets/ova-server/ova-server-inventory-table'
import AssetFilters from 'ui-v2/src/views/assets/filters'
import { getInventoryServerColumns } from './column-config'

const ServerInventory = () => {
  const [searchParams] = useSearchParams()
  const view = searchParams.get(INVENTORY_VIEW_PARAM)

  const { filters, setPageSize, setPageToken } = useAssetsOvaServerFilters()

  const { data: assetsOvaServerData, isLoading: isAssetsOvaServerDataLoading } =
    useAssetsOvaServerQuery({
      ...filters,
      ...(view === INVENTORY_VIEW_PARAM_VALUES.CLEAN
        ? { statusList: [0] }
        : {}),
    })

  const { data: ovaAccountsData, isLoading: isOvaAccountsLoading } =
    useListOvaAccountsQuery()

  const data = useMemo(() => {
    if (!assetsOvaServerData || !ovaAccountsData) {
      return undefined
    }

    return new AssetsOvaServerInventoryTableData({
      servers: assetsOvaServerData.servers,
      ovaAccounts: ovaAccountsData,
    }).getOvaServerTableData()
  }, [assetsOvaServerData, ovaAccountsData])

  const columns = useMemo(getInventoryServerColumns, [])

  const isLoading = isAssetsOvaServerDataLoading || isOvaAccountsLoading

  return (
    <Stack spacing={1}>
      <AssetFilters
        filters={[
          ASSET_FILTERS_MAP.OVA_ACCOUNT_ID,
          ASSET_FILTERS_MAP.BACKUP_VENDOR,
        ]}
      />
      <CursorTable
        key={searchParams.toString()}
        data={data}
        columns={columns}
        isLoading={isLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsOvaServerData?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default ServerInventory
