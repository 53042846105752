import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'

import { GeneratedReportsFilters } from 'ui-v2/src/lib/models/assets/common'
import { ListGeneratedReportsRequestParams } from 'ui-v2/src/lib/models/reports'
import dayjs from 'dayjs'
import { reportOptions } from 'ui-v2/src/lib/constants/reports.constant'
import { OptionalReportKind } from 'ui-v2/src/lib/models/reports/reports'

export const useGeneratedReportListFilters =
  (): GeneratedReportsFilters<ListGeneratedReportsRequestParams> => {
    const [pageSize, setPageSize] = useState<number>(
      TABLE_ROWS_PER_PAGE_OPTIONS[0]
    )
    const [pageToken, setPageToken] = useState<string | undefined>(undefined)
    const [searchParams] = useSearchParams()

    const search = searchParams.get('search')
    const reportType = searchParams.get('type')
    const startDate = searchParams.get('start')
      ? dayjs(searchParams.get('start'))
      : dayjs().startOf('month')
    const endDate = searchParams.get('end')
      ? dayjs(searchParams.get('end'))
      : dayjs()

    const reportKind = reportOptions.find(
      (reportOption) => reportOption.slug === reportType
    )

    useEffect(() => {
      setPageToken(undefined)
    }, [searchParams])

    return useMemo(() => {
      return {
        startDate,
        endDate,
        filters: {
          pageSize,
          pageToken,
          textSearch: search ?? '',
          reportKind: reportKind?.value as OptionalReportKind,
          rangeStartAt: Math.floor(startDate.valueOf() / 1000),
          rangeEndAt: Math.floor(endDate.valueOf() / 1000),
        },
        setPageSize,
        setPageToken,
      }
    }, [
      startDate,
      endDate,
      reportKind,
      pageSize,
      pageToken,
      searchParams,
      setPageSize,
      setPageToken,
    ])
  }
