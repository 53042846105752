/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from './grpc'
import { TimeRange } from '../models/types'
import {
  CloudConnectorJobRequestFilters,
  ListCloudConnnectorJobsFilterRequestParams,
} from 'ui-v2/src/lib/models/monitor'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { ClientConfig } from 'ui-v2/src/lib/models/client'
import {
  CloudConnectorJobStatus,
  JobCloudConnectorKind,
} from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import EnumHelper from 'ui-v2/src/lib/helpers/enum.helper'
import {
  CloudConnectorJobKindTransformer,
  CloudConnectorJobStatusTransformer,
  CloudConnectorJobTransformer,
} from '../transformers/cloud-connector-job'
import {
  GetCloudConnectorJobsFiltersRequest,
  GetCloudConnectorJobsFiltersResponse,
  GetJobRequest,
  GrpcListTenantJobsRequest,
  GrpcListTenantJobsResponse,
  JobCloudConnector,
  JobProto,
  JobsPromiseClient,
  ListCloudConnectorJobsRequest,
  Pagination,
} from '../grpc'
import { JobTenant } from 'blues-corejs/dist'
import {
  TenantJobKindTransformer,
  TenantJobStatusTransformer,
  TenantJobTransformer,
} from '../transformers/tenant-jobs'
import {
  TenantJobKind,
  TenantJobStatus,
} from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { Job as JobPt } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/tenant/job_pb'
import {
  Request as GetTenantJobFilters,
  Response as GetTenantJobFiltersResponse,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/get_tenant_job_filters_pb'

export interface ListTenantJobs {
  jobsList: Array<JobTenant>
}

export interface JobPagination {
  pageToken?: string
}

export interface ListTenantJobsFilterRequestParams {
  kindsList?: Array<TenantJobKind>
  statusesList?: Array<TenantJobStatus>
  jobIdsList?: Array<string>
  ccIdsList?: Array<string>
  timeRange?: TimeRange
  pageSize?: number
  pageToken?: string
}

export interface PossibleTenantJobFilters {
  kindsList: Array<TenantJobKind>
  statusesList: Array<TenantJobStatus>
}

export class JobsClient extends GrpcClient<JobsPromiseClient> {
  #jobsClient: JobsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#jobsClient = this.getClient(hostName)
    this.#token = token
  }

  protected initClient(hostName: string): JobsPromiseClient {
    return new JobsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'JobsClient'
  }

  async getCloudConnectorJob(id: string): Promise<JobCloudConnector> {
    const response = (
      await this.callGrpcService(
        () =>
          this.#jobsClient.getCloudConnectorJob(
            new GetJobRequest().setJobId(id),
            this.metadata(this.#token)
          ),
        {
          requestName: 'JobsPromiseClient/getCloudConnectorJob',
        }
      )
    ).toObject()

    if (!response.job) {
      throw new Error('Cloud connector job not found')
    }

    return new CloudConnectorJobTransformer(response.job).transform()
  }

  async listCloudConnectorJobs(
    filterParams: ListCloudConnnectorJobsFilterRequestParams
  ) {
    const request = new ListCloudConnectorJobsRequest()
    const filter = new ListCloudConnectorJobsRequest.Initial.Filter()
    const initial = new ListCloudConnectorJobsRequest.Initial()

    const {
      kindsList,
      timeRange,
      statusesList,
      assetIdsList,
      pageSize,
      pageToken,
    } = filterParams

    if (timeRange) {
      const timeRangeProto = this.#setupTimeRange({
        start: timeRange.start as number,
        end: timeRange.end as number,
      })

      filter.setTimeRange(timeRangeProto)
    }

    if (statusesList) {
      const statusesProtoList: Array<JobProto.Status> = []

      for (const status of statusesList) {
        statusesProtoList.push(this.#setJobStatus(status))
      }

      filter.setStatusesList(statusesProtoList)
    }

    if (kindsList) {
      const kindsProtoList: Array<JobProto.Kind> = []

      for (const kind of kindsList) {
        kindsProtoList.push(this.#setJobKind(kind))
      }

      filter.setKindsList(kindsProtoList)
    }

    if (assetIdsList) {
      filter.setAssetIdsList(assetIdsList)
    }

    initial.setPageSize(pageSize ?? 15)
    initial.setFilter(filter)
    request.setInitial(initial)
    if (pageToken) {
      request.setPagination(new Pagination().setPageToken(pageToken))
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#jobsClient.listCloudConnectorJobs(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'JobsPromiseClient/listCloudConnectorJobs',
        }
      )
    ).toObject()
    return {
      jobsList: response.jobsList.map((job) =>
        new CloudConnectorJobTransformer(job).transform()
      ),
      pagination: response.pagination,
    }
  }

  async getCloudConnectorJobsFilters(
    timeRange: TimeRange
  ): Promise<CloudConnectorJobRequestFilters> {
    const timeRangeProto = new GetCloudConnectorJobsFiltersRequest.TimeRange()

    const startTimeStamp = new Timestamp()
    const endTimeStamp = new Timestamp()

    if (timeRange.start) {
      timeRangeProto.setStart(startTimeStamp.setSeconds(timeRange.start))
    }

    if (timeRange.end) {
      timeRangeProto.setEnd(endTimeStamp.setSeconds(timeRange.end))
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#jobsClient.getCloudConnectorJobsFilters(
            new GetCloudConnectorJobsFiltersRequest().setTimeRange(
              timeRangeProto
            ),
            this.metadata(this.#token)
          ),
        {
          requestName: 'JobsPromiseClient/getCloudConnectorJobsFilters',
        }
      )
    ).toObject()

    return this.#transformCloudConnectorJobFilters(response)
  }

  #transformCloudConnectorJobFilters(
    response: GetCloudConnectorJobsFiltersResponse.AsObject
  ): CloudConnectorJobRequestFilters {
    return {
      ...response,
      kindsList: response.kindsList.map((kind) =>
        new CloudConnectorJobKindTransformer(kind).transform()
      ),
      statusesList: response.statusesList.map((status) =>
        new CloudConnectorJobStatusTransformer(status).transform()
      ),
    }
  }

  #setupTimeRange({ start, end }: { start: number; end: number }) {
    const timeRange = new GrpcListTenantJobsRequest.Initial.Filter.TimeRange()
    if (start) {
      timeRange.setStart(this.#createTimestamp(start))
    }

    if (end) {
      timeRange.setEnd(this.#createTimestamp(end))
    }
    return timeRange
  }

  #createTimestamp(seconds: number) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(seconds)
    return timestamp
  }

  #setJobStatus(status: CloudConnectorJobStatus): JobProto.Status {
    const ProtobufJobStatus = JobProto.Status

    const matchingStatus = EnumHelper.getNumericEnumValues(
      ProtobufJobStatus
    ).find((protobufStatus) => protobufStatus === status)

    if (matchingStatus === undefined) {
      throw new Error(`ListTenantJobsFilter: Invalid job status: ${status}`)
    }

    return matchingStatus
  }

  #setJobKind(kind: JobCloudConnectorKind) {
    const ProtobufJobKind = JobProto.Kind

    const matchingKind = EnumHelper.getNumericEnumValues(ProtobufJobKind).find(
      (protobufKind) => protobufKind === kind
    )

    if (matchingKind === undefined) {
      throw new Error(`ListTenantJobsFilter: Invalid job kind: ${kind}`)
    }

    return matchingKind
  }

  async listTenantJobs(
    filterParams: ListTenantJobsFilterRequestParams
  ): Promise<ListTenantJobs & JobPagination> {
    const request = new GrpcListTenantJobsRequest()
    const filter = new GrpcListTenantJobsRequest.Initial.Filter()
    const initial = new GrpcListTenantJobsRequest.Initial()

    const {
      kindsList,
      timeRange,
      statusesList,
      jobIdsList,
      pageSize,
      pageToken,
    } = filterParams

    if (timeRange) {
      const timeRangeProto = this.#setupTimeRange({
        start: timeRange.start as number,
        end: timeRange.end as number,
      })

      filter.setTimeRange(timeRangeProto)
    }

    if (statusesList) {
      const statusesProtoList: Array<JobPt.Status> = []

      for (const status of statusesList) {
        statusesProtoList.push(this.#setTenantJobStatus(status))
      }

      filter.setStatusesList(statusesProtoList)
    }

    if (kindsList) {
      const kindsProtoList: Array<JobPt.Kind> = []

      for (const kind of kindsList) {
        kindsProtoList.push(this.#setTenantJobKind(kind))
      }

      filter.setKindsList(kindsProtoList)
    }

    if (jobIdsList) {
      filter.setJobIdsList(jobIdsList)
    }

    initial.setPageSize(pageSize ?? 15)
    initial.setFilter(filter)
    request.setInitial(initial)
    if (pageToken) {
      request.setPagination(new Pagination().setPageToken(pageToken))
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#jobsClient.listTenantJobs(request, this.metadata(this.#token)),
        {
          requestName: 'JobsPromiseClient/listTenantJobs',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  #transformResponse(
    response: GrpcListTenantJobsResponse.AsObject
  ): ListTenantJobs & JobPagination {
    return {
      jobsList: response.jobsList.map((job) =>
        new TenantJobTransformer(job).transform()
      ),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  #setTenantJobStatus(status: TenantJobStatus): JobPt.Status {
    const ProtobufJobStatus = JobPt.Status

    const matchingStatus = EnumHelper.getNumericEnumValues(
      ProtobufJobStatus
    ).find((protobufStatus) => protobufStatus === status)

    if (matchingStatus === undefined) {
      throw new Error(`ListTenantJobsFilter: Invalid job status: ${status}`)
    }

    return matchingStatus
  }

  #setTenantJobKind(kind: TenantJobKind) {
    const ProtobufJobKind = JobPt.Kind

    const matchingKind = EnumHelper.getNumericEnumValues(ProtobufJobKind).find(
      (protobufKind) => protobufKind === kind
    )

    if (matchingKind === undefined) {
      throw new Error(`ListTenantJobsFilter: Invalid job kind: ${kind}`)
    }

    return matchingKind
  }

  async getTenantJobFilters(
    timeRange: TimeRange
  ): Promise<PossibleTenantJobFilters> {
    const timeRangeProto = new GetTenantJobFilters.TimeRange()

    const startTimeStamp = new Timestamp()
    const endTimeStamp = new Timestamp()

    if (timeRange.start) {
      timeRangeProto.setStart(startTimeStamp.setSeconds(timeRange.start))
    }

    if (timeRange.end) {
      timeRangeProto.setEnd(endTimeStamp.setSeconds(timeRange.end))
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#jobsClient.getTenantJobsFilters(
            new GetTenantJobFilters().setTimeRange(timeRangeProto),
            this.metadata(this.#token)
          ),
        {
          requestName: 'JobsPromiseClient/getTenantJobsFilters',
        }
      )
    ).toObject()

    return this.#transformTenantJobFilters(response)
  }

  #transformTenantJobFilters(
    response: GetTenantJobFiltersResponse.AsObject
  ): PossibleTenantJobFilters {
    return {
      kindsList: response.kindsList.map((kind) =>
        new TenantJobKindTransformer(kind).transform()
      ),
      statusesList: response.statusesList.map((status) =>
        new TenantJobStatusTransformer(status).transform()
      ),
    }
  }
}
