import { useMemo } from 'react'
import { useAwsScanCoverageQuery } from '../queries/aws-stats'
import { NormalizedCoverageCardData } from 'ui-v2/src/lib/models/aws-stats'
import { COVERAGE_CARD_SLUG } from 'ui-v2/src/lib/constants/stats.constant'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

const useAwsFileScanCoverageData = () => {
  const { data: awsScanCoverageData, isLoading } = useAwsScanCoverageQuery({})

  const normalizedData: Array<NormalizedCoverageCardData> = useMemo(() => {
    if (!awsScanCoverageData) {
      return []
    }

    const services = ['ebs', 'ec2', 'efs', 's3'] as const

    const coveredCount = services.reduce(
      (sum, service) => sum + (awsScanCoverageData[service]?.covered ?? 0),
      0
    )

    const totalCount = services.reduce(
      (sum, service) => sum + (awsScanCoverageData[service]?.total ?? 0),
      0
    )

    return [
      {
        slug: COVERAGE_CARD_SLUG.SCAN_COVERAGE,
        count: coveredCount,
        totalCount,
        link: `/${BASE_ROUTE_SEGMENTS.ASSETS}/ec2`,
      },
    ]
  }, [awsScanCoverageData])

  return {
    data: normalizedData,
    isLoading,
  }
}

export default useAwsFileScanCoverageData
