/* eslint-disable import/no-extraneous-dependencies */
import { Typography } from '@mui/material'
import { Asset, GenericHost, OvaServer } from 'blues-corejs/dist'
import React from 'react'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'
import { isAwsAsset as checkIfAwsAsset } from 'ui-v2/src/lib/helpers/assets.helper'

type AssetIdentifierProps = {
  asset: Asset | undefined
}

const AssetIdentifier: React.FC<AssetIdentifierProps> = ({ asset }) => {
  if (!asset) {
    return null
  }

  const isAwsAsset = checkIfAwsAsset(asset)

  let assetIdentifier = ''

  if (isAwsAsset) {
    if (asset.name) {
      assetIdentifier += `${asset.name}/\n`
    }
    assetIdentifier += asset.awsId
  } else if (asset instanceof OvaServer) {
    assetIdentifier += asset.name
  } else if (asset instanceof GenericHost) {
    assetIdentifier += asset.hostname
  }

  let maxLength = 20
  if (isAwsAsset) {
    maxLength += asset.name.length + 2 // 2 for new line
  }

  return assetIdentifier.length <= maxLength ? (
    <Typography
      variant="body2"
      fontWeight={600}
      whiteSpace={'pre-line'}
      lineHeight={1.2}
    >
      {assetIdentifier}
    </Typography>
  ) : (
    <LongTextTooltip
      text={assetIdentifier}
      placement={'bottom-start'}
      fontSize="14px"
      fontWeight={600}
      maxLength={maxLength}
    />
  )
}

export default AssetIdentifier
