import { useState } from 'react'
import { Box, Button, Card, Stack, Typography } from '@mui/material'
import ReturnOfInvestmentDrawer from 'ui-v2/src/views/dashboard/dashboard/estimated-value-card/estimated-value-drawer'
import { createDollarFormatter, getStoredRoiData, RoiData } from './util'

function EstimatedValueCard() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [roiData, setRoiData] = useState<RoiData>(getStoredRoiData())

  const dollarFormatter = createDollarFormatter()

  return (
    <>
      <Card
        elevation={0}
        sx={(theme) => ({
          paddingX: 2.5,
          paddingY: 2,
          borderRadius: 2.5,
          background: theme.palette.background.secondary,
          height: '100%',
        })}
      >
        <Typography
          fontSize={16}
          fontWeight={700}
          mb={2}
          color="text.secondary"
        >
          Estimated Value
        </Typography>
        <Stack spacing={2}>
          <Typography
            variant="body2"
            color="text.secondary"
            textAlign={'center'}
          >
            How much Elastio Platform can save
            <br /> your business in the event of a ransomware attack.
          </Typography>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={2}
          >
            <Typography fontSize={32} fontWeight={700} color={'#32B6F3'}>
              {dollarFormatter.format(parseInt(roiData.calculatedRoi))}
            </Typography>
            <Button
              sx={{
                fontSize: '12px',
                padding: '2px 1rem',
              }}
              variant="outlined"
              size="small"
              onClick={() => {
                setIsDrawerOpen(true)
              }}
            >
              Calculate
            </Button>
          </Box>
        </Stack>
      </Card>

      <ReturnOfInvestmentDrawer
        isOpen={isDrawerOpen}
        closeDrawer={() => {
          setIsDrawerOpen(false)
        }}
        roiData={roiData}
        setRoiData={setRoiData}
      />
    </>
  )
}

export default EstimatedValueCard
