import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import { DRAWER_WIDTH } from 'ui-v2/src/lib/constants/ui.constant'
import { useLocation } from 'react-router-dom'
import AccountMenu from './account-menu'
import BreadcrumbsContainer from 'ui-v2/src/components/common/breadcrumbs/breadcrumbs-container'

const Navbar = ({ toggleMobileDrawer }: { toggleMobileDrawer: () => void }) => {
  const { pathname } = useLocation()

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: { sm: `calc(100% - ${DRAWER_WIDTH})` },
        ml: { sm: DRAWER_WIDTH },
      }}
    >
      <Toolbar
        sx={(theme) => ({
          background: theme.palette.background.secondary,
        })}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box display={'flex'} alignItems={'center'} columnGap={1.5}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleMobileDrawer}
              sx={{
                mr: {
                  sm: 0,
                  md: 1,
                },
                pl: 2,
                display: { sm: 'none' },
              }}
            >
              <MenuOutlinedIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              sx={(theme) => ({ color: theme.palette.text.primary })}
            >
              elastio
            </Typography>
            <Divider
              orientation="vertical"
              sx={{
                height: 28,
                alignSelf: 'center',
              }}
            />
            <Typography
              fontSize={16}
              fontWeight={700}
              noWrap
              sx={(theme) => ({
                color: theme.palette.text.primary,
                textTransform: 'capitalize',
              })}
            >
              {pathname.split('/')[1]}
            </Typography>
            <BreadcrumbsContainer />
          </Box>
          <AccountMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
