import { useRestClient } from 'ui-v2/src/hooks/rest-client'

type DataScannedResponse = {
  bytesScanned: 0
}

export const useDataScannedService = () => {
  const { isReady, isLoading, get } = useRestClient()

  const getDataScanned = async () => {
    if (!isReady) {
      throw new Error('REST client is not initialized')
    }

    const url = '/api/v1/data-scanned-30d'
    return get<DataScannedResponse>(url)
  }

  return {
    getDataScanned,
    isReady,
    isLoading,
  }
}
