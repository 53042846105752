const DNSIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clipPath="url(#clip0_2636_7498)">
      <path
        d="M19 13.5H5C3.9 13.5 3 14.4 3 15.5V19.5C3 20.6 3.9 21.5 5 21.5H19C20.1 21.5 21 20.6 21 19.5V15.5C21 14.4 20.1 13.5 19 13.5ZM7 19.5C5.9 19.5 5 18.6 5 17.5C5 16.4 5.9 15.5 7 15.5C8.1 15.5 9 16.4 9 17.5C9 18.6 8.1 19.5 7 19.5ZM19 3.5H5C3.9 3.5 3 4.4 3 5.5V9.5C3 10.6 3.9 11.5 5 11.5H19C20.1 11.5 21 10.6 21 9.5V5.5C21 4.4 20.1 3.5 19 3.5ZM7 9.5C5.9 9.5 5 8.6 5 7.5C5 6.4 5.9 5.5 7 5.5C8.1 5.5 9 6.4 9 7.5C9 8.6 8.1 9.5 7 9.5Z"
        fill="#B2B5C1"
      />
    </g>
    <defs>
      <clipPath id="clip0_2636_7498">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default DNSIcon
