import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { RadioGroup, FormControlLabel, Radio } from '@mui/material'

interface LabelOption {
  value: string | number
  label: string
}

interface RadioGroupControllerOwnProps {
  name: string
  defaultValue?: string | number
  labelOptions: Array<LabelOption>
  onChange?: (value: string) => void
}

function RadioGroupController({
  name,
  defaultValue,
  labelOptions = [],
  onChange,
}: RadioGroupControllerOwnProps) {
  const { control, setValue } = useFormContext()

  const handleChange = (value: string) => {
    setValue(name, value)
    if (!onChange) {
      return
    }

    onChange(value)
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioGroup row {...field} onChange={(_, value) => handleChange(value)}>
          {labelOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      )}
    />
  )
}

export default RadioGroupController
