/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import {
  ListGenericHostsInventory,
  Disk,
  File,
  LocalVolume,
  Stream,
} from 'blues-corejs/dist'
import { useSearchParams } from 'react-router-dom'
import {
  useAssetItemsQuery,
  useAssetsLiveQuery,
} from 'ui-v2/src/hooks/queries/assets'
import {
  INVENTORY_VIEW_PARAM,
  INVENTORY_VIEW_PARAM_VALUES,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { getAssetItemsMapWithHostId } from './util'
import { getInventoryHostColumns } from './column-config'
import { Stack } from '@mui/material'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'

const HostInventory = () => {
  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)
  const view = searchParams.get(INVENTORY_VIEW_PARAM)

  const { data: assetsLiveData, isLoading: isAssetsLiveLoading } =
    useAssetsLiveQuery()

  const { data: assetItemsData, isLoading: isAssetItemsLoading } =
    useAssetItemsQuery(
      {
        assetIdList: assetsLiveData?.genericHosts.map((host) => host.id) ?? [],
      },
      { enabled: !!assetsLiveData && assetsLiveData.genericHosts.length > 0 }
    )

  const assetItemsListWithHostIds = useMemo(() => {
    if (!assetsLiveData || !assetItemsData) {
      return new Map<string, Array<File | Stream | LocalVolume | Disk>>()
    }
    return getAssetItemsMapWithHostId({
      assetItems: assetItemsData,
      genericHostsData: assetsLiveData.genericHosts,
    })
  }, [assetsLiveData, assetItemsData])

  const genericHosts = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }

    const scans = assetsLiveData.scans.flatMap(
      (scan) =>
        scan.ransomwareScan ??
        scan.malwareScan ??
        scan.filesystemCheckScan ??
        []
    )

    const { genericHosts: inventoryGenericHosts } =
      new ListGenericHostsInventory({
        genericHosts: assetsLiveData.genericHosts,
        threats: assetsLiveData.threats,
        lastBackups: new Map(),
        assetItemsListWithHostIds,
        scans,
      }).execute({ idSearch: (search ?? '').toLowerCase() })

    if (view === INVENTORY_VIEW_PARAM_VALUES.CLEAN) {
      return inventoryGenericHosts.filter(
        (genericHost) => !genericHost.isInfected
      )
    }
    return inventoryGenericHosts
  }, [assetsLiveData, search, assetItemsData, assetItemsListWithHostIds])

  const columns = useMemo(getInventoryHostColumns, [])

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems={'center'}>
        <SearchBar />
      </Stack>

      <ClientTable
        data={genericHosts}
        columns={columns}
        isLoading={isAssetsLiveLoading || isAssetItemsLoading}
      />
    </Stack>
  )
}

export default HostInventory
