import { Link } from 'react-router-dom'

interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

const ElastioLogo = ({ size = 48, ...props }: IconProps) => {
  return (
    <Link to="/dashboard">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 48 48"
        fill="none"
        {...props}
      >
        <path
          d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
          fill="#FF1654"
        />
        <path
          d="M23.8603 35.9999C24.1959 36.0026 24.5262 35.9173 24.8181 35.7528L32.8332 31.1978C33.1263 31.0307 33.3702 30.7901 33.5403 30.5C33.7103 30.2098 33.8007 29.8804 33.8024 29.5446V24.3652H31.6605V29.4194L23.8603 33.846L15.2783 28.9219V19.0767L23.8603 14.1526L31.3584 18.4556L23.4662 22.9015L24.5209 24.7472L32.7427 20.1152C33.035 19.9492 33.2782 19.7097 33.4479 19.4207C33.6176 19.1318 33.7077 18.8036 33.7094 18.4691C33.711 18.1345 33.624 17.8055 33.4572 17.5149C33.2904 17.2243 33.0495 16.9825 32.7589 16.8137L24.8181 12.2539C24.5273 12.0875 24.1975 12 23.8619 12C23.5263 12 23.1965 12.0875 22.9056 12.2539L14.1023 17.2952C13.811 17.4627 13.5691 17.7032 13.4007 17.9928C13.2323 18.2823 13.1434 18.6107 13.1429 18.9451V29.0406C13.1434 29.375 13.2323 29.7034 13.4007 29.993C13.5691 30.2825 13.811 30.5231 14.1023 30.6906L22.9056 35.7528C23.1966 35.9167 23.5258 36.0019 23.8603 35.9999Z"
          fill="white"
        />
        <path
          d="M23.9911 25.9478C22.8118 25.9478 21.8557 24.9978 21.8557 23.826C21.8557 22.6541 22.8118 21.7041 23.9911 21.7041C25.1705 21.7041 26.1265 22.6541 26.1265 23.826C26.1265 24.9978 25.1705 25.9478 23.9911 25.9478Z"
          fill="white"
        />
        <path
          d="M30.4975 24.4599C30.4975 25.6316 31.5426 26.4935 32.7217 26.4935C33.2881 26.4935 33.8312 26.2699 34.2317 25.872C34.6322 25.4741 34.8571 24.9344 34.8571 24.3717C34.8571 23.8089 34.6322 23.2692 34.2317 22.8713C33.8312 22.4734 33.2881 22.2498 32.7217 22.2498C32.1371 22.2664 31.581 22.5046 31.1674 22.9155C30.7539 23.3264 30.5142 23.879 30.4975 24.4599Z"
          fill="white"
        />
      </svg>
    </Link>
  )
}

export default ElastioLogo
