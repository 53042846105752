/* eslint-disable import/no-extraneous-dependencies */
import React, { ReactNode } from 'react'
import { useInfoModalStore } from '@features/alerts/store/alerts-info-modal-store'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  computeAwsAccounts,
  computeChannels,
  computeEventKinds,
  computeMode,
  computeTags,
  computeOvaAccounts,
  ComputeRuleStatus,
} from '@features/alerts/modals/rule-details/compute'
import { useRuleDrawerDataState } from '@features/alerts/create-rule-drawer'
import { RuleSuppressUntil } from 'blues-corejs/dist/models/notifications/rule'

interface KeyValueDisplayProps {
  title: string
  value: string | ReactNode
}

export const KeyValueDisplay = ({ title, value }: KeyValueDisplayProps) => {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      {title && (
        <Typography variant="body1" fontWeight="bold" mr={1}>
          {title}:
        </Typography>
      )}
      <Typography variant="body1" fontStyle="italic">
        {value}
      </Typography>
    </Box>
  )
}

function RuleDetails() {
  const { rule } = useInfoModalStore()
  const { listUsers, webhooksList } = useRuleDrawerDataState()

  if (!rule) {
    return null
  }

  const { name, description, mode, channels, criteria, status } = rule
  const statusValue = ComputeRuleStatus(status)
  const modeValue = computeMode(mode)
  const suppressReason =
    mode instanceof RuleSuppressUntil ? (
      <KeyValueDisplay title={'Suppress Reason'} value={mode.reason} />
    ) : null
  const channelsValue = computeChannels(channels, listUsers, webhooksList)
  const ovaAccountsValue = computeOvaAccounts(criteria.ovaAccountProviders)
  const awsAccountsValue = computeAwsAccounts(criteria.awsAccountRegions)
  const eventKindsValue = computeEventKinds(criteria.eventKinds)
  const tagsValue = computeTags(criteria.tags.tags, criteria.tags.operator)

  return (
    <Box>
      <KeyValueDisplay title="" value={statusValue} />
      <KeyValueDisplay title="Name" value={name} />
      <KeyValueDisplay title="Description" value={description} />
      {awsAccountsValue}
      {ovaAccountsValue}
      {tagsValue}
      <KeyValueDisplay title="Mode" value={modeValue} />
      {suppressReason}
      {eventKindsValue}
      <KeyValueDisplay title="Delivery Channel" value={channelsValue} />
    </Box>
  )
}

export default RuleDetails
