import React from 'react'

interface ShieldIconProps {
  isHealthy: boolean
}

const ShieldIcon: React.FC<ShieldIconProps> = ({ isHealthy }) => {
  if (isHealthy) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="88"
        viewBox="0 0 72 88"
        fill="none"
      >
        <path
          d="M2 40V17.2997L36 2.18864L70 17.2997V40C70 61.189 55.4296 80.9342 36 85.9381C16.5704 80.9342 2 61.189 2 40Z"
          stroke="#45AA5B"
          stroke-width="4"
        />
        <path
          d="M35.7744 63.3844C36.3165 63.3886 36.8502 63.2509 37.3217 62.9851L50.2691 55.627C50.7426 55.3572 51.1365 54.9684 51.4113 54.4998C51.686 54.0311 51.8321 53.4989 51.8347 52.9565V44.5899H48.3748V52.7543L35.7744 59.905L21.9112 51.9505V36.0469L35.7744 28.0925L47.8868 35.0435L35.1377 42.2253L36.8416 45.2069L50.123 37.7244C50.5951 37.4562 50.988 37.0693 51.2621 36.6026C51.5362 36.1358 51.6818 35.6056 51.6845 35.0653C51.6871 34.5249 51.5466 33.9933 51.2771 33.5239C51.0076 33.0546 50.6186 32.6639 50.1491 32.3912L37.3217 25.0253C36.8518 24.7566 36.3192 24.6152 35.777 24.6152C35.2349 24.6152 34.7022 24.7566 34.2323 25.0253L20.0116 33.169C19.541 33.4395 19.1502 33.8281 18.8782 34.2959C18.6062 34.7636 18.4626 35.2941 18.4617 35.8343V52.1424C18.4626 52.6826 18.6062 53.2131 18.8782 53.6808C19.1502 54.1486 19.541 54.5371 20.0116 54.8077L34.2323 62.9851C34.7024 63.2499 35.2341 63.3876 35.7744 63.3844Z"
          fill="#45AA5B"
        />
        <path
          d="M35.9858 47.1463C34.0807 47.1463 32.5363 45.6117 32.5363 43.7187C32.5363 41.8257 34.0807 40.2911 35.9858 40.2911C37.8909 40.2911 39.4353 41.8257 39.4353 43.7187C39.4353 45.6117 37.8909 47.1463 35.9858 47.1463Z"
          fill="#45AA5B"
        />
        <path
          d="M46.4961 44.7428C46.4961 46.6355 48.1843 48.0278 50.0891 48.0278C51.0039 48.0278 51.8813 47.6667 52.5282 47.0239C53.1751 46.3811 53.5386 45.5093 53.5386 44.6002C53.5386 43.6912 53.1751 42.8194 52.5282 42.1766C51.8813 41.5338 51.0039 41.1727 50.0891 41.1727C49.1447 41.1994 48.2463 41.5842 47.5783 42.248C46.9102 42.9118 46.523 43.8044 46.4961 44.7428Z"
          fill="#45AA5B"
        />
      </svg>
    )
  }
  return (
    <svg
      width="72"
      height="88"
      viewBox="0 0 72 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 40V17.2997L36 2.18864L70 17.2997V40C70 61.189 55.4296 80.9342 36 85.9381C16.5704 80.9342 2 61.189 2 40Z"
        stroke="#FFAB2D"
        stroke-width="4"
      />
      <path
        d="M35.7744 63.3844C36.3165 63.3886 36.8502 63.2509 37.3217 62.9851L50.2691 55.627C50.7426 55.3572 51.1365 54.9684 51.4113 54.4998C51.686 54.0311 51.8321 53.4989 51.8347 52.9565V44.5899H48.3748V52.7543L35.7744 59.905L21.9112 51.9505V36.0469L35.7744 28.0925L47.8868 35.0435L35.1377 42.2253L36.8416 45.2069L50.123 37.7244C50.5951 37.4562 50.988 37.0693 51.2621 36.6026C51.5362 36.1358 51.6818 35.6056 51.6845 35.0653C51.6871 34.5249 51.5466 33.9933 51.2771 33.5239C51.0076 33.0546 50.6186 32.6639 50.1491 32.3912L37.3217 25.0253C36.8518 24.7566 36.3192 24.6152 35.777 24.6152C35.2349 24.6152 34.7022 24.7566 34.2323 25.0253L20.0116 33.169C19.541 33.4395 19.1502 33.8281 18.8782 34.2959C18.6062 34.7636 18.4626 35.2941 18.4617 35.8343V52.1424C18.4626 52.6826 18.6062 53.2131 18.8782 53.6808C19.1502 54.1486 19.541 54.5371 20.0116 54.8077L34.2323 62.9851C34.7024 63.2499 35.2341 63.3876 35.7744 63.3844Z"
        fill="#FFAB2D"
      />
      <path
        d="M35.9858 47.1463C34.0807 47.1463 32.5363 45.6117 32.5363 43.7187C32.5363 41.8257 34.0807 40.2911 35.9858 40.2911C37.8909 40.2911 39.4353 41.8257 39.4353 43.7187C39.4353 45.6117 37.8909 47.1463 35.9858 47.1463Z"
        fill="#FFAB2D"
      />
      <path
        d="M46.4961 44.7428C46.4961 46.6355 48.1843 48.0278 50.0891 48.0278C51.0039 48.0278 51.8813 47.6667 52.5282 47.0239C53.1751 46.3811 53.5386 45.5093 53.5386 44.6002C53.5386 43.6912 53.1751 42.8194 52.5282 42.1766C51.8813 41.5338 51.0039 41.1727 50.0891 41.1727C49.1447 41.1994 48.2463 41.5842 47.5783 42.248C46.9102 42.9118 46.523 43.8044 46.4961 44.7428Z"
        fill="#FFAB2D"
      />
    </svg>
  )
}

export default ShieldIcon
