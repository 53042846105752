import { useQuery } from '@tanstack/react-query'
import { VaultClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export const useListVaultForActiveRedstacks = (awsAccountId?: string) => {
  const vaultClient = useGrpcClient(VaultClient)

  return useQuery({
    queryKey: ['vault-client', 'list-vaults'],
    queryFn: () => {
      if (!vaultClient) {
        throw new Error('Vault Client Client not initialized')
      }

      return vaultClient?.listVaults(awsAccountId)
    },
    enabled: !!vaultClient,
  })
}
