import React from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { useAlertsFilterStore } from './store/alerts-filter-store'
import { SearchField, SearchIconButton, SearchWrapper } from '../styles'

const AlertsSearch = () => {
  const { setRuleValue, ruleSearch } = useAlertsFilterStore()

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRuleValue(event.target.value)
  }

  return (
    <SearchWrapper>
      <SearchField
        placeholder="Search Rules"
        variant="outlined"
        size="small"
        value={ruleSearch}
        onChange={handleSearch}
      />
      <SearchIconButton>
        <SearchIcon
          sx={{
            fontSize: 18,
          }}
        />
      </SearchIconButton>
    </SearchWrapper>
  )
}

export default AlertsSearch
