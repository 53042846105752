import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import CloudCircleIcon from 'ui-v2/src/assets/icons/cloud-circle-icon'
import { formatDate } from 'ui-v2/src/lib/helpers/time.helper'
import { useParams } from 'react-router-dom'
import { useBackupByIdQuery } from 'ui-v2/src/hooks/queries/backups'
import { CheckCircle, ErrorOutline, FileCopy } from '@mui/icons-material'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'

type BackupSourceProps = {
  source: string | undefined
  backupKind: string | undefined
  timestamp: string | undefined
  backupId?: string
}

type BackupArnTooltipProps = {
  children: React.ReactElement
  backupId: string | undefined
}

const backupKindsWithArn = [
  'awsb_s3',
  'awsb_ec2',
  'awsb_ebs',
  'awsb_efs',
  'aws_ebs_snapshot',
  'aws_ami_backup',
]

const BackupArnTooltip: React.FC<BackupArnTooltipProps> = ({
  children,
  backupId,
}) => {
  const [isCopied, setIsCopied] = useState(false)

  const { recoveryPointId } = useParams<{
    recoveryPointId: string | undefined
  }>()

  const id = backupId ?? recoveryPointId

  const { data: backupData, status: backupDataQueryStatus } =
    useBackupByIdQuery(id ? [id] : [], {
      staleTime: Infinity,
    })

  const backupArn = useMemo(() => {
    const backup = backupData?.backupsList[0]

    if (!backup) {
      return ''
    }

    if (backup.awsbRp) {
      return backup.awsbRp.arn
    }

    if (backup.ebsSnapshot) {
      return backup.ebsSnapshot.arn
    }

    return ''
  }, [backupData])

  const timeoutRef = useRef<NodeJS.Timeout>()

  const copyButtonClickHandler = () => {
    copyToClipboard(backupArn)
    setIsCopied(true)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const getTooltipTitle = () => {
    if (backupDataQueryStatus === 'pending') {
      return (
        <Box display={'flex'} gap={1}>
          <Skeleton width={200} height={25} />
          <Skeleton width={20} height={25} />
        </Box>
      )
    }

    if (backupDataQueryStatus === 'error') {
      return (
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <ErrorOutline
            sx={{
              fontSize: 16,
              color: 'error.main',
            }}
          />
          <Typography variant="body2" color="error">
            Error fetching backup ARN
          </Typography>
        </Box>
      )
    }

    return (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <Typography variant="body2" fontWeight={600}>
          {backupArn}
        </Typography>
        <IconButton
          sx={{
            padding: 0.5,
            '&:hover': {
              background: isCopied ? 'transparent' : undefined,
            },
          }}
          onClick={copyButtonClickHandler}
        >
          {isCopied ? (
            <CheckCircle
              sx={{
                fontSize: 16,
                color: 'success.main',
              }}
            />
          ) : (
            <FileCopy
              sx={{
                fontSize: 16,
                color: '#B2B5C1',
              }}
            />
          )}
        </IconButton>
      </Box>
    )
  }

  return (
    <Tooltip
      placement="top"
      leaveDelay={200}
      slotProps={{
        tooltip: {
          sx: (theme) => ({
            color:
              theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#727883',
            backgroundColor:
              theme.palette.mode === ThemeMode.DARK ? '#19232F' : '#F6F9FC',
            border:
              theme.palette.mode === ThemeMode.DARK
                ? '1px solid #4E5665'
                : '1px solid #D3D8DE',
            borderRadius: '6px',
            maxWidth: '800px',
          }),
        },
      }}
      title={getTooltipTitle()}
    >
      {children}
    </Tooltip>
  )
}

const BackupSource: React.FC<BackupSourceProps> = ({
  source,
  backupKind,
  timestamp,
  backupId,
}) => {
  const isBackupKindWithArn = backupKindsWithArn.includes(backupKind ?? '')

  const backupDate = (
    <Typography
      component={'span'}
      sx={(theme) => ({
        color: theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#727883',
      })}
    >
      ({formatDate(timestamp, 'MM/DD/YY hh:mm A')})
    </Typography>
  )

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <CloudCircleIcon />
      <Typography
        sx={(theme) => ({
          color: theme.palette.mode === ThemeMode.DARK ? '#F6F9FC' : '#4E5665',
          fontWeight: 600,

          '& .MuiTypography-root': {
            fontWeight: 'inherit',
            paddingRight: 0.5,
          },
        })}
      >
        <Typography component={'span'}>Source:</Typography>

        <Typography
          component={'span'}
          sx={(theme) => ({
            color:
              theme.palette.mode === ThemeMode.DARK ? '#B2B5C1' : '#999CAA',
            fontWeight: 600,
            fontSize: '16px',
          })}
        >
          {source ?? ''}
        </Typography>

        {isBackupKindWithArn ? (
          <BackupArnTooltip backupId={backupId}>{backupDate}</BackupArnTooltip>
        ) : (
          backupDate
        )}
      </Typography>
    </Stack>
  )
}

export default BackupSource
