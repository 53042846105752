import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SortConfig } from '../components/ui/data-table/base-table'

interface UseSortParamsOptions {
  tableId?: string
  defaultSort?: Array<SortConfig>
}

interface UseSortParamsReturn {
  sortConfigs: Array<SortConfig>
  setSortConfigs: (newConfigs: Array<SortConfig>) => void
  clearSort: () => void
  addSort: (config: SortConfig) => void
  removeSort: (key: string) => void
  toggleSortDirection: (key: string) => void
}

export const useSortParams = (
  options: UseSortParamsOptions = {}
): UseSortParamsReturn => {
  const { tableId, defaultSort = [] } = options
  const [searchParams, setSearchParams] = useSearchParams()

  const parseUrlSortParams = useCallback(
    (params: URLSearchParams): Array<SortConfig> => {
      const prefix = tableId ? `${tableId}_` : ''
      const sortParams = params.getAll(`${prefix}sort`)

      if (sortParams.length === 0) {
        return defaultSort
      }

      return sortParams
        .map((param) => {
          const [key, direction] = param.split(':')
          if (key && (direction === 'asc' || direction === 'desc')) {
            return {
              key,
              direction,
            }
          }
          return null
        })
        .filter((config): config is SortConfig => config !== null)
    },
    [tableId, defaultSort]
  )

  const sortConfigs = useMemo(() => {
    return parseUrlSortParams(searchParams)
  }, [searchParams, parseUrlSortParams])

  const setSortConfigs = useCallback(
    (newConfigs: Array<SortConfig>) => {
      const newSearchParams = new URLSearchParams(searchParams)
      const prefix = tableId ? `${tableId}_` : ''

      newSearchParams.delete(`${prefix}sort`)

      newConfigs.forEach((config) => {
        newSearchParams.append(
          `${prefix}sort`,
          `${config.key}:${config.direction}`
        )
      })

      setSearchParams(newSearchParams)
    },
    [searchParams, setSearchParams, tableId]
  )

  const clearSort = useCallback(() => {
    setSortConfigs([])
  }, [setSortConfigs])

  const addSort = useCallback(
    (config: SortConfig) => {
      setSortConfigs([...sortConfigs, config])
    },
    [sortConfigs, setSortConfigs]
  )

  const removeSort = useCallback(
    (key: string) => {
      setSortConfigs(sortConfigs.filter((config) => config.key !== key))
    },
    [sortConfigs, setSortConfigs]
  )

  const toggleSortDirection = useCallback(
    (key: string) => {
      const newConfigs = sortConfigs.map((config) => {
        if (config.key === key) {
          return {
            ...config,
            direction: config.direction === 'asc' ? 'desc' : 'asc',
          }
        }
        return config
      })
      setSortConfigs(newConfigs as Array<SortConfig>)
    },
    [sortConfigs, setSortConfigs]
  )

  return {
    sortConfigs,
    setSortConfigs,
    clearSort,
    addSort,
    removeSort,
    toggleSortDirection,
  }
}
