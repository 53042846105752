import { AssetKindEnum } from 'ui-v2/src/lib/constants/assets.constant'
import { CheckStatus } from 'ui-v2/src/lib/models/backups'

export type RecoveryPointRow = {
  id: string
  health: string | undefined
  name: string
  ransomware_status: ThreatStatus
  anomaly_status: ThreatStatus
  malware_status: ThreatStatus
  fs_check_status: ThreatStatus
  entity_kind?: string
}

export type RecoveryPointBackup = {
  id: string
  backupType: string
  health: string | undefined
  name: string
  timestamp: string
  kind: string
}

export type RecoveryPointBackupResponse = {
  pages: Array<RecoveryPointBackup>
  pagination: {
    next_page_token: string | undefined
  }
}

export enum ThreatStatus {
  DETECTED = 'bad',
  CLEAN = 'good',
  NOT_COVERED = 'unscanned',
}

export enum ThreatStatusMap {
  bad = 'Detected',
  good = 'Clean',
  unscanned = 'Not Covered',
}

export type RecoveryPointFilesTableRow = {
  id: string
  path: string
  threats: string
  variants: string
  firstDetected: string
  suppressed: boolean
}

export enum HealthStatusMap {
  'infected' = 'error',
  'clean' = 'success',
  'at_risk' = 'warning',
  'noScanned' = 'warning',
  'bad' = 'error',
  'good' = 'success',
  'unscanned' = 'warning',
  'unknown' = 'disabled',
}

export type RecoveryPointEntry = { label: string; value: string }
export type RecoveryPointType = Record<AssetKindEnum, Array<RecoveryPointEntry>>

export const RECOVERY_POINTS_TYPES_FILTER_VALUES: RecoveryPointType = {
  [AssetKindEnum.EC2]: [
    {
      label: 'Key',
      value: 'key',
    },
    {
      label: 'AWS Backup',
      value: 'AWS Backup',
    },
  ],
  [AssetKindEnum.EBS]: [
    {
      label: 'Key',
      value: 'key',
    },
    {
      label: 'AWS Backup',
      value: 'AWS Backup',
    },
    {
      label: 'AWS Snapshot',
      value: 'AWS Snapshot',
    },
    {
      label: 'Elastio Managed - EBS Snapshot',
      value: 'Elastio Managed - EBS Snapshot',
    },
  ],
  [AssetKindEnum.S3]: [
    {
      label: 'Key',
      value: 'key',
    },
    {
      label: 'AWS Backup',
      value: 'AWS Backup',
    },
  ],
  [AssetKindEnum.EFS]: [
    {
      label: 'Key',
      value: 'key',
    },
    {
      label: 'AWS Backup',
      value: 'AWS Backup',
    },
  ],
  [AssetKindEnum.SERVER]: [
    {
      label: 'Key',
      value: 'key',
    },
  ],
}

export enum KeyItemTitle {
  latest_backup = 'Latest Known Copy',
  latest_infected_backup = 'Last Known Infected Copy',
  latest_good_backup = 'Last Known Clean Copy',
  earliest_infected_backup = 'First Forensic Copy',
  earliest_backup = 'First Known Copy',
  latest_scan = 'Latest Known Scan',
  latest_infected_scan = 'Last Known Infected Scan',
  latest_good_scan = 'Last Known Clean Scan',
  earliest_infected_scan = 'First Forensic Scan',
  earliest_scan = 'First Known Scan',
}

export type CheckStatusData = {
  ransomware_check_status: CheckStatus
  malware_check_status: CheckStatus
  fs_check_status: CheckStatus
}

export const threatStatusToCheckStatusMap = {
  [ThreatStatus.CLEAN]: CheckStatus.GOOD,
  [ThreatStatus.DETECTED]: CheckStatus.BAD,
  [ThreatStatus.NOT_COVERED]: CheckStatus.UNCHECKED,
} as const
