import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { NotificationEvent } from 'ui-v2/src/lib/models/events'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'

export const getLogsListColumns = (): Array<Column<NotificationEvent>> => [
  {
    key: 'kind',
    header: 'Event',
    width: '50%',
  },
  {
    key: 'eventTime',
    header: 'Triggered',
    width: '50%',
    render: (row) => formatDateToUtc(row.eventTime),
  },
]
