export const MONITOR_FILTER_ASSETS = {
  EC2: 0,
  EBS: 1,
  S3_BUCKET: 2,
  SERVERS: 3,
  GENERIC_HOST: 4,
}

export const MONITOR_FILTER_STATUS = {
  STATUS_SCHEDULED: 0,
  STATUS_STARTING: 1,
  STATUS_INPROGRESS: 2,
  STATUS_FAILED: 3,
  STATUS_SUCCEEDED: 4,
  STATUS_ABORTED: 5,
}
export const MONITOR_FILTER_JOB_TYPE = {
  UNKNOWN: 0,
  EBS_BACKUP: 1,
  EBS_RESTORE: 2,
  EC2_BACKUP: 3,
  EC2_RESTORE: 4,
  EFS_BACKUP: 5,
  ROLLUP: 6,
  BLOCK_BACKUP: 7,
  BLOCK_RESTORE: 8,
  FILE_BACKUP: 9,
  FILE_RESTORE: 10,
  STREAM_BACKUP: 11,
  STREAM_RESTORE: 12,
  ISCAN: 13,
  IMPORT_SNAPSHOTS: 14,
  BACKUP_INTEGRITY_CHECK: 15,
  APPLY_RETENTION_POLICY: 16,
  FS_CHECK: 17,
  POSTGRES_BACKUP: 18,
  POSTGRES_RESTORE: 19,
  AWS_BACKUP_IMPORT: 20,
  VAULT_CATALOG_RESTORE: 21,
  DATABASE_MAINTENANCE: 22,
  POSTGRES_RECOVERY_TEST: 23,
  S3_BACKUP: 24,
  S3_RESTORE: 25,
  AWS_BACKUP_RP_ISCAN: 26,
  AWS_BACKUP_RP_EC2_SCAN: 27,
  AWS_BACKUP_RP_EBS_SCAN: 28,
  AWS_BACKUP_RP_EFS_SCAN: 29,
  AWS_EFS_SCAN: 30,
  AWS_BACKUP_RP_EC2_IMPORT: 31,
  AWS_BACKUP_RP_EBS_IMPORT: 32,
  VAULT_REPLICATION: 33,
  AWS_EC2_AMI_SCAN: 34,
  AWS_FSX_ONTAP_SCAN: 35,
  AWS_EBS_SCAN: 36,
  AWS_EC2_SCAN: 37,
  AWS_BACKUP_RP_FSX_ONTAP_SCAN: 38,
  AWS_EC2_AMI_IMPORT: 39,
  AWS_EBS_SNAPSHOT_SCAN: 40,
  AWS_S3_SCAN: 41,
  KIND_AWS_EBS_SNAPSHOTS_SCAN: 42,
  KIND_AWS_BACKUP_RP_S3_SCAN: 43,
  KIND_AWS_BACKUP_RP_VM_SCAN: 44,
}
export const MONITOR_FILTER_MAP = {
  ASSET: 'asset',
  JOB: 'job',
  STATUS: 'status',
}

export const DEPLOYMENT_FILTER_MAP = {
  JOB: 'job',
  STATUS: 'status',
}
