import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { ScheduleTableRow } from 'ui-v2/src/lib/models/reports'
import dayjs from 'dayjs'
import ScheduleAction from './schedule-action'
import ScheduleNamection from './schedule-name-action'
import { Typography } from '@mui/material'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'

export const getScheduleColumns = (): Array<Column<ScheduleTableRow>> => [
  {
    key: 'name',
    header: 'Name',
    render: (row) => <ScheduleNamection row={row} />,
  },
  {
    key: 'description',
    header: 'Description',
    render: (row) => (
      <LongTextTooltip
        text={row.description ?? '-'}
        placement={'bottom-start'}
        maxLength={50}
      >
        <Typography whiteSpace={'normal'} sx={{ wordBreak: 'break-word' }}>
          {row.description ? row.description : '-'}
        </Typography>
      </LongTextTooltip>
    ),
  },
  {
    key: 'reportType',
    header: 'Report Type',
    render: (row) => <>{row.reportType ? row.reportType : '-'}</>,
  },
  {
    key: 'frequency',
    header: 'Frequency',
    render: (row) => <>{row.frequency ? row.frequency : '-'}</>,
  },
  {
    key: 'lastGeneratedDate',
    header: 'Last Generated',
    render: (row) => (
      <>
        {row.lastGeneratedDate
          ? dayjs(row.lastGeneratedDate).format('hh:mm A, MM/DD/YYYY')
          : '-'}
      </>
    ),
  },
  {
    key: 'nextReportDueOnDate',
    header: 'Next Report Due On',
    render: (row) => (
      <>
        {row.nextReportDueOnDate
          ? dayjs(row.nextReportDueOnDate).format('hh:mm A, MM/DD/YYYY')
          : '-'}
      </>
    ),
  },
  {
    key: 'action',
    header: '',
    render: (row) => <ScheduleAction row={row} />,
  },
]
