import { EntropyDetectionNotificationsStatus as EntropyDetectionNotificationsStatusPb } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/tenant_settings/tenant_settings_pb'

import {
  GetTenantSettingsRequest,
  TenantSettingsModelPb,
  TenantSettingsPromiseClient,
  UpdateTenantSettingsRequest,
} from 'ui-v2/src/lib/grpc'

import {
  EntropyDetectionNotificationsStatus,
  TenantSettings,
  UpdateTenantSettingsRequestAttrs,
} from 'ui-v2/src/lib/models/settings/tenant'
import { TenantSettingsTransformer } from 'ui-v2/src/lib/transformers/settings/tenant-settings.transformer'
import { GrpcClient } from 'ui-v2/src/lib/clients/grpc'
import { ClientConfig } from 'ui-v2/src/lib/models/client'

export class TenantSettingsClient extends GrpcClient<TenantSettingsPromiseClient> {
  #client: TenantSettingsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#client = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'TenantSettingsClient'
  }

  protected initClient(hostname = ''): TenantSettingsPromiseClient {
    return new TenantSettingsPromiseClient(hostname, null, null)
  }

  async getTenantSettings(): Promise<TenantSettings> {
    const request = new GetTenantSettingsRequest()

    const response = (
      await this.callGrpcService(
        () =>
          this.#client.getTenantSettings(request, this.metadata(this.#token)),
        {
          requestName: 'TenantSettingsPromiseClient/getTenantSettings',
        }
      )
    ).toObject()

    return new TenantSettingsTransformer(response.tenantSettings!).transform()
  }

  async updateTenantSettings({
    entropyDetectionStatus,
  }: UpdateTenantSettingsRequestAttrs): Promise<TenantSettings> {
    const request = new UpdateTenantSettingsRequest()

    const tenantSettings = new TenantSettingsModelPb()

    if (entropyDetectionStatus) {
      tenantSettings.setEntropyDetectionNotifications(
        this.#transformEntropyDetectionStatusToProto(entropyDetectionStatus)
      )
    }

    request.setTenantSettings(tenantSettings)

    await this.callGrpcService(
      () =>
        this.#client.updateTenantSettings(request, this.metadata(this.#token)),
      {
        requestName: 'TenantSettingsPromiseClient/updateTenantSettings',
      }
    )

    return this.getTenantSettings()
  }

  #transformEntropyDetectionStatusToProto(
    status: EntropyDetectionNotificationsStatus
  ): EntropyDetectionNotificationsStatusPb {
    const mapping: Record<
      EntropyDetectionNotificationsStatus,
      EntropyDetectionNotificationsStatusPb
    > = {
      [EntropyDetectionNotificationsStatus.DISABLED]:
        EntropyDetectionNotificationsStatusPb.ENTROPY_DETECTION_NOTIFICATIONS_DISABLED,
      [EntropyDetectionNotificationsStatus.ENABLED]:
        EntropyDetectionNotificationsStatusPb.ENTROPY_DETECTION_NOTIFICATIONS_ENABLED,
    }

    return mapping[status]
  }
}
