import VpcModel from 'ui-v2/src/lib/models/red-stack/vpc.model'

export interface SubnetOption {
  id: string
  name: string
}

export interface VpcOption {
  id: string
  name: string
  children: Array<SubnetOption>
}

export interface RegionOption {
  id: string
  name: string
  children: Array<VpcOption>
}

export const transformVpcListToNestedOptions = (
  data: Array<VpcModel> = []
): Array<RegionOption> => {
  const result: Array<RegionOption> = []
  data.forEach((item) => {
    if (!item.hasPublicSubnets) {
      return
    }
    const regionName: string = item.region.name || 'Unknown Region'
    const regionLabel: string = item.region.label || 'Unknown Region'
    const innerId: string = item.innerId || 'Unknown VPC'

    const subnets: Array<SubnetOption> = item.subnetsList.map((subnet) => ({
      id: subnet.id,
      name: subnet.params.name || subnet.id,
    }))

    let region = result.find((r) => r.name === regionName)

    if (!region) {
      region = {
        id: regionName,
        name: regionLabel,
        children: [],
      }
      result.push(region)
    }

    region.children.push({
      id: innerId,
      name: innerId,
      children: subnets,
    })
  })

  return result
}
