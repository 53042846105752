import { ChipType } from 'ui-v2/src/components/ui/data-display/chip'
import { HealthState } from 'ui-v2/src/lib/grpc'

export function getHealthStatus(state: HealthState): ChipType {
  switch (state) {
    case HealthState.AT_RISK:
      return 'warning'
    case HealthState.CLEAN:
      return 'success'
    case HealthState.INFECTED:
      return 'error'
    default:
      return 'disabled'
  }
}

interface KeyValuePair {
  key: string
  value: string
}

export function getName(pairs: Array<KeyValuePair>): string {
  const found = pairs.find((pair) => pair.key.toLowerCase() === 'name')
  return found?.value ?? ''
}
