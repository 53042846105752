import React from 'react'
import FilesTable from '../files-table'
import {
  useGetRecoveryPointByIdQuery,
  useListAssetThreatsForRecoveryPointQuery,
} from 'ui-v2/src/hooks/queries/recovery-points'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import { useSortParams } from 'ui-v2/src/hooks/sort-params'
import { transformSortingConfig } from '../common/util'
import { validateBooleanString } from '../../utils'

const FilesTableContainer = () => {
  const { sortConfigs } = useSortParams()
  const [searchParams] = useSearchParams()
  const selectedFileList = validateBooleanString(
    searchParams.get('suppressed') || ''
  )
  const selectedInsiderThreatsList = searchParams.get('kind') || ''

  const { assetId, recoveryPointId, volumeId, volumeKind } = useParams<{
    assetId: string | undefined
    recoveryPointId: string | undefined
    volumeId: string | undefined
    volumeKind: string | undefined
  }>()

  const { data, status } = useListAssetThreatsForRecoveryPointQuery(
    volumeId ?? assetId,
    recoveryPointId,
    volumeKind,
    {
      sorting: transformSortingConfig(sortConfigs),
      ...(selectedFileList && { suppressed: selectedFileList }),
      ...(selectedInsiderThreatsList && { kind: selectedInsiderThreatsList }),
    }
  )

  const { data: recoveryPointByIdData, status: recoveryPointByIdQueryStatus } =
    useGetRecoveryPointByIdQuery(assetId, recoveryPointId)

  if (status === 'pending' || recoveryPointByIdQueryStatus === 'pending') {
    return (
      <Box
        sx={{
          height: '100%',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={24} sx={{ color: 'grey.500' }} />
      </Box>
    )
  }

  if (status === 'error' || recoveryPointByIdQueryStatus === 'error') {
    return (
      <ErrorDisplay message="Something went wrong while retrieving threats data, please try again later." />
    )
  }

  return (
    <FilesTable
      data={data}
      health={recoveryPointByIdData.health}
      backup={recoveryPointByIdData}
    />
  )
}

export default FilesTableContainer
