import React from 'react'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useFormContext } from 'react-hook-form'
import { ExpandMore } from '@mui/icons-material'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

export const SelectTrigger = styled('button')(({ theme }) => {
  const { palette } = theme
  return {
    width: '100%',
    padding: '4px 14px',
    backgroundColor: palette.background.drawer,
    border: `1px solid ${palette.border.main}`,
    display: 'flex',
    color: palette.text.primary,
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '19px',
    height: '37px',
    minWidth: '158px',
    '&:hover': {
      borderColor: palette.text.primary,
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  }
})

export const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  const { palette } = theme

  return {
    color: palette.text.primary,
    padding: '8px 16px',
    borderBottom: `1px solid ${palette.border.main}`,
    fontSize: '14px',
    backgroundColor: palette.background.hover,

    '&:hover': {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      'svg path': {
        fill: palette.primary.contrastText,
      },
    },

    '&.Mui-selected': {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      'svg path': {
        fill: palette.primary.contrastText,
      },

      '&:hover': {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        'svg path': {
          fill: palette.primary.contrastText,
        },
      },
    },
  }
})

export const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    color: #fff;
    width: 250px;
  }
`

export interface NestedOption {
  id: string
  name: string
  children?: Array<NestedOption>
}

interface FlexibleNestedSelectProps {
  options: Array<NestedOption>
  searchPlaceholder?: string
  allOptionText?: string
  name: string
  isLoading: boolean
  onSelect: (option: Array<NestedOption>) => void
  disabled?: boolean
}

const getAllIds = (nestedOptions: Array<NestedOption>): Array<string> => {
  return nestedOptions.flatMap((option) => [
    option.id,
    ...(option.children ? getAllIds(option.children) : []),
  ])
}

interface OptionMenuProps {
  options: Array<NestedOption>
  level: number
  selectedIds: Array<string>
  toggleOption: (id: string) => void
  handleParentSelect: (option: NestedOption) => void
}

const getSelectedTree = (
  options: Array<NestedOption>,
  selectedIds: Array<string>
): Array<NestedOption> => {
  return options.reduce((acc: Array<NestedOption>, region) => {
    if (region.children) {
      const selectedVpCs = region.children.reduce(
        (vpAcc: Array<NestedOption>, vpc) => {
          if (vpc.children) {
            const selectedSubnets = vpc.children.filter((subnet) =>
              selectedIds.includes(subnet.id)
            )

            if (selectedSubnets.length > 0) {
              vpAcc.push({
                ...vpc,
                children: selectedSubnets,
              })
            }
          } else {
            if (selectedIds.includes(vpc.id)) {
              vpAcc.push({
                ...vpc,
              })
            }
          }
          return vpAcc
        },
        []
      )

      if (selectedVpCs.length > 0) {
        acc.push({
          ...region,
          children: selectedVpCs,
        })
      }
    }
    return acc
  }, [])
}

const OptionMenu: React.FC<OptionMenuProps> = ({
  options,
  level,
  selectedIds,
  toggleOption,
  handleParentSelect,
}) => {
  const [openSubmenuId, setOpenSubmenuId] = React.useState<string | null>(null)
  const [submenuAnchorEl, setSubmenuAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const handleOptionClick = (
    event: React.MouseEvent<HTMLElement>,
    option: NestedOption
  ) => {
    if (option.children && option.children.length > 0) {
      if (openSubmenuId === option.id) {
        setOpenSubmenuId(null)
        setSubmenuAnchorEl(null)
      } else {
        setOpenSubmenuId(option.id)
        setSubmenuAnchorEl(event.currentTarget)
      }
    } else {
      toggleOption(option.id)
    }
  }

  return (
    <Box>
      {options.map((option) => {
        const descendantIds = option.children ? getAllIds(option.children) : []
        const isSelected = option.children
          ? descendantIds.some((id) => selectedIds.includes(id))
          : selectedIds.includes(option.id)
        return (
          <React.Fragment key={option.id}>
            <StyledMenuItem
              onClick={(e) => handleOptionClick(e, option)}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              selected={isSelected}
            >
              <Box component="span">{option.name}</Box>
              {option.children && (
                <>
                  {openSubmenuId === option.id ? (
                    <ExpandMore className="h-4 w-4" />
                  ) : (
                    <ChevronRight className="h-4 w-4" />
                  )}
                </>
              )}
            </StyledMenuItem>
            {option.children && openSubmenuId === option.id && (
              <StyledPopover
                open={true}
                anchorEl={submenuAnchorEl}
                onClose={() => {
                  setOpenSubmenuId(null)
                  setSubmenuAnchorEl(null)
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <OptionMenu
                  options={option.children}
                  level={level + 1}
                  selectedIds={selectedIds}
                  toggleOption={toggleOption}
                  handleParentSelect={handleParentSelect}
                />
              </StyledPopover>
            )}
          </React.Fragment>
        )
      })}
    </Box>
  )
}

export default function NestedSelect({
  options,
  allOptionText = 'All',
  name,
  isLoading = false,
  onSelect,
  disabled = false,
}: FlexibleNestedSelectProps) {
  const { setValue, watch } = useFormContext()
  const selectedIds: Array<string> = watch(name) || []

  const [isOpenPopover, setIsOpenPopover] = React.useState(false)
  const triggerRef = React.useRef<HTMLButtonElement>(null)

  const toggleOption = (id: string) => {
    const updatedIds = selectedIds.includes(id)
      ? selectedIds.filter((existingId: string) => existingId !== id)
      : [...selectedIds, id]
    setValue(name, updatedIds)

    const thirdLevelSelections = getSelectedTree(options, updatedIds)
    onSelect(thirdLevelSelections)
  }

  const handleParentSelect = (option: NestedOption) => {
    const childIds = option.children ? getAllIds(option.children) : []
    const allSelected = childIds.every((id) => selectedIds.includes(id))
    setValue(
      name,
      allSelected
        ? selectedIds.filter((id: string) => !childIds.includes(id))
        : [...new Set([...selectedIds, ...childIds])]
    )
  }

  const handleTriggerClick = () => {
    setIsOpenPopover(true)
  }

  const handleClose = () => {
    setIsOpenPopover(false)
  }

  const handleSelectAll = () => {
    const allIds = getAllIds(options)
    setValue(name, selectedIds.length === allIds.length ? [] : allIds)
  }

  const handleClear = () => {
    setValue(name, [])
  }

  return (
    <Box component="div" className="w-[300px]">
      <SelectTrigger
        ref={triggerRef}
        onClick={handleTriggerClick}
        disabled={isLoading || disabled}
        type="button"
      >
        <Box component="span" alignItems="center">
          {isLoading ? (
            <>
              <CircularProgress size={20} color="inherit" /> Loading...
            </>
          ) : selectedIds.length === 0 ? (
            allOptionText
          ) : (
            `${selectedIds.length} selected`
          )}
        </Box>
        <ArrowDropDownIcon className="h-4 w-4" />
      </SelectTrigger>
      <Popover
        open={isOpenPopover}
        anchorEl={triggerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              width: '200px',
              padding: 0,
              '& > :first-child': {
                padding: 0,
              },
            },
          },
        }}
      >
        <Box p={2}>
          <Box
            sx={{
              padding: 1,
              display: 'flex',
              gap: 2,
            }}
          >
            <Button
              onClick={handleSelectAll}
              variant={'contained'}
              size="small"
              sx={{
                textTransform: 'none',
                height: 32,
                fontSize: 14,
                padding: '5px 16px',
              }}
            >
              {allOptionText}
            </Button>
            <Button
              onClick={handleClear}
              variant={'contained'}
              size="small"
              sx={{
                textTransform: 'none',
                height: 32,
                fontSize: 14,
                padding: '5px 16px',
              }}
            >
              Clear
            </Button>
          </Box>
          <OptionMenu
            options={options}
            level={0}
            selectedIds={selectedIds}
            toggleOption={toggleOption}
            handleParentSelect={handleParentSelect}
          />
        </Box>
      </Popover>
    </Box>
  )
}
