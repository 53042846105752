import { useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import {
  ASSET_FILTERS_MAP,
  BACKUP_PROVIDER,
  BACKUP_VENDOR_MAP,
  Provider,
} from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import { useListAssetFilterQuery } from 'ui-v2/src/hooks/queries/assets/filters-list'

const BackupVendorFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const backupVendorList =
    searchParams.get(ASSET_FILTERS_MAP.BACKUP_VENDOR)?.split(',') || []

  const { assetKind } = useParams<{ assetKind: string | undefined }>()
  const { data: filterData } = useListAssetFilterQuery({ asset: assetKind })

  const backupVendorOptions = useMemo(() => {
    if (!filterData || !filterData.data.data.vendor) {
      return []
    }

    return filterData.data.data.vendor.map((instance) => {
      const provider = instance.backup_provider
      const label = BACKUP_VENDOR_MAP[BACKUP_PROVIDER[provider as Provider]]
      const value = BACKUP_PROVIDER[provider as Provider]

      return {
        label: label,
        value: value.toString(),
      }
    })
  }, [filterData])

  const handleBackupVendorChange = (
    newBackupVendorList: Array<MultiSelectOption['value']>
  ) => {
    if (newBackupVendorList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.BACKUP_VENDOR)
    } else {
      searchParams.set(
        ASSET_FILTERS_MAP.BACKUP_VENDOR,
        newBackupVendorList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={backupVendorOptions}
      value={backupVendorList}
      onChange={handleBackupVendorChange}
      label={
        <SelectLabelText
          selectedValueLength={backupVendorList?.length}
          optionsLength={backupVendorOptions?.length}
          text="Source"
        />
      }
      width={125}
    />
  )
}

export default BackupVendorFilter
