import { useQuery } from '@tanstack/react-query'
import { useRansomwareService } from '../../rest-service/use-ransomware-service'

export function useListRansomwareQuery() {
  const {
    getRansomwareData,
    isReady,
    isLoading: isClientLoading,
  } = useRansomwareService()

  return useQuery({
    queryKey: ['ransomware', 'list'],
    queryFn: async () => {
      return getRansomwareData()
    },
    enabled: isReady && !isClientLoading,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  })
}
