import { useMemo } from 'react'
import { NormalizedStatCardData } from 'ui-v2/src/lib/models/aws-stats'
import { STAT_CARD_SLUG } from 'ui-v2/src/lib/constants/stats.constant'
import {
  BASE_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { useAwsStorageMisconfigurationQuery } from '../queries/aws-stats/storage-misconfigurations'
import { calculateTotalVulnerabilitiesByRisk } from 'ui-v2/src/views/dashboard/assets-vulnerabilities/utils'

const useAwsStorageMisconfigurationStatsData = () => {
  const { data: awsAssetVulnerabilitiesStatsData, isLoading } =
    useAwsStorageMisconfigurationQuery({})

  const normalizedData: Array<NormalizedStatCardData> = useMemo(() => {
    if (!awsAssetVulnerabilitiesStatsData) {
      return []
    }

    const snapshotVulnerabilitiesList =
      awsAssetVulnerabilitiesStatsData.snapshotVulnerabilitiesList
    const volumeVulnerabilitiesList =
      awsAssetVulnerabilitiesStatsData.volumeVulnerabilitiesList

    const totalRisks = calculateTotalVulnerabilitiesByRisk({
      volumeVulnerabilitiesList,
      snapshotVulnerabilitiesList,
    })

    const totalRiskCount = totalRisks.high + totalRisks.medium + totalRisks.low

    return [
      {
        slug: STAT_CARD_SLUG.STORAGE_MISCONFIGURATIONS,
        count: totalRiskCount,
        link: `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.ASSET_VULNERABILITIES}?t=1`,
      },
    ]
  }, [awsAssetVulnerabilitiesStatsData])

  return {
    data: normalizedData,
    isLoading,
  }
}

export default useAwsStorageMisconfigurationStatsData
