import { useMemo } from 'react'
import { useAwsThreatExposureQuery } from '../queries/aws-stats'
import { NormalizedStatCardData } from 'ui-v2/src/lib/models/aws-stats'
import { STAT_CARD_SLUG } from 'ui-v2/src/lib/constants/stats.constant'
import {
  ASSETS_ROUTE_SEGMENTS,
  BASE_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { getDefaultQueryParams } from 'ui-v2/src/views/new-assets/filters/config'
import { NEW_ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'

const useAwsThreatExposureStatsData = () => {
  const { data: awsThreatExposureStatsData, isLoading } =
    useAwsThreatExposureQuery({})

  const normalizedData: Array<NormalizedStatCardData> = useMemo(() => {
    if (!awsThreatExposureStatsData) {
      return []
    }

    return [
      {
        slug: STAT_CARD_SLUG.THREAT_EXPOSURE,
        count: awsThreatExposureStatsData.assetsWithThreatsCount,
        link: `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EC2}?${getDefaultQueryParams(ASSETS_ROUTE_SEGMENTS.EC2).toString()}&${NEW_ASSET_FILTERS_MAP.HEALTH}=2`,
      },
    ]
  }, [awsThreatExposureStatsData])

  return {
    data: normalizedData,
    isLoading,
  }
}

export default useAwsThreatExposureStatsData
