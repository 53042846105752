import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { SearchClient } from 'ui-v2/src/lib/clients/search'
import { AssetsSearchFilters } from 'ui-v2/src/lib/models/assets'

//TODO:rename this we completely migrate off old assets page
export function useNewAssetsSearchQuery(filters: AssetsSearchFilters) {
  const searchClient = useGrpcClient(SearchClient)

  return useQuery({
    queryKey: ['assets', 'search', filters],
    queryFn: async () => {
      if (!searchClient) {
        throw new Error('Search client not initialized')
      }
      return searchClient.searchNewAssets(filters)
    },
    enabled: !!searchClient,
  })
}
