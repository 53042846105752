/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import Button from 'ui-v2/src/components/ui/inputs/button'
import DeleteIcon from '@mui/icons-material/Delete'
import { OvaAccount } from 'blues-corejs/dist'
import {
  processRedStacksData,
  transformOvaAccountsToNestedOptions,
} from './utils'
import { RedStackModel } from 'ui-v2/src/lib/models/base-model'
import {
  RuleAwsAccountRegion,
  Tag,
  TagsOperator,
} from 'blues-corejs/dist/models/notifications/rule/criteria'
import { AntSwitch, buttonStyle, InputField } from './styles'
import NestedSelect, {
  NestedOption,
} from 'ui-v2/src/components/ui/inputs/nested-select/nested-select'
import { FormProvider, useForm } from 'react-hook-form'

interface RuleInfrastructureProps {
  infrastructure: 'AWS' | 'On Prem'
  setInfrastructure: (v: 'AWS' | 'On Prem') => void
  selectedAccounts: Array<string>
  setSelectedAccounts: (v: Array<string>) => void
  ovaAccountsList?: Array<OvaAccount>
  selectedRegions: Array<RuleAwsAccountRegion>
  setSelectedRegions: (v: Array<RuleAwsAccountRegion>) => void
  redStackListData?: Array<RedStackModel>
  tagRelation: TagsOperator
  setTagRelation: (v: TagsOperator) => void
  tagPairs: Array<Tag>
  setTagPairs: (v: Array<Tag>) => void
}

const RuleInfrastructure = ({
  infrastructure,
  setInfrastructure,
  selectedAccounts,
  setSelectedAccounts,
  ovaAccountsList,
  selectedRegions,
  setSelectedRegions,
  redStackListData,
  tagRelation,
  setTagRelation,
  tagPairs,
  setTagPairs,
}: RuleInfrastructureProps) => {
  const method = useForm<{
    regions: Array<string>
    ovaAccounts: Array<string>
  }>({
    defaultValues: {
      regions: selectedRegions.map(
        ({ accountId, region }) => `${accountId}-${region}`
      ),
      ovaAccounts: selectedAccounts,
    },
  })
  const [newTagKey, setNewTagKey] = useState('')
  const [newTagValue, setNewTagValue] = useState('')
  const { accounts } = processRedStacksData(redStackListData)

  const nestedRegionsOption: Array<NestedOption> = accounts.map((account) => {
    return {
      id: account.id,
      name: account.id,
      children: account.regions.map((region) => {
        return {
          id: `${account.id}-${region}`,
          name: region,
        }
      }),
    }
  })
  const nestedOvaOptions: Array<NestedOption> =
    transformOvaAccountsToNestedOptions(ovaAccountsList)

  useEffect(() => {
    method.setValue('ovaAccounts', selectedAccounts)
  }, [selectedAccounts])

  const handleChangeRegions = (value: Array<NestedOption>) => {
    const regions: Array<RuleAwsAccountRegion> = []
    value.forEach((account) => {
      account?.children?.forEach((region) => {
        regions.push({
          accountId: account.id,
          region: region.name,
        })
      })
    })

    setSelectedRegions(regions)
  }

  const handleChangeAccounts = (value: Array<NestedOption>) => {
    const accountsList: Array<string> = []
    value.map((account) => {
      account?.children?.forEach((provider) => {
        accountsList.push(provider.id)
      })
    })

    setSelectedAccounts(accountsList)
  }

  const handleAddTagPair = () => {
    if (newTagKey.trim() && newTagValue.trim()) {
      setTagPairs([
        ...tagPairs,
        {
          key: newTagKey,
          value: newTagValue,
        },
      ])
      // Clear inputs after adding
      setNewTagKey('')
      setNewTagValue('')
    }
  }

  const handleRemoveTagPair = (index: number) => {
    setTagPairs(tagPairs.filter((_, i) => i !== index))
  }

  return (
    <FormProvider {...method}>
      <Typography
        variant="subtitle1"
        sx={{
          mb: 2,
          pb: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        Select infrastructure and optionally asset tags (AWS Only)
      </Typography>

      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 600,
        }}
      >
        Infrastructure
      </Typography>
      <Box sx={{ mb: '5px' }}>
        <RadioGroup
          row
          value={infrastructure}
          onChange={(e) =>
            setInfrastructure(e.target.value as 'AWS' | 'On Prem')
          }
        >
          <FormControlLabel
            value="AWS"
            control={<Radio />}
            label="AWS"
            sx={{ mr: 4 }}
          />
          <FormControlLabel
            value="On Prem"
            control={<Radio />}
            label="On Prem"
          />
        </RadioGroup>
      </Box>

      {infrastructure === 'On Prem' ? (
        <Box>
          <Typography variant="subtitle2">
            Select accounts and backup providers
          </Typography>
          <NestedSelect
            options={nestedOvaOptions}
            name={'ovaAccounts'}
            isLoading={false}
            onSelect={handleChangeAccounts}
          />
        </Box>
      ) : (
        <>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
            }}
          >
            Select Accounts & Regions
          </Typography>

          <Box sx={{ width: 157 }}>
            <NestedSelect
              options={nestedRegionsOption}
              name={'regions'}
              isLoading={false}
              onSelect={handleChangeRegions}
            />
          </Box>

          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              mt: 2,
              mb: 1,
              pt: 2,
              borderTop: '1px solid',
              borderColor: 'divider',
            }}
          >
            Select A Subset Of Assets For These Accounts (Optional)
          </Typography>

          <Box
            sx={{
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              Key Relationship:
            </Typography>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <Typography sx={{ fontSize: '14px' }}>AND</Typography>
              <AntSwitch
                checked={tagRelation === TagsOperator.OR}
                onChange={(e) =>
                  setTagRelation(
                    e.target.checked ? TagsOperator.OR : TagsOperator.AND
                  )
                }
              />
              <Typography sx={{ fontSize: '14px' }}>OR</Typography>
            </Stack>
          </Box>

          {/* Updated Tag Input Form */}
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              mb: 1,
              alignItems: 'stretch',
            }}
          >
            <InputField
              size="small"
              placeholder="Tag Key"
              value={newTagKey}
              onChange={(e) => setNewTagKey(e.target.value)}
              sx={{ flex: 1 }}
            />
            <InputField
              size="small"
              placeholder="Tag Value"
              value={newTagValue}
              onChange={(e) => setNewTagValue(e.target.value)}
              sx={{ flex: 1 }}
            />
            <Button
              variant="contained"
              onClick={handleAddTagPair}
              size="small"
              disabled={!newTagKey.trim() || !newTagValue.trim()}
              sx={buttonStyle}
            >
              Add
            </Button>
          </Box>

          {/* Tags Table */}
          {tagPairs.length > 0 && (
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableBody>
                  {tagPairs.map((pair, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          width: '45%',
                        }}
                      >
                        {pair.key}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderLeft: '1px solid',
                        }}
                      >
                        {pair.value}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleRemoveTagPair(index)}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </FormProvider>
  )
}

export default RuleInfrastructure
