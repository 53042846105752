/* eslint-disable import/no-extraneous-dependencies */
import { Job as ProtobufJob } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/tenant/job_pb'
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { JobTenant } from 'blues-corejs/dist'

export class TenantJobStatusTransformer {
  #status: ProtobufJob.Status

  constructor(status: ProtobufJob.Status) {
    this.#status = status
  }

  transform() {
    const mapping = {
      [ProtobufJob.Status.STATUS_SCHEDULED]: TenantJobStatus.SCHEDULED,
      [ProtobufJob.Status.STATUS_STARTING]: TenantJobStatus.STARTING,
      [ProtobufJob.Status.STATUS_INPROGRESS]: TenantJobStatus.INPROGRESS,
      [ProtobufJob.Status.STATUS_FAILED]: TenantJobStatus.FAILED,
      [ProtobufJob.Status.STATUS_SUCCEEDED]: TenantJobStatus.SUCCEEDED,
      [ProtobufJob.Status.STATUS_ABORTED]: TenantJobStatus.ABORTED,
    }

    return mapping[this.#status]
  }
}

export function getTenantJobStatusMessage({ job }: { job: JobTenant }) {
  const mapping = {
    [TenantJobStatus.SCHEDULED]: 'Scheduled',
    [TenantJobStatus.STARTING]: 'Starting',
    [TenantJobStatus.INPROGRESS]: 'In Progress',
    [TenantJobStatus.FAILED]: job.statusMessage,
    [TenantJobStatus.SUCCEEDED]: 'Completed successfully',
    [TenantJobStatus.ABORTED]: 'Aborted',
  }

  return mapping[job.status] || 'Unknown job status'
}
export function getHumanReadableTenantJobStatus(
  status: TenantJobStatus
): string {
  const mapping: Record<TenantJobStatus, string> = {
    [TenantJobStatus.ABORTED]: 'Aborted',
    [TenantJobStatus.FAILED]: 'Failed',
    [TenantJobStatus.INPROGRESS]: 'In progress',
    [TenantJobStatus.SCHEDULED]: 'Scheduled',
    [TenantJobStatus.STARTING]: 'Starting',
    [TenantJobStatus.SUCCEEDED]: 'Succeeded',
  }

  return mapping[status]
}
