/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { useAssetsThreatsFilters } from 'ui-v2/src/hooks/filters/assets-threats'
import {
  ASSETS_THREATS_FILTERS_MAP,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { ValueInterface } from 'ui-v2/src/lib/engine-types'
import DataHelper from 'ui-v2/src/lib/helpers/data.helper'
import {
  listVolumesWithActiveThreatsPossibleFilters,
  ListVolumesWithThreats,
} from 'blues-corejs/dist'
import { useSearchParams } from 'react-router-dom'
import {
  initialPrioritySortForEBSTable,
  useLiveAssetsDataForAssetsThreat,
} from '../utils'
import AssetsThreatsFilters from '../filters'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { Stack } from '@mui/material'
import { getEbsThreatColumns } from './column-config'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import { AssetThreatsTabs, TAB_FILTERS } from '../filters/config'
import ThreatList from '../threat-list'

const AssetsThreatsEbs = () => {
  const filters = useAssetsThreatsFilters()
  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)

  const { data: liveAssetsData, isLoading } = useLiveAssetsDataForAssetsThreat()

  const data = useMemo(() => {
    if (!liveAssetsData) {
      return undefined
    }

    const { instances, volumes, threats, lastScans, lastBackups } =
      liveAssetsData

    if (instances.length || volumes.length) {
      return {
        instances,
        volumes,
        lastBackups,
        threats,
        lastScans,
      }
    }
  }, [liveAssetsData])

  const possibleVolumesWithActiveThreatsFilters = useMemo(() => {
    if (!data) {
      return undefined
    }

    return new listVolumesWithActiveThreatsPossibleFilters({
      volumes: data.volumes,
      threats: data.threats,
    }).execute().possibleVolumesWithActiveThreatsFilters
  }, [data])

  const volumesFilteredData = useMemo(() => {
    if (!data) {
      return undefined
    }

    const parseFilters = (selectedFilters: Array<ValueInterface>) => {
      return DataHelper.getDataForDashboardFilter(selectedFilters, search ?? '')
    }

    return initialPrioritySortForEBSTable(
      new ListVolumesWithThreats(data)
        .execute(parseFilters(filters))
        .map(({ volume }) => volume)
    )
  }, [data, filters])

  const columns = useMemo(getEbsThreatColumns, [])

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems={'center'}>
        <SearchBar />
        {!isLoading && <ThreatList filters={filters} />}
      </Stack>

      <AssetsThreatsFilters
        filters={TAB_FILTERS[AssetThreatsTabs.VOLUMES]}
        filterValues={{
          [ASSETS_THREATS_FILTERS_MAP.REGION]:
            possibleVolumesWithActiveThreatsFilters?.regions,
          [ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID]:
            possibleVolumesWithActiveThreatsFilters?.accountIds,
          [ASSETS_THREATS_FILTERS_MAP.MALWARE]:
            possibleVolumesWithActiveThreatsFilters?.malwaresList,
          [ASSETS_THREATS_FILTERS_MAP.RANSOMWARE]:
            possibleVolumesWithActiveThreatsFilters?.ransomwaresList,
          [ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY]:
            possibleVolumesWithActiveThreatsFilters?.fsCheck,
        }}
      />
      <ClientTable
        idField="volumeId"
        data={volumesFilteredData ?? []}
        columns={columns}
        isLoading={isLoading}
      />
    </Stack>
  )
}

export default AssetsThreatsEbs
