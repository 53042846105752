/* eslint-disable import/no-extraneous-dependencies */
import { EFS_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { Backup, EFS, Nullable, Threat } from 'blues-corejs/dist'
import { EfsState } from 'blues-corejs/dist/models/assets/aws/efs/types'
import {
  MalwareScanBackup,
  MalwareScanTarget,
} from 'blues-corejs/dist/models/scans/malware/index'
import { ScanTarget } from 'blues-corejs/dist/models/scans/malware/types'
import {
  RansomwareScanBackup,
  RansomwareScanTarget,
} from 'blues-corejs/dist/models/scans/ransomware/index'

export abstract class AssetsEfsBase {
  protected abstract readonly filesystems: Array<EFS>

  protected abstract readonly scans: Array<Scan>

  protected abstract readonly lastBackups: Map<string, Backup>

  protected abstract readonly threats: Array<Threat>

  protected abstract readonly filesystemsWithScans: Array<{
    filesystem: EFS
    scans: Array<Scan>
  }>

  static convertEfsState(
    state: EfsState
  ): (typeof EFS_STATE_MAP)[keyof typeof EFS_STATE_MAP] {
    return EFS_STATE_MAP[state]
  }

  protected isUnhealthy(filesystem: EFS) {
    if (filesystem.state === EfsState.DELETED) {
      return false
    }

    return this.threats.some(
      (threat) =>
        threat.source.asset?.assetId === filesystem.id ||
        threat.source.assetItem?.assetId === filesystem.id
    )
  }

  protected getLastBackup(bucket: EFS): Nullable<Backup> {
    return this.lastBackups.get(bucket.id)
  }

  protected getLastScanForFilesystem(filesystem: EFS) {
    const matchingScan = this.filesystemsWithScans.find(
      (filesystemScan) => filesystemScan.filesystem.id === filesystem.id
    )
    if (!matchingScan) {
      return null
    }

    return matchingScan.scans.reduce(
      (latest, current) =>
        current.createdAt.getTime() > (latest?.createdAt?.getTime() ?? 0)
          ? current
          : latest,
      matchingScan.scans[0]
    )
  }

  protected isRansomwareScanTarget(target: ScanTarget, filesystem: EFS) {
    if (target instanceof RansomwareScanTarget) {
      if ('assetId' in target.target) {
        return target.target.assetId === filesystem.id
      }

      if (target instanceof RansomwareScanBackup && target.source.asset) {
        return target.source.asset.backupAssetId === filesystem.id
      }
    }
  }

  protected isMalwareScanTarget(target: ScanTarget, filesystem: EFS) {
    if (target instanceof MalwareScanTarget) {
      if ('assetId' in target.target) {
        return target.target.assetId === filesystem.id
      }

      if (target instanceof MalwareScanBackup && target.source.asset) {
        return target.source.asset.backupAssetId === filesystem.id
      }
    }
  }

  protected filterScansForFilesystem(
    filesystem: EFS,
    scans: Array<Scan>
  ): Array<Scan> {
    return scans.filter((scan) => {
      const ransomwareScanMatches = this.isRansomwareScanTarget(
        scan.scanTarget,
        filesystem
      )

      const malwareScanMatches = this.isMalwareScanTarget(
        scan.scanTarget,
        filesystem
      )

      return ransomwareScanMatches ?? malwareScanMatches
    })
  }

  protected mapFilesystemToScans(filesystem: EFS): {
    filesystem: EFS
    scans: Array<Scan>
  } {
    const scans = this.filterScansForFilesystem(filesystem, this.scans)
    return {
      filesystem,
      scans,
    }
  }

  protected getFilesystemsWithScans(): Array<{
    filesystem: EFS
    scans: Array<Scan>
  }> {
    return this.filesystems.map(this.mapFilesystemToScans.bind(this))
  }
}
