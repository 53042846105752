import { Column } from 'ui-v2/src/components/ui/data-table/base-table'

import { MonitorTableRow } from 'ui-v2/src/lib/models/monitor'

import dayjs from 'dayjs'

export const getJobColumns = (): Array<Column<MonitorTableRow>> => [
  {
    key: 'scanStartTime',
    header: 'Scheduled On',
    render: (row) => (
      <>{dayjs(row.scanStartTime).format('MM/DD/YY HH[h]:mm[m]')}</>
    ),
  },
  {
    key: 'jobType',
    header: 'Job Type',
    render: (row) => (
      <>
        {row.jobType} {row.assetKind} {row.assetId ? row.assetId : '-'}
      </>
    ),
  },
  {
    key: 'assetId',
    header: 'Asset',
    render: (row) => <>{row.assetId ? row.assetId : '-'}</>,
  },
  {
    key: 'status',
    header: 'Status',
    render: (row) => (
      <>
        Completed{' '}
        {row.isFinished && row.scanEndTime
          ? dayjs(row.scanEndTime).format('MM/DD/YY HH[h]:mm[m]')
          : '-'}
      </>
    ),
  },
]
