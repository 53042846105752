import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const HostFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const hostList = searchParams.get(ASSET_FILTERS_MAP.HOST)?.split(',') || []

  const { data: liveAssetsData } = useAssetsLiveQuery()

  const hostOptions = useMemo(() => {
    if (!liveAssetsData) {
      return []
    }

    return liveAssetsData.genericHosts.map((genericHost) => ({
      label: genericHost.hostname,
      value: genericHost.hostname,
    }))
  }, [liveAssetsData])

  const handleHostChange = (newHostList: Array<MultiSelectOption['value']>) => {
    if (newHostList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.HOST)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.HOST, newHostList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={hostOptions}
      value={hostList}
      onChange={handleHostChange}
      label={
        <SelectLabelText
          selectedValueLength={hostList?.length}
          optionsLength={hostOptions?.length}
          text="Host"
        />
      }
      width={200}
    />
  )
}

export default HostFilter
