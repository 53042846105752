import { Button, FormControl, IconButton, Stack } from '@mui/material'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import { reportOptions } from 'ui-v2/src/lib/constants/reports.constant'
import { useSearchParams } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import { Close } from '@mui/icons-material'
import { Delete } from '@mui/icons-material'
import DateRangePicker from 'ui-v2/src/components/ui/inputs/date-range-picker'

const getOption = (key: keyof (typeof reportOptions)[0], value: string) => {
  return reportOptions.find((reportOption) => reportOption[key] === value)
}

const Filters = ({ startDate, endDate }: FiltersProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const reportOption = getOption('slug', searchParams.get('type') ?? '')

  const handleReport = (value: string) => {
    const selectReportOption = getOption('value', value)
    if (selectReportOption?.slug) {
      searchParams.set('type', selectReportOption.slug)
    }
    setSearchParams(searchParams)
  }

  const handleDateSearchParams = (sDate: Dayjs, eDate: Dayjs) => {
    if (!dayjs(sDate).isValid() || !dayjs(eDate).isValid()) {
      return
    }

    searchParams.set('start', sDate.format('MM-DD-YYYY'))
    searchParams.set('end', eDate.format('MM-DD-YYYY'))

    setSearchParams(searchParams)
  }

  const handleReportClose = () => {
    searchParams.delete('type')
    setSearchParams(searchParams)
  }

  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams())
  }

  return (
    <Stack direction="row" spacing={1} alignItems={'center'} flexWrap={'wrap'}>
      <SearchBar placeholder="Search" />
      <FormControl fullWidth sx={{ maxWidth: 350 }} size="small">
        <BasicSelect
          value={reportOption?.value ?? ''}
          onChange={(value) => handleReport(value as string)}
          options={reportOptions.map(({ label, value }) => ({
            label,
            value,
          }))}
          size="small"
          placeholder="Select a Report"
          endAdornment={
            reportOption?.value ? (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  handleReportClose()
                }}
              >
                <Close />
              </IconButton>
            ) : null
          }
        />
      </FormControl>

      <FormControl fullWidth sx={{ maxWidth: 450 }} size="small">
        <DateRangePicker
          key={startDate.toString() + endDate.toString()}
          initialDateRange={{
            startDate: dayjs(startDate),
            endDate: dayjs(endDate),
          }}
          onChange={(range) => {
            handleDateSearchParams(range.startDate, range.endDate)
          }}
        />
      </FormControl>
      <Button
        disableElevation
        startIcon={<Delete />}
        variant="contained"
        color="inherit"
        sx={(theme) => ({
          textTransform: 'capitalize',
          color: theme.palette.mode === 'dark' ? '#FFF' : '#1AA8EA',
          backgroundColor:
            theme.palette.mode === 'dark' ? '#1A1F28' : '#EFF3F6',
          height: 36,
          border: '1px solid',
          borderRadius: '4px',
          borderColor: theme.palette.mode === 'dark' ? '#29303C' : '#1AA8EA',

          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark' ? '#0076AC' : '#1AA8EA',
            color: '#FFF',
          },
        })}
        onClick={handleClearFilters}
      >
        Clear
      </Button>
    </Stack>
  )
}

interface FiltersProps {
  startDate: Dayjs
  endDate: Dayjs
}

export default Filters
