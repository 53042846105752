/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { useSearchParams } from 'react-router-dom'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { DEPLOYMENT_FILTER_MAP } from 'ui-v2/src/lib/constants/monitor.constant'
import { getHumanReadableTenantJobStatus } from 'ui-v2/src/lib/transformers/tenant-jobs/tenant-job-status'

const JobStatusDropdown = ({ statusesList }: JobStatusDropdownProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const statusList =
    searchParams.get(DEPLOYMENT_FILTER_MAP.STATUS)?.split(',') || []

  const options = useMemo(() => {
    return Array.from(statusesList)
      .filter((statusId) => !!statusId)
      .map((status) => ({
        label: getHumanReadableTenantJobStatus(status),
        value: status?.toString(),
      }))
  }, [statusesList])

  const handleStatusChange = (
    newInstanceIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newInstanceIdList.length === 0) {
      searchParams.delete(DEPLOYMENT_FILTER_MAP.STATUS)
    } else {
      searchParams.set(
        DEPLOYMENT_FILTER_MAP.STATUS,
        newInstanceIdList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      value={statusList}
      options={options}
      onChange={handleStatusChange}
      width={200}
      label={
        <SelectLabelText
          selectedValueLength={statusList?.length}
          optionsLength={options?.length}
          text="Job Status"
        />
      }
    />
  )
}
interface JobStatusDropdownProps {
  statusesList: Array<TenantJobStatus>
}
export default JobStatusDropdown
