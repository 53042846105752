import {
  formatDateToUtc,
  maybeSecondsToDate,
} from 'ui-v2/src/lib/helpers/time.helper'
import Link from 'ui-v2/src/components/ui/data-display/link'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import { Efs } from 'ui-v2/src/lib/grpc'
import { getHealthStatus, getName } from '../util'

export const getEfsColumns = (): Array<Column<Efs.AsObject>> => [
  {
    key: 'health',
    header: 'Health',
    render: (row) => <StatusChip type={getHealthStatus(row.health)} />,
    width: 80,
    align: 'center',
    sortable: true,
  },
  {
    key: 'awsTagsList',
    header: 'Name',
    render: (row) => (
      <Link to={`/details/${row.id}`} preservePath>
        {getName(row.awsTagsList)}
      </Link>
    ),
  },
  {
    key: 'awsId',
    header: 'ID',
    render: (row) => (
      <Link to={`/details/${row.id}`} preservePath>
        {row.awsId}
      </Link>
    ),
    sortable: true,
  },
  {
    key: 'awsAccountId',
    header: 'Account',
    sortable: true,
  },
  {
    key: 'awsRegion',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.awsRegion)
      return region?.en?.single ?? ''
    },
    sortable: true,
  },
  {
    key: 'lastScan',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(maybeSecondsToDate(row.lastScan?.seconds)),
    sortable: true,
  },
  {
    key: 'backupPoliciesList',
    header: 'Policy Coverage',
    render: (row) => row.backupPoliciesList.join(', '),
    wrap: true,
    sortable: true,
  },
]
