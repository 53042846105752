import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_VULNERABILITIES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

export interface FilterComponentProps {
  options: Array<string>
}

const InstanceMisconfigurationFilter: React.FC<FilterComponentProps> = ({
  options,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsMisconfigurationsList =
    searchParams
      .get(ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_MISCONFIGURATION)
      ?.split(',') || []

  const awsMisconfigurationOptions = useMemo(() => {
    return options.map((awsMisconfiguration) => ({
      value: awsMisconfiguration,
      label: awsMisconfiguration,
    }))
  }, [options])

  const handleAwsMisconfigurationChange = (
    newAwsMisconfigurationList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsMisconfigurationList.length === 0) {
      searchParams.delete(
        ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_MISCONFIGURATION
      )
    } else {
      searchParams.set(
        ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_MISCONFIGURATION,
        newAwsMisconfigurationList.join(',')
      )
    }
    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsMisconfigurationOptions}
      value={awsMisconfigurationsList}
      onChange={handleAwsMisconfigurationChange}
      width={205}
      label={
        <SelectLabelText
          selectedValueLength={awsMisconfigurationsList?.length}
          optionsLength={awsMisconfigurationOptions?.length}
          text="Misconfiguration Type"
        />
      }
    />
  )
}

export default InstanceMisconfigurationFilter
