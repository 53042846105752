import React from 'react'
import { Dialog, DialogHeader } from '../ui/dialog'
import DialogActions from '../ui/dialog/actions'
import Button from '../ui/inputs/button'
import { DialogContent } from '@mui/material'

interface ConfirmationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => Promise<void> | void
  title?: string
  confirmText?: string
  cancelText?: string
  isLoading?: boolean
  children: React.ReactNode
}

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title = 'Confirmation Dialog',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  isLoading = false,
  children,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader handleCloseDialog={onClose} title={title} />
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button size="medium" color="inherit" onClick={onClose} type="button">
          {cancelText}
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={onConfirm}
          isLoading={isLoading}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
