/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { CloudConnector } from 'blues-corejs/dist'
import { ActiveDeploymentTableData } from '../types'

interface DeploymentStore {
  rowData: ActiveDeploymentTableData | null
  cloudConnectors: Array<CloudConnector>
  setRowData: (data: ActiveDeploymentTableData | null) => void
  setCloudConnectors: (data: Array<CloudConnector>) => void
}

export const useDeploymentStore = create<DeploymentStore>((set) => ({
  cloudConnectors: [],
  rowData: null,
  setRowData: (rowData: ActiveDeploymentTableData | null) => set({ rowData }),
  setCloudConnectors: (cloudConnectors: Array<CloudConnector>) =>
    set({ cloudConnectors }),
}))
