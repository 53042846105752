import React, { useMemo, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_THREATS_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

export interface FilterComponentProps {
  options: Array<string>
}

const MalwareFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const malwareParam = searchParams.get(ASSETS_THREATS_FILTERS_MAP.MALWARE)

  useEffect(() => {
    if (!options.length) {
      return
    }
    if (malwareParam?.toLowerCase() === 'all') {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set(ASSETS_THREATS_FILTERS_MAP.MALWARE, options.join(','))
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [malwareParam, options, setSearchParams])

  const malwareList = useMemo(() => {
    if (!malwareParam) {
      return []
    }
    if (malwareParam.toLowerCase() === 'all') {
      return options
    }
    return malwareParam.split(',').filter(Boolean)
  }, [malwareParam, options])

  const malwareOptions = useMemo(() => {
    return options.map((malware) => ({
      value: malware,
      label: malware,
    }))
  }, [options])

  const handleMalwareChange = (
    MalwareList: Array<MultiSelectOption['value']>
  ) => {
    const newSearchParams = new URLSearchParams(searchParams)
    if (MalwareList.length === 0) {
      newSearchParams.delete(ASSETS_THREATS_FILTERS_MAP.MALWARE)
    } else {
      newSearchParams.set(
        ASSETS_THREATS_FILTERS_MAP.MALWARE,
        MalwareList.join(',')
      )
    }
    setSearchParams(newSearchParams)
  }

  return (
    <FilterMultiSelect
      options={malwareOptions}
      value={malwareList}
      onChange={handleMalwareChange}
      label={
        <SelectLabelText
          selectedValueLength={malwareList?.length}
          optionsLength={malwareOptions?.length}
          text="Malware"
        />
      }
      width={200}
    />
  )
}

export default MalwareFilter
