const ElastioLogoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
  >
    <path
      d="M9.88027 21.2862C10.1679 21.2885 10.4511 21.2154 10.7013 21.0744L17.5713 17.1701C17.8226 17.0269 18.0316 16.8206 18.1774 16.5719C18.3232 16.3233 18.4007 16.0409 18.4021 15.7531V11.3136H16.5662V15.6458L9.88027 19.44L2.52426 15.2193V6.78062L9.88027 2.55991L16.3073 6.24822L9.54244 10.059L10.4465 11.641L17.4938 7.67071C17.7443 7.52842 17.9528 7.32313 18.0982 7.07547C18.2436 6.82781 18.3209 6.54649 18.3223 6.25975C18.3237 5.97302 18.2492 5.69096 18.1062 5.44191C17.9632 5.19285 17.7568 4.98556 17.5076 4.84085L10.7013 0.932432C10.452 0.789873 10.1693 0.714844 9.88165 0.714844C9.59398 0.714844 9.31135 0.789873 9.06201 0.932432L1.51632 5.25357C1.26663 5.39713 1.05926 5.60331 0.914934 5.8515C0.770609 6.09969 0.694393 6.38117 0.693909 6.66781V15.3211C0.694393 15.6077 0.770609 15.8892 0.914934 16.1374C1.05926 16.3856 1.26663 16.5918 1.51632 16.7353L9.06201 21.0744C9.31142 21.2149 9.59356 21.2879 9.88027 21.2862Z"
      fill="white"
    />
    <path
      d="M9.99242 12.6701C8.98154 12.6701 8.16207 11.8558 8.16207 10.8514C8.16207 9.84694 8.98154 9.03267 9.99242 9.03267C11.0033 9.03267 11.8228 9.84694 11.8228 10.8514C11.8228 11.8558 11.0033 12.6701 9.99242 12.6701Z"
      fill="white"
    />
    <path
      d="M15.5693 11.3948C15.5693 12.3991 16.4651 13.1378 17.4758 13.1378C17.9612 13.1378 18.4268 12.9462 18.7701 12.6051C19.1133 12.2641 19.3062 11.8015 19.3062 11.3191C19.3062 10.8368 19.1133 10.3742 18.7701 10.0331C18.4268 9.69203 17.9612 9.50042 17.4758 9.50042C16.9747 9.51462 16.498 9.71876 16.1435 10.071C15.789 10.4232 15.5836 10.8969 15.5693 11.3948Z"
      fill="white"
    />
  </svg>
)

export default ElastioLogoIcon
