import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { NotificationsRulesClient } from 'ui-v2/src/lib/clients'
import { useMutation } from '@tanstack/react-query'

export function useEnableNotificationRules() {
  const notificationClient = useGrpcClient(NotificationsRulesClient)

  return useMutation({
    mutationFn: async (ids: Array<string>) => {
      if (!notificationClient) {
        throw new Error('Notification Client not initialized')
      }
      return notificationClient.enableNotificationRule({ ids })
    },
  })
}
