import React from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import { assetWidgetConfig, widgetComponents } from './config'

const Widgets = () => {
  const { assetKind } = useParams<{
    assetKind: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
  }>()

  if (!assetKind) {
    return null
  }

  const widgets = assetWidgetConfig[assetKind]

  return (
    <Box display={'flex'} gap={2} height={'100%'}>
      {widgets.map((widget) => {
        const Widget = widgetComponents[widget.key]
        return (
          <Box {...widget}>
            <Widget />
          </Box>
        )
      })}
    </Box>
  )
}

export default Widgets
