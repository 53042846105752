/* eslint-disable import/no-extraneous-dependencies */
import TimeFormatConstants from 'ui-v2/src/lib/constants/time-format.constant'
import { computeAssetKind } from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job'
import { Asset, GenericHost, OvaServer, Threat } from 'blues-corejs/dist'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function getAssetName(asset: Asset): string {
  if (asset instanceof OvaServer) {
    return asset.name
  }

  if (asset instanceof GenericHost) {
    return asset.hostname
  }

  return asset.name || asset.awsId
}

function prepareActiveThreatDataToCsvFormat(
  threatsList: Array<Threat>,
  assetsMap: Map<Threat, Asset>
) {
  return threatsList.reduce(
    (result, threat) => {
      const asset = assetsMap.get(threat)
      if (!asset) {
        return result
      }

      result.push({
        assetName: getAssetName(asset),
        assetType: computeAssetKind(asset),
        firstDetected: `${dayjs(threat.firstDetectedAt)
          .utc()
          .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`,
        path: threat.location,
        threatName: threat.name,
      })

      return result
    },
    [] as Array<{
      assetName: string
      assetType: string
      firstDetected: string
      path: string
      threatName: string
    }>
  )
}

const ACTIVE_THREATS_CSV_TABLE_HEADERS = [
  {
    label: 'Asset name',
    key: 'assetName',
  },
  {
    label: 'Asset type',
    key: 'assetType',
  },
  {
    label: 'First Detected',
    key: 'firstDetected',
  },
  {
    label: 'Path',
    key: 'path',
  },
  {
    label: 'Threat name',
    key: 'threatName',
  },
]

export { ACTIVE_THREATS_CSV_TABLE_HEADERS, prepareActiveThreatDataToCsvFormat }
