/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from 'react'
import {
  NotificationRule,
  RuleNotify,
  RuleStatus,
  ExactEvents,
} from 'blues-corejs/dist/models/notifications/rule'
import { useListEventKindsQuery } from 'ui-v2/src/hooks/queries/alerts/list-notifications-rules'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { EventsColumn, getEventsListColumns } from './column-config'
import { useEventsListStore } from './events-list-store'
import { Box, Button } from '@mui/material'
import { SearchField, SearchIconButton, SearchWrapper } from '../styles'
import { Search as SearchIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { useAlertsActiveRuleStore } from '../alerts/store/alerts-active-rule-store'
import { TagsOperator } from 'blues-corejs/dist/models/notifications/rule/criteria'
import { ALERTS_KINDS_MAP } from '../alerts/utils'

const buttonStyle = {
  textTransform: 'none',
  height: 24,
  fontSize: 12,
  padding: '5px 8px',
}

const createRuleLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.ALERTS}/${GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS.ALERTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_ALERT_RULE}`

const Events = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useListEventKindsQuery()
  const { setActiveRule } = useAlertsActiveRuleStore()
  const { selectedRows, setSelectedRows } = useEventsListStore()
  const [eventSearch, setEventSearch] = useState('')

  const tableData: Array<EventsColumn> = useMemo(() => {
    if (!data) {
      return []
    }

    return data
      .filter((event) => {
        const eventLabel = ALERTS_KINDS_MAP[event]
        return (
          !!eventLabel &&
          eventLabel.toLowerCase().includes(eventSearch.toLowerCase())
        )
      })
      .map((event) => ({
        event: '',
        details: event,
        label: ALERTS_KINDS_MAP[event] ?? '',
      }))
  }, [data, eventSearch])

  const columns = getEventsListColumns()

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEventSearch(event.target.value)
  }

  const handleCreate = () => {
    if (!selectedRows.length) {
      return
    }
    const rule = new NotificationRule({
      status: RuleStatus.enabled,
      name: '',
      description: '',
      createdAt: new Date(),
      mode: new RuleNotify(),
      channels: {
        webhooks: [],
        tenantMemberEmails: [],
        emails: [],
        slack: false,
      },
      id: '',
      updatedAt: new Date(),
      criteria: {
        assetIds: [],
        awsAccountRegions: [],
        ovaAccountProviders: [],
        eventKinds: new ExactEvents(selectedRows.map((event) => event.details)),
        tags: {
          tags: [],
          operator: TagsOperator.AND,
        },
      },
    })

    setActiveRule(rule)
    navigate(createRuleLink)
  }

  useEffect(() => {
    return () => {
      setSelectedRows([])
    }
  }, [])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <SearchWrapper>
          <SearchField
            placeholder="Search Events"
            variant="outlined"
            size="small"
            value={eventSearch}
            onChange={handleSearch}
          />
          <SearchIconButton>
            <SearchIcon
              sx={{
                fontSize: 18,
              }}
            />
          </SearchIconButton>
        </SearchWrapper>
        <Button
          variant="contained"
          color="primary"
          sx={{
            ...buttonStyle,
            textTransform: 'capitalize',
          }}
          onClick={handleCreate}
          disabled={!selectedRows.length}
        >
          Create Rule from Event(s)
        </Button>
      </Box>
      <ClientTable
        checkable
        data={tableData}
        columns={columns}
        onSelectRows={setSelectedRows}
        selectedRows={selectedRows}
        isLoading={isLoading}
        loadingRowCount={3}
        initialPageSize={100}
        rowsPerPageOptions={[100]}
        styles={{
          cell: {
            height: '40px',
          },
          headerCell: {
            height: '40px',
          },
        }}
      />
    </Box>
  )
}

export default Events
