import React from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  MONITOR_FILTER_MAP,
  MONITOR_FILTER_STATUS,
} from 'ui-v2/src/lib/constants/monitor.constant'
import { getHumanReadableCloudConnectorJobStatus } from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job-status'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const StatusDropdown = () => {
  // eslint-disable-next-line no-unused-vars
  const options = Object.entries(MONITOR_FILTER_STATUS).map(([_, value]) => ({
    value: value.toString(),
    label: getHumanReadableCloudConnectorJobStatus(value),
  }))

  const [searchParams, setSearchParams] = useSearchParams()
  const statusList =
    searchParams.get(MONITOR_FILTER_MAP.STATUS)?.split(',') || []

  const handleStatusChange = (
    newInstanceIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newInstanceIdList.length === 0) {
      searchParams.delete(MONITOR_FILTER_MAP.STATUS)
    } else {
      searchParams.set(MONITOR_FILTER_MAP.STATUS, newInstanceIdList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      value={statusList}
      options={options}
      onChange={handleStatusChange}
      width={200}
      label={
        <SelectLabelText
          selectedValueLength={statusList?.length}
          optionsLength={options?.length}
          text="Status"
        />
      }
    />
  )
}

export default StatusDropdown
