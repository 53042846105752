import React from 'react'
import { Delete } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { filterOptions } from './config'

interface AssetDetailsFiltersProps {
  filters: any
}

const AssetDetailsFilters: React.FC<AssetDetailsFiltersProps> = ({
  filters,
}) => {
  const [, setSearchParams] = useSearchParams()

  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams())
  }

  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction="row"
        flexWrap={'wrap'}
        rowGap={1}
        columnGap={1}
        alignItems={'center'}
        height={32}
      >
        {filters.map((filter: string) => (
          <React.Fragment key={filter}>
            {filterOptions[filter as keyof typeof filterOptions]}
          </React.Fragment>
        ))}

        <Button
          disableElevation
          startIcon={<Delete />}
          variant="contained"
          color="inherit"
          sx={(theme) => ({
            textTransform: 'capitalize',
            color: theme.palette.mode === 'dark' ? '#FFF' : '#1AA8EA',
            backgroundColor:
              theme.palette.mode === 'dark' ? '#1A1F28' : '#EFF3F6',
            height: 36,
            border: '1px solid',
            borderRadius: '4px',
            borderColor: theme.palette.mode === 'dark' ? '#29303C' : '#1AA8EA',

            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark' ? '#0076AC' : '#1AA8EA',
              color: '#FFF',
            },
          })}
          onClick={handleClearFilters}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  )
}

export default AssetDetailsFilters
