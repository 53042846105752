/* eslint-disable import/no-extraneous-dependencies */
import {
  EmailChannel,
  ExactEvents,
  NotificationRule,
  RuleAwsAccountRegion,
  RuleMode,
  RuleOvaAccountProviders,
  RuleStatus,
  RuleSuppressUntil,
  TenantMemberEmailChannel,
  WebhookChannel,
} from 'blues-corejs/dist/models/notifications/rule'
import dayjs from 'dayjs'
import {
  EventKind,
  Tag,
  TagsOperator,
} from 'blues-corejs/dist/models/notifications/rule/criteria'
import { UserProfile } from 'blues-corejs/dist/models'
import { WebhooksListItem } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'
import { HUMAN_READABLE_OVA_BACKUP_PROVIDER } from '@lib/constants/reports/consts'

export interface CreateRuleFormStates {
  name: string
  description: string
  isEnabled: boolean
  selectedEvents: Array<string>
  isSuppress: boolean
  suppressUntil: dayjs.Dayjs
  suppressReason: string
  infrastructure: 'AWS' | 'On Prem'
  selectedAccounts: Array<string>
  selectedRegions: Array<RuleAwsAccountRegion>
  tagPairs: Array<Tag>
  tagRelation: TagsOperator
  emailUsers: Array<string>
  selectedWebhooks: Array<string>
  slackEnabled: boolean
}

export const changeStatusForRules = ({
  rulesList,
  newStatus,
  changedIds,
}: {
  rulesList: Array<NotificationRule>
  changedIds: Array<string>
  newStatus: RuleStatus
}) => {
  return rulesList.map((rule) => {
    const {
      id,
      mode,
      createdAt,
      updatedAt,
      criteria,
      channels,
      name,
      description,
      status,
    } = rule
    return new NotificationRule({
      id,
      name,
      description,
      mode,
      criteria,
      channels,
      createdAt,
      updatedAt,
      status: changedIds.includes(id) ? newStatus : status,
    })
  })
}

const getEmailUsers = (
  emails: Array<EmailChannel>,
  tenantEmails: Array<TenantMemberEmailChannel>,
  recipients: Array<UserProfile>
): Array<string> => {
  const emailsList = emails.map(({ email }) => email)
  const tenantEmailsIds = tenantEmails.map(({ customer_id }) => customer_id)
  const tenatEmails = recipients.reduce((acc: Array<string>, profile) => {
    if (tenantEmailsIds.includes(profile.id)) {
      return [...acc, profile.email]
    }

    return acc
  }, [])

  return [...tenatEmails, ...emailsList]
}

const getEvents = (
  eventKinds: EventKind,
  allEvents: Array<string>
): Array<string> => {
  if (eventKinds instanceof ExactEvents) {
    return eventKinds.kinds
  }

  return allEvents
}

const getRuleSuppression = (
  mode: RuleMode
): {
  isSuppress: boolean
  suppressDate: dayjs.Dayjs
  suppressReason: string
} => {
  if (mode instanceof RuleSuppressUntil) {
    return {
      isSuppress: true,
      suppressReason: mode.reason,
      suppressDate: dayjs(mode.date),
    }
  }

  return {
    isSuppress: false,
    suppressDate: dayjs(),
    suppressReason: '',
  }
}

const getWebhooks = (
  rulesWebhook: Array<WebhookChannel>,
  webhooksList: Array<WebhooksListItem.AsObject>
): Array<string> => {
  const rulesWeebhookIds = rulesWebhook.map(({ webhookId }) => webhookId)

  return webhooksList.reduce((acc: Array<string>, hook) => {
    if (rulesWeebhookIds.includes(hook.id)) {
      return [...acc, hook.name]
    }

    return acc
  }, [])
}

const getOvaAccounts = (
  rulesOvaAccount: Array<RuleOvaAccountProviders>
): Array<string> => {
  return rulesOvaAccount.map(
    ({ provider, accountId }) =>
      `${accountId}||${HUMAN_READABLE_OVA_BACKUP_PROVIDER[provider].toUpperCase()}`
  )
}

export const transformNotificationForForm = ({
  notification,
  recipients,
  allEvents,
  webhooksList,
}: {
  notification: NotificationRule
  recipients: Array<UserProfile>
  allEvents: Array<string>
  webhooksList: Array<WebhooksListItem.AsObject>
}): CreateRuleFormStates => {
  const { name, description, status, criteria, channels, mode } = notification
  const { emails, tenantMemberEmails } = channels
  const { isSuppress, suppressReason, suppressDate } = getRuleSuppression(mode)

  return {
    name,
    description,
    isSuppress,
    suppressReason,
    suppressUntil: suppressDate,
    isEnabled: status === RuleStatus.enabled,
    emailUsers: getEmailUsers(emails, tenantMemberEmails, recipients),
    selectedEvents: getEvents(criteria.eventKinds, allEvents),
    slackEnabled: channels.slack,
    infrastructure: criteria.ovaAccountProviders.length > 0 ? 'On Prem' : 'AWS',
    tagPairs: criteria.tags.tags,
    tagRelation: criteria.tags.operator,
    selectedRegions: criteria.awsAccountRegions,
    selectedWebhooks: getWebhooks(channels.webhooks, webhooksList),
    selectedAccounts: getOvaAccounts(criteria.ovaAccountProviders),
  }
}

export const ALERTS_KINDS_MAP: { [key: string]: string } = {
  'elastio:asset:threat:detected': 'Threat Detected',
  'elastio:asset:threat:resolved': 'Threat Resolved',
  'elastio:asset:infected': 'Asset Infected',
  'elastio:asset:iscan:failed': 'Scan Failed',
  'elastio:asset:iscan:succeeded': 'Scan Succeeded',
  'elastio:asset:anomaly:detected': 'Insider Threat Detected',
  'elastio:user:activate:succeeded': 'User Activated',
  'elastio:user:deactivate:succeeded': 'User Deactivated',
  'elastio:user:invite:succeeded': 'User Invited',
  'elastio:user:soft-delete:succeeded': 'User Deleted',
  'elastio:account-level-stack:discovery:multiple-stacks-detected':
    'Multiple Stacks Detected',
}
