import React from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { MONITOR_FILTER_MAP } from 'ui-v2/src/lib/constants/monitor.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const AssetsDropdown = ({ assets }: AssetsDropdownProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const assetIdList =
    searchParams.get(MONITOR_FILTER_MAP.ASSET)?.split(',') || []

  const handleAssetsChange = (
    newInstanceIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newInstanceIdList.length === 0) {
      searchParams.delete(MONITOR_FILTER_MAP.ASSET)
    } else {
      searchParams.set(MONITOR_FILTER_MAP.ASSET, newInstanceIdList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      value={assetIdList}
      options={assets}
      onChange={handleAssetsChange}
      width={200}
      label={
        <SelectLabelText
          selectedValueLength={assetIdList?.length}
          optionsLength={assets?.length}
          text="Assets"
        />
      }
    />
  )
}

interface AssetsDropdownProps {
  assets: Array<MultiSelectOption>
}

export default AssetsDropdown
