/* eslint-disable */
/* eslint-disable import/no-extraneous-dependencies */
import {
  Asset,
  EBS,
  EBSState,
  EC2,
  EC2State,
  EFS,
  S3Bucket,
} from 'blues-corejs/dist'
import {
  HealthStatusMap,
  KeyItemTitle,
  ThreatStatus,
} from './data-panel/common/types'
import { Scan } from 'ui-v2/src/lib/models/backups'
import { S3BucketState } from 'blues-corejs/dist/models/assets/aws/s3-bucket/types'
import { EfsState } from 'blues-corejs/dist/models/assets/aws/efs/types'

export const processKinds = (kindsString: string | null) => {
  if (!kindsString) {
    return {
      keyKind: 'all',
      backupType: '',
    }
  }

  const kindsArray: Array<string> = kindsString.split(',')
  const keyKind: string = kindsArray.includes('key') ? 'key' : 'all'
  const backupType: string = kindsArray
    .filter((backup) => backup !== 'key')
    .join(',')

  return {
    keyKind,
    backupType,
  }
}

export const extractBackupAndAssetIdFromScans = (scans: Array<Scan>) => {
  let result: {
    assetId: string | undefined
    recoveryId: string | undefined
  } = {
    assetId: undefined,
    recoveryId: undefined,
  }

  for (const scan of scans) {
    if (scan.type === 'backup' && !!scan.target) {
      result.recoveryId = scan.target.id

      if (scan.target.resource.kind_tag === 'asset') {
        result.assetId = scan.target.resource.id
      }

      if (scan.target.resource.kind_tag === 'asset_item') {
        result.assetId = scan.target.resource.asset_id
      }

      break
    } else if (scan.type === 'direct') {
      if (scan.target.kind_tag === 'asset') {
        result.assetId = scan.target.id
      }
      if (scan.target.kind_tag === 'asset_item') {
        result.assetId = scan.target.asset_id
      }
    }
  }

  return result
}

export const getStatus = (
  status: string
): 'disabled' | 'error' | 'success' | 'warning' => {
  return HealthStatusMap[status as keyof typeof HealthStatusMap] || 'disabled'
}

export const getHealthDefaultValue = (item: any): string => {
  if (
    item?.ransomware_status === 'bad' ||
    item?.malware_status === 'bad' ||
    item?.fs_check_status === 'bad' ||
    item?.insider_threat_status > 0
  ) {
    return 'error'
  }

  if (
    item?.ransomware_status === 'unscanned' ||
    item?.malware_status === 'unscanned'
  ) {
    return 'warning'
  }

  if (item?.ransomware_status === 'good' && item?.malware_status === 'good') {
    return 'success'
  }

  return 'warning'
}

export const getInsiderThreatsStatus = (value: number) => {
  if (value === 0) {
    return ThreatStatus.CLEAN
  } else if (value > 0) {
    return ThreatStatus.DETECTED
  } else {
    return ThreatStatus.NOT_COVERED
  }
}

const isKeyItemTitleKey = (key: string): key is keyof typeof KeyItemTitle => {
  return key in KeyItemTitle
}

export const getKeyItemText = (keys: string[]): string => {
  if (!keys.length) {
    return ''
  }

  const coreKeys = new Set<keyof typeof KeyItemTitle>()

  for (const key of keys) {
    if (isKeyItemTitleKey(key)) {
      coreKeys.add(key)
    }
  }

  if (coreKeys.size === 0) {
    return ''
  }

  const priorityKeys: Array<keyof typeof KeyItemTitle> = [
    'latest_infected_backup',
    'latest_good_backup',
    'earliest_infected_backup',
    'latest_infected_scan',
    'latest_good_scan',
    'earliest_infected_scan',
  ]

  for (const priorityKey of priorityKeys) {
    if (coreKeys.has(priorityKey)) {
      return KeyItemTitle[priorityKey]
    }
  }

  if (coreKeys.has('latest_backup')) {
    return KeyItemTitle.latest_backup
  }

  if (coreKeys.has('latest_scan')) {
    return KeyItemTitle.latest_scan
  }

  if (coreKeys.has('earliest_backup')) {
    return KeyItemTitle.earliest_backup
  }

  if (coreKeys.has('earliest_scan')) {
    return KeyItemTitle.earliest_scan
  }

  return ''
}

export const formatTimeAgo = (timestamp: string): string => {
  const now = new Date()
  const past = new Date(timestamp)
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000)

  const minute = 60
  const hour = 60 * minute
  const day = 24 * hour
  const month = 30 * day
  const year = 365 * day

  if (diffInSeconds < minute) {
    return `${diffInSeconds} sec`
  } else if (diffInSeconds < hour) {
    const minutes = Math.floor(diffInSeconds / minute)
    return `${minutes} min`
  } else if (diffInSeconds < day) {
    const hours = Math.floor(diffInSeconds / hour)
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`
  } else if (diffInSeconds < month) {
    const days = Math.floor(diffInSeconds / day)
    return `${days} ${days === 1 ? 'day' : 'days'}`
  } else if (diffInSeconds < year) {
    const months = Math.floor(diffInSeconds / month)
    return `${months} ${months === 1 ? 'month' : 'months'}`
  } else {
    const years = Math.floor(diffInSeconds / year)
    return `${years} ${years === 1 ? 'year' : 'years'}`
  }
}

export const validateBooleanString = (input: string): string => {
  return input.includes('true,false') || input.includes('false,true')
    ? ''
    : input
}

export const getAssetIdsToScan = (asset: Asset | undefined): Array<string> => {
  if (!asset) {
    return []
  }

  if (asset instanceof EC2) {
    return [asset.id, ...asset.ebsIds]
  }

  return [asset.id]
}

export const restoreLastCleanSnapshotsScript = (snapshotIds: Array<string>) =>
  '#!/bin/bash\n' +
  '\n' +
  '# Set variables\n' +
  'SNAPSHOT_IDS=(' +
  snapshotIds.map((id) => `"${id}"`).join(', ') +
  ') # Array of snapshot IDs\n' +
  'NEW_INSTANCE_NAME="Restored-EC2-Instance"\n' +
  '\n' +
  '# Function to get the original EBS volume info from a snapshot\n' +
  'get_volume_info() {\n' +
  '    local snapshot_id=$1\n' +
  "    volume_info=$(aws ec2 describe-snapshots --snapshot-ids $snapshot_id --query 'Snapshots[0].[VolumeId,VolumeSize]' --output text)\n" +
  '    echo $volume_info\n' +
  '}\n' +
  '\n' +
  '# Function to get device mapping and availability zone from the original EBS volume\n' +
  'get_volume_details() {\n' +
  '    local volume_id=$1\n' +
  "    volume_details=$(aws ec2 describe-volumes --volume-ids $volume_id --query 'Volumes[0].[Attachments[0].[Device,AvailabilityZone]]' --output text)\n" +
  "    device_name=$(echo $volume_details | awk '{print $1}')\n" +
  "    availability_zone=$(aws ec2 describe-volumes --volume-ids $volume_id --query 'Volumes[0].[AvailabilityZone]' --output text | awk '{print $1}')\n" +
  '    \n' +
  '    echo $device_name $availability_zone\n' +
  '}\n' +
  '\n' +
  '# Function to get instance details (AMI ID, instance type, subnet ID, security group ID, IAM role, and key pair name)\n' +
  'get_instance_details() {\n' +
  '    local volume_id=$1\n' +
  "    volume_instance_id=$(aws ec2 describe-volumes --volume-ids $volume_id --query 'Volumes[0].Attachments[0].InstanceId' --output text)\n" +
  "    instance_details=$(aws ec2 describe-instances --instance-ids $volume_instance_id --query 'Reservations[0].Instances[0].[ImageId,InstanceType,SubnetId,SecurityGroups[0].GroupId,IamInstanceProfile.Arn,KeyName]' --output text)\n" +
  "    ami_id=$(echo $instance_details | awk '{print $1}')\n" +
  "    instance_type=$(echo $instance_details | awk '{print $2}')\n" +
  "    subnet_id=$(echo $instance_details | awk '{print $3}')\n" +
  "    security_group_id=$(echo $instance_details | awk '{print $4}')\n" +
  "    iam_role_arn=$(aws ec2 describe-instances --instance-ids $volume_instance_id  --query 'Reservations[0].Instances[0].[IamInstanceProfile.Arn]' --output text)\n" +
  "    key_name=$(echo $instance_details | awk '{print $6}')\n" +
  '\n' +
  '    echo $ami_id $instance_type $subnet_id $security_group_id $iam_role_arn $key_name\n' +
  '}\n' +
  '\n' +
  '# Step 1: Create EBS volumes from snapshots and gather original instance info\n' +
  'VOLUME_IDS=()\n' +
  'DEVICE_NAMES=()\n' +
  'VOLUME_SIZES=()\n' +
  'AVAILABILITY_ZONES=()\n' +
  'ORIGINAL_AMI_ID=""\n' +
  'ORIGINAL_INSTANCE_TYPE=""\n' +
  'SUBNET_ID=""\n' +
  'SECURITY_GROUP_ID=""\n' +
  'IAM_ROLE=""\n' +
  'KEY_NAME=""\n' +
  '\n' +
  'for snapshot_id in "${SNAPSHOT_IDS[@]}"; do\n' +
  '    echo "Getting original volume information for snapshot $snapshot_id..."\n' +
  '    volume_info=$(get_volume_info $snapshot_id)\n' +
  "    original_volume_id=$(echo $volume_info | awk '{print $1}')\n" +
  "    volume_size=$(echo $volume_info | awk '{print $2}')\n" +
  '    \n' +
  '    volume_details=$(get_volume_details $original_volume_id)\n' +
  "    device_name=$(echo $volume_details | awk '{print $1}')\n" +
  "    availability_zone=$(echo $volume_details | awk '{print $2}')\n" +
  '    \n' +
  '    instance_details=$(get_instance_details $original_volume_id)\n' +
  "    ami_id=$(echo $instance_details | awk '{print $1}')\n" +
  "    instance_type=$(echo $instance_details | awk '{print $2}')\n" +
  "    subnet_id=$(echo $instance_details | awk '{print $3}')\n" +
  "    security_group_id=$(echo $instance_details | awk '{print $4}')\n" +
  "    iam_role=$(echo $instance_details | awk '{print $5}')\n" +
  "    key_name=$(echo $instance_details | awk '{print $6}')\n" +
  '\n' +
  '    if [ -z "$original_volume_id" ] || [ -z "$volume_size" ] || [ -z "$device_name" ] || [ -z "$availability_zone" ]; then\n' +
  '        echo "Error: Could not retrieve information for snapshot $snapshot_id."\n' +
  '        exit 1\n' +
  '    fi\n' +
  '\n' +
  '    # Set original instance details if not already set\n' +
  '    if [ -z "$ORIGINAL_AMI_ID" ]; then\n' +
  '        ORIGINAL_AMI_ID=$ami_id\n' +
  '        ORIGINAL_INSTANCE_TYPE=$instance_type\n' +
  '        SUBNET_ID=$subnet_id\n' +
  '        SECURITY_GROUP_ID=$security_group_id\n' +
  '        IAM_ROLE=$iam_role\n' +
  '        KEY_NAME=$key_name\n' +
  '    fi\n' +
  '\n' +
  '    echo "Creating EBS volume from snapshot $snapshot_id in availability zone $availability_zone with size ${volume_size}GB..."\n' +
  '    new_volume_id=$(aws ec2 create-volume \n' +
  '        --snapshot-id $snapshot_id \n' +
  '        --volume-type gp2 \n' +
  '        --size $volume_size \n' +
  '        --availability-zone $availability_zone \n' +
  "        --output text --query 'VolumeId')\n" +
  '\n' +
  '    if [ $? -eq 0 ]; then\n' +
  '        echo "New volume $new_volume_id created successfully."\n' +
  '        VOLUME_IDS+=("$new_volume_id")\n' +
  '        DEVICE_NAMES+=("$device_name")\n' +
  '        VOLUME_SIZES+=("$volume_size")\n' +
  '        AVAILABILITY_ZONES+=("$availability_zone")\n' +
  '    else\n' +
  '        echo "Error creating volume from snapshot $snapshot_id. Exiting."\n' +
  '        exit 1\n' +
  '    fi\n' +
  'done\n' +
  '\n' +
  '# Step 2: Wait for all volumes to become available\n' +
  'echo "Waiting for all volumes to become available..."\n' +
  'for volume_id in "${VOLUME_IDS[@]}"; do\n' +
  '    aws ec2 wait volume-available --volume-ids $volume_id\n' +
  '    echo "Volume $volume_id is now available."\n' +
  'done\n' +
  '\n' +
  '# Step 3: Create a new EC2 instance using the original instance type, AMI ID, Subnet ID, Security Group, IAM Role (if present), and Key Pair Name (if available)\n' +
  'echo "Launching a new EC2 instance with AMI ID $ORIGINAL_AMI_ID and Instance Type $ORIGINAL_INSTANCE_TYPE..."\n' +
  '\n' +
  '# Base parameters for instance launch\n' +
  'INSTANCE_PARAMS="--image-id $ORIGINAL_AMI_ID --instance-type $ORIGINAL_INSTANCE_TYPE --security-group-ids $SECURITY_GROUP_ID --subnet-id $SUBNET_ID"\n' +
  '\n' +
  '# Add key name parameter if present\n' +
  'if [ -n "$KEY_NAME" ]; then\n' +
  '    INSTANCE_PARAMS="$INSTANCE_PARAMS --key-name $KEY_NAME"\n' +
  'fi\n' +
  '\n' +
  '# Add IAM instance profile parameter if IAM_ROLE is present\n' +
  'if [ "$IAM_ROLE" != "None" ]; then\n' +
  '    INSTANCE_PARAMS="$INSTANCE_PARAMS --iam-instance-profile Name=$IAM_ROLE"\n' +
  'fi\n' +
  '\n' +
  "NEW_INSTANCE_ID=$(aws ec2 run-instances $INSTANCE_PARAMS --output text --query 'Instances[0].InstanceId')\n" +
  '\n' +
  'if [ $? -eq 0 ]; then\n' +
  '    echo "New EC2 instance $NEW_INSTANCE_ID launched successfully."\n' +
  'else\n' +
  '    echo "Error launching EC2 instance. Exiting."\n' +
  '    exit 1\n' +
  'fi\n' +
  '\n' +
  'echo "Waiting for instance to be running..."\n' +
  'aws ec2 wait instance-running --instance-ids $NEW_INSTANCE_ID\n' +
  'echo "Instance $NEW_INSTANCE_ID is now running."\n' +
  '\n' +
  'echo "Stopping instance..."\n' +
  'aws ec2 stop-instances --instance-ids $NEW_INSTANCE_ID --output text\n' +
  'aws ec2 wait instance-stopped --instance-ids $NEW_INSTANCE_ID\n' +
  'echo "Instance $NEW_INSTANCE_ID is now stopped."\n' +
  '\n' +
  'echo "Detaching all volumes from instance $NEW_INSTANCE_ID..."\n' +
  "attached_volumes=$(aws ec2 describe-volumes --filters Name=attachment.instance-id,Values=$NEW_INSTANCE_ID --query 'Volumes[*].{ID:VolumeId}' --output text)\n" +
  'for volume_id in $attached_volumes; do\n' +
  '    echo "Detaching volume $volume_id from instance $NEW_INSTANCE_ID..."\n' +
  '    aws ec2 detach-volume --volume-id $volume_id --instance-id $NEW_INSTANCE_ID\n' +
  '    if [ $? -eq 0 ]; then\n' +
  '        echo "Detached volume $volume_id successfully."\n' +
  '    else\n' +
  '        echo "Error detaching volume $volume_id. Exiting."\n' +
  '        exit 1\n' +
  '    fi\n' +
  'done\n' +
  '\n' +
  '# Step 4: Attach the EBS volumes to the new instance\n' +
  'for i in "${!VOLUME_IDS[@]}"; do\n' +
  '    echo "Attaching volume ${VOLUME_IDS[$i]} to instance $NEW_INSTANCE_ID as ${DEVICE_NAMES[$i]}..."\n' +
  '    aws ec2 attach-volume \n' +
  '        --volume-id ${VOLUME_IDS[$i]} \n' +
  '        --instance-id $NEW_INSTANCE_ID \n' +
  '        --device ${DEVICE_NAMES[$i]}\n' +
  '    if [ $? -eq 0 ]; then\n' +
  '        echo "Volume ${VOLUME_IDS[$i]} attached successfully."\n' +
  '    else\n' +
  '        echo "Error attaching volume ${VOLUME_IDS[$i]}. Exiting."\n' +
  '        exit 1\n' +
  '    fi\n' +
  'done\n' +
  '\n' +
  'echo "Waiting for instance to be running..."\n' +
  'aws ec2 start-instances --instance-ids $NEW_INSTANCE_ID --output text\n' +
  'aws ec2 wait instance-running --instance-ids $NEW_INSTANCE_ID\n' +
  'echo "Instance $NEW_INSTANCE_ID is now running."\n' +
  '\n' +
  '# Step 5: Tag the instance\n' +
  'echo "Tagging the new EC2 instance with Name=$NEW_INSTANCE_NAME..."\n' +
  'aws ec2 create-tags --resources $NEW_INSTANCE_ID --tags Key=Name,Value=$NEW_INSTANCE_NAME\n' +
  '\n' +
  'echo "EC2 instance $NEW_INSTANCE_ID has been restored from snapshots ${SNAPSHOT_IDS[@]} and tagged as $NEW_INSTANCE_NAME."'

export const shouldPerformOnDemandAction = (asset: Asset | undefined) => {
  if (asset instanceof EC2) {
    return asset.state === EC2State.RUNNING || asset.state === EC2State.STOPPED
  }
  if (asset instanceof EBS) {
    return asset.state === EBSState.AVAILABLE || asset.state === EBSState.INUSE
  }
  if (asset instanceof S3Bucket) {
    return asset.state === S3BucketState.IN_USE
  }
  if (asset instanceof EFS) {
    return asset.state === EfsState.IN_USE
  }
  return false
}
