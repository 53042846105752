import Button from 'ui-v2/src/components/ui/inputs/button'
import { useGetCloudInformationLinkQuery } from 'ui-v2/src/hooks/queries/red-stack'
import { buildCloudFormationLink } from 'ui-v2/src/lib/helpers/data.helper'
import { useFormContext } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'

interface CloudFormationStackButtonProps {
  onCloudFormationClickHandler: any
  isCloudFormationLaunched: boolean
}

export const CloudFormationStackButton = ({
  onCloudFormationClickHandler,
  isCloudFormationLaunched,
}: CloudFormationStackButtonProps) => {
  const { getValues } = useFormContext<SourceFormData>()
  const { awsAccountId, awsRegionId } = getValues()
  const { data: cloudInformationLinkData, isLoading } =
    useGetCloudInformationLinkQuery(awsAccountId)

  const cloudFormationLink = buildCloudFormationLink(
    cloudInformationLinkData ?? '',
    awsRegionId
  )

  if (!cloudInformationLinkData || isLoading || isCloudFormationLaunched) {
    return (
      <Button variant="contained" isLoading={isLoading} disabled>
        Launch CloudFormation Stack
      </Button>
    )
  }

  return (
    <a href={cloudFormationLink} target="_blank" rel="noopener noreferrer">
      <Button
        variant="contained"
        isLoading={isLoading}
        onClick={() => {
          console.log('Button clicked')
          onCloudFormationClickHandler()
        }}
      >
        Launch CloudFormation Stack
      </Button>
    </a>
  )
}

export const ReviewElastioButton = () => {
  const { getValues } = useFormContext<SourceFormData>()
  const { awsAccountId } = getValues()
  const { data: cloudInformationLinkData } =
    useGetCloudInformationLinkQuery(awsAccountId)
  return (
    <Button
      variant="contained"
      color="inherit"
      component="a"
      href={cloudInformationLinkData}
      sx={{ textDecoration: 'none' }}
      {...(cloudInformationLinkData
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {})}
    >
      Review Elastio CFN
    </Button>
  )
}
