import { useRestClient } from 'ui-v2/src/hooks/rest-client'
import {
  AssetFilterParams,
  AssetFilterResponse,
} from 'ui-v2/src/lib/models/assets/filters-list'

export const useAssetFilterService = () => {
  const { isReady, isLoading, get } = useRestClient()

  const listAssetFilters = async (params: AssetFilterParams) => {
    if (!isReady) {
      throw new Error('REST client is not initialized')
    }

    const url = '/api/v1/get-filtered-data'
    return get<AssetFilterResponse>(url, params)
  }

  return {
    listAssetFilters,
    isReady,
    isLoading,
  }
}
