/* eslint-disable import/no-extraneous-dependencies */
import { NotificationRule } from 'blues-corejs/dist/models/notifications/rule'
import { useAlertsActiveRuleStore } from '../store/alerts-active-rule-store'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { useNavigate } from 'react-router-dom'
import { Box, Link } from '@mui/material'

interface Props {
  rule: NotificationRule
}

const AlertsCellName = ({ rule }: Props) => {
  const navigate = useNavigate()
  const { setActiveRule } = useAlertsActiveRuleStore()

  const handleClick = () => {
    setActiveRule(rule)
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.ALERTS}/${GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS.ALERTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.DETAIL_ALERT_RULE}`
    )
  }

  return (
    <Box
      component={Link}
      onClick={handleClick}
      sx={{
        textDecoration: 'none',
        cursor: 'pointer',
      }}
    >
      {rule.name}
    </Box>
  )
}

export default AlertsCellName
