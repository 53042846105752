import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress, Stack, useTheme } from '@mui/material'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import VolumeTable from '../volume-table'
import { RecoveryPointRow, ThreatStatus } from '../common/types'
import { getHealthDefaultValue } from '../../utils'
import EmptyContentComponent from '../common/empty-content-component'
import { ThemeMode } from '../../../../components/common/theme-switcher'
import {
  useGetScanJobByIdQuery,
  useListScanJobEntitiesQuery,
} from 'ui-v2/src/hooks/queries/scan-log'
import { getScanJobSource } from '../common/util'

const VolumeTableContainer = () => {
  const {
    assetId,
    recoveryPointId: scanJobId,
    scanJobId: recoveryPointScanJobId,
  } = useParams<{
    assetId: string | undefined
    recoveryPointId: string | undefined
    scanJobId: string | undefined
  }>()

  const theme = useTheme()

  const { data: scanJobEntitiesData, status: scanJobEntitiesQueryData } =
    useListScanJobEntitiesQuery(assetId, recoveryPointScanJobId ?? scanJobId)

  const { data: scanJobByIdData, status: scanJobByIdQueryStatus } =
    useGetScanJobByIdQuery(assetId, recoveryPointScanJobId ?? scanJobId)

  const entities: Array<RecoveryPointRow> = useMemo(() => {
    if (!scanJobEntitiesData) {
      return []
    }

    return scanJobEntitiesData
      .filter((entity) => entity.kind !== 'aws_ec2')
      .map((entity) => ({
        id: entity.id,
        health: getHealthDefaultValue(entity),
        name: entity.name ?? 'N/A',
        ransomware_status: entity.ransomware_status ?? ThreatStatus.NOT_COVERED,
        malware_status: entity.malware_status ?? ThreatStatus.NOT_COVERED,
        fs_check_status: entity.fs_check_status ?? ThreatStatus.NOT_COVERED,
        anomaly_status: entity.anomaly_status ?? ThreatStatus.NOT_COVERED,
        anomalies_count: entity.anomalies_count ?? 0,
        entity_kind: entity.entity_kind,
      }))
  }, [scanJobEntitiesData])

  const backupId = useMemo(() => {
    if (!scanJobByIdData) {
      return undefined
    }

    const backupScan = scanJobByIdData.scans.find(
      (scan) => scan.type === 'backup'
    )

    if (!backupScan) {
      return undefined
    }

    if (backupScan.target.kind_tag === 'backup') {
      return backupScan.target.id
    }

    return undefined
  }, [scanJobByIdData])

  if (
    scanJobEntitiesQueryData === 'pending' ||
    scanJobByIdQueryStatus === 'pending'
  ) {
    return (
      <Box
        sx={{
          height: '100%',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={24} sx={{ color: 'grey.500' }} />
      </Box>
    )
  }

  if (
    scanJobEntitiesQueryData === 'error' ||
    scanJobByIdQueryStatus === 'error'
  ) {
    return (
      <ErrorDisplay message="Something went wrong while retrieving threats data, please try again later." />
    )
  }

  if (entities.length === 0) {
    return (
      <Stack
        sx={{
          height: '100%',
          borderRadius: '8px',
          border: '1px solid transparent',
          background:
            theme.palette.mode === ThemeMode.DARK ? '#152635' : '#F6F9FC',
        }}
      >
        <Stack
          sx={{
            height: '100%',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            padding: '16px',
            background:
              theme.palette.mode === ThemeMode.DARK ? '#152635' : '#F6F9FC',
          }}
          spacing={1}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.secondary,
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              gap: 1,
            }}
          >
            <EmptyContentComponent atRisk={false} isHealthy={true} />
          </Box>
        </Stack>
      </Stack>
    )
  }

  return (
    <VolumeTable
      data={entities}
      source={getScanJobSource(
        scanJobByIdData.type,
        scanJobByIdData.backupType
      )}
      backupKind={scanJobByIdData.backupKind}
      timestamp={scanJobByIdData.timestamp}
      backupId={backupId}
    />
  )
}

export default VolumeTableContainer
