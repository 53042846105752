import React from 'react'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import Label from '@mui/material/FormLabel'
import { Controller, useFormContext } from 'react-hook-form'

const OPERATOR_AND = 0
const OPERATOR_OR = 1

interface SwitchControllerOwnProps {
  name: string
  defaultValue?: string | number
  leftLabel?: string
  rightLabel?: string
  onChange?: (value: string | number) => void
}

const SwitchController = ({
  name,
  defaultValue = OPERATOR_AND,
  leftLabel = '',
  rightLabel = '',
  onChange,
}: SwitchControllerOwnProps) => {
  const methods = useFormContext()

  return (
    <Controller
      name={name}
      control={methods.control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Box
          className="flex items-center space-x-4"
          sx={{ alignItems: 'center' }}
        >
          {leftLabel && (
            <Label
              htmlFor={name}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {leftLabel}
            </Label>
          )}
          <Switch
            color="primary"
            id={name}
            checked={field.value === OPERATOR_OR}
            onChange={(event) => {
              const newValue = event.target.checked ? OPERATOR_OR : OPERATOR_AND
              field.onChange(newValue)
              onChange?.(newValue)
            }}
          />
          {rightLabel && (
            <Label
              htmlFor={name}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {rightLabel}
            </Label>
          )}
        </Box>
      )}
    />
  )
}

export default SwitchController
