import { useEffect } from 'react'
import SiteLoader from 'ui-v2/src/components/common/site-loader'
import { useAuth0 } from '@auth0/auth0-react'

const Unauthenticated = () => {
  const { loginWithRedirect } = useAuth0()

  const goToLoginPage = async () => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
        },
      })
    } catch (error) {
      console.error('Login failed:', error)
    }
  }

  useEffect(() => {
    goToLoginPage()
  }, [])

  return <SiteLoader text="Redirecting to login page..." />
}

export default Unauthenticated
