export const BASE_ROUTE_SEGMENTS = {
  DASHBOARD: 'dashboard',
  ASSETS: 'assets',
  ASSET: 'asset',
  INCIDENTS: 'incidents',
  MONITOR: 'monitor',
  REPORTS: 'reports',
  TENANT: 'tenant',
  SETTINGS: 'settings',
  ACCOUNTS_SETTINGS: 'account-and-settings',
  JOBS: 'jobs',
  SOURCES: 'sources',
  POLICIES: 'policies',
  ONBOARDING: 'onboarding',
} as const

export const TENANT_ROUTE_SEGMENTS = {
  ACCESS_DENIED: 'access-denied',
  UNAUTHENTICATED: 'unauthenticated',
} as const

export const ASSETS_ROUTE_SEGMENTS = {
  EC2: 'ec2',
  EBS: 'ebs',
  S3: 's3',
  EFS: 'efs',
  SERVER: 'server',
  GENERIC_HOST: 'generic-host',
} as const

export const ASSETS_DETAILS_ROUTE_SEGMENTS = {
  RECOVERY_POINTS: 'recovery-points',
  SCAN_LOG: 'scan-log',
} as const

export const ASSETS_DRAWER_ROUTE_SEGMENTS = {
  RESTORE_LAST_CLEAN_BACKUP: 'restore-last-clean-backup',
} as const

export const SETTINGS_ROUTE_SEGMENTS = {
  GENERAL: 'general',
  USERS_AND_ACCESS: 'users-access',
  ALERTS: 'alerts',
  WEBHOOKS: 'webhooks',
  SOURCES: 'sources',
  POLICIES: 'policies',
} as const

export const GENERAL_SETTINGS_ROUTE_SEGMENTS = {
  TENANT: 'tenant',
  ACCOUNT_PROFILE: 'account-profile',
  ACCOUNT_SECURITY: 'account-security',
  API_ACCESS: 'api-access',
  ALERTS: 'alerts',
} as const

export const GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS = {
  ACTIVE_DEPLOYMENTS: 'active-deployments',
  ACTIVITY_LOG: 'activity-log',
} as const

export const GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS = {
  ALERTS: 'alerts',
  EVENTS: 'event',
  LOG: 'log',
} as const

export const USERS_ACCESS_SETTINGS_ROUTE_SEGMENTS = {
  USERS: 'users',
  USER_GROUPS: 'user-groups',
  ACCESS_POLICIES: 'access-policies',
} as const

export const SETTINGS_ACTIONS_ROUTE_SEGMENTS = {
  ADD_USER: 'add-user',
  ADD_WEBHOOK: 'add-webhook',
  EDIT_WEBHOOK: 'edit-webhook',
  ADD_ALERT_RULE: 'add-alert-rule',
  UPDATE_ALERT_RULE: 'update-alert-rule',
  DETAIL_ALERT_RULE: 'detail-alert-rule',
  CREATE_POLICY: 'create-policy',
  EDIT_POLICY: 'edit-policy',
  LINK_SOURCE: 'cloud-configure-deployment',
  UPDATE_PROTECTED_ACCOUNT: 'update-protected-account',
  UNLINK_PROTECTED_ACCOUNT: 'unlink-protected-account',
  LINK_PROTECTED_ACCOUNT: 'link-protected-account',
  UNLINK_CLOUD_CONNECTOR: 'unlink-cloud-connector',
  SCAN_CLUSTER: 'scan-cluster',
  UPGRADE_CLOUD_CONNECTOR: 'upgrade-cloud-connector',
  MODIFY_CLOUD_CONNECTOR: 'modify-cloud-connector',
  BIND_LINKED_PROTECTED_ACCOUNT: 'bind-linked-protected-account',
} as const

export const DASHBOARD_ROUTE_SEGMENTS = {
  ASSET_THREATS: 'assets-threats',
  INVENTORY_TABLES: 'inventory-tables',
  ACTIVE_THREATS: 'active-threats',
  ASSET_VULNERABILITIES: 'assets-vulnerabilities',
  ENCRYPTION_ACTIVITY: 'encryption-activity',
} as const

export const ACTIVE_THREATS_ROUTE_SEGMENTS = {
  AWS: 'aws',
} as const

export const POLICIES_SETTINGS_ROUTE_SEGMENTS = {
  PROTECTION_POLICIES: 'protection',
  RETENTION_POLICIES: 'retention',
} as const

export const ALERTS_SETTINGS_ROUTE_SEGMENTS = {
  ALERT_RULES: 'alert-rules',
} as const

export const MONITOR_ROUTE_SEGMENTS = {
  CLOUD_CONNECTOR: 'cloud-connector',
  DEPLOYMENT: 'deployment',
  SYSTEM: 'system',
} as const
