import { useQuery } from '@tanstack/react-query'
import { useAssetFilterService } from '../../rest-service/use-asset-filters-service'
import { AssetFilterParams } from 'ui-v2/src/lib/models/assets/filters-list'

export function useListAssetFilterQuery(params: AssetFilterParams) {
  const {
    listAssetFilters,
    isReady,
    isLoading: isClientLoading,
  } = useAssetFilterService()

  return useQuery({
    queryKey: ['asset', params],
    queryFn: async () => {
      if (!params) {
        throw new Error('assetType is required')
      }
      return listAssetFilters(params)
    },
    enabled: params && isReady && !isClientLoading,
  })
}
