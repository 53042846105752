import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ListS3Filters } from 'ui-v2/src/lib/models/assets'
import EnumHelper from 'ui-v2/src/lib/helpers/enum.helper'
import { AssetsFilters, PolicyCoverageState } from 'ui-v2/src/lib/models/assets'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import {
  ASSET_FILTERS_MAP,
  NAME_TAG_KEY,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { S3 } from 'ui-v2/src/lib/grpc'
import { LastScanState } from 'ui-v2/src/lib/models/assets/common'
import { HealthState } from 'blue-stack-libs/api-grpc-libs/js/api/search/filters/common_filter_pb'

const useAssetsS3Filters = (): AssetsFilters<ListS3Filters> => {
  const [pageSize, setPageSize] = useState<number>(
    TABLE_ROWS_PER_PAGE_OPTIONS[0]
  )
  const [pageToken, setPageToken] = useState<string | undefined>(undefined)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setPageToken(undefined)
  }, [searchParams])

  return useMemo(() => {
    return {
      filters: {
        pageSize,
        pageToken,
        search: searchParams.get(SEARCH_PARAM) ?? undefined,
        assetIdList: searchParams
          .get(ASSET_FILTERS_MAP.ASSET_ID)
          ?.split(',')
          .filter(Boolean),
        bucketStatesList: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.BUCKET_STATE)?.split(',') ?? [],
          S3.State
        ),
        tagsList: searchParams
          .get(ASSET_FILTERS_MAP.ASSET_NAME)
          ?.split(',')
          .filter(Boolean)
          .map((name) => ({
            key: NAME_TAG_KEY,
            value: name,
          })),
        regionsList: searchParams
          .get(ASSET_FILTERS_MAP.REGION)
          ?.split(',')
          .filter(Boolean),
        accountIdsList: searchParams
          .get(ASSET_FILTERS_MAP.ACCOUNT_ID)
          ?.split(',')
          .filter(Boolean),
        protectionPolicyCoverageStatesList: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.POLICY_COVERAGE)?.split(',') ?? [],
          PolicyCoverageState
        ),
        health: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.HEALTH)?.split(',') ?? [],
          HealthState
        ),
        lastScan: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.LAST_SCAN)?.split(',') ?? [],
          LastScanState
        ),
      },
      setPageSize,
      setPageToken,
    }
  }, [pageSize, pageToken, searchParams, setPageSize, setPageToken])
}
export default useAssetsS3Filters
