import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import {
  ASSET_FILTERS_MAP,
  POLICY_COVERAGE_STATE_MAP,
} from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const PolicyCoverageFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const policy =
    searchParams.get(ASSET_FILTERS_MAP.POLICY_COVERAGE)?.split(',') || []

  const policyOptions = useMemo(() => {
    return Object.entries(POLICY_COVERAGE_STATE_MAP).map(([key, value]) => ({
      label: value,
      value: key.toString(),
    }))
  }, [])

  const handlePolicyChange = (
    newPolicyList: Array<MultiSelectOption['value']>
  ) => {
    if (newPolicyList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.POLICY_COVERAGE)
    } else {
      searchParams.set(
        ASSET_FILTERS_MAP.POLICY_COVERAGE,
        newPolicyList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={policyOptions}
      value={policy}
      onChange={handlePolicyChange}
      label={
        <SelectLabelText
          selectedValueLength={policy?.length}
          optionsLength={policyOptions?.length}
          text="Policy"
        />
      }
      width={180}
    />
  )
}

export default PolicyCoverageFilter
