/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { useSearchParams } from 'react-router-dom'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { DEPLOYMENT_FILTER_MAP } from 'ui-v2/src/lib/constants/monitor.constant'
import { getHumanReadableTenantJobKind } from 'ui-v2/src/lib/transformers/tenant-jobs'
import { SYSTEM_JOB_KINDS } from 'ui-v2/src/lib/transformers/tenant-jobs/tenant-job-kind'

const JobKindDropdown = ({ kindsList }: JobKindDropdownProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const jobTypeList =
    searchParams.get(DEPLOYMENT_FILTER_MAP.JOB)?.split(',') || []

  const options = useMemo(
    () =>
      kindsList
        .filter((kind) => SYSTEM_JOB_KINDS.includes(kind))
        .map((kind) => ({
          label: getHumanReadableTenantJobKind(kind),
          value: kind.toString(),
        })),
    [kindsList]
  )

  const handleJobTypeChange = (
    newInstanceIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newInstanceIdList.length === 0) {
      searchParams.delete(DEPLOYMENT_FILTER_MAP.JOB)
    } else {
      searchParams.set(DEPLOYMENT_FILTER_MAP.JOB, newInstanceIdList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      value={jobTypeList}
      options={options}
      onChange={handleJobTypeChange}
      width={200}
      label={
        <SelectLabelText
          selectedValueLength={jobTypeList?.length}
          optionsLength={options?.length}
          text="Job Type"
        />
      }
    />
  )
}
interface JobKindDropdownProps {
  kindsList: Array<TenantJobKind>
}
export default JobKindDropdown
