import { create } from 'zustand'

import { RuleFormValues } from './types'
import { defaultValues } from './consts'

interface RuleDetailsActions {
  setRuleDetailsData: (data: RuleFormValues) => void
  setAllKinds: (value: boolean) => void
  setRuleId: (value: string) => void
  resetStore: () => void
}

export interface RuleDetailsState {
  ruleDetailsData: RuleFormValues
  allKinds: boolean
  ruleId: string
}

export type RuleDetailsDataStore = RuleDetailsActions & RuleDetailsState

const INITIAL_STATE: RuleDetailsState = {
  ruleDetailsData: { ...defaultValues },
  allKinds: false,
  ruleId: '',
}

const useRuleDetailsStore = create<RuleDetailsDataStore>((set) => ({
  ...INITIAL_STATE,
  setRuleDetailsData: (data) => set({ ruleDetailsData: data }),
  setAllKinds: (value) => set({ allKinds: value }),
  setRuleId: (value) => set({ ruleId: value }),
  resetStore: () => set({ ...INITIAL_STATE }),
}))

export const resetRuleDetailsStore = () => {
  useRuleDetailsStore.getState().resetStore()
}

export const useRuleDetailsState = () =>
  useRuleDetailsStore((state) => ({
    ruleDetailsData: state.ruleDetailsData,
    allKinds: state.allKinds,
    ruleId: state.ruleId,
  }))

export const useRuleDetailsActions = () =>
  useRuleDetailsStore((state) => ({
    setRuleDetailsData: state.setRuleDetailsData,
    setAllKinds: state.setAllKinds,
    setRuleId: state.setRuleId,
    resetStore: state.resetStore,
  }))
