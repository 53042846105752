import { useQuery } from '@tanstack/react-query'
import { useThreatsTrendHistoryService } from '../../rest-service'
import { ThreatsTrendPayload } from 'ui-v2/src/lib/models/anomalies/threats-trend'

export function useGetThreatsTrendHistoryQuery(payload: ThreatsTrendPayload) {
  const {
    getThreatTrendsHistory,
    isReady,
    isLoading: isClientLoading,
  } = useThreatsTrendHistoryService()

  return useQuery({
    queryKey: ['threats-trend-history', payload],
    queryFn: async () => {
      return getThreatTrendsHistory(payload)
    },
    enabled: isReady && !isClientLoading,
    refetchOnWindowFocus: false,
  })
}
