/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Box, Stack } from '@mui/material'
import LabeledValue from '../common/labeled-value'
import { Asset } from 'blues-corejs/dist'
import StartScan from './start-scan'
import { ScheduleScanAsset } from 'ui-v2/src/lib/models/assets'
import { shouldPerformOnDemandAction } from '../../utils'

type SuccessContentProps = {
  asset: Asset | undefined
  assetKind: string | undefined
  scanResult: string
}

const SuccessContent: React.FC<SuccessContentProps> = ({
  asset,
  scanResult,
  assetKind,
}) => {
  const isAssetScannable = shouldPerformOnDemandAction(asset)
  return (
    <Stack spacing={0.5}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        <LabeledValue label="Latest Scan" value={scanResult} />
        {isAssetScannable ? (
          <StartScan asset={asset as ScheduleScanAsset} />
        ) : null}
      </Box>
      <LabeledValue label="Scan Type" value={assetKind ?? '-'} />
    </Stack>
  )
}

export default SuccessContent
