import React from 'react'
import { useSearchParams } from 'react-router-dom'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'

const FilesFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedFileList = searchParams.get('suppressed')?.split(',') || []

  const handleFilesFilterChange = (
    newFilterValue: Array<MultiSelectOption['value']>
  ) => {
    if (newFilterValue.length === 0) {
      searchParams.delete('suppressed')
    } else {
      searchParams.set('suppressed', newFilterValue.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={[
        {
          label: 'Unsuppressed',
          value: 'false',
        },
        {
          label: 'Suppressed',
          value: 'true',
        },
      ]}
      value={selectedFileList}
      onChange={handleFilesFilterChange}
      label={
        <SelectLabelText
          selectedValueLength={0}
          optionsLength={1}
          text="Files:"
        />
      }
      width={200}
      size="xxs"
    />
  )
}

export default FilesFilter
