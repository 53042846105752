import React from 'react'
import { useAssetDetails } from '../common/use-asset-details'
import LoadingContent from '../common/loading'
// import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import WidgetCard from '../common/widget-card'
import LabeledValue from '../common/labeled-value'
import { isAwsAsset } from 'ui-v2/src/lib/helpers/assets.helper'
import { Stack } from '@mui/material'
import { usePlanInfoQuery } from 'ui-v2/src/hooks/queries/pechkin'
import { getFrequencyDisplay, getScanTypes } from './util'

const PolicyDetailsWidget = () => {
  // const { asset, isAssetsDataLoading, isAssetsDataError } = useAssetDetails()
  const { asset, isAssetsDataLoading } = useAssetDetails()

  const policyId =
    !!asset && isAwsAsset(asset) ? asset.backupPolicies[0] : undefined

  const {
    data: policyData,
    isLoading: isPolicyDataLoading,
    // isError: isPolicyDataError,
  } = usePlanInfoQuery(policyId ?? '')

  if (!!asset && !isAwsAsset(asset)) {
    return null
  }

  if (isAssetsDataLoading || isPolicyDataLoading) {
    return (
      <WidgetCard>
        <LoadingContent />
      </WidgetCard>
    )
  }

  // if (isAssetsDataError || isPolicyDataError) {
  //   return (
  //     <WidgetCard>
  //       <ErrorDisplay message="Failed to load widget, please try again later." />
  //     </WidgetCard>
  //   )
  // }

  const policyName = policyData ? policyData.policyName : ''
  const frequency = policyData ? getFrequencyDisplay(policyData.schedule) : ''
  const scanSettings = policyData ? getScanTypes(policyData) : ''

  return (
    <WidgetCard>
      <Stack spacing={0.5}>
        <LabeledValue label="Policy" value={policyName} />
        <LabeledValue label="Policy Frequency" value={frequency} />
        <LabeledValue label="Policy Scan Settings" value={scanSettings} />
      </Stack>
    </WidgetCard>
  )
}

export default PolicyDetailsWidget
