import React from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const ThreatsFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedInsiderThreatsList = searchParams.get('kind')?.split(',') || []

  const handleThreatsFilterChange = (
    newFilterValue: Array<MultiSelectOption['value']>
  ) => {
    if (newFilterValue.length === 0) {
      searchParams.delete('kind')
    } else {
      searchParams.set('kind', newFilterValue.join(','))
    }

    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={[
        {
          label: 'Insider Threats',
          value: 'Insider Threats',
        },
        {
          label: 'Malware',
          value: 'malware',
        },
        {
          label: 'Ransomware',
          value: 'ransomware',
        },
      ]}
      value={selectedInsiderThreatsList}
      onChange={handleThreatsFilterChange}
      label={
        <SelectLabelText
          selectedValueLength={0}
          optionsLength={1}
          text="Threats:"
        />
      }
      width={200}
      size="xxs"
    />
  )
}

export default ThreatsFilter
