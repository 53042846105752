/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import { UserProfile } from 'blues-corejs/dist/models/index'
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material'
import Button from 'ui-v2/src/components/ui/inputs/button'
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Delete'
import { WebhooksListItem } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'
import { SelectChangeEvent } from '@mui/material/Select'
import { buttonStyle, InputField, MenuItemsStyle, SelectStyle } from './styles'

interface RuleDeliveryChannelsProps {
  emailUsers: Array<string>
  setEmailUsers: (v: Array<string>) => void
  userProfilesList?: Array<UserProfile>
  selectedWebhooks: Array<string>
  setSelectedWebhooks: (v: Array<string>) => void
  webhooksList?: Array<WebhooksListItem.AsObject>
  slackEnabled: boolean
  setSlackEnabled: (v: boolean) => void
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const RuleDeliveryChannels = ({
  emailUsers,
  setEmailUsers,
  userProfilesList,
  selectedWebhooks,
  setSelectedWebhooks,
  webhooksList,
  slackEnabled,
  setSlackEnabled,
}: RuleDeliveryChannelsProps) => {
  const [emailSelectOpen, setEmailSelectOpen] = useState(false)
  const [manualEmail, setManualEmail] = useState('')
  const [webhookOpen, setWebhookOpen] = useState(false)
  const [webhookSearchTerm, setWebhookSearchTerm] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const consoleUsers = userProfilesList?.map((user) => user.email) || []

  const filteredConsoleUser = consoleUsers?.filter((email: string) =>
    email.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const webhooks = webhooksList?.map((webhook) => webhook.name) || []
  const filteredWebhooks = webhooks.filter((webhook) =>
    webhook.toLowerCase().includes(webhookSearchTerm.toLowerCase())
  )

  const handleEmailChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as Array<string>
    if (value.length === 1 && value[0] === undefined) {
      return
    }
    setEmailUsers(value)
  }

  const handleAddManualEmail = () => {
    if (manualEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(manualEmail)) {
      setEmailUsers([...emailUsers, manualEmail])
      setManualEmail('')
    }
  }

  const handleRemoveEmail = (email: string) => {
    setEmailUsers(emailUsers.filter((e) => e !== email))
  }

  const handleWebhookChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as Array<string>
    if (value.length === 1 && value[0] === undefined) {
      return
    }

    setSelectedWebhooks(value)
  }

  const renderSelectValue = (selected: unknown) => {
    const selectedValues = selected as Array<string>
    return selectedValues.join(', ')
  }

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 3 }}>
        Select delivery channels. This can be a combination of Email, Webhook
        and Slack.
      </Typography>

      <Typography variant="subtitle2" sx={{ mb: '5px' }}>
        Email Users
      </Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <SelectStyle
          multiple
          size="small"
          open={emailSelectOpen}
          onOpen={() => setEmailSelectOpen(true)}
          onClose={() => setEmailSelectOpen(false)}
          value={emailUsers}
          onChange={handleEmailChange}
          renderValue={renderSelectValue}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
              },
            },
            MenuListProps: {
              style: {
                padding: 0,
                background: 'background.paper',
              },
            },
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              bgcolor: 'background.paper',
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                p: '5px 8px ',
                background: 'background.paper',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 1.5,
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  {emailUsers.length} Selected
                </Typography>
                <Button
                  size="small"
                  onClick={() => setEmailUsers(consoleUsers)}
                  variant="contained"
                  color="inherit"
                  sx={buttonStyle}
                >
                  All
                </Button>
                <Button
                  size="small"
                  onClick={() => setEmailUsers([])}
                  variant="contained"
                  color="inherit"
                  sx={buttonStyle}
                >
                  Clear
                </Button>
                <Box />
                <Button
                  size="small"
                  onClick={() => setEmailSelectOpen(false)}
                  variant="contained"
                  sx={{
                    ...buttonStyle,
                    ml: 'auto',
                  }}
                >
                  Done
                </Button>
              </Box>
              <InputField
                size="small"
                fullWidth
                placeholder="Search events..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 1 }}
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Box>
          </Box>

          {filteredConsoleUser.map((user) => (
            <MenuItem key={user} value={user} sx={MenuItemsStyle}>
              <Typography>{user}</Typography>
            </MenuItem>
          ))}
        </SelectStyle>
      </FormControl>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          mb: 2,
        }}
      >
        <InputField
          fullWidth
          size="small"
          placeholder="Add non-Console email addresses"
          value={manualEmail}
          onChange={(e) => setManualEmail(e.target.value)}
          error={manualEmail !== '' && !emailRegex.test(manualEmail)}
          helperText={
            manualEmail !== '' && !emailRegex.test(manualEmail)
              ? 'Please enter a valid email address'
              : ''
          }
        />
        <Button
          variant="contained"
          onClick={handleAddManualEmail}
          disabled={!manualEmail || !emailRegex.test(manualEmail)}
          sx={buttonStyle}
        >
          Add
        </Button>
      </Box>

      {emailUsers.length > 0 && (
        <Box
          sx={{
            mb: 4,
            maxHeight: '300px',
            overflowY: 'auto',
          }}
        >
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Recipients ({emailUsers.length})
          </Typography>
          {emailUsers.map((email) => (
            <Box
              key={email}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1,
                '&:hover': {
                  bgcolor: 'action.hover',
                },
              }}
            >
              <Typography>{email}</Typography>
              <IconButton size="small" onClick={() => handleRemoveEmail(email)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      <Typography variant="subtitle2" sx={{ mb: '5px' }}>
        Webhooks
      </Typography>
      <FormControl fullWidth>
        <SelectStyle
          multiple
          open={webhookOpen}
          onOpen={() => setWebhookOpen(true)}
          onClose={() => setWebhookOpen(false)}
          size="small"
          value={selectedWebhooks}
          onChange={handleWebhookChange}
          renderValue={renderSelectValue}
          sx={{ mb: 1 }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
              },
            },
            MenuListProps: {
              style: {
                padding: 0,
                background: 'background.paper',
              },
            },
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              bgcolor: 'background.paper',
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                p: '5px 8px ',
                background: 'background.paper',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 1.5,
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  {selectedWebhooks.length} Selected
                </Typography>
                <Button
                  size="small"
                  onClick={() => setSelectedWebhooks(webhooks)}
                  variant="contained"
                  color="inherit"
                  sx={buttonStyle}
                >
                  All
                </Button>
                <Button
                  size="small"
                  onClick={() => setSelectedWebhooks([])}
                  variant="contained"
                  color="inherit"
                  sx={buttonStyle}
                >
                  Clear
                </Button>
                <Box />
                <Button
                  size="small"
                  onClick={() => setWebhookOpen(false)}
                  variant="contained"
                  sx={{
                    ...buttonStyle,
                    ml: 'auto',
                  }}
                >
                  Done
                </Button>
              </Box>
            </Box>
            <InputField
              size="small"
              fullWidth
              placeholder="Search webhooks..."
              value={webhookSearchTerm}
              onChange={(e) => setWebhookSearchTerm(e.target.value)}
              sx={{ mb: 1 }}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Box>
          {filteredWebhooks.map((webhook) => (
            <MenuItem key={webhook} value={webhook} sx={MenuItemsStyle}>
              {webhook}
            </MenuItem>
          ))}
        </SelectStyle>
      </FormControl>
      <Typography variant="subtitle2">Slack Integration</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={slackEnabled}
            onChange={(e) => setSlackEnabled(e.target.checked)}
          />
        }
        componentsProps={{
          typography: {
            fontSize: '14px',
          },
        }}
        label="Enable"
      />
    </>
  )
}

export default RuleDeliveryChannels
