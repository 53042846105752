import { useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import { useListAssetFilterQuery } from 'ui-v2/src/hooks/queries/assets/filters-list'

const AccountIdFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const account =
    searchParams.get(ASSET_FILTERS_MAP.ACCOUNT_ID)?.split(',') || []

  const { assetKind } = useParams<{ assetKind: string | undefined }>()
  const { data: filterData } = useListAssetFilterQuery({ asset: assetKind })

  const awsAccountIdOptions = useMemo(() => {
    if (!filterData || !filterData.data.data.account_id) {
      return []
    }

    return filterData.data.data.account_id.map((awsAccountId) => ({
      value: awsAccountId.account_id,
      label: awsAccountId.account_id,
    }))
  }, [filterData])

  const handleAwsAccountIdChange = (
    newAccountIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newAccountIdList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.ACCOUNT_ID)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.ACCOUNT_ID, newAccountIdList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={awsAccountIdOptions}
      value={account}
      onChange={handleAwsAccountIdChange}
      label={
        <SelectLabelText
          selectedValueLength={account?.length}
          optionsLength={awsAccountIdOptions?.length}
          text="Account"
        />
      }
      width={129}
    />
  )
}

export default AccountIdFilter
