import { UpdateNotificationRuleRequest } from '../../grpc'
import { UpdateNotificationRuleCriteria } from './types'
import {
  createNotificationChannels,
  createRuleCriteria,
  createRuleMode,
  createRuleStatus,
} from './utils'

export function buildUpdateNotificationRuleRequest({
  name,
  description,
  mode,
  status,
  criteria,
  channels,
  ruleId,
}: UpdateNotificationRuleCriteria): UpdateNotificationRuleRequest {
  const request = new UpdateNotificationRuleRequest()

  request.setId(ruleId)
  request.setName(name)

  if (description) {
    request.setDescription(description)
  }

  const ruleCriteriaPB = createRuleCriteria(criteria)

  const ruleModePB = createRuleMode(mode)

  const notificationChannelsPB = createNotificationChannels(channels)

  request.setMode(ruleModePB)
  request.setCriteria(ruleCriteriaPB)
  request.setChannels(notificationChannelsPB)
  request.setStatus(createRuleStatus(status))

  return request
}
