/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import { Box, Button, Stack } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AssetDrawerDetails from './details'
import AssetsDrawerActions from './actions'
import {
  useAssetsListByIdQuery,
  useAssetsLiveQuery,
} from 'ui-v2/src/hooks/queries/assets'
import {
  getAsset,
  getAssetDetails,
  isAwsAsset,
} from 'ui-v2/src/lib/helpers/assets.helper'

import { useAssetItemsQuery } from 'ui-v2/src/hooks/queries/assets/asset-items'
import { GenericHost } from 'blues-corejs/dist'
import { useListAnomaliesQuery } from 'ui-v2/src/hooks/queries/entropy-detection'
import { getDateRange } from 'ui-v2/src/lib/helpers/time.helper'
import { useAssetsListScansQuery } from 'ui-v2/src/hooks/queries/scans'
import { useAssetsListBackupsQuery } from 'ui-v2/src/hooks/queries/backups'
import { extractScanResults } from 'ui-v2/src/lib/helpers/scans.helper'
import { getLatestBackupMapFromBackups } from 'ui-v2/src/lib/helpers/backups.helper'
import InfectedAssets from './infected-assets'
import { Grid2 } from '@mui/material'
import InfectedBackupProvider from './infected-backup-provider'
import Link from 'ui-v2/src/components/ui/data-display/link'
import DownloadReports from './infected-assets/download-reports'

const AssetsDetailsDrawer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { assetId } = useParams()

  const {
    data: assetsData,
    isLoading: isAssetsDataLoading,
    isError: isAssetsDataError,
  } = useAssetsListByIdQuery(assetId ? [assetId] : [])

  const {
    data: assetsLiveData,
    isLoading: isAssetsLiveDataLoading,
    isError: isAssetsLiveDataError,
  } = useAssetsLiveQuery()

  const asset = useMemo(() => {
    if (!assetsData?.assetsList?.[0] || !assetsData.assetsList.length) {
      return undefined
    }
    return getAsset(assetsData?.assetsList?.[0])
  }, [assetsData])

  const { data: assetsListScans, isLoading: isLoadingAssetsListScans } =
    useAssetsListScansQuery(
      {
        assetIdList: asset ? [asset.id] : [],
        pageSize: 1,
      },
      {
        enabled: !!asset,
      }
    )

  const { data: assetsListBackups, isLoading: isLoadingAssetsListBackups } =
    useAssetsListBackupsQuery(
      {
        assetIdList: asset ? [asset.id] : [],
        pageSize: 1,
      },
      {
        enabled: !!asset,
      }
    )

  const { data: assetItemsData, isLoading: isAssetItemsDataLoading } =
    useAssetItemsQuery(
      {
        assetIdList: asset?.id ? [asset.id] : undefined,
        pageSize: 1000,
      },
      { enabled: !!asset && asset instanceof GenericHost }
    )

  const { data: assetAnomaliesData } = useListAnomaliesQuery(
    {
      assetIdsList: asset?.id ? [asset.id] : [],
      ...getDateRange(365),
    },
    {
      enabled: !!asset && isAwsAsset(asset),
    }
  )

  const assetDetails = useMemo(() => {
    if (
      !asset ||
      !assetsData ||
      !assetsData.assetsList.length ||
      !assetsLiveData ||
      !assetsListScans ||
      !assetsListBackups ||
      !assetsData?.assetsList?.[0]
    ) {
      return undefined
    }

    const scans = extractScanResults(assetsListScans.scansRunList)
    const backups = getLatestBackupMapFromBackups(asset.id, assetsListBackups)

    return getAssetDetails({
      asset: assetsData?.assetsList?.[0],
      assetsLiveData,
      assetItemsData,
      scans,
      backups,
    })
  }, [
    asset,
    assetsData,
    assetsLiveData,
    assetItemsData,
    assetsListScans,
    assetsListBackups,
  ])

  const isLoading =
    isAssetsDataLoading ||
    isAssetsLiveDataLoading ||
    isAssetItemsDataLoading ||
    isLoadingAssetsListScans ||
    isLoadingAssetsListBackups

  const isError = isAssetsDataError || isAssetsLiveDataError

  const isEmpty =
    !isLoading && !isError && !!assetsData && !assetsData.assetsList.length

  const closeDrawer = () => {
    const currentPath = location.pathname
    const newPath = currentPath.split('/').slice(0, -1).join('/')
    const searchParams = location.search
    navigate(newPath + searchParams)
  }

  return (
    <Drawer
      open={!!assetId}
      onClose={closeDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: 1300,
        },
      }}
    >
      {/*<DrawerHeader*/}
      {/*  padding={'0 16px'}*/}
      {/*  title="Asset Details"*/}
      {/*  handleCloseDrawer={closeDrawer}*/}
      {/*/>*/}
      <DrawerContent isLoading={isLoading} isError={isError} isEmpty={isEmpty}>
        <Stack spacing={3} direction="column" flexWrap={'wrap'} paddingX={2.5}>
          <Grid2 container columnSpacing={4}>
            {assetDetails ? (
              <AssetDrawerDetails
                assetDetails={assetDetails}
                asset={asset!}
                data={assetAnomaliesData?.anomaliesList || []}
                handleCloseDrawer={closeDrawer}
              />
            ) : null}
          </Grid2>
          <Box
            display={'flex'}
            alignItems={'center'}
            flexWrap={'wrap'}
            sx={{
              marginTop: '0 !important',
            }}
            columnGap={2}
          >
            <Link
              to="restore-last-clean-backup"
              preservePath
              preserveSearchParams
            >
              <Button
                variant="contained"
                color="success"
                sx={{
                  color: 'white',
                  textTransform: 'capitalize',
                  marginRight: '16px',
                  height: '32px',
                  backgroundColor: '#45AA5B !important',
                }}
              >
                Restore Last Clean Recovery Point
              </Button>
            </Link>

            {!!assetsListBackups && (
              <InfectedBackupProvider backupData={assetsListBackups} />
            )}
            <AssetsDrawerActions asset={asset!} scan={assetDetails?.lastScan} />
            {!!asset && (
              <DownloadReports asset={asset} scan={assetDetails?.lastScan} />
            )}
          </Box>
          {!!assetsListBackups && !!assetsListScans && (
            <InfectedAssets
              asset={asset!}
              backupData={assetsListBackups}
              scanData={{
                scansRunList: assetsListScans.scansRunList,
                runsList: assetsListScans.runsList,
              }}
            />
          )}
        </Stack>
      </DrawerContent>
    </Drawer>
  )
}

export default AssetsDetailsDrawer
