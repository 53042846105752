import { Dayjs } from 'dayjs'
import { BaseModel } from 'ui-v2/src/lib/models/base-model'
import { Nullable } from 'ui-v2/src/lib/engine-types'

class AccessTokenModel extends BaseModel {
  public readonly name: string

  public readonly userId: string

  public readonly tenantId: string

  public readonly createdAt: Nullable<Dayjs>

  public readonly updatedAt: Nullable<Dayjs>

  public readonly scopesList: Array<string>

  // extra

  public readonly scopesListRow: string

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.createdAt = params.createdAt
    this.updatedAt = params.updatedAt
    this.name = params.name
    this.tenantId = params.tenantId
    this.userId = params.userId
    this.scopesList = params.scopesList

    // extra
    this.scopesListRow = this.scopesList.join(', ')
  }
}

export default AccessTokenModel
