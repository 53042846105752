import { useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import { useListAssetFilterQuery } from 'ui-v2/src/hooks/queries/assets/filters-list'

const AssetIdFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const instanceIdList =
    searchParams.get(ASSET_FILTERS_MAP.ASSET_ID)?.split(',') || []

  const { assetKind } = useParams<{ assetKind: string | undefined }>()
  const { data: filterData } = useListAssetFilterQuery({ asset: assetKind })
  const isS3AssetKind = assetKind === 's3'

  const assetIdOptions = useMemo(() => {
    if (!filterData) {
      return []
    }

    if (isS3AssetKind) {
      return filterData.data.data.id.map((instance) => ({
        label: instance.aws_id,
        value: instance.id,
      }))
    }

    if (assetKind === 'server') {
      return filterData.data.data.id.map((instance) => ({
        label: instance.name ?? '',
        value: instance.aws_id,
      }))
    }

    return filterData.data.data.id.map((instance) => ({
      label: instance.aws_id,
      value: instance.id,
    }))
  }, [filterData])

  const handleInstanceIdChange = (
    newInstanceIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newInstanceIdList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.ASSET_ID)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.ASSET_ID, newInstanceIdList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={assetIdOptions}
      value={instanceIdList}
      onChange={handleInstanceIdChange}
      label={
        <SelectLabelText
          selectedValueLength={instanceIdList.length}
          optionsLength={assetIdOptions?.length}
          text={!isS3AssetKind ? 'ID' : 'Name'}
        />
      }
      width={!isS3AssetKind ? 91 : 120}
    />
  )
}

export default AssetIdFilter
