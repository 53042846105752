import {
  useEmailSelectionState,
  useInfrastructureScopeState,
  useReviewEnableState,
} from '@features/alerts/create-rule-drawer/stepper/steps'
import {
  useNotificationsState,
  WebhookList,
  EmailList,
} from '@features/alerts/create-rule-drawer/stepper/steps/notifications'
import { FormProvider, useForm } from 'react-hook-form'
import {
  useRuleDetailsState,
  useAssetTagSelectionState,
} from '@features/alerts/create-rule-drawer/stepper/steps'
import { zodResolver } from '@hookform/resolvers/zod'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { FormSchema } from '@features/reports/schedules/report/drawer/dynamic-form/schedule/validations/schema'
import { useDebouncedCallback } from 'use-debounce'
import { RuleFormValues } from '@features/alerts/create-rule-drawer/stepper/steps/notifications/types'
import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { FORM_FIELDS, ENABLED, statusOptionsForRules } from './consts'
import {
  Subtitle,
  RadioGroupController,
} from '@features/alerts/create-rule-drawer/shared'
import { ALERT_ON_EVENTS } from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/consts'
import NameDisplay from './name-display'
import { DEBOUNCE_DELAY_MS } from '@features/alerts/create-rule-drawer/stepper/steps/notifications/notifications'
import Typography from '@mui/material/Typography'
import { HoverablePopover } from './hoverable-popover'
import { Table } from '@components/table'
import { getTagsColumns } from '@features/alerts/create-rule-drawer/stepper/steps/review-enable/tags-column'
import { getAccountsColumns } from '@features/alerts/create-rule-drawer/stepper/steps/review-enable/accounts-column'
import { getProvidersColumns } from '@features/alerts/create-rule-drawer/stepper/steps/review-enable/providers-column'
import { transformAccountToTableData } from '@features/alerts/create-rule-drawer/stepper/steps/review-enable/utils'
import { useReviewEnableActions } from './review-enable-store'
import { SUPPRESS_EVENTS } from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/consts/mode'
import Stack from '@mui/material/Stack'
import { readableTagsOperator } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope/asset-tag-selection/asset-tag-selection-store'

function getEventLabel(mode: string, suppressUntil: number | undefined) {
  return mode === ALERT_ON_EVENTS ? (
    'Alert On These Events'
  ) : (
    <>
      Suppress These Events Until:{' '}
      <DateTimeTooltip direction="row" date={suppressUntil || 0} />
    </>
  )
}

function ReviewEnable() {
  const { ruleName, description, mode, suppressUntil, reasonSuppression } =
    useRuleDetailsState().ruleDetailsData
  const { tags, tagsOperator } = useAssetTagSelectionState()
  const { setReviewEnableData } = useReviewEnableActions()
  const { ruleStatus } = useReviewEnableState()
  const { accountsBackupProviders, accountsRegions } =
    useInfrastructureScopeState()
  const emails = useEmailSelectionState()
  const { webhooks, slackIntegration, existingConsoleUsers } =
    useNotificationsState()
  const methods = useForm<any>({
    defaultValues: {
      [FORM_FIELDS.RULE_STATUS]: ruleStatus,
    },
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
  })

  const accountsRegionsTableData = transformAccountToTableData(accountsRegions)

  const accountsBackupProvidersTableData = transformAccountToTableData(
    accountsBackupProviders
  )

  const debouncedSave = useDebouncedCallback((values: RuleFormValues) => {
    setReviewEnableData(values)
  }, DEBOUNCE_DELAY_MS)

  useEffect(() => {
    const { unsubscribe } = methods.watch((value) => {
      debouncedSave(value)
    })
    return () => unsubscribe()
  }, [])

  const isShowSuppressReason = !!reasonSuppression && mode === SUPPRESS_EVENTS
  const isShowEmailList = emails.length > 0 || existingConsoleUsers.length > 0

  return (
    <Box>
      <Subtitle>Review Rule Properties</Subtitle>
      <FormProvider {...methods}>
        <RadioGroupController
          name={FORM_FIELDS.RULE_STATUS}
          defaultValue={ENABLED}
          labelOptions={statusOptionsForRules}
        />
        <NameDisplay label="Name" value={ruleName} />
        <NameDisplay label="Description" value={description} />

        {accountsRegionsTableData.length > 0 && (
          <Box mb={2}>
            <Table
              data={accountsRegionsTableData}
              columns={getAccountsColumns()}
              generalProps={{
                noDataMessage: 'No Account found',
                isLoading: false,
              }}
            />
          </Box>
        )}

        {accountsBackupProvidersTableData.length > 0 && (
          <Box mb={2}>
            <Table
              data={accountsBackupProvidersTableData}
              columns={getProvidersColumns()}
              generalProps={{
                noDataMessage: 'No Backups found',
                isLoading: false,
              }}
            />
          </Box>
        )}

        {tags.length > 0 && (
          <>
            <NameDisplay
              label="Tags Operator"
              value={readableTagsOperator[tagsOperator]}
            />
            <Box mb={2}>
              <Table
                data={tags}
                columns={getTagsColumns()}
                generalProps={{
                  noDataMessage: 'No tags found',
                  isLoading: false,
                }}
              />
            </Box>
          </>
        )}

        <NameDisplay label="Mode" value={getEventLabel(mode, suppressUntil)} />
        {isShowSuppressReason && (
          <NameDisplay label="Suppress Reason" value={reasonSuppression} />
        )}

        <Box display="flex" alignItems="center">
          <Typography variant="body1" fontWeight="bold">
            Delivery Channel:&nbsp;
          </Typography>
          <Stack
            direction="row"
            divider={<Box mr={0.5}>,</Box>}
            alignItems="baseline"
          >
            {isShowEmailList && (
              <>
                <HoverablePopover title="Recipients:" content={<EmailList />}>
                  <Typography
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'var(--blue500)',
                    }}
                  >
                    Email
                  </Typography>
                </HoverablePopover>
              </>
            )}
            {webhooks.length > 0 && (
              <>
                <HoverablePopover
                  title="Webhooks:"
                  content={<WebhookList webhooks={webhooks} />}
                >
                  <Typography
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'var(--blue500)',
                    }}
                  >
                    Webhook
                  </Typography>
                </HoverablePopover>
              </>
            )}

            {slackIntegration && <Typography>Slack</Typography>}
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}

export default ReviewEnable
