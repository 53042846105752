import { AssetDetailsLink } from 'ui-v2/src/components/ui/data-display/asset-details-link'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { OvaServerInventoryTableRow } from 'ui-v2/src/lib/models/assets/ova-server'

export const getInventoryServerColumns = (): Array<
  Column<OvaServerInventoryTableRow>
> => [
  {
    key: 'health',
    header: 'Health',
    render: (row) => <StatusChip type={row.health ? 'error' : 'success'} />,
    width: 80,
    align: 'center',
  },
  {
    key: 'name',
    header: 'Server',
    render: (row) => (
      <AssetDetailsLink
        assetId={row.id}
        assetKind={ASSETS_ROUTE_SEGMENTS.SERVER}
      >
        {row.name}
      </AssetDetailsLink>
    ),
  },
  {
    key: 'ovaAccountId',
    header: 'Ova Account ID',
  },
  {
    key: 'lastCleanBackupDate',
    header: 'Last Clean Backup',
    render: (row) => formatDateToUtc(row.lastCleanBackupDate),
  },
]
