/* eslint-disable import/no-extraneous-dependencies */
import { Box, Typography, Link, Button as MUIButton } from '@mui/material'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { useParams } from 'react-router-dom'
import { useAccountLevelCloudFormationLink } from 'ui-v2/src/hooks/queries/red-stack/account-level-cloud-formation-link'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import IconButton from '@mui/material/IconButton'
import { useToast } from 'ui-v2/src/hooks/toast'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const LinkProtectedAccountModal = () => {
  const { id } = useParams()
  const { data: cloudInformationLinkData, isLoading } =
    useAccountLevelCloudFormationLink(id)

  const toast = useToast()
  const handleDownloadButton = () => {
    window.open(cloudInformationLinkData, '_blank')
  }

  return (
    <Box
      sx={{
        paddingX: 2.5,
        paddingBottom: 2.5,
      }}
    >
      <Box mb={2} fontSize={'14px'}>
        <Typography
          variant="body1"
          fontWeight={400}
          mb={1}
          fontSize={'14px'}
          color={'#B2B5C1'}
        >
          Follow these steps to deploy the Protect Account CouldFormation (CFN)
          template to the Cloud Connector:
        </Typography>
        <Typography mt={3} fontWeight={600} fontSize={'14px'} mb={2}>
          Deployment Options:
        </Typography>
        <Typography fontSize={'14px'} fontWeight={600} mt={2} mb={1}>
          Option A: Deploy Across Multiple Accounts Using AWS StackSets
        </Typography>
        <Typography component="ol" sx={{ pl: 2 }} fontSize={'14px'}>
          <Typography component="li" fontSize={'14px'} color={'#B2B5C1'}>
            Open the AWS CloudFormation StackSets console.
          </Typography>
          <Typography component="li" fontSize={'14px'} color={'#B2B5C1'}>
            Launch the StackSet to deploy the Protected Account CFN across
            multiple accounts.
          </Typography>
          <Typography component="li" fontSize={'14px'} color={'#B2B5C1'}>
            Follow the{' '}
            <Link
              href="https://support.elastio.com/hc/en-us/articles/25332246293650-Protected-Accounts-Deployment-Steps"
              underline="hover"
              target={'_blank'}
              fontSize={'14px'}
            >
              detailed step-by-step instructions
            </Link>{' '}
            to complete the deployment.
          </Typography>
        </Typography>
        <Typography fontSize={'14px'} fontWeight={600} mt={2} mb={1}>
          Option B: Deploy in a Single Account
        </Typography>
        <Typography component="ol" sx={{ pl: 2 }} fontSize={'14px'}>
          <Typography component="li" fontSize={'14px'} color={'#B2B5C1'}>
            Open the AWS CloudFormation console.
          </Typography>
          <Typography component="li" fontSize={'14px'} color={'#B2B5C1'}>
            Select Create Stack/Choose Existing Template and enter the S3 URL
            below in the Amazon S3 URL field.
          </Typography>
          <Typography component="li" fontSize={'14px'} color={'#B2B5C1'}>
            Follow the on-screen instructions to complete the deployment.
          </Typography>
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography variant="body1" fontWeight={600} mb={1} fontSize={'14px'}>
          Amazon S3 URL
        </Typography>
        <TextField
          placeholder="https://"
          value={cloudInformationLinkData}
          sx={{
            minWidth: '338px',
            fontSize: '14px !important',
          }}
          disabled
        />
        <IconButton
          onClick={() => {
            copyToClipboard(cloudInformationLinkData || '').then(() => {
              toast.success('Copied to clipboard')
            })
          }}
          size="small"
          sx={{
            padding: '3px',
            paddingTop: '8px',
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Box>
      <Box>
        <MUIButton
          variant="contained"
          sx={{
            marginRight: '16px',
          }}
          href={'https://console.aws.amazon.com/cloudformation/home'}
          target="_blank"
        >
          Launch CloudFormation Stack
        </MUIButton>

        <Button
          variant="contained"
          disabled={isLoading}
          onClick={handleDownloadButton}
          sx={{
            backgroundColor: '#727883',
            '&:hover': {
              backgroundColor: '#5f6570',
            },
          }}
        >
          Download CFN
        </Button>
        <Typography
          sx={{
            fontSize: '10px',
            color: '#B2B5C1',
            mt: 2,
            maxWidth: '219px',
          }}
        >
          <strong>ATTENTION:</strong> Login to the account and region to deploy
          the StackSet or CloudFormation Stack.
        </Typography>
      </Box>
      <Typography fontSize={'14px'} fontWeight={600} mt={2} mb={1}>
        Verify Deployment
      </Typography>
      <Typography
        component="ol"
        sx={{ pl: 2 }}
        fontSize={'14px'}
        color={'#B2B5C1'}
      >
        <Typography component="li" fontSize={'14px'} color={'#B2B5C1'}>
          Check the status of the deployment in the AWS CloudFormation console.
        </Typography>
        <Typography component="li" fontSize={'14px'} color={'#B2B5C1'}>
          Once the status shows SUCCEEDED, the setup is complete.
        </Typography>
        <Typography component="li" fontSize={'14px'} color={'#B2B5C1'}>
          The Cloud Connector will automatically link to the protected accounts,
          which will then appear in the Deployments view.
        </Typography>
      </Typography>
    </Box>
  )
}

export default LinkProtectedAccountModal
