import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { Box, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import {
  INVENTORY_VIEW_PARAM,
  INVENTORY_VIEW_PARAM_VALUES,
} from 'ui-v2/src/lib/constants/assets.constant'
import { BackupType } from 'ui-v2/src/lib/models/assets/live'
import useRpoStore from 'ui-v2/src/components/drawers/resilience-score/rpo-store'
import LangHelper from 'ui-v2/src/lib/helpers/lang.helper'
import { clampPercentage } from 'ui-v2/src/lib/helpers/num.helper'

const AreaChart = ({ lastBackups }: { lastBackups: Array<BackupType> }) => {
  const theme = useTheme()
  const areachartRef = useRef(null)
  const navigate = useNavigate()
  const { rpoHours } = useRpoStore()

  useEffect(() => {
    if (!areachartRef.current || !lastBackups.length) {
      return
    }

    const Areachart = echarts.init(areachartRef.current)
    const now = dayjs()

    const ages = lastBackups.map((backup) =>
      now.diff(dayjs(backup.timestamp), 'hour')
    )
    const maxAgeHours = Math.max(...ages)
    const maxAgeDays = Math.ceil(maxAgeHours / 24)

    const backupsPerDay = new Array(maxAgeDays + 1).fill(0)
    lastBackups.forEach((backup) => {
      const ageInDays = Math.floor(
        now.diff(dayjs(backup.timestamp), 'hour') / 24
      )
      if (ageInDays <= maxAgeDays) {
        backupsPerDay[ageInDays]++
      }
    })

    const smoothDistribution = []
    const sigma = 0.5

    for (let i = 0; i <= maxAgeDays; i++) {
      let weightedSum = 0
      let totalWeight = 0

      for (let j = Math.max(0, i - 3); j <= Math.min(maxAgeDays, i + 3); j++) {
        const distance = i - j
        const weight = Math.exp(-(distance * distance) / (2 * sigma * sigma))
        weightedSum += backupsPerDay[j] * weight
        totalWeight += weight
      }

      const smoothedValue = weightedSum / totalWeight
      smoothDistribution.push([i, smoothedValue])
    }

    const totalAssets = lastBackups.length

    const formatAge = (hours: number) => {
      if (hours < 24) {
        return `${hours} Hour${hours !== 1 ? 's' : ''}`
      }
      const days = Math.floor(hours / 24)
      return `${days} Day${days !== 1 ? 's' : ''}`
    }

    const backupsUnderRpo = lastBackups.filter((backup) => {
      const backupTime = dayjs(backup.timestamp)
      const currentTime = dayjs()
      const diffInSeconds = currentTime.diff(backupTime, 'second')
      return diffInSeconds <= rpoHours * 60 * 60
    }).length

    const recoveryAssurancePercentage = clampPercentage(
      Math.round((backupsUnderRpo / (totalAssets || 1)) * 100)
    )

    const rpoDays = rpoHours / 24

    // @ts-ignore
    const beforeRPO = smoothDistribution.filter((point) => point[0] <= rpoDays)

    // @ts-ignore
    const afterRPO = smoothDistribution.filter((point) => point[0] >= rpoDays)

    if (!Number.isInteger(rpoDays)) {
      const lowerDay = Math.floor(rpoDays)
      const upperDay = Math.ceil(rpoDays)
      const ratio = rpoDays - lowerDay

      const lowerValue = smoothDistribution[lowerDay]?.[1] || 0
      const upperValue = smoothDistribution[upperDay]?.[1] || 0

      const interpolatedValue = lowerValue + (upperValue - lowerValue) * ratio
      beforeRPO.push([rpoDays, interpolatedValue])
      afterRPO.unshift([rpoDays, interpolatedValue])
    }

    const getLabelPosition = (xValue: number) => {
      if (xValue >= maxAgeDays * 0.1 && xValue <= maxAgeDays * 0.9) {
        return ['center', 0]
      } else if (xValue < maxAgeDays * 0.1) {
        return ['left', -20]
      } else if (xValue > maxAgeDays * 0.9) {
        return ['right', 0]
      }

      return ['center', 0]
    }

    const [align, offsetX] = getLabelPosition(rpoHours / 24)

    const option = {
      animation: false,
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          const dayIndex = params[0].data[0]
          const actualCount = backupsPerDay[Math.floor(dayIndex)]
          const percentage = ((actualCount / totalAssets) * 100).toFixed(1)
          const totalHours = Math.round(dayIndex * 24)

          const label =
            totalHours < 24
              ? `${totalHours} Hours`
              : totalHours % 24 === 0
                ? `${totalHours / 24} Days`
                : `${totalHours} Hours`

          return `${label}<br/>Backups: ${actualCount}<br/>Percentage: ${percentage}%`
        },
        backgroundColor: 'rgba(50, 50, 50, 0.9)',
        borderColor: '#68A9A5',
        textStyle: {
          color: '#fff',
        },
      },
      xAxis: {
        type: 'value',
        min: 0,
        max: maxAgeDays,
        show: false,
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: Math.ceil(Math.max(...backupsPerDay)), // Use actual max count instead of smoothed values
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      },
      series: [
        {
          type: 'line',
          smooth: true,
          data: beforeRPO,
          areaStyle: {
            color: '#68A9A5',
            opacity: 0.6,
          },
          lineStyle: {
            width: 2,
            color: '#68A9A5',
          },
          showSymbol: false,
        },
        {
          type: 'line',
          smooth: true,
          data: afterRPO,
          areaStyle: {
            color: '#E74C3C',
            opacity: 0.6,
          },
          lineStyle: {
            width: 2,
            color: '#E74C3C',
          },
          showSymbol: false,
        },
        {
          type: 'line',
          markLine: {
            symbol: 'none',
            data: [
              {
                xAxis: rpoDays,
                lineStyle: {
                  color: '#4CAF50',
                  width: 2,
                  type: 'solid',
                },
                label: {
                  formatter: `RPO (${rpoHours} ${LangHelper.pluralizeEn('hours', rpoHours)})\n${backupsUnderRpo} of ${totalAssets} ${LangHelper.pluralizeEn('Asset', totalAssets)} (${recoveryAssurancePercentage}%)`,
                  position: 'end',
                  padding: [6, 10, 6, 10],
                  color: '#4CAF50',
                  fontSize: 12,
                  fontWeight: 'bold',
                  align: align,
                  offset: [offsetX, 0],
                },
              },
            ],
          },
          data: [],
        },
      ],
      graphic: [
        {
          type: 'text',
          left: 'center',
          top: '0%',
          style: {
            text: `${totalAssets} ${LangHelper.pluralizeEn('Asset', totalAssets)}`,
            fill: theme.palette.text.primary,
            font: 'bold 14px Arial',
            textAlign: 'center',
          },
          onclick: function () {
            navigate(
              `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.INVENTORY_TABLES}?${INVENTORY_VIEW_PARAM}=${INVENTORY_VIEW_PARAM_VALUES.RPO}`
            )
          },
        },
        {
          type: 'text',
          left: '5%',
          bottom: '10%',
          style: {
            text: '0 Hours\nMin, Age of\nRecovery Point',
            fill: theme.palette.text.primary,
            font: '12px Arial',
            textAlign: 'left',
            fontWeight: 'bold',
          },
        },
        {
          type: 'text',
          right: '5%',
          bottom: '10%',
          style: {
            text: `${formatAge(maxAgeHours)}\nMax, Age of\nRecovery Point`,
            fill: '#E74C3C',
            font: '12px Arial',
            textAlign: 'right',
            fontWeight: 'bold',
          },
        },
      ],
    }

    Areachart.setOption(option)

    const handleResize = () => {
      Areachart.resize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      Areachart.dispose()
    }
  }, [lastBackups, rpoHours, theme])

  if (!lastBackups.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '240px',
        }}
      >
        No backup data available
      </Box>
    )
  }

  return (
    <Box
      ref={areachartRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '240px',
      }}
    />
  )
}

export default React.memo(AreaChart)
