import { Divider, Box } from '@mui/material'

const NumberedDivider = ({
  number,
  sx,
  ...props
}: {
  number: number
  sx?: {
    marginTop?: string
    marginBottom?: string
  }
}) => {
  return (
    <Box display="flex" alignItems="center" sx={sx} {...props}>
      <Box
        sx={{
          width: '17.54px',
          height: '17.83px',
          borderRadius: '50%',
          backgroundColor: 'grey.600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FFF',
          fontWeight: '400',
          fontSize: 14,
        }}
      >
        {number}
      </Box>
      <Divider
        sx={{
          flexGrow: 1,
          backgroundColor: '#727883',
          height: '2px',
        }}
      />
    </Box>
  )
}

export default NumberedDivider
