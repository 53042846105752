/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  Box,
  FormControlLabel,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import dayjs from 'dayjs'
import {
  RuleAwsAccountRegion,
  Tag,
} from 'blues-corejs/dist/models/notifications/rule/criteria'
import { ALERTS_KINDS_MAP } from '../utils'

interface RulePropertiesProps {
  isEnabled: boolean
  setIsEnabled: (v: boolean) => void
  name: string
  description: string
  selectedRegions: Array<RuleAwsAccountRegion>
  tagPairs: Array<Tag>
  isSuppress: boolean
  suppressUntil: dayjs.Dayjs
  selectedEvents: Array<string>
  emailUsers: Array<string>
  selectedWebhooks: Array<string>
  slackEnabled: boolean
  disableChange?: boolean
}

const RuleProperties = ({
  isEnabled,
  setIsEnabled,
  name,
  description,
  selectedRegions,
  selectedEvents,
  selectedWebhooks,
  slackEnabled,
  suppressUntil,
  isSuppress,
  emailUsers,
  tagPairs,
  disableChange,
}: RulePropertiesProps) => {
  const theme = useTheme()

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Review Rule Properties
      </Typography>

      <Box
        sx={{
          mb: '11px',
          display: 'flex',
          gap: 2,
        }}
      >
        <FormControlLabel
          control={
            <Radio
              checked={isEnabled}
              onChange={() => setIsEnabled(true)}
              disabled={disableChange}
            />
          }
          componentsProps={{
            typography: {
              fontSize: '14px',
            },
          }}
          label="Enable Rule"
        />
        <FormControlLabel
          control={
            <Radio
              checked={!isEnabled}
              onChange={() => setIsEnabled(false)}
              disabled={disableChange}
            />
          }
          componentsProps={{
            typography: {
              fontSize: '14px',
            },
          }}
          label="Disabled Rule"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          mb: '19px',
          gap: 0.5,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          Name:
        </Typography>
        <Typography>{name}</Typography>
      </Box>

      {description && (
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 0.5,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            Description:
          </Typography>
          <Typography
            sx={{
              maxHeight: '76px',
              overflowY: 'auto',
            }}
          >
            {description}
          </Typography>
        </Box>
      )}

      {selectedRegions.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      pl: 0,
                    }}
                  >
                    AWS Accounts
                  </TableCell>
                  <TableCell>Regions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  '& .MuiTableCell-body': {
                    backgroundColor:
                      theme.palette.mode === 'dark' ? '#181B1F' : '#f8f8f8',
                  },
                }}
              >
                {selectedRegions.map(({ accountId, region }) => (
                  <TableRow key={`${accountId}-${region}`}>
                    <TableCell
                      sx={{
                        width: '160px',
                        pl: '16px',
                      }}
                    >
                      {accountId}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderLeftWidth: '1px',
                        borderLeftStyle: 'solid',
                      }}
                    >
                      {region}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {tagPairs.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Tags (Key : Value)
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableBody
                sx={{
                  '& .MuiTableCell-body': {
                    backgroundColor:
                      theme.palette.mode === 'dark' ? '#181B1F' : '#f8f8f8',
                  },
                }}
              >
                {tagPairs.map((pair, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        width: '160px',
                      }}
                    >
                      {pair.key}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderLeftWidth: '1px',
                        borderLeftStyle: 'solid',
                      }}
                    >
                      {pair.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          mb: 2,
          gap: 0.5,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          Mode:
        </Typography>
        <Typography>
          {!isSuppress
            ? 'Alert On These Events'
            : `Suppress These Events Until: ${suppressUntil.format('DD/MM/YYYY')}`}
        </Typography>
      </Box>

      {selectedEvents.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <TableContainer>
            <Table size="small">
              <TableBody
                sx={{
                  '& .MuiTableCell-body': {
                    backgroundColor:
                      theme.palette.mode === 'dark' ? '#181B1F' : '#f8f8f8',
                  },
                }}
              >
                {selectedEvents.map((event, index) => {
                  if (ALERTS_KINDS_MAP[event]) {
                    return (
                      <TableRow key={index}>
                        <TableCell>{ALERTS_KINDS_MAP[event]}</TableCell>
                      </TableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          mb: 2,
          gap: 0.5,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          Delivery Channel:
        </Typography>
        <Stack spacing={1} divider={<>,</>} direction="row">
          {emailUsers.length > 0 && (
            <Tooltip
              placement={'top-start'}
              title={
                <Box>
                  <Typography
                    sx={{
                      background: '#4E5665',
                      color: '#D8DCE4',
                      padding: '2px 16px',
                    }}
                  >
                    Recipients
                  </Typography>
                  <Box
                    sx={{
                      padding: '5px 16px',
                      maxHeight: '300px',
                      overflowY: 'auto',
                    }}
                  >
                    {emailUsers.map((email) => (
                      <Typography key={email}>{email}</Typography>
                    ))}
                  </Box>
                </Box>
              }
              slotProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'rgba(0, 0, 0, 0.87)',
                    color: '#fff',
                    fontSize: '0.875rem',
                    padding: 0,
                  },
                },
              }}
            >
              <Typography component="span" color="primary.main">
                Email ({emailUsers.length} recipients)
              </Typography>
            </Tooltip>
          )}
          {selectedWebhooks.length > 0 && (
            <Tooltip
              placement={'top-start'}
              title={
                <Box>
                  <Typography
                    sx={{
                      background: '#4E5665',
                      color: '#D8DCE4',
                      padding: '2px 16px',
                    }}
                  >
                    Webhooks
                  </Typography>
                  <Box
                    sx={{
                      padding: '5px 16px',
                      maxHeight: '300px',
                      overflowY: 'auto',
                    }}
                  >
                    {selectedWebhooks.map((hook) => (
                      <Typography key={hook}>{hook}</Typography>
                    ))}
                  </Box>
                </Box>
              }
              slotProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'rgba(0, 0, 0, 0.87)',
                    color: '#fff',
                    fontSize: '0.875rem',
                    padding: 0,
                  },
                },
              }}
            >
              <Typography component="span" color="primary.main">
                Webhook ({selectedWebhooks.length} webhooks)
              </Typography>
            </Tooltip>
          )}
          {slackEnabled && (
            <Typography component="span" color="primary.main">
              Slack
            </Typography>
          )}
          {!emailUsers.length && !selectedWebhooks.length && !slackEnabled && (
            <Typography color="error.main">
              No delivery channels selected
            </Typography>
          )}
        </Stack>
      </Box>
    </>
  )
}

export default RuleProperties
