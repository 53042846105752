import { TableBody, TableCell, TableRow } from '@mui/material'

const LoadingTable = ({
  columnCount,
  rowCount,
}: {
  columnCount: number
  rowCount: number
}) => {
  return (
    <TableBody
      sx={(theme) => ({
        background: theme.palette.background.tertiary,
      })}
    >
      {Array.from({ length: rowCount }, (_, index) => (
        <TableRow key={index}>
          {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
          {Array.from({ length: columnCount }, (_, cellIndex) => (
            <TableCell key={cellIndex} sx={{ minHeight: '43px' }} />
          ))}
        </TableRow>
      ))}
    </TableBody>
  )
}

export default LoadingTable
