import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import ElastioShieldIcon from 'ui-v2/src/assets/icons/elastio-shield-icon'
import { NewsFeedItem as NewsFeedItemType } from 'ui-v2/src/lib/constants/news-feed.constant'

type NewsFeedItemdProps = {
  newsFeedItem: NewsFeedItemType
}

const NewsFeedItem: React.FC<NewsFeedItemdProps> = ({ newsFeedItem }) => {
  return (
    <Link
      key={newsFeedItem.id}
      to={newsFeedItem.link}
      style={{
        textDecoration: 'none',
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box
        maxWidth={'auto'}
        minWidth={150}
        sx={(theme) => ({
          backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1A2F47',
          border:
            theme.palette.mode === 'light'
              ? '1px solid #eee'
              : '1px solid #28425F',

          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'light' ? '#e9ecf0' : '#1e3b5c',
          },
        })}
        component={'div'}
        className="dashboard-news-feed"
      >
        <Typography
          fontSize={22}
          fontWeight={700}
          lineHeight={'28px'}
          color="text.secondary"
          mb={0.5}
        >
          {newsFeedItem.name}
        </Typography>
        <Box display={'flex'} alignItems={'center'} columnGap={1}>
          <ElastioShieldIcon />
          <Typography
            fontSize={16}
            fontWeight={400}
            lineHeight={'20px'}
            color="text.secondary"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              textOverflow: 'ellipsis',
            }}
          >
            {newsFeedItem.updatedAt}
          </Typography>
        </Box>
      </Box>
    </Link>
  )
}

export default React.memo(NewsFeedItem)
