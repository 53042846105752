import React, { useEffect } from 'react'
import { Box, styled, Theme, Typography } from '@mui/material'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { formatDate } from 'ui-v2/src/lib/helpers/time.helper'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ASSET_DETAILS_ACTIVE_TAB } from '../constants'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import { RecoveryPoint as RecoveryPointItem } from 'ui-v2/src/lib/models/backups'
import { formatTimeAgo, getKeyItemText, getStatus } from '../../utils'
import RecoveryPointScans from './recovery-point-scans'

type RecoveryPointProps = {
  recoveryPoint: RecoveryPointItem
  defaultItemId: string | undefined
}

const ListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean; isKeyType?: boolean }>(
  ({ theme, isActive, isKeyType }) => ({
    borderBottom: `1px solid ${theme.palette.border.dark}`,
    padding: '0.5rem',
    paddingLeft: '0.75rem',
    backgroundColor: isActive
      ? theme.palette.background.highlight
      : theme.palette.background.elevation1,
    cursor: 'pointer',
    position: 'relative',
    width: '280px',
    minHeight: isKeyType ? '87px' : '40px',

    '&:hover': {
      backgroundColor: theme.palette.background.highlight,
    },

    '&::before': isActive
      ? {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          backgroundColor: theme.palette.primary.main,
        }
      : {},
  })
)

const RecoveryPoint: React.FC<RecoveryPointProps> = ({
  recoveryPoint,
  defaultItemId,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {
    assetKind,
    assetId,
    activeTab = ASSET_DETAILS_ACTIVE_TAB.recoveryPoint,
    recoveryPointId,
    scanJobId,
  } = useParams<{
    assetKind: string
    assetId: string
    activeTab: string
    recoveryPointId?: string
    scanJobId?: string
  }>()
  const isActive = recoveryPointId
    ? recoveryPointId === recoveryPoint.id
    : recoveryPoint.id === defaultItemId

  const navigateToRP = () => {
    const pathParts = [
      assetKind,
      'details',
      assetId,
      activeTab,
      recoveryPoint.id,
    ]
      .filter(Boolean)
      .join('/')

    navigate(
      {
        pathname: `/${BASE_ROUTE_SEGMENTS.ASSETS}/${pathParts}`,
        search: searchParams ? `?${searchParams.toString()}` : '',
      },
      { replace: true }
    )
  }

  const navigateToRPScan = (jobId: string | undefined) => {
    if (!jobId) {
      return
    }

    const pathParts = [
      assetKind,
      'details',
      assetId,
      ASSET_DETAILS_ACTIVE_TAB.recoveryPoint,
      recoveryPoint.id,
      ASSET_DETAILS_ACTIVE_TAB.scanLog,
      jobId,
    ]
      .filter(Boolean)
      .join('/')

    navigate(
      {
        pathname: `/${BASE_ROUTE_SEGMENTS.ASSETS}/${pathParts}`,
        search: searchParams ? `?${searchParams.toString()}` : '',
      },
      { replace: true }
    )
  }

  const ListItemProps = {
    isActive,
    onClick: () => {
      if (isActive && recoveryPoint.all_scans?.[0]) {
        return navigateToRPScan(recoveryPoint.all_scans[0].job_id)
      }
      if (recoveryPoint.all_scans?.[0]) {
        navigateToRPScan(recoveryPoint.all_scans[0].job_id)
      } else {
        navigateToRP()
      }
    },
    isKeyType: Boolean(recoveryPoint?.key?.length),
  }

  const rpContainerSxProps = (theme: Theme) => {
    const activeBackgroundColor =
      theme.palette.mode === ThemeMode.DARK
        ? '#0076AC'
        : theme.palette.background.default
    return {
      background:
        isActive && !scanJobId ? activeBackgroundColor : 'transparent',
    }
  }

  useEffect(() => {
    if (!recoveryPointId && recoveryPoint.id === defaultItemId) {
      if (recoveryPoint.all_scans?.[0]) {
        navigateToRPScan(recoveryPoint.all_scans[0].job_id)
      } else {
        navigateToRP()
      }
    }
  }, [])

  if (recoveryPoint?.key?.length) {
    return (
      <ListItem {...ListItemProps}>
        <Box display={'flex'} alignItems={'center'} gap={0.5}>
          <StatusChip type={getStatus(recoveryPoint.health)} />
          <Typography
            variant="body2"
            fontWeight={600}
            sx={(theme) => ({
              color:
                theme.palette.mode === ThemeMode.DARK ? '#F6F9FC' : '#4E5665',
              fontSize: '14px',
            })}
          >
            {getKeyItemText(recoveryPoint.key)}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={0.5}
          py={0.5}
          pl={0.25}
          mt={1}
          mb={0.5}
          sx={rpContainerSxProps}
        >
          <Typography variant="body2" fontWeight={600} color={'text.secondary'}>
            RP
          </Typography>
          <Typography variant="body2" fontWeight={600}>
            {formatTimeAgo(recoveryPoint.timestamp)} |
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatDate(recoveryPoint.timestamp, 'hh:mm A, MM/DD/YY')}
          </Typography>
        </Box>

        <RecoveryPointScans
          recoveryPointId={recoveryPoint.id}
          scans={recoveryPoint.all_scans}
          navigateToRPScan={navigateToRPScan}
        />

        <Typography variant="body2" color="text.secondary" pt={0.5}>
          {recoveryPoint.backupType}
        </Typography>
      </ListItem>
    )
  }

  return (
    <ListItem {...ListItemProps}>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={1}
        pl={0.25}
        mb={0.5}
        sx={rpContainerSxProps}
      >
        <Typography variant="body1" fontWeight={600}>
          {formatDate(recoveryPoint.timestamp, 'hh:mm A, MM/DD/YY')}
        </Typography>
        <StatusChip type={getStatus(recoveryPoint.health)} />
      </Box>

      <RecoveryPointScans
        recoveryPointId={recoveryPoint.id}
        scans={recoveryPoint.all_scans}
        navigateToRPScan={navigateToRPScan}
      />

      <Typography variant="body2" color="text.secondary" pt={0.5}>
        {recoveryPoint.backupType}
      </Typography>
    </ListItem>
  )
}

export default RecoveryPoint
