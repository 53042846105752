import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import { AssetKindEnum } from 'ui-v2/src/lib/constants/assets.constant'
import { RECOVERY_POINTS_TYPES_FILTER_VALUES } from '../data-panel/common/types'

const ScanTypeFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const kindNameList = searchParams.get('backupType')?.split(',') || []
  const { assetKind } = useParams<{
    assetKind: string
  }>()
  const keyParam = searchParams.get('type')

  if (keyParam) {
    kindNameList.push(keyParam)
  }

  const handleTypeChange = (
    newKindNameList: Array<MultiSelectOption['value']>
  ) => {
    if (newKindNameList.length === 0) {
      searchParams.delete('backupType')
    } else {
      searchParams.set('backupType', newKindNameList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={
        RECOVERY_POINTS_TYPES_FILTER_VALUES[
          assetKind?.toUpperCase() as AssetKindEnum
        ]
      }
      value={kindNameList}
      onChange={handleTypeChange}
      label={
        <SelectLabelText
          selectedValueLength={0}
          optionsLength={1}
          text="Type"
        />
      }
      width={270}
      size="xxs"
    />
  )
}

export default ScanTypeFilter
