import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_VULNERABILITIES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

export interface FilterComponentProps {
  options: Array<string>
}

const VolumeIdFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsVolumeList =
    searchParams
      .get(ASSETS_VULNERABILITIES_FILTERS_MAP.VOLUME_ID)
      ?.split(',') || []

  const awsVolumeOptions = useMemo(() => {
    return options.map((awsVolumeOption) => ({
      value: awsVolumeOption,
      label: awsVolumeOption,
    }))
  }, [options])

  const handleAwsVolumeChange = (
    newAwsVolumeList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsVolumeList.length === 0) {
      searchParams.delete(ASSETS_VULNERABILITIES_FILTERS_MAP.VOLUME_ID)
    } else {
      searchParams.set(
        ASSETS_VULNERABILITIES_FILTERS_MAP.VOLUME_ID,
        newAwsVolumeList.join(',')
      )
    }
    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsVolumeOptions}
      value={awsVolumeList}
      onChange={handleAwsVolumeChange}
      label={
        <SelectLabelText
          selectedValueLength={awsVolumeList?.length}
          optionsLength={awsVolumeOptions?.length}
          text="Volume ID"
        />
      }
      width={200}
    />
  )
}

export default VolumeIdFilter
