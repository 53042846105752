import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRevokeMutation } from 'ui-v2/src/hooks/queries/settings/personal-access-token/revoke'
import { TokenListTableRow } from 'ui-v2/src/lib/models/settings/api-access/api-access'
import { useQueryClient } from '@tanstack/react-query'
import { EditOutlined, DeleteOutline } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import ConfirmationDialog from 'ui-v2/src/components/dialogs/confirmation'
import { useToast } from 'ui-v2/src/hooks/toast'

const ActiveTokenAction = ({ row }: ActiveTokenActionProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [tokenId, setTokenId] = useState<string>()
  const { mutate, isPending } = useRevokeMutation()
  const toast = useToast()
  const handleEdit = () => {
    navigate(`/settings/general/api-access/edit-access-token/${row.id}`)
  }

  const handleRevokeToken = async () => {
    setTokenId(undefined)
    mutate(
      { tokens: [row.id] },
      {
        onSuccess: () => {
          toast.success('The access api token has been revoked')
          queryClient.invalidateQueries({
            queryKey: ['settings', 'tokenList'],
          })
          queryClient.invalidateQueries({
            queryKey: ['settings', 'revokedTokenList'],
          })
        },
        onError: (error) => {
          toast.error(error?.message ?? 'Cannot perform the tokens revoking')
        },
      }
    )
  }

  const iconStyles = {
    color: 'icon.dark',
  }

  return (
    <Box textAlign={'right'}>
      <IconButton aria-label="delete" size="small" onClick={handleEdit}>
        <EditOutlined fontSize="small" sx={iconStyles} />
      </IconButton>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => setTokenId(row.id)}
      >
        {isPending ? (
          <CircularProgress size={20} />
        ) : (
          <DeleteOutline fontSize="small" sx={iconStyles} />
        )}
      </IconButton>

      <ConfirmationDialog
        open={!!tokenId}
        title="Confirmation"
        onClose={() => setTokenId(undefined)}
        onConfirm={handleRevokeToken}
        confirmText="Yes, Proceed"
        cancelText="No, Cancel"
      >
        <Typography>
          You are going to remove API access for the user, please confirm the
          action in order to proceed
        </Typography>
      </ConfirmationDialog>
    </Box>
  )
}
interface ActiveTokenActionProps {
  row: TokenListTableRow
}
export default ActiveTokenAction
