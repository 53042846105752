import React from 'react'
import { useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'

const TooltipComponent = ({
  children,
  text,
}: {
  children: React.ReactElement
  text: string
}) => {
  const theme = useTheme()

  return (
    <Tooltip
      title={text}
      leaveDelay={200}
      placement={'top'}
      slotProps={{
        tooltip: {
          sx: {
            color:
              theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#727883',
            backgroundColor:
              theme.palette.mode === ThemeMode.DARK ? '#19232F' : '#F6F9FC',
            border:
              theme.palette.mode === ThemeMode.DARK
                ? '1px solid #4E5665'
                : '1px solid #D3D8DE',
            whiteSpace: 'pre-line',
            borderRadius: '6px',
            fontSize: '14px',
            fontWeight: 400,
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

export default TooltipComponent
