import {
  DEFAULT_RPO_HOURS,
  RPO_HOURS_LOCAL_STORAGE_KEY,
} from 'ui-v2/src/lib/constants/ui.constant'
import { create } from 'zustand'

interface RpoStore {
  rpoHours: number
  setRpoHours: (newRpoHours: number) => void
}

const useRpoStore = create<RpoStore>((set) => ({
  rpoHours: DEFAULT_RPO_HOURS,

  setRpoHours: (newRpoHours: number) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(
        RPO_HOURS_LOCAL_STORAGE_KEY,
        JSON.stringify(newRpoHours)
      )
    }
    set({ rpoHours: newRpoHours })
  },
}))

if (typeof window !== 'undefined') {
  const storedValue = window.localStorage.getItem(RPO_HOURS_LOCAL_STORAGE_KEY)
  if (storedValue) {
    useRpoStore.setState({
      rpoHours: Number(JSON.parse(storedValue)),
    })
  }
}

export default useRpoStore
