import { useQuery } from '@tanstack/react-query'
import { ScansClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

interface Options {
  enabled?: boolean
}

export function useListScansQuery(ids: Array<string>, options?: Options) {
  const scansClient = useGrpcClient(ScansClient)

  return useQuery({
    queryKey: ['scans', 'list-scan', ids],
    queryFn: async () => {
      if (!scansClient) {
        throw new Error('Scans client not initialized')
      }
      return scansClient.listScans(ids)
    },
    enabled:
      !!scansClient &&
      (options?.enabled === undefined ? true : options.enabled),
  })
}
