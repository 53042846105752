import { styled } from '@mui/material/styles'
import { Box, IconButton, TextField } from '@mui/material'

export const SearchWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  position: 'relative',
  width: '365px',
}))

export const SearchField = styled(TextField)(({ theme }) => ({
  width: '100%',
  height: '32px',
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5, 1),
    height: '32px',
    fontSize: '12px',
    fontStyle: 'italic',
    paddingLeft: 0,
  },
}))

export const SearchIconButton = styled(IconButton)({
  position: 'absolute',
  right: 6,
})
