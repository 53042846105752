export const industryOptions = [
  {
    label: 'IT',
    value: 'it',
    smb: 145000,
    enterprise: 217500,
  },
  {
    label: 'Automotive',
    value: 'automotive',
    smb: 250000,
    enterprise: 375000,
  },
  {
    label: 'Manufacturing',
    value: 'manufacturing',
    smb: 200000,
    enterprise: 300000,
  },
  {
    label: 'Retail',
    value: 'retail',
    smb: 300000,
    enterprise: 450000,
  },
  {
    label: 'Telecommunications',
    value: 'telecommunications',
    smb: 200000,
    enterprise: 300000,
  },
  {
    label: 'Healthcare',
    value: 'healthcare',
    smb: 250000,
    enterprise: 375000,
  },
  {
    label: 'Energy',
    value: 'energy',
    smb: 500000,
    enterprise: 750000,
  },
  {
    label: 'Media',
    value: 'media',
    smb: 100000,
    enterprise: 150000,
  },
  {
    label: 'Other',
    value: 'other',
    smb: 225000,
    enterprise: 337500,
  },
]
