import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  TableSortLabel,
  SxProps,
  Theme,
  Collapse,
} from '@mui/material'
import {
  MoreVert,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'
import { mergeSx, sortConfigsToUrlParams, urlParamsToSortConfigs } from './util'
import LoadingTable from './loading-table'
import ShieldIcon from 'ui-v2/src/assets/icons/shield-icon'

export interface Column<T> {
  key: keyof T
  header: string
  render?: (row: T) => React.ReactNode
  width?: number | string
  wrap?: boolean
  align?: 'left' | 'right' | 'center'
  sortable?: boolean
  sx?: SxProps<Theme>
  headerSx?: SxProps<Theme>
  cellSx?: SxProps<Theme>
}

export type SortDirection = 'asc' | 'desc'

export interface SortConfig {
  key: string
  direction: SortDirection
}

export interface RowAction<T> {
  label: string
  icon?: React.ReactNode
  onClick: (row: T) => void
}

interface EmptyStateProps {
  title?: string
  description?: string
  action?: React.ReactNode
}

export interface BaseTableStyles {
  root?: SxProps<Theme>
  container?: SxProps<Theme>
  table?: SxProps<Theme>
  header?: SxProps<Theme>
  headerRow?: SxProps<Theme>
  headerCell?: SxProps<Theme>
  body?: SxProps<Theme>
  row?: SxProps<Theme>
  cell?: SxProps<Theme>
  checkbox?: SxProps<Theme>
  sortLabel?: SxProps<Theme>
  actionButton?: SxProps<Theme>
  loadingOverlay?: SxProps<Theme>
  emptyState?: SxProps<Theme>
  menu?: SxProps<Theme>
}

export interface BaseTableProps<T extends Record<string, any>> {
  data: Array<T> | undefined
  columns: Array<Column<T>>
  idField?: keyof T
  onRowClick?: (row: T) => void
  checkable?: boolean
  selectedRows?: Array<T>
  onSelectRows?: (rows: Array<T>) => void
  isLoading?: boolean
  loadingRowCount?: number
  emptyState?: EmptyStateProps
  footer?: React.ReactNode
  rowActions?: Array<RowAction<T>> | ((row: T) => Array<RowAction<T>>)
  tableId?: string
  styles?: BaseTableStyles
  multiSort?: boolean
  renderExpandedRow?: (row: T) => React.ReactNode
}

function BaseTable<T extends Record<string, any>>({
  data,
  columns,
  idField = 'id' as keyof T,
  onRowClick,
  checkable = false,
  selectedRows = [],
  onSelectRows,
  isLoading = false,
  loadingRowCount = 10,
  emptyState,
  footer,
  rowActions,
  tableId,
  styles = {},
  multiSort = true,
  renderExpandedRow,
}: BaseTableProps<T>) {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set())
  const [searchParams, setSearchParams] = useSearchParams()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [actionRow, setActionRow] = useState<T | null>(null)
  const open = Boolean(anchorEl)

  const getRowId = (row: T): string => {
    const id = row[idField]
    return id !== undefined ? String(id) : JSON.stringify(row)
  }

  const getRowActions = (row: T) => {
    if (!rowActions) {
      return []
    }
    return Array.isArray(rowActions) ? rowActions : rowActions(row)
  }

  const handleActionClick = (event: React.MouseEvent<HTMLElement>, row: T) => {
    event.stopPropagation()
    setActionRow(row)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setActionRow(null)
  }

  const handleMenuItemClick = (action: RowAction<T>) => {
    if (actionRow) {
      action.onClick(actionRow)
    }
    handleClose()
  }

  const handleSelectAllClick = () => {
    if (selectedRows.length === 0 && data) {
      onSelectRows?.(data)
    } else {
      onSelectRows?.([])
    }
  }

  const handleRowSelect = (row: T) => {
    const selectedIndex = selectedRows.findIndex(
      (selectedRow) => getRowId(selectedRow) === getRowId(row)
    )
    let newSelectedRows: Array<T> = []

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, row]
    } else if (selectedIndex === 0) {
      newSelectedRows = selectedRows.slice(1)
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = selectedRows.slice(0, -1)
    } else if (selectedIndex > 0) {
      newSelectedRows = [
        ...selectedRows.slice(0, selectedIndex),
        ...selectedRows.slice(selectedIndex + 1),
      ]
    }

    onSelectRows?.(newSelectedRows)
  }

  const handleSort = (columnKey: string) => {
    const currentSortConfigs = urlParamsToSortConfigs(searchParams, tableId)
    const currentSortIndex = currentSortConfigs.findIndex(
      (config) => config.key === columnKey
    )

    let newSortConfigs: Array<SortConfig>

    if (currentSortIndex === -1) {
      newSortConfigs = multiSort
        ? [
            ...currentSortConfigs,
            {
              key: columnKey,
              direction: 'asc',
            },
          ]
        : [
            {
              key: columnKey,
              direction: 'asc',
            },
          ]
    } else {
      const currentConfig = currentSortConfigs[currentSortIndex]
      if (multiSort) {
        newSortConfigs = currentSortConfigs.map((config) =>
          config.key === columnKey
            ? {
                ...config,
                direction: currentConfig?.direction === 'asc' ? 'desc' : 'asc',
              }
            : config
        )
      } else {
        newSortConfigs = [
          {
            key: columnKey,
            direction: currentConfig?.direction === 'asc' ? 'desc' : 'asc',
          },
        ]
      }
    }

    const newSearchParams = new URLSearchParams(searchParams)
    const prefix = tableId ? `${tableId}_` : ''
    newSearchParams.delete(`${prefix}sort`)
    const updatedParams = sortConfigsToUrlParams(newSortConfigs, tableId)
    updatedParams.forEach((value, key) => {
      newSearchParams.append(key, value)
    })
    setSearchParams(newSearchParams)
  }

  const getSortDirection = (columnKey: string): 'asc' | 'desc' | false => {
    const currentSortConfigs = urlParamsToSortConfigs(searchParams, tableId)
    const sortConfig = currentSortConfigs.find(
      (config) => config.key === columnKey
    )
    return sortConfig ? sortConfig.direction : false
  }

  const isSelected = (row: T) =>
    selectedRows.findIndex(
      (selectedRow) => getRowId(selectedRow) === getRowId(row)
    ) !== -1

  const getCellStyle = (column: Column<T>): SxProps<Theme> => {
    return mergeSx(
      {
        width: column.width,
        ...(!column.wrap && {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }),
      },
      styles.cell,
      column.cellSx
    )
  }

  const toggleRowExpansion = (rowId: string) => {
    setExpandedRows((prev) => {
      const newExpanded = new Set(prev)
      if (newExpanded.has(rowId)) {
        newExpanded.delete(rowId)
      } else {
        newExpanded.add(rowId)
      }
      return newExpanded
    })
  }

  const renderEmptyState = () => (
    <TableRow
      sx={{
        pointerEvents: 'none',
      }}
    >
      <TableCell
        colSpan={columns.length + (checkable ? 1 : 0) + (rowActions ? 1 : 0)}
        sx={{
          border: 'none',
          ...(styles.emptyState || {}),
        }}
      >
        <Box component={'div'} className="render-empty-state">
          <Box sx={{ mb: 2 }}>
            <ShieldIcon isHealthy={true} />
          </Box>
          <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
            {emptyState?.title ?? 'No data available'}
          </Typography>
          <Typography variant="body2" color="text.disabled" sx={{ mb: 2 }}>
            {emptyState?.description ?? 'There are no items to display.'}
          </Typography>
          {emptyState?.action && <Box>{emptyState.action}</Box>}
        </Box>
      </TableCell>
    </TableRow>
  )

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: 2,
        position: 'relative',
        ...(styles.root || {}),
      }}
    >
      <TableContainer sx={styles.container}>
        <Table stickyHeader sx={styles.table}>
          <TableHead
            sx={{
              '& .MuiTableCell-head': {
                backgroundColor: (theme) => theme.palette.background.paper,
                fontWeight: '700 !important',
              },
              ...(styles.header || {}),
            }}
          >
            <TableRow sx={styles.headerRow}>
              {renderExpandedRow && (
                <TableCell
                  sx={{
                    width: 24,
                    padding: 0,
                  }}
                />
              )}
              {checkable && (
                <TableCell
                  padding="checkbox"
                  sx={{
                    width: 40,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < (data?.length ?? 0)
                    }
                    checked={
                      data?.length === selectedRows.length && data.length > 0
                    }
                    onChange={handleSelectAllClick}
                    sx={{
                      color: (theme) => theme.palette.grey[700],
                      ...(styles.checkbox || {}),
                      width: '36px',
                      height: '36px',
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={String(column.key)}
                  sx={mergeSx(
                    getCellStyle(column),
                    styles.headerCell,
                    column.headerSx
                  )}
                  align={column.align ?? 'left'}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={Boolean(getSortDirection(String(column.key)))}
                      direction={getSortDirection(String(column.key)) || 'asc'}
                      onClick={() => handleSort(String(column.key))}
                      sx={{
                        '&:hover .MuiTableSortLabel-icon': {
                          opacity: Boolean(getSortDirection(String(column.key)))
                            ? 1
                            : 0.3,
                        },
                        '& .MuiTableSortLabel-icon': {
                          opacity: Boolean(getSortDirection(String(column.key)))
                            ? 1
                            : 0.3,
                        },
                        ...(styles.sortLabel || {}),
                      }}
                    >
                      {column.header}
                    </TableSortLabel>
                  ) : (
                    column.header
                  )}
                </TableCell>
              ))}
              {rowActions && <TableCell padding="checkbox" />}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <LoadingTable
              columnCount={
                columns.length + (checkable ? 1 : 0) + (rowActions ? 1 : 0)
              }
              rowCount={loadingRowCount}
            />
          ) : (
            <TableBody
              sx={{
                '& .MuiTableRow-root:hover': {
                  backgroundColor: (theme) => theme.palette.background.hover,
                },
                '& .MuiTableRow-root:hover .MuiTableCell-body': {
                  borderColor: (theme) => theme.palette.background.hover,
                },
                ...(styles.body || {}),
              }}
            >
              {!data || data.length === 0
                ? renderEmptyState()
                : data.map((row) => {
                    const rowId = getRowId(row)
                    const isExpanded = expandedRows.has(rowId)
                    const isShouldExpanded =
                      renderExpandedRow && renderExpandedRow(row)

                    return (
                      <>
                        <TableRow
                          key={getRowId(row)}
                          hover
                          onClick={() => onRowClick?.(row)}
                          sx={{
                            background: (theme) =>
                              theme.palette.background.tertiary,
                            cursor: onRowClick ? 'pointer' : 'default',
                            ...(styles.row || {}),
                          }}
                        >
                          {renderExpandedRow &&
                            (isShouldExpanded ? (
                              <TableCell
                                sx={{
                                  width: 24,
                                  padding: 0,
                                }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    toggleRowExpansion(row.id)
                                  }}
                                >
                                  {isExpanded ? (
                                    <KeyboardArrowDown />
                                  ) : (
                                    <KeyboardArrowRight />
                                  )}
                                </IconButton>
                              </TableCell>
                            ) : (
                              <TableCell
                                sx={{
                                  width: 24,
                                  padding: 0,
                                }}
                              />
                            ))}

                          {checkable && (
                            <TableCell
                              padding="checkbox"
                              sx={{
                                width: 40,
                                padding: 0,
                              }}
                            >
                              <Checkbox
                                checked={isSelected(row)}
                                onChange={(e) => {
                                  e.stopPropagation()
                                  handleRowSelect(row)
                                }}
                                sx={(theme) => ({
                                  color: theme.palette.grey[700],
                                  width: '36px',
                                  height: '36px',
                                })}
                              />
                            </TableCell>
                          )}
                          {columns.map((column) => (
                            <TableCell
                              key={String(column.key)}
                              align={column.align ?? 'left'}
                              sx={getCellStyle(column)}
                            >
                              {column.render
                                ? column.render(row)
                                : (row[column.key] as React.ReactNode)}
                            </TableCell>
                          ))}
                          {rowActions && (
                            <TableCell padding="checkbox">
                              <IconButton
                                size="small"
                                onClick={(e) => handleActionClick(e, row)}
                              >
                                <MoreVert />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                        {renderExpandedRow && (
                          <TableRow>
                            <TableCell
                              colSpan={columns.length + 1}
                              sx={{
                                height: 'auto',
                                borderBottom: 0,
                                padding: 0,
                              }}
                            >
                              <Collapse
                                in={isExpanded}
                                timeout="auto"
                                unmountOnExit
                              >
                                {renderExpandedRow(row)}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )
                  })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {footer}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
            backgroundColor: 'transparent',
            ...(styles.loadingOverlay || {}),
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.10))',
              ...(styles.menu || {}),
            },
          },
        }}
      >
        {actionRow &&
          getRowActions(actionRow).map((action, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(action)}>
              {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
              {action.label}
            </MenuItem>
          ))}
      </Menu>
    </Paper>
  )
}

export default BaseTable
