/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo } from 'react'
import { Stack } from '@mui/material'
import { getEc2EbsColumns } from '../column-config'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import {
  PolicyEc2EbsTableRow,
  PolicyFormData,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const AssetsEc2 = ({
  ec2EbsPolicyTableData,
  ec2PolicyTableData,
  isLoading,
  handleAssetsSelect,
}: AssetsEc2Props) => {
  const { id } = useParams()
  const { watch, getValues, setValue, trigger } =
    useFormContext<PolicyFormData>()
  const selectedEc2EbsAssetsRows = watch('selectedEc2EbsAssetsRows')

  useEffect(() => {
    if (id && ec2EbsPolicyTableData) {
      const selectedAssets = getValues('selectedAssets')
      const selectedAssetsIds = selectedAssets.map((asset) => asset.asset.id)
      const selectedEc2EbsAssetsRowsIds = selectedEc2EbsAssetsRows.map(
        (asset) => asset.id
      )

      const uniqueIds = Array.from(
        new Set([...selectedAssetsIds, ...selectedEc2EbsAssetsRowsIds])
      )

      const selectedEc2EbsRows = ec2EbsPolicyTableData.filter((row) =>
        uniqueIds.includes(row.id)
      )
      setValue('selectedEc2EbsAssetsRows', selectedEc2EbsRows)
    }
  }, [id, ec2EbsPolicyTableData])

  const columns = useMemo(getEc2EbsColumns, [])

  return (
    <Stack spacing={1}>
      <ClientTable
        checkable={true}
        data={ec2PolicyTableData}
        columns={columns}
        isLoading={isLoading}
        selectedRows={selectedEc2EbsAssetsRows}
        onSelectRows={(rows) => {
          handleAssetsSelect(rows)
          trigger('assetVariant')
        }}
        clearRows={false}
      />
    </Stack>
  )
}
interface AssetsEc2Props {
  ec2EbsPolicyTableData: Array<PolicyEc2EbsTableRow>
  ec2PolicyTableData: Array<PolicyEc2EbsTableRow>
  isLoading: boolean
  handleAssetsSelect: (rows: Array<PolicyEc2EbsTableRow>) => void
}
export default AssetsEc2
