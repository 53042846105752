import { useMemo, useState } from 'react'
import Label from 'ui-v2/src/components/ui/data-display/label'
import { DrawerSubHeader } from 'ui-v2/src/components/ui/drawer'
import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import { SETTINGS_ACTIONS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import {
  Box,
  FormControl,
  Grid2,
  Stack,
  Divider,
  InputAdornment,
} from '@mui/material'
import {
  getWebhooksEventType,
  authorizationOptions,
  versionOptions,
  severityOptions,
} from './data'
import FilterMultiSelect from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import {
  AuthenticationType,
  EventTypeConstant,
  VersionType,
  WebhookAuthType,
  WebhookFormData,
} from 'ui-v2/src/lib/models/webhooks'
import AuthenticationFields from './authentication-fields'
import { useListEventTypesQuery } from 'ui-v2/src/hooks/queries/webhooks'
import EventListTable from './event-list-table'
import { stripProtocol, WebhookFormProps } from './utils'
import { useTestWebhookMutation } from 'ui-v2/src/hooks/queries/webhooks'
import { useToast } from 'ui-v2/src/hooks/toast'
import RegexConstants from 'ui-v2/src/lib/constants/regex.constant'
import Button from 'ui-v2/src/components/ui/inputs/button'

const WebhookForm = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  setValue,
  setAuthType,
  authType,
  eventList,
  handleSeverityChange,
  handleAddEvent,
  handleDeleteEvent,
  eventType,
  setEventType,
  versionType,
  setVersionType,
  action,
  isSubmitDisabled,
  severityList,
  isAddDisabled,
  getValues,
  watch,
  trigger,
  webHookName,
}: WebhookFormProps) => {
  const toast = useToast()
  const [isWebhookTestFailed, setIsWebhookTestFailed] = useState<boolean>(false)
  const severityLists = useMemo(() => {
    return (
      severityOptions?.map((severityOption) => ({
        label: severityOption.label,
        value: severityOption.label,
      })) || []
    )
  }, [severityOptions])

  const { mutate: testWebhookMutate, isPending: isTestWebhookPending } =
    useTestWebhookMutation()

  const { data: eventTypesData } = useListEventTypesQuery()
  const eventTypeList = useMemo(() => {
    if (!eventTypesData || !eventList) {
      return []
    }

    const selectedEventTypes = new Set(
      eventList.map((event) => event.eventType)
    )

    return eventTypesData.eventTypesList.reduce(
      (acc: Array<{ label: string; value: string }>, eventTypeOption) => {
        if (!selectedEventTypes.has(eventTypeOption.name)) {
          acc.push({
            label: getWebhooksEventType(eventTypeOption.name),
            value: eventTypeOption.name,
          })
        }
        return acc
      },
      []
    )
  }, [eventTypesData, eventList])

  const handleTestWebhook = (data: WebhookFormData) => {
    testWebhookMutate(
      {
        id: undefined,
        name: data.name,
        endpoint: `https://${data.endpoint}`,
      },
      {
        onError: (error) => {
          setIsWebhookTestFailed(true)
          toast.error(
            error.message ?? 'Something went wrong while testing webhook'
          )
        },
        onSuccess: (result) => {
          if (result.success) {
            setIsWebhookTestFailed(false)
            toast.success('Webhook test is successful')
          } else {
            setIsWebhookTestFailed(true)
            toast.error('Webhook test failed')
          }
        },
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack p={2.5} spacing={2}>
        <FormControl fullWidth>
          <Label>Webhook Name</Label>
          <TextField
            {...register('name', {
              required: 'Webhook name is required',
              maxLength: {
                value: 100,
                message: 'Maximal length is 100 characters',
              },
              validate: (value: string) => {
                const isWebhookNameExist = webHookName.includes(value)

                if (isWebhookNameExist) {
                  return 'Webhook with this name already exists'
                }

                return true
              },
            })}
            placeholder="Webhook Name"
            fullWidth
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </FormControl>
        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <FormControl fullWidth>
              <Label>Endpoint URL</Label>
              <TextField
                {...register('endpoint', {
                  required: 'Endpoint URL is required',
                  validate: (value: string) => {
                    const url = `https://${value}`
                    if (RegexConstants.SITE_WITH_HTTPS.test(url)) {
                      return true
                    }

                    return 'Invalid URL'
                  },
                  onChange: (e) => {
                    const strippedUrl = stripProtocol(e.target.value)

                    setValue('endpoint', strippedUrl)
                  },
                })}
                placeholder="api.example.com/webhook"
                fullWidth
                error={!!errors.endpoint}
                helperText={errors.endpoint?.message}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">https://</InputAdornment>
                    ),
                    onBlur: () => {
                      trigger('endpoint')
                    },
                  },
                }}
                sx={{ mr: 0 }}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl fullWidth>
              <Label>Authentication Option</Label>
              <BasicSelect
                options={authorizationOptions}
                onChange={(value) => {
                  setValue('authentication', value as AuthenticationType)
                  setAuthType(value as WebhookAuthType)
                }}
                value={authType}
              />
            </FormControl>
          </Grid2>
        </Grid2>

        <AuthenticationFields
          authType={authType}
          register={register}
          errors={errors}
        />

        <FormControl fullWidth>
          <Label>Description</Label>
          <TextField
            {...register('description', {
              maxLength: {
                value: 100,
                message: 'Maximal length is 100 characters',
              },
            })}
            placeholder="Description"
            fullWidth
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => handleTestWebhook(getValues())}
            disabled={
              watch().name === '' ||
              watch().endpoint === '' ||
              !!errors?.endpoint?.message
            }
            isLoading={isTestWebhookPending}
            sx={{ width: 150 }}
          >
            Test Webhook
          </Button>
        </Box>

        <Divider sx={{ py: 1 }} />

        <DrawerSubHeader py={1}>Select events</DrawerSubHeader>

        <FormControl fullWidth>
          <Label>Event Type</Label>
          <BasicSelect
            options={eventTypeList}
            placeholder="Event Type"
            onChange={(value) => {
              setValue(
                'event_subscriptions.0.event_type',
                value as EventTypeConstant
              )
              setEventType(value as EventTypeConstant)
            }}
            value={eventType}
          />
        </FormControl>

        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <FormControl fullWidth>
              <Label>Version</Label>
              <BasicSelect
                options={versionOptions}
                onChange={(value) => {
                  setValue(
                    'event_subscriptions.0.version',
                    value as VersionType
                  )
                  setVersionType(value as VersionType)
                }}
                value={versionType}
                disabled
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl fullWidth>
              <Label>Severities</Label>
              <FilterMultiSelect
                options={severityLists}
                value={severityList}
                onChange={handleSeverityChange}
                label="Select Severities"
                width={320}
                disabled={!eventType}
              />
            </FormControl>
          </Grid2>
        </Grid2>
        <Button
          type="button"
          variant="text"
          color="inherit"
          size="medium"
          onClick={handleAddEvent}
          disabled={isAddDisabled}
        >
          Add
        </Button>
        {eventList.length > 0 && (
          <EventListTable
            eventList={eventList}
            handleDeleteEvent={handleDeleteEvent}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK &&
              (isSubmitDisabled || isWebhookTestFailed)
            }
          >
            {action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK
              ? 'Add Webhook'
              : 'Update Webhook'}
          </Button>
        </Box>
      </Stack>
    </form>
  )
}

export default WebhookForm
