import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { BackupsClient } from 'ui-v2/src/lib/clients'

interface Options {
  enabled?: boolean
  staleTime?: number
}

export function useBackupByIdQuery(
  backupIds?: Array<string>,
  options?: Options
) {
  const backupsClient = useGrpcClient(BackupsClient)

  return useQuery({
    queryKey: ['backup', 'backup-id', backupIds, options?.staleTime],
    queryFn: async () => {
      if (!backupsClient) {
        throw new Error('Backups client not initialized')
      }
      if (!backupIds) {
        return undefined
      }

      if (!backupIds.length) {
        throw new Error('Backup ID is required')
      }
      return backupsClient.getBackupsByIds(backupIds)
    },
    enabled:
      !!backupsClient &&
      !!backupIds?.length &&
      (options?.enabled === undefined ? true : options.enabled),
    staleTime: options?.staleTime,
  })
}
