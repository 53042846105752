import React, { memo, useEffect, useMemo, useState } from 'react'
import FormHelper from '@lib/helpers/form.helper'
import buildDefaultFormData from './data'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import SelectField from '@forms/fields/select-field'
import { Button } from '@mui/material'
import {
  EngineCallback,
  EngineCallbackPure,
  Nullable,
  ValueObject,
} from '@lib/engine-types'
import FormAddSpecificPathsInterface from '@lib/interfaces/form/form-add-specific-paths.interface'
import clsx from 'clsx'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import SpecificPathsBlock from '@components-composite/specific-paths-block/SpecificPathsBlock'

interface Props {
  defaultValues?: ValueObject<FormAddSpecificPathsInterface>
  onSubmit: EngineCallback<FormAddSpecificPathsInterface>
  loading: boolean
  onCancel: EngineCallbackPure
  data?: Nullable<FormAddSpecificPathsInterface>
  isShowPrefix?: boolean
}

const errorGroups = [ErrorGroupConstants.S3_PATHS]

function AddSpecificPathsForm({
  defaultValues,
  onSubmit,
  onCancel,
  loading,
  data,
  isShowPrefix = true,
}: Props) {
  const currentForm = useMemo(
    () =>
      FormHelper.fillDefaultValues(
        buildDefaultFormData(isShowPrefix),
        defaultValues
      ),
    []
  )
  const selectedSelector =
    data?.pathSelector ?? String(currentForm.pathSelector.value)
  const selectedPathsList = data?.pathsList ?? {}
  const selectedCurrentPaths = selectedPathsList
    ? selectedPathsList[selectedSelector]
    : []

  const [paths, setPaths] =
    useState<Record<string, Array<string>>>(selectedPathsList)

  const [currentPaths, setCurrentPaths] = useState<Array<string>>(
    selectedCurrentPaths ?? []
  )
  const [selector, setSelector] = useState<string>(selectedSelector)

  const { setValue, isFormDisabled, submit, formState, globalFormError } =
    useGeneralForm(currentForm, onSubmit, errorGroups, loading)

  FormHelper.fillErrors(formState.errors, currentForm)

  useEffect(() => {
    setValue('pathSelector', selector ?? '')
  }, [selector])

  const onSetPaths = (list: Array<string>) => {
    let result: Record<string, Array<string>> = {}

    if (list.length === 0) {
      for (const key in paths) {
        if (key !== selector) {
          result[key] = paths[key] ?? []
        }
      }
    } else {
      result = paths
      result[selector] = list
    }

    setPaths(result)
    setValue('pathsList', result ?? [])
  }

  const onSetSelector = (selected: string) => {
    setSelector(selected)
    setCurrentPaths(paths[selected] ?? [])
  }

  useEffect(() => {
    onSetPaths(currentPaths)
  }, [JSON.stringify(currentPaths)])

  return (
    <form
      className={clsx('wrap-1617284626632', { formDisabled: isFormDisabled })}
      onSubmit={submit}
    >
      {globalFormError && (
        <div className="formGlobalError">{globalFormError}</div>
      )}

      <div className="selectorBlock">
        <h3>Type</h3>
        <SelectField
          field={currentForm.pathSelector}
          disabled={isFormDisabled}
          variant="outlined"
          onChange={(e) => {
            onSetSelector(e.target.value)
          }}
          iconComponent={SelectArrowIcon}
        />
      </div>
      <div className="pathsBlock">
        <SpecificPathsBlock
          disabled={false}
          paths={currentPaths}
          setPaths={setCurrentPaths}
          showTitle={false}
        />
      </div>

      <div className="actionsButtons">
        <Button
          disabled={loading}
          className="sizeS showButton"
          data-testid="jsAddPathsCancel"
          type="button"
          variant="text"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          className="sizeS btnDone"
          data-testid="jsAddPathsSubmit"
          color="primary"
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  )
}

export default memo(AddSpecificPathsForm)
