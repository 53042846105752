import { useQuery } from '@tanstack/react-query'
import { useDataScannedService } from '../../rest-service/use-data-scanned-service'

export function useGetDataScannedQuery() {
  const {
    getDataScanned,
    isReady,
    isLoading: isClientLoading,
  } = useDataScannedService()

  return useQuery({
    queryKey: ['data-scanned'],
    queryFn: async () => {
      return getDataScanned()
    },
    enabled: isReady && !isClientLoading,
    refetchOnWindowFocus: false,
  })
}
