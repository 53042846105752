/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { EventsColumn } from './column-config'

type EventsListStore = {
  selectedRows: Array<EventsColumn>
  setSelectedRows: (rows: Array<EventsColumn>) => void
}

export const useEventsListStore = create<EventsListStore>((set) => ({
  selectedRows: [],
  setSelectedRows: (rows: Array<EventsColumn>) =>
    set((state) => ({
      ...state,
      selectedRows: rows,
    })),
}))
