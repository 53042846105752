/* eslint-disable import/no-extraneous-dependencies */
import { Asset, Threat } from 'blues-corejs/dist'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { getAssetName, getAssetRouteSegmentKind, getAssetType } from './util'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { AssetDetailsLink } from 'ui-v2/src/components/ui/data-display/asset-details-link'

export const getActiveAssetThreatsColumns = (
  assetByThreat: Map<Threat, Asset>
): Array<Column<Threat>> => [
  {
    key: 'assetId',
    header: 'Asset Name',
    render: (row) => (
      <AssetDetailsLink
        assetId={row.assetId ?? ''}
        assetKind={getAssetRouteSegmentKind(assetByThreat.get(row))}
      >
        {getAssetName(assetByThreat.get(row))}
      </AssetDetailsLink>
    ),
  },
  {
    key: 'source',
    header: 'Asset Type',
    render: (row) => getAssetType(assetByThreat.get(row)),
  },
  {
    key: 'firstDetectedAt',
    header: 'Last Backup',
    render: (row) => formatDateToUtc(row.firstDetectedAt),
  },
  {
    key: 'location',
    header: 'Path',
    wrap: true,
  },
  {
    key: 'name',
    header: 'Threat Name',
  },
]
