/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { IconButton, List, ListItem, Typography } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { FormProvider, useForm } from 'react-hook-form'
import { ModifyCloudConnectorForm } from './types'
import { useDeploymentStore } from '../../store/deployment-store'
import ModifyCloudConnectorModal from './modify-cloud-connector-modal'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'

const ModifyCloudConnector = () => {
  const { action, id } = useParams()
  const { rowData, setRowData } = useDeploymentStore()
  const rexClientGRPC = useGrpcClient(RexClient)
  const isDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.MODIFY_CLOUD_CONNECTOR
  const navigate = useNavigate()
  const [isSubmitLoading, setSubmitLoading] = useState(false)

  const method = useForm<ModifyCloudConnectorForm>({
    defaultValues: {
      aliasName: '',
      description: '',
      vpcList: [],
      vpcListForApi: [],
    },
  })
  const { handleSubmit, reset, setValue } = method

  const handleCloseDrawer = () => {
    reset()
    setRowData(null)
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
  }

  const submit = async (data: ModifyCloudConnectorForm) => {
    if (!rexClientGRPC || !rowData || !id) {
      return
    }
    setSubmitLoading(true)
    const { vpcListForApi } = data
    const requests: Array<Promise<any>> = []
    const accountId = id
    data.vpcListForApi.forEach((region) => {
      const regionName = region.id

      if (region.children) {
        region.children.forEach((vpc) => {
          if (vpc?.children?.length) {
            const subnetIds = vpc.children.map((subnet) => subnet.id)
            requests.push(
              rexClientGRPC.installRedstack(
                accountId,
                vpc.id,
                regionName,
                false,
                subnetIds
              )
            )
          }
        })
      } else {
        requests.push(
          rexClientGRPC.installRedstack(
            accountId,
            undefined,
            regionName,
            false,
            []
          )
        )
      }
    })

    const removedRegions = rowData.awsRegions.filter(
      (region) => !vpcListForApi.find((nested) => nested.id === region)
    )

    removedRegions.forEach((region) => {
      const redstackId = rowData.regionWithRedStackId.get(region)
      if (redstackId) {
        requests.push(rexClientGRPC.delete(redstackId))
      }
    })

    if (
      rowData.accountAlias !== data.aliasName ||
      rowData.accountDescription !== data.description
    ) {
      requests.push(
        rexClientGRPC.updateAwsAccount(id, data.aliasName, data.description)
      )
    }

    Promise.all(requests)
      .then(() => {
        toast.success('Updated success')
      })
      .catch((error) => {
        toast.error(error.message ?? 'Cannot perform the submit source action')
      })
      .finally(() => {
        setSubmitLoading(false)
        handleCloseDrawer()
      })
  }

  useEffect(() => {
    if (!rowData) {
      return
    }

    setValue('aliasName', rowData.accountAlias)
    setValue('description', rowData.accountDescription)
    setValue('vpcList', [])
    setValue('vpcListForApi', [])
  }, [rowData])

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <DrawerContent>
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(submit)}>
            <List
              sx={{
                paddingX: 0,
                '& .MuiListItem-root': {
                  paddingX: 2.5,
                },
              }}
            >
              <ListItem
                sx={{
                  paddingRight: '70px',
                }}
                secondaryAction={[
                  <Button
                    variant="contained"
                    type="submit"
                    isLoading={isSubmitLoading}
                  >
                    Add
                  </Button>,
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      handleCloseDrawer()
                    }}
                  >
                    <Close />
                  </IconButton>,
                ]}
              >
                <Typography fontWeight={700} fontSize={22}>
                  Add Regions to Cloud Connector
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontWeight={500} fontSize={'14px'}>
                  Use this option to add new AWS regions to your existing cloud
                  connector. This allows Elastio to expand protection and
                  scanning coverage without requiring redeployment.
                </Typography>
              </ListItem>
            </List>
            {isDrawerOpen && <ModifyCloudConnectorModal />}
          </form>
        </FormProvider>
      </DrawerContent>
    </Drawer>
  )
}

export default ModifyCloudConnector
