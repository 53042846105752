import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const RegionFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsRegionList =
    searchParams.get(ASSET_FILTERS_MAP.REGION)?.split(',') || []

  const { data: redStackListData } = useRedStackListQuery()

  const awsRegionOptions = useMemo(() => {
    if (!redStackListData) {
      return []
    }

    const awsRegionSet = new Set<string>()

    redStackListData.forEach((redStack) => awsRegionSet.add(redStack.awsRegion))

    return Array.from(awsRegionSet).map((awsRegion) => ({
      value: awsRegion,
      label: LangAwsRegionConstant.get(awsRegion)?.en?.single ?? awsRegion,
    }))
  }, [redStackListData])

  const handleAwsRegionChange = (
    newAwsRegionList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsRegionList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.REGION)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.REGION, newAwsRegionList.join(','))
    }

    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsRegionOptions}
      value={awsRegionList}
      onChange={handleAwsRegionChange}
      label={
        <SelectLabelText
          selectedValueLength={awsRegionList?.length}
          optionsLength={awsRegionOptions?.length}
          text="Region"
        />
      }
      width={200}
    />
  )
}

export default RegionFilter
