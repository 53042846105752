import React from 'react'
import { Grid2 } from '@mui/material'
import Widgets from './widgets'
import DataPanel from './data-panel'

const AssetDetails = () => {
  return (
    <Grid2
      container
      direction={'column'}
      height={'100%'}
      spacing={1}
      padding={'4px'}
    >
      {/* Widgets */}
      <Grid2
        className="custom-scrollbar"
        sx={{
          width: '100%',
          minHeight: '11.625rem',
          overflowX: 'auto',
          paddingBottom: '0px',
        }}
      >
        <Widgets />
      </Grid2>

      {/* List and Table */}
      <Grid2 flexGrow={1}>
        <DataPanel />
      </Grid2>
    </Grid2>
  )
}

export default AssetDetails
