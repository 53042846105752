import { useMutation } from '@tanstack/react-query'
import { VaultClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { CreateVaultInterface } from 'ui-v2/src/lib/models/vault/vault'

export const useListVaultCreate = () => {
  const vaultClient = useGrpcClient(VaultClient)

  return useMutation({
    mutationFn: async (data: CreateVaultInterface) => {
      if (!vaultClient) {
        throw new Error('rexClient client not initialized')
      }
      return vaultClient.createVault(data)
    },
  })
}
