import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

import { WebhooksClient } from 'ui-v2/src/lib/clients'
import { TestWebhookFilters } from 'ui-v2/src/lib/models/webhooks'
import { useMutation } from '@tanstack/react-query'

export function useTestWebhookMutation() {
  const webhooksClient = useGrpcClient(WebhooksClient)

  return useMutation({
    mutationFn: async (filters: TestWebhookFilters) => {
      if (!webhooksClient) {
        throw new Error('Webhooks client not initialized')
      }
      return webhooksClient.testWebhook(filters)
    },
  })
}
