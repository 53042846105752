/* eslint-disable import/no-extraneous-dependencies */
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import Typography from '@mui/material/Typography'
import { ButtonPrimary } from '@components/shared'
import {
  useCreateRuleDrawerActions,
  useCreateRuleDrawerState,
} from '@features/alerts/create-rule-drawer'
import {
  useNotificationsState,
  useInfrastructureScopeState,
  useRuleDetailsState,
  useAssetTagSelectionState,
  useEmailSelectionState,
  useReviewEnableState,
  resetAllStores,
} from '@features/alerts/create-rule-drawer/stepper/steps'
import { useRuleDrawerDataState } from '@features/alerts/create-rule-drawer/create-rule-drawer-data-store'

import { PreviousButton } from './previous-button'
import { useStepperActions, useStepperState } from '../stepper/stepper-store'
import { useFormValidationState } from '../form-validation-store'
import { getDisplayStep, isLastStep } from '../stepper/stepper-logic'
import { StepWithoutInfrastructure, Step } from '../stepper/constants'
import { NotificationsRulesClient } from '@lib/clients/notifications'
import GrpcRequestError from '@lib/errors/grpc-error'
import { GrpcCodes } from '@lib/constants/data/error/api-errors.constant'
import ToastHelper from '@lib/helpers/toast.helper'
import {
  RuleAwsAccountRegion,
  RuleMode,
  RuleNotify,
  RuleOvaAccountProviders,
  RuleOvaBackupProvider,
  RuleSuppressUntil,
} from 'blues-corejs/dist/models/notifications/rule'
import { CreateNotificationRuleCriteria } from '@lib/clients/notifications/types'
import { useFetchAlertsListData } from '@features/alerts/hooks/use-fetch-alerts-list-data'

const notificationsRulesClient = new NotificationsRulesClient()

function CreateRuleDrawerActionHeader() {
  const isFormValid = useFormValidationState()
  const { fetchOnRefreshInterval } = useFetchAlertsListData()
  const { isUpdate } = useCreateRuleDrawerState()
  const { closeDrawer } = useCreateRuleDrawerActions()
  const { step: currentStep, hasStepWithoutNotifications } = useStepperState()
  const { goNext } = useStepperActions()

  const { assetId } = useRuleDrawerDataState()
  const {
    ruleDetailsData: {
      description,
      ruleName,
      reasonSuppression,
      events,
      suppressUntil,
      mode,
    },
    allKinds,
    ruleId,
  } = useRuleDetailsState()
  const { accountsBackupProviders, accountsRegions } =
    useInfrastructureScopeState()
  const { tags, tagsOperator } = useAssetTagSelectionState()
  const { slackIntegration, existingConsoleUsers, webhooks } =
    useNotificationsState()
  const { ruleStatus } = useReviewEnableState()

  const emails = useEmailSelectionState()

  let modeLocal: RuleMode = {}
  const eventKindLocal = allKinds ? {} : events.map((event) => event.value)

  if (mode === 'alertOnEvents') {
    modeLocal = new RuleNotify()
  } else {
    modeLocal = new RuleSuppressUntil(
      new Date(suppressUntil || ''),
      reasonSuppression
    )
  }

  const maxStep = hasStepWithoutNotifications
    ? StepWithoutInfrastructure.REVIEW_ENABLE
    : Step.REVIEW_ENABLE

  const lastStepButtonText = isUpdate ? 'Update' : 'Create'
  const actionButtonText = isLastStep(currentStep, maxStep)
    ? lastStepButtonText
    : 'Next'

  const actionTitle = `${isUpdate ? 'Update rule' : 'Create rule'} (${getDisplayStep(currentStep)}/${getDisplayStep(maxStep)})`

  const handleSuccessfulStateChange = () => {
    closeDrawer()
    resetAllStores()
    fetchOnRefreshInterval()
    ToastHelper.success(
      isUpdate ? 'Update Notification Rule' : 'Create Notification Rule'
    )
  }

  const handleGoNextAndCreate = async () => {
    if (!isLastStep(currentStep, maxStep)) {
      goNext()
    }

    if (isLastStep(currentStep, maxStep)) {
      try {
        const ruleCriteria: CreateNotificationRuleCriteria = {
          name: ruleName,
          description,
          mode: modeLocal,
          status: ruleStatus,
          criteria: {
            assetIds: assetId ? [assetId] : [],
            awsAccountRegions: accountsRegions.reduce(
              (acc: Array<RuleAwsAccountRegion>, item) => {
                const [accountId, region] = item.split('||')
                if (accountId && region) {
                  return [
                    ...acc,
                    {
                      accountId,
                      region,
                    },
                  ]
                }
                return acc
              },
              []
            ),
            ovaAccountProviders: accountsBackupProviders.reduce(
              (acc: Array<RuleOvaAccountProviders>, item) => {
                const [accountId, provider] = item.split('||')
                if (accountId && provider) {
                  return [
                    ...acc,
                    {
                      accountId,
                      provider: provider as unknown as RuleOvaBackupProvider,
                    },
                  ]
                }
                return acc
              },
              []
            ),
            eventKinds: eventKindLocal,
            tags: {
              operator: tagsOperator,
              tags: tags.map((tag) => ({
                key: tag.tagKey,
                value: tag.tagValue,
              })),
            },
          },
          channels: {
            slack: slackIntegration,
            webhooks: webhooks.map((webhook) => ({
              webhookId: webhook.value as unknown as string,
            })),
            emails: emails.map((email) => ({
              email: email.email,
            })),
            tenantMemberEmails: existingConsoleUsers.map(
              (tenantMemberEmail) => ({
                customer_id: String(tenantMemberEmail.value),
              })
            ),
          },
          assetsIds: [],
        }

        if (isUpdate) {
          await notificationsRulesClient.updateNotificationRule({
            ...ruleCriteria,
            ruleId,
          })
        } else {
          await notificationsRulesClient.createNotificationRule(ruleCriteria)
        }

        handleSuccessfulStateChange()
      } catch (error) {
        if (error instanceof GrpcRequestError) {
          if (error.code === GrpcCodes.ALREADY_EXISTS) {
            if (isUpdate) {
              ToastHelper.warning('Rule can`t update')
            } else {
              ToastHelper.warning('Rule name already exists.')
            }
          }
        }
      }
    }
  }

  const handleClose = () => {
    closeDrawer()
    resetAllStores()
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography fontSize="22px" fontWeight="600">
        {actionTitle}
      </Typography>
      <Box display="flex" gap="5px" alignItems="center">
        <PreviousButton variant="muted" color="primary" size="medium" />
        <ButtonPrimary
          onClick={handleGoNextAndCreate}
          isDisabled={!isFormValid}
          variant="contained"
          color="primary"
          size="medium"
        >
          {actionButtonText}
        </ButtonPrimary>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default CreateRuleDrawerActionHeader
