import React, { useEffect, useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import ActionCard from './action-card'
import { UserSettings } from 'ui-v2/src/lib/constants/settings.constant'
import {
  useUpdateSettingMutation,
  useUserProfileQuery,
} from 'ui-v2/src/hooks/queries/users'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import { useJobsListQuery } from 'ui-v2/src/hooks/queries/job-status'
import { getOnboardingActionCardsData } from './action-card-data'

const Onboarding = () => {
  const { mutate: updateUserConfig } = useUpdateSettingMutation()
  useUserProfileQuery()

  const { data: redStackListData, isLoading: isRedStackListDataLoading } =
    useRedStackListQuery()

  const { data: jobsListData, isLoading: isJobsListDataLoading } =
    useJobsListQuery({
      enabled: !!redStackListData && redStackListData.length === 0,
    })

  const isButtonDisabled = useMemo(() => {
    if (isRedStackListDataLoading || isJobsListDataLoading) {
      return true
    }

    const isRedStackInstalled = (redStackListData?.length ?? 0) > 0
    if (isRedStackInstalled) {
      return false
    }

    const isRedStackJobInProgress = (jobsListData?.length ?? 0) > 0
    return !isRedStackJobInProgress
  }, [
    isRedStackListDataLoading,
    isJobsListDataLoading,
    redStackListData,
    jobsListData,
  ])

  useEffect(() => {
    return () => {
      updateUserConfig({
        name: UserSettings.OnBoardingCongratulationsShown,
        value: 1,
      })
    }
  }, [])

  const actionCardData = useMemo(
    () => getOnboardingActionCardsData(isButtonDisabled),
    [isButtonDisabled]
  )

  return (
    <Box p={2}>
      <Typography fontWeight={700} mb={2}>
        Onboarding
      </Typography>
      <Stack gap={2}>
        {actionCardData.map((data) => (
          <ActionCard
            key={data.to}
            title={data.title}
            description={data.description}
            to={data.to}
            buttonText={data.buttonText}
            disabled={data.disabled}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default Onboarding
