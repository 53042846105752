import { Button, styled } from '@mui/material'

const StyledButton = styled(Button)({
  fontSize: '0.75rem',
  padding: '0rem 1rem',
  borderRadius: 0,
  textTransform: 'capitalize',
})

export default StyledButton
