import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import { useDeploymentStore } from '../../store/deployment-store'

export const SelectTrigger = styled('button')(({ theme }) => {
  const { palette } = theme
  return {
    width: '100%',
    padding: '4px 14px',
    backgroundColor: palette.background.drawer,
    border: `1px solid ${palette.border.main}`,
    display: 'flex',
    color: palette.text.primary,
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '19px',
    height: '37px',
    minWidth: '158px',
    '&:hover': {
      borderColor: palette.text.primary,
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  }
})

export const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  const { palette } = theme

  return {
    color: palette.text.primary,
    padding: '8px 16px',
    borderBottom: `1px solid ${palette.border.main}`,
    fontSize: '14px',
    backgroundColor: palette.background.hover,

    '&:hover': {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      'svg path': {
        fill: palette.primary.contrastText,
      },
    },

    '&.Mui-selected': {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      'svg path': {
        fill: palette.primary.contrastText,
      },

      '&:hover': {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        'svg path': {
          fill: palette.primary.contrastText,
        },
      },
    },
  }
})

export interface Option {
  id: string
  name: string
}

interface SingleLevelSelectProps {
  options: Array<Option>
  placeholder?: string
  allOptionText?: string
  isLoading?: boolean
  onSelect?: (options: Array<Option>) => void
  disabled?: boolean
}

export default function SingleLevelSelect({
  options,
  placeholder = 'Select options',
  allOptionText = 'All',
  isLoading = false,
  onSelect,
  disabled = false,
}: SingleLevelSelectProps) {
  const { rowData } = useDeploymentStore()

  const [selectedIds, setSelectedIds] = useState(rowData?.awsRegions || [])

  const [isOpenPopover, setIsOpenPopover] = React.useState(false)
  const triggerRef = React.useRef<HTMLButtonElement>(null)

  const toggleOption = (id: string) => {
    const updatedIds: Array<string> = selectedIds.includes(id)
      ? selectedIds.filter((existingId: string) => existingId !== id)
      : [...selectedIds, id]

    setSelectedIds(updatedIds)

    if (onSelect) {
      const selectedOptions = options.filter((option) =>
        updatedIds.includes(option.id)
      )
      onSelect(selectedOptions)
    }
  }

  const handleTriggerClick = () => {
    setIsOpenPopover(true)
  }

  const handleClose = () => {
    setIsOpenPopover(false)
  }

  const handleSelectAll = () => {
    const allIds = options.map((option) => option.id)
    const updatedIds = selectedIds.length === allIds.length ? [] : allIds

    setSelectedIds(updatedIds)

    if (onSelect) {
      const selectedOptions = options.filter((option) =>
        updatedIds.includes(option.id)
      )
      onSelect(selectedOptions)
    }
  }

  const handleClear = () => {
    setSelectedIds([])
    if (onSelect) {
      onSelect([])
    }
  }

  const getDisplayText = () => {
    if (isLoading) {
      return 'Loading...'
    }

    if (selectedIds.length === 0) {
      return placeholder
    }

    return `${selectedIds.length} selected`
  }

  return (
    <Box component="div" className="w-[300px]">
      <SelectTrigger
        ref={triggerRef}
        onClick={handleTriggerClick}
        disabled={isLoading || disabled}
        type="button"
      >
        <Box component="span" alignItems="center">
          {isLoading ? (
            <>
              <CircularProgress size={20} color="inherit" /> Loading...
            </>
          ) : (
            getDisplayText()
          )}
        </Box>
        <ArrowDropDownIcon className="h-4 w-4" />
      </SelectTrigger>
      <Popover
        open={isOpenPopover}
        anchorEl={triggerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              width: '200px',
              padding: 0,
              '& > :first-child': {
                padding: 0,
              },
            },
          },
        }}
      >
        <Box p={2}>
          <Box
            sx={{
              padding: 1,
              display: 'flex',
              gap: 2,
            }}
          >
            <Button
              onClick={handleSelectAll}
              variant={'contained'}
              size="small"
              sx={{
                textTransform: 'none',
                height: 32,
                fontSize: 14,
                padding: '5px 16px',
              }}
            >
              {allOptionText}
            </Button>
            <Button
              onClick={handleClear}
              variant={'contained'}
              size="small"
              sx={{
                textTransform: 'none',
                height: 32,
                fontSize: 14,
                padding: '5px 16px',
              }}
            >
              Clear
            </Button>
          </Box>

          <Box>
            {options.map((option) => {
              const isSelected = selectedIds.includes(option.id)
              return (
                <StyledMenuItem
                  key={option.id}
                  onClick={() => toggleOption(option.id)}
                  selected={isSelected}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box component="span">{option.name}</Box>
                </StyledMenuItem>
              )
            })}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
