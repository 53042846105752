/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import { useAlertsListStore } from './store/alerts-list-store'
import { useDeleteNotificationRules } from 'ui-v2/src/hooks/queries/alerts/delete-notification-rules'
import { useDisableNotificationRules } from 'ui-v2/src/hooks/queries/alerts/disable-notification-rules'
import { useEnableNotificationRules } from 'ui-v2/src/hooks/queries/alerts/enable-notification-rules'
import { changeStatusForRules } from './utils'
import { RuleStatus } from 'blues-corejs/dist/models/notifications/rule'
import { useToast } from 'ui-v2/src/hooks/toast'

export function useAlertsActions() {
  const {
    setSelectedRows,
    tableData,
    setTableData,
    setLoading: setLoadingStore,
  } = useAlertsListStore()
  const toast = useToast()
  const { mutate: requestDeleteRules } = useDeleteNotificationRules()
  const { mutate: requestDisableRules } = useDisableNotificationRules()
  const { mutate: requestEnableRules } = useEnableNotificationRules()
  const [isLoading, setLoading] = useState(false)

  const handleEnableRule = async (ids: Array<string>) => {
    setLoading(true)
    setLoadingStore(true)
    requestEnableRules(ids, {
      onSuccess: () => {
        setSelectedRows([])
        setTableData(
          changeStatusForRules({
            rulesList: tableData,
            changedIds: ids,
            newStatus: RuleStatus.enabled,
          })
        )
        toast.success('Rules enabled')
      },
      onError: (error) => {
        toast.error(error?.message ?? 'Error during rules enabling')
      },
      onSettled: () => {
        setLoading(false)
        setLoadingStore(false)
      },
    })
  }

  const handleDisableRule = async (ids: Array<string>) => {
    requestDisableRules(ids, {
      onSuccess: () => {
        toast.success('Rules disabled')
        setSelectedRows([])
        setTableData(
          changeStatusForRules({
            rulesList: tableData,
            changedIds: ids,
            newStatus: RuleStatus.disabled,
          })
        )
      },
      onError: (error) => {
        toast.error(error?.message ?? 'Error during rules disabling')
      },
      onSettled: () => {
        setLoading(false)
        setLoadingStore(false)
      },
    })
  }

  const handleDeleteRule = async (ids: Array<string>) => {
    requestDeleteRules(ids, {
      onSuccess: () => {
        setSelectedRows([])
        setTableData(tableData.filter((rule) => !ids.includes(rule.id)))
        toast.success('Rules deleted')
      },
      onError: (error) => {
        toast.error(error?.message ?? 'Error during rules deleting')
      },
      onSettled: () => {
        setLoading(false)
        setLoadingStore(false)
      },
    })
  }

  return {
    handleEnableRule,
    handleDisableRule,
    handleDeleteRule,
    isLoading,
  }
}
