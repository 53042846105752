/* eslint-disable import/no-extraneous-dependencies */
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import { SnapshotOutput } from 'blues-corejs/dist'
import { IconButton, Stack } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { getRiskStatus } from '../utils'
import { AssetDetailsLink } from 'ui-v2/src/components/ui/data-display/asset-details-link'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

export const getVolumesVulnerabilitiesColumns = (): Array<
  Column<SnapshotOutput>
> => [
  {
    key: 'volumeAwsId',
    header: 'Risk',
    render: (row) => {
      const status = getRiskStatus(row.riskVulnerabilities)
      return <StatusChip {...status} />
    },
    width: 80,
    align: 'center',
  },
  {
    key: 'snapshotId',
    header: 'Snapshot ID',
    render: (row) => (
      <AssetDetailsLink
        assetId={row.volumeId}
        assetKind={ASSETS_ROUTE_SEGMENTS.EBS}
      >
        {row.snapshotId}
      </AssetDetailsLink>
    ),
  },
  {
    key: 'vulnerabilitiesList',
    header: 'Misconfiguration',
    render: (row) =>
      row.vulnerabilitiesList
        .map((riskVulnerabilities) => riskVulnerabilities)
        .join(', '),
    wrap: true,
  },
  {
    key: 'volumeId',
    header: 'Volume ID',
    render: (row) => (
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {row.volumeAwsId}
      </Stack>
    ),
  },
  {
    key: 'accountId',
    header: 'Account ID',
    render: (row) => (
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {row.accountId}
        <IconButton
          onClick={() => copyToClipboard(row.accountId)}
          size="small"
          sx={{
            padding: '6px',
            '& svg': { fontSize: 16 },
          }}
        >
          <ContentCopy />
        </IconButton>
      </Stack>
    ),
  },
  {
    key: 'region',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.region)
      return region?.en?.single ?? ''
    },
  },
]
