import { Typography } from '@mui/material'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { PolicyEc2EbsTableRow } from 'ui-v2/src/lib/models/settings/policies/policies'

export const getEc2EbsColumns = (): Array<Column<PolicyEc2EbsTableRow>> => [
  {
    key: 'resourceId',
    header: 'Resource ID',
    render: (row) => <>{row.resourceId}</>,
  },

  {
    key: 'accountId',
    header: 'Account ID',
    render: (row) => row.accountId,
  },

  {
    key: 'lastBackup',
    header: 'Last Backup',
    render: (row) => row.lastBackup,
  },
  {
    key: 'policy',
    header: 'Policy',
    render: (row) => (
      <Typography whiteSpace={'normal'} sx={{ wordBreak: 'break-word' }}>
        {row.policy}
      </Typography>
    ),
  },
]
