import { useMemo } from 'react'
import { useListOvaAccountsQuery } from 'ui-v2/src/hooks/queries/ova-accounts'
import MultiSelectChip from 'ui-v2/src/components/ui/inputs/multi-select-chip'
import { ReportForm } from 'ui-v2/src/lib/models/reports'
import { useFormContext } from 'react-hook-form'

const OvaAccount = ({
  value,
  error,
  helperText,
  onChange,
}: OvaAccountProps) => {
  const { watch } = useFormContext<ReportForm>()
  const backupProvider = watch('backupProvider')

  const { data } = useListOvaAccountsQuery()

  const options = useMemo(() => {
    if (!data) {
      return []
    }

    if (backupProvider.length === 0) {
      return data.map((account) => ({
        label: account.name,
        value: account.id,
      }))
    } else {
      return data
        .filter((account) =>
          account.backupProvidersList.some((provider) =>
            backupProvider.includes(String(provider))
          )
        )
        .map((account) => ({
          label: account.name,
          value: account.id,
        }))
    }
  }, [data, backupProvider])

  return (
    <MultiSelectChip
      options={options}
      selectedOptions={value}
      size="small"
      placeholder="Select"
      setSelectedOptions={onChange}
      error={error}
      helperText={helperText}
    />
  )
}
interface OvaAccountProps {
  value: Array<string>
  error?: boolean
  helperText?: string
  onChange: (options: Array<string>) => void
}
export default OvaAccount
