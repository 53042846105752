/* eslint-disable import/no-extraneous-dependencies */
import { Job as ProtobufJob } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/tenant/job_pb'
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'

export class TenantJobKindTransformer {
  #jobKind: ProtobufJob.Kind

  constructor(jobKind: ProtobufJob.Kind) {
    this.#jobKind = jobKind
  }

  transform(): TenantJobKind {
    // @ts-ignore
    const mapping: Record<ProtobufJob.Kind, TenantJobKind> = {
      [ProtobufJob.Kind.KIND_INSTALL_CLOUD_CONNECTOR]:
        TenantJobKind.INSTALL_CLOUD_CONNECTOR,
      [ProtobufJob.Kind.KIND_CREATE_VAULT]: TenantJobKind.CREATE_VAULT,
      [ProtobufJob.Kind.KIND_DESTROY_VAULT]: TenantJobKind.DESTROY_VAULT,
      [ProtobufJob.Kind.KIND_REFRESH_PLANS]: TenantJobKind.REFRESH_PLANS,
      [ProtobufJob.Kind.KIND_SET_PLAN_STATUS]: TenantJobKind.SET_PLAN_STATUS,
      [ProtobufJob.Kind.KIND_RESYNC]: TenantJobKind.RESYNC,
      [ProtobufJob.Kind.KIND_ACTIVATE_CLOUD_CONNECTOR]:
        TenantJobKind.ACTIVATE_CLOUD_CONNECTOR,
      [ProtobufJob.Kind.KIND_ANALYZE_CLOUD_CONNECTOR_TARGET_REGION]:
        TenantJobKind.ANALYZE_CLOUD_CONNECTOR_TARGET_REGION,
      [ProtobufJob.Kind.KIND_EVALUATE_PLANS]: TenantJobKind.EVALUATE_PLANS,
      [ProtobufJob.Kind.KIND_SCAN_INVENTORY]: TenantJobKind.SCAN_INVENTORY,
      [ProtobufJob.Kind.KIND_ENABLE_ELASTIO_REPORTS_GATHERING]:
        TenantJobKind.ENABLE_ELASTIO_REPORTS_GATHERING,
      [ProtobufJob.Kind.KIND_DISABLE_ELASTIO_REPORTS_GATHERING]:
        TenantJobKind.DISABLE_ELASTIO_REPORTS_GATHERING,
      [ProtobufJob.Kind.KIND_UPGRADE_CLOUD_CONNECTOR]:
        TenantJobKind.UPGRADE_CLOUD_CONNECTOR,
      [ProtobufJob.Kind.KIND_UPGRADE_VAULT]: TenantJobKind.UPGRADE_VAULT,
      [ProtobufJob.Kind.KIND_REPAIR_REPORT_GATHERING_INFRA]:
        TenantJobKind.REPAIR_REPORT_GATHERING_INFRA,
      [ProtobufJob.Kind.KIND_CLEANUP_RED_STACK_DATA]:
        TenantJobKind.CLEANUP_RED_STACK_DATA,
      [ProtobufJob.Kind.KIND_REPAIR_CLOUD_CONNECTOR]:
        TenantJobKind.REPAIR_CLOUD_CONNECTOR,
      [ProtobufJob.Kind.KIND_REPAIR_VAULT]: TenantJobKind.REPAIR_VAULT,
      [ProtobufJob.Kind.KIND_UPDATE_VAULT_SUBNETS]:
        TenantJobKind.UPDATE_VAULT_SUBNETS,
      [ProtobufJob.Kind.KIND_CLOUD_CONNECTOR_INTEGRITY_CHECK]:
        TenantJobKind.CLOUD_CONNECTOR_INTEGRITY_CHECK,
      [ProtobufJob.Kind.KIND_CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK]:
        TenantJobKind.CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK,
      [ProtobufJob.Kind.KIND_VAULT_INFRASTRUCTURE_CHECK]:
        TenantJobKind.VAULT_INFRASTRUCTURE_CHECK,
      [ProtobufJob.Kind.KIND_VAULT_NETWORKING_CHECK]:
        TenantJobKind.VAULT_NETWORKING_CHECK,
      [ProtobufJob.Kind.KIND_EXECUTE_BACKUP_POLICY]:
        TenantJobKind.EXECUTE_BACKUP_POLICY,

      [ProtobufJob.Kind.KIND_CLEANUP_BACKUP_POLICY_COVERAGE]:
        TenantJobKind.CLEANUP_BACKUP_POLICY_COVERAGE,
      [ProtobufJob.Kind.KIND_REFRESH_CONNECTOR_RETENTION_POLICIES]:
        TenantJobKind.REFRESH_CONNECTOR_RETENTION_POLICIES,
      [ProtobufJob.Kind.KIND_EVALUATE_RETENTION_POLICIES]:
        TenantJobKind.EVALUATE_RETENTION_POLICIES,
      [ProtobufJob.Kind.KIND_UPDATE_VAULT]: TenantJobKind.UPDATE_VAULT,
      [ProtobufJob.Kind.KIND_EVALUATE_ASSETS_INTEGRITY_STATUS]:
        TenantJobKind.EVALUATE_ASSETS_INTEGRITY_STATUS,

      [ProtobufJob.Kind.KIND_REFRESH_ASSET_THREAT_STATUSES]:
        TenantJobKind.REFRESH_ASSET_THREAT_STATUSES,
      [ProtobufJob.Kind.KIND_GENERATE_SCHEDULED_REPORT]:
        TenantJobKind.GENERATE_SCHEDULED_REPORT,
      [ProtobufJob.Kind.KIND_DELETE_CLOUD_CONNECTOR]:
        TenantJobKind.DELETE_CLOUD_CONNECTOR,
    }

    return mapping[this.#jobKind]
  }
}

export function getHumanReadableTenantJobKind(kind: TenantJobKind): string {
  const mapping: Record<TenantJobKind, string> = {
    [TenantJobKind.INSTALL_CLOUD_CONNECTOR]: 'Install Cloud Connector',
    [TenantJobKind.CREATE_VAULT]: 'Create Scan Cluster',
    [TenantJobKind.DESTROY_VAULT]: 'Destroy Scan Cluster',
    [TenantJobKind.REFRESH_PLANS]: 'Refresh Policies',
    [TenantJobKind.SET_PLAN_STATUS]: 'Set Plan Status',
    [TenantJobKind.RESYNC]: 'Resync',
    [TenantJobKind.ACTIVATE_CLOUD_CONNECTOR]: 'Activate Cloud Connector',
    [TenantJobKind.ANALYZE_CLOUD_CONNECTOR_TARGET_REGION]:
      'Analyze Cloud Connector Target Region',
    [TenantJobKind.EVALUATE_PLANS]: 'Evaluate Policies',
    [TenantJobKind.SCAN_INVENTORY]: 'Scan Inventory',
    [TenantJobKind.ENABLE_ELASTIO_REPORTS_GATHERING]:
      'Enable Elastio Reports Gathering',
    [TenantJobKind.DISABLE_ELASTIO_REPORTS_GATHERING]:
      'Disable Elastio Reports Gathering',
    [TenantJobKind.UPGRADE_CLOUD_CONNECTOR]: 'Upgrade Cloud Connector',
    [TenantJobKind.UPGRADE_VAULT]: 'Upgrade Scan Cluster',
    [TenantJobKind.REPAIR_REPORT_GATHERING_INFRA]:
      'Repair Report Gathering Infra',
    [TenantJobKind.CLEANUP_RED_STACK_DATA]: 'Cleanup Red Stack Data',
    [TenantJobKind.REPAIR_CLOUD_CONNECTOR]: 'Repair Cloud Connector',
    [TenantJobKind.REPAIR_VAULT]: 'Repair Scan Cluster',
    [TenantJobKind.UPDATE_VAULT_SUBNETS]: 'Update Scan Cluster Subnets',
    [TenantJobKind.CLOUD_CONNECTOR_INTEGRITY_CHECK]:
      'Cloud Connector Integrity Check',
    [TenantJobKind.CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK]:
      'Cloud Connector Infrastructure Check',
    [TenantJobKind.VAULT_INFRASTRUCTURE_CHECK]:
      'Scan Cluster Infrastructure Check',
    [TenantJobKind.VAULT_NETWORKING_CHECK]: 'Scan Cluster Networking Check',
    [TenantJobKind.EXECUTE_BACKUP_POLICY]: 'Execute Backup Policy',
    [TenantJobKind.CLEANUP_BACKUP_POLICY_COVERAGE]:
      'Cleanup Backup Policy Coverage',
    [TenantJobKind.REFRESH_CONNECTOR_RETENTION_POLICIES]:
      'Refresh Connector Retention Policies',
    [TenantJobKind.EVALUATE_RETENTION_POLICIES]: 'Evaluate Retention Policies',
    [TenantJobKind.UPDATE_VAULT]: 'Update Scan Cluster',
    [TenantJobKind.EVALUATE_ASSETS_INTEGRITY_STATUS]:
      'Evaluate Assets Integrity Status',
    [TenantJobKind.REFRESH_ASSET_THREAT_STATUSES]:
      'Refresh Asset Threat Statuses',
    [TenantJobKind.GENERATE_SCHEDULED_REPORT]: 'Generate Scheduled Report',
    [TenantJobKind.DELETE_CLOUD_CONNECTOR]: 'Delete Cloud Connector',
  }

  const humanReadableJobKind = mapping[kind]

  return humanReadableJobKind || 'Unknown job kind'
}

export const DEPLOYMENT_JOB_KINDS = [
  TenantJobKind.INSTALL_CLOUD_CONNECTOR,
  TenantJobKind.ACTIVATE_CLOUD_CONNECTOR,
  TenantJobKind.UPGRADE_CLOUD_CONNECTOR,
  TenantJobKind.REPAIR_CLOUD_CONNECTOR,
  TenantJobKind.CREATE_VAULT,
  TenantJobKind.REPAIR_VAULT,
  TenantJobKind.DESTROY_VAULT,
  TenantJobKind.UPDATE_VAULT_SUBNETS,
  TenantJobKind.DELETE_CLOUD_CONNECTOR,
  TenantJobKind.ENABLE_ELASTIO_REPORTS_GATHERING,
  TenantJobKind.DISABLE_ELASTIO_REPORTS_GATHERING,
  TenantJobKind.REPAIR_REPORT_GATHERING_INFRA,
]

export const SYSTEM_JOB_KINDS = [
  TenantJobKind.REFRESH_PLANS,
  TenantJobKind.SET_PLAN_STATUS,
  TenantJobKind.EVALUATE_PLANS,
  TenantJobKind.CLOUD_CONNECTOR_INTEGRITY_CHECK,
  TenantJobKind.EXECUTE_BACKUP_POLICY,
  TenantJobKind.EVALUATE_RETENTION_POLICIES,
  TenantJobKind.CLEANUP_BACKUP_POLICY_COVERAGE,
  TenantJobKind.REFRESH_CONNECTOR_RETENTION_POLICIES,
  TenantJobKind.EVALUATE_ASSETS_INTEGRITY_STATUS,
  TenantJobKind.REFRESH_ASSET_THREAT_STATUSES,
  TenantJobKind.GENERATE_SCHEDULED_REPORT,
  TenantJobKind.CLEANUP_RED_STACK_DATA,
]
