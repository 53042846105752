// TODO: remove this after we migrate to the new assets page completely for the drilldowns
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Link from './link'
import { setAssetDetailsSourcePath } from 'ui-v2/src/store/features/navigation/asset-details-source-path-slice'
import {
  BASE_ROUTE_SEGMENTS,
  ASSETS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

type AssetDetailsLinkProps = {
  assetId: string
  assetKind: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
  children: React.ReactNode
}

export const AssetDetailsLink: React.FC<AssetDetailsLinkProps> = ({
  assetId,
  assetKind,
  children,
  ...props
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const getAssetPath = () => {
    return `/${BASE_ROUTE_SEGMENTS.ASSETS}/${assetKind}/details/${assetId}`
  }

  const handleClick = () => {
    dispatch(setAssetDetailsSourcePath(location.pathname + location.search))
  }

  return (
    <Link to={getAssetPath()} onClick={handleClick} {...props}>
      {children}
    </Link>
  )
}
