import React, { useMemo } from 'react'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import { useAssetDetails } from '../common/use-asset-details'
import LoadingContent from '../common/loading'
import SuccessContent from './success'
import { useAssetsListScansQuery } from 'ui-v2/src/hooks/queries/scans'
import { extractScanResults } from 'ui-v2/src/lib/helpers/scans.helper'
import WidgetCard from '../common/widget-card'
import { getAssetIdsToScan } from '../../utils'

const ScanDetailsWidget = () => {
  const {
    parentAsset,
    parentAssetKind,
    isAssetsDataLoading,
    isAssetsDataError,
  } = useAssetDetails()

  const assetIdList = getAssetIdsToScan(parentAsset)

  const {
    data: assetsListScans,
    isLoading: isLoadingAssetsListScans,
    isError: isAssetScanError,
  } = useAssetsListScansQuery(
    {
      assetIdList,
      pageSize: 1,
    },
    {
      enabled: assetIdList.length > 0,
    }
  )

  const scans = useMemo(() => {
    if (!assetsListScans) {
      return []
    }
    return extractScanResults(assetsListScans.scansRunList)
  }, [assetsListScans])

  const renderContent = () => {
    if (isAssetsDataLoading || isLoadingAssetsListScans) {
      return <LoadingContent />
    }
    if (isAssetsDataError || isAssetScanError) {
      return (
        <ErrorDisplay message="Failed to load widget, please try again later." />
      )
    }

    const scansLength = scans.length

    const scanResult =
      scansLength === 0
        ? ''
        : scans.some((scanlist) => scanlist.isInfected)
          ? 'Infected'
          : 'Clean'

    return (
      <SuccessContent
        asset={parentAsset}
        assetKind={scansLength ? parentAssetKind : ''}
        scanResult={scanResult}
      />
    )
  }

  return <WidgetCard>{renderContent()}</WidgetCard>
}

export default ScanDetailsWidget
