import { useAwsBackupCoverageQuery } from '../queries/aws-stats'

const useAwsBackupCoverageData = () => {
  const { normalizedData, isLoading } = useAwsBackupCoverageQuery({})

  return {
    data: normalizedData,
    isLoading,
  }
}

export default useAwsBackupCoverageData
