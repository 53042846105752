/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { Typography } from '@mui/material'
import DoughnutChart from '../dashboard-charts/doughnut-chart'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { calculateTotalVulnerabilitiesByRisk } from 'ui-v2/src/views/dashboard/assets-vulnerabilities/utils'
import { useAwsStorageMisconfigurationQuery } from 'ui-v2/src/hooks/queries/aws-stats/storage-misconfigurations'
import { useNavigate } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { RiskLevel } from 'blues-corejs/dist'

export const StorageHealth = () => {
  const { data: awsAssetVulnerabilitiesStatsData } =
    useAwsStorageMisconfigurationQuery({})
  const navigate = useNavigate()

  const chartData = useMemo(() => {
    if (!awsAssetVulnerabilitiesStatsData) {
      return {
        risks: {
          low: {
            count: 0,
            percentage: 0,
          },
          medium: {
            count: 0,
            percentage: 0,
          },
          high: {
            count: 0,
            percentage: 0,
          },
        },
        totalRisks: 0,
      }
    }

    const snapshotVulnerabilitiesList =
      awsAssetVulnerabilitiesStatsData.snapshotVulnerabilitiesList
    const volumeVulnerabilitiesList =
      awsAssetVulnerabilitiesStatsData.volumeVulnerabilitiesList

    const risks = calculateTotalVulnerabilitiesByRisk({
      volumeVulnerabilitiesList,
      snapshotVulnerabilitiesList,
    })

    const totalRisks = risks.high + risks.medium + risks.low
    const lowRiskPercentage = Math.round((risks.low / totalRisks) * 100) || 0
    const mediumRiskPercentage =
      Math.round((risks.medium / totalRisks) * 100) || 0
    const highRiskPercentage = Math.round((risks.high / totalRisks) * 100) || 0

    return {
      risks: {
        low: {
          count: risks.low,
          percentage: lowRiskPercentage,
        },
        medium: {
          count: risks.medium,
          percentage: mediumRiskPercentage,
        },
        high: {
          count: risks.high,
          percentage: highRiskPercentage,
        },
      },
      totalRisks,
    }
  }, [awsAssetVulnerabilitiesStatsData])

  const pathtoHighRiskVolumes = `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.ASSET_VULNERABILITIES}?t=1&riskType=${RiskLevel.High}`
  const pathtoMediumRiskVolumes = `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.ASSET_VULNERABILITIES}?t=1&riskType=${RiskLevel.Medium}`
  const pathtoLowRiskVolumes = `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.ASSET_VULNERABILITIES}?t=1&riskType=${RiskLevel.Low}`

  const onChartClick = (componentIndex: number) => {
    switch (componentIndex) {
      case 0:
        navigate(pathtoHighRiskVolumes)
        break
      case 1:
        navigate(pathtoMediumRiskVolumes)
        break
      case 2:
        navigate(pathtoLowRiskVolumes)
        break
      default:
    }
  }

  const onChartLegendClick = (legendName: string | undefined) => {
    if (!legendName) {
      return
    }
    if (legendName.toLowerCase().includes('high')) {
      navigate(pathtoHighRiskVolumes)
    }
    if (legendName.toLowerCase().includes('medium')) {
      navigate(pathtoMediumRiskVolumes)
    }
    if (legendName.toLowerCase().includes('low')) {
      navigate(pathtoLowRiskVolumes)
    }
  }

  return (
    <GradientCard>
      <Typography
        fontSize={16}
        fontWeight={700}
        mb={1}
        color="text.secondary"
        sx={{ position: 'relative' }}
      >
        Storage Health
      </Typography>
      <DoughnutChart
        title={'Storage Health'}
        count={chartData.totalRisks}
        data={[
          {
            value: chartData.risks.high.percentage,
            name: `High  {bold|${chartData.risks.high.count}/${chartData.risks.high.percentage}%}`,
            itemStyle: { color: '#FF1654' },
          },
          {
            value: chartData.risks.medium.percentage,
            name: `Medium  {bold|${chartData.risks.medium.count}/${chartData.risks.medium.percentage}%}`,
            itemStyle: { color: '#E28800' },
          },
          {
            value: chartData.risks.low.percentage,
            name: `Low  {bold|${chartData.risks.low.count}/${chartData.risks.low.percentage}%}`,
            itemStyle: { color: '#FFE99C' },
          },
        ]}
        onClick={onChartClick}
        onChartLegendClick={onChartLegendClick}
        graphicPosition={{
          top: '47%',
        }}
      />
    </GradientCard>
  )
}

export default StorageHealth
