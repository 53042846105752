import { ErrorOutline } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'

interface ErrorDisplayProps {
  message?: string
  retryFn?: () => void
}

const ErrorDisplay = ({
  message = 'Something went wrong',
  retryFn,
}: ErrorDisplayProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '100%',
        py: 4,
        px: 2,
      }}
      spacing={2}
    >
      <ErrorOutline color="error" sx={{ fontSize: 40 }} />
      <Typography variant="body2" color="error.main" textAlign="center">
        {message}
      </Typography>
      {retryFn ? <Button onClick={retryFn}>Retry</Button> : null}
    </Stack>
  )
}

export default ErrorDisplay
