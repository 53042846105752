import { AssetKind } from '../constants/assets.constant'
import DurationConstant from '../constants/duration.constant'
import FrequencyConstant from '../constants/frequency.constant'
import PeriodConstant from '../constants/period.constant'
import { SelectedVariant } from '../constants/policies.constant'
import RegexConstants from '../constants/regex.constant'
import { CloudConnectorProblems } from '../constants/sources.constant'
import { ValueInterface, VIRow } from '../engine-types'
import { RedStackModel } from '../models/base-model'
import CfnModel from '../models/red-stack/cfn.model'
import { AssetType } from '../models/settings/policies/policies'
import ScheduleInterface from '../models/settings/policies/schedule'
import VaultModel from '../models/settings/policies/vault.model'
import {
  FormPeriodInterface,
  RetentionDurationInterface,
  RetentionPolicy,
  SelectCategory,
  Selector,
} from '../models/settings/retention-policy/retention-policy.model'
import LangHelper from './lang.helper'
import { numberHash } from './num.helper'
import { isSubstring } from './string.helper'
import { getDateWithTimezone } from './time.helper'
/* eslint-disable import/no-extraneous-dependencies */
import { AssetAccount } from 'blues-corejs/dist'

export const calculateNextRunForInterval = (
  schedule: ScheduleInterface,
  intervalInMinutes: number
): string => {
  const { windowStart, windowEnd } = schedule

  const currentTime = getDateWithTimezone(Date.now())
  const currentTimeInPolicyTimeZone = getDateWithTimezone(
    new Date().getTime(),
    windowStart.timezone
  )

  const startDatetime = currentTimeInPolicyTimeZone
    .clone()
    .hour(windowStart.hour)
    .minute(windowStart.minute)
    .second(0)
  const endDatetime = currentTimeInPolicyTimeZone
    .clone()
    .hour(windowEnd.hour)
    .minute(windowEnd.minute)
    .second(0)

  if (startDatetime.diff(endDatetime, 'ms') > 0) {
    endDatetime.add(1, 'day')
  }

  // If currentTime is in the range between start and end time, then take % of 30 and use the remainder (currentTime) - nextRun should not be greater than endTime, if it is, then nextRun = startDatetime + 1 day
  // If currentTime is not in the range between start and endTime, but currentTime > endTime, then nextRun === startTime + 1 day,
  // if currentTime < endTime, nextRun ==== startTime
  if (currentTime.isBetween(startDatetime, endDatetime)) {
    const remainder =
      intervalInMinutes - (currentTime.clone().minute() % intervalInMinutes)

    const nextRun = currentTime.clone().add(remainder, 'm').second(0)

    if (nextRun.diff(endDatetime, 'ms') > 0) {
      return `Next run: ${startDatetime.clone().add(1, 'day')}`
    }

    return `Next run: ${nextRun}`
  }

  if (currentTime.diff(endDatetime, 'ms') > 0) {
    return `Next run: ${startDatetime.clone().add(1, 'day')}`
  }

  return `Next run: ${startDatetime}`
}

export const nextRunInterval12HoursLabel = (
  schedule: ScheduleInterface
): string => {
  const { windowStart } = schedule

  const currentTime = getDateWithTimezone(Date.now())
  const currentTimeInPolicyTimeZone = getDateWithTimezone(
    new Date().getTime(),
    windowStart.timezone
  )
  const startDatetime = currentTimeInPolicyTimeZone
    .clone()
    .hour(windowStart.hour)
    .minute(windowStart.minute)
    .second(0)

  const between = currentTime.isBetween(
    startDatetime,
    startDatetime.clone().add(12, 'h')
  )

  if (between) {
    return `Next run: ${startDatetime.clone().add(12, 'h')}`
  }

  return `Next run: ${startDatetime.clone().add(1, 'day')}`
}

export const nextRunIntervalDailyLabel = (
  schedule: ScheduleInterface
): string => {
  const { windowStart, daysList } = schedule

  const currentTime = getDateWithTimezone(Date.now())
  const currentTimeInPolicyTimeZone = getDateWithTimezone(
    new Date().getTime(),
    windowStart.timezone
  )

  let startDatetime = currentTimeInPolicyTimeZone
    .clone()
    .hour(windowStart.hour)
    .minute(windowStart.minute)
    .second(0)

  const dayNamesList = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]

  for (let day = 0; day < 7; day++) {
    const datetime = startDatetime.clone().add(day, 'd')
    const datetimeDay = datetime.day()

    // @ts-ignore
    if (daysList[dayNamesList[datetimeDay]]) {
      startDatetime = datetime
      break
    }
  }

  const diff = currentTime.diff(startDatetime, 'ms')

  if (diff > 0) {
    let nextRun = startDatetime.clone().add(24, 'h')

    for (let day = 0; day < 7; day++) {
      const datetime = nextRun.clone().add(day, 'd')
      const datetimeDay = datetime.day()

      // @ts-ignore
      if (daysList[dayNamesList[datetimeDay]]) {
        nextRun = datetime
        break
      }
    }

    return `Next run: ${nextRun}`
  }

  return `Next run: ${startDatetime}`
}

export const nextRunIntervalWeeklyLabel = (
  schedule: ScheduleInterface
): string => {
  const { windowStart, dayOfWeek } = schedule

  const dayInPolicy = dayOfWeek.value

  const currentTime = getDateWithTimezone(Date.now())
  const currentTimeInPolicyTimeZone = getDateWithTimezone(
    new Date().getTime(),
    windowStart.timezone
  )

  let startDatetime = currentTimeInPolicyTimeZone
    .clone()
    .hour(windowStart.hour)
    .minute(windowStart.minute)
    .second(0)

  for (let day = 0; day < 7; day++) {
    const datetime = startDatetime.clone().add(day, 'd')
    const datetimeDay = datetime.day()

    if (datetimeDay === dayInPolicy) {
      startDatetime = datetime
      break
    }
  }

  const diff = currentTime.diff(startDatetime, 'ms')

  if (diff > 0) {
    return `Next run: ${startDatetime.clone().add(7, 'd')}`
  }

  return `Next run: ${startDatetime}`
}

export const nextRunIntervalMonthlyLabel = (
  schedule: ScheduleInterface
): string => {
  const { windowStart, dayOfMonth } = schedule

  const currentTime = getDateWithTimezone(Date.now())
  const currentTimeInPolicyTimeZone = getDateWithTimezone(
    new Date().getTime(),
    windowStart.timezone
  )

  const lastDayOfTheMonth = currentTime.clone().endOf('month')
  const startDatetime = currentTimeInPolicyTimeZone
    .clone()
    .date(dayOfMonth > 0 ? dayOfMonth : lastDayOfTheMonth.date() + dayOfMonth)
    .hour(windowStart.hour)
    .minute(windowStart.minute)
    .second(0)
  const diff = currentTime.diff(startDatetime, 'ms')

  if (diff > 0) {
    const nextRun = startDatetime.clone().add(1, 'month')

    return `Next run: ${nextRun
      .clone()
      .date(
        dayOfMonth > 0
          ? dayOfMonth
          : nextRun.clone().endOf('month').date() + dayOfMonth
      )}`
  }

  return `Next run: ${startDatetime}`
}

export const scheduleNextRunLabel = (schedule: ScheduleInterface): string => {
  const intervalMap = {
    [FrequencyConstant.FREQUENCY_EVERY_15_MIN.name]: 15,
    [FrequencyConstant.FREQUENCY_EVERY_30_MIN.name]: 30,
    [FrequencyConstant.FREQUENCY_EVERY_HOUR.name]: 60,
  }

  const intervalInMinutes = intervalMap[schedule.frequency.name]

  if (intervalInMinutes !== undefined) {
    return calculateNextRunForInterval(schedule, intervalInMinutes)
  }

  switch (schedule.frequency.value) {
    case FrequencyConstant.FREQUENCY_EVERY_12_HOURS.value:
      return nextRunInterval12HoursLabel(schedule)
    case FrequencyConstant.FREQUENCY_DAILY.value:
      return nextRunIntervalDailyLabel(schedule)
    case FrequencyConstant.FREQUENCY_WEEKLY.value:
      return nextRunIntervalWeeklyLabel(schedule)
    case FrequencyConstant.FREQUENCY_MONTHLY.value:
      return nextRunIntervalMonthlyLabel(schedule)
    default:
      throw new Error('Cannot determine the schedule next run label')
      return ''
  }
}
export const accountRegionPairsList = (arr: Array<RedStackModel>): VIRow => {
  const result: VIRow = []
  arr
    .sort((a: RedStackModel, b: RedStackModel) => {
      const firstAccount = `${a.awsAccount} ${a.awsRegion}`
      const secondAccount = `${b.awsAccount} ${b.awsRegion}`

      return firstAccount.localeCompare(secondAccount)
    })
    .map((rs: RedStackModel) => {
      const regionName = LangHelper.getAwsRegionSingleTranslation(rs.awsRegion)
      const accountRegionLabel = `${rs.awsAccount} (${regionName})`
      result.push({
        name: accountRegionLabel,
        label: accountRegionLabel,
        value: rs.awsAccount,
        extraValue: rs.awsRegion,
      })
    })
  return result
}

export const assetAccountRegionPairsList = (
  arr: Array<AssetAccount>
): VIRow => {
  const result: VIRow = []
  arr
    .sort((a: AssetAccount, b: AssetAccount) => {
      const firstAccount = `${a.awsAccountId} ${a.stackRegion}`
      const secondAccount = `${b.awsAccountId} ${b.stackRegion}`

      return firstAccount.localeCompare(secondAccount)
    })
    .map((rs: AssetAccount) => {
      if (rs.regionStacksList.length > 0) {
        rs.regionStacksList.map((regionStack) => {
          const regionName = LangHelper.getAwsRegionSingleTranslation(
            regionStack.awsRegion
          )
          const accountRegionLabel = `${rs.awsAccountId} (${regionName})`
          result.push({
            name: accountRegionLabel,
            label: accountRegionLabel,
            value: rs.awsAccountId,
            extraValue: regionStack.awsRegion,
          })
        })
      } else {
        const regionName = LangHelper.getAwsRegionSingleTranslation(
          rs.stackRegion
        )
        const accountRegionLabel = `${rs.awsAccountId} (${regionName})`
        result.push({
          name: accountRegionLabel,
          label: accountRegionLabel,
          value: rs.awsAccountId,
          extraValue: rs.stackRegion,
        })
      }
    })
  return result
}

export const getAssetType = (
  selectedVariant: SelectedVariant
): AssetType | undefined => {
  if (selectedVariant === SelectedVariant.AllEC2Variant) {
    return AssetType.ASSET_TYPE_EC2
  }

  if (selectedVariant === SelectedVariant.AllEBSVariant) {
    return AssetType.ASSET_TYPE_EBS
  }

  if (selectedVariant === SelectedVariant.AllEC2EBSVariant) {
    return AssetType.ASSET_TYPE_EC2_EBS
  }

  if (selectedVariant === SelectedVariant.S3Variant) {
    return AssetType.ASSET_TYPE_S3
  }

  if (selectedVariant === SelectedVariant.AllS3Variant) {
    return AssetType.ASSET_TYPE_S3
  }
}
export const buildSelectedRecoveryPointTypes = (
  selectors?: Array<Selector>,
  assetSources?: VIRow,
  backupTypes?: VIRow
): VIRow => {
  const result: VIRow = []
  const editPolicyAccountIDs: VIRow = []
  const editPolicyRegions: VIRow = []
  const editPolicyVaults: VIRow = []
  const editPolicyTags: VIRow = []
  const editPolicyAssetSources = assetSources || []
  const editPolicyBackupTypes = backupTypes || []
  const editPolicyAssets: VIRow = []
  selectors?.map((selector) => {
    selector.criteriaList.map((criteria) => {
      if (criteria.awsAccountId) {
        editPolicyAccountIDs.push({
          name: criteria.awsAccountId,
        })
      }
      if (criteria.awsRegion) {
        editPolicyRegions.push({
          id: numberHash(criteria.awsRegion),
          name: criteria.awsRegion,
          label: LangHelper.getAwsRegionSingleTranslation(criteria.awsRegion),
        })
      }

      if (criteria.vault) {
        const vaultLabel = `${criteria.vault.vault} (Account ID: ${criteria.vault.awsAccountId}, Region: ${criteria.vault.region})`
        editPolicyVaults.push({
          name: String(numberHash(vaultLabel)),
          value: criteria.vault.vault,
          label: vaultLabel,
          defaultValue: criteria.vault.awsAccountId,
          extraValue: criteria.vault.region,
          supplementalValue: 0,
        })
      }

      if (criteria.tags) {
        criteria.tags.tagsMap.map((tag) => {
          const currentTag = `${tag[0]}${tag[1] ? '=' + tag[1] : ''}`
          editPolicyTags.push({
            name: tag[0],
            label: currentTag,
            value: tag[1],
          })
        })
      }

      if (criteria.assets) {
        criteria.assets.awsList.map((aws) => {
          if (aws.ebs) {
            editPolicyAssets.push({
              name: aws.ebs,
              value: aws.accountId,
              label: aws.region,
              type: AssetKind.AWS_EBS,
            })
          } else if (aws.ec2) {
            editPolicyAssets.push({
              name: aws.ec2,
              value: aws.accountId,
              label: aws.region,
              type: AssetKind.AWS_EC2,
            })
          } else {
            editPolicyAssets.push({
              name: aws.s3,
              value: aws.accountId,
              label: aws.region,
              type: AssetKind.AWS_S3,
            })
          }
        })
        criteria.assets.genericList.map((generic) => {
          editPolicyAssets.push({
            name: generic.hostname,
            value: '',
            label: '',
            type: AssetKind.GENERIC_HOST,
          })
        })
        criteria.assets.nasList.map((nas) => {
          editPolicyAssets.push({
            name: nas.hostname,
            value: '',
            label: '',
            type: AssetKind.GENERIC_FS,
          })
        })
      }
    })
  })

  if (editPolicyAccountIDs.length > 0) {
    result.push({
      name: SelectCategory.ACCOUNT_IDS,
      options: editPolicyAccountIDs,
    })
  }
  if (editPolicyRegions.length > 0) {
    result.push({
      name: SelectCategory.REGIONS,
      options: editPolicyRegions,
    })
  }
  if (editPolicyVaults.length > 0) {
    result.push({
      name: SelectCategory.VAULTS,
      options: editPolicyVaults,
    })
  }
  if (editPolicyTags.length > 0) {
    result.push({
      name: SelectCategory.TAGS,
      options: editPolicyTags,
    })
  }
  if (editPolicyAssetSources.length > 0) {
    result.push({
      name: SelectCategory.ASSET_SOURCES,
      options: editPolicyAssetSources,
    })
  }
  if (editPolicyBackupTypes.length > 0) {
    result.push({
      name: SelectCategory.BACKUP_TYPES,
      options: editPolicyBackupTypes,
    })
  }
  if (editPolicyAssets.length > 0) {
    result.push({
      name: SelectCategory.ASSETS,
      options: editPolicyAssets,
    })
  }
  return result
}

export const getRetentionPeriod = (
  period: RetentionDurationInterface
): string => {
  const result: Array<string> = []

  if (period.days) {
    result.push(`${period.days} days`)
  }
  if (period.hours) {
    result.push(`${period.hours} hours`)
  }
  if (period.weeks) {
    result.push(`${period.weeks} weeks`)
  }
  if (period.months) {
    result.push(`${period.months} months`)
  }
  if (period.years) {
    result.push(`${period.years} years`)
  }
  if (period.infinite) {
    result.push('forever')
  }

  return result.length === 0 ? '' : result.join(', ')
}

export const getRetentionString = (policy?: RetentionPolicy): string => {
  let keepAllPoints = ''
  let resultAll = ''
  const keepOnePoint = 'Keep one '
  const result: Array<string> = []
  if (policy) {
    if (policy.keepAll) {
      keepAllPoints = `Keep all ${getRetentionPeriod(policy.keepAll)}`
    }
    if (policy.keepHourly) {
      result.push(`hourly ${getRetentionPeriod(policy.keepHourly)}`)
    }
    if (policy.keepDaily) {
      result.push(`daily ${getRetentionPeriod(policy.keepDaily)}`)
    }
    if (policy.keepWeekly) {
      result.push(`weekly ${getRetentionPeriod(policy.keepWeekly)}`)
    }
    if (policy.keepMonthly) {
      result.push(`monthly ${getRetentionPeriod(policy.keepMonthly)}`)
    }
    if (policy.keepYearly) {
      result.push(`yearly ${getRetentionPeriod(policy.keepYearly)}`)
    }
  }

  resultAll = `${keepAllPoints ? keepAllPoints + '; ' : ''}${
    result.length > 0 ? keepOnePoint + result.join(', ') : ''
  }`

  return resultAll ? resultAll : 'Not assigned'
}
export const getDurationParams = (
  period: RetentionDurationInterface
): ValueInterface => {
  if (period.hours) {
    return {
      name: DurationConstant.DURATION_HOURS.name,
      label: String(period.hours),
    }
  }
  if (period.days) {
    return {
      name: DurationConstant.DURATION_DAYS.name,
      label: String(period.days),
    }
  }
  if (period.weeks) {
    return {
      name: DurationConstant.DURATION_WEEKS.name,
      label: String(period.weeks),
    }
  }
  if (period.months) {
    return {
      name: DurationConstant.DURATION_MONTHS.name,
      label: String(period.months),
    }
  }
  if (period.years) {
    return {
      name: DurationConstant.DURATION_YEARS.name,
      label: String(period.years),
    }
  }
  return {
    name: DurationConstant.DURATION_INFINITE.name,
    label: '',
  }
}
export const buildSelectedPeriodFormData = (
  policy?: RetentionPolicy
): FormPeriodInterface => {
  const result: FormPeriodInterface = { period: [] }
  if (!policy) {
    return result
  }
  if (policy.keepAll) {
    const currentDuration = getDurationParams(policy.keepAll)
    result?.period?.push({
      periodValue: PeriodConstant.PERIOD_KEEP_ALL.name,
      countDuration: currentDuration.label ?? '',
      duration: currentDuration.name,
      removable: false,
    })
  }
  if (policy.keepHourly) {
    const currentDuration = getDurationParams(policy.keepHourly)
    result?.period?.push({
      periodValue: PeriodConstant.PERIOD_KEEP_HOURLY.name,
      countDuration: currentDuration.label ?? '',
      duration: currentDuration.name,
      removable: false,
    })
  }
  if (policy.keepDaily) {
    const currentDuration = getDurationParams(policy.keepDaily)
    result?.period?.push({
      periodValue: PeriodConstant.PERIOD_KEEP_DAILY.name,
      countDuration: currentDuration.label ?? '',
      duration: currentDuration.name,
      removable: false,
    })
  }
  if (policy.keepWeekly) {
    const currentDuration = getDurationParams(policy.keepWeekly)
    result?.period?.push({
      periodValue: PeriodConstant.PERIOD_KEEP_WEEKLY.name,
      countDuration: currentDuration.label ?? '',
      duration: currentDuration.name,
      removable: false,
    })
  }
  if (policy.keepMonthly) {
    const currentDuration = getDurationParams(policy.keepMonthly)
    result?.period?.push({
      periodValue: PeriodConstant.PERIOD_KEEP_MONTHLY.name,
      countDuration: currentDuration.label ?? '',
      duration: currentDuration.name,
      removable: false,
    })
  }
  if (policy.keepYearly) {
    const currentDuration = getDurationParams(policy.keepYearly)
    result?.period?.push({
      periodValue: PeriodConstant.PERIOD_KEEP_YEARLY.name,
      countDuration: currentDuration.label ?? '',
      duration: currentDuration.name,
      removable: false,
    })
  }
  return result
}
export const getSelectedAssetsFiltersRow = (
  category: SelectCategory,
  value: VIRow
): VIRow => {
  const filtersRow: VIRow = []
  const filterOptions: VIRow = []
  const enableFilterMessage =
    'To enable filtering remove the pre-selected values from the Recovery point types selectors.'
  value.map((item) => {
    filterOptions.push({
      type: 1,
      name: item.name,
      label: item.name,
      value: true,
    })
  })
  switch (category) {
    case SelectCategory.ACCOUNT_IDS:
      if (filterOptions.length > 0) {
        filtersRow.push({
          name: 'accountId',
          label: 'Account ID',
          value: '',
          type: 2,
          disabled: true,
          supplementalValue: `The list below is filtered by the account number(s) you selected on the previous steps. ${enableFilterMessage}`,
          options: filterOptions,
        })
      }
      break
    case SelectCategory.REGIONS:
      if (filterOptions.length > 0) {
        filtersRow.push({
          name: 'regions',
          label: 'Regions',
          value: '',
          type: 2,
          disabled: true,
          supplementalValue: `The list below is filtered by the region(s) you selected on the previous steps. ${enableFilterMessage}`,
          options: filterOptions,
        })
      }
      break
    case SelectCategory.VAULTS:
      const filterVaultOptions: VIRow = []
      value.map((item) => {
        if (item.supplementalValue !== 0) {
          filterVaultOptions.push({
            type: 1,
            name: String(item.supplementalValue),
            label: item.label,
            value: true,
            defaultValue: item.defaultValue,
            extraValue: item.extraValue,
          })
        }
      })
      if (filterVaultOptions.length > 0) {
        filtersRow.push({
          name: 'vaults',
          label: 'Vaults',
          value: '',
          type: 2,
          disabled: true,
          supplementalValue: `The list below is filtered by the vault(s) you selected on the previous steps. ${enableFilterMessage}`,
          options: filterVaultOptions,
        })

        // disable accountId and regions filters in case vaults filter selected
        filtersRow.push({
          name: 'accountId',
          label: 'Account ID',
          value: '',
          type: 2,
          disabled: true,
          supplementalValue:
            'To enable filtering remove the pre-selected vault(s) values from the Recovery point types selectors.',
          options: [],
        })

        filtersRow.push({
          name: 'regions',
          label: 'Regions',
          value: '',
          type: 2,
          disabled: true,
          supplementalValue:
            'To enable filtering remove the pre-selected vault(s) values from the Recovery point types selectors.',
          options: [],
        })
      }
      break
    case SelectCategory.TAGS:
      const filterTagsOptions: VIRow = []
      value.map((item) => {
        filterTagsOptions.push({
          type: 1,
          name: String(item.label),
          label: item.label,
          value: true,
        })
      })
      if (filterTagsOptions.length > 0) {
        filtersRow.push({
          name: 'assetTags',
          label: 'Asset Tags',
          value: '',
          type: 2,
          disabled: true,
          supplementalValue: `The list below is filtered by the tag(s) you selected on the previous steps. ${enableFilterMessage}`,
          options: filterTagsOptions,
        })
      }
      break
  }

  return filtersRow
}
export const protectedObjectIdParsed = (protectedObject?: string): string => {
  // the protectedObject examples:
  // for ebs: elastio:asset:aws-ebs:s:0123456789012:eu-west-1:v-123
  // for ec2: elastio:asset:aws-ec2:s:0123456789012:eu-central-1:i-123
  // for file: elastio:asset:file:s:aws-ebs:s:0123456789012:eu-west-1:v-012:/file1
  // for local-disk: elastio:asset:local-disk:s:aws-ec2:s:0123456789012:eu-central-1:i-abcd:/dev/sda1
  // for local-volume: elastio:asset:local-volume:s:aws-ec2:s:0123456789012:eu-central-1:i-abcd:/dev/sda1
  // for nas: elastio:asset:nas:s:nas.example.com
  // for stream: elastio:asset:stream:s:generic-host:s:host.example.com:stream1
  // for generic-host: elastio:asset:generic-host:s:host.example.com

  // for protectedObject with only one value, like: C:\, D:\
  if (
    protectedObject &&
    !isSubstring(protectedObject.toLowerCase(), 'elastio')
  ) {
    return protectedObject
  }

  let result = protectedObject
    ? (protectedObject.split(':').pop() ?? protectedObject)
    : ''

  // the protectedObject example with escape symbols
  // for local-volume: elastio:asset:local-volume:s:aws-ec2:s:300892765037:us-east-1:i-01e73d64a4d4024f6:C\:\\
  // for local-volume: elastio:asset:local-volume:s:generic-host:s:block-win:C\:\\
  if (
    protectedObject &&
    (isSubstring(protectedObject, 'local-volume') ||
      isSubstring(protectedObject, 'local-disk'))
  ) {
    const splitResult = protectedObject.split(':')
    if (splitResult.length > 0) {
      splitResult.map((elem) => {
        if (elem.startsWith('i-') || elem.startsWith('block-')) {
          const localPathString = protectedObject.split(elem + ':').pop() ?? ''
          // unescape local-volume or local-disk path
          if (localPathString) {
            result = localPathString.replace(/(?:\\(.))/g, '$1')
          }
        }
      })
    }
  }
  return result
}
export const dataGroupedByAwsAccount = (
  arr: Array<RedStackModel>
): Record<string, any> => {
  const result: Record<string, any> = {}

  arr.map((rs: RedStackModel) => {
    if (result[rs.awsAccount] === undefined) {
      result[rs.awsAccount] = {
        accountAlias: rs.accountAlias,
        accountDescription: rs.accountDescription,
        awsRegions: [],
        lastErrorMessages: [],
        statuses: [],
        assetsCount: 0,
        awsAccount: rs.awsAccount,
        redStackAccount: rs.redStackId,
        lastWarningMessage: rs.lastWarningMessage,
        cfnUpgradeRequired: rs.cfnUpgradeRequired,
        // isActive: '',
      }
    }
    result[rs.awsAccount].awsRegions.push(rs.awsRegion)
    result[rs.awsAccount].lastErrorMessages.push(rs.lastErrorMessage)
    result[rs.awsAccount].statuses.push(rs.status)
    // result[rs.awsAccount].isActive.push(rs.isActive);

    return result
  })
  return result
}

export const findProblematicCC = (
  accounts: Array<RedStackModel>,
  vaults: Array<VaultModel>
) => {
  if (!accounts.length) {
    return {
      isProblem: false,
      problemsArray: [],
    }
  }

  const findReason = (redStackId: string) => {
    const redStackVaults = vaults.filter(
      (vault) => vault.redStackId === redStackId
    )
    const isNoDefaultVaults = !redStackVaults.some((vault) => vault.isDefault)

    const otherErrors = accounts.some(
      (rs) =>
        rs.redStackId === redStackId &&
        (rs.lastErrorMessage || rs.lastWarningMessage)
    )

    if (!redStackVaults.length) {
      return CloudConnectorProblems.NO_VAULTS
    }

    if (isNoDefaultVaults) {
      return CloudConnectorProblems.NO_DEFAULT
    }

    if (otherErrors) {
      return CloudConnectorProblems.BS_ERROR
    }

    return ''
  }

  const problematicRS = accounts
    .map((rs) => {
      return {
        reason: findReason(rs.redStackId),
        model: rs,
      }
    })
    .filter((rs) => rs.reason)

  // We return boolean and the array
  return {
    isProblem: !!problematicRS.length,
    problemsArray: problematicRS,
  }
}
export const accountIdToServerFormat = (accountId: string) => {
  return accountId.replace(RegexConstants.ACCOUNT_ID_SERVER_FORMAT, '')
}
export const buildCfnDeployCommand = (
  cfLink: string,
  region: string
): string => {
  return `aws cloudformation create-stack --stack-name elastio-account-level-stack --template-url '${cfLink}' --capabilities CAPABILITY_NAMED_IAM --region ${region}`
}
export const buildCloudFormationLink = (
  cfLink: string,
  region: string
): string => {
  return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/create/review?templateURL=${cfLink}&stackName=elastio-account-level-stack`
}
export const buildCfnUpdatingLink = (
  cfLink: string,
  cfnModel: CfnModel
): string => {
  return `https://${cfnModel.region}.console.aws.amazon.com/cloudformation/home?region=${cfnModel.region}#/stacks/update/template?stackId=${cfnModel.arn}&templateURL=${cfLink}`
}
export const buildCfnUpdatingCommand = (
  cfLink: string,
  cfnModel: CfnModel
): string => {
  return `aws cloudformation update-stack --stack-name elastio-account-level-stack --template-url '${cfLink}' --capabilities CAPABILITY_NAMED_IAM --region ${cfnModel.region}`
}

abstract class DataHelper {
  public static getDataForDashboardFilter(
    filters?: Array<ValueInterface>,
    search?: string
  ) {
    const dataForNewTable: Record<string, any> = {}
    // @ts-ignore
    const isFilters = (filters?.length ?? []) > 0

    if (isFilters) {
      filters?.map((filter: ValueInterface) => {
        if (filter.name === 'accountId') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.accountIds) {
              dataForNewTable.accountIds = []
              dataForNewTable.accountIds?.push(value.name)
            } else {
              dataForNewTable.accountIds?.push(value.name)
            }
          })
        } else if (filter.name === 'regions') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.regions) {
              dataForNewTable.regions = []
              dataForNewTable.regions?.push(value.name)
            } else {
              dataForNewTable.regions?.push(value.name)
            }
          })
        } else if (filter.name === 'covered') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.covered) {
              dataForNewTable.covered = []
              dataForNewTable.covered?.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.covered
            }
          })
        } else if (filter.name === 'backupsOnSchedule') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.backupsOnSchedule) {
              dataForNewTable.backupsOnSchedule = []
              dataForNewTable.backupsOnSchedule?.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.backupsOnSchedule
            }
          })
        } else if (filter.name === 'protected') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.protected) {
              dataForNewTable.protected = []
              dataForNewTable.protected?.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.protected
            }
          })
        } else if (filter.name === 'malwares') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.malwaresList) {
              dataForNewTable.malwaresList = []
              dataForNewTable.malwaresList?.push(value.name)
            } else {
              dataForNewTable.malwaresList?.push(value.name)
            }
          })
        } else if (filter.name === 'ransomwares') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.ransomwaresList) {
              dataForNewTable.ransomwaresList = []
              dataForNewTable.ransomwaresList?.push(value.name)
            } else {
              dataForNewTable.ransomwaresList?.push(value.name)
            }
          })
        } else if (filter.name === 'ebsVulnerabilities') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.ebsVulnerabilities) {
              dataForNewTable.ebsVulnerabilities = []
              dataForNewTable.ebsVulnerabilities?.push(value.name)
            } else {
              dataForNewTable.ebsVulnerabilities?.push(value.name)
            }
          })
        } else if (filter.name === 'snapshotVulnerabilityTypes') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.snapshotVulnerabilityTypes) {
              dataForNewTable.snapshotVulnerabilityTypes = []
              dataForNewTable.snapshotVulnerabilityTypes?.push(value.name)
            } else {
              dataForNewTable.snapshotVulnerabilityTypes?.push(value.name)
            }
          })
        } else if (filter.name === 'osKinds') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.osKinds) {
              dataForNewTable.osKinds = []
              dataForNewTable.osKinds?.push(JSON.parse(value.name))
            } else {
              dataForNewTable.osKinds?.push(JSON.parse(value.name))
            }
          })
        } else if (filter.name === 'fsCheck') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.fsCheck) {
              dataForNewTable.fsCheck = []
              dataForNewTable.fsCheck?.push(value.name)
            } else {
              dataForNewTable.fsCheck?.push(value.name)
            }
          })
        } else if (filter.name === 'riskType') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.riskType) {
              dataForNewTable.riskType = []
              dataForNewTable.riskType?.push(JSON.parse(value.name))
            } else {
              dataForNewTable.riskType?.push(JSON.parse(value.name))
            }
          })
        } else if (filter.name === 'state') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.state) {
              dataForNewTable.state = []
              dataForNewTable.state?.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.state
            }
          })
        } else if (filter.name === 'volumeAwsIds') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.volumeAwsIds) {
              dataForNewTable.volumeAwsIds = []
              dataForNewTable.volumeAwsIds?.push(value.name)
            } else {
              dataForNewTable.volumeAwsIds?.push(value.name)
            }
          })
        } else if (filter.name === 'attachedInstancesAwsIds') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.attachedInstancesAwsIds) {
              dataForNewTable.attachedInstancesAwsIds = []
              dataForNewTable.attachedInstancesAwsIds?.push(value.name)
            } else {
              dataForNewTable.attachedInstancesAwsIds?.push(value.name)
            }
          })
        } else {
          delete dataForNewTable.accountIds
          delete dataForNewTable.regions
          delete dataForNewTable.covered
          delete dataForNewTable.protected
          delete dataForNewTable.malwaresList
          delete dataForNewTable.ransomwaresList
          delete dataForNewTable.ebsVulnerabilities
          delete dataForNewTable.snapshotVulnerabilityTypes
          delete dataForNewTable.protected
          delete dataForNewTable.osKinds
          delete dataForNewTable.state
        }
      })
    }

    if (search) {
      if (search !== '') {
        dataForNewTable.idSearch = search
      } else {
        delete dataForNewTable.idSearch
      }
    }
    return dataForNewTable
  }
}

export default DataHelper
