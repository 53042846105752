import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

interface ClientConfig {
  token: string
  hostName?: string
}

type ClientConstructor<T> = new (config: ClientConfig) => T

export function useGrpcClient<T>(
  ClientClass: ClientConstructor<T>,
  options: {
    hostName?: string
    onError?: (error: any) => void
  } = {}
) {
  const [client, setClient] = useState<T | undefined>(undefined)
  const { getIdTokenClaims } = useAuth0()
  const { hostName = '', onError } = options

  useEffect(() => {
    const initializeClient = async () => {
      try {
        const claims = await getIdTokenClaims()

        if (!claims?.__raw) {
          throw new Error('No token available')
        }

        const newClient = new ClientClass({
          token: claims.__raw,
          hostName,
        })

        setClient(newClient)
      } catch (error) {
        console.error('Failed to initialize Grpc client', error)
        onError?.(error)
      }
    }

    initializeClient()
  }, [ClientClass, hostName, getIdTokenClaims, onError])

  return client
}
