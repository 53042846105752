/* eslint-disable import/no-extraneous-dependencies */
import { RuleStatus } from 'blues-corejs/dist/models/notifications/rule/status'
import { ExactEvents as ExactAlertEvent } from 'blues-corejs/dist/models/notifications/rule/criteria'
import {
  RuleCriteria,
  RuleOvaBackupProvider,
  TagsCriterion,
} from 'blues-corejs/dist/models/notifications/rule/criteria'
import {
  RuleMode,
  RuleNotify,
} from 'blues-corejs/dist/models/notifications/rule'
import { NotificationChannels } from 'blues-corejs/dist/models/notifications/rule/notification-channels'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { TagsOperator } from 'blues-corejs/dist/models/notifications/rule/criteria'
import {
  AwsAccountRegion,
  EmailChannel,
  Empty,
  EventKindCriterion,
  ExactEvents,
  NotificationChannels as NotificationChannelsPB,
  OvaAccountProviders,
  RuleCriteria as RuleCriteriaPB,
  RuleMode as RuleModePB,
  RuleStatus as RuleStatusPB,
  Tag,
  TagsCriterion as TagsCriterionPB,
  TagsOperator as TagsOperatorPB,
  TenantMemberEmailChannel,
  WebhookChannel,
  OvaBackupProvider as OvaBackupProviderPB,
} from '../../grpc'

export function mapTagsOperator(tagsOperator: TagsOperator): TagsOperatorPB {
  const mapping = {
    [TagsOperator.AND]: TagsOperatorPB.TAGS_OPERATOR_AND,
    [TagsOperator.OR]: TagsOperatorPB.TAGS_OPERATOR_OR,
  }
  return mapping[tagsOperator]
}

export function mapRuleOvaBackupProvider(
  ruleOvaBackupProvider: RuleOvaBackupProvider
): OvaBackupProviderPB {
  const mapping = {
    [RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_AWS_BACKUP_VMWARE]:
      OvaBackupProviderPB.OVA_BACKUP_PROVIDER_AWS_BACKUP_VMWARE,
    [RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_COHESITY]:
      OvaBackupProviderPB.OVA_BACKUP_PROVIDER_COHESITY,
    [RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_RUBRIK]:
      OvaBackupProviderPB.OVA_BACKUP_PROVIDER_RUBRIK,
    [RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_VEEAM]:
      OvaBackupProviderPB.OVA_BACKUP_PROVIDER_VEEAM,
    [RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_COMMVAULT]:
      OvaBackupProviderPB.OVA_BACKUP_PROVIDER_COMMVAULT,
    [RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_VERITAS_NET_BACKUP]:
      OvaBackupProviderPB.OVA_BACKUP_PROVIDER_VERITAS_NET_BACKUP,
  }
  return mapping[ruleOvaBackupProvider]
}

export const createRuleStatus = (status: RuleStatus): RuleStatusPB => {
  const statusPB = new RuleStatusPB()
  if (status === RuleStatus.enabled) {
    statusPB.setEnabled(new Empty())
  } else {
    statusPB.setDisabled(new Empty())
  }

  return statusPB
}

export const createTagsCriterion = (
  criteria: TagsCriterion
): TagsCriterionPB => {
  return new TagsCriterionPB()
    .setOperator(mapTagsOperator(criteria.operator))
    .setTagsList(
      criteria.tags.map((tag) => new Tag().setKey(tag.key).setValue(tag.value))
    )
}

export const createRuleCriteria = (criteria: RuleCriteria): RuleCriteriaPB => {
  const ruleCriteriaPB = new RuleCriteriaPB()

  if (criteria.assetIds.length > 0) {
    ruleCriteriaPB.setAssetIdsList(criteria.assetIds)
  }

  if (criteria.tags.tags.length) {
    ruleCriteriaPB.setTags(createTagsCriterion(criteria.tags))
  }

  ruleCriteriaPB.setAwsAccountRegionsList(
    criteria.awsAccountRegions.map((awsAccountRegion) =>
      new AwsAccountRegion()
        .setAccountId(awsAccountRegion.accountId)
        .setRegion(awsAccountRegion.region)
    )
  )
  ruleCriteriaPB.setOvaAccountProvidersList(
    criteria.ovaAccountProviders.map((ovaAccountProvider) =>
      new OvaAccountProviders()
        .setAccountId(ovaAccountProvider.accountId)
        .setProvider(mapRuleOvaBackupProvider(ovaAccountProvider.provider))
    )
  )

  const eventKindCriterion = new EventKindCriterion()

  if (
    criteria.eventKinds instanceof ExactAlertEvent &&
    Array.isArray(criteria.eventKinds.kinds)
  ) {
    eventKindCriterion.setExactEvents(
      new ExactEvents().setKindsList(criteria.eventKinds.kinds)
    )
  } else {
    eventKindCriterion.setAllEvents(new Empty())
  }

  ruleCriteriaPB.setEventKinds(eventKindCriterion)

  return ruleCriteriaPB
}

export const createRuleMode = (mode: RuleMode): RuleModePB => {
  const ruleModePB = new RuleModePB()

  if (mode instanceof RuleNotify) {
    ruleModePB.setNotify(new Empty())
  } else {
    ruleModePB.setSuppressUntil(
      new RuleModePB.SuppressMode()
        .setReason(mode.reason)
        .setUntil(
          new Timestamp().setSeconds(
            Number((mode.date.getTime() / 1000).toFixed(0))
          )
        )
    )
  }

  return ruleModePB
}

export const createNotificationChannels = (
  channels: NotificationChannels
): NotificationChannelsPB => {
  const notificationChannelsPB = new NotificationChannelsPB()

  notificationChannelsPB.setSlack(channels.slack)
  notificationChannelsPB.setWebhooksList(
    channels?.webhooks.map((webhook) =>
      new WebhookChannel().setWebhookId(webhook.webhookId)
    )
  )

  notificationChannelsPB.setEmailsList(
    channels.emails.map((email) => new EmailChannel().setEmail(email.email))
  )
  notificationChannelsPB.setTenantMemberEmailsList(
    channels.tenantMemberEmails.map((email) =>
      new TenantMemberEmailChannel().setCustomerId(email.customer_id)
    )
  )

  return notificationChannelsPB
}
