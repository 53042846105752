/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Stack } from '@mui/material'
import LabeledValue from '../common/labeled-value'
import { Asset, EBS, EC2, GenericHost, OvaServer } from 'blues-corejs/dist'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import Link from 'ui-v2/src/components/ui/data-display/link'
import {
  ASSETS_ROUTE_SEGMENTS,
  BASE_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

type SuccessContentProps = {
  asset: Asset | undefined
  attachedInstance: EC2 | undefined
}

const SuccessContent: React.FC<SuccessContentProps> = ({
  asset,
  attachedInstance,
}) => {
  const isAwsAsset =
    asset && !(asset instanceof OvaServer) && !(asset instanceof GenericHost)

  const accountValue = isAwsAsset ? asset.awsAccountId : '-'

  const regionValue = isAwsAsset
    ? (LangAwsRegionConstant.get(asset.awsRegion)?.en?.single ?? '-')
    : '-'

  const getAttachedInstanceId = () => {
    if (attachedInstance) {
      return (
        <Link
          to={`/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EC2}/details/${attachedInstance.id}`}
          fontWeight={600}
        >
          {attachedInstance.awsId}
        </Link>
      )
    }

    return 'Not Attached'
  }

  const getAdditionalAssetDetails = () => {
    if (asset instanceof EC2) {
      return {
        label: 'Attached EBS Volumes',
        value: asset.ebsIds.length.toString(),
      }
    }

    if (asset instanceof EBS) {
      return {
        label: 'Attached Instance',
        value: getAttachedInstanceId(),
      }
    }

    return {
      label: '',
      value: '',
    }
  }

  return (
    <Stack spacing={0.5}>
      <LabeledValue label="Region" value={regionValue} />
      <LabeledValue label="Account" value={accountValue} />
      <LabeledValue {...getAdditionalAssetDetails()} />
    </Stack>
  )
}

export default SuccessContent
