/* eslint-disable import/no-extraneous-dependencies */
import {
  CloudConnectorJobStatus,
  JobCloudConnectorKind,
} from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import { TimeRange } from '../types'
import { JobCloudConnector } from 'blues-corejs/dist'
import { AssetList } from '../assets'
import RedStackModel from '../base-model/red-stack.model'
import { JobTenant } from 'blues-corejs/dist'
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'

export interface CloudConnectorJobRequestFilters {
  kindsList: Array<JobCloudConnectorKind>
  statusesList: Array<CloudConnectorJobStatus>
  assetIdsList: Array<string>
}

export interface ListCloudConnnectorJobsFilterRequestParams {
  assetIdsList?: Array<string>
  kindsList?: Array<JobCloudConnectorKind>
  statusesList?: Array<CloudConnectorJobStatus>
  ccIdsList?: Array<string>
  timeRange?: TimeRange
  pageSize?: number
  pageToken?: string
}

export interface Options {
  label: string
  id: string
}

export class MonitorTableRow {
  readonly #id: string

  readonly #accountId: string

  readonly #assetId: string

  readonly #status: CloudConnectorJobStatus

  readonly #jobType: string

  readonly #scanStartTime: Date

  readonly #scanEndTime: Date | undefined

  readonly #isFinished: boolean

  readonly #duration: string

  readonly #progress: number

  readonly #awsRegion: string

  readonly #assetKind: string

  readonly #createdAt: Date | undefined

  readonly #name: string

  readonly #message: string

  readonly #realAssetId: string

  constructor(parameters: MonitorTableRowAttr) {
    const {
      id,
      accountId,
      assetId,
      status,
      jobType,
      scanStartTime,
      scanEndTime,
      isFinished,
      duration,
      progress,
      awsRegion,
      assetKind,
      createdAt,
      name,
      message,
      realAssetId,
    } = parameters

    this.#id = id
    this.#accountId = accountId
    this.#assetId = assetId
    this.#status = status
    this.#jobType = jobType
    this.#scanStartTime = scanStartTime
    this.#scanEndTime = scanEndTime
    this.#duration = duration
    this.#isFinished = isFinished
    this.#progress = progress
    this.#awsRegion = awsRegion
    this.#assetKind = assetKind
    this.#createdAt = createdAt
    this.#name = name
    this.#message = message
    this.#realAssetId = realAssetId
  }

  get id(): string {
    return this.#id
  }

  get accountId(): string {
    return this.#accountId
  }

  get assetId(): string {
    return this.#assetId
  }

  get status(): CloudConnectorJobStatus {
    return this.#status
  }

  get jobType(): string {
    return this.#jobType
  }

  get scanStartTime(): Date {
    return this.#scanStartTime
  }

  get scanEndTime(): Date | undefined {
    return this.#scanEndTime
  }

  get duration(): string {
    return this.#duration
  }

  get isFinished(): boolean {
    return this.#isFinished
  }

  get progress(): number {
    return this.#progress
  }

  get awsRegion(): string {
    return this.#awsRegion
  }

  get assetKind(): string {
    return this.#assetKind
  }

  get createdAt(): Date | undefined {
    return this.#createdAt
  }

  get message(): string {
    return this.#message
  }

  get name(): string {
    return this.#name
  }

  get realAssetId(): string {
    return this.#realAssetId
  }
}

interface MonitorTableRowAttr {
  id: string
  accountId: string
  assetId: string
  status: CloudConnectorJobStatus
  jobType: string
  scanStartTime: Date
  scanEndTime: Date | undefined
  duration: string
  isFinished: boolean
  progress: number
  awsRegion: string
  assetKind: string
  createdAt: Date | undefined
  message: string
  name: string
  realAssetId: string
}

export interface MonitorTableDataAttrs {
  monitors: Array<JobCloudConnector>
  assetsList: Array<AssetList>
  redStackListData: Array<RedStackModel>
}

export interface DeploymentTableDataAttrs {
  tenantJobs: Array<JobTenant>
  redStackListData: Array<RedStackModel>
}

interface DeploymentTableRowAttr {
  id: string
  status: TenantJobStatus
  scheduledOn: Date
  jobType: string
  cloudConnector: RedStackModel | undefined
  message: string
  task: string
  completedOn: Date | undefined
  childrenList: Array<JobTenant>
}

export class DeploymentTableRow {
  readonly #id: string

  readonly #status: TenantJobStatus

  readonly #scheduledOn: Date

  readonly #jobType: string

  readonly #cloudConnector: RedStackModel | undefined

  readonly #message: string

  readonly #task: string

  readonly #completedOn: Date | undefined

  readonly #childrenList: Array<JobTenant>

  constructor(parameters: DeploymentTableRowAttr) {
    const {
      id,
      status,
      scheduledOn,
      jobType,
      cloudConnector,
      message,
      task,
      completedOn,
      childrenList,
    } = parameters

    this.#id = id
    this.#status = status
    this.#scheduledOn = scheduledOn
    this.#jobType = jobType
    this.#cloudConnector = cloudConnector
    this.#message = message
    this.#task = task
    this.#completedOn = completedOn
    this.#childrenList = childrenList
  }

  get id(): string {
    return this.#id
  }

  get status(): TenantJobStatus {
    return this.#status
  }

  get scheduledOn(): Date {
    return this.#scheduledOn
  }

  get jobType(): string {
    return this.#jobType
  }

  get cloudConnector(): RedStackModel | undefined {
    return this.#cloudConnector
  }

  get message(): string {
    return this.#message
  }

  get task(): string {
    return this.#task
  }

  get completedOn(): Date | undefined {
    return this.#completedOn
  }

  get childrenList(): Array<JobTenant> {
    return this.#childrenList
  }
}
