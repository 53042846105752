interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

const CirclePendingIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M11.9998 4.6665L12.639 4.69441L13.2733 4.77791L13.8978 4.91638L14.508 5.10876L15.099 5.35358L15.6665 5.64898L16.2061 5.99272L16.7136 6.38218L17.1853 6.81439L17.6175 7.28606L18.007 7.79361L18.3507 8.33317L18.6461 8.90064L18.8909 9.49169L19.0833 10.1018L19.2218 10.7264L19.3053 11.3607L19.3332 11.9998L19.3053 12.639L19.2218 13.2733L19.0833 13.8978L18.8909 14.508L18.6461 15.099L18.3507 15.6665L18.007 16.2061L17.6175 16.7136L17.1853 17.1853L16.7136 17.6175L16.2061 18.007L15.6665 18.3507L15.099 18.6461L14.508 18.8909L13.8978 19.0833L13.2733 19.2218L12.639 19.3053L11.9998 19.3332L11.3607 19.3053L10.7264 19.2218L10.1018 19.0833L9.49169 18.8909L8.90064 18.6461L8.33317 18.3507L7.79361 18.007L7.28606 17.6175L6.81439 17.1853L6.38218 16.7136L5.99272 16.2061L5.64898 15.6665L5.35358 15.099L5.10876 14.508L4.91638 13.8978L4.77791 13.2733L4.69441 12.639L4.6665 11.9998L4.69441 11.3607L4.77791 10.7264L4.91638 10.1018L5.10876 9.49169L5.35358 8.90064L5.64898 8.33317L5.99272 7.79361L6.38218 7.28606L6.81439 6.81439L7.28606 6.38218L7.79361 5.99272L8.33317 5.64898L8.90064 5.35358L9.49169 5.10876L10.1018 4.91638L10.7264 4.77791L11.3607 4.69441L11.9998 4.6665Z"
        stroke="#4E5665"
        stroke-width="4"
      />
    </svg>
  )
}

export default CirclePendingIcon
