import { Inbox } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'

interface NoDataDisplayProps {
  message?: string
}

const NoDataDisplay = ({
  message = 'No data available',
}: NoDataDisplayProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '100%',
        py: 4,
      }}
      spacing={2}
    >
      <Inbox
        sx={{
          fontSize: 40,
          color: 'text.secondary',
        }}
      />
      <Typography variant="body2" color="text.secondary" textAlign="center">
        {message}
      </Typography>
    </Stack>
  )
}

export default NoDataDisplay
