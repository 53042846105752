import { useState, useEffect, useRef, useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { RequestOptions, RestClient } from '../lib/clients/rest-client'

interface UseRestClientOptions {
  baseUrl?: string
  timeout?: number
  onError?: (error: any) => void
}

export function useRestClient(restClientOptions: UseRestClientOptions = {}) {
  const { baseUrl = '', timeout, onError } = restClientOptions

  const { getIdTokenClaims, isAuthenticated, isLoading } = useAuth0()
  const [isClientReady, setIsClientReady] = useState(false)
  const restClientRef = useRef<RestClient | null>(null)

  const initializeService = useCallback(async () => {
    try {
      if (!isAuthenticated) {
        setIsClientReady(false)
        return
      }

      const claims = await getIdTokenClaims()
      if (!claims?.__raw) {
        throw new Error('No Auth0 token available')
      }

      if (!restClientRef.current) {
        restClientRef.current = new RestClient({
          baseUrl,
          timeout,
        })
      }

      restClientRef.current.setAuthToken(claims.__raw)
      setIsClientReady(true)
    } catch (error) {
      console.error('Failed to initialize REST client', error)
      setIsClientReady(false)
      onError?.(error)
    }
  }, [baseUrl, timeout, getIdTokenClaims, isAuthenticated, onError])

  useEffect(() => {
    initializeService()
  }, [initializeService])

  const get = useCallback(
    async <T>(
      url: string,
      params?: any,
      options?: RequestOptions
    ): Promise<T> => {
      if (!restClientRef.current || !isClientReady) {
        throw new Error('REST client is not initialized')
      }
      return restClientRef.current.get<T>(url, params, options)
    },
    [isClientReady]
  )

  const post = useCallback(
    async <T>(url: string, data: any, options?: RequestOptions): Promise<T> => {
      if (!restClientRef.current || !isClientReady) {
        throw new Error('REST client is not initialized')
      }
      return restClientRef.current.post<T>(url, data, options)
    },
    [isClientReady]
  )

  const put = useCallback(
    async <T>(url: string, data: any, options?: RequestOptions): Promise<T> => {
      if (!restClientRef.current || !isClientReady) {
        throw new Error('REST client is not initialized')
      }
      return restClientRef.current.put<T>(url, data, options)
    },
    [isClientReady]
  )

  const del = useCallback(
    async <T>(url: string, options?: RequestOptions): Promise<T> => {
      if (!restClientRef.current || !isClientReady) {
        throw new Error('REST client is not initialized')
      }
      return restClientRef.current.delete<T>(url, options)
    },
    [isClientReady]
  )

  return {
    isReady: isClientReady,
    isLoading,
    get,
    post,
    put,
    delete: del,
  }
}
