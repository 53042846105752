import Box from '@mui/material/Box'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import { MenuItem, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ClusterFormData } from './types'
import { useParams } from 'react-router-dom'
import { useListVaultForActiveRedstacks } from 'ui-v2/src/hooks/queries/vault/list-vault-for-active-redstacks'
import { useListSupportedAwsRegionsQuery } from 'ui-v2/src/hooks/queries/red-stack'
import DrawerLoader from '../drawer-loader'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useListAwsVpcArr } from 'ui-v2/src/hooks/queries/red-stack/list-aws-vpc-arr'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import { RegionOption, transformVpcListToNestedOptions } from '../../../utils'
import {
  NestedOption,
  NestedSelect,
} from 'ui-v2/src/components/ui/inputs/nested-select'
import { useDeploymentStore } from '../../store/deployment-store'

interface ScanClusterFormProps {
  setIsCreate: (v: boolean) => void
}

export const ScanClusterForm = ({ setIsCreate }: ScanClusterFormProps) => {
  const { id } = useParams()
  const { rowData } = useDeploymentStore()
  const { control, setValue, watch } = useFormContext<ClusterFormData>()
  const rexClient = useGrpcClient(RexClient)
  const [nestedOptions, setNestedOptions] = useState<Array<RegionOption>>([])
  const { data, isLoading: isLoadingListVault } =
    useListVaultForActiveRedstacks(id)
  const { data: listSupportedAwsRegionsData, isLoading: isLoadingAwsRegions } =
    useListSupportedAwsRegionsQuery()
  const { mutate: requestVpcListArr, isPending: requestVpcListArrIsPending } =
    useListAwsVpcArr()
  const isLoadingData =
    requestVpcListArrIsPending || isLoadingListVault || isLoadingAwsRegions

  const awsRegionId = watch('awsRegionId')
  const disabled = !awsRegionId
  const filteredVpcList: Array<RegionOption> = useMemo(() => {
    if (!awsRegionId) {
      return []
    }
    return nestedOptions.filter((region) => region.id === awsRegionId)
  }, [awsRegionId])

  const filteredRegions = useMemo(() => {
    if (!listSupportedAwsRegionsData || !rowData?.awsRegions) {
      return []
    }

    return listSupportedAwsRegionsData.filter((region) =>
      rowData.awsRegions.includes(String(region.name))
    )
  }, [listSupportedAwsRegionsData, rowData?.awsRegions])

  const getVpcListArr = () => {
    if (!id) {
      return
    }

    requestVpcListArr(id, {
      onSuccess: (vpcList) => {
        setNestedOptions([])
        setNestedOptions(transformVpcListToNestedOptions(vpcList))
      },
      onError: (error) => {
        console.log(error.message)
      },
    })
  }

  useEffect(() => {
    if (!rexClient || nestedOptions.length > 0) {
      return
    }
    getVpcListArr()
  }, [id, rexClient])

  useEffect(() => {
    const selectedVault = data?.find(
      (vault) => vault.region === awsRegionId && vault.isDefault
    )

    if (selectedVault) {
      setValue('name', selectedVault.name)
      setValue('description', selectedVault.description)
      setValue('vpcList', selectedVault.subnetIdsList)
      setValue('vaultId', String(selectedVault.innerId))
      setValue('vpcListForApi', [
        {
          id: selectedVault.region,
          name: selectedVault.regionLabel,
          children: [
            {
              id: selectedVault.vpcId,
              name: selectedVault.vpcId,
              children: selectedVault.subnetIdsList.map((subnetId) => ({
                id: subnetId,
                name: subnetId,
              })),
            },
          ],
        },
      ])
    }
  }, [awsRegionId])

  useEffect(() => {
    if (data?.length) {
      setIsCreate(false)
    } else {
      setIsCreate(true)
    }
  }, [data])

  const handleRegionChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value as string
    setValue('awsRegionId', value, { shouldValidate: true })
  }

  const handleVpcSelect = (selectedData: Array<NestedOption>) => {
    setValue('vpcListForApi', selectedData)
  }

  if (isLoadingData) {
    return <DrawerLoader />
  }

  return (
    <Box
      sx={{
        paddingX: 2.5,
        paddingBottom: 2.5,
      }}
    >
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid size={12}>
          <Typography variant="body2" mb={1} fontWeight={600}>
            Select AWS Region
          </Typography>
          <FormControl fullWidth>
            <Controller
              control={control}
              rules={{
                required: 'AWS Region is required',
              }}
              name="awsRegionId"
              render={({ field, fieldState }) => (
                <Select
                  value={field.value}
                  onChange={handleRegionChange}
                  size="small"
                  fullWidth
                  error={!!fieldState.error?.message}
                >
                  {filteredRegions?.map((region) => (
                    <MenuItem value={region.name}>{region.label}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid size={12}>
          <Typography variant="body2" mb={1} fontWeight={600}>
            Scan Cluster Name
          </Typography>
          <FormControl fullWidth>
            <Controller
              control={control}
              rules={{
                required: 'Scan Cluster Name is required',
              }}
              name="name"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '14px',
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '14px',
                    },
                  }}
                  size="small"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  slotProps={{ htmlInput: { maxLength: 12 } }}
                  fullWidth
                  placeholder="Scan Cluster Name"
                  disabled={disabled || !!data?.length}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid size={12}>
          <Typography variant="body2" mb={1} fontWeight={600}>
            Description (Optional)
          </Typography>
          <FormControl fullWidth>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '14px',
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '14px',
                    },
                  }}
                  size="small"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  slotProps={{ htmlInput: { maxLength: 150 } }}
                  fullWidth
                  placeholder="Scan Cluster Description"
                  disabled={disabled}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid size={12}>
          <Typography variant="body2" fontWeight={600} mb={1}>
            Select Subnets
          </Typography>
          <NestedSelect
            onSelect={handleVpcSelect}
            name={'vpcList'}
            options={filteredVpcList || []}
            isLoading={false}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ScanClusterForm
