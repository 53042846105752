import { Box, Stack, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import { getRecoveryPointFilesTableColumns } from './column-config'
import {
  AssetThreatsResponse,
  RecoveryPointBackupByIdResponse,
  ScanJobData,
} from 'ui-v2/src/lib/models/backups'
import RecoveryPointFilesTableHeader from '../recovery-points/files-table-header'
import BaseTable from 'ui-v2/src/components/ui/data-table/base-table'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'
import { useToast } from 'ui-v2/src/hooks/toast'
import EmptyContentComponent from '../common/empty-content-component'
import ScanLogFilesTableHeader from '../scan-log/files-table-header'

type FilesTableProps = {
  data: AssetThreatsResponse
  health: string
  backup?: RecoveryPointBackupByIdResponse
  scanJob?: ScanJobData
}

const FilesTable: React.FC<FilesTableProps> = ({
  data,
  health,
  backup,
  scanJob,
}) => {
  const theme = useTheme()
  const toast = useToast()

  const columns = useMemo(() => getRecoveryPointFilesTableColumns(toast), [])

  const isHealthy = health === 'clean'
  const atRisk = health === 'at_risk' && data.length === 0

  const getBackground = () => {
    if (isHealthy && theme.palette.mode === ThemeMode.DARK) {
      return 'radial-gradient(100% 100% at 54.49% 100%, rgba(7, 224, 152, 0.60) 0%, rgba(0, 47, 91, 0.00) 100%)'
    } else if (atRisk && theme.palette.mode === ThemeMode.DARK) {
      return 'radial-gradient(100% 100% at 54.49% 100%, var(--Severity-Medium, rgba(255, 186, 84, 0.60)) 0%, rgba(0, 47, 91, 0.00) 95.5%)'
    }
    return theme.palette.background.secondary
  }

  const getBackgroundBorder = () => {
    if (isHealthy && theme.palette.mode === ThemeMode.DARK) {
      return 'linear-gradient(#152635, #152635) padding-box, radial-gradient(100% 100% at 50% 0%, rgba(7, 224, 152, 0.6) 0%, rgba(0, 47, 91, 0.00) 100%) border-box'
    } else if (atRisk && theme.palette.mode === ThemeMode.DARK) {
      return 'linear-gradient(#152635, #152635) padding-box, radial-gradient(100% 100% at 50% 0%, var(--Severity-Medium, rgba(255, 186, 84, 0.6)) 0%, rgba(0, 47, 91, 0) 95.5%) border-box;'
    }
    return ''
  }

  return (
    <Stack
      sx={{
        height: '100%',
        borderRadius: '8px',
        border: '1px solid transparent',
        background: getBackgroundBorder(),
      }}
    >
      <Stack
        sx={{
          height: '100%',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          padding: '16px',
          background: getBackground(),
        }}
        spacing={1}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.secondary,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: 1,
          }}
        >
          {backup ? (
            <RecoveryPointFilesTableHeader
              isHealthy={isHealthy}
              atRisk={atRisk}
              backup={backup}
            />
          ) : scanJob ? (
            <ScanLogFilesTableHeader
              isHealthy={isHealthy}
              atRisk={atRisk}
              scanJob={scanJob}
            />
          ) : null}

          {isHealthy || atRisk ? (
            <EmptyContentComponent isHealthy={isHealthy} atRisk={atRisk} />
          ) : (
            <BaseTable
              data={data}
              columns={columns}
              multiSort={false}
              styles={{
                root: {
                  height: '100%',
                  maxHeight: 'none',
                  background: theme.palette.background.elevation3,
                },
                container: {
                  height: '100%',
                },
                cell: {
                  background: theme.palette.background.elevation3,
                },
                header: {
                  '& .MuiTableCell-head': {
                    backgroundColor: theme.palette.background.elevation2,
                  },
                },
              }}
            />
          )}
        </Box>
      </Stack>
    </Stack>
  )
}

export default FilesTable
