import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { BreadcrumbItem } from './breadcrumbs-config'
import { useTheme } from '@mui/material'
import { ThemeMode } from '../theme-switcher'
import NavigateNextIcon from 'ui-v2/src/assets/icons/navigate-next-icon'

interface CommonBreadcrumbsProps {
  data: Array<BreadcrumbItem>
}

const CommonBreadcrumbs: React.FC<CommonBreadcrumbsProps> = ({ data }) => {
  const theme = useTheme()

  if (!data || data.length === 0) {
    return null
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon />}
      sx={{
        '& .MuiBreadcrumbs-ol': {
          position: 'absolute',
          top: 0,
          bottom: 0,
        },
        '& .MuiBreadcrumbs-separator': {
          margin: 0,
        },
        fontSize: '14px',
      }}
    >
      {data.map((item, index) =>
        item.link ? (
          <Link
            underline="none"
            key={index}
            component={RouterLink}
            to={item.link}
            sx={{
              fontSize: '14px',
              color:
                theme.palette.mode === ThemeMode.DARK
                  ? '#32B6F3 !important'
                  : '#0084C1 !important',
            }}
            onClick={item.onClick}
          >
            {item.label}
          </Link>
        ) : (
          <Typography
            key={index}
            sx={{
              fontSize: '14px',
              color:
                theme.palette.mode === ThemeMode.DARK ? '#F6F9FC' : '#4E5665',
            }}
          >
            {item.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  )
}

export default CommonBreadcrumbs
