interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

const DataScanneIcon = ({ size = 18, ...props }: IconProps) => {
  return (
    <svg
      width="16"
      height={size}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 0C3.58 0 0 1.79 0 4C0 6.21 3.58 8 8 8C12.42 8 16 6.21 16 4C16 1.79 12.42 0 8 0ZM0 6V9C0 11.21 3.58 13 8 13C12.42 13 16 11.21 16 9V6C16 8.21 12.42 10 8 10C3.58 10 0 8.21 0 6ZM0 11V14C0 16.21 3.58 18 8 18C12.42 18 16 16.21 16 14V11C16 13.21 12.42 15 8 15C3.58 15 0 13.21 0 11Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DataScanneIcon
