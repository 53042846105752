import Link from 'ui-v2/src/components/ui/data-display/link'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { Server } from 'ui-v2/src/lib/grpc'
import { getHealthStatus } from '../util'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import {
  formatDateToUtc,
  maybeSecondsToDate,
} from 'ui-v2/src/lib/helpers/time.helper'
import { BACKUP_VENDOR_MAP } from 'ui-v2/src/lib/constants/assets.constant'

export const getOvaServerColumns = (
  ovaAccountMap: Map<string, string>
): Array<Column<Server.AsObject>> => [
  {
    key: 'health',
    header: 'Health',
    render: (row) => <StatusChip type={getHealthStatus(row.health)} />,
    width: 80,
    align: 'center',
    sortable: true,
  },
  {
    key: 'name',
    header: 'Name',
    render: (row) => (
      <Link to={`/details/${row.id}/recoveryPoint`} preservePath>
        {row.name}
      </Link>
    ),
  },
  {
    key: 'ovaAccountId',
    header: 'Account',
    render: (row) => (
      <Link to={`/details/${row.id}/recoveryPoint`} preservePath>
        {ovaAccountMap.get(row.ovaAccountId)}
      </Link>
    ),
    sortable: true,
  },
  {
    key: 'backupProvider',
    header: 'Source',
    render: (row) => BACKUP_VENDOR_MAP[row.backupProvider],
    sortable: true,
  },
  {
    key: 'lastScan',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(maybeSecondsToDate(row.lastScan?.seconds)),
    sortable: true,
  },
]
