/* eslint-disable import/no-extraneous-dependencies */
import { EC2_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { EBS, EC2, EC2State, Nullable, Threat } from 'blues-corejs/dist'
import { Scan } from 'ui-v2/src/lib/models/scans'

export abstract class AssetsEc2Base {
  protected abstract readonly instances: Array<EC2>

  protected abstract readonly volumes: Map<string, EBS>

  protected abstract readonly threats: Array<Threat>

  protected abstract readonly volumesWithScans: Map<string, Array<Scan>>

  protected abstract readonly scans: Array<Scan>

  static convertEc2State(
    state: EC2State
  ): (typeof EC2_STATE_MAP)[keyof typeof EC2_STATE_MAP] {
    return EC2_STATE_MAP[state]
  }

  protected isUnhealthyInstance(instance: EC2): boolean {
    const attachedVolumes = this.getAttachedVolumes(instance)

    if (instance.state === EC2State.TERMINATED) {
      return false
    }

    return attachedVolumes.some((volume) =>
      this.threats.some(
        (threat) =>
          threat.source.asset?.assetId === volume.id ||
          threat.source.assetItem?.assetId === volume.id
      )
    )
  }

  protected getAttachedVolumes(instance: EC2): Array<EBS> {
    return instance.ebsIds
      .map((id) => this.volumes.get(id))
      .filter(Boolean) as Array<EBS>
  }

  protected getLastScanForInstance(instance: EC2): Nullable<Scan> {
    const attachedVolumes = this.getAttachedVolumes(instance)

    const volumeScans = attachedVolumes
      .map((volume) => this.getLastScanForVolume(volume))
      .filter((scan): scan is Scan => scan !== null)

    if (!volumeScans.length) {
      return null
    }

    return volumeScans.reduce(
      (latest, current) =>
        current.createdAt.getTime() > (latest?.createdAt?.getTime() ?? 0)
          ? current
          : latest,
      volumeScans[0]
    )
  }

  protected getLastScanForVolume(volume: EBS): Nullable<Scan> {
    const scansList = this.volumesWithScans.get(volume.id)
    if (!scansList || scansList.length === 0) {
      return null
    }

    return scansList.reduce(
      (latest, current) =>
        current.createdAt.getTime() > (latest?.createdAt?.getTime() ?? 0)
          ? current
          : latest,
      scansList[0]
    )
  }

  protected getVolumeWithScans(): Map<string, Array<Scan>> {
    const map = new Map<string, Array<Scan>>()

    for (const scan of this.scans) {
      const volumesScans = map.get(scan.scanTarget.target.assetId)

      if (volumesScans) {
        volumesScans.push(scan)
      } else {
        map.set(scan.scanTarget.target.assetId, [scan])
      }
    }

    return map
  }
}
