export enum Origin {
  TENANT = 0,
  CLOUD_CONNECTOR = 1,
}

export enum Severity {
  ERROR = 0,
  WARNING = 1,
  INFO = 2,
  TRACE = 3,
}

export interface Customer {
  id: string
  email: string
  ipAddress: string
}

export interface CloudProviderResource {
  name: string
}

export interface Trigger {
  customer?: Customer
  portal?: unknown
  cloudProviderResource?: CloudProviderResource
  cloudConnector?: unknown
}

interface BaseNotificationEventAttrs {
  origin: Origin
  kind: string
  labelsMap: Array<[string, string]>
  severity: Severity
  description: string
  eventTime: Date
  trigger: Trigger
}

export class NotificationEvent {
  readonly #origin: Origin

  readonly #kind: string

  readonly #labelsMap: Array<[string, string]>

  readonly #severity: Severity

  readonly #description: string

  readonly #eventTime: Date

  readonly #trigger: Trigger

  constructor(params: BaseNotificationEventAttrs) {
    const {
      origin,
      eventTime,
      kind,
      description,
      trigger,
      severity,
      labelsMap,
    } = params

    this.#origin = origin
    this.#kind = kind
    this.#labelsMap = labelsMap
    this.#severity = severity
    this.#description = description
    this.#eventTime = eventTime
    this.#trigger = trigger
  }

  get origin() {
    return this.#origin
  }

  get kind() {
    return this.#kind
  }

  get labelsMap() {
    return this.#labelsMap
  }

  get severity() {
    return this.#severity
  }

  get description() {
    return this.#description
  }

  get eventTime() {
    return this.#eventTime
  }

  get trigger() {
    return this.#trigger
  }
}
