import { ChangeEvent, useState, useEffect, FC } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'

import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'

import { Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Fab from '@mui/material/Fab'
import {
  CustomScheduleState,
  Days,
  RepeatPeriod,
  ReportEnd,
} from 'ui-v2/src/lib/models/reports'
import {
  DaysOptions,
  EndOptions,
  getMonthOptions,
  repeatPeriodOptions,
} from 'ui-v2/src/lib/constants/reports.constant'
import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import dayjs, { Dayjs } from 'dayjs'
import DatepickerInput from 'ui-v2/src/components/ui/inputs/datepicker-input'

interface RepeatsProps {
  periodValue: RepeatPeriod
  daysValue: string | null
  handleDaysChange: (v: Days) => void
  monthsValue: string
  handleMonthsChange: (v: string) => void
}

interface CustomScheduleProps {
  getStateCallback: (customScheduleState: CustomScheduleState) => void
  initialValues?: CustomScheduleState | null
}
export type Value = string | number
const monthOptions = getMonthOptions()

const computeRepeats = ({
  periodValue,
  daysValue,
  handleDaysChange,
  handleMonthsChange,
  monthsValue,
}: RepeatsProps) => {
  if (periodValue === RepeatPeriod.WEEK) {
    return (
      <Stack spacing={1.5}>
        <Typography>Repeat On</Typography>
        <Box display="flex" gap={1}>
          {DaysOptions.map((option) => (
            <Fab
              className="custom-schedule-repeat"
              size="small"
              onClick={() => handleDaysChange(option.value)}
              sx={{
                background:
                  option.value === daysValue ? 'var(--blue500)' : '#f1f3f4',
                color: option.value === daysValue ? '#fff' : '#70757a',
              }}
            >
              {option.label}
            </Fab>
          ))}
        </Box>
      </Stack>
    )
  }

  if (periodValue === RepeatPeriod.MONTH) {
    return (
      <Box>
        <Typography mb={1}>Repeats On</Typography>
        <FormControl fullWidth>
          <BasicSelect
            options={monthOptions}
            value={monthsValue}
            onChange={(value) => {
              handleMonthsChange(value as string)
            }}
          />
        </FormControl>
      </Box>
    )
  }

  return null
}

const CustomSchedule: FC<CustomScheduleProps> = ({
  getStateCallback,
  initialValues,
}) => {
  const [everyValue, setEveryValue] = useState<number>(
    initialValues?.everyValue || 1
  )
  const [periodValue, setPeriodValue] = useState<RepeatPeriod>(
    initialValues?.periodValue || RepeatPeriod.DAY
  )
  const [daysValue, setDaysValue] = useState<string | null>(
    initialValues?.daysValue || null
  )
  const [monthsValue, setMonthsValue] = useState<string>(
    initialValues?.monthsValue || ''
  )
  const [endValue, setEndValue] = useState<ReportEnd>(
    initialValues?.endValue || ReportEnd.NEVER
  )
  const [dateValue, setDateValue] = useState<Dayjs | null>(
    initialValues?.dateValue || null
  )
  const [occurrencesValue, setOccurrencesValue] = useState<number | null>(
    initialValues?.occurrencesValue || null
  )

  const isDateDisable = !(endValue === ReportEnd.ON)
  const isOccurrencesDisabled = !(endValue === ReportEnd.AFTER)

  const getState = (): CustomScheduleState => ({
    everyValue,
    periodValue,
    daysValue,
    monthsValue,
    endValue,
    dateValue,
    occurrencesValue,
  })

  useEffect(() => {
    getStateCallback(getState())
  }, [
    everyValue,
    periodValue,
    daysValue,
    monthsValue,
    endValue,
    dateValue,
    occurrencesValue,
  ])

  const handleEveryChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = Number(event.target.value)

    setEveryValue(value)
  }

  const handlePeriodChange = (value: RepeatPeriod) => {
    setPeriodValue(value)
  }

  const handleEndChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ReportEnd
    setEndValue(value)
  }

  const handleDateChange = (date: Dayjs) => {
    setDateValue(date)
  }

  const handleOccurrencesChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = Number(event.target.value)
    setOccurrencesValue(value)
  }

  const handleDaysChange = (v: Days) => {
    setDaysValue(v)
  }

  const handleMonthsChange = (v: string) => {
    setMonthsValue(v)
  }

  const repeatsBlock = computeRepeats({
    periodValue,
    daysValue,
    handleDaysChange,
    monthsValue,
    handleMonthsChange,
  })

  return (
    <>
      <Typography variant="h6" component="h2" mb={2}>
        Custom schedule
      </Typography>
      <Box display="flex" gap={2} mb={3}>
        <Box maxWidth="60px">
          <OutlinedInput
            value={everyValue}
            onChange={handleEveryChange}
            type="number"
            size="small"
            inputProps={{
              min: 0,
            }}
          />
        </Box>
        <Box minWidth={'100px'} maxWidth="150px">
          <FormControl fullWidth>
            <BasicSelect
              options={repeatPeriodOptions}
              value={periodValue}
              onChange={(value) => {
                handlePeriodChange(value as RepeatPeriod)
              }}
            />
          </FormControl>
        </Box>
      </Box>
      {repeatsBlock}
      <Typography mt={2}>Ends</Typography>
      <Box display="flex" gap="56px">
        <Box>
          <RadioGroup value={endValue} onChange={handleEndChange}>
            {EndOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{ textTransform: 'capitalize' }}
              />
            ))}
          </RadioGroup>
        </Box>
        <Box>
          <Box mt="46px">
            <DatepickerInput
              value={dateValue ? dayjs(dateValue?.valueOf()) : null}
              onChange={(value) => {
                if (value) {
                  handleDateChange(value)
                }
              }}
              disabled={isDateDisable}
            />
          </Box>
          <Box>
            <Box width="100%">
              <OutlinedInput
                value={occurrencesValue}
                onChange={handleOccurrencesChange}
                type="number"
                size="small"
                disabled={isOccurrencesDisabled}
                placeholder="1 occurrences"
                fullWidth
                inputProps={{
                  min: 0,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CustomSchedule
