import React, { useMemo, useState } from 'react'
import { useDownloadScansMutation } from 'ui-v2/src/hooks/queries/scans'
import { ArrowDropDown } from '@mui/icons-material'
import StyledButton from './styled-button'
import { CircularProgress, Menu, MenuItem, styled } from '@mui/material'
import { useToast } from 'ui-v2/src/hooks/toast'
import { useParams } from 'react-router-dom'
import { useListRecoveryPointsBackupEntitiesQuery } from 'ui-v2/src/hooks/queries/recovery-points'
import { useListScanJobEntitiesQuery } from 'ui-v2/src/hooks/queries/scan-log'
import { ASSET_DETAILS_ACTIVE_TAB } from '../constants'

type Item = {
  label: string
  value: string
  id: string
}

const SCAN_LABEL_MAP = {
  ransomware_scan: 'Ransomware Scan Report',
  malware_scan: 'Malware Scan Report',
  fs_check: 'Filesystem Scan Report',
}

const BaseMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 14,
  background: theme.palette.background.elevation2,
  paddingTop: '5px',
  paddingBottom: '5px',
  minWidth: '150px',
}))

const InteractiveMenuItem = styled(BaseMenuItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.background.highlight,
  },
}))

const NonInteractiveMenuItem = styled(BaseMenuItem)(({ theme }) => ({
  cursor: 'default',
  '&:hover': {
    background: theme.palette.background.elevation2,
  },
}))

const DownloadReport = () => {
  const { assetId, activeTab, recoveryPointId, scanJobId, volumeId } =
    useParams<{
      assetId: string | undefined
      activeTab: string | undefined
      recoveryPointId: string | undefined
      scanJobId: string | undefined
      volumeId: string | undefined
    }>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const toast = useToast()

  const { mutate } = useDownloadScansMutation()

  const {
    data: recoveryPointsEntitiesData,
    isLoading: isRecoveryPointEntitiesLoading,
  } = useListRecoveryPointsBackupEntitiesQuery(
    assetId,
    recoveryPointId,
    {
      scan_job_id: scanJobId,
    },
    {
      enabled: activeTab === ASSET_DETAILS_ACTIVE_TAB.recoveryPoint,
    }
  )

  const { data: scanJobEntitiesData, isLoading: isScanJobEntitiesLoading } =
    useListScanJobEntitiesQuery(assetId, scanJobId ?? recoveryPointId, {
      enabled: activeTab === ASSET_DETAILS_ACTIVE_TAB.scanLog,
    })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const downloadScanReport = (scanId: string) => {
    mutate(scanId, {
      onError: (error) => {
        toast.error(error.message ?? 'Failed to download scan report')
      },
      onSuccess: (scanReportLink) => {
        if (scanReportLink) {
          window.open(scanReportLink, '_blank')
        }
      },
    })
  }

  const handleItemClick = (item: Item) => {
    toast.info('Fetching scan report, please wait.')
    downloadScanReport(item.id)
    handleClose()
  }

  const items: Array<Item> = useMemo(() => {
    const entities = recoveryPointsEntitiesData ?? scanJobEntitiesData

    if (!entities) {
      return []
    }

    const selectedEntity = entities.find((entity) => entity.id === volumeId)

    if (!selectedEntity) {
      return []
    }

    return selectedEntity.scans.map((scan) => ({
      id: scan.id,
      value: scan.kind,
      label: SCAN_LABEL_MAP[scan.kind] ?? 'Scan Report',
    }))
  }, [recoveryPointsEntitiesData, scanJobEntitiesData, volumeId])

  const open = Boolean(anchorEl)

  const isLoading = isRecoveryPointEntitiesLoading || isScanJobEntitiesLoading

  return (
    <>
      <StyledButton
        variant={'outlined'}
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={{ marginTop: '12px' }}
      >
        Download
      </StyledButton>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiList-root': { padding: 0 },
        }}
      >
        {isLoading ? (
          <NonInteractiveMenuItem>
            <CircularProgress
              size={16}
              sx={{
                color: 'grey.500',
                marginX: 'auto',
              }}
            />
          </NonInteractiveMenuItem>
        ) : items.length ? (
          items.map((item, index) => (
            <InteractiveMenuItem
              key={item.id}
              onClick={() => handleItemClick(item)}
              sx={{
                borderBottom:
                  index === items.length - 1 ? 'none' : '1px solid #393F49',
              }}
            >
              {item.label}
            </InteractiveMenuItem>
          ))
        ) : (
          <NonInteractiveMenuItem>No Latest Scans</NonInteractiveMenuItem>
        )}
      </Menu>
    </>
  )
}

export default DownloadReport
