/* eslint-disable import/no-extraneous-dependencies */
import {
  GeneratedReportsPromiseClient,
  ListGeneratedReportsResponse,
} from 'ui-v2/src/lib/grpc'
import { ClientConfig } from 'ui-v2/src/lib/models/client'
import {
  GenerateOnDemandReportCriteria,
  ListGeneratedReportsRequestParams,
} from 'ui-v2/src/lib/models/reports'
import { GrpcClient } from 'ui-v2/src/lib/clients/grpc'

import { buildGenerateOnDemandReportRequest } from './generate-on-demand-report'
import GrpcError from 'ui-v2/src/lib/helpers/grpc-error.helper'
import { GrpcCodes } from 'ui-v2/src/lib/constants/api-errors.constant'
import { GeneratedReport as GeneratedReportGRPC } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/generated_reports/generated_report_pb'
import { Origin } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/generated_reports/origin_pb'
import { Status } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/generated_reports/status_pb'
import { ReportGenerated } from 'blues-corejs/dist/models/reports/report-generated/report-generated'
import {
  OnDemandOrigin,
  OriginReport,
  ScheduledOrigin,
} from 'blues-corejs/dist/models/reports/report-generated/origin'
import {
  ReportGeneratedCompleted,
  ReportGeneratedPending,
  ReportGeneratedStatus,
} from 'blues-corejs/dist/models/reports/report-generated/status'
import { ListGeneratedReportsFilter } from './list-generated-reports-filter'
import {
  GeneratedAwsAccountSummaryReportParams,
  GeneratedAwsCompletedScansReportParams,
  GeneratedAwsExecutiveReportParams,
  GeneratedAwsInfectedAssetsReportParams,
  GeneratedAwsScannedAssetsSummaryReportParams,
  GeneratedAwsTenantSummaryReportParams,
  GeneratedFailedScansReportParams,
  GeneratedInfectedFilesReportParams,
  GeneratedOvaMspAccountSummaryReportParams,
  GeneratedOvaMspCompletedScansReportParams,
  GeneratedOvaMspExecutiveReportParams,
  GeneratedOvaMspFailedScansReportParams,
  GeneratedOvaMspInfectedAssetsReportParams,
  GeneratedOvaMspInfectedFilesReportParams,
  GeneratedOvaMspOperationalReportParams,
  GeneratedOvaMspScannedAssetsSummaryReportParams,
  GeneratedOvaMspTenantSummaryReportParams,
  GeneratedReportKindWithParams,
  ReportKindCase,
} from 'blues-corejs/dist/models/reports/report-generated/generated-kind'
import { ReportKindWithParams as ReportKindWithParamsGrpc } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/generated_reports/kind_pb'
import {
  buildDownloadGeneratedReportRequest,
  DownloadGeneratedReportCriteria,
  DownloadGeneratedReportResponse,
} from './download-generated-report'

const CLIENT_TYPE_ID = 'GeneratedReportsClient'

export class GeneratedReportsClient extends GrpcClient<GeneratedReportsPromiseClient> {
  #generatedReportsClient: GeneratedReportsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#generatedReportsClient = this.getClient(hostName)
    this.#token = token
  }

  protected initClient(hostName: string): GeneratedReportsPromiseClient {
    return new GeneratedReportsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return CLIENT_TYPE_ID
  }

  async generateOnDemandReport(
    criteria: GenerateOnDemandReportCriteria
  ): Promise<void> {
    const request = buildGenerateOnDemandReportRequest(criteria)

    try {
      await this.callGrpcService(
        () =>
          this.#generatedReportsClient.generateOnDemandReport(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: `${this.innerClientTypeId()}/generateOnDemandReport`,
        }
      )
    } catch (error) {
      if (error instanceof GrpcError) {
        const grpcError = new GrpcError({
          code: error.code ?? GrpcCodes.ALREADY_EXISTS,
          message: '',
          metadata: {},
          requestName: `${this.innerClientTypeId()}/generateOnDemandReport`,
        })
        throw grpcError
      }
    }
  }

  async listGeneratedReport(
    params: ListGeneratedReportsRequestParams
  ): Promise<{
    generatedReportList: Array<ReportGenerated>
    pageToken: string | undefined
  }> {
    const listGeneratedReports = new ListGeneratedReportsFilter(params)
    const request = listGeneratedReports.toGrpc()

    const response = await this.callGrpcService(
      () =>
        this.#generatedReportsClient.listGeneratedReports(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: `${this.innerClientTypeId()}/listGeneratedReports`,
      }
    )

    return {
      generatedReportList: this.#transformGeneratedReportList(response),
      pageToken: response.getNextPageToken(),
    }
  }

  async downloadGeneratedReport({
    id,
  }: DownloadGeneratedReportCriteria): Promise<DownloadGeneratedReportResponse> {
    const request = buildDownloadGeneratedReportRequest({ id })
    const response = await this.callGrpcService(
      () =>
        this.#generatedReportsClient.downloadGeneratedReport(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: `${this.innerClientTypeId()}/downloadGeneratedReport`,
      }
    )
    const generatedReport = response.getReport()?.getReport()

    const result: DownloadGeneratedReportResponse = {
      csvUrl: response.getReport()?.getCsvUrl(),
      pdfUrl: response.getReport()?.getPdfUrl(),
      xlsxUrl: response.getReport()?.getXlsxUrl(),
      generatedReport: undefined,
    }

    if (generatedReport) {
      result.generatedReport = this.#transformGeneratedReport(generatedReport)
    }

    return result
  }

  #transformGeneratedReportList(
    response: ListGeneratedReportsResponse
  ): Array<ReportGenerated> {
    const list = response.getReportsList()

    const reportGenerated = list.map((report) =>
      this.#transformGeneratedReport(report)
    )

    return reportGenerated
  }

  #transformGeneratedReport(report: GeneratedReportGRPC): ReportGenerated {
    return new ReportGenerated({
      id: report.getId(),
      availableFormatsList: report.getAvailableFormatsList(),
      reportKindWithParams: this.#transformreportKindWithParams(
        report?.getReportKindWithParams()
      ),
      origin: this.#transformOrigin(report?.getOrigin()),
      status: this.#transformStatus(report?.getStatus()),
      createdAt: report.getCreatedAt()?.toDate(),
      updatedAt: report.getUpdatedAt()?.toDate(),
    })
  }

  #transformreportKindWithParams(
    reportKindWithParams: ReportKindWithParamsGrpc | undefined
  ): GeneratedReportKindWithParams | undefined {
    const ovaMsp = reportKindWithParams?.getOvaMspExecutive()
    if (ovaMsp) {
      return new GeneratedOvaMspExecutiveReportParams({
        kind: ReportKindCase.OVA_MSP_EXECUTIVE,
        startAt: ovaMsp.getStartAt()?.toDate(),
        endDate: ovaMsp.getEndAt()?.toDate(),
        ovaAccountIds: ovaMsp.getOvaAccountIdsList(),
      })
    }

    const ovaMspOperational = reportKindWithParams?.getOvaMspOperational()

    if (ovaMspOperational) {
      return new GeneratedOvaMspOperationalReportParams({
        kind: ReportKindCase.OVA_MSP_OPERATIONAL,
        startAt: ovaMspOperational.getStartAt()?.toDate(),
        endDate: ovaMspOperational.getEndAt()?.toDate(),
        ovaAccountIds: ovaMspOperational.getOvaAccountIdsList(),
      })
    }

    const ovaMspTenantSummary = reportKindWithParams?.getOvaMspTenantSummary()

    if (ovaMspTenantSummary) {
      return new GeneratedOvaMspTenantSummaryReportParams({
        kind: ReportKindCase.OVA_MSP_TENANT_SUMMARY,
        startAt: ovaMspTenantSummary.getStartAt()?.toDate(),
        endDate: ovaMspTenantSummary.getEndAt()?.toDate(),
        ovaAccountIds: ovaMspTenantSummary?.getOvaAccountIdsList(),
      })
    }

    const ovaMspAccountSummary = reportKindWithParams?.getOvaMspAccountSummary()

    if (ovaMspAccountSummary) {
      return new GeneratedOvaMspAccountSummaryReportParams({
        kind: ReportKindCase.OVA_MSP_ACCOUNT_SUMMARY,
        startAt: ovaMspAccountSummary.getStartAt()?.toDate(),
        endDate: ovaMspAccountSummary.getEndAt()?.toDate(),
        ovaAccountIds: ovaMspAccountSummary.getOvaAccountIdsList(),
      })
    }

    const ovaMspCompletedScans = reportKindWithParams?.getOvaMspCompletedScans()

    if (ovaMspCompletedScans) {
      return new GeneratedOvaMspCompletedScansReportParams({
        kind: ReportKindCase.OVA_MSP_COMPLETED_SCANS,
        startAt: ovaMspCompletedScans.getStartAt()?.toDate(),
        endDate: ovaMspCompletedScans.getEndAt()?.toDate(),
        ovaAccountIds: ovaMspCompletedScans.getOvaAccountIdsList(),
      })
    }

    const ovaMspScannedAssetsSummary =
      reportKindWithParams?.getOvaMspScannedAssetsSummary()

    if (ovaMspScannedAssetsSummary) {
      return new GeneratedOvaMspScannedAssetsSummaryReportParams({
        kind: ReportKindCase.OVA_MSP_SCANNED_ASSETS_SUMMARY,
        startAt: ovaMspScannedAssetsSummary.getStartAt()?.toDate(),
        endDate: ovaMspScannedAssetsSummary.getEndAt()?.toDate(),
        ovaAccountIds: ovaMspScannedAssetsSummary.getOvaAccountIdsList(),
      })
    }

    const ovaMspInfectedFiles = reportKindWithParams?.getOvaMspInfectedFiles()

    if (ovaMspInfectedFiles) {
      return new GeneratedOvaMspInfectedFilesReportParams({
        kind: ReportKindCase.OVA_MSP_INFECTED_FILES,
        startAt: ovaMspInfectedFiles.getStartAt()?.toDate(),
        endDate: ovaMspInfectedFiles.getEndAt()?.toDate(),
        ovaAccountIds: ovaMspInfectedFiles.getOvaAccountIdsList(),
      })
    }

    const ovaMspInfectedAssets = reportKindWithParams?.getOvaMspInfectedAssets()

    if (ovaMspInfectedAssets) {
      return new GeneratedOvaMspInfectedAssetsReportParams({
        kind: ReportKindCase.OVA_MSP_INFECTED_ASSETS,
        startAt: ovaMspInfectedAssets.getStartAt()?.toDate(),
        endDate: ovaMspInfectedAssets.getEndAt()?.toDate(),
        ovaAccountIds: ovaMspInfectedAssets.getOvaAccountIdsList(),
      })
    }

    const ovaMspFailedScans = reportKindWithParams?.getOvaMspFailedScans()

    if (ovaMspFailedScans) {
      return new GeneratedOvaMspFailedScansReportParams({
        kind: ReportKindCase.OVA_MSP_FAILED_SCANS,
        startAt: ovaMspFailedScans.getStartAt()?.toDate(),
        endDate: ovaMspFailedScans.getEndAt()?.toDate(),
        ovaAccountIds: ovaMspFailedScans.getOvaAccountIdsList(),
      })
    }

    const awsExecutive = reportKindWithParams?.getAwsExecutive()

    if (awsExecutive) {
      return new GeneratedAwsExecutiveReportParams({
        kind: ReportKindCase.AWS_EXECUTIVE,
        startAt: awsExecutive.getStartAt()?.toDate(),
        endDate: awsExecutive.getEndAt()?.toDate(),
      })
    }

    const awsTenantSummary = reportKindWithParams?.getAwsTenantSummary()

    if (awsTenantSummary) {
      return new GeneratedAwsTenantSummaryReportParams({
        kind: ReportKindCase.AWS_TENANT_SUMMARY,
        startAt: awsTenantSummary.getStartAt()?.toDate(),
        endDate: awsTenantSummary.getEndAt()?.toDate(),
      })
    }

    const awsAccountSummary = reportKindWithParams?.getAwsAccountSummary()

    if (awsAccountSummary) {
      return new GeneratedAwsAccountSummaryReportParams({
        kind: ReportKindCase.AWS_ACCOUNT_SUMMARY,
        startAt: awsAccountSummary.getStartAt()?.toDate(),
        endDate: awsAccountSummary.getEndAt()?.toDate(),
      })
    }

    const awsCompletedScans = reportKindWithParams?.getAwsCompletedScans()

    if (awsCompletedScans) {
      return new GeneratedAwsCompletedScansReportParams({
        kind: ReportKindCase.AWS_COMPLETED_SCANS,
        startAt: awsCompletedScans.getStartAt()?.toDate(),
        endDate: awsCompletedScans.getEndAt()?.toDate(),
      })
    }

    const awsScannedAssetsSummary =
      reportKindWithParams?.getAwsScannedAssetsSummary()

    if (awsScannedAssetsSummary) {
      return new GeneratedAwsScannedAssetsSummaryReportParams({
        kind: ReportKindCase.AWS_SCANNED_ASSETS_SUMMARY,
        startAt: awsScannedAssetsSummary.getStartAt()?.toDate(),
        endDate: awsScannedAssetsSummary.getEndAt()?.toDate(),
      })
    }

    const awsInfectedAssets = reportKindWithParams?.getAwsInfectedAssets()

    if (awsInfectedAssets) {
      return new GeneratedAwsInfectedAssetsReportParams({
        kind: ReportKindCase.AWS_INFECTED_ASSETS,
        startAt: awsInfectedAssets.getStartAt()?.toDate(),
        endDate: awsInfectedAssets.getEndAt()?.toDate(),
      })
    }

    const infectedFiles = reportKindWithParams?.getInfectedFiles()

    if (infectedFiles) {
      return new GeneratedInfectedFilesReportParams({
        kind: ReportKindCase.INFECTED_FILES,
        startAt: infectedFiles.getStartAt()?.toDate(),
        endDate: infectedFiles.getEndAt()?.toDate(),
      })
    }

    const failedScans = reportKindWithParams?.getFailedScans()

    if (failedScans) {
      return new GeneratedFailedScansReportParams({
        kind: ReportKindCase.FAILED_SCANS,
        startAt: failedScans.getStartAt()?.toDate(),
        endDate: failedScans.getEndAt()?.toDate(),
      })
    }

    return undefined
  }

  #transformOrigin(origin: Origin | undefined): OriginReport {
    const scheduled = origin?.getScheduled()
    if (scheduled) {
      return new ScheduledOrigin({
        id: scheduled.getId(),
        name: scheduled.getName(),
        description: scheduled.getDescription(),
      })
    }

    const onDemand = origin?.getOnDemand()
    const description = onDemand?.getDescription() || ''

    return new OnDemandOrigin({
      description,
    })
  }

  #transformStatus(status: Status | undefined): ReportGeneratedStatus {
    const completed = status?.getCompleted()
    if (completed) {
      const completedAt = completed?.getGeneratedAt()?.toDate()
      return new ReportGeneratedCompleted(completedAt)
    }

    return new ReportGeneratedPending()
  }
}
