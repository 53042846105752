import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'

import { DEPLOYMENT_FILTER_MAP } from 'ui-v2/src/lib/constants/monitor.constant'
import dayjs, { Dayjs } from 'dayjs'
import { transformDateRangeForApi } from 'ui-v2/src/lib/transformers/reports/transform-date-range-for-api'
import { TimeRangeInSeconds } from 'ui-v2/src/lib/models/types'
import { ListTenantJobsFilterRequestParams } from 'ui-v2/src/lib/clients/jobs'
import { SYSTEM_JOB_KINDS } from 'ui-v2/src/lib/transformers/tenant-jobs/tenant-job-kind'

export interface JobFilters<T> {
  filters: T
  startDate: Dayjs
  endDate: Dayjs
  timeRange: TimeRangeInSeconds
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  setPageToken: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const useSystemFilters =
  (): JobFilters<ListTenantJobsFilterRequestParams> => {
    const [pageSize, setPageSize] = useState<number>(
      TABLE_ROWS_PER_PAGE_OPTIONS[0]
    )
    const [pageToken, setPageToken] = useState<string | undefined>(undefined)
    const [searchParams] = useSearchParams()

    const startDate = searchParams.get('start')
      ? dayjs(searchParams.get('start'))
      : dayjs().startOf('month')
    const endDate = searchParams.get('end')
      ? dayjs(searchParams.get('end'))
      : dayjs()

    const timeRange = transformDateRangeForApi([startDate, endDate])

    useEffect(() => {
      setPageToken(undefined)
    }, [searchParams])

    return useMemo(() => {
      return {
        filters: {
          pageSize,
          pageToken,
          kindsList: searchParams.get(DEPLOYMENT_FILTER_MAP.JOB)
            ? searchParams
                .get(DEPLOYMENT_FILTER_MAP.JOB)!
                .split(',')
                .map(Number)
            : SYSTEM_JOB_KINDS,
          statusesList: searchParams.get(DEPLOYMENT_FILTER_MAP.STATUS)
            ? searchParams
                .get(DEPLOYMENT_FILTER_MAP.STATUS)
                ?.split(',')
                ?.map((id) => +id)
            : [],

          timeRange,
        },
        startDate: startDate,
        endDate: endDate,
        timeRange: timeRange,
        setPageSize,
        setPageToken,
      }
    }, [pageSize, pageToken, searchParams, setPageSize, setPageToken])
  }
