import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { industryOptions } from './industry-options'
import RoiForm from './roi-form'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
} from 'ui-v2/src/components/ui/drawer'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { useGetDataScannedQuery } from 'ui-v2/src/hooks/queries/data-scanned'
import { RoiData } from '../util'

type ReturnOfInvestmentDrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
  roiData: RoiData
  setRoiData: Dispatch<SetStateAction<RoiData>>
}

const roiReductionFactor = 0.01

const bytesToTerabytes = (bytes: number): number => {
  const bytesInTerabyte = 1099511627776 // 1 TB = 1,099,511,627,776 bytes (2^40)
  return Number((bytes / bytesInTerabyte).toFixed(2))
}

const ReturnOfInvestmentDrawer: React.FC<ReturnOfInvestmentDrawerProps> = ({
  isOpen,
  closeDrawer,
  roiData,
  setRoiData,
}) => {
  const [isFormCleared, setIsFormCleared] = useState(false)

  const { data: bytesScannedData, status } = useGetDataScannedQuery()

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<RoiData>({
    defaultValues: roiData,
    mode: 'onChange',
  })

  const { selectedIndustry, revenueInput, downtimeCost } = watch()

  useEffect(() => {
    if (status === 'success' && !roiData.downtimeCost) {
      const terabytesScanned = bytesToTerabytes(bytesScannedData.bytesScanned)
      setValue('downtimeCost', terabytesScanned)
    }
  }, [bytesScannedData, status, setValue])

  const onSubmit = (data: RoiData) => {
    const selectedIndustryData = industryOptions.find(
      (industry) => industry.value === data.selectedIndustry
    )

    const hourlyDowntimeCost =
      (data.revenueInput || 0) > 1000000
        ? selectedIndustryData?.enterprise
        : selectedIndustryData?.smb

    const downtimeSavings =
      Number(hourlyDowntimeCost) * (data.downtimeCost || 0)

    const latestRoiData: RoiData = {
      selectedIndustry: data.selectedIndustry,
      revenueInput: data.revenueInput,
      downtimeCost: data.downtimeCost,
      calculatedRoi: downtimeSavings.toString(),
    }

    // Reduce ROI data with the reduction factor
    const calculatedRoi =
      Number(latestRoiData.calculatedRoi) * roiReductionFactor
    latestRoiData.calculatedRoi = calculatedRoi.toString()

    localStorage.setItem('roiData', JSON.stringify(latestRoiData))
    setRoiData(latestRoiData)
  }

  useEffect(() => {
    if (isFormCleared && (selectedIndustry || revenueInput || downtimeCost)) {
      setIsFormCleared(false)
    }
  }, [selectedIndustry, revenueInput, downtimeCost, isFormCleared])

  const isSaveDisabled =
    isFormCleared ||
    !isValid ||
    !selectedIndustry ||
    !revenueInput ||
    !downtimeCost

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: '500px',
        },
      }}
    >
      <DrawerContent isEmpty={false}>
        <DrawerHeader
          title="Estimated Value"
          handleCloseDrawer={closeDrawer}
          typographySx={(theme) => ({
            fontSize: '22px',
            fontWeight: 700,
            color: theme.palette.icon.main,
          })}
          padding={3}
        />
        <Stack
          spacing={6}
          sx={{
            paddingX: 3,
            paddingY: 1.5,
            color: 'text.secondary',
            fontWeight: 500,
          }}
        >
          <Typography fontWeight={500}>
            Proactive ransomware recovery assurance means your business is
            always in a position to recover from ransomware attacks quickly and
            with clean data.
          </Typography>
          <Typography fontWeight={500}>
            This calculator estimates the value of your investment using
            industry benchmarks for downtime costs and expected recovery times.
            Simply enter your business details and watch as the estimated
            savings grow, the more that Elastio protects.
          </Typography>
        </Stack>
        {status !== 'pending' ? (
          <RoiForm
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            roiData={roiData}
            control={control}
            isSaveDisabled={isSaveDisabled}
          />
        ) : (
          <Stack alignItems={'center'} p={4}>
            <CircularProgress size={24} sx={{ color: 'grey.500' }} />
          </Stack>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default ReturnOfInvestmentDrawer
