import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { NotificationsRulesClient } from 'ui-v2/src/lib/clients'
import { useMutation } from '@tanstack/react-query'
import { CreateNotificationRuleCriteria } from 'ui-v2/src/lib/clients/alerts/types'

export function useCreateNotificationRule() {
  const notificationClient = useGrpcClient(NotificationsRulesClient)

  return useMutation({
    mutationFn: async (criteria: CreateNotificationRuleCriteria) => {
      if (!notificationClient) {
        throw new Error('Notification Client not initialized')
      }
      return notificationClient.createNotificationRule(criteria)
    },
  })
}
