import { Skeleton, Stack } from '@mui/material'
import React from 'react'

const LastCleanBackupWidgetLoading = () => {
  return (
    <Stack spacing={2} height={'100%'}>
      <Stack
        spacing={1}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Skeleton variant="text" width={60} height={32} />
          <Skeleton variant="text" width={100} height={20} />
        </Stack>
        <Skeleton variant="text" width={60} height={20} />
      </Stack>
      <Stack spacing={0.5}>
        <Stack spacing={0.5}>
          <Skeleton variant="text" width={60} height={20} />
          <Skeleton variant="text" width={120} height={20} />
        </Stack>
        <Stack spacing={0.5}>
          <Skeleton variant="text" width={60} height={20} />
          <Skeleton variant="text" width={120} height={20} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default LastCleanBackupWidgetLoading
