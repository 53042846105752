import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import {
  ASSET_FILTERS_MAP,
  NAME_TAG_KEY,
} from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const VolumeNameFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const volumeNameList =
    searchParams.get(ASSET_FILTERS_MAP.VOLUME_NAME)?.split(',') || []

  const { data: liveAssetsData } = useAssetsLiveQuery()

  const volumeIdOptions = useMemo(() => {
    if (!liveAssetsData) {
      return []
    }

    const volumeNameSet = new Set<string>()

    liveAssetsData.ebsVolumes.forEach((volume) => {
      const nameTag = volume.tags.find((tag) => tag.key === NAME_TAG_KEY)
      if (nameTag) {
        volumeNameSet.add(nameTag.value)
      }
    })

    return Array.from(volumeNameSet).map((volumeName) => ({
      label: volumeName,
      value: volumeName,
    }))
  }, [liveAssetsData])

  const handleVolumeIdChange = (
    newVolumeNameList: Array<MultiSelectOption['value']>
  ) => {
    if (newVolumeNameList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.VOLUME_NAME)
    } else {
      searchParams.set(
        ASSET_FILTERS_MAP.VOLUME_NAME,
        newVolumeNameList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={volumeIdOptions}
      value={volumeNameList}
      onChange={handleVolumeIdChange}
      label={
        <SelectLabelText
          selectedValueLength={volumeNameList?.length}
          optionsLength={volumeIdOptions?.length}
          text="Volumes"
        />
      }
      width={200}
    />
  )
}

export default VolumeNameFilter
