import React, { useMemo } from 'react'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { useAssetDetails } from '../common/use-asset-details'
import { useLastCleanBackupQuery } from 'ui-v2/src/hooks/queries/backups'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import LastCleanBackupWidgetLoading from './loading'
import CleanBackupDetails from './clean-backup-details'
import NoCleanBackupDetails from './no-clean-backup-details'

const LastCleanBackupWidget = () => {
  const { asset, assetId, isAssetsDataLoading, isAssetsDataError } =
    useAssetDetails()

  const {
    data: assetLastCleanBackup,
    isLoading: isAssetLastCleanBackupLoading,
    isError: isAssetLastCleanBackupError,
  } = useLastCleanBackupQuery(assetId)

  const lastCleanBackup = useMemo(() => {
    if (!assetLastCleanBackup) {
      return undefined
    }

    return Object.entries(assetLastCleanBackup)
      .filter(([, value]) => value !== undefined)
      .map(([, value]) => value)[0]
  }, [assetLastCleanBackup])

  const isLoading = isAssetsDataLoading || isAssetLastCleanBackupLoading
  const isError = isAssetsDataError || isAssetLastCleanBackupError

  const gradientCardProps = () => ({
    gradientVariant:
      isError || (!isLoading && !lastCleanBackup)
        ? ('red' as const)
        : ('green' as const),
    sx: {
      height: '100%',
      padding: '1rem',
    },
  })

  if (isLoading) {
    return (
      <GradientCard {...gradientCardProps()}>
        <LastCleanBackupWidgetLoading />
      </GradientCard>
    )
  }

  if (isError) {
    return (
      <GradientCard {...gradientCardProps()}>
        <ErrorDisplay message="Failed to load widget, please try again later." />
      </GradientCard>
    )
  }

  return (
    <>
      <GradientCard {...gradientCardProps()}>
        {lastCleanBackup ? (
          <CleanBackupDetails
            asset={asset}
            lastCleanBackup={lastCleanBackup}
            isLoading={isLoading}
            isError={isError}
          />
        ) : (
          <NoCleanBackupDetails />
        )}
      </GradientCard>
    </>
  )
}

export default LastCleanBackupWidget
