import { ColorSystemOptions } from '@mui/material'

export const lightColorSystem: ColorSystemOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#1AA8EA',
      contrastText: '#fff',
    },
    accent: {
      main: '#FF1654',
    },
    background: {
      default: '#E3E8ED',
      secondary: '#fff', // Used for navbar and sidebar
      tertiary: '#fff',
      paper: '#fff',
      drawer: '#fff',
      dialog: '#f1f1f1',
      hover: '#fff',
      elevation1: '#EFF3F6',
      elevation2: '#F6F9FC',
      elevation3: '#FFFFFF',
      highlight: '#FFFFFF',
    },
    text: {
      primary: '#50565c',
      // secondary: '#85888c',
      secondary: '#4e5665',
      muted: '#878d91',
    },
    icon: {
      main: '#85888c',
    },
    border: {
      dark: '#D3D8DE',
      main: '#0000003b',
    },
    grey: {
      700: '#ccccdc33',
      800: '#f9f9fb',
    },
    link: {
      main: '#0084C1',
    },
  },
}
