import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { BackupsClient } from 'ui-v2/src/lib/clients'

export function useLastCleanBackupQuery(assetId: string | undefined) {
  const backupsClient = useGrpcClient(BackupsClient)

  return useQuery({
    queryKey: ['backups', 'last-clean', assetId],
    queryFn: async () => {
      if (!backupsClient) {
        throw new Error('Assets client not initialized')
      }
      if (!assetId) {
        throw new Error('Asset ID is required')
      }
      return backupsClient.lastCleanBackupForAsset(assetId)
    },
    enabled: !!backupsClient && !!assetId,
  })
}
