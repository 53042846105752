import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid2'
import { FormControl, Typography } from '@mui/material'

import { Controller, useFormContext } from 'react-hook-form'
import { reportOptions } from 'ui-v2/src/lib/constants/reports.constant'
import {
  FormOptionValueSlug,
  FormVariant,
  Option,
  ReportForm,
} from 'ui-v2/src/lib/models/reports'

import AwsForm from './aws-form'
import BackupConnectorForm from './backup-connector-form'
import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'

const OnDemandForm = ({
  selectReportType,
  reportId,
  setReportTypeLabel,
}: OnDemandFormProps) => {
  const navigate = useNavigate()

  const { watch, control, setValue, reset } = useFormContext<ReportForm>()

  const selectedReportValue = watch('reportType')

  const selectedReportOption = useMemo(
    () => reportOptions.find((option) => option.value === selectedReportValue),
    [selectedReportValue]
  )

  const selectedFormVariant = selectedReportOption?.type
  const availableFormats = selectedReportOption?.formats ?? []

  const handleReportTypeChange = (value: string) => {
    const reportOption = reportOptions.find((option) => option.value === value)

    if (reportOption?.slug) {
      navigate(
        `/reports/schedules/form/${FormOptionValueSlug.ON_DEMAND}/${reportOption.slug}`
      )
    }
  }

  useEffect(() => {
    if (selectReportType) {
      setValue('reportType', selectReportType?.value?.toString())
    }
  }, [selectReportType])

  useEffect(() => {
    if (selectedReportOption) {
      setReportTypeLabel(selectedReportOption.label)
    }
  }, [selectedReportOption])

  useEffect(() => {
    if (availableFormats.length === 1) {
      setValue('format', [availableFormats[0] ?? ''])
    }
  }, [availableFormats])

  return (
    <Grid container spacing={2}>
      <Grid
        size={{
          xs: 12,
          sm: 10,
          md: 9,
          lg: 8,
        }}
      >
        <FormControl fullWidth size="small">
          <Typography variant="body1" mb={1}>
            Report Type
          </Typography>
          <Controller
            control={control}
            name="reportType"
            rules={{ required: 'Report Type is required' }}
            render={({ field }) => (
              <BasicSelect
                value={field.value}
                onChange={(value) => {
                  field.onChange(value)
                  handleReportTypeChange(value as string)
                  reset()
                }}
                options={reportOptions.map(({ label, value }) => ({
                  label,
                  value,
                }))}
                size="small"
                placeholder="Select a Report"
                disabled={!!reportId}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid size={12}>
        <FormControl fullWidth>
          <Typography variant="body1" mb={1}>
            Description (Optional)
          </Typography>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Description"
                size="small"
                slotProps={{
                  htmlInput: { maxLength: 120 },
                }}
              />
            )}
          />
        </FormControl>
      </Grid>

      {selectedFormVariant === FormVariant.AWS && (
        <AwsForm availableFormats={availableFormats} />
      )}
      {selectedFormVariant === FormVariant.BackupConnector && (
        <BackupConnectorForm availableFormats={availableFormats} />
      )}
    </Grid>
  )
}
interface OnDemandFormProps {
  selectReportType?: Option
  reportId?: string
  setReportTypeLabel: Dispatch<SetStateAction<string>>
}
export default OnDemandForm
