import { Nullable } from 'ui-v2/src/lib/engine-types'
import { BaseModel, RedStackModel } from 'ui-v2/src/lib/models/base-model'
import { IntegrityCheckError } from 'ui-v2/src/lib/models/base-model/red-stack.model'
import LangHelper from 'ui-v2/src/lib/helpers/lang.helper'
import { realFileSize } from 'ui-v2/src/lib/helpers/string.helper'
import { roundTo } from 'ui-v2/src/lib/helpers/num.helper'

class VaultModel extends BaseModel {
  public readonly name: string

  public readonly accountId: string

  public readonly region: string

  public readonly redStackId: string

  public readonly assetsCount: number

  public readonly recoveryPointsCount: number

  public readonly vpcId: string

  public readonly subnetIdsList: Array<string>

  public readonly isDefault: boolean

  public readonly version: string

  public readonly totalOptimizedSize: number

  public readonly totalRecoveryPointSize: number

  public readonly reductionFactor: number

  public readonly contaminatedRecoveryPointsCount: number

  public readonly cleanRecoveryPointsCount: number

  public readonly description: string

  public readonly safetyLock: boolean

  public readonly softDeleted: boolean

  // we can get this info from the extra request
  public readonly redStack: Nullable<RedStackModel>

  // extra

  public readonly regionLabel: string

  public readonly totalOptimizedSizeFormatted: string

  public readonly allocatedSizeFormatted: string

  public readonly totalRecoveryPointSizeFormatted: string

  public readonly backUpSizeFormatted: string

  public readonly reductionFactorFormatted: string

  public readonly lastIntegrityCheckErrorsList: Array<IntegrityCheckError>

  constructor(readonly params: any = {}) {
    super(params)
    // base
    this.name = params.name
    this.accountId = params.accountId
    this.region = params.region
    this.redStackId = params.redStackId
    this.assetsCount = params.assetsCount
    this.recoveryPointsCount = params.recoveryPointsCount
    this.vpcId = params.vpcId
    this.subnetIdsList = params.subnetIdsList
    this.isDefault = params.isDefault
    this.redStack = params.redStack
    this.safetyLock = params.safetyLock
    this.softDeleted = params.softDeleted

    this.version = params.version
    this.totalOptimizedSize = params.totalOptimizedSize
    this.allocatedSizeFormatted = params.allocatedSizeFormatted
    this.totalRecoveryPointSize = params.totalRecoveryPointSize
    this.backUpSizeFormatted = params.backUpSizeFormatted
    this.reductionFactor = params.reductionFactor
    this.contaminatedRecoveryPointsCount =
      params.contaminatedRecoveryPointsCount
    this.cleanRecoveryPointsCount = params.cleanRecoveryPointsCount
    this.description = params.description

    this.lastIntegrityCheckErrorsList = params.lastIntegrityCheckErrorsList

    // extra
    this.regionLabel = LangHelper.getAwsRegionSingleTranslation(this.region)
    this.totalOptimizedSizeFormatted = realFileSize(
      this.totalOptimizedSize,
      '0'
    )

    this.allocatedSizeFormatted = realFileSize(this.totalOptimizedSize, '--')

    this.totalRecoveryPointSizeFormatted = realFileSize(
      this.totalRecoveryPointSize,
      '0'
    )

    this.backUpSizeFormatted = realFileSize(this.totalRecoveryPointSize, '--')

    const reductionFactorFormatted = String(roundTo(this.reductionFactor, 2))
    this.reductionFactorFormatted = reductionFactorFormatted
      ? `${reductionFactorFormatted}x`
      : '- - x'
  }
}

export default VaultModel
