import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import {
  buildCfnUpdatingCommand,
  buildCfnUpdatingLink,
} from 'ui-v2/src/lib/helpers/data.helper'
import CopyRow from './copy-row'
import {
  useDiscoverInstalledCfnQuery,
  useGetCloudInformationLinkQuery,
} from 'ui-v2/src/hooks/queries/red-stack'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import CfnModel from 'ui-v2/src/lib/models/red-stack/cfn.model'
import DrawerLoader from '../drawer-loader'

const BulletListItem = styled(ListItem)({
  display: 'list-item',
  listStyleType: 'disc',
  marginLeft: 20,
  padding: 0,

  '& .MuiTypography-root': {
    fontSize: 14,
    fontWeight: 400,
  },
})

const UpgradeCloudConnectorModal = () => {
  const { id } = useParams()
  const rexClient = useGrpcClient(RexClient)
  const { mutate: discoverInstalledCfnMutate } = useDiscoverInstalledCfnQuery()
  const {
    data: cloudInformationLinkData,
    isLoading: isLoadingCloudFormationLink,
  } = useGetCloudInformationLinkQuery()

  const [cfn, setCfn] = useState<CfnModel | null>(null)

  useEffect(() => {
    if (!id || !rexClient) {
      return
    }
    discoverInstalledCfnMutate(id, {
      onSuccess: (cfnData) => {
        setCfn(cfnData)
      },
      onError: (error) => {
        console.log(error.message)
      },
    })
  }, [id, rexClient])

  if (!cfn || isLoadingCloudFormationLink) {
    return <DrawerLoader />
  }

  const typographyProps: TypographyProps = {
    mb: 1,
    variant: 'body2',
    fontWeight: 400,
  }

  return (
    <Box
      sx={{
        paddingX: 2.5,
        paddingBottom: 2.5,
      }}
    >
      <Typography {...typographyProps}>A new version is available.</Typography>
      <Typography {...typographyProps}>To upgrade:</Typography>
      <List sx={{ padding: 0 }}>
        <BulletListItem>
          <ListItemText secondary="In the UI: Click the Upgrade button." />
        </BulletListItem>
        <BulletListItem>
          <ListItemText secondary="Using AWS CLI: Run the appropriate CFN update command in AWS CloudFormation." />
        </BulletListItem>
      </List>
      <Typography {...typographyProps} mb={3}>
        Updating ensures you have the latest features, security improvements,
        and performance enhancements.
      </Typography>

      <Grid container spacing={4} pb={2}>
        <Grid size={12}>
          <Button
            variant="contained"
            size="medium"
            disableElevation
            sx={{
              textTransform: 'capitalize',
            }}
            href={buildCfnUpdatingLink(cloudInformationLinkData || '', cfn)}
            target="_blank"
            disabled={!cloudInformationLinkData}
          >
            Upgrade
          </Button>
        </Grid>
        <Grid size={12}>
          <Typography {...typographyProps}>AWS CLI</Typography>
          {!!cloudInformationLinkData && (
            <CopyRow
              text={buildCfnUpdatingCommand(cloudInformationLinkData, cfn)}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default UpgradeCloudConnectorModal
