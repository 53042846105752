import {
  useListEventKindsQuery,
  useListEventsQuery,
} from 'ui-v2/src/hooks/queries/alerts/list-notifications-rules'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import React, { useState } from 'react'
import { getLogsListColumns } from './column-config'
import { Box } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import DateRangePicker from '../../../../components/ui/inputs/date-range-picker'
import { SearchField, SearchIconButton, SearchWrapper } from '../styles'
import { Search as SearchIcon } from '@mui/icons-material'
import {
  NotificationEvent,
  Origin,
  Severity,
} from '../../../../lib/models/events'

const pageSize = 15

const Log = () => {
  const { data: eventKinds } = useListEventKindsQuery()
  const [token, setToken] = useState('')
  const [page, setPage] = useState(0)
  const [eventSearch, setEventSearch] = useState('')
  const [dateRange, setDateRange] = useState({
    start: dayjs().subtract(1, 'week').startOf('day').toDate(),
    end: dayjs().endOf('day').toDate(),
  })
  const { start: startDate, end: endDate } = dateRange
  const filteredEventKinds = eventKinds?.filter((event) =>
    event.toLowerCase().includes(eventSearch.toLowerCase())
  )

  const { data, isLoading } = useListEventsQuery({
    eventKinds: filteredEventKinds || [],
    token,
    start: startDate,
    end: endDate,
  })

  const columns = getLogsListColumns()

  const handleNextPage = () => {
    if (data?.nextPageToken) {
      setToken(data.nextPageToken)
    }
  }

  const handlePreviousPage = () => {
    if (data?.prevPageToken && data.prevPageToken.length > 0) {
      setToken(data.prevPageToken)
    }
  }

  const onPageChange = (newPage: number) => {
    if (newPage > page) {
      handleNextPage()
    } else {
      handlePreviousPage()
    }
    setPage(newPage)
  }

  const handleDateSearchChange = (sDate: Dayjs, eDate: Dayjs) => {
    if (!dayjs(sDate).isValid() || !dayjs(eDate).isValid()) {
      return
    }
    setDateRange({
      start: sDate.startOf('day').toDate(),
      end: eDate.endOf('day').toDate(),
    })
    setToken('')
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEventSearch(event.target.value)
    setToken('')
  }

  const tableData = data?.eventList
    ? [
        ...new Array(page * pageSize).fill(
          new NotificationEvent({
            origin: Origin.CLOUD_CONNECTOR,
            labelsMap: [],
            eventTime: new Date(),
            severity: Severity.INFO,
            trigger: {},
            kind: '',
            description: '',
          })
        ),
        ...data.eventList,
      ]
    : []

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <SearchWrapper>
          <SearchField
            placeholder="Search Events"
            variant="outlined"
            size="small"
            value={eventSearch}
            onChange={handleSearch}
          />
          <SearchIconButton>
            <SearchIcon
              sx={{
                fontSize: 18,
              }}
            />
          </SearchIconButton>
        </SearchWrapper>
        <Box
          sx={{
            maxWidth: '300px',
          }}
        >
          <DateRangePicker
            key={startDate.toString() + endDate.toString()}
            initialDateRange={{
              startDate: dayjs(startDate),
              endDate: dayjs(endDate),
            }}
            onChange={(range) => {
              handleDateSearchChange(range.startDate, range.endDate)
            }}
            sxTextField={{
              height: '32px',
              '& .MuiInputBase-root': {
                height: '32px',
                fontSize: '12px',
                fontStyle: 'italic',
                paddingLeft: 0,
              },
            }}
            maxDate={dayjs()}
          />
        </Box>
      </Box>
      <ClientTable
        data={tableData}
        columns={columns}
        isLoading={isLoading}
        loadingRowCount={3}
        styles={{
          cell: {
            height: '40px',
          },
          headerCell: {
            height: '40px',
          },
        }}
        rowsPerPageOptions={[pageSize]}
        slotProps={{
          actions: {
            nextButton: {
              disabled: !data?.nextPageToken,
            },
            previousButton: {
              disabled: data?.prevPageToken.length === 0,
            },
          },
        }}
        onPageChange={onPageChange}
      />
    </Box>
  )
}

export default Log
