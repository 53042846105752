/* eslint-disable import/no-extraneous-dependencies */
import {
  Backup,
  BackupPoliciesArray,
  Nullable,
  S3Bucket,
  Tag,
  Threat,
} from 'blues-corejs/dist'
import { LastScanState, PolicyCoverageState } from '../common'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { BUCKET_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { ListS3Request } from 'ui-v2/src/lib/grpc'
import { HealthState } from 'blue-stack-libs/api-grpc-libs/js/api/search/filters/common_filter_pb'

export interface ListS3Filters {
  assetIdList?: Array<string>
  bucketStatesList?: Array<ListS3Request.Initial.Filter.BucketState>
  tagsList?: Array<Tag>
  accountIdsList?: Array<string>
  regionsList?: Array<string>
  protectionPolicyCoverageStatesList?: Array<PolicyCoverageState>
  search?: string
  pageSize?: number
  pageToken?: string
  health?: Array<HealthState>
  lastScan?: Array<LastScanState>
}

export interface AssetsS3Attrs {
  s3Buckets: Array<S3Bucket>
  scans: Array<Scan>
  lastBackups: Map<string, Backup>
  threats: Array<Threat>
}

interface BaseS3Attrs {
  id: string
  name: string
  awsId: string
  awsAccountId: string
  awsRegion: string
  health: boolean
  state: (typeof BUCKET_STATE_MAP)[keyof typeof BUCKET_STATE_MAP]
}

export abstract class BaseS3 {
  readonly #id: string

  readonly #name: string

  readonly #awsId: string

  readonly #awsAccountId: string

  readonly #awsRegion: string

  readonly #health: boolean

  readonly #state: (typeof BUCKET_STATE_MAP)[keyof typeof BUCKET_STATE_MAP]

  constructor(parameters: BaseS3Attrs) {
    const { id, name, awsId, awsAccountId, awsRegion, health, state } =
      parameters

    this.#id = id
    this.#name = name
    this.#awsId = awsId
    this.#awsAccountId = awsAccountId
    this.#awsRegion = awsRegion
    this.#health = health
    this.#state = state
  }

  get id(): string {
    return this.#id
  }

  get name(): string {
    return this.#name
  }

  get awsId(): string {
    return this.#awsId
  }

  get awsAccountId(): string {
    return this.#awsAccountId
  }

  get awsRegion(): string {
    return this.#awsRegion
  }

  get health(): boolean {
    return this.#health
  }

  get state(): (typeof BUCKET_STATE_MAP)[keyof typeof BUCKET_STATE_MAP] {
    return this.#state
  }
}

export class S3TableRow extends BaseS3 {
  readonly #backupPolicies: BackupPoliciesArray

  readonly #lastBackupDate: Nullable<Date>

  readonly #lastScan: Nullable<Date>

  constructor(parameters: S3TableRowAttrs) {
    const { backupPolicies, lastBackupDate, lastScan, ...baseParams } =
      parameters
    super(baseParams)

    this.#backupPolicies = backupPolicies
    this.#lastBackupDate = lastBackupDate
    this.#lastScan = lastScan
  }

  get backupPolicies(): BackupPoliciesArray {
    return this.#backupPolicies
  }

  get lastBackupDate(): Nullable<Date> {
    return this.#lastBackupDate
  }

  get lastScan(): Nullable<Date> {
    return this.#lastScan
  }
}

export class S3Drawer extends BaseS3 {
  readonly #lastBackup: Nullable<Backup>

  readonly #lastScan: Nullable<Scan>

  readonly assetKind: string = 'S3 Bucket'

  constructor(parameters: S3DrawerAttrs) {
    const { lastBackup, lastScan, ...baseParams } = parameters
    super(baseParams)

    this.#lastBackup = lastBackup
    this.#lastScan = lastScan
  }

  get lastBackup(): Nullable<Backup> {
    return this.#lastBackup
  }

  get lastScan(): Nullable<Scan> {
    return this.#lastScan
  }
}

interface S3TableRowAttrs extends BaseS3Attrs {
  backupPolicies: BackupPoliciesArray
  lastBackupDate: Nullable<Date>
  lastScan: Nullable<Date>
}

interface S3DrawerAttrs extends BaseS3Attrs {
  lastBackup: Nullable<Backup>
  lastScan: Nullable<Scan>
}
