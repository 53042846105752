/* eslint-disable import/no-extraneous-dependencies */
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { IconButton, Stack } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { InstanceOutput } from 'blues-corejs/dist'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { getRiskStatus } from '../utils'
import { AssetDetailsLink } from 'ui-v2/src/components/ui/data-display/asset-details-link'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

export const getInstancesVulnerabilitiesColumns = (): Array<
  Column<InstanceOutput>
> => [
  {
    key: 'instanceId',
    header: 'Risk',
    render: (row) => {
      const status = getRiskStatus(row.riskVulnerabilities)
      return <StatusChip {...status} />
    },
    width: 80,
    align: 'center',
  },
  {
    key: 'instanceAwsId',
    header: 'Instance',
    render: (row) => (
      <AssetDetailsLink
        assetId={row.instanceId}
        assetKind={ASSETS_ROUTE_SEGMENTS.EC2}
      >
        {row.instanceAwsId}
      </AssetDetailsLink>
    ),
  },
  {
    key: 'volumeVulnerabilities',
    header: 'Misconfiguration',
    render: (row) =>
      row.volumeVulnerabilities
        .map((volumeVulnerabilities) => volumeVulnerabilities)
        .join(', '),
    wrap: true,
  },
  {
    key: 'riskVulnerabilities',
    header: 'Risk',
    render: (row) => {
      const risk =
        row.riskVulnerabilities === 0
          ? 'Low'
          : row.riskVulnerabilities === 1
            ? 'Medium'
            : 'High'
      return risk
    },
  },
  {
    key: 'accountId',
    header: 'Account ID',
    render: (row) => (
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {row.accountId}
        <IconButton
          onClick={() => copyToClipboard(row.accountId)}
          size="small"
          sx={{
            padding: '6px',
            '& svg': { fontSize: 16 },
          }}
        >
          <ContentCopy />
        </IconButton>
      </Stack>
    ),
  },
  {
    key: 'region',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.region)
      return region?.en?.single ?? ''
    },
  },
  {
    key: 'osKind',
    header: 'Attached Volumes',
    render: (row) => row.osKind,
  },
  {
    key: 'attachedVolumesCount',
    header: 'Attached Volumes',
    render: (row) => row.attachedVolumesCount,
  },
  {
    key: 'instanceState',
    header: 'Status',
    render: (row) => row.instanceState,
  },
  {
    key: 'lastBackup',
    header: 'Last Backup',
    render: (row) => formatDateToUtc(row.lastBackup?.backup.createdAt),
  },
]
