import React from 'react'
import { Skeleton, Stack } from '@mui/material'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'

const LoadingActionCard = () => {
  return (
    <GradientCard
      sx={{
        p: 1.5,
        px: 2,
        height: '64px',
      }}
      gradientVariant="blue"
    >
      <Stack flexDirection="row" alignItems="center" height="100%">
        <Skeleton variant="rectangular" width={300} height={20} />
      </Stack>
    </GradientCard>
  )
}

export default LoadingActionCard
