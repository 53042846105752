import React from 'react'
import { useNavigate } from 'react-router-dom'
import ActionMenu from 'ui-v2/src/components/ui/data-table/action-menu'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { AccountStatusConstant, ActiveDeploymentTableData } from '../types'
import { useDeploymentStore } from '../store/deployment-store'

const linkProtectedLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_PROTECTED_ACCOUNT}`
const unlinkProtectedLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.UNLINK_CLOUD_CONNECTOR}`
const scanClusterLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.SCAN_CLUSTER}`
const upgradeCloudConnectorLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.UPGRADE_CLOUD_CONNECTOR}`
const modifyCloudConnectorLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.MODIFY_CLOUD_CONNECTOR}`
const bindLinkedProtectedLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.BIND_LINKED_PROTECTED_ACCOUNT}`

const MenuItemsConnector = ({ row }: { row: ActiveDeploymentTableData }) => {
  const navigate = useNavigate()
  const { awsAccount } = row
  const { setRowData } = useDeploymentStore()

  const menuItems = [
    {
      name: 'Deploy Protected Account',
      action: () => {
        navigate(`${linkProtectedLink}/${awsAccount}`)
      },
    },
    {
      name: 'Link Protected Account',
      action: () => {
        navigate(`${bindLinkedProtectedLink}/${awsAccount}`)
      },
    },
    {
      name: 'Disconnect',
      action: () => {
        setRowData(row)
        navigate(`${unlinkProtectedLink}`)
      },
    },
    {
      name: 'Add Regions',
      action: () => {
        setRowData(row)
        navigate(`${modifyCloudConnectorLink}/${awsAccount}`)
      },
    },
    {
      name: 'Modify Network',
      action: () => {
        setRowData(row)
        navigate(`${scanClusterLink}/${awsAccount}`)
      },
    },
  ]

  if (row.status === AccountStatusConstant.UPGRADE_REQUIRED) {
    menuItems.push({
      name: 'Upgrade',
      action: () => {
        navigate(`${upgradeCloudConnectorLink}/${awsAccount}`)
      },
    })
  }

  return <ActionMenu menuItems={menuItems} />
}

export default MenuItemsConnector
