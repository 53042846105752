import { Box, useTheme } from '@mui/material'
import ShieldIcon from 'ui-v2/src/assets/icons/shield-icon'
import Typography from '@mui/material/Typography'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'

interface EmptyContentComponentProps {
  isHealthy: boolean
  atRisk: boolean
}

const EmptyContentComponent = ({
  isHealthy,
  atRisk,
}: EmptyContentComponentProps) => {
  const theme = useTheme()

  if (isHealthy) {
    return (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          padding: '8px 16px 0px 0px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
          flexShrink: 0,
          alignSelf: 'stretch',
        }}
      >
        <ShieldIcon isHealthy={true} />
        <Typography
          style={{
            color:
              theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#999CAA',
            marginTop: '16px',
          }}
        >
          All Clean
        </Typography>
      </Box>
    )
  }
  if (atRisk) {
    return (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          padding: '8px 16px 0px 0px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
          flexShrink: 0,
          alignSelf: 'stretch',
        }}
      >
        <ShieldIcon isHealthy={false} />
        <Typography
          style={{
            color:
              theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#999CAA',
            marginTop: '16px',
          }}
        >
          At Risk
        </Typography>
      </Box>
    )
  }
  return null
}

export default EmptyContentComponent
