import { forwardRef } from 'react'
import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',

  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1A1F28' : '#FFFFFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor:
      theme.palette.mode === 'dark' ? '#1A1F28' : 'rgba(228, 219, 233, 0.25',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
}))

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ value, onChange, size = 'small', ...props }, ref) => {
    return (
      <StyledTextField
        ref={ref}
        value={value}
        onChange={onChange}
        size={size}
        {...props}
      />
    )
  }
)

TextField.displayName = 'TextField'

export default TextField
