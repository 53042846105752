import { ASSET_DETAILS_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import ScanTypeFilter from './scan-type'
import { Divider } from '@mui/material'
import ScanTimeFilter from './scan-time'
import VolumeHealthFilter from './volume-health'
import VolumeIDFilter from './volume-id'
import ThreatsFilter from './threats'

export const filterOptions = {
  [ASSET_DETAILS_FILTERS_MAP.SCAN_TYPE]: <ScanTypeFilter />,
  [ASSET_DETAILS_FILTERS_MAP.SCAN_TIME]: <ScanTimeFilter />,
  [ASSET_DETAILS_FILTERS_MAP.DIVIDER]: (
    <Divider
      orientation="vertical"
      sx={{
        height: 28,
        alignSelf: 'center',
        margin: '0 24px',
      }}
    />
  ),
  [ASSET_DETAILS_FILTERS_MAP.VOLUME_HEALTH]: <VolumeHealthFilter />,
  [ASSET_DETAILS_FILTERS_MAP.VOLUME_ID]: <VolumeIDFilter />,
  [ASSET_DETAILS_FILTERS_MAP.THREATS]: <ThreatsFilter />,
}

export const defaultFilters = {
  [ASSETS_ROUTE_SEGMENTS.EC2]: {
    [ASSET_DETAILS_FILTERS_MAP.SCAN_TYPE]: [],
  },
  [ASSETS_ROUTE_SEGMENTS.EBS]: {
    [ASSET_DETAILS_FILTERS_MAP.SCAN_TIME]: [],
  },
  [ASSETS_ROUTE_SEGMENTS.S3]: {
    [ASSET_DETAILS_FILTERS_MAP.VOLUME_HEALTH]: [],
  },
  [ASSETS_ROUTE_SEGMENTS.EFS]: {
    [ASSET_DETAILS_FILTERS_MAP.VOLUME_ID]: [],
  },
  [ASSETS_ROUTE_SEGMENTS.SERVER]: {},
  [ASSETS_ROUTE_SEGMENTS.GENERIC_HOST]: {},
} as const
