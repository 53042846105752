/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import Threats from './threats'
import RecoveryPoints from './recovery-points'
import Scans from './scans'
import Jobs from './jobs'
import { Asset } from 'blues-corejs/dist'

import { BackupsForAsset } from 'ui-v2/src/lib/models/backups'

import { ScanRun, ScanRunList } from 'ui-v2/src/lib/models/scans'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const InfectedAssets = ({
  asset,
  backupData,
  scanData,
}: InfectedAssetsProps) => {
  const [value, setValue] = React.useState(0)

  const lastBackupData = useMemo(() => {
    if (!backupData) {
      return []
    }

    const backupsList = [
      ...backupData.awsbRpsList,
      ...backupData.elastioRpsList,
      ...backupData.ebsSnapshotsList,
      ...backupData.ovaBackupList,
    ]

    if (backupsList.length === 0) {
      return []
    }

    return backupsList.reduce((previousBackup, currentBackup) =>
      previousBackup.timestamp > currentBackup.timestamp
        ? previousBackup
        : currentBackup
    )
  }, [backupData])

  const lastScanData = useMemo(() => {
    if (!scanData) {
      return []
    }
    const lastScansRun = scanData.scansRunList[0]

    if (!lastScansRun) {
      return []
    }

    return [
      ...lastScansRun.filesystemChecksList,
      ...lastScansRun.malwareScansList,
      ...lastScansRun.ransomwareScansList,
    ]
  }, [scanData])

  const handleChange = (_: any, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '0 !important',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={'Threats'} {...a11yProps(0)} />
          <Tab label="Recovery Points" {...a11yProps(1)} />
          <Tab label="Scans" {...a11yProps(2)} />
          <Tab label="Jobs" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Threats
          asset={asset}
          lastBackupData={lastBackupData}
          lastScanData={lastScanData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <RecoveryPoints asset={asset} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Scans asset={asset} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Jobs asset={asset} />
      </CustomTabPanel>
    </Box>
  )
}

interface ScanData {
  scansRunList: Array<ScanRun>
  runsList: Array<ScanRunList>
}

interface InfectedAssetsProps {
  asset: Asset
  backupData: BackupsForAsset
  scanData: ScanData
}

export default InfectedAssets
