/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

const NoCleanBackupDetails = () => {
  return (
    <>
      <Stack spacing={3}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography variant="body2" fontWeight={600}>
              No Last Known Clean
            </Typography>
          </Box>
        </Box>
      </Stack>
    </>
  )
}

export default NoCleanBackupDetails
