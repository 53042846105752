import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import Typography from '@mui/material/Typography'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'
import ThreatSuppress from './threat-suppress'
import { AssetThreat } from 'ui-v2/src/lib/models/backups'
import { copyToClipboard } from '../../../../lib/helpers/system.helper'
import { FileCopy } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'
import { ToastUtils } from '../../../../hooks/toast'

export const getRecoveryPointFilesTableColumns = (
  toast: ToastUtils
): Array<Column<AssetThreat>> => {
  return [
    {
      key: 'path',
      header: 'Files',
      render: (row) => {
        const copyIconButton = (
          <IconButton
            onClick={() => {
              copyToClipboard(row.path).then(() => {
                toast.success('Copied to clipboard')
              })
            }}
            size="small"
            sx={{ padding: '3px' }}
          >
            <FileCopy
              sx={{
                fontSize: '16px',
                color: '#B2B5C1',
              }}
            />
          </IconButton>
        )

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <LongTextTooltip
              text={row.path}
              maxLength={30}
              fontSize="14px"
              color={'link.main'}
              leaveDelay={50}
              tooltipText={
                <Box display={'flex'} gap={1} alignItems={'center'}>
                  <Typography fontSize={'14px'} fontWeight={500}>
                    Path: {row.path}
                  </Typography>
                  {copyIconButton}
                </Box>
              }
              maxWidth={'600px'}
              placement="bottom-start"
            >
              <Typography color={'link.main'} fontSize={'14px'}>
                {row.path}
              </Typography>
            </LongTextTooltip>
            {copyIconButton}
          </Box>
        )
      },
      sortable: true,
    },
    {
      key: 'kind',
      header: 'Threats Type',
      render: (row) => (
        <Typography color={'text.secondary'} fontSize={'14px'}>
          {row.kind}
        </Typography>
      ),
      sortable: true,
    },
    {
      key: 'name',
      header: 'Variants',
      render: (row) => (
        <Typography color={'text.secondary'} fontSize={'14px'}>
          {row.name}
        </Typography>
      ),
      sortable: true,
    },
    {
      key: 'detected_at',
      header: 'First Detected',
      render: (row) => (
        <Typography color={'text.secondary'} fontSize={'14px'}>
          {row.detected_at}
        </Typography>
      ),
      sortable: true,
    },
    {
      key: 'id',
      header: 'Suppress',
      render: (row) => <ThreatSuppress row={row} />,
    },
  ]
}
