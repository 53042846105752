import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import {
  ASSET_FILTERS_MAP,
  EC2_STATE_MAP,
} from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const InstanceStateFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const status =
    searchParams.get(ASSET_FILTERS_MAP.INSTANCE_STATE)?.split(',') || []

  const statusOptions = useMemo(() => {
    return Object.entries(EC2_STATE_MAP).map(([key, value]) => ({
      label: value,
      value: key.toString(),
    }))
  }, [])

  const handleStatusChange = (
    newStatusList: Array<MultiSelectOption['value']>
  ) => {
    if (newStatusList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.INSTANCE_STATE)
    } else {
      searchParams.set(
        ASSET_FILTERS_MAP.INSTANCE_STATE,
        newStatusList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={statusOptions}
      value={status}
      onChange={handleStatusChange}
      label={
        <SelectLabelText
          selectedValueLength={status?.length}
          optionsLength={statusOptions?.length}
          text="State"
        />
      }
      width={200}
    />
  )
}

export default InstanceStateFilter
