import { useMemo, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_THREATS_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

export interface FilterComponentProps {
  options: Array<string>
}

const RansomwareFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const ransomwareParam = searchParams.get(
    ASSETS_THREATS_FILTERS_MAP.RANSOMWARE
  )

  useEffect(() => {
    if (!options.length) {
      return
    }
    if (ransomwareParam?.toLowerCase() === 'all') {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set(
        ASSETS_THREATS_FILTERS_MAP.RANSOMWARE,
        options.join(',')
      )
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [ransomwareParam, options, setSearchParams])

  const ransomwareList = useMemo(() => {
    if (!ransomwareParam) {
      return []
    }
    if (ransomwareParam.toLowerCase() === 'all') {
      return options
    }
    return ransomwareParam.split(',').filter(Boolean)
  }, [ransomwareParam, options])

  const ransomwareOptions = useMemo(() => {
    return options.map((ransomware) => ({
      value: ransomware,
      label: ransomware,
    }))
  }, [options])

  const handleRansomwareChange = (
    newRansomwareList: Array<MultiSelectOption['value']>
  ) => {
    const newSearchParams = new URLSearchParams(searchParams)
    if (newRansomwareList.length === 0) {
      newSearchParams.delete(ASSETS_THREATS_FILTERS_MAP.RANSOMWARE)
    } else {
      newSearchParams.set(
        ASSETS_THREATS_FILTERS_MAP.RANSOMWARE,
        newRansomwareList.join(',')
      )
    }
    setSearchParams(newSearchParams)
  }

  return (
    <FilterMultiSelect
      options={ransomwareOptions}
      value={ransomwareList}
      onChange={handleRansomwareChange}
      label={
        <SelectLabelText
          selectedValueLength={ransomwareList?.length}
          optionsLength={ransomwareOptions?.length}
          text="Ransomware"
        />
      }
      width={200}
    />
  )
}

export default RansomwareFilter
