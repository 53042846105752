import { BackupsClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useQueries } from '@tanstack/react-query'

export function useLastCleanBackupsQuery(assetIds: Array<string>) {
  const backupsClient = useGrpcClient(BackupsClient)

  return useQueries({
    queries: assetIds.map((assetId) => ({
      queryKey: ['backups', 'last-clean', assetId],
      queryFn: async () => {
        if (!backupsClient) {
          throw new Error('Assets client not initialized')
        }
        return backupsClient.lastCleanBackupForAsset(assetId)
      },
      enabled: !!backupsClient,
    })),
  })
}
