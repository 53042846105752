/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { NotificationRule } from 'blues-corejs/dist/models/notifications/rule'

interface AlertsActiveRuleStore {
  activeRule: NotificationRule | null
  setActiveRule: (v: NotificationRule | null) => void
}

export const useAlertsActiveRuleStore = create<AlertsActiveRuleStore>(
  (set) => ({
    activeRule: null,
    setActiveRule: (data: NotificationRule | null) =>
      set((state) => ({
        ...state,
        activeRule: data,
      })),
  })
)
