import { DEFAULT_PAGE_SIZE } from '../constants/api.constant'
import {
  EBS_Filter,
  EBS_Search,
  EBS_SortBy,
  EC2_Filter,
  EC2_Search,
  EC2_SortBy,
  EFS_Filter,
  EFS_Search,
  EFS_SortBy,
  Host_Filter,
  Host_Search,
  Host_SortBy,
  S3_Filter,
  S3_Search,
  S3_SortBy,
  SearchPaginationRequest,
  SearchPromiseClient,
  SearchRequest,
  Server_Filter,
  Server_Search,
  Server_SortBy,
} from '../grpc'
import {
  AssetsSearchFilters,
  SearchEbsFilters,
  SearchEc2Filters,
  SearchEfsFilters,
  SearchHostFilters,
  SearchS3Filters,
  SearchServerFilters,
} from '../models/assets'
import { ClientConfig } from '../models/client'
import {
  EBSTransformer,
  EC2Transformer,
  EFSTransformer,
  GenericHostTransformer,
  OvaServerTransformer,
  S3BucketTransformer,
} from '../transformers/assets'
import { GrpcClient } from './grpc'

export class SearchClient extends GrpcClient<SearchPromiseClient> {
  #searchClient: SearchPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#searchClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'SearchClient'
  }

  protected initClient(hostName = ''): SearchPromiseClient {
    return new SearchPromiseClient(hostName, null, null)
  }

  async search(filters: AssetsSearchFilters) {
    const request = new SearchRequest()
    const initial = new SearchRequest.Initial()

    if (filters.ec2) {
      const ec2Search = this.#buildEc2Filters(filters.ec2)
      initial.setEc2(ec2Search)
    }

    if (filters.ebs) {
      const ebsSearch = this.#buildEbsFilters(filters.ebs)
      initial.setEbs(ebsSearch)
    }

    if (filters.s3) {
      const s3Search = this.#buildS3Filters(filters.s3)
      initial.setS3(s3Search)
    }

    if (filters.efs) {
      const efsSearch = this.#buildEfsFilters(filters.efs)
      initial.setEfs(efsSearch)
    }

    if (filters.servers) {
      const serverSearch = this.#buildServerFilters(filters.servers)
      initial.setServer(serverSearch)
    }

    if (filters.host) {
      const hostSearch = this.#buildHostFilters(filters.host)
      initial.setHost(hostSearch)
    }

    initial.setPageSize(filters.pageSize ?? DEFAULT_PAGE_SIZE)
    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(
        new SearchPaginationRequest().setPageToken(filters.pageToken)
      )
    }

    const response = (
      await this.callGrpcService(
        () => this.#searchClient.search(request, this.metadata(this.#token)),
        {
          requestName: 'SearchPromiseClient/search',
        }
      )
    ).toObject()

    return {
      instancesList: response.assetsList
        .filter(
          (asset): asset is { ec2: NonNullable<typeof asset.ec2> } =>
            !!asset.ec2
        )
        .map((asset) => new EC2Transformer(asset.ec2).transform()),
      volumesList: response.assetsList
        .filter(
          (asset): asset is { ebs: NonNullable<typeof asset.ebs> } =>
            !!asset.ebs
        )
        .map((asset) => new EBSTransformer(asset.ebs).transform()),
      bucketsList: response.assetsList
        .filter(
          (asset): asset is { s3: NonNullable<typeof asset.s3> } => !!asset.s3
        )
        .map((asset) => new S3BucketTransformer(asset.s3).transform()),
      filesystemsList: response.assetsList
        .filter(
          (asset): asset is { efs: NonNullable<typeof asset.efs> } =>
            !!asset.efs
        )
        .map((asset) => new EFSTransformer(asset.efs).transform()),
      servers: response.assetsList
        .filter(
          (asset): asset is { server: NonNullable<typeof asset.server> } =>
            !!asset.server
        )
        .map((asset) => new OvaServerTransformer(asset.server).transform()),
      hostsList: response.assetsList
        .filter(
          (asset): asset is { host: NonNullable<typeof asset.host> } =>
            !!asset.host
        )
        .map((asset) => new GenericHostTransformer(asset.host).transform()),
      pagination: response.pagination,
    }
  }

  async searchNewAssets(filters: AssetsSearchFilters) {
    const request = new SearchRequest()
    const initial = new SearchRequest.Initial()

    if (filters.ec2) {
      const ec2Search = this.#buildEc2Filters(filters.ec2)
      initial.setEc2(ec2Search)
    }

    if (filters.ebs) {
      const ebsSearch = this.#buildEbsFilters(filters.ebs)
      initial.setEbs(ebsSearch)
    }

    if (filters.s3) {
      const s3Search = this.#buildS3Filters(filters.s3)
      initial.setS3(s3Search)
    }

    if (filters.efs) {
      const efsSearch = this.#buildEfsFilters(filters.efs)
      initial.setEfs(efsSearch)
    }

    if (filters.servers) {
      const serverSearch = this.#buildServerFilters(filters.servers)
      initial.setServer(serverSearch)
    }

    if (filters.host) {
      const hostSearch = this.#buildHostFilters(filters.host)
      initial.setHost(hostSearch)
    }

    initial.setPageSize(filters.pageSize ?? DEFAULT_PAGE_SIZE)
    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(
        new SearchPaginationRequest().setPageToken(filters.pageToken)
      )
    }

    const response = (
      await this.callGrpcService(
        () => this.#searchClient.search(request, this.metadata(this.#token)),
        {
          requestName: 'SearchPromiseClient/search',
        }
      )
    ).toObject()

    return {
      instancesList: response.assetsList
        .filter(
          (asset): asset is { ec2: NonNullable<typeof asset.ec2> } =>
            !!asset.ec2
        )
        .map((asset) => asset.ec2),
      volumesList: response.assetsList
        .filter(
          (asset): asset is { ebs: NonNullable<typeof asset.ebs> } =>
            !!asset.ebs
        )
        .map((asset) => asset.ebs),
      bucketsList: response.assetsList
        .filter(
          (asset): asset is { s3: NonNullable<typeof asset.s3> } => !!asset.s3
        )
        .map((asset) => asset.s3),
      filesystemsList: response.assetsList
        .filter(
          (asset): asset is { efs: NonNullable<typeof asset.efs> } =>
            !!asset.efs
        )
        .map((asset) => asset.efs),
      servers: response.assetsList
        .filter(
          (asset): asset is { server: NonNullable<typeof asset.server> } =>
            !!asset.server
        )
        .map((asset) => asset.server),
      hostsList: response.assetsList
        .filter(
          (asset): asset is { host: NonNullable<typeof asset.host> } =>
            !!asset.host
        )
        .map((asset) => asset.host),
      pagination: response.pagination,
    }
  }

  #buildEc2Filters(filters: SearchEc2Filters) {
    const ec2Filter = new EC2_Filter()
    const ec2SortBy = new EC2_SortBy()

    if (filters.sortBy && filters.sortBy.length) {
      const sortFieldsList = filters.sortBy.map((sort) => {
        const ec2SortField = new EC2_SortBy.SortField()
        ec2SortField.setField(sort.field)
        ec2SortField.setAscending(sort.ascending)
        return ec2SortField
      })
      ec2SortBy.setSortFieldsList(sortFieldsList)
    }

    if (filters.search) {
      ec2Filter.setSearch(filters.search)
    }

    if (filters.accountIdsList && filters.accountIdsList.length) {
      ec2Filter.setAccountIdsList(filters.accountIdsList)
    }

    if (filters.assetIdList && filters.assetIdList.length) {
      ec2Filter.setAssetIdList(filters.assetIdList)
    }

    if (filters.regionsList && filters.regionsList.length) {
      ec2Filter.setRegionsList(filters.regionsList)
    }

    if (filters.instanceStatesList && filters.instanceStatesList.length) {
      ec2Filter.setInstanceStatesList(filters.instanceStatesList)
    }

    if (
      filters.protectionPolicyCoverageStatesList &&
      filters.protectionPolicyCoverageStatesList.length
    ) {
      ec2Filter.setProtectionPolicyCoverageStatesList(
        filters.protectionPolicyCoverageStatesList
      )
    }

    if (filters.tagsList && filters.tagsList.length) {
      const tagsList = filters.tagsList.map((tag) => {
        const grpcTag = new EC2_Filter.Tag()
        grpcTag.setKey(tag.key)
        grpcTag.setValue(tag.value)
        return grpcTag
      })
      ec2Filter.setTagsList(tagsList)
    }

    if (filters.health && filters.health.length) {
      ec2Filter.setHealthList(filters.health)
    }

    if (filters.lastScan?.length) {
      ec2Filter.setLastScan(Math.max(...filters.lastScan))
    }

    const ec2Search = new EC2_Search()
    ec2Search.setFilter(ec2Filter)
    ec2Search.setSortBy(ec2SortBy)

    return ec2Search
  }

  #buildEbsFilters(filters: SearchEbsFilters) {
    const ebsFilter = new EBS_Filter()
    const ebsSortBy = new EBS_SortBy()

    if (filters.sortBy && filters.sortBy.length) {
      const sortFieldsList = filters.sortBy.map((sort) => {
        const ebsSortField = new EBS_SortBy.SortField()
        ebsSortField.setField(sort.field)
        ebsSortField.setAscending(sort.ascending)
        return ebsSortField
      })
      ebsSortBy.setSortFieldsList(sortFieldsList)
    }

    if (filters.search) {
      ebsFilter.setSearch(filters.search)
    }

    if (filters.accountIdsList && filters.accountIdsList.length) {
      ebsFilter.setAccountIdsList(filters.accountIdsList)
    }

    if (filters.assetIdList && filters.assetIdList.length) {
      ebsFilter.setAssetIdList(filters.assetIdList)
    }

    if (filters.regionsList && filters.regionsList.length) {
      ebsFilter.setRegionsList(filters.regionsList)
    }

    if (filters.volumeStatesList && filters.volumeStatesList.length) {
      ebsFilter.setVolumeStatesList(filters.volumeStatesList)
    }

    if (
      filters.protectionPolicyCoverageStatesList &&
      filters.protectionPolicyCoverageStatesList.length
    ) {
      ebsFilter.setProtectionPolicyCoverageStatesList(
        filters.protectionPolicyCoverageStatesList
      )
    }

    if (filters.tagsList && filters.tagsList.length) {
      const tagsList = filters.tagsList.map((tag) => {
        const grpcTag = new EBS_Filter.Tag()
        grpcTag.setKey(tag.key)
        grpcTag.setValue(tag.value)
        return grpcTag
      })
      ebsFilter.setTagsList(tagsList)
    }

    if (filters.health && filters.health.length) {
      ebsFilter.setHealthList(filters.health)
    }

    if (filters.lastScan?.length) {
      ebsFilter.setLastScan(Math.max(...filters.lastScan))
    }

    const ebsSearch = new EBS_Search()
    ebsSearch.setFilter(ebsFilter)
    ebsSearch.setSortBy(ebsSortBy)

    return ebsSearch
  }

  #buildS3Filters(filters: SearchS3Filters) {
    const s3Filter = new S3_Filter()
    const s3SortBy = new S3_SortBy()

    if (filters.sortBy && filters.sortBy.length) {
      const sortFieldsList = filters.sortBy.map((sort) => {
        const s3SortField = new S3_SortBy.SortField()
        s3SortField.setField(sort.field)
        s3SortField.setAscending(sort.ascending)
        return s3SortField
      })
      s3SortBy.setSortFieldsList(sortFieldsList)
    }

    if (filters.search) {
      s3Filter.setSearch(filters.search)
    }

    if (filters.accountIdsList && filters.accountIdsList.length) {
      s3Filter.setAccountIdsList(filters.accountIdsList)
    }

    if (filters.bucketStatesList && filters.bucketStatesList.length) {
      s3Filter.setBucketStatesList(filters.bucketStatesList)
    }

    if (filters.regionsList && filters.regionsList.length) {
      s3Filter.setRegionsList(filters.regionsList)
    }

    if (filters.assetIdList && filters.assetIdList.length) {
      s3Filter.setAssetIdList(filters.assetIdList)
    }

    if (
      filters.protectionPolicyCoverageStatesList &&
      filters.protectionPolicyCoverageStatesList.length
    ) {
      s3Filter.setProtectionPolicyCoverageStatesList(
        filters.protectionPolicyCoverageStatesList
      )
    }

    if (filters.tagsList && filters.tagsList.length) {
      const tagsList = filters.tagsList.map((tag) => {
        const grpcTag = new S3_Filter.Tag()
        grpcTag.setKey(tag.key)
        grpcTag.setValue(tag.value)
        return grpcTag
      })
      s3Filter.setTagsList(tagsList)
    }

    if (filters.health && filters.health.length) {
      s3Filter.setHealthList(filters.health)
    }

    if (filters.lastScan?.length) {
      s3Filter.setLastScan(Math.max(...filters.lastScan))
    }

    const s3Search = new S3_Search()
    s3Search.setFilter(s3Filter)
    s3Search.setSortBy(s3SortBy)

    return s3Search
  }

  #buildEfsFilters(filters: SearchEfsFilters) {
    const efsFilter = new EFS_Filter()
    const efsSortBy = new EFS_SortBy()

    if (filters.sortBy && filters.sortBy.length) {
      const sortFieldsList = filters.sortBy.map((sort) => {
        const efsSortField = new EFS_SortBy.SortField()
        efsSortField.setField(sort.field)
        efsSortField.setAscending(sort.ascending)
        return efsSortField
      })
      efsSortBy.setSortFieldsList(sortFieldsList)
    }

    if (filters.search) {
      efsFilter.setSearch(filters.search)
    }

    if (filters.accountIdsList && filters.accountIdsList.length) {
      efsFilter.setAccountIdsList(filters.accountIdsList)
    }

    if (filters.regionsList && filters.regionsList.length) {
      efsFilter.setRegionsList(filters.regionsList)
    }

    if (filters.assetIdList && filters.assetIdList.length) {
      efsFilter.setAssetIdList(filters.assetIdList)
    }

    if (filters.filesystemStatesList && filters.filesystemStatesList.length) {
      efsFilter.setFilesystemStatesList(filters.filesystemStatesList)
    }

    if (
      filters.protectionPolicyCoverageStatesList &&
      filters.protectionPolicyCoverageStatesList.length
    ) {
      efsFilter.setProtectionPolicyCoverageStatesList(
        filters.protectionPolicyCoverageStatesList
      )
    }

    if (filters.tagsList && filters.tagsList.length) {
      const tagsList = filters.tagsList.map((tag) => {
        const grpcTag = new EFS_Filter.Tag()
        grpcTag.setKey(tag.key)
        grpcTag.setValue(tag.value)
        return grpcTag
      })
      efsFilter.setTagsList(tagsList)
    }

    if (filters.health && filters.health.length) {
      efsFilter.setHealthList(filters.health)
    }

    if (filters.lastScan?.length) {
      efsFilter.setLastScan(Math.max(...filters.lastScan))
    }

    const efsSearch = new EFS_Search()
    efsSearch.setFilter(efsFilter)
    efsSearch.setSortBy(efsSortBy)

    return efsSearch
  }

  #buildServerFilters(filters: SearchServerFilters) {
    const serverFilter = new Server_Filter()
    const serverSortBy = new Server_SortBy()

    if (filters.sortBy && filters.sortBy.length) {
      const sortFieldsList = filters.sortBy.map((sort) => {
        const serverSortField = new Server_SortBy.SortField()
        serverSortField.setField(sort.field)
        serverSortField.setAscending(sort.ascending)
        return serverSortField
      })
      serverSortBy.setSortFieldsList(sortFieldsList)
    }

    if (filters.search) {
      serverFilter.setTextSearch(filters.search)
    }

    if (filters.accountIdsList && filters.accountIdsList.length) {
      serverFilter.setAccountIdsList(filters.accountIdsList)
    }

    if (filters.backupProvidersList && filters.backupProvidersList.length) {
      serverFilter.setBackupProvidersList(filters.backupProvidersList)
    }

    if (filters.health && filters.health.length) {
      serverFilter.setHealthList(filters.health)
    }

    if (filters.lastScan?.length) {
      serverFilter.setLastScan(Math.max(...filters.lastScan))
    }

    const serverSearch = new Server_Search()
    serverSearch.setFilter(serverFilter)
    serverSearch.setSortBy(serverSortBy)

    return serverSearch
  }

  #buildHostFilters(filters: SearchHostFilters) {
    const hostFilter = new Host_Filter()
    const hostSortBy = new Host_SortBy()

    if (filters.sortBy && filters.sortBy.length) {
      const sortFieldsList = filters.sortBy.map((sort) => {
        const hostSortField = new Host_SortBy.SortField()
        hostSortField.setField(sort.field)
        hostSortField.setAscending(sort.ascending)
        return hostSortField
      })
      hostSortBy.setSortFieldsList(sortFieldsList)
    }

    if (filters.hostnameList && filters.hostnameList.length) {
      hostFilter.setHostNameList(filters.hostnameList)
    }

    const hostSearch = new Host_Search()
    hostSearch.setFilter(hostFilter)
    hostSearch.setSortBy(hostSortBy)

    return hostSearch
  }
}
