import React, { useMemo } from 'react'
import ThreatsLineChart from './threats-line-chart'
import { Stack } from '@mui/material'
import AssetKindChip from '../common/asset-kind-chip'
import AssetIdentifier from './asset-identifier'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { getDateRange } from 'ui-v2/src/lib/helpers/time.helper'
import ThreatWidgetLoading from './loading'
import { useAssetDetails } from '../common/use-asset-details'
import WidgetCard from '../common/widget-card'
import { getAssetIdsToScan, getStatus } from '../../utils'
import { ThreatStatus } from '../../data-panel/common/types'
import { useAssetsListScansQuery } from 'ui-v2/src/hooks/queries/scans'
import { extractScanResults } from 'ui-v2/src/lib/helpers/scans.helper'
import { useGetThreatsTrendHistoryQuery } from 'ui-v2/src/hooks/queries/threats-trend-history'

const ThreatsGraphWidget = () => {
  const {
    asset,
    assetKind,
    parentAsset,
    isAssetsDataLoading,
    isAssetsDataError,
  } = useAssetDetails()

  const assetIdList = getAssetIdsToScan(parentAsset)

  const { data: threatsTrendHistoryData, status: threndsHistoryQueryStatus } =
    useGetThreatsTrendHistoryQuery({
      ...getDateRange(),
      timeGranularity: 1,
      assetIdsList: assetIdList,
    })

  const {
    data: assetsListScans,
    isLoading: isLoadingAssetsListScans,
    isError: isAssetScanError,
  } = useAssetsListScansQuery(
    {
      assetIdList,
      pageSize: 1,
    },
    {
      enabled: assetIdList.length > 0,
    }
  )

  const scans = useMemo(() => {
    if (!assetsListScans) {
      return []
    }
    return extractScanResults(assetsListScans.scansRunList)
  }, [assetsListScans])

  if (
    isAssetsDataLoading ||
    threndsHistoryQueryStatus === 'pending' ||
    isLoadingAssetsListScans
  ) {
    return (
      <WidgetCard>
        <ThreatWidgetLoading />
      </WidgetCard>
    )
  }

  if (isAssetsDataError) {
    return (
      <WidgetCard>
        <ErrorDisplay message="Failed to load widget, please try again later." />
      </WidgetCard>
    )
  }

  const assetHealthStatus =
    scans.length === 0
      ? ThreatStatus.NOT_COVERED
      : scans.some((scanlist) => scanlist.isInfected)
        ? ThreatStatus.DETECTED
        : ThreatStatus.CLEAN

  return (
    <WidgetCard>
      <Stack height={'100%'} spacing={1}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          spacing={1}
        >
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <AssetKindChip assetKind={assetKind} />
            <AssetIdentifier asset={asset} />
          </Stack>
          {!isAssetScanError ? (
            <StatusChip type={getStatus(assetHealthStatus)} />
          ) : null}
        </Stack>
        {threndsHistoryQueryStatus === 'error' ? (
          <ErrorDisplay message="Could not load threats trend history." />
        ) : (
          <ThreatsLineChart
            assetKind={assetKind}
            timeSeriesData={threatsTrendHistoryData}
          />
        )}
      </Stack>
    </WidgetCard>
  )
}

export default ThreatsGraphWidget
