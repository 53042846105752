import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useMutation } from '@tanstack/react-query'
import { RexClient } from 'ui-v2/src/lib/clients'

export function useUpdateConnectorAccountInstallFlow() {
  const rexClient = useGrpcClient(RexClient)

  return useMutation({
    mutationFn: async ({
      awsAccountId,
      isVpcAutoCreate,
      vpcRegions,
    }: {
      awsAccountId: string
      isVpcAutoCreate: boolean
      vpcRegions: Array<string>
    }) => {
      if (!rexClient) {
        throw new Error('Pechkin client not initialized')
      }
      return rexClient.updateConnectorAccountInstallFlow({
        awsAccountId,
        isVpcAutoCreate,
        vpcRegions,
      })
    },
  })
}
