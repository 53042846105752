import { useMemo, useState } from 'react'
import Label from 'ui-v2/src/components/ui/data-display/label'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { useTokenListQuery } from 'ui-v2/src/hooks/queries/settings'
import { ActiveTokenTableData } from 'ui-v2/src/lib/use-cases/settings/general/api-access'
import { Grid2 } from '@mui/material'
import { getTokenListColumns } from './column-config'
import { TokenListTableRow } from 'ui-v2/src/lib/models/settings/api-access/api-access'
import Button from 'ui-v2/src/components/ui/inputs/button'
import ConfirmationDialog from 'ui-v2/src/components/dialogs/confirmation'
import { useRevokeMutation } from 'ui-v2/src/hooks/queries/settings/personal-access-token/revoke'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'ui-v2/src/hooks/toast'

const ActiveTokens = () => {
  const { data: tokenList } = useTokenListQuery()
  const [selectedTokenRows, setSelectedTokenRows] = useState<
    Array<TokenListTableRow>
  >([])
  const [isRevokeTokenModal, setIsRevokeTokenModal] = useState<boolean>(false)
  const { mutate, isPending } = useRevokeMutation()
  const queryClient = useQueryClient()
  const toast = useToast()
  const data = useMemo(() => {
    if (!tokenList) {
      return undefined
    }

    return new ActiveTokenTableData({
      tokenList: tokenList,
    }).getTokenList()
  }, [tokenList])
  const columns = useMemo(getTokenListColumns, [])

  const handleSelectedRevokeToken = () => {
    setIsRevokeTokenModal(false)

    const tokens = selectedTokenRows?.map((token) => token.id)

    mutate(
      { tokens },
      {
        onSuccess: () => {
          setSelectedTokenRows([])

          toast.success('The access api token has been revoked')
          queryClient.invalidateQueries({
            queryKey: ['settings', 'tokenList'],
          })
          queryClient.invalidateQueries({
            queryKey: ['settings', 'revokedTokenList'],
          })
        },
        onError: (error) => {
          toast.error(error?.message ?? 'Cannot perform the tokens revoking')
        },
      }
    )
  }
  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Label>Active Tokens</Label>
          <Label variant="body2" fontWeight={600}>
            For those that you don't recognize or that are out-of-date, click,
            then click Revoke. To revoke all tokens, click Revoke all.
          </Label>
          <ClientTable
            checkable
            data={data ?? []}
            columns={columns}
            selectedRows={selectedTokenRows}
            onSelectRows={setSelectedTokenRows}
          />
          <Button
            variant="contained"
            color="error"
            sx={{
              textTransform: 'capitalize',
              mt: 2,
            }}
            disabled={!selectedTokenRows?.length || isPending}
            onClick={() => setIsRevokeTokenModal(true)}
            isLoading={isPending}
          >
            Revoke Selected Tokens
          </Button>
        </Grid2>
      </Grid2>
      <ConfirmationDialog
        open={isRevokeTokenModal}
        title="Confirmation"
        onClose={() => setIsRevokeTokenModal(false)}
        onConfirm={handleSelectedRevokeToken}
        confirmText="Yes, Proceed"
        cancelText="No, Cancel"
      >
        <Label>
          You are going to remove selected API access tokens, please confirm the
          action in order to proceed
        </Label>
      </ConfirmationDialog>
    </>
  )
}

export default ActiveTokens
