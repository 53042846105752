import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import { RexPromiseClient } from 'blue-stack-libs/rex-grpc-libs/js/rex/rex_grpc_web_pb'
import DeploymentFilesInterface from '@lib/interfaces/deployment-files.interface'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import {
  ActivateRequest,
  DeactivateRequest,
  DeleteRequest,
  DiscoverInstalledCfnRequest,
  GetAwsAccountInfoRequest,
  InstallRedstackRequest,
  ListAvailabilityZonesRequest,
  ListAwsAccountsRequest,
  ListAwsSecurityGroupsRequest,
  ListAwsVpcsRequest,
  RedStackInstallParameters,
  RepairCloudConnectorRequest,
  UpdateAwsAccountRequest,
  GetCloudFormationLinkRequest,
} from 'blue-stack-libs/rex-grpc-libs/js/rex/rex_pb'
import { ListRequest } from 'blue-stack-libs/users-grpc-libs/js/users/messages/pat_pb'
import RedStackModel from '@lib/models/red-stack.model'
import RedStackFactory from '@lib/factories/red-stack.factory'
import { VIRow } from '@lib/engine-types'
import GeneralFactory from '@lib/factories/general.factory'
import VpcModel from '@lib/models/vpc.model'
import VpcFactory from '@lib/factories/vpc.factory'
import ValueInterface from '@lib/interfaces/value.interface'
import ArrHelper from '@lib/helpers/arr.helper'
import AwsAccountInfoModel from '@lib/models/aws-account-info.model'
import AwsAccountInfoFactory from '@lib/factories/aws-account-info.factory'
import CfnModel from '@lib/models/cfn.model'
import CfnFactory from '@lib/factories/cfn.factory'
import AvailabilityZoneModel from '@lib/models/availability-zone.model'
import AvailabilityZoneFactory from '@lib/factories/availability-zone.factory'
import AwsSecurityGroupsModel from '@lib/models/aws-security-groups.model'
import AwsSecurityGroupsFactory from '@lib/factories/aws-security-groups.factory'

class GrpcRexService extends GrpcBaseService {
  protected static client = new RexPromiseClient('', null, null)
  protected static clientName = 'Rex'

  public static async getDeploymentFiles(): Promise<DeploymentFilesInterface> {
    const request = new Empty()
    const result = await this.handleQueryRetry(
      this.client.getDeploymentFiles,
      request
    )
    const objResult = result.toObject()
    return {
      cfTemplate: objResult.cfTemplateJson,
      tfArchiveUrl: objResult.tfArchiveUrl,
    }
  }

  public static async activate(id: string): Promise<string> {
    const request = new ActivateRequest()
    request.setRedStackId(id)
    const result = await this.handleQuery(this.client.activate, request)
    const resultObject = result.toObject()
    return resultObject.jobId
  }

  public static async deactivate(id: string): Promise<any> {
    const request = new DeactivateRequest()
    request.setRedStackId(id)
    const result = await this.handleQuery(this.client.deactivate, request)
    return result.toObject()
  }

  public static async delete(id: string): Promise<any> {
    const request = new DeleteRequest()
    request.setRedStackId(id)
    const result = await this.handleQuery(this.client.delete, request)
    return result.toObject()
  }

  public static async updateAwsAccount(
    accountId: string,
    accountAliasName: string,
    accountDescription: string
  ): Promise<any> {
    const request = new UpdateAwsAccountRequest()
    request.setAwsAccountId(accountId)
    request.setAccountAlias(accountAliasName)
    request.setDescription(accountDescription)
    const result = await this.handleQueryRetry(
      this.client.updateAwsAccount,
      request
    )
    return result.toObject()
  }

  public static async getAllRedstacks(): Promise<Array<RedStackModel>> {
    const request = new ListRequest()
    const result = await this.handleQueryRetry(
      this.client.getAllRedstacks,
      request
    )
    return result
      .toObject()
      .redStacksList.map(RedStackFactory.buildFromScanRedStack)
  }

  public static async getAllRedStacksByAccountId(
    id: string
  ): Promise<Array<RedStackModel>> {
    return this.getAllRedstacks().then((arr) =>
      arr.filter((v) => v.awsAccount === id)
    )
  }

  public static async getAllActiveRedStacks(): Promise<Array<RedStackModel>> {
    return this.getAllRedstacks().then((arr) => arr.filter((v) => v.isActive))
  }

  public static async listSupportedAwsRegions(): Promise<VIRow> {
    const request = new Empty()
    const result = await this.handleQueryRetry(
      this.client.listSupportedAwsRegions,
      request
    )
    return GeneralFactory.buildPossibleRegions(
      result.toObject().regionNamesList
    )
  }

  public static async listAwsVpcs(
    accountId: string,
    regionName: string
  ): Promise<Array<VpcModel>> {
    const request = new ListAwsVpcsRequest()
    request.setAccountId(accountId)
    request.setRegionName(regionName)
    const result = await this.handleQueryRetryLong(
      this.client.listAwsVpcs,
      request
    )

    return result
      .toObject()
      .vpcsList.map((params) =>
        VpcFactory.buildFromGrpc({
          ...params,
          accountId,
          regionName,
        })
      )
      .sort((a, b) => (a.isDefault < b.isDefault ? 1 : -1))
  }

  public static async listAwsVpcArr(
    accountId: string
  ): Promise<Array<VpcModel>> {
    const supportedRegions = await this.listSupportedAwsRegions()
    const requests: Array<Promise<Array<VpcModel>>> = []
    supportedRegions.forEach((region: ValueInterface) => {
      requests.push(this.listAwsVpcs(accountId, region.name))
    })
    const allVpcTree: Array<Array<VpcModel>> = await Promise.all(requests)
    return ArrHelper.flatDeep<VpcModel>(allVpcTree)
  }

  public static async getAwsAccountInfo(
    accountIds: Array<string>
  ): Promise<Array<AwsAccountInfoModel>> {
    const request = new GetAwsAccountInfoRequest()
    request.setAwsAccountIdsList(accountIds)
    const result = await this.handleQueryRetry(
      this.client.getAwsAccountInfo,
      request
    )
    const resultObj = result.toObject()
    return resultObj.awsAccountsInfoList.map(
      AwsAccountInfoFactory.buildFromGrpc
    )
  }

  public static async repairCloudConnector(
    cloudConnectorId: string
  ): Promise<string> {
    const request = new RepairCloudConnectorRequest()
    request.setRedStackId(cloudConnectorId)
    const result = await this.handleQuery(
      this.client.repairCloudConnector,
      request
    )
    return result.toObject().jobId
  }

  public static async getCloudFormationLink(): Promise<string> {
    const request = new GetCloudFormationLinkRequest()
    const result = await this.handleQueryRetry(
      this.client.getCloudFormationLink,
      request
    )
    return result.toObject().link
  }

  public static async installRedstack(
    accountId: string,
    vpcId: string,
    regionName: string,
    safetyLock: boolean,
    subnetIdsList: Array<string>
  ): Promise<string> {
    const request = new InstallRedstackRequest()
    request.setAccountId(accountId)
    const region = new RedStackInstallParameters()
    region.setVpcId(vpcId)
    region.setSubnetIdsList(subnetIdsList)
    region.setRegion(regionName)
    request.setRegionsList([region])
    region.setSafetyLock(safetyLock)
    const result = await this.handleQueryLong(
      this.client.installRedstack,
      request
    )
    return result.toObject().jobsIdsList[0] ?? ''
  }

  public static async discoverInstalledCfn(
    accountId: string
  ): Promise<CfnModel> {
    const request = new DiscoverInstalledCfnRequest()
    request.setAwsAccountId(accountId)
    const result = await this.handleQueryRetry(
      this.client.discoverInstalledCfn,
      request
    )
    return CfnFactory.buildFromGrpc(result.toObject())
  }

  public static async listAwsAccounts(): Promise<any> {
    const request = new ListAwsAccountsRequest()
    const result = await this.handleQueryRetry(
      this.client.listAwsAccounts,
      request
    )
    return result.toObject().awsAccountsList
  }

  public static async listAvailabilityZones(
    redStackId: string
  ): Promise<Array<AvailabilityZoneModel>> {
    const request = new ListAvailabilityZonesRequest()
    request.setRedStackId(redStackId)
    const result = await this.handleQueryRetry(
      this.client.listAvailabilityZones,
      request
    )
    return result
      .toObject()
      .zonesList.map(AvailabilityZoneFactory.buildFromGrpc)
  }

  public static async listAwsSecurityGroups(
    awsAccountId: string,
    awsRegion: string
  ): Promise<Array<AwsSecurityGroupsModel>> {
    const request = new ListAwsSecurityGroupsRequest()
    request.setAwsAccountId(awsAccountId)
    request.setAwsRegion(awsRegion)
    const result = await this.handleQueryRetry(
      this.client.listAwsSecurityGroups,
      request
    )
    return result
      .toObject()
      .securityGroupsList.map(AwsSecurityGroupsFactory.buildFromGrpc)
  }
}

export default GrpcRexService
