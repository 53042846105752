import { useRestClient } from 'ui-v2/src/hooks/rest-client'
import {
  AssetThreatsParams,
  AssetThreatsResponse,
  Entities,
  RecoveryPointBackupByIdResponse,
  RecoveryPointResponse,
  RecoveryPointsParams,
} from 'ui-v2/src/lib/models/backups'

export const useRecoveryPointsService = () => {
  const { isReady, isLoading, get } = useRestClient()

  const listRecoveryPoints = async (
    assetId: string,
    params: RecoveryPointsParams
  ) => {
    if (!isReady) {
      throw new Error('REST client is not initialized')
    }

    const url = `api/v1/assets/${assetId}/recovery-points`
    return get<RecoveryPointResponse>(url, params)
  }

  const listAssetThreatsForRecoveryPoint = async (
    assetId: string,
    recoveryPoint: string,
    params: AssetThreatsParams
  ) => {
    if (!isReady) {
      throw new Error('REST client is not initialized')
    }

    const url = `api/v1/assets/${assetId}/recovery-points/${recoveryPoint}/entities/assets/${assetId}/threats`
    return get<AssetThreatsResponse>(url, params)
  }

  const listAssetItemThreatsForRecoveryPoint = async (
    assetId: string,
    recoveryPoint: string,
    params: AssetThreatsParams
  ) => {
    if (!isReady) {
      throw new Error('REST client is not initialized')
    }

    const url = `api/v1/assets/${assetId}/recovery-points/${recoveryPoint}/entities/asset-items/${assetId}/threats`
    return get<AssetThreatsResponse>(url, params)
  }

  const listRecoveryPointsBackupEntities = async (
    assetId: string,
    backupId: string,
    params: { scan_job_id?: string }
  ) => {
    if (!isReady) {
      throw new Error(
        'REST client is not initialized for recovery points backup entities'
      )
    }

    const url = `api/v1/assets/${assetId}/recovery-points/${backupId}/entities`
    return get<Array<Entities>>(url, params)
  }

  const getRecoveryPointById = async (assetId: string, backupId: string) => {
    if (!isReady) {
      throw new Error(
        'REST client is not initialized for recovery points backup'
      )
    }

    const url = `api/v1/assets/${assetId}/recovery-points/${backupId}`
    return get<RecoveryPointBackupByIdResponse>(url)
  }

  return {
    listRecoveryPoints,
    listAssetThreatsForRecoveryPoint,
    listRecoveryPointsBackupEntities,
    getRecoveryPointById,
    isReady,
    isLoading,
    listAssetItemThreatsForRecoveryPoint,
  }
}
