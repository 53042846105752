import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircleInfoIcon from 'ui-v2/src/assets/icons/circle-info-icon'
import {
  CloudFormationStackButton,
  ReviewElastioButton,
} from '../aws-account-buttons'

interface AwsAccountElastioProps {
  isHideCfnButtons?: boolean
  onCloudFormationClickHandler: any
  isCloudFormationLaunched: boolean
}

const AwsAccountElastio = ({
  isHideCfnButtons,
  onCloudFormationClickHandler,
  isCloudFormationLaunched,
}: AwsAccountElastioProps) => {
  const { getValues } = useFormContext<SourceFormData>()
  const { awsAccountId, vpcRegions, vpcListForApi } = getValues()

  return (
    <Box>
      <Typography variant="body1" fontWeight={500} mb={2} fontSize={'14px'}>
        The Cloud Connector deployments are currently in progress for the
        following account and regions:
      </Typography>
      <Typography component={'ul'} mb={2} fontSize={'14px'}>
        <Typography component={'li'} fontSize={'14px'}>
          Dedicated Account: {awsAccountId}
        </Typography>
        {vpcRegions.length > 0 && (
          <Box
            component={'li'}
            sx={{
              listStyleType: 'disc',
              fontWeight: '500',
            }}
          >
            Regions:
            <Box component={'ul'} fontSize={'14px'}>
              {vpcRegions.map((region) => (
                <Box
                  component={'li'}
                  key={region}
                  sx={{
                    listStyleType: 'disc',
                    fontSize: '14px',
                  }}
                >
                  {region}
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {vpcListForApi.length > 0 && (
          <Box
            component={'li'}
            sx={{
              listStyleType: 'disc',
              fontWeight: '500',
            }}
          >
            Regions:
            <Box component={'ul'}>
              {vpcListForApi.map((item) => {
                const { id, name, children } = item
                return (
                  <Box
                    component={'li'}
                    key={id}
                    sx={{
                      listStyleType: 'disc',
                    }}
                  >
                    {name}
                    {children?.length && (
                      <Box component={'ul'}>
                        {children.map((vpc) => {
                          const {
                            id: vpcId,
                            name: vpcName,
                            children: vpcChildren,
                          } = vpc
                          return (
                            <Box
                              component={'li'}
                              key={id + vpcId}
                              sx={{
                                listStyleType: 'disc',
                              }}
                            >
                              {vpcName}
                              {vpcChildren?.length && (
                                <Box component={'ul'}>
                                  {vpcChildren.map((subnet) => {
                                    return (
                                      <Box
                                        component={'li'}
                                        key={id + vpcId + subnet.id}
                                        sx={{
                                          listStyleType: 'disc',
                                        }}
                                      >
                                        {subnet.name}
                                      </Box>
                                    )
                                  })}
                                </Box>
                              )}
                            </Box>
                          )
                        })}
                      </Box>
                    )}
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}
      </Typography>
      {!isHideCfnButtons && (
        <>
          <Typography variant="body1" fontWeight={500} mb={6} fontSize={'14px'}>
            Launch the CFN stack and return here to proceed after successful
            setup.
          </Typography>
          <Box mb={6}>
            <CloudFormationStackButton
              onCloudFormationClickHandler={onCloudFormationClickHandler}
              isCloudFormationLaunched={isCloudFormationLaunched}
            />
          </Box>
          <Typography variant="body1" fontWeight={500} mb={2} fontSize={'14px'}>
            You can close this window to monitor deployment progress in the
            Deployment Dashboard.
          </Typography>
          <Typography variant="body1" fontWeight={500} mb={2} fontSize={'14px'}>
            Once the deployment is complete, <b>Click "Link"</b> from the
            deployment dashboard and follow the instructions to link your
            protected accounts to the Cloud Connector deployed in your dedicated
            account.
          </Typography>
          <Stack mb={2} direction="row" gap={1}>
            <CircleInfoIcon />
            <Typography variant="body1" fontWeight={500} fontSize={'14px'}>
              For reference, the CloudFormation template is available here for
              your inspection.
            </Typography>
          </Stack>
          <Box>
            <ReviewElastioButton />
          </Box>
        </>
      )}
    </Box>
  )
}

export default AwsAccountElastio
