import { useQuery } from '@tanstack/react-query'
import { useRecoveryPointsService } from '../../rest-service'

interface Options {
  enabled?: boolean
  staleTime?: number
}

export function useListRecoveryPointsBackupEntitiesQuery(
  assetId: string | undefined,
  backupId: string | undefined,
  params: { scan_job_id?: string } = {},
  options?: Options
) {
  const {
    listRecoveryPointsBackupEntities,
    isReady,
    isLoading: isClientLoading,
  } = useRecoveryPointsService()

  return useQuery({
    queryKey: [
      'assets',
      assetId,
      'recovery-points',
      backupId,
      'entities',
      params,
    ],
    queryFn: async () => {
      if (!assetId || !backupId) {
        throw new Error('assetId and backupId are required')
      }

      return listRecoveryPointsBackupEntities(assetId, backupId, params)
    },

    enabled:
      Boolean(assetId) &&
      isReady &&
      !isClientLoading &&
      (options?.enabled === undefined ? true : options.enabled),
    staleTime: options?.staleTime ?? 1 * 60 * 1000,
  })
}
