import { Box, CircularProgress, SxProps } from '@mui/material'

const Loading = ({ sx = {} }: { sx?: SxProps }) => {
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <CircularProgress size={24} sx={{ color: 'grey.500' }} />
    </Box>
  )
}

export default Loading
