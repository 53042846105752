/* eslint-disable import/no-extraneous-dependencies */
import { GenericHostInventoryOutput } from 'blues-corejs/dist/use_cases/list_generic_hosts_inventory/types'
import { AssetDetailsLink } from 'ui-v2/src/components/ui/data-display/asset-details-link'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'

export const getInventoryHostColumns = (): Array<
  Column<GenericHostInventoryOutput>
> => [
  {
    key: 'isInfected',
    header: 'Health',
    render: (row) => <StatusChip type={row.isInfected ? 'error' : 'success'} />,
    width: 80,
    align: 'center',
  },
  {
    key: 'genericHostName',
    header: 'Host Name',
    render: (row) => (
      <AssetDetailsLink
        assetId={row.uuid}
        assetKind={ASSETS_ROUTE_SEGMENTS.GENERIC_HOST}
      >
        {row.genericHostName}
      </AssetDetailsLink>
    ),
  },
  {
    key: 'lastScan',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(row.lastScan),
  },
  {
    key: 'threats',
    header: 'State',
  },
]
