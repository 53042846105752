import { useMemo } from 'react'
import { useAlertsActiveRuleStore } from '../store/alerts-active-rule-store'
import { transformNotificationForForm } from '../utils'
import { useListEventKindsQuery } from 'ui-v2/src/hooks/queries/alerts/list-notifications-rules'
import { useListWebhooksQuery } from 'ui-v2/src/hooks/queries/webhooks'
import { useListUsersQuery } from 'ui-v2/src/hooks/queries/users'
import DrawerLoader from 'ui-v2/src/components/drawers/drawer-loader'
import RuleProperties from '../create-rule/rule-properties'
import dayjs from 'dayjs'

const DetailRuleContent = () => {
  const { activeRule } = useAlertsActiveRuleStore()
  const { data: eventKinds, isLoading: isLoadingEventKinds } =
    useListEventKindsQuery()
  const { data: webhooksList, isLoading: isLoadongWebhooks } =
    useListWebhooksQuery()
  const { data: recipients, isLoading: isLoadingRecipients } =
    useListUsersQuery()
  const isLoading =
    isLoadingEventKinds || isLoadongWebhooks || isLoadingRecipients

  const values = useMemo(() => {
    if (!activeRule || !eventKinds || !webhooksList || !recipients) {
      return null
    }

    return transformNotificationForForm({
      notification: activeRule,
      allEvents: eventKinds,
      webhooksList,
      recipients: recipients.userProfilesList,
    })
  }, [activeRule, eventKinds, webhooksList, recipients])

  if (isLoading || !values) {
    ;<DrawerLoader />
  }

  return (
    <RuleProperties
      name={values?.name || ''}
      description={values?.description || ''}
      selectedEvents={values?.selectedEvents || []}
      isSuppress={values?.isSuppress || false}
      suppressUntil={values?.suppressUntil || dayjs()}
      isEnabled={values?.isEnabled || false}
      setIsEnabled={() => {}}
      selectedRegions={values?.selectedRegions || []}
      tagPairs={values?.tagPairs || []}
      emailUsers={values?.emailUsers || []}
      selectedWebhooks={values?.selectedWebhooks || []}
      slackEnabled={values?.slackEnabled || false}
      disableChange
    />
  )
}

export default DetailRuleContent
