import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const VolumeIdFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const volumeIdList =
    searchParams.get(ASSET_FILTERS_MAP.VOLUME_ID)?.split(',') || []

  const { data: liveAssetsData } = useAssetsLiveQuery()

  const volumeIdOptions = useMemo(() => {
    if (!liveAssetsData) {
      return []
    }

    return liveAssetsData.ebsVolumes.map((volume) => ({
      label: volume.awsId,
      value: volume.id,
    }))
  }, [liveAssetsData])

  const handleVolumeIdChange = (
    newVolumeIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newVolumeIdList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.VOLUME_ID)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.VOLUME_ID, newVolumeIdList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={volumeIdOptions}
      value={volumeIdList}
      onChange={handleVolumeIdChange}
      label={
        <SelectLabelText
          selectedValueLength={volumeIdList?.length}
          optionsLength={volumeIdOptions?.length}
          text="Volume ID"
        />
      }
      width={200}
    />
  )
}

export default VolumeIdFilter
