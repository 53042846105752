/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetsEc2DrawerDataAttrs,
  Ec2Drawer,
} from 'ui-v2/src/lib/models/assets'
import { Backup, EBS, EC2, Nullable, Threat } from 'blues-corejs/dist'
import { AssetsEc2Base } from './ec2-base'
import { Scan } from 'ui-v2/src/lib/models/scans'

export class AssetsEc2DrawerData extends AssetsEc2Base {
  protected readonly instances: Array<EC2>

  protected readonly volumes: Map<string, EBS>

  protected readonly threats: Array<Threat>

  readonly #lastBackups: Map<string, Backup>

  protected readonly scans: Array<Scan>

  protected readonly volumesWithScans: Map<string, Array<Scan>>

  constructor(parameters: AssetsEc2DrawerDataAttrs) {
    super()
    this.instances = parameters.instances
    this.volumes = parameters.volumes.reduce((map, volume) => {
      map.set(volume.id, volume)
      return map
    }, new Map<string, EBS>())
    this.threats = parameters.threats
    this.#lastBackups = parameters.lastBackups
    this.scans = parameters.scans
    this.volumesWithScans = this.getVolumeWithScans()
  }

  getEc2DrawerData() {
    const instance = this.instances[0]
    if (!instance) {
      return undefined
    }
    return new Ec2Drawer({
      id: instance.id,
      name: instance.name,
      awsId: instance.awsId,
      awsAccountId: instance.awsAccountId,
      awsRegion: instance.awsRegion,
      state: AssetsEc2Base.convertEc2State(instance.state),
      isUnhealthy: this.isUnhealthyInstance(instance),
      lastScan: this.getLastScanForInstance(instance),
      lastBackup: this.#getLastBackup(instance),
    })
  }

  #getLastBackup(instance: EC2): Nullable<Backup> {
    return this.#lastBackups.get(instance.id)
  }
}
