import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAssetsListByIdQuery } from 'ui-v2/src/hooks/queries/assets'
import { getAsset, getAssetKind } from 'ui-v2/src/lib/helpers/assets.helper'

export const useAssetDetails = () => {
  const { assetId: parentAssetId, volumeId: drilldownAssetId } = useParams<{
    assetId: string | undefined
    volumeId: string | undefined
  }>()

  const assetIds: Array<string> = useMemo(() => {
    const ids = []
    if (parentAssetId) {
      ids.push(parentAssetId)

      if (drilldownAssetId) {
        ids.push(drilldownAssetId)
      }
    }

    return ids
  }, [parentAssetId, drilldownAssetId])

  const {
    data: assetsData,
    isLoading: isAssetsDataLoading,
    isError: isAssetsDataError,
  } = useAssetsListByIdQuery(assetIds)

  const { parentAsset, drilldownAsset } = useMemo(() => {
    if (!assetsData?.assetsList?.length) {
      return {
        parentAsset: undefined,
        drilldownAsset: undefined,
      }
    }

    const processedAssets = assetsData.assetsList
      .map((asset) => getAsset(asset))
      .filter(
        (asset): asset is NonNullable<ReturnType<typeof getAsset>> =>
          asset !== undefined && asset !== null
      )

    const parentAssetData = processedAssets.find(
      (asset) => asset.id === parentAssetId
    )

    const drilldownAssetData = drilldownAssetId
      ? processedAssets.find((asset) => asset.id === drilldownAssetId)
      : undefined

    return {
      parentAsset: parentAssetData,
      drilldownAsset: drilldownAssetData,
    }
  }, [assetsData, parentAssetId, drilldownAssetId])

  const { parentAssetKind, drilldownAssetKind } = useMemo(() => {
    if (!parentAsset) {
      return {
        parentAssetKind: undefined,
        drilldownAssetKind: undefined,
      }
    }

    return {
      parentAssetKind: getAssetKind(parentAsset),
      drilldownAssetKind: drilldownAsset
        ? getAssetKind(drilldownAsset)
        : undefined,
    }
  }, [parentAsset, drilldownAsset])

  const asset = drilldownAsset ?? parentAsset
  const assetId = drilldownAssetId ?? parentAssetId
  const assetKind = drilldownAssetKind ?? parentAssetKind

  return {
    parentAssetId,
    drilldownAssetId,
    parentAsset,
    drilldownAsset,
    parentAssetKind,
    drilldownAssetKind,
    assetsData,
    isAssetsDataLoading,
    isAssetsDataError,
    asset,
    assetId,
    assetKind,
  }
}
