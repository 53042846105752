import { EventList } from 'ui-v2/src/lib/models/webhooks'
import { getWebhooksEventType } from './data'
import DeleteIcon from 'ui-v2/src/assets/icons/delete-icon'

interface EventListTableProps {
  eventList: Array<EventList>
  handleDeleteEvent: (index: number) => void
}

const EventListTable = ({
  eventList,
  handleDeleteEvent,
}: EventListTableProps) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Event type</th>
          <th>Version</th>
          <th>Severity</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {eventList.map((item, index) => (
          <tr key={index}>
            <td>{getWebhooksEventType(item.eventType)}</td>
            <td>{item.versionType}</td>
            <td>{item.severityList.join(', ')}</td>
            <td>
              <DeleteIcon
                onClick={() => handleDeleteEvent(index)}
                cursor={'pointer'}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default EventListTable
