import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { getHumanReadableCloudConnectorJobKind } from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job'
import {
  MONITOR_FILTER_JOB_TYPE,
  MONITOR_FILTER_MAP,
} from 'ui-v2/src/lib/constants/monitor.constant'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const JobTypeDropdown = () => {
  // eslint-disable-next-line no-unused-vars
  const options = Object.entries(MONITOR_FILTER_JOB_TYPE).map(([_, value]) => ({
    value: value.toString(),
    label: getHumanReadableCloudConnectorJobKind(value),
  }))

  const [searchParams, setSearchParams] = useSearchParams()
  const jobTypeList = searchParams.get(MONITOR_FILTER_MAP.JOB)?.split(',') || []

  const handleJobTypeChange = (
    newInstanceIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newInstanceIdList.length === 0) {
      searchParams.delete(MONITOR_FILTER_MAP.JOB)
    } else {
      searchParams.set(MONITOR_FILTER_MAP.JOB, newInstanceIdList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      value={jobTypeList}
      options={options}
      onChange={handleJobTypeChange}
      width={200}
      label={
        <SelectLabelText
          selectedValueLength={jobTypeList?.length}
          optionsLength={options?.length}
          text="Job Type"
        />
      }
    />
  )
}

export default JobTypeDropdown
