/* eslint-disable import/no-extraneous-dependencies */
import {
  DeploymentTableDataAttrs,
  DeploymentTableRow,
} from 'ui-v2/src/lib/models/monitor'
import RedStackModel from 'ui-v2/src/lib/models/base-model/red-stack.model'
import { JobTenant } from 'blues-corejs/dist'
import {
  getHumanReadableTenantJobKind,
  getTenantJobStatusMessage,
} from 'ui-v2/src/lib/transformers/tenant-jobs'

export class DeploymentTableData {
  readonly #tenantJobs: Array<JobTenant>

  readonly #redStackListData: Array<RedStackModel>

  constructor(parameters: DeploymentTableDataAttrs) {
    this.#tenantJobs = parameters.tenantJobs
    this.#redStackListData = parameters.redStackListData
  }

  getDeploymentData = (): Array<DeploymentTableRow> => {
    return this.#tenantJobs.map((job) => {
      const jobType = getHumanReadableTenantJobKind(job.kind)

      const redstack = this.#redStackListData.find(
        (r) => String(r.redStackId) === job.ccId
      )

      const message = getTenantJobStatusMessage({ job })

      return new DeploymentTableRow({
        id: job.id,
        status: job.status,
        scheduledOn: job.createdAt,
        jobType: jobType,
        cloudConnector: redstack,
        message: message,
        task: job.name,
        completedOn: job.finishedAt,
        childrenList: job.childrenList,
      })
    })
  }
}
