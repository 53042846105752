import React from 'react'
import { CardProps } from '@mui/material'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import LoadingContent from '../common/loading'
import SuccessContent from './success'
import { useAssetDetails } from '../common/use-asset-details'
import { useListOvaAccountsQuery } from 'ui-v2/src/hooks/queries/ova-accounts'
import WidgetCard from '../common/widget-card'

const BackupVendorWidget = () => {
  const { asset, isAssetsDataLoading, isAssetsDataError } = useAssetDetails()

  const {
    data: ovaAccountsData,
    isLoading: isOvaAccountsLoading,
    isError: isOvaAccountsError,
  } = useListOvaAccountsQuery()

  const solidCardSxProp: CardProps['sx'] = {
    height: '100%',
    borderRadius: '8px',
    p: 2,
  }

  const renderContent = () => {
    if (isAssetsDataLoading || isOvaAccountsLoading) {
      return <LoadingContent />
    }
    if (isAssetsDataError || isOvaAccountsError) {
      return (
        <ErrorDisplay message="Failed to load widget, please try again later." />
      )
    }
    return <SuccessContent asset={asset} ovaAccountsData={ovaAccountsData} />
  }

  return <WidgetCard sx={solidCardSxProp}>{renderContent()}</WidgetCard>
}

export default BackupVendorWidget
