import {
  ASSET_FILTERS_MAP,
  NEW_ASSET_FILTERS_MAP,
} from 'ui-v2/src/lib/constants/assets.constant'
import AccountIdFilter from './common/account-id'
import PolicyCoverageFilter from './common/policy'
import RegionFilter from './common/region'
import BackupVendorFilter from './ova-server/backup-vendor'
import LastScanFilter from './common/last-scan'
import HealthFilter from './common/health'
import AssetIdFilter from './common/asset-id'
import AssetNameFilter from './common/asset-name'
import InstanceStateFilter from './ec2/instance-state'
import VolumeStateFilter from './ebs/volume-state'
import BucketStateFilter from './s3/bucket-state'
import FilesystemStateFilter from './efs/filesystem'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import {
  ListEbsRequest,
  ListEc2Request,
  ListEfsRequest,
  ListS3Request,
} from 'ui-v2/src/lib/grpc'

export const filterOptions = {
  [NEW_ASSET_FILTERS_MAP.ACCOUNT_ID]: <AccountIdFilter />,
  [NEW_ASSET_FILTERS_MAP.BACKUP_VENDOR]: <BackupVendorFilter />,
  [NEW_ASSET_FILTERS_MAP.REGION]: <RegionFilter />,
  [NEW_ASSET_FILTERS_MAP.POLICY_COVERAGE]: <PolicyCoverageFilter />,
  [NEW_ASSET_FILTERS_MAP.LAST_SCAN]: <LastScanFilter />,
  [NEW_ASSET_FILTERS_MAP.HEALTH]: <HealthFilter />,
  [NEW_ASSET_FILTERS_MAP.ASSET_ID]: <AssetIdFilter />,
  [NEW_ASSET_FILTERS_MAP.ASSET_NAME]: <AssetNameFilter />,
  [NEW_ASSET_FILTERS_MAP.INSTANCE_STATE]: <InstanceStateFilter />,
  [NEW_ASSET_FILTERS_MAP.VOLUME_STATE]: <VolumeStateFilter />,
  [NEW_ASSET_FILTERS_MAP.BUCKET_STATE]: <BucketStateFilter />,
  [NEW_ASSET_FILTERS_MAP.FILESYSTEM_STATE]: <FilesystemStateFilter />,
}

export const defaultFilters = {
  [ASSETS_ROUTE_SEGMENTS.EC2]: {
    [ASSET_FILTERS_MAP.INSTANCE_STATE]: [
      ListEc2Request.Initial.Filter.InstanceState.RUNNING,
      ListEc2Request.Initial.Filter.InstanceState.STOPPED,
      ListEc2Request.Initial.Filter.InstanceState.UNKNOWN,
    ],
  },
  [ASSETS_ROUTE_SEGMENTS.EBS]: {
    [ASSET_FILTERS_MAP.VOLUME_STATE]: [
      ListEbsRequest.Initial.Filter.VolumeState.IN_USE,
      ListEbsRequest.Initial.Filter.VolumeState.AVAILABLE,
      ListEbsRequest.Initial.Filter.VolumeState.UNKNOWN,
    ],
  },
  [ASSETS_ROUTE_SEGMENTS.S3]: {
    [ASSET_FILTERS_MAP.BUCKET_STATE]: [
      ListS3Request.Initial.Filter.BucketState.IN_USE,
    ],
  },
  [ASSETS_ROUTE_SEGMENTS.EFS]: {
    [ASSET_FILTERS_MAP.FILESYSTEM_STATE]: [
      ListEfsRequest.Initial.Filter.FilesystemState.IN_USE,
    ],
  },
  [ASSETS_ROUTE_SEGMENTS.SERVER]: {},
  [ASSETS_ROUTE_SEGMENTS.GENERIC_HOST]: {},
} as const

export function getDefaultQueryParams(
  tabSlug: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
): URLSearchParams {
  const tabDefaultFilters = defaultFilters[tabSlug]
  if (!tabDefaultFilters) {
    return new URLSearchParams()
  }

  const queryParams = new URLSearchParams()

  Object.entries(tabDefaultFilters).forEach(([filterKey, filterValues]) => {
    if (Array.isArray(filterValues)) {
      queryParams.set(filterKey, filterValues.join(','))
    }
  })

  return queryParams
}
