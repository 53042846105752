import { useMemo } from 'react'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ENCRYPTION_ACTIVITY_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useSearchParams } from 'react-router-dom'

export interface FilterComponentProps {
  options: Array<string>
}

const PolicyCoverageFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const policyList =
    searchParams.get(ENCRYPTION_ACTIVITY_FILTERS_MAP.POLICY)?.split(',') || []

  const policyOptions = useMemo(() => {
    return options.map((option) => ({
      label: option === 'true' ? 'Covered' : 'Not Covered',
      value: option,
    }))
  }, [])

  const handlePolicyChange = (
    newPolicyIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newPolicyIdList.length === 0) {
      searchParams.delete(ENCRYPTION_ACTIVITY_FILTERS_MAP.POLICY)
    } else {
      searchParams.set(
        ENCRYPTION_ACTIVITY_FILTERS_MAP.POLICY,
        newPolicyIdList.join(',')
      )
    }

    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={policyOptions}
      value={policyList}
      onChange={handlePolicyChange}
      label="Policy"
      width={200}
    />
  )
}

export default PolicyCoverageFilter
