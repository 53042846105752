import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

export function useGetCloudInformationLinkQuery(awsAccountId?: string) {
  const rexClient = useGrpcClient(RexClient)

  return useQuery({
    queryKey: ['rex', 'get-cloud-information-link'],
    queryFn: () => {
      if (!rexClient) {
        throw new Error('Rex client not initialized')
      }

      return rexClient.getCloudFormationLink(awsAccountId)
    },
    retry: 5,
    enabled: !!rexClient,
  })
}
