import LangHelper from '../helpers/lang.helper'
import { CoverageCardSlugValues, StatCardSlugValues } from '../models/aws-stats'

export const STAT_CARD_SLUG = {
  MALWARE: 'malware',
  RANSOMWARE: 'ransomware',
  CORRUPTED_FILESYSTEMS: 'corrupted-filesystems',
  THREAT_EXPOSURE: 'threat-exposure',
  FILE_SCANS: 'file-scans',
  STORAGE_MISCONFIGURATIONS: 'storage-misconfigurations',
} as const

export const COVERAGE_CARD_SLUG = {
  SCAN_COVERAGE: 'scan-coverage',
  BACKUP_COVERAGE: 'backup-coverage',
} as const

export const STAT_TITLE_MAP: Record<StatCardSlugValues, string> = {
  [STAT_CARD_SLUG.MALWARE]: 'Active Malware',
  [STAT_CARD_SLUG.RANSOMWARE]: 'Ransomware Detected',
  [STAT_CARD_SLUG.CORRUPTED_FILESYSTEMS]: 'File System Corruptions',
  [STAT_CARD_SLUG.THREAT_EXPOSURE]: 'Threats Detected',
  [STAT_CARD_SLUG.FILE_SCANS]: 'Infected Files',
  [STAT_CARD_SLUG.STORAGE_MISCONFIGURATIONS]: 'Storage Misconfigured',
} as const

export const COVERAGE_TITLE_MAP: Record<CoverageCardSlugValues, string> = {
  [COVERAGE_CARD_SLUG.SCAN_COVERAGE]: 'Scan Coverage',
  [COVERAGE_CARD_SLUG.BACKUP_COVERAGE]: 'Backup Coverage',
} as const

export const getStatContent = (
  slug: StatCardSlugValues,
  count: number
): string => {
  switch (slug) {
    case STAT_CARD_SLUG.MALWARE:
    case STAT_CARD_SLUG.RANSOMWARE:
    case STAT_CARD_SLUG.THREAT_EXPOSURE:
      return `${LangHelper.pluralizeEn('asset', count)} impacted.`
    case STAT_CARD_SLUG.STORAGE_MISCONFIGURATIONS:
      return `storage ${LangHelper.pluralizeEn('misconfiguration', count)}.`
    case STAT_CARD_SLUG.CORRUPTED_FILESYSTEMS:
      return `${LangHelper.pluralizeEn('filesystem', count)}.`
    case STAT_CARD_SLUG.FILE_SCANS:
      return `${LangHelper.pluralizeEn('file', count)} infected.`
    default:
      return ''
  }
}

export const getCoverageContent = (slug: CoverageCardSlugValues): string => {
  switch (slug) {
    case COVERAGE_CARD_SLUG.SCAN_COVERAGE:
    case COVERAGE_CARD_SLUG.BACKUP_COVERAGE:
      return `${LangHelper.pluralizeEn('asset')}.`
    default:
      return ''
  }
}
