import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

const FilesystemIdFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const filesystemIdList =
    searchParams.get(ASSET_FILTERS_MAP.FILESYSTEM_ID)?.split(',') || []

  const { data: liveAssetsData } = useAssetsLiveQuery()

  const filesystemIdOptions = useMemo(() => {
    if (!liveAssetsData) {
      return []
    }

    return liveAssetsData.efs.map((filesystem) => ({
      label: filesystem.awsId,
      value: filesystem.id,
    }))
  }, [liveAssetsData])

  const handleFilesystemIdChange = (
    newFilesystemIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newFilesystemIdList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.FILESYSTEM_ID)
    } else {
      searchParams.set(
        ASSET_FILTERS_MAP.FILESYSTEM_ID,
        newFilesystemIdList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={filesystemIdOptions}
      value={filesystemIdList}
      onChange={handleFilesystemIdChange}
      label={
        <SelectLabelText
          selectedValueLength={filesystemIdList?.length}
          optionsLength={filesystemIdOptions?.length}
          text="File System ID"
        />
      }
      width={200}
    />
  )
}

export default FilesystemIdFilter
