import { Typography, TypographyOwnProps, TypographyProps } from '@mui/material'
import { Link as LinkReactRouter, useLocation } from 'react-router-dom'
import { getTargetPath } from './utils'

interface LinkProps extends TypographyProps {
  to: string
  preservePath?: boolean
  preserveSearchParams?: boolean
  children: React.ReactNode
  variant?: TypographyOwnProps['variant']
  color?: TypographyOwnProps['color']
}

const Link: React.FC<LinkProps> = ({
  to,
  preservePath = false,
  preserveSearchParams = false,
  children,
  variant = 'body2',
  color = 'link',
  ...props
}) => {
  const location = useLocation()

  return (
    <Typography
      component={LinkReactRouter}
      to={getTargetPath(
        to,
        preservePath,
        preserveSearchParams,
        location.search
      )}
      sx={{
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' },
        '&.active': {
          textDecoration: 'underline',
          color: 'secondary',
        },
        ...props.sx,
      }}
      variant={variant}
      color={color}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default Link
