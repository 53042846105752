import { useToast } from 'ui-v2/src/hooks/toast'
import { ScheduleScanAsset } from 'ui-v2/src/lib/models/assets'
import { useScheduleScansMutation } from 'ui-v2/src/hooks/queries/pechkin'
import Button from 'ui-v2/src/components/ui/inputs/button'

interface StartScanProps {
  asset: ScheduleScanAsset
}

const StartScan: React.FC<StartScanProps> = ({ asset }) => {
  const { mutate, isPending } = useScheduleScansMutation()
  const toast = useToast()

  const scheduleScan = () => {
    mutate([asset], {
      onError: (error) => {
        toast.error(error.message ?? 'Failed to schedule scan')
      },
      onSuccess: (data) => {
        if (data?.[0]?.errorMessage) {
          toast.error('Failed to schedule scan')
        } else {
          toast.success('Scan scheduled successfully')
        }
      },
    })
  }

  return (
    <Button
      variant="outlined"
      onClick={scheduleScan}
      isLoading={isPending}
      sx={{
        textTransform: 'capitalize',
        borderRadius: 1,
        height: '1.5rem',
        width: '3.75rem',
      }}
    >
      Scan
    </Button>
  )
}

export default StartScan
