import React, { SyntheticEvent, useEffect } from 'react'
import { Box, Stack, Tab, Tabs } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ASSET_DETAILS_ACTIVE_TAB } from './constants'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import AssetDetailsFilters from '../filters'
import FilesTableFilters from './files-table/filters'
import RecoveryPoints from './recovery-points'
import ScanLog from './scan-log'
import useTabsCount from './common/use-tabs-count'
import RecoveryPointFilesTableContainer from './recovery-points/files-table-container'
import ScanLogFilesTableContainer from './scan-log/files-table-container'
import Loading from './common/loading'

const TABS = {
  recoveryPoint: {
    component: <RecoveryPoints />,
    slug: ASSET_DETAILS_ACTIVE_TAB.recoveryPoint,
    getLabel: (count: number) => `Recovery Points (${count})`,
  },
  scanLog: {
    component: <ScanLog />,
    slug: ASSET_DETAILS_ACTIVE_TAB.scanLog,
    getLabel: (count: number) => `Scans (${count})`,
  },
} as const

type TabKeys = keyof typeof TABS

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  slug: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, slug, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== slug}
      id={`tabpanel-${slug}`}
      aria-labelledby={`tab-${slug}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === slug && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
  )
}

function a11yProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

const DataPanel = () => {
  const {
    assetKind,
    assetId,
    activeTab = ASSET_DETAILS_ACTIVE_TAB.recoveryPoint,
    volumeId,
  } = useParams<{
    assetKind: string
    assetId: string
    activeTab?: TabKeys
    recoveryPointId?: string
    volumeId?: string
  }>()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = TABS[activeTab]?.slug ?? TABS.recoveryPoint.slug

  const { tabsCount, isLoading } = useTabsCount()

  const handleChange = (
    _: SyntheticEvent,
    newSlug: (typeof TABS)[keyof typeof TABS]['slug']
  ) => {
    const pathParts = [assetKind, 'details', assetId, newSlug]
      .filter(Boolean)
      .join('/')

    navigate(
      {
        pathname: `/${BASE_ROUTE_SEGMENTS.ASSETS}/${pathParts}`,
        search: `?${searchParams.toString()}`,
      },
      { replace: true }
    )
  }

  useEffect(() => {
    const typeFilter = searchParams.get('backupType')
    if (!typeFilter) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('backupType', 'key')
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [])

  if (isLoading) {
    return (
      <Loading
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      />
    )
  } else if (volumeId) {
    return (
      <Stack spacing={2} height={'100%'}>
        <Box minHeight="32px">
          <FilesTableFilters />
        </Box>
        <Box
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.elevation2,
            borderRadius: '8px',
            marginTop: '8px !important',
          })}
        >
          {activeTab === ASSET_DETAILS_ACTIVE_TAB.recoveryPoint ||
          assetKind === 'server' ? (
            <RecoveryPointFilesTableContainer />
          ) : (
            <ScanLogFilesTableContainer />
          )}
        </Box>
      </Stack>
    )
  } else {
    return (
      <Stack spacing={2} height={'100%'}>
        <Stack
          direction="row"
          flexWrap={'wrap'}
          rowGap={1}
          columnGap={1}
          alignItems={'center'}
          height={32}
        >
          <AssetDetailsFilters filters={['scan-type', 'scan-time']} />
        </Stack>
        <Box
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.elevation2,
            borderRadius: '8px',
            marginTop: '8px !important',
          })}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="recovery points and scan log tabs"
            sx={(theme) => ({
              padding: '8px 16px',
              minHeight: '37px !important',
              height: '37px',
              alignItems: 'center',
              '& .MuiTab-root': {
                color: theme.palette.text.primary,
              },
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.primary.main,
              },
              '& .MuiButtonBase-root': {
                mr: '16px !important',
              },
            })}
          >
            {Object.values(TABS).map((tabItem) =>
              tabItem.slug === ASSET_DETAILS_ACTIVE_TAB.scanLog &&
              assetKind === 'server' ? null : (
                <Tab
                  key={tabItem.slug}
                  value={tabItem.slug}
                  label={tabItem.getLabel(tabsCount[tabItem.slug])}
                  sx={{ py: 0 }}
                  {...a11yProps(tabItem.slug)}
                />
              )
            )}
          </Tabs>
          {Object.entries(TABS).map(([key, value]) => (
            <CustomTabPanel key={key} value={tab} slug={value.slug}>
              {value.component}
            </CustomTabPanel>
          ))}
        </Box>
      </Stack>
    )
  }
}

export default DataPanel
