/* eslint-disable import/no-extraneous-dependencies */
import { AssetsOvaServerTableAttrs } from 'ui-v2/src/lib/models/assets'
import { OvaServerTableRow } from 'ui-v2/src/lib/models/assets'
import { AssetsOvaServerBase } from './ova-server-base'
import { OvaServer } from 'blues-corejs/dist'

export class AssetsOvaServerTableData extends AssetsOvaServerBase {
  readonly #servers: Array<OvaServer>

  readonly ovaAccountsMap: Map<string, string>

  constructor(parameters: AssetsOvaServerTableAttrs) {
    super()
    this.#servers = parameters.servers
    this.ovaAccountsMap = parameters.ovaAccounts.reduce((map, ovaAccount) => {
      map.set(ovaAccount.id, ovaAccount.name)
      return map
    }, new Map<string, string>())
  }

  getOvaServerTableData() {
    return this.#servers.map(
      (server) =>
        new OvaServerTableRow({
          id: server.id,
          name: server.name,
          ovaAccountId: this.ovaAccountsMap.get(server.ovaAccountId) ?? '',
          backupProvider: this.convertBackupProvider(server.backupProvider),
        })
    )
  }
}
