import { ASSET_KIND_MAP } from 'ui-v2/src/lib/constants/assets.constant'

export const COLOR_CONFIG = {
  [ASSET_KIND_MAP.EC2]: {
    main: '#1DA6E6',
    light: '#1874A1',
  },
  [ASSET_KIND_MAP.EBS]: {
    main: '#6EA2A0',
    light: '#4A7175',
  },
  [ASSET_KIND_MAP.S3]: {
    main: '#1B59F8',
    light: '#1B59F880',
  },
  [ASSET_KIND_MAP.EFS]: {
    main: '#E19E9A',
    light: '#8B6A6D',
  },
  [ASSET_KIND_MAP.SERVER]: {
    main: '#9747FF',
    light: '#6D56A0',
  },
  [ASSET_KIND_MAP.HOST]: {
    main: '#8BA83B',
    light: '#70884C',
  },
}
