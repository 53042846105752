/* eslint-disable import/no-extraneous-dependencies */
import { useNavigate, useParams } from 'react-router-dom'
import { List, ListItem, Typography } from '@mui/material'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import Button from 'ui-v2/src/components/ui/inputs/button'
import UpgradeCloudConnectorModal from './upgrade-cloud-connector-modal'

const UpgradeCloudConnector = () => {
  const { action } = useParams()
  const isDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.UPGRADE_CLOUD_CONNECTOR
  const navigate = useNavigate()

  const handleCloseDrawer = () => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
  }

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <DrawerContent>
        <List sx={{ paddingX: 0 }}>
          <ListItem
            sx={{
              paddingX: 2.5,
              paddingRight: '70px',
            }}
            secondaryAction={
              <Button variant="contained" onClick={handleCloseDrawer}>
                Close
              </Button>
            }
          >
            <Typography fontWeight={700} fontSize={22}>
              Upgrade Elastio CloudFormation Template
            </Typography>
          </ListItem>
        </List>
        {isDrawerOpen && <UpgradeCloudConnectorModal />}
      </DrawerContent>
    </Drawer>
  )
}

export default UpgradeCloudConnector
