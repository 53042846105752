import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { PolicyListTableRow } from 'ui-v2/src/lib/models/settings/policies/policies'
import dayjs from 'dayjs'
import PolicyAction from './policy-action'

export const getPolicyListColumns = (): Array<Column<PolicyListTableRow>> => [
  {
    key: 'id',
    header: 'Name',
    render: (row) => row.id,
  },
  {
    key: 'status',
    header: 'Status',
    render: (row) => row.status,
  },
  {
    key: 'lastRun',
    header: 'Last Run',
    render: (row) => (
      <>
        {row.lastRun
          ? dayjs(row.lastRun).format('MM/DD/YYYY hh:mm A')
          : 'Never'}
      </>
    ),
  },
  {
    key: 'nextRun',
    header: 'Next Run',
    render: (row) => (
      <>
        {row.nextRun === 'Paused'
          ? 'Never'
          : row.nextRun?.startsWith('Next run: ')
            ? ` Next run: ${dayjs(row.nextRun).format('MM/DD/YYYY hh:mm A')}`
            : row.nextRun?.startsWith('Scheduled: ')
              ? `Scheduled: ${dayjs(row.nextRun).format('MM/DD/YYYY hh:mm A')}`
              : row.nextRun}
      </>
    ),
  },
  {
    key: 'action',
    header: '',
    render: (row) => <PolicyAction row={row} />,
  },
]
