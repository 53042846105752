import { useInfiniteQuery } from '@tanstack/react-query'
import { ListScanJobParams } from 'ui-v2/src/lib/models/backups'
import { useScanJobService } from 'ui-v2/src/hooks/rest-service/use-scan-job-service'

export function useListScanLogInfiniteQuery(
  assetId: string | undefined,
  params: ListScanJobParams
) {
  const {
    listScanLog,
    isReady,
    isLoading: isClientLoading,
  } = useScanJobService()

  return useInfiniteQuery({
    queryKey: ['assets', assetId, 'scans', params],
    queryFn: async ({ pageParam }: { pageParam: string | undefined }) => {
      if (!assetId) {
        throw new Error('assetId is required for scan log')
      }

      const queryParams = pageParam
        ? {
            ...params,
            pageToken: pageParam,
          }
        : params

      return listScanLog(assetId, queryParams)
    },
    getNextPageParam: (lastPage) =>
      lastPage.pagination.next_page_token || undefined,
    initialPageParam: undefined,
    enabled: Boolean(assetId) && isReady && !isClientLoading,
  })
}
