import { useQuery } from '@tanstack/react-query'
import { useRecoveryPointsService } from '../../rest-service'
import { AssetThreatsParams } from 'ui-v2/src/lib/models/backups'

export function useListAssetThreatsForRecoveryPointQuery(
  assetId: string | undefined,
  recoveryPointId: string | undefined,
  volumeKind: string | undefined,
  params: AssetThreatsParams
) {
  const {
    listAssetThreatsForRecoveryPoint,
    listAssetItemThreatsForRecoveryPoint,
    isReady,
    isLoading: isClientLoading,
  } = useRecoveryPointsService()

  return useQuery({
    queryKey: [
      'assets',
      assetId,
      'recovery-points',
      recoveryPointId,
      'assets',
      assetId,
      'threats',
      params,
    ],
    queryFn: async () => {
      if (!assetId) {
        throw new Error('assetId is required')
      }
      if (!recoveryPointId) {
        throw new Error('recoveryPointId is required')
      }

      if (volumeKind === 'asset_item') {
        return listAssetItemThreatsForRecoveryPoint(
          assetId,
          recoveryPointId,
          params
        )
      }

      return listAssetThreatsForRecoveryPoint(assetId, recoveryPointId, params)
    },
    enabled:
      Boolean(assetId) &&
      Boolean(recoveryPointId) &&
      isReady &&
      !isClientLoading,
  })
}
