import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { getEc2Columns } from './column-config'
import { useSearchParams } from 'react-router-dom'
import { useAssetsEc2Filters } from 'ui-v2/src/hooks/filters/assets'
import AssetFilters from '../filters'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useNewAssetsSearchQuery } from 'ui-v2/src/hooks/queries/search'
import { useSortParams } from 'ui-v2/src/hooks/sort-params'
import { convertEc2SortConfigsToProto } from './sort-mapping'

const AssetsEc2 = () => {
  const { sortConfigs } = useSortParams()

  const [searchParams] = useSearchParams()

  const { filters, setPageSize, setPageToken } = useAssetsEc2Filters()

  const { data: assetsData, isLoading: isAssetsDataLoading } =
    useNewAssetsSearchQuery({
      ec2: {
        ...filters,
        ...convertEc2SortConfigsToProto(sortConfigs),
      },
      pageToken: filters.pageToken,
      pageSize: filters.pageSize,
    })

  const columns = useMemo(getEc2Columns, [])

  return (
    <Stack spacing={1}>
      <AssetFilters
        showSearch
        filters={[
          ASSET_FILTERS_MAP.HEALTH,
          ASSET_FILTERS_MAP.ASSET_NAME,
          ASSET_FILTERS_MAP.ASSET_ID,
          ASSET_FILTERS_MAP.ACCOUNT_ID,
          ASSET_FILTERS_MAP.REGION,
          ASSET_FILTERS_MAP.INSTANCE_STATE,
          ASSET_FILTERS_MAP.LAST_SCAN,
          ASSET_FILTERS_MAP.POLICY_COVERAGE,
        ]}
      />
      <CursorTable
        key={searchParams.toString()}
        data={assetsData?.instancesList}
        columns={columns}
        isLoading={isAssetsDataLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsData?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default AssetsEc2
