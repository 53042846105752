import React from 'react'
import { Box, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useAlertsListStore } from './store/alerts-list-store'
import { useAlertsActions } from './use-alerts-actions'

const buttonStyle = {
  textTransform: 'none',
  height: 24,
  fontSize: 12,
  padding: '5px 8px',
}

const AlertsAction = () => {
  const { selectedRows, isLoading: isLoadingTableData } = useAlertsListStore()
  const rulesIds = selectedRows.map((rule) => rule.id)
  const { handleEnableRule, handleDisableRule, handleDeleteRule, isLoading } =
    useAlertsActions()

  const isDisable = !selectedRows.length || isLoading || isLoadingTableData

  const handleEnable = () => {
    handleEnableRule(rulesIds)
  }

  const handleDisable = () => {
    handleDisableRule(rulesIds)
  }

  const handleDelete = () => {
    handleDeleteRule(rulesIds)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
      }}
    >
      <Button
        variant="contained"
        component={Link}
        to={`/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.ALERTS}/${GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS.ALERTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_ALERT_RULE}`}
        color="primary"
        sx={{
          ...buttonStyle,
          textTransform: 'capitalize',
        }}
      >
        Create Rule
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CheckCircleOutlineIcon />}
        disabled={isDisable}
        onClick={handleEnable}
        sx={buttonStyle}
      >
        Enable
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<HighlightOffIcon />}
        disabled={isDisable}
        onClick={handleDisable}
        sx={buttonStyle}
      >
        Disable
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DeleteOutlineIcon />}
        disabled={isDisable}
        onClick={handleDelete}
        sx={buttonStyle}
      >
        Delete
      </Button>
    </Box>
  )
}

export default AlertsAction
