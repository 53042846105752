import React from 'react'
import FilesTable from '../files-table'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import { useSortParams } from 'ui-v2/src/hooks/sort-params'
import { transformSortingConfig } from '../common/util'
import { validateBooleanString } from '../../utils'
import {
  useGetScanJobByIdQuery,
  useListAssetThreatsForScanJobQuery,
} from 'ui-v2/src/hooks/queries/scan-log'

const FilesTableContainer = () => {
  const { sortConfigs } = useSortParams()
  const [searchParams] = useSearchParams()
  const selectedFileList = validateBooleanString(
    searchParams.get('suppressed') || ''
  )
  const selectedInsiderThreatsList = searchParams.get('kind') || ''

  const {
    assetId,
    recoveryPointId: scanJobId,
    scanJobId: recoveryPointScanJobId,
    volumeId,
  } = useParams<{
    assetId: string | undefined
    recoveryPointId: string | undefined
    scanJobId: string | undefined
    volumeId: string | undefined
  }>()

  const {
    data: assetThreatsForScanJobData,
    status: assetThreatsForScanJobQueryStatus,
  } = useListAssetThreatsForScanJobQuery(
    volumeId ?? assetId,
    recoveryPointScanJobId ?? scanJobId,
    {
      sorting: transformSortingConfig(sortConfigs),
      ...(selectedFileList && { suppressed: selectedFileList }),
      ...(selectedInsiderThreatsList && { kind: selectedInsiderThreatsList }),
    }
  )

  const { data: scanJobByIdData, status: scanJobByIdQueryStatus } =
    useGetScanJobByIdQuery(assetId, recoveryPointScanJobId ?? scanJobId)

  if (
    assetThreatsForScanJobQueryStatus === 'pending' ||
    scanJobByIdQueryStatus === 'pending'
  ) {
    return (
      <Box
        sx={{
          height: '100%',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={24} sx={{ color: 'grey.500' }} />
      </Box>
    )
  }

  if (
    assetThreatsForScanJobQueryStatus === 'error' ||
    scanJobByIdQueryStatus === 'error'
  ) {
    return (
      <ErrorDisplay message="Something went wrong while retrieving threats data, please try again later." />
    )
  }

  return (
    <FilesTable
      data={assetThreatsForScanJobData}
      health={scanJobByIdData.health}
      scanJob={scanJobByIdData}
    />
  )
}

export default FilesTableContainer
