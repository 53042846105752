import React from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { FileCopy } from '@mui/icons-material'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { useToast } from 'ui-v2/src/hooks/toast'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'

type LabeledValueProps = {
  label: string
  value: string | React.ReactNode
  showClipboardCopy?: boolean
  maxLength?: number
}

const LabeledValue: React.FC<LabeledValueProps> = ({
  label,
  value,
  showClipboardCopy = false,
  maxLength = 22,
}) => {
  const toast = useToast()

  if (!label) {
    return null
  }

  const clickHandler = () => {
    if (typeof value === 'string') {
      copyToClipboard(value)
      toast.success('Copied to clipboard')
    }
  }

  return (
    <Stack py={0.25}>
      <Box display={'flex'} alignItems={'center'} gap={0.5}>
        <Typography fontWeight={400} variant="body2" color="text.secondary">
          {label}
        </Typography>
        {showClipboardCopy && typeof value === 'string' && !!value ? (
          <IconButton
            onClick={clickHandler}
            size="small"
            sx={{ padding: '3px' }}
          >
            <FileCopy
              sx={{
                fontSize: '16px',
                color: 'text.secondary',
              }}
            />
          </IconButton>
        ) : null}
      </Box>
      {typeof value === 'string' ? (
        value.length <= maxLength ? (
          <Typography fontWeight={600} variant="body2">
            {value || '-'}
          </Typography>
        ) : (
          <LongTextTooltip
            text={value || '-'}
            placement={'bottom-start'}
            fontSize="14px"
            fontWeight={600}
            maxLength={maxLength}
          />
        )
      ) : (
        value
      )}
    </Stack>
  )
}

export default LabeledValue
