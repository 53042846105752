export enum AccountStatusConstant {
  UNDEFINED = -1,
  CONNECTED,
  DISCONNECTED,
  MIXED,
  UPGRADE_REQUIRED,
}

export interface AssetsAccountTableData {
  id: string
  awsAccountId: string
  stackRegion: Set<string>
  updatedAt?: Date
  createdAt?: Date
}

export interface ActiveDeploymentTableData {
  id: string
  awsAccount: string
  accountAlias: string
  accountDescription: string
  awsRegions: Array<string>
  updatedAt: Date
  assetsAccount: Array<AssetsAccountTableData> | undefined
  statuses: Array<AccountStatusConstant>
  status: AccountStatusConstant
  messages: Array<string>
  redStackIds: Array<string>
  regionWithRedStackId: Map<string, string>
}
