/* eslint-disable import/no-extraneous-dependencies */
import {
  Backup,
  BackupPoliciesArray,
  EBS,
  Nullable,
  Tag,
  Threat,
} from 'blues-corejs/dist'
import { LastScanState, PolicyCoverageState } from '../common'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { EBS_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { ListEbsRequest } from 'ui-v2/src/lib/grpc'
import { HealthState } from 'blue-stack-libs/api-grpc-libs/js/api/search/filters/common_filter_pb'

export interface ListEbsFilters {
  accountIdsList?: Array<string>
  assetIdList?: Array<string>
  volumeStatesList?: Array<ListEbsRequest.Initial.Filter.VolumeState>
  tagsList?: Array<Tag>
  regionsList?: Array<string>
  protectionPolicyCoverageStatesList?: Array<PolicyCoverageState>
  search?: string
  pageSize?: number
  pageToken?: string
  health?: Array<HealthState>
  lastScan?: Array<LastScanState>
}

export interface AssetsEbsAttrs {
  volumes: Array<EBS>
  lastBackups: Map<string, Backup>
  scans: Array<Scan>
  threats: Array<Threat>
}

interface BaseEbsAttrs {
  id: string
  name: string
  awsId: string
  isUnhealthy: boolean
  awsAccountId: string
  awsRegion: string
  state: (typeof EBS_STATE_MAP)[keyof typeof EBS_STATE_MAP]
}

export abstract class BaseEbs {
  readonly #id: string

  readonly #name: string

  readonly #awsId: string

  readonly #isUnhealthy: boolean

  readonly #awsRegion: string

  readonly #awsAccountId: string

  readonly #state: (typeof EBS_STATE_MAP)[keyof typeof EBS_STATE_MAP]

  constructor(parameters: BaseEbsAttrs) {
    const { id, name, awsId, isUnhealthy, awsRegion, awsAccountId, state } =
      parameters

    this.#id = id
    this.#name = name
    this.#awsId = awsId
    this.#isUnhealthy = isUnhealthy
    this.#awsRegion = awsRegion
    this.#awsAccountId = awsAccountId
    this.#state = state
  }

  get id(): string {
    return this.#id
  }

  get name(): string {
    return this.#name
  }

  get awsId(): string {
    return this.#awsId
  }

  get isUnhealthy(): boolean {
    return this.#isUnhealthy
  }

  get awsAccountId(): string {
    return this.#awsAccountId
  }

  get awsRegion(): string {
    return this.#awsRegion
  }

  get state(): (typeof EBS_STATE_MAP)[keyof typeof EBS_STATE_MAP] {
    return this.#state
  }
}

export class EbsTableRow extends BaseEbs {
  readonly #lastScanDate: Nullable<Date>

  readonly #lastBackupDate: Nullable<Date>

  readonly #backupPolicies: BackupPoliciesArray

  constructor(parameters: EbsTableRowAttrs) {
    super(parameters)
    this.#lastScanDate = parameters.lastScanDate
    this.#lastBackupDate = parameters.lastBackupDate
    this.#backupPolicies = parameters.backupPolicies
  }

  get lastScanDate(): Nullable<Date> {
    return this.#lastScanDate
  }

  get lastBackupDate(): Nullable<Date> {
    return this.#lastBackupDate
  }

  get backupPolicies(): BackupPoliciesArray {
    return this.#backupPolicies
  }
}

export class EbsDrawer extends BaseEbs {
  readonly #lastScan: Nullable<Scan>

  readonly #lastBackup: Nullable<Backup>

  readonly assetKind: string = 'EBS Volume'

  constructor(parameters: EbsDrawerAttrs) {
    const { lastScan, lastBackup, ...baseParams } = parameters
    super(baseParams)

    this.#lastScan = lastScan
    this.#lastBackup = lastBackup
  }

  get lastScan(): Nullable<Scan> {
    return this.#lastScan
  }

  get lastBackup(): Nullable<Backup> {
    return this.#lastBackup
  }
}

interface EbsTableRowAttrs extends BaseEbsAttrs {
  lastScanDate: Nullable<Date>
  lastBackupDate: Nullable<Date>
  backupPolicies: BackupPoliciesArray
}

interface EbsDrawerAttrs extends BaseEbsAttrs {
  lastScan: Nullable<Scan>
  lastBackup: Nullable<Backup>
}
