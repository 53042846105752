import { useRestClient } from 'ui-v2/src/hooks/rest-client'
import {
  ThreatsTrendPayload,
  ThreatsTrendResponse,
} from 'ui-v2/src/lib/models/anomalies/threats-trend'

export const useThreatsTrendHistoryService = () => {
  const { isReady, isLoading, post } = useRestClient()

  const getThreatTrendsHistory = async (payload: ThreatsTrendPayload) => {
    if (!isReady) {
      throw new Error('REST client is not initialized')
    }

    const url = 'api/v1/trends-history'
    return post<ThreatsTrendResponse>(url, {
      start_at: {
        seconds: payload.startAt,
        nanos: payload.startAt * 1_000_000_000,
      },
      end_at: {
        seconds: payload.endAt,
        nanos: payload.endAt * 1_000_000_000,
      },
      time_granularity: payload.timeGranularity,
      asset_ids: payload.assetIdsList,
    })
  }

  return {
    getThreatTrendsHistory,
    isReady,
    isLoading,
  }
}
