import { Navigate, useSearchParams } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

type Tab = '0' | '1' | '2' | '3'

const TAB_SEARCH_PARAM_MAP: Record<
  Tab,
  (typeof GENERAL_SETTINGS_ROUTE_SEGMENTS)[keyof typeof GENERAL_SETTINGS_ROUTE_SEGMENTS]
> = {
  '0': GENERAL_SETTINGS_ROUTE_SEGMENTS.ACCOUNT_PROFILE,
  '1': GENERAL_SETTINGS_ROUTE_SEGMENTS.ACCOUNT_SECURITY,
  '2': GENERAL_SETTINGS_ROUTE_SEGMENTS.API_ACCESS,
  '3': GENERAL_SETTINGS_ROUTE_SEGMENTS.ALERTS,
}

const RedirectToAccountSettingsPath = () => {
  const [searchParams] = useSearchParams()

  const tab = (searchParams.get('t') ?? '0') as Tab

  const redirectTab =
    TAB_SEARCH_PARAM_MAP[tab] ?? GENERAL_SETTINGS_ROUTE_SEGMENTS.ACCOUNT_PROFILE

  const newPath = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.GENERAL}/${redirectTab}`

  return <Navigate to={newPath} replace />
}

export default RedirectToAccountSettingsPath
