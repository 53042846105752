import { Box, Card, Stack } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import Marquee from 'react-fast-marquee'
import Typography from '@mui/material/Typography'

const NewsMarquee: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={(theme) => ({
        background:
          theme.palette.mode === 'dark' ? 'rgba(21, 38, 53, 1)' : '#ffffff',
        boxShadow:
          theme.palette.mode === 'dark'
            ? 'none'
            : '0px 16px 20px 0px #A7ACD32B',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.2,
        borderRadius: '8px',
      })}
    >
      <Typography
        marginLeft={'16px'}
        mt={2}
        fontWeight={700}
        fontSize={'16px'}
        sx={(theme) => ({
          color: theme.palette.mode === 'dark' ? '#B2B5C1' : '#4e5665',
        })}
      >
        Latest Ransomware Threats Covered By Elastio
      </Typography>
      <Card
        elevation={0}
        sx={(theme) => ({
          borderRadius: '8px !important',
          background:
            theme.palette.mode === 'dark' ? 'rgba(21, 38, 53, 1)' : '#ffffff',
          boxShadow:
            theme.palette.mode === 'dark'
              ? 'none'
              : '0px 16px 20px 0px #A7ACD32B',
        })}
        className="news-feed-card"
      >
        <Marquee pauseOnHover speed={50} autoFill play>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            columnGap={1.5}
            marginRight={1.5}
          >
            {children}
          </Stack>
        </Marquee>
      </Card>
    </Box>
  )
}

export default React.memo(NewsMarquee)
