import { Box, Typography } from '@mui/material'
import React from 'react'
import FolderIcon from 'ui-v2/src/assets/icons/folder-icon'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'

type ScanStatusProps = {
  status: string
  isHealthy: boolean
  atRisk: boolean
}

const ScanStatus: React.FC<ScanStatusProps> = ({
  status,
  isHealthy,
  atRisk,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <FolderIcon isHealthy={isHealthy} atRisk={atRisk} />
      <Typography
        gutterBottom
        sx={(theme) => ({
          marginLeft: '4px',
          marginBottom: 0,
          color: theme.palette.mode === ThemeMode.DARK ? '#B2B5C1' : '#999CAA',
        })}
      >
        {status}
      </Typography>
    </Box>
  )
}

export default ScanStatus
