import LangHelper from 'ui-v2/src/lib/helpers/lang.helper'
import { RecoveryPointScan } from 'ui-v2/src/lib/models/backups'
import { useParams } from 'react-router-dom'
import { alpha, Box, SvgIconProps, Typography } from '@mui/material'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { formatTimeAgo } from '../../utils'
import { formatDate } from 'ui-v2/src/lib/helpers/time.helper'
import { useEffect, useState } from 'react'
import StartBackupScan from './start-backup-scan'
import { ThemeMode } from 'ui-v2/src/components/common/theme-switcher'

type RecoveryPointScansProps = {
  recoveryPointId: string
  scans: Array<RecoveryPointScan> | undefined
  navigateToRPScan: (scanJobId: string | undefined) => void
}

const RecoveryPointScans: React.FC<RecoveryPointScansProps> = ({
  recoveryPointId,
  scans,
  navigateToRPScan,
}) => {
  const { recoveryPointId: paramsRecoveryPointId, scanJobId: paramsScanJobId } =
    useParams<{
      recoveryPointId: string | undefined
      scanJobId: string | undefined
    }>()

  const [isScansExpanded, setIsScansExpanded] = useState(
    !!paramsScanJobId && paramsRecoveryPointId === recoveryPointId
  )

  const toggleIsScansExpanded = () => {
    if (!scans?.length) {
      return
    }

    setIsScansExpanded((prevIsScansExpanded) => {
      if (prevIsScansExpanded) {
        return false
      } else {
        navigateToRPScan(scans?.[0]?.job_id)
        return true
      }
    })
  }

  const scanCount = scans?.length ?? 0
  const iconColor = scanCount === 0 ? 'disabled' : 'primary'
  const iconProps: SvgIconProps = {
    color: iconColor,
    fontSize: 'small',
    sx: { marginRight: 0.5 },
  }

  useEffect(() => {
    if (
      !isScansExpanded &&
      !!paramsScanJobId &&
      paramsRecoveryPointId === recoveryPointId
    ) {
      setIsScansExpanded(true)
    }
  }, [paramsScanJobId, paramsRecoveryPointId, recoveryPointId])

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          onClick={(e) => {
            e.stopPropagation()
            toggleIsScansExpanded()
          }}
        >
          {isScansExpanded ? (
            <ExpandMore {...iconProps} />
          ) : (
            <ChevronRight {...iconProps} />
          )}
          <Typography variant="body2" fontWeight={600}>
            {scanCount} {LangHelper.pluralizeEn('scan', scanCount)}
          </Typography>
        </Box>
        <StartBackupScan />
      </Box>
      {isScansExpanded
        ? scans?.map((scan) => (
            <Box
              key={scan.job_id}
              display={'flex'}
              gap={0.5}
              py={0.5}
              pl={3}
              onClick={(e) => {
                e.stopPropagation()
                navigateToRPScan(scan.job_id)
              }}
              sx={(theme) => {
                const isScanJobActive = paramsScanJobId === scan.job_id

                const activeBackgroundColor =
                  theme.palette.mode === ThemeMode.DARK
                    ? '#0076AC'
                    : theme.palette.background.default
                return {
                  background: isScanJobActive
                    ? activeBackgroundColor
                    : 'transparent',

                  '&:hover': {
                    background: isScanJobActive
                      ? activeBackgroundColor
                      : alpha(activeBackgroundColor, 0.3),
                  },
                }
              }}
            >
              <Typography variant="body2" fontWeight={600} flexShrink={0}>
                {formatTimeAgo(scan.timestamp)} ago |
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                noWrap
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {formatDate(scan.timestamp, 'hh:mm A, MM/DD/YY')}
              </Typography>
            </Box>
          ))
        : null}
    </>
  )
}

export default RecoveryPointScans
