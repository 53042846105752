import dayjs from 'dayjs'
import { getWebhooksDateRangeFilters } from './manage-webhook-drawer/data'
import { DefinedDateRangeTuple } from 'ui-v2/src/lib/engine-types'

export const generateDateRangeTuples = (selectedValue: string) => {
  const filters = getWebhooksDateRangeFilters()
  const selectedOption = filters.options.find(
    (option) => option.name === selectedValue
  )

  if (!selectedOption) {
    return []
  }

  const now = dayjs()
  const totalHours = selectedOption.extraValue
  const numTuples = selectedOption.supplementalValue
  const tupleSize = totalHours / numTuples

  const startTime = now.subtract(totalHours, 'hour')
  const tuples: Array<DefinedDateRangeTuple> = []

  for (let i = 0; i < numTuples; i++) {
    const tupleStart = startTime.add(i * tupleSize, 'hour')
    const tupleEnd = startTime.add((i + 1) * tupleSize, 'hour')
    tuples.push([tupleStart.valueOf(), tupleEnd.valueOf()])
  }

  return tuples
}

export const getReadableTuples = (tuples: Array<[number, number]>) => {
  return tuples.map(([start, end]) => ({
    start: dayjs(start).format(' MM/DD/YYYY hh:mm A'),
    end: dayjs(end).format('MM/DD/YYYY hh:mm A'),
  }))
}
