import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'

const ThreatWidgetLoading = () => {
  return (
    <Stack spacing={2} justifyContent={'space-between'} height={'100%'}>
      <Stack
        spacing={1}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Skeleton variant="text" width={60} height={32} />
          <Skeleton variant="text" width={100} height={20} />
        </Stack>
        <Skeleton variant="text" width={60} height={20} />
      </Stack>
      <Box height={'100%'}>
        <Skeleton
          variant="rectangular"
          sx={{
            minHeight: 100,
            height: '100%',
          }}
        />
      </Box>
    </Stack>
  )
}

export default ThreatWidgetLoading
