import React, { useState } from 'react'
import { Button, useTheme } from '@mui/material'
import ThreatSuppressDialog from './threat-suppress-dialog'
import { AssetThreat } from 'ui-v2/src/lib/models/backups'
import Typography from '@mui/material/Typography'

type ThreatSuppressProps = {
  row: AssetThreat
}

const ThreatSuppress: React.FC<ThreatSuppressProps> = ({ row }) => {
  const [isSuppressDialogOpen, setIsSuppressDialogOpen] =
    useState<boolean>(false)

  const theme = useTheme()
  const linkColor = theme.palette.mode === 'dark' ? '#32B6F3' : '#0084C1'
  const suppressedColor = theme.palette.mode === 'dark' ? '#D3D8DE' : '#4E5665'

  if (row.suppressed) {
    return (
      <Typography color={suppressedColor} fontSize={'14px'}>
        Suppressed
      </Typography>
    )
  }

  return (
    <>
      <Button
        variant="text"
        sx={{
          color: linkColor,
          fontSize: 14,
          fontWeight: 400,
          textTransform: 'capitalize',
          paddingX: 0,

          '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
          },
        }}
        onClick={() => {
          setIsSuppressDialogOpen(true)
        }}
      >
        Suppress Until
      </Button>
      <ThreatSuppressDialog
        open={isSuppressDialogOpen}
        row={row}
        handleClose={() => setIsSuppressDialogOpen(false)}
      />
    </>
  )
}

export default ThreatSuppress
