/* eslint-disable import/no-extraneous-dependencies */
import { NotificationRuleGRPC } from '@lib/clients/grpc-imports'
import {
  AllKinds,
  EmailChannel,
  ExactEvents,
  NotificationChannels,
  NotificationRule,
  RuleAwsAccountRegion,
  RuleCriteria,
  RuleMode,
  RuleNotify,
  RuleOvaAccountProviders,
  RuleOvaBackupProvider,
  RuleStatus,
  RuleSuppressUntil,
  TenantMemberEmailChannel,
  WebhookChannel,
} from 'blues-corejs/dist/models/notifications/rule'
import TimeHelper from '../../helpers/time.helper'
import { OvaBackupProvider } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/notification_rules/criteria_pb'
import { TagsOperator } from 'blues-corejs/dist/models/notifications/rule/criteria'

const convertMode = (rule: NotificationRuleGRPC.AsObject): RuleMode => {
  const suppressUntil = rule?.mode?.suppressUntil
  if (rule.mode?.notify) {
    return new RuleNotify()
  }

  const suppressDate = TimeHelper.secondsToDate(suppressUntil?.until?.seconds)
  return new RuleSuppressUntil(suppressDate, suppressUntil?.reason || '')
}

const convertBackupProvider = (
  provider: OvaBackupProvider
): RuleOvaBackupProvider => {
  const mapping: Record<OvaBackupProvider, RuleOvaBackupProvider> = {
    [OvaBackupProvider.OVA_BACKUP_PROVIDER_VEEAM]:
      RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_VEEAM,
    [OvaBackupProvider.OVA_BACKUP_PROVIDER_COMMVAULT]:
      RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_COMMVAULT,
    [OvaBackupProvider.OVA_BACKUP_PROVIDER_RUBRIK]:
      RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_RUBRIK,
    [OvaBackupProvider.OVA_BACKUP_PROVIDER_COHESITY]:
      RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_COHESITY,
    [OvaBackupProvider.OVA_BACKUP_PROVIDER_VERITAS_NET_BACKUP]:
      RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_VERITAS_NET_BACKUP,
    [OvaBackupProvider.OVA_BACKUP_PROVIDER_AWS_BACKUP_VMWARE]:
      RuleOvaBackupProvider.OVA_BACKUP_PROVIDER_AWS_BACKUP_VMWARE,
  }

  return mapping[provider]
}

const convertTagsOperator = (operator?: number): TagsOperator => {
  switch (operator) {
    case 0:
      return TagsOperator.AND
    case 1:
      return TagsOperator.OR
    default:
      return TagsOperator.AND
  }
}

const convertCriteria = (rule: NotificationRuleGRPC.AsObject): RuleCriteria => {
  const criteria = rule.criteria
  const assetIds = criteria?.assetIdsList || []
  const eventKinds = criteria?.eventKinds
  const kindList = eventKinds?.exactEvents?.kindsList || []
  const ovaAccountProviders = criteria?.ovaAccountProvidersList || []
  const awsAccountRegions = criteria?.awsAccountRegionsList || []
  const tags = criteria?.tags
  const convertedOvaAccountProviders: Array<RuleOvaAccountProviders> =
    ovaAccountProviders.map((provider) => ({
      accountId: provider.accountId,
      provider: convertBackupProvider(provider.provider),
    }))
  const convertedAwsAccountRegions: Array<RuleAwsAccountRegion> =
    awsAccountRegions.map((account) => ({
      region: account.region,
      accountId: account.accountId,
    }))

  const eventKindstransform = eventKinds?.allEvents
    ? new AllKinds()
    : new ExactEvents(kindList)

  const tagsOperator = convertTagsOperator(tags?.operator)

  return {
    assetIds: assetIds || [],
    eventKinds: eventKindstransform,
    ovaAccountProviders: convertedOvaAccountProviders,
    awsAccountRegions: convertedAwsAccountRegions,
    tags: {
      operator: tagsOperator,
      tags: tags?.tagsList || [],
    },
  }
}

const convertChannels = (
  rule: NotificationRuleGRPC.AsObject
): NotificationChannels => {
  const channels = rule?.channels
  const emails = channels?.emailsList || []
  const convertedEmails: Array<EmailChannel> = emails.map(({ email }) => ({
    email,
  }))
  const webHooks = channels?.webhooksList || []
  const convertedWebHooks: Array<WebhookChannel> = webHooks.map(
    ({ webhookId }) => ({
      webhookId,
    })
  )
  const tenantMembers = channels?.tenantMemberEmailsList || []
  const convertedTenantMembers: Array<TenantMemberEmailChannel> =
    tenantMembers.map(({ customerId }) => ({
      customer_id: customerId,
    }))

  return {
    emails: convertedEmails,
    webhooks: convertedWebHooks,
    tenantMemberEmails: convertedTenantMembers,
    slack: channels?.slack || false,
  }
}

const convertStatus = (rule: NotificationRuleGRPC.AsObject): RuleStatus => {
  const status = rule.status
  if (status?.enabled !== undefined) {
    return RuleStatus.enabled
  }

  return RuleStatus.disabled
}

export const convertRules = (
  listResponse: Array<NotificationRuleGRPC.AsObject>
): Array<NotificationRule> => {
  return listResponse.map((rule) => {
    return new NotificationRule({
      id: rule.id,
      name: rule.name,
      description: rule.description,
      mode: convertMode(rule),
      status: convertStatus(rule),
      criteria: convertCriteria(rule),
      channels: convertChannels(rule),
      updatedAt: TimeHelper.secondsToDate(rule?.updatedAt?.seconds),
      createdAt: TimeHelper.secondsToDate(rule?.createdAt?.seconds),
    })
  })
}
