import React from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import CommonBreadcrumbs from './breadcrumbs'
import useGetBreadCrumbsRoutes, { BreadcrumbItem } from './breadcrumbs-config'
import { useAssetDetails } from 'ui-v2/src/views/asset-details/widgets/common/use-asset-details'

const BreadcrumbsContainer: React.FC = () => {
  const { pathname } = useLocation()

  const {
    drilldownAsset,
    parentAsset,
    isAssetsDataLoading,
    isAssetsDataError,
  } = useAssetDetails()

  let breadcrumbData: Array<BreadcrumbItem> = []
  const breadcrumbRoutes = useGetBreadCrumbsRoutes({
    parentAsset,
    drilldownAsset,
  })

  for (const routeConfig of breadcrumbRoutes) {
    const match = matchPath(
      {
        path: routeConfig.path,
        end: true,
      },
      pathname
    )
    if (match && match.params) {
      breadcrumbData = routeConfig.getBreadcrumbs(
        match.params as { [key: string]: string }
      )
      break
    }
  }

  if (isAssetsDataLoading || isAssetsDataError) {
    return null
  }

  return <CommonBreadcrumbs data={breadcrumbData} />
}

export default BreadcrumbsContainer
