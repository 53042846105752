import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid2'
import { FormControl, Typography } from '@mui/material'

import { Controller, useFormContext } from 'react-hook-form'
import { reportOptions } from 'ui-v2/src/lib/constants/reports.constant'
import {
  CustomScheduleState,
  FormOptionValueSlug,
  FrequencyType,
  Option,
  ReportForm,
} from 'ui-v2/src/lib/models/reports'

import AwsForm from './aws-form'
import BackupConnectorForm from './backup-connector-form'
import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'

import CustomScheduleModal from './custom-schedule-modal'
import formatCustomSchedule from './format-custom-schedule'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'

const ScheduleForm = ({
  selectReportType,
  reportId,
  setReportTypeLabel,
}: ScheduleFormProps) => {
  const navigate = useNavigate()

  const [isCustomScheduleModalOpen, setIsCustomScheduleModalOpen] =
    useState<boolean>(false)

  const { control, watch, getValues, setValue, reset, trigger } =
    useFormContext<ReportForm>()

  const selectedReportValue = watch('reportType')

  const customSchedule = watch('customSchedule')
  const initialFormattedValue = customSchedule
    ? formatCustomSchedule(customSchedule)
    : ''

  const [formattedValue, setFormattedValue] = useState(
    initialFormattedValue || ''
  )

  const selectedReportOption = useMemo(
    () => reportOptions.find((option) => option.value === selectedReportValue),
    [selectedReportValue]
  )

  const selectedFormVariant = selectedReportOption?.type
  const availableFormats = selectedReportOption?.formats ?? []

  const handleReportTypeChange = (value: string) => {
    const reportOption = reportOptions.find((option) => option.value === value)
    if (reportOption?.slug) {
      navigate(
        `/reports/schedules/form/${FormOptionValueSlug.SCHEDULE}/${reportOption.slug}`
      )
    }
  }

  useEffect(() => {
    if (selectReportType) {
      setValue('reportType', selectReportType?.value?.toString())
    }
  }, [selectReportType])

  useEffect(() => {
    if (selectedReportOption) {
      setReportTypeLabel(selectedReportOption.label)
    }
  }, [selectedReportOption])

  useEffect(() => {
    if (availableFormats.length === 1) {
      setValue('format', [availableFormats[0] ?? ''])
    }
  }, [availableFormats])

  const handleOpenCustomScheduleModal = () => {
    setIsCustomScheduleModalOpen(true)
  }

  const handleCloseCustomScheduleModal = () => {
    const frequncey = getValues('frequency')
    if (
      frequncey === FrequencyType.CUSTOM &&
      customSchedule &&
      Object.keys(customSchedule).length > 0
    ) {
      setIsCustomScheduleModalOpen(false)
    } else {
      setValue('frequency', FrequencyType.DAILY)
      setIsCustomScheduleModalOpen(false)
    }
  }

  const handleSaveCustomSchedule = (
    customScheduleState: CustomScheduleState
  ) => {
    setValue('customSchedule', customScheduleState)
    setFormattedValue(formatCustomSchedule(customScheduleState))
    setIsCustomScheduleModalOpen(false)
  }

  return (
    <Grid container spacing={2}>
      <Grid
        size={{
          xs: 12,
          sm: 10,
          md: 9,
          lg: 8,
        }}
      >
        <FormControl fullWidth size="small">
          <Typography variant="body1" mb={1}>
            Report Type
          </Typography>
          <Controller
            control={control}
            name="reportType"
            render={({ field }) => (
              <BasicSelect
                value={field.value}
                onChange={(value) => {
                  field.onChange(value)
                  handleReportTypeChange(value as string)
                  reset()
                }}
                options={reportOptions.map(({ label, value }) => ({
                  label,
                  value,
                }))}
                size="small"
                placeholder="Select a Report"
                disabled={!!reportId}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 10,
          md: 9,
          lg: 8,
        }}
      >
        <FormControl fullWidth>
          <Typography variant="body1" mb={1}>
            Name
          </Typography>
          <Controller
            control={control}
            rules={{ required: 'Name is required' }}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value)
                  trigger('name')
                }}
                placeholder="Name"
                size="small"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid size={12}>
        <FormControl fullWidth>
          <Typography variant="body1" mb={1}>
            Description (Optional)
          </Typography>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextField placeholder="Description" size="small" {...field} />
            )}
          />
        </FormControl>
      </Grid>

      {selectedFormVariant === 'AWS' && (
        <AwsForm
          availableFormats={availableFormats}
          formattedValue={formattedValue}
          isCustomSchedule={
            !!(customSchedule && Object.keys(customSchedule).length > 1)
          }
          handleOpenCustomScheduleModal={handleOpenCustomScheduleModal}
        />
      )}
      {selectedFormVariant === 'BackupConnector' && (
        <BackupConnectorForm
          availableFormats={availableFormats}
          formattedValue={formattedValue}
          isCustomSchedule={
            !!(customSchedule && Object.keys(customSchedule).length > 1)
          }
          handleOpenCustomScheduleModal={handleOpenCustomScheduleModal}
        />
      )}
      <CustomScheduleModal
        open={isCustomScheduleModalOpen}
        initialValues={customSchedule}
        handleSaveCustomSchedule={handleSaveCustomSchedule}
        handleCloseCustomScheduleModal={handleCloseCustomScheduleModal}
      />
    </Grid>
  )
}
interface ScheduleFormProps {
  selectReportType?: Option
  reportId?: string
  setReportTypeLabel: Dispatch<SetStateAction<string>>
}
export default ScheduleForm
