import React from 'react'
import { Chip, ChipProps } from '@mui/material'
import { ASSET_KIND_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { COLOR_CONFIG } from './asset-color-config'

type AssetKindChipProps = {
  assetKind: (typeof ASSET_KIND_MAP)[keyof typeof ASSET_KIND_MAP] | undefined
  chipProps?: ChipProps
}

const AssetKindChip: React.FC<AssetKindChipProps> = ({
  assetKind,
  chipProps = {},
}) => {
  return (
    <Chip
      label={assetKind ?? 'Unknown'}
      sx={{
        color: 'white',
        px: 0.5,
        border: 1,
        borderColor: assetKind ? COLOR_CONFIG[assetKind].main : 'gray',
        backgroundColor: assetKind ? COLOR_CONFIG[assetKind].light : 'gray',
        ...(chipProps?.sx || {}),
      }}
      {...chipProps}
    />
  )
}

export default AssetKindChip
