export type RoiData = {
  selectedIndustry: string
  revenueInput: number | null
  downtimeCost: number | null
  calculatedRoi: string
}

export const getStoredRoiData = (): RoiData => {
  const storedData = localStorage.getItem('roiData')
  if (storedData) {
    return JSON.parse(storedData)
  }
  return {
    selectedIndustry: 'it',
    revenueInput: null,
    downtimeCost: null,
    calculatedRoi: '0',
  }
}

export const createDollarFormatter = (): Intl.NumberFormat => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}
