import {
  DownloadScanRequest,
  ListScanRequest,
  ListScansRequest,
  Pagination,
  ScansPromiseClient,
} from '../grpc'
import { ClientConfig } from '../models/client'
import { ListScansForAssetsFilters } from '../models/scans'
import { ListScans } from '../models/settings/policies/filter.model'
import {
  FilesystemScanTransformer,
  MalwareScanTransformer,
  RansomwareScanTransformer,
} from '../transformers/scans'
import { GrpcClient } from './grpc'

export class ScansClient extends GrpcClient<ScansPromiseClient> {
  #scansClient: ScansPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#scansClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'ScansClient'
  }

  protected initClient(hostName = ''): ScansPromiseClient {
    return new ScansPromiseClient(hostName, null, null)
  }

  async downloadScan(scanId: string): Promise<string> {
    const request = new DownloadScanRequest()
    request.setScanId(scanId)

    const response = (
      await this.callGrpcService(
        () =>
          this.#scansClient.downloadScan(request, this.metadata(this.#token)),
        {
          requestName: 'ScansPromiseClient/downloadScan',
        }
      )
    ).toObject()

    return response && response.reportDownloadUrl
  }

  async listScansForAsset(filters: ListScansForAssetsFilters) {
    const request = new ListScansRequest()
    const initial = new ListScansRequest.Initial()
    const filter = new ListScansRequest.Initial.Filter()

    filter.setAssetIdList(filters.assetIdList)
    initial.setFilter(filter)
    initial.setPageSize(filters.pageSize ?? 100)
    request.setInitial(initial)

    if (filters.pageToken) {
      request.setPagination(new Pagination().setPageToken(filters.pageToken))
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#scansClient.listScansForAsset(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'ScansPromiseClient/listScansForAsset',
        }
      )
    ).toObject()

    return {
      runsList: response.runsList.map(
        ({ filesystemChecks, malwares, ransomwares }) => ({
          filesystemChecks:
            filesystemChecks &&
            new FilesystemScanTransformer(filesystemChecks).transform(),
          malwares:
            malwares && new MalwareScanTransformer(malwares).transform(),
          ransomwares:
            ransomwares &&
            new RansomwareScanTransformer(ransomwares).transform(),
        })
      ),
      scansRunList: response.scanRunsList.map(
        ({ filesystemChecksList, malwareScansList, ransomwareScansList }) => ({
          filesystemChecksList: filesystemChecksList?.map((filesystemChecks) =>
            new FilesystemScanTransformer(filesystemChecks).transform()
          ),
          malwareScansList: malwareScansList.map((malwares) =>
            new MalwareScanTransformer(malwares).transform()
          ),
          ransomwareScansList: ransomwareScansList.map((ransomwares) =>
            new RansomwareScanTransformer(ransomwares).transform()
          ),
        })
      ),
      pagination: response.pagination,
    }
  }

  async listScans(ids: Array<string>): Promise<ListScans> {
    const request = new ListScanRequest().setScanIdsList(ids)
    const response = (
      await this.callGrpcService(
        () => this.#scansClient.listScans(request, this.metadata(this.#token)),
        {
          requestName: 'ScansPromiseClient/listScans',
        }
      )
    ).toObject()

    return {
      filesystemChecks: response.filesystemChecksList.map((filesystemCheck) => {
        return new FilesystemScanTransformer(filesystemCheck).transform()
      }),
      malwares: response.malwaresList.map((malware) =>
        new MalwareScanTransformer(malware).transform()
      ),

      ransomwares: response.ransomwaresList.map((ransomware) =>
        new RansomwareScanTransformer(ransomware).transform()
      ),
    }
  }
}
