export function formatBytes(bytes: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (bytes === 0) {
    return '0 B'
  }

  let exponent = Math.floor(Math.log(bytes) / Math.log(1024))
  const unit = units[exponent]

  let size = (bytes / Math.pow(1024, exponent)).toFixed(2)

  if (unit === 'TB' && parseFloat(size) < 1) {
    exponent = 4
    size = (bytes / Math.pow(1024, exponent)).toFixed(2)
    return `${parseFloat(size)} GB`
  }

  return `${parseFloat(size)} ${unit}`
}
