import { useQuery } from '@tanstack/react-query'
import { useRecoveryPointsService } from '../../rest-service'

export function useGetRecoveryPointByIdQuery(
  assetId: string | undefined,
  backupId: string | undefined
) {
  const {
    getRecoveryPointById,
    isReady,
    isLoading: isClientLoading,
  } = useRecoveryPointsService()

  return useQuery({
    queryKey: ['assets', assetId, 'recovery-point', backupId],
    queryFn: async () => {
      if (!assetId || !backupId) {
        throw new Error('assetId or backupId are required')
      }

      return getRecoveryPointById(assetId, backupId)
    },
    enabled: Boolean(assetId) && isReady && !isClientLoading,
  })
}
