/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import { Stack } from '@mui/material'
import LabeledValue from '../common/labeled-value'
import { Asset, OvaAccount, OvaServer } from 'blues-corejs/dist'
import { BACKUP_VENDOR_MAP } from 'ui-v2/src/lib/constants/assets.constant'

type SuccessContentProps = {
  asset: Asset | undefined
  ovaAccountsData: Array<OvaAccount> | undefined
}

const computeOvaAccountId = (asset: Asset) => {
  if (asset instanceof OvaServer) {
    return asset.ovaAccountId
  }
}

const computeBackupProvider = (asset: Asset) => {
  if (asset instanceof OvaServer) {
    return asset.backupProvider
  }
}

const SuccessContent: React.FC<SuccessContentProps> = ({
  asset,
  ovaAccountsData,
}) => {
  const accountName = useMemo(() => {
    const ovaAccountId = asset && computeOvaAccountId(asset)
    return ovaAccountId && ovaAccountsData
      ? ovaAccountsData?.find((item) => item.id === ovaAccountId)?.name
      : '-'
  }, [asset, ovaAccountsData])

  const backupProvider = asset && computeBackupProvider(asset)
  const backupVendor =
    backupProvider !== undefined ? BACKUP_VENDOR_MAP[backupProvider] : '-'

  return (
    <Stack spacing={0.5}>
      <LabeledValue label="Backup Vendor" value={backupVendor} />
      <LabeledValue label="Account" value={accountName} />
    </Stack>
  )
}

export default SuccessContent
