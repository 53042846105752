import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import InstanceIdFilter from './ec2/instance-id'
import InstanceStateFilter from './ec2/instance-state'
import AccountIdFilter from './common/account-id'
import PolicyCoverageFilter from './common/policy'
import VolumeIdFilter from './ebs/volume-id'
import RegionFilter from './common/region'
import BucketIdFilter from './s3/bucket-id'
import BucketNameFilter from './s3/bucket-name'
import VolumeNameFilter from './ebs/volume-name'
import FilesystemNameFilter from './efs/filesystem-name'
import FilesystemIdFilter from './efs/filesystem-id'
import BackupVendorFilter from './ova-server/backup-vendor'
import OvaAccountIdFilter from './ova-server/ova-account-id'
import HostFilter from './generic-host/host'
import VolumeStateFilter from './ebs/volume-state'
import BucketStateFilter from './s3/bucket-state'
import FilesystemStateFilter from './efs/filesystem-state'
import {
  ListEbsRequest,
  ListEc2Request,
  ListEfsRequest,
  ListS3Request,
} from 'ui-v2/src/lib/grpc'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import InstanceNameFilter from './ec2/instance-name'

export const filterOptions = {
  [ASSET_FILTERS_MAP.INSTANCE_ID]: <InstanceIdFilter />,
  [ASSET_FILTERS_MAP.INSTANCE_NAME]: <InstanceNameFilter />,
  [ASSET_FILTERS_MAP.INSTANCE_STATE]: <InstanceStateFilter />,
  [ASSET_FILTERS_MAP.VOLUME_ID]: <VolumeIdFilter />,
  [ASSET_FILTERS_MAP.VOLUME_NAME]: <VolumeNameFilter />,
  [ASSET_FILTERS_MAP.VOLUME_STATE]: <VolumeStateFilter />,
  [ASSET_FILTERS_MAP.ACCOUNT_ID]: <AccountIdFilter />,
  [ASSET_FILTERS_MAP.BUCKET_ID]: <BucketIdFilter />,
  [ASSET_FILTERS_MAP.BUCKET_NAME]: <BucketNameFilter />,
  [ASSET_FILTERS_MAP.BUCKET_STATE]: <BucketStateFilter />,
  [ASSET_FILTERS_MAP.FILESYSTEM_ID]: <FilesystemIdFilter />,
  [ASSET_FILTERS_MAP.FILESYSTEM_NAME]: <FilesystemNameFilter />,
  [ASSET_FILTERS_MAP.FILESYSTEM_STATE]: <FilesystemStateFilter />,
  [ASSET_FILTERS_MAP.OVA_ACCOUNT_ID]: <OvaAccountIdFilter />,
  [ASSET_FILTERS_MAP.BACKUP_VENDOR]: <BackupVendorFilter />,
  [ASSET_FILTERS_MAP.HOST]: <HostFilter />,
  [ASSET_FILTERS_MAP.REGION]: <RegionFilter />,
  [ASSET_FILTERS_MAP.POLICY_COVERAGE]: <PolicyCoverageFilter />,
}

export const defaultFilters = {
  [ASSETS_ROUTE_SEGMENTS.EC2]: {
    [ASSET_FILTERS_MAP.INSTANCE_STATE]: [
      ListEc2Request.Initial.Filter.InstanceState.RUNNING,
      ListEc2Request.Initial.Filter.InstanceState.STOPPED,
    ],
  },
  [ASSETS_ROUTE_SEGMENTS.EBS]: {
    [ASSET_FILTERS_MAP.VOLUME_STATE]: [
      ListEbsRequest.Initial.Filter.VolumeState.IN_USE,
    ],
  },
  [ASSETS_ROUTE_SEGMENTS.S3]: {
    [ASSET_FILTERS_MAP.BUCKET_STATE]: [
      ListS3Request.Initial.Filter.BucketState.IN_USE,
    ],
  },
  [ASSETS_ROUTE_SEGMENTS.EFS]: {
    [ASSET_FILTERS_MAP.FILESYSTEM_STATE]: [
      ListEfsRequest.Initial.Filter.FilesystemState.IN_USE,
    ],
  },
  [ASSETS_ROUTE_SEGMENTS.SERVER]: {},
  [ASSETS_ROUTE_SEGMENTS.GENERIC_HOST]: {},
} as const

export function getDefaultQueryParams(
  tabSlug: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
): string {
  const tabDefaultFilters = defaultFilters[tabSlug]
  if (!tabDefaultFilters) {
    return ''
  }

  const queryParams = new URLSearchParams()

  Object.entries(tabDefaultFilters).forEach(([filterKey, filterValues]) => {
    if (Array.isArray(filterValues)) {
      queryParams.set(filterKey, filterValues.join(','))
    }
  })

  const queryString = queryParams.toString()
  return queryString ? `?${queryString}` : ''
}
