"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetAccount = void 0;
class AssetAccount {
    #id;
    #regionStacksList;
    #awsAccountId;
    #connectorAwsAccountId;
    #alias;
    #description;
    #stackRegion;
    #version;
    #createdAt;
    #updatedAt;
    constructor(args) {
        this.#id = args.id;
        this.#regionStacksList = args.regionStacksList;
        this.#awsAccountId = args.awsAccountId;
        this.#connectorAwsAccountId = args.connectorAwsAccountId;
        this.#alias = args.alias;
        this.#description = args.description;
        this.#stackRegion = args.stackRegion;
        this.#version = args.version;
        this.#createdAt = args.createdAt;
        this.#updatedAt = args.updatedAt;
    }
    get id() {
        return this.#id;
    }
    get regionStacksList() {
        return this.#regionStacksList;
    }
    get awsAccountId() {
        return this.#awsAccountId;
    }
    get connectorAwsAccountId() {
        return this.#connectorAwsAccountId;
    }
    get alias() {
        return this.#alias;
    }
    get description() {
        return this.#description;
    }
    get stackRegion() {
        return this.#stackRegion;
    }
    get version() {
        return this.#version;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
}
exports.AssetAccount = AssetAccount;
