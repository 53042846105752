import React, { useMemo, useState } from 'react'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { useListTenantJobsQuery } from 'ui-v2/src/hooks/queries/jobs'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import CollapsibleDetailsDrawer from './collapsible-details-drawer'
import { DeploymentTableData } from 'ui-v2/src/lib/use-cases/monitor/deployment-table-data'
import { RedStackModel } from 'ui-v2/src/lib/models/base-model'
import dayjs from 'dayjs'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'

import JobProgressCell from './job-progress-cell'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import { useSystemFilters } from 'ui-v2/src/hooks/filters/monitor'
import Filters from './filters'
import LoadingTable from 'ui-v2/src/components/ui/data-table/loading-table'
import ShieldIcon from 'ui-v2/src/assets/icons/shield-icon'

const Deployment = () => {
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [previousPageTokens, setPreviousPageTokens] = useState<
    Array<string | undefined>
  >([])
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  )
  const { filters, startDate, endDate, timeRange, setPageSize, setPageToken } =
    useSystemFilters()

  const { data: tenantCloudConnectorJobs, isLoading } = useListTenantJobsQuery({
    kindsList: filters.kindsList,
    statusesList: filters.statusesList,
    timeRange: timeRange,
    pageSize: filters.pageSize,
    pageToken: filters.pageToken,
  })
  const { data: redStackList = [] } = useRedStackListQuery()

  const handleExpandClick = (id: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  const redStackListData = useMemo(
    () =>
      redStackList?.map(
        (data) =>
          new RedStackModel({
            redStackId: data.redStackId,
            accountAlias: data.accountAlias,
            awsRegion: data.awsRegion,
            awsAccount: data.awsAccountId,
            status: data.status,
            iamRole: data.iamRole,
            accountDescription: data.accountDescription,
            lastErrorMessage: data.lastErrorMessage,
            version: data.version,
            lastWarningMessage: data.lastWarningMessage,
            awsAccountCfnVersion: data.awsAccountCfnVersion,
            lastIntegrityCheckErrorsList: data.lastIntegrityCheckErrors,
            cfnUpgradeRequired: data.cfnUpgradeRequired,
          })
      ) || [],
    [redStackList]
  )

  const deploymentData = useMemo(() => {
    if (!tenantCloudConnectorJobs?.jobsList) {
      return []
    }

    return new DeploymentTableData({
      tenantJobs: tenantCloudConnectorJobs?.jobsList,
      redStackListData: redStackListData,
    }).getDeploymentData()
  }, [tenantCloudConnectorJobs, redStackListData])

  const handleNextPage = () => {
    if (tenantCloudConnectorJobs?.pageToken) {
      setPreviousPageTokens((prev) => [...prev, filters.pageToken])
      setPageToken?.(tenantCloudConnectorJobs?.pageToken)
    }
  }

  const handlePreviousPage = () => {
    if (previousPageTokens.length > 0) {
      const lastPreviousToken =
        previousPageTokens[previousPageTokens.length - 1]
      setPreviousPageTokens((prev) => prev.slice(0, -1))
      setPageToken?.(lastPreviousToken)
    }
  }

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      handleNextPage()
    } else {
      handlePreviousPage()
    }
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(0)
    setPreviousPageTokens([])
    setPageToken?.(undefined)
    setPageSize?.(parseInt(e.target.value, 10))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Filters startDate={startDate} endDate={endDate} timeRange={timeRange} />
      <Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead
              sx={{
                '& .MuiTableCell-head': {
                  backgroundColor:
                    theme.palette.mode === 'dark' ? '#1A1F28' : '#ffffff',
                },
              }}
            >
              <TableRow>
                <TableCell sx={{ width: '10px' }}>&nbsp;</TableCell>
                <TableCell sx={{ width: '30px' }}>Scheduled On</TableCell>
                <TableCell sx={{ width: '150px' }}>Job Type</TableCell>
                <TableCell sx={{ width: '90px' }}>Cloud Connector</TableCell>
                <TableCell sx={{ width: '130px' }}>Message</TableCell>
                <TableCell sx={{ width: '140px' }}>Task</TableCell>
                <TableCell sx={{ width: '140px' }}>Completed On</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <>
                <LoadingTable
                  columnCount={7}
                  rowCount={filters.pageSize ?? 1}
                />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  zIndex={10}
                  sx={{ backgroundColor: 'transparent' }}
                >
                  <CircularProgress />
                </Box>
              </>
            ) : (
              <TableBody
                sx={{
                  '& .MuiTableCell-body': {
                    backgroundColor:
                      theme.palette.mode === 'dark' ? '#181B1F' : '#f8f8f8',
                  },
                }}
              >
                {deploymentData?.length > 0 ? (
                  <>
                    {deploymentData.map((row) => (
                      <React.Fragment key={row.id}>
                        <TableRow sx={{ height: '60px' }}>
                          <TableCell
                            sx={{
                              paddingRight: 0,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {row?.childrenList?.length > 0 && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <IconButton
                                  sx={{
                                    padding: 0,
                                    width: 30,
                                    height: 30,
                                  }}
                                  onClick={() => handleExpandClick(row.id)}
                                  size="small"
                                >
                                  {expandedRows[row.id] ? (
                                    <KeyboardArrowDown />
                                  ) : (
                                    <KeyboardArrowRight />
                                  )}
                                </IconButton>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell>
                            <Box
                              display={'flex'}
                              sx={{
                                position: 'relative',
                                whiteSpace: 'nowrap',
                              }}
                              columnGap={1}
                            >
                              <JobProgressCell status={row.status} />

                              <Typography variant="body2" fontWeight={400}>
                                {dayjs(row.scheduledOn).format(
                                  'MM/DD/YY hh:mm A'
                                )}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" fontWeight={400}>
                              {row.jobType}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {row.cloudConnector ? (
                              <>
                                <Typography variant="body2" fontWeight={400}>
                                  {row.cloudConnector?.awsAccount}
                                </Typography>
                                <Typography variant="body2" fontWeight={400}>
                                  {row.cloudConnector?.awsRegion}
                                </Typography>
                              </>
                            ) : (
                              <Typography variant="body2" fontWeight={400}>
                                -{' '}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell>
                            <LongTextTooltip
                              text={row.message}
                              placement={'bottom-start'}
                              maxLength={50}
                              fontSize={'0.875rem'}
                            />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: 'nowrap' }}>
                            <LongTextTooltip
                              text={row.task ?? '-'}
                              placement={'bottom-start'}
                              maxLength={50}
                              fontSize={'0.875rem'}
                            />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: 'nowrap' }}>
                            <Typography variant="body2" fontWeight={400}>
                              {!!row.completedOn &&
                                dayjs(row.completedOn).format(
                                  'MM/DD/YY hh:mm A'
                                )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              height: 'auto',
                              borderBottom: 0,
                              padding: 0,
                            }}
                            colSpan={7}
                          >
                            <Collapse
                              in={expandedRows[row.id] || false}
                              timeout="auto"
                              unmountOnExit
                            >
                              <CollapsibleDetailsDrawer
                                childrenList={row.childrenList}
                                redStackListData={redStackListData}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} sx={{ border: 'none' }}>
                      <Box component={'div'} className="render-empty-state">
                        <Box sx={{ mb: 2 }}>
                          <ShieldIcon isHealthy={true} />
                        </Box>
                        <Typography
                          variant="h6"
                          color="text.secondary"
                          sx={{ mb: 1 }}
                        >
                          No data available
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.disabled"
                          sx={{ mb: 2 }}
                        >
                          There are no items to display.
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={TABLE_ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={-1}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={filters.pageSize ?? -1}
          onRowsPerPageChange={onRowsPerPageChange}
          slotProps={{
            actions: {
              nextButton: {
                disabled: !tenantCloudConnectorJobs?.pageToken,
              },
              previousButton: {
                disabled: previousPageTokens.length === 0,
              },
            },
          }}
          sx={{
            '& .MuiTablePagination-toolbar': {
              minHeight: '43px !important',
              height: '43px',
              backgroundColor: theme.palette.background.paper,
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default Deployment
