/* eslint-disable import/no-extraneous-dependencies */
import { IconButton } from '@mui/material'
import { useCloudConnectorJobQuery } from 'ui-v2/src/hooks/queries/jobs'
import { useAssetsListByIdQuery } from 'ui-v2/src/hooks/queries/assets/list-by-id'
import { Asset } from 'blues-corejs/dist'
import {
  computeAssetID,
  getNotEmptyAsset,
} from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job'
import { ScheduleScanAsset } from 'ui-v2/src/lib/models/assets'
import { Refresh } from '@mui/icons-material'
import { useScheduleScansMutation } from 'ui-v2/src/hooks/queries/pechkin'
import { useToast } from 'ui-v2/src/hooks/toast'

interface RestartScanProps {
  monitorId: string
}

const RestartScan = ({ monitorId }: RestartScanProps) => {
  const toast = useToast()
  const { mutate } = useScheduleScansMutation()

  const {
    data: monitorDetailsData,
    isLoading: isMonitorDetailsLoading,
    error: monitorDetailsError,
  } = useCloudConnectorJobQuery(monitorId)

  const targetAssetId = monitorDetailsData?.targetAssetId

  const { data: assetIdsList } = useAssetsListByIdQuery(
    targetAssetId ? [targetAssetId] : []
  )

  const assetsMap = new Map(
    assetIdsList?.assetsList?.reduce<Map<string, Asset>>((map, asset) => {
      const validAsset = getNotEmptyAsset(asset)
      if (validAsset) {
        map.set(validAsset.id, validAsset)
      }
      return map
    }, new Map())
  )

  const asset = assetsMap.get(targetAssetId ?? '')

  const assetId = computeAssetID(asset)

  const scheduleScan = () => {
    mutate([asset as ScheduleScanAsset], {
      onError: (error: { message: string }) => {
        toast.error(error.message ?? 'Failed to schedule scan')
      },
      onSuccess: (data: Array<{ errorMessage: string }>) => {
        if (data?.[0]?.errorMessage) {
          toast.error('Failed to schedule scan')
        } else {
          toast.success('Scan scheduled successfully')
        }
      },
    })
  }

  return (
    <>
      {!!assetId && !isMonitorDetailsLoading && !monitorDetailsError && (
        <IconButton
          sx={(theme) => ({
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(204, 204, 220, 1)'
                : '#1A1F28',
            padding: 1,
          })}
        >
          <Refresh onClick={scheduleScan} />
        </IconButton>
      )}
    </>
  )
}

export default RestartScan
