import React from 'react'
import { useTheme } from '@mui/material'
import { ThemeMode } from '../../components/common/theme-switcher'

const CircleQuestionIcon = () => {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M8 0.673828C3.584 0.673828 0 4.25783 0 8.67383C0 13.0898 3.584 16.6738 8 16.6738C12.416 16.6738 16 13.0898 16 8.67383C16 4.25783 12.416 0.673828 8 0.673828ZM8 15.0738C4.472 15.0738 1.6 12.2018 1.6 8.67383C1.6 5.14583 4.472 2.27383 8 2.27383C11.528 2.27383 14.4 5.14583 14.4 8.67383C14.4 12.2018 11.528 15.0738 8 15.0738ZM7.2 11.8738H8.8V13.4738H7.2V11.8738ZM8.488 3.90583C6.84 3.66583 5.384 4.68183 4.944 6.13783C4.8 6.60183 5.152 7.07383 5.64 7.07383H5.8C6.128 7.07383 6.392 6.84183 6.504 6.53783C6.76 5.82583 7.52 5.33783 8.344 5.51383C9.104 5.67383 9.664 6.41783 9.6 7.19383C9.52 8.26583 8.304 8.49783 7.64 9.49783C7.64 9.50583 7.632 9.50583 7.632 9.51383C7.624 9.52983 7.616 9.53783 7.608 9.55383C7.536 9.67383 7.464 9.80983 7.408 9.95383C7.4 9.97783 7.384 9.99383 7.376 10.0178C7.368 10.0338 7.368 10.0498 7.36 10.0738C7.264 10.3458 7.2 10.6738 7.2 11.0738H8.8C8.8 10.7378 8.888 10.4578 9.024 10.2178C9.04 10.1938 9.048 10.1698 9.064 10.1458C9.128 10.0338 9.208 9.92983 9.288 9.83383C9.296 9.82583 9.304 9.80983 9.312 9.80183C9.392 9.70583 9.48 9.61783 9.576 9.52983C10.344 8.80183 11.384 8.20983 11.168 6.68183C10.976 5.28983 9.88 4.11383 8.488 3.90583Z"
        fill={theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#727883'}
      />
    </svg>
  )
}

export default CircleQuestionIcon
