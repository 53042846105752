import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { Box, IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'

const BackupProvider = ({ computedBackupId }: BackupProviderProps) => {
  return (
    <Box
      display={'inline-flex'}
      alignItems={'center'}
      marginRight={'16px'}
      marginTop={0}
    >
      <LongTextTooltip text={computedBackupId} maxLength={30} fontSize="14px" />
      <IconButton
        size="small"
        onClick={() => {
          copyToClipboard(computedBackupId)
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    </Box>
  )
}

interface BackupProviderProps {
  computedBackupId: string
}

export default BackupProvider
