import { useEffect, useState } from 'react'
import { ASSET_DETAILS_ACTIVE_TAB, PAGE_SIZE } from '../constants'
import { useListRecoveryPointsInfiniteQuery } from 'ui-v2/src/hooks/queries/recovery-points'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { processKinds } from '../../utils'
import { useListScanLogInfiniteQuery } from 'ui-v2/src/hooks/queries/scan-log'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

type TabCount = Record<keyof typeof ASSET_DETAILS_ACTIVE_TAB, number>

const useTabsCount = () => {
  const [tabsCount, setTabCount] = useState<TabCount>(() => ({
    [ASSET_DETAILS_ACTIVE_TAB.recoveryPoint]: 0,
    [ASSET_DETAILS_ACTIVE_TAB.scanLog]: 0,
  }))

  const [didQueriesResolveInitially, setDidQueriesResolveInitially] =
    useState(false)

  const navigate = useNavigate()

  const { assetKind, assetId } = useParams<{
    assetKind: string
    assetId: string
  }>()

  const [searchParams] = useSearchParams()
  const kindFilter = searchParams.get('backupType')

  const { keyKind, backupType } = processKinds(kindFilter)

  const daysFilter = searchParams
    .get('days')
    ?.split(',')
    ?.map(Number)
    ?.sort((a, b) => a - b)

  const lastBiggestDate = daysFilter
    ? daysFilter[daysFilter.length - 1]
    : undefined

  const { data: recoveryPointsData, status: recoveryPointsDataQueryStatus } =
    useListRecoveryPointsInfiniteQuery(assetId, {
      pageSize: PAGE_SIZE,
      days: lastBiggestDate,
      ...(keyKind && { type: keyKind }),
      ...(backupType && { backupType }),
    })

  const { data: scanLogData, status: scanLogDataQueryStatus } =
    useListScanLogInfiniteQuery(assetId, {
      pageSize: PAGE_SIZE,
      days: lastBiggestDate,
      ...(keyKind && { type: keyKind }),
      ...(backupType && { backupType }),
    })

  useEffect(() => {
    setTabCount((prevState) => ({
      ...prevState,
      [ASSET_DETAILS_ACTIVE_TAB.recoveryPoint]:
        recoveryPointsData?.pages[0]?.pagination.total_count ?? 0,
      [ASSET_DETAILS_ACTIVE_TAB.scanLog]:
        scanLogData?.pages[0]?.pagination.total_count ?? 0,
    }))
  }, [recoveryPointsData, scanLogData])

  //Redirect the user to scans if there are no recovery points
  //Will work on the first load and not when filters are applied
  useEffect(() => {
    const didQueriesSucceed =
      recoveryPointsDataQueryStatus === 'success' &&
      scanLogDataQueryStatus === 'success'

    if (!didQueriesResolveInitially && didQueriesSucceed) {
      if (!recoveryPointsData?.pages[0] || !scanLogData?.pages[0]) {
        return
      }

      const isRecoveryPointsListEmpty =
        recoveryPointsData.pages[0].pagination.total_count === 0

      const doesScanLogHasData =
        scanLogData?.pages[0]?.pagination.total_count > 0

      if (isRecoveryPointsListEmpty && doesScanLogHasData) {
        const pathParts = [
          assetKind,
          'details',
          assetId,
          ASSET_DETAILS_ACTIVE_TAB.scanLog,
        ]
          .filter(Boolean)
          .join('/')

        navigate(
          {
            pathname: `/${BASE_ROUTE_SEGMENTS.ASSETS}/${pathParts}`,
            search: `?${searchParams.toString()}`,
          },
          { replace: true }
        )
      }

      setDidQueriesResolveInitially(true)
    }
  }, [
    recoveryPointsDataQueryStatus,
    scanLogDataQueryStatus,
    didQueriesResolveInitially,
  ])

  //We need to show loading state until both queries resolve atleast once to avoid flicker when redirecting to scans
  //The child tabs will show the loading state independently thereafter
  return {
    tabsCount,
    isLoading: !didQueriesResolveInitially,
  }
}

export default useTabsCount
