import React, { useEffect, useRef } from 'react'
import { Box, useTheme } from '@mui/material'
import * as echarts from 'echarts'
import type { EChartsOption, LineSeriesOption } from 'echarts'
import { ASSET_KIND_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { COLOR_CONFIG } from '../common/asset-color-config'
import {
  formatDate,
  maybeSecondsToDate,
} from 'ui-v2/src/lib/helpers/time.helper'
import { ThreatsTrendResponse } from 'ui-v2/src/lib/models/anomalies/threats-trend'

type ThreatsLineChartProps = {
  assetKind: (typeof ASSET_KIND_MAP)[keyof typeof ASSET_KIND_MAP] | undefined
  timeSeriesData: ThreatsTrendResponse
}

const ThreatsLineChart: React.FC<ThreatsLineChartProps> = ({
  assetKind,
  timeSeriesData,
}) => {
  const theme = useTheme()
  const chartRef = useRef<echarts.ECharts>()
  const containerRef = useRef<HTMLDivElement>(null)

  const xAxisData = timeSeriesData.entropy_trends_history.map((insiderThreat) =>
    formatDate(
      maybeSecondsToDate(insiderThreat.timestamp.seconds),
      'MM/DD/YYYY'
    )
  )

  const data = {
    xAxis: xAxisData,
    insiderThreats: timeSeriesData.entropy_trends_history.map(
      (insiderThreat) => insiderThreat.anomaly_finding_count
    ),
    threatsTrend: timeSeriesData.threats_history.map(
      (threat) => threat.threats_finding_count
    ),
    fileSystemIntegrity: timeSeriesData.file_system_threats_history.map(
      (filesystemThreat) => filesystemThreat.threats_finding_count
    ),
  }

  const assetColor = assetKind
    ? COLOR_CONFIG[assetKind]
    : {
        main: theme.palette.grey[300],
        light: theme.palette.grey[700],
      }

  const areaStyle: LineSeriesOption['areaStyle'] = {
    color: {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [
        {
          offset: 0,
          color: assetColor.light,
        },
        {
          offset: 1,
          color: theme.palette.background.secondary!,
        },
      ],
    },
  }

  const option: EChartsOption = {
    backgroundColor: theme.palette.background.elevation3,
    grid: {
      top: 4,
      bottom: 20,
      left: 0,
      right: 0,
    },
    xAxis: {
      type: 'category',
      data: data.xAxis,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      boundaryGap: false,
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    series: [
      {
        name: 'Insider Threats Trend',
        type: 'line',
        data: data.insiderThreats,
        symbol: 'circle',
        symbolSize: 8,
        smooth: true,
        itemStyle: {
          color: assetColor.main,
        },
        lineStyle: {
          color: assetColor.main,
          width: 2,
        },
        areaStyle,
      },
      {
        name: 'Threats Trend',
        type: 'line',
        data: data.threatsTrend,
        symbol: 'none',
        smooth: true,
        lineStyle: {
          color: assetColor.main,
          width: 2,
          type: 'solid',
        },
        areaStyle,
      },
      {
        name: 'File System Integrity',
        type: 'line',
        data: data.fileSystemIntegrity,
        symbol: 'none',
        smooth: true,
        lineStyle: {
          color: assetColor.main,
          width: 2,
          type: 'dashed',
        },
        areaStyle,
      },
    ],
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.7)',
      textStyle: {
        color: '#fff',
        fontSize: 10,
      },
      position: function (point) {
        return [point[0], point[1] - 50]
      },
      align: 'center',
      confine: true,
      formatter: function (params) {
        const paramsList = Array.isArray(params) ? params : [params]

        let result = `<div style="margin-bottom:5px; font-size:10px;">${paramsList[0]?.name}</div>`

        paramsList.forEach((param) => {
          let marker
          if (param.seriesName === 'Insider Threats Trend') {
            marker = `<div style="position:relative; width:15px; height:10px; display:flex; align-items:center;">
              <span style="position:absolute; top:50%; left:0; transform:translate(45%,-50%); border-radius:50%; width:8px; height:8px; background-color:${assetColor.main};"></span>
              <span style="position:absolute; top:50%; left:0; right:0; transform:translateY(-50%); height:2px; background-color:${assetColor.main};"></span>
            </div>`
          } else if (param.seriesName === 'Threats Trend') {
            marker = `<div style="display:flex; align-items:center;">
              <span style="display:inline-block; width:15px; height:2px; background-color:${assetColor.main};"></span>
            </div>`
          } else if (param.seriesName === 'File System Integrity') {
            marker = `<div style="display:flex; align-items:center;">
              <span style="display:inline-block; width:15px; height:0; border-top:2px dashed ${assetColor.main};"></span>
            </div>`
          }

          result += `<div style="display:flex; justify-content:space-between; align-items:center; margin:3px 0; font-size:10px;">
            <div style="display:flex; align-items:center;">
              ${marker} <span style="margin-left:5px;">${param.seriesName}</span>
            </div>
            <div style="font-weight:bold; margin-left:15px;">${param.value}</div>
          </div>`
        })

        return result
      },
    },
    legend: {
      data: [
        'Insider Threats Trend',
        {
          name: 'Threats Trend',
          icon: 'emptyLine',
        },
        {
          name: 'File System Integrity',
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            width: 1,
          },
        },
      ],
      bottom: 0,
      textStyle: {
        color: theme.palette.text.secondary,
        fontSize: '8.3px',
      },
      itemStyle: {
        color: assetColor.main,
      },
      itemWidth: 12,
      itemHeight: 8,
    },
  }

  useEffect(() => {
    if (containerRef.current) {
      chartRef.current = echarts.init(containerRef.current)
      chartRef.current.setOption(option)
    }

    const handleResize = () => {
      chartRef.current?.resize()
    }
    window.addEventListener('resize', handleResize)

    return () => {
      chartRef.current?.dispose()
      window.removeEventListener('resize', handleResize)
    }
  }, [option])

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
      }}
    />
  )
}

export default React.memo(ThreatsLineChart)
