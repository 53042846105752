import React from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

interface SelectLabelOption {
  selectedValueLength: number
  optionsLength?: number
  text: string
}

export default function SelectLabelText({
  selectedValueLength,
  optionsLength = 0,
  text,
}: SelectLabelOption) {
  const theme = useTheme()
  return selectedValueLength > 0 && selectedValueLength !== optionsLength ? (
    <Typography component="span" fontSize="14px">
      {text}
    </Typography>
  ) : (
    <Typography component="span" lineHeight="1rem">
      <Typography component="span" paddingRight="4px" fontSize="14px">
        {text}
      </Typography>
      <Typography
        color={theme.palette.mode === 'dark' ? '#ffffff' : '#50565c'}
        component="span"
        sx={{
          fontWeight: 700,
          fontSize: '14px',
        }}
      >
        All
      </Typography>
    </Typography>
  )
}
