/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { Drawer } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import CloseIcon from '@mui/icons-material/Close'
import dayjs from 'dayjs'
import { useListEventKindsQuery } from 'ui-v2/src/hooks/queries/alerts/list-notifications-rules'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import { RedStackModel } from 'ui-v2/src/lib/models/base-model'
import { useListUsersQuery } from 'ui-v2/src/hooks/queries/users/list-users'
import { useListWebhooksQuery } from 'ui-v2/src/hooks/queries/webhooks/list-webhooks'
import { useListOvaAccountsQuery } from 'ui-v2/src/hooks/queries/ova-accounts'
import RuleInformation from './rule-information'
import RuleInfrastructure from './rule-infrastructure'
import RuleDeliveryChannels from './rule-delivery-channels'
import RuleProperties from './rule-properties'
import { useCreateNotificationRule } from 'ui-v2/src/hooks/queries/alerts/create-notification-rule'
import {
  RuleAwsAccountRegion,
  Tag,
  TagsOperator,
} from 'blues-corejs/dist/models/notifications/rule/criteria'
import { getCreateNotificationCriteria } from './utils'
import { useToast } from 'ui-v2/src/hooks/toast'
import { useAlertsListStore } from '../store/alerts-list-store'
import { useAlertsActiveRuleStore } from '../store/alerts-active-rule-store'
import { transformNotificationForForm } from '../utils'
import { useUpdateNotificationRule } from 'ui-v2/src/hooks/queries/alerts/update-notification-rule'

// Add empty export to make it a module
export {}

const CreateRule = () => {
  const toast = useToast()
  const { tableData, setTableData } = useAlertsListStore()
  const { activeRule, setActiveRule } = useAlertsActiveRuleStore()
  const navigate = useNavigate()
  const { action } = useParams()
  const isUpdate = action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.UPDATE_ALERT_RULE
  const isDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_ALERT_RULE || isUpdate

  const { data: eventKinds } = useListEventKindsQuery()
  const { data: redStackList } = useRedStackListQuery()
  const { data: recipientList } = useListUsersQuery()
  const { data: webhooksList } = useListWebhooksQuery()
  const { data: ovaAccountsList } = useListOvaAccountsQuery()

  const { mutate: createNotificationRequest } = useCreateNotificationRule()
  const { mutate: updateNotificationRequest } = useUpdateNotificationRule()
  const [currentPage, setCurrentPage] = useState(1)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [selectedEvents, setSelectedEvents] = useState<Array<string>>([])
  const [isSuppress, setSuppress] = useState(false)
  const [suppressUntil, setSuppressUntil] = useState(dayjs())
  const [suppressReason, setSuppressReason] = useState('')

  const [infrastructure, setInfrastructure] = useState<'AWS' | 'On Prem'>('AWS')
  const [selectedRegions, setSelectedRegions] = useState<
    Array<RuleAwsAccountRegion>
  >([])
  const [selectedAccounts, setSelectedAccounts] = useState<Array<string>>([])
  const [tagPairs, setTagPairs] = useState<Array<Tag>>([])
  const [tagRelation, setTagRelation] = useState<TagsOperator>(TagsOperator.AND)

  const [emailUsers, setEmailUsers] = useState<Array<string>>([])
  const [selectedWebhooks, setSelectedWebhooks] = useState<Array<string>>([])
  const [slackEnabled, setSlackEnabled] = useState(false)

  const [isEnabled, setIsEnabled] = useState(true)

  const redStackListData = useMemo(() => {
    return redStackList?.map(
      (data) =>
        new RedStackModel({
          redStackId: data.redStackId,
          accountAlias: data.accountAlias,
          awsRegion: data.awsRegion,
          awsAccount: data.awsAccountId,
          status: data.status,
          iamRole: data.iamRole,
          accountDescription: data.accountDescription,
          lastErrorMessage: data.lastErrorMessage,
          version: data.version,
          lastWarningMessage: data.lastWarningMessage,
          awsAccountCfnVersion: data.awsAccountCfnVersion,
          lastIntegrityCheckErrorsList: data.lastIntegrityCheckErrors,
          cfnUpgradeRequired: data.cfnUpgradeRequired,
        })
    )
  }, [redStackList])

  const recipients = useMemo(() => {
    if (!recipientList) {
      return undefined
    }

    return {
      userProfilesList: recipientList.userProfilesList.filter(
        // @ts-ignore
        (user) => user.status !== 'invited'
      ),
      totalCount: recipientList.totalCount,
    }
  }, [recipientList])

  useEffect(() => {
    if (!activeRule || !eventKinds || !webhooksList || !recipients) {
      return
    }
    const values = transformNotificationForForm({
      notification: activeRule,
      allEvents: eventKinds,
      webhooksList,
      recipients: recipients.userProfilesList,
    })
    setName(values.name)
    setDescription(values.description)
    setSelectedEvents(values.selectedEvents)
    setSuppress(values.isSuppress)
    setSuppressUntil(values.suppressUntil)
    setSuppressReason(values.suppressReason)
    setInfrastructure(values.infrastructure)
    setSelectedRegions(values.selectedRegions)
    setSelectedAccounts(values.selectedAccounts)
    setTagPairs(values.tagPairs)
    setTagRelation(values.tagRelation)
    setEmailUsers(values.emailUsers)
    setSelectedWebhooks(values.selectedWebhooks)
    setSlackEnabled(values.slackEnabled)
    setIsEnabled(values.isEnabled)
  }, [activeRule])

  const isNextButtonDisabled = () => {
    if (currentPage === 1) {
      return (
        name === '' ||
        (isSuppress && suppressReason === '') ||
        selectedEvents.length === 0
      )
    }

    if (currentPage === 3) {
      return !selectedWebhooks.length && !emailUsers.length && !slackEnabled
    }

    return false
  }

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, 4))
  }

  const handleBackPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }

  const clearAll = () => {
    setName('')
    setDescription('')
    setIsEnabled(true)
    setSelectedEvents([])
    setSuppressUntil(dayjs())
    setSuppress(false)
    setCurrentPage(1)
    setInfrastructure('AWS')
    setSelectedAccounts([])
    setSelectedRegions([])
    setTagPairs([])
    setTagRelation(TagsOperator.AND)
    setEmailUsers([])
    setSelectedWebhooks([])
    setSlackEnabled(false)
  }
  const handleCloseDrawer = () => {
    clearAll()
    setActiveRule(null)
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.ALERTS}/${GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS.ALERTS}`
    )
  }

  const handleCreate = () => {
    const criteria = getCreateNotificationCriteria({
      tags: tagPairs,
      tagOperator: tagRelation,
      selectedRegions: infrastructure === 'AWS' ? selectedRegions : [],
      isAllEventsSelected: selectedEvents.length === eventKinds?.length,
      selectedEvents,
      selectedAccounts:
        infrastructure === 'On Prem'
          ? new Set<string>([...selectedAccounts])
          : new Set<string>(),
      ovaAccountsList,
      isEnabled,
      suppressUntil,
      isSuppress,
      suppressReason,
      description,
      emails: emailUsers,
      name: name,
      slack: slackEnabled,
      userProfilesList: recipients?.userProfilesList,
      webhooks: new Set<string>([...selectedWebhooks]),
      webhooksList: webhooksList,
    })

    if (isUpdate) {
      updateNotificationRequest(
        {
          ...criteria,
          ruleId: activeRule?.id || '',
        },
        {
          onSuccess: (data) => {
            toast.success('Notification Rule updated')
            if (data) {
              setTableData(
                tableData.map((rule) => {
                  if (rule.id === data.id) {
                    return data
                  }
                  return rule
                })
              )
            }

            handleCloseDrawer()
          },
          onError: (error) => {
            toast.error(error?.message ?? 'Error during rule updating')
          },
        }
      )
      return
    }

    createNotificationRequest(criteria, {
      onSuccess: (data) => {
        toast.success('Notification Rule created')
        if (data) {
          setTableData([data, ...tableData])
        }

        handleCloseDrawer()
      },
      onError: (error) => {
        toast.error(error?.message ?? 'Error during rule creating')
      },
    })
  }

  const renderPageContent = () => {
    switch (currentPage) {
      case 1:
        return (
          <RuleInformation
            selectedEvents={selectedEvents}
            setSelectedEvents={setSelectedEvents}
            suppressUntil={suppressUntil}
            setSuppressUntil={setSuppressUntil}
            isSuppress={isSuppress}
            setSuppress={setSuppress}
            suppressReason={suppressReason}
            setSuppressReason={setSuppressReason}
            eventKinds={eventKinds}
            description={description}
            setDescription={setDescription}
            name={name}
            setName={setName}
          />
        )

      case 2:
        return (
          <RuleInfrastructure
            selectedRegions={selectedRegions}
            setSelectedRegions={setSelectedRegions}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            tagRelation={tagRelation}
            setTagRelation={setTagRelation}
            ovaAccountsList={ovaAccountsList}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            tagPairs={tagPairs}
            setTagPairs={setTagPairs}
            redStackListData={redStackListData}
          />
        )

      case 3:
        return (
          <RuleDeliveryChannels
            emailUsers={emailUsers}
            setEmailUsers={setEmailUsers}
            webhooksList={webhooksList}
            selectedWebhooks={selectedWebhooks}
            setSelectedWebhooks={setSelectedWebhooks}
            slackEnabled={slackEnabled}
            setSlackEnabled={setSlackEnabled}
            userProfilesList={recipients?.userProfilesList}
          />
        )

      case 4: {
        return (
          <RuleProperties
            slackEnabled={slackEnabled}
            selectedWebhooks={selectedWebhooks}
            emailUsers={emailUsers}
            tagPairs={tagPairs}
            selectedRegions={selectedRegions}
            name={name}
            isSuppress={isSuppress}
            description={description}
            suppressUntil={suppressUntil}
            selectedEvents={selectedEvents}
            isEnabled={isEnabled}
            setIsEnabled={setIsEnabled}
          />
        )
      }

      // Add cases 4 as needed
      default:
        return null
    }
  }

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          width: '640px !important',
          maxWidth: '640px !important',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: '22px',
          }}
        >
          <Typography variant="h6">
            {isUpdate ? 'Update rule ' : 'Create rule '}({currentPage}/4)
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            {currentPage > 1 && (
              <Button
                variant="contained"
                color="inherit"
                onClick={handleBackPage}
                size="small"
              >
                Back
              </Button>
            )}
            {currentPage < 4 ? (
              <Button
                variant="contained"
                onClick={handleNextPage}
                size="small"
                disabled={isNextButtonDisabled()}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleCreate}
              >
                {isUpdate ? 'Update' : 'Create'}
              </Button>
            )}
            <IconButton onClick={handleCloseDrawer} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {renderPageContent()}
      </Box>
    </Drawer>
  )
}

export default CreateRule
