import { useQuery } from '@tanstack/react-query'
import { useScanJobService } from '../../rest-service'
import { AssetThreatsParams } from 'ui-v2/src/lib/models/backups'

export function useListAssetThreatsForScanJobQuery(
  assetId: string | undefined,
  scanJobId: string | undefined,
  params: AssetThreatsParams
) {
  const {
    listAssetThreatsForScanJob,
    isReady,
    isLoading: isClientLoading,
  } = useScanJobService()

  return useQuery({
    queryKey: [
      'assets',
      assetId,
      'scan-jobs',
      scanJobId,
      'assets',
      assetId,
      'threats',
      params,
    ],
    queryFn: async () => {
      if (!assetId) {
        throw new Error('assetId is required')
      }
      if (!scanJobId) {
        throw new Error('scanJobId is required')
      }

      return listAssetThreatsForScanJob(assetId, scanJobId, params)
    },
    enabled:
      Boolean(assetId) && Boolean(scanJobId) && isReady && !isClientLoading,
  })
}
