import { useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'
import { useListAssetFilterQuery } from 'ui-v2/src/hooks/queries/assets/filters-list'

const RegionFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsRegionList =
    searchParams.get(ASSET_FILTERS_MAP.REGION)?.split(',') || []

  const { assetKind } = useParams<{ assetKind: string | undefined }>()
  const { data: filterData } = useListAssetFilterQuery({ asset: assetKind })

  const awsRegionOptions = useMemo(() => {
    if (!filterData || !filterData.data.data.region) {
      return []
    }

    return filterData.data.data.region.map((awsRegion) => ({
      value: awsRegion.region,
      label:
        LangAwsRegionConstant.get(awsRegion.region)?.en?.single ??
        awsRegion.region,
    }))
  }, [filterData])

  const handleAwsRegionChange = (
    newAwsRegionList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsRegionList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.REGION)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.REGION, newAwsRegionList.join(','))
    }

    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsRegionOptions}
      value={awsRegionList}
      onChange={handleAwsRegionChange}
      label={
        <SelectLabelText
          selectedValueLength={awsRegionList?.length}
          optionsLength={awsRegionOptions?.length}
          text="Region"
        />
      }
      width={121}
    />
  )
}

export default RegionFilter
