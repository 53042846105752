/* eslint-disable import/no-extraneous-dependencies */
import RadioGroup from 'ui-v2/src/components/ui/inputs/radio-group'
import { SelectedVariant } from 'ui-v2/src/lib/constants/policies.constant'
import {
  AssetVariants,
  AssetVariantType,
  PolicyEc2EbsTableRow,
  PolicyFormData,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { Stack, Typography } from '@mui/material'

import Grid from '@mui/material/Grid2'

import { Controller, useFormContext } from 'react-hook-form'
import AssetsEc2 from './ec2'
import AssetsEbs from './ebs'
import Tags from './tags'
import { LiveAssets } from 'ui-v2/src/lib/models/assets'
import { AssetsForPolicyCoverage } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { useMemo } from 'react'
import { convertBackupsToMap } from 'ui-v2/src/lib/helpers/policies.helper'
import { BackupProcessor } from 'ui-v2/src/lib/models/assets/backup-processor'
import { PolicyEc2EbsTableData } from 'ui-v2/src/lib/use-cases/settings/policies/ec2-ebs-table-data'

const AssetEc2Ebs = ({
  liveAssetsData,
  ec2Assets,
  ebsAssets,
  isLoading,
  handlEc2EbsAllSelect,
  handleAssetsSelect,
  isEditingPolicy,
}: AssetEc2EbsProps) => {
  const { control, setValue, watch, getValues } =
    useFormContext<PolicyFormData>()
  const selectedVariant = watch('selectedVariant')
  const assetVariant = watch('assetVariant')
  const shouldRenderSpecificAssets =
    selectedVariant === SelectedVariant.AssetsVariant

  const shouldRenderSpecificTags =
    selectedVariant === SelectedVariant.TagsVariant

  const { ec2EbsPolicyTableData, ec2PolicyTableData, ebsPolicyTableData } =
    useMemo(() => {
      if (!liveAssetsData || !ec2Assets || !ebsAssets) {
        return {
          ec2EbsPolicyTableData: [],
          ec2PolicyTableData: [],
          ebsPolicyTableData: [],
        }
      }

      const lastBackup = convertBackupsToMap(
        liveAssetsData.lastBackups as unknown as BackupProcessor
      )

      const ec2EbsData = new PolicyEc2EbsTableData({
        ec2EbsAssets: [...ebsAssets, ...ec2Assets],
        lastBackup,
      }).getEc2AssetsList()

      const ec2Data = ec2EbsData.filter(
        (data) => data.assetType === AssetVariantType.EC2
      )

      const ebsData = ec2EbsData.filter(
        (data) => data.assetType === AssetVariantType.EBS
      )

      return {
        ec2EbsPolicyTableData: ec2EbsData,
        ec2PolicyTableData: ec2Data,
        ebsPolicyTableData: ebsData,
      }
    }, [liveAssetsData, ec2Assets, ebsAssets])

  return (
    <Grid container>
      <Grid size={12}>
        <Stack flexDirection={'row'} alignItems={'center'}>
          <Typography variant="body1" mr={1}>
            Select By:
          </Typography>
          <Controller
            name="selectedVariant"
            control={control}
            rules={{
              validate: (value) => {
                const tabAssetTypeSelectedNum = getValues(
                  'tabAssetTypeSelectedNum'
                )
                const policyTags = getValues('policyTags')
                if (
                  tabAssetTypeSelectedNum === AssetVariants.EBS &&
                  value === SelectedVariant.TagsVariant &&
                  policyTags.length === 0
                ) {
                  return 'Please add at least one Tag'
                }
              },
            }}
            render={({ field, fieldState }) => (
              <RadioGroup
                {...field}
                row
                value={field.value}
                options={[
                  {
                    label: 'All Assets',
                    value: SelectedVariant.AllEC2EBSVariant,
                  },
                  {
                    label: 'Specific Assets',
                    value: SelectedVariant.AssetsVariant,
                  },
                  {
                    label: 'Asset Tags',
                    value: SelectedVariant.TagsVariant,
                  },
                ]}
                disabled={isEditingPolicy}
                onChange={(e) => {
                  field.onChange(+e.target.value)
                  setValue('assetVariant', AssetVariantType.EC2)

                  if (+e.target.value === SelectedVariant.AllEC2EBSVariant) {
                    handlEc2EbsAllSelect()
                  }
                }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
      </Grid>
      <Grid size={12}>
        {shouldRenderSpecificAssets && (
          <>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Typography variant="body1" mr={1}>
                Asset:
              </Typography>
              <Controller
                name="assetVariant"
                control={control}
                rules={{
                  validate: (value) => {
                    const tabAssetTypeSelectedNum = getValues(
                      'tabAssetTypeSelectedNum'
                    )
                    const variant = getValues('selectedVariant')
                    const selectedEc2EbsAssetsRows = getValues(
                      'selectedEc2EbsAssetsRows'
                    )

                    if (
                      tabAssetTypeSelectedNum === AssetVariants.EBS &&
                      variant === SelectedVariant.AssetsVariant
                    ) {
                      if (
                        value === AssetVariantType.EC2 &&
                        ec2Assets?.length &&
                        selectedEc2EbsAssetsRows.length === 0
                      ) {
                        return 'Please select at least one EC2'
                      }

                      if (
                        value === AssetVariantType.EBS &&
                        ebsAssets?.length &&
                        selectedEc2EbsAssetsRows.length === 0
                      ) {
                        return 'Please select at least one EBS'
                      }
                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <RadioGroup
                    row
                    value={assetVariant}
                    options={[
                      {
                        label: AssetVariantType.EC2,
                        value: AssetVariantType.EC2,
                      },
                      {
                        label: AssetVariantType.EBS,
                        value: AssetVariantType.EBS,
                      },
                    ]}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Stack>
            {assetVariant === AssetVariantType.EC2 &&
              !!liveAssetsData &&
              !!ec2Assets && (
                <AssetsEc2
                  ec2EbsPolicyTableData={ec2EbsPolicyTableData}
                  ec2PolicyTableData={ec2PolicyTableData}
                  isLoading={isLoading}
                  handleAssetsSelect={handleAssetsSelect}
                />
              )}
            {assetVariant === AssetVariantType.EBS &&
              !!liveAssetsData &&
              !!ebsAssets && (
                <AssetsEbs
                  ec2EbsPolicyTableData={ec2EbsPolicyTableData}
                  ebsPolicyTableData={ebsPolicyTableData}
                  isLoading={isLoading}
                  handleAssetsSelect={handleAssetsSelect}
                />
              )}
          </>
        )}

        {shouldRenderSpecificTags && <Tags />}
      </Grid>
    </Grid>
  )
}
interface AssetEc2EbsProps {
  liveAssetsData: LiveAssets | undefined
  ec2Assets: AssetsForPolicyCoverage | undefined
  ebsAssets: AssetsForPolicyCoverage | undefined
  isLoading: boolean
  isEditingPolicy: boolean
  handlEc2EbsAllSelect: () => void
  handleAssetsSelect: (rows: Array<PolicyEc2EbsTableRow>) => void
}
export default AssetEc2Ebs
