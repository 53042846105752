import { useEffect, useState } from 'react'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  POLICIES_SETTINGS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import {
  AssetType,
  AssetVariantType,
  PolicyFormData,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import {
  DEFAULT_TIME_ZONE,
  EMPTY_SCAN_WITH_TIMESTAMPS,
  EMPTY_SCHEDULE,
} from 'ui-v2/src/lib/constants/time.constant'
import {
  IntegrityCheckOptions,
  IntegrityScanOptions,
  ProtectNewImmediately,
  SelectedCloudConnectorVariant,
  SelectedVariant,
  SnapshotImport,
  TabAssetType,
} from 'ui-v2/src/lib/constants/policies.constant'
import { base64Decode } from 'ui-v2/src/lib/helpers/string.helper'
import { usePlanInfoSelectedAssetsQuery } from 'ui-v2/src/hooks/queries/pechkin/plan-info-selected-assets'
import { PolicyInfoWithSelectedAssets } from 'ui-v2/src/lib/models/pechkin/pechkin'
import PolicyForm from './policy-form'

const steps = 4

const PolicyDrawer = ({ existingPoliciesNames }: PolicyDrawerProps) => {
  const { action, id } = useParams()
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState<number>(0)

  const method = useForm<PolicyFormData>({
    defaultValues: {
      policyName: '',
      description: '',
      schedule: EMPTY_SCHEDULE(),
      integrityScan: {
        scanForMalware: true,
        scanForRansomware: true,
      },
      policyTags: [],
      selectedVariant: SelectedVariant.AllEC2EBSVariant,
      integrityCheck: IntegrityCheckOptions.INTEGRITY_CHECK_FILE_SYSTEM,
      snapshotImportVariant: SnapshotImport.SKIP,
      protectNewImmediately: ProtectNewImmediately.DISABLED,
      selectedVaultsList: [],
      skipEbsBackup: false,
      selectedCloudConnectorVariant: SelectedCloudConnectorVariant.ALL,
      selectedCloudConnectors: [],
      integrityScanOption: IntegrityScanOptions.SCAN_WITH_ELASTIO,
      keepDataCopy: {
        keepLastClean: true,
        keepLatestInfected: true,
      },
      scanWithTimestamps: EMPTY_SCAN_WITH_TIMESTAMPS(),
      isEntropyDetectionEnabled: true,
      timezone: DEFAULT_TIME_ZONE(),
      tabAssetTypeSelectedNum: 0,
      assetVariant: AssetVariantType.EC2,
      selectedEc2EbsAssetsRows: [],
      selectedS3BucketAssetsRows: [],
      selectedEfsAssetsRows: [],
      selectedAssets: [],
      selectedS3Assets: [],
      selectedEfsAssets: [],
    },
  })

  const { handleSubmit, reset, setValue } = method

  const {
    data: planInfoSelectedAssetsData,
    isLoading: isPlanSelectedAssetsDataLoading,
  } = usePlanInfoSelectedAssetsQuery(id ? base64Decode(id) : '')

  const handleCloseDrawer = () => {
    setActiveStep(0)
    reset()
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.POLICIES}/${POLICIES_SETTINGS_ROUTE_SEGMENTS.PROTECTION_POLICIES}`
    )
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const handlePolicyDrawerForm = (
    planSelectedAssetsData: PolicyInfoWithSelectedAssets
  ) => {
    const {
      policyName,
      schedule,
      iscan,
      isEntropyDetectionEnabled,
      integrityCheck,
      selectedAssets,
      selectedS3Assets,
      policyTags,
      isType,
      accountRegionSelectorsList,
      selectedEfsAssets,
      keepDataCopy,
      protectNewImmediately,
      scanWithTimestamps,
      snapshotImportVariant,
      skipEbsBackup,
    } = planSelectedAssetsData

    setValue('policyName', policyName)
    setValue('schedule', schedule)
    setValue('selectedCloudConnectors', accountRegionSelectorsList)

    // set up the selected tab
    let selectedVariant = SelectedVariant.AllEC2Variant
    let selectedTab = TabAssetType.Ec2andEbs

    if (policyTags.length > 0 && isType === undefined) {
      selectedVariant = SelectedVariant.AllEC2EBSVariant
    } else if (selectedAssets?.length > 0) {
      selectedVariant = SelectedVariant.AssetsVariant
    } else if (selectedS3Assets?.length !== 0) {
      selectedTab = TabAssetType.S3
      selectedVariant = SelectedVariant.S3Variant
    } else if (selectedEfsAssets?.length !== 0) {
      selectedTab = TabAssetType.EFS
      selectedVariant = SelectedVariant.EfsVariant
    } else if (selectedAssets.length === 0 && policyTags?.length > 0) {
      selectedVariant = SelectedVariant.TagsVariant
    } else if (isType !== undefined) {
      if (isType == AssetType.ASSET_TYPE_EC2) {
        selectedTab = TabAssetType.Ec2andEbs
        selectedVariant = SelectedVariant.AllEC2Variant
      }
      if (isType == AssetType.ASSET_TYPE_EBS) {
        selectedTab = TabAssetType.Ec2andEbs
        selectedVariant = SelectedVariant.AllEBSVariant
      }
      if (isType == AssetType.ASSET_TYPE_EC2_EBS) {
        selectedTab = TabAssetType.Ec2andEbs
        selectedVariant = SelectedVariant.AllEC2EBSVariant
      }
      if (isType == AssetType.ASSET_TYPE_S3) {
        selectedTab = TabAssetType.S3
        selectedVariant = SelectedVariant.AllS3Variant
      }
    }
    setValue('selectedVariant', selectedVariant)
    setValue('tabAssetTypeSelectedNum', selectedTab)
    setValue('selectedAssets', selectedAssets)
    setValue('selectedS3Assets', selectedS3Assets)
    setValue('selectedEfsAssets', selectedEfsAssets)
    setValue('integrityScan', iscan)
    setValue('isEntropyDetectionEnabled', isEntropyDetectionEnabled)
    setValue('integrityCheck', integrityCheck as IntegrityCheckOptions)
    setValue('keepDataCopy', keepDataCopy)
    setValue('protectNewImmediately', protectNewImmediately)
    setValue('scanWithTimestamps', scanWithTimestamps)
    setValue('policyTags', policyTags)
    setValue('snapshotImportVariant', snapshotImportVariant)
    setValue('skipEbsBackup', !!skipEbsBackup)
  }

  useEffect(() => {
    if (planInfoSelectedAssetsData) {
      handlePolicyDrawerForm(planInfoSelectedAssetsData)
    }
  }, [planInfoSelectedAssetsData])

  return (
    <Drawer
      open={!!action}
      onClose={handleCloseDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: activeStep === 0 ? 640 : 1120,
        },
      }}
    >
      <DrawerContent
        isLoading={isPlanSelectedAssetsDataLoading}
        isEmpty={false}
      >
        <FormProvider {...method}>
          <PolicyForm
            handleSubmit={handleSubmit}
            activeStep={activeStep}
            steps={steps}
            id={id}
            handleBack={handleBack}
            handleNext={handleNext}
            handleCloseDrawer={handleCloseDrawer}
            existingPoliciesNames={existingPoliciesNames}
          />
        </FormProvider>
      </DrawerContent>
    </Drawer>
  )
}
interface PolicyDrawerProps {
  existingPoliciesNames: Array<string>
}
export default PolicyDrawer
