import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { NotificationsRulesClient } from 'ui-v2/src/lib/clients'
import { EventsClient } from 'ui-v2/src/lib/clients/events'

type ListNotificationRulesFilters = {
  search: string
}

export function useListNotificationsRulesQuery(
  filters: ListNotificationRulesFilters
) {
  const notificationsRulesClient = useGrpcClient(NotificationsRulesClient)

  return useQuery({
    queryKey: ['notification-rules', 'list', filters],
    queryFn: async () => {
      if (!notificationsRulesClient) {
        throw new Error('Notifications rules client not initialized')
      }
      return notificationsRulesClient.getNotificationRulesList(filters)
    },
    enabled: !!notificationsRulesClient,
  })
}

export function useListEventKindsQuery() {
  const eventKindsClient = useGrpcClient(EventsClient)

  return useQuery({
    queryKey: ['notification-rules', 'list-event-kinds'],
    queryFn: async () => {
      if (!eventKindsClient) {
        throw new Error('Event kinds client not initialized')
      }
      return eventKindsClient.getListEventKinds()
    },
    enabled: !!eventKindsClient,
  })
}

interface ListEventFilters {
  eventKinds: Array<string>
  token: string
  start: Date
  end: Date
}

export const useListEventsQuery = (filters: ListEventFilters) => {
  const eventKindsClient = useGrpcClient(EventsClient)

  return useQuery({
    queryKey: ['notification-rules', 'list-event', filters],
    queryFn: async () => {
      if (!eventKindsClient) {
        throw new Error('Event kinds client not initialized')
      }
      return eventKindsClient.getListEvents(filters)
    },
    enabled: !!eventKindsClient,
  })
}
