/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { useAssetsThreatsFilters } from 'ui-v2/src/hooks/filters/assets-threats'
import {
  ASSETS_THREATS_FILTERS_MAP,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { useSearchParams } from 'react-router-dom'
import {
  initialPrioritySortForEFSTable,
  useLiveAssetsDataForAssetsThreat,
} from '../utils'
import {
  listEfsWithActiveThreatsPossibleFilters,
  ListEfsWithThreats,
} from 'blues-corejs/dist'
import { ValueInterface } from 'ui-v2/src/lib/engine-types'
import DataHelper from 'ui-v2/src/lib/helpers/data.helper'
import { Stack } from '@mui/material'
import AssetsThreatsFilters from '../filters'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { getEfsThreatColumns } from './column-config'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import { AssetThreatsTabs, TAB_FILTERS } from '../filters/config'
import ThreatList from '../threat-list'

const AssetsThreatsEfs = () => {
  const filters = useAssetsThreatsFilters()
  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)

  const { data: liveAssetsData, isLoading } = useLiveAssetsDataForAssetsThreat()

  const data = useMemo(() => {
    if (!liveAssetsData) {
      return undefined
    }

    const { filesystems, threats, lastMalwareScans, lastRansomwareScans } =
      liveAssetsData

    if (filesystems.length) {
      return {
        filesystems,
        threats,
        lastScans: [...lastMalwareScans, ...lastRansomwareScans],
      }
    }
  }, [liveAssetsData])

  const possibleEfsWithActiveThreatsFilters = useMemo(() => {
    if (!data) {
      return undefined
    }

    return new listEfsWithActiveThreatsPossibleFilters({
      efsList: data.filesystems,
      threats: data.threats,
    }).execute().possibleEfsWithActiveThreatsFilters
  }, [data])

  const efsFilteredData = useMemo(() => {
    if (!data) {
      return undefined
    }
    const parseFilters = (selectedFilters: Array<ValueInterface>) => {
      return DataHelper.getDataForDashboardFilter(selectedFilters, search ?? '')
    }

    return initialPrioritySortForEFSTable(
      new ListEfsWithThreats(data).execute(parseFilters(filters))
    )
  }, [data, filters])

  const columns = useMemo(getEfsThreatColumns, [])

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems={'center'}>
        <SearchBar />
        {!isLoading && <ThreatList filters={filters} />}
      </Stack>
      <AssetsThreatsFilters
        filters={TAB_FILTERS[AssetThreatsTabs.EFS]}
        filterValues={{
          [ASSETS_THREATS_FILTERS_MAP.REGION]:
            possibleEfsWithActiveThreatsFilters?.regions,
          [ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID]:
            possibleEfsWithActiveThreatsFilters?.accountIds,
          [ASSETS_THREATS_FILTERS_MAP.RANSOMWARE]:
            possibleEfsWithActiveThreatsFilters?.ransomwaresList,
          [ASSETS_THREATS_FILTERS_MAP.MALWARE]:
            possibleEfsWithActiveThreatsFilters?.malwaresList,
        }}
      />
      <ClientTable
        idField="efsId"
        data={efsFilteredData ?? []}
        columns={columns}
        isLoading={isLoading}
      />
    </Stack>
  )
}

export default AssetsThreatsEfs
