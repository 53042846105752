import { PersonalAccessTokensPromiseClient } from 'blue-stack-libs/users-grpc-libs/js/users/users_grpc_web_pb'

import { GrpcClient } from 'ui-v2/src/lib/clients/grpc'
import { ClientConfig } from 'ui-v2/src/lib/models/client'
import AccessTokenModel from 'ui-v2/src/lib/models/settings/api-access/access-token.model'

import {
  IssueRequest,
  ListRequest,
  RevokeRequest,
  TokenDetailsByIdRequest,
  UpdateRequest,
} from 'blue-stack-libs/users-grpc-libs/js/users/messages/pat_pb'

import TypeHelper from 'ui-v2/src/lib/helpers/type.helper'
import { numberHash } from 'ui-v2/src/lib/helpers/num.helper'
import { TokenIssueResultInterface } from 'ui-v2/src/lib/models/settings/api-access'

export const buildFromGRPC = (params: any): AccessTokenModel => {
  return new AccessTokenModel({
    id: numberHash(params.id),
    innerId: TypeHelper.stringValDef(params.id, ''),
    createdAt: TypeHelper.momentVal(params.createdAt.seconds * 1000),
    updatedAt: TypeHelper.momentVal(params.updatedAt.seconds * 1000),
    name: TypeHelper.stringValDef(params.name, ''),
    scopesList: TypeHelper.arrayStringValDef(params.scopesList, []),
    tenantId: TypeHelper.stringValDef(params.tenantId, ''),
    userId: TypeHelper.stringValDef(params.userId, ''),
  })
}
export const buildEmpty = (): AccessTokenModel => {
  return new AccessTokenModel({
    name: '',
    scopesList: [],
  })
}

export class PersonalAccessTokenClient extends GrpcClient<PersonalAccessTokensPromiseClient> {
  #client: PersonalAccessTokensPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#client = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'PersonalAccessTokenClient'
  }

  protected initClient(hostname = ''): PersonalAccessTokensPromiseClient {
    return new PersonalAccessTokensPromiseClient(hostname, null, null)
  }

  async list(): Promise<Array<AccessTokenModel>> {
    const request = new ListRequest()

    const result = await this.callGrpcService(
      () => this.#client.list(request, this.metadata(this.#token)),
      {
        requestName: 'PersonalAccessTokensPromiseClient/List',
      }
    )
    return result.toObject().tokensDetailsList.map(buildFromGRPC)
  }

  async listRevoked(): Promise<Array<AccessTokenModel>> {
    const request = new ListRequest()

    const result = await this.callGrpcService(
      () => this.#client.listRevoked(request, this.metadata(this.#token)),
      {
        requestName: 'PersonalAccessTokensPromiseClient/ListRevoked',
      }
    )
    return result.toObject().tokensDetailsList.map(buildFromGRPC)
  }

  async getTokenDetailsById(id: string): Promise<AccessTokenModel> {
    const request = new TokenDetailsByIdRequest()
    request.setTokenId(id)
    const result = await this.callGrpcService(
      () =>
        this.#client.getTokenDetailsById(request, this.metadata(this.#token)),
      {
        requestName: 'PersonalAccessTokensPromiseClient/GetTokenDetailsById',
      }
    )
    return buildFromGRPC(result.toObject())
  }

  async issue(
    tokenName: string,
    scopes: Array<string>
  ): Promise<TokenIssueResultInterface> {
    const request = new IssueRequest()

    request.setName(tokenName)
    request.setScopesList(scopes)

    const result = await this.callGrpcService(
      () => this.#client.issue(request, this.metadata(this.#token)),
      {
        requestName: 'PersonalAccessTokensPromiseClient/Issue',
      }
    )
    const resultObj = result.toObject()

    if (!resultObj || !resultObj.details) {
      return {
        secretToken: '',
        generatedToken: buildEmpty(),
      }
    }
    return {
      secretToken: resultObj.token,
      generatedToken: buildFromGRPC(resultObj.details),
    }
  }

  async revoke(tokens: Array<string>): Promise<any> {
    const request = new RevokeRequest()
    request.setTokensIdsList(tokens)
    const result = await this.callGrpcService(
      () => this.#client.revoke(request, this.metadata(this.#token)),
      {
        requestName: 'PersonalAccessTokensPromiseClient/revoke',
      }
    )
    return result.toObject()
  }

  async update(
    tokenId: string,
    tokenName: string,
    scopes: Array<string>
  ): Promise<any> {
    const request = new UpdateRequest()
    request.setTokenId(tokenId)
    request.setName(tokenName)
    request.setScopesList(scopes)
    const result = await this.callGrpcService(
      () => this.#client.update(request, this.metadata(this.#token)),
      {
        requestName: 'PersonalAccessTokensPromiseClient/Update',
      }
    )
    return result.toObject()
  }
}
