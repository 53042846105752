import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { Drawer } from 'ui-v2/src/components/ui/drawer'
import { Box, IconButton, Typography, Button as ButtonMUI } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAlertsActiveRuleStore } from '../store/alerts-active-rule-store'
import DetailRuleContent from './detail-rule-content'

const DetailRule = () => {
  const navigate = useNavigate()
  const { action } = useParams()
  const { setActiveRule } = useAlertsActiveRuleStore()
  const isDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.DETAIL_ALERT_RULE

  const handleCloseDrawer = () => {
    setActiveRule(null)
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.ALERTS}/${GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS.ALERTS}`
    )
  }

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: { width: '600px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
            borderBottom: '1px solid',
            borderColor: 'divider',
            pb: 2,
          }}
        >
          <Typography variant="h6">Detail rule</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <ButtonMUI
              variant="contained"
              color="inherit"
              size="small"
              component={Link}
              to={`/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.ALERTS}/${GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS.ALERTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.UPDATE_ALERT_RULE}`}
            >
              Edit
            </ButtonMUI>
            <IconButton onClick={handleCloseDrawer} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {isDrawerOpen && <DetailRuleContent />}
      </Box>
    </Drawer>
  )
}

export default DetailRule
