import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import { Grid2 } from '@mui/material'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import { useAssetDetails } from '../../widgets/common/use-asset-details'
import ScanListItem from './scan-list-item'
import { processKinds } from '../../utils'
import Loading from '../common/loading'
import NoDataDisplay from 'ui-v2/src/components/common/no-data'
import { useListScanLogInfiniteQuery } from 'ui-v2/src/hooks/queries/scan-log'
import { PAGE_SIZE } from '../constants'
import VolumeTableContainer from './volumes-table-container'

const ScanLog = () => {
  const { parentAssetId } = useAssetDetails()
  const { recoveryPointId } = useParams<{
    recoveryPointId: string | undefined
  }>()
  const [searchParams] = useSearchParams()
  const kindFilter = searchParams.get('backupType')
  const { keyKind, backupType } = processKinds(kindFilter)
  const daysFilter = searchParams
    .get('days')
    ?.split(',')
    ?.map(Number)
    ?.sort((a, b) => a - b)
  const lastBiggestDate = daysFilter
    ? daysFilter[daysFilter.length - 1]
    : undefined

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isFetching,
  } = useListScanLogInfiniteQuery(parentAssetId, {
    pageSize: PAGE_SIZE,
    days: lastBiggestDate,
    ...(keyKind && { type: keyKind }),
    ...(backupType && { backupType }),
  })

  const items = data?.pages?.flatMap((page) => page.items) || []

  const loadMore = React.useCallback(() => {
    if (hasNextPage && !isFetchingNextPage && !isFetching) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetchingNextPage, isFetching, fetchNextPage])

  if (status === 'pending') {
    return (
      <Loading
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      />
    )
  }

  if (status === 'error') {
    return (
      <ErrorDisplay message="Error loading scan log. Please try again later." />
    )
  }

  if (items.length === 0) {
    return <NoDataDisplay message="No scan logs" />
  }

  const Footer = () => {
    if (isFetchingNextPage || isFetching) {
      return <Loading />
    }

    return null
  }

  return (
    <Grid2 container height={'100%'} direction="row" wrap="nowrap">
      <Grid2 height={'100%'} minWidth={'288px'} flexShrink={0}>
        <Virtuoso
          className="custom-scrollbar"
          style={{
            height: '100%',
            width: '288px',
          }}
          data={items}
          endReached={loadMore}
          overscan={200}
          itemContent={(_, scanJob) => (
            <ScanListItem scanJob={scanJob} defaultItemId={items[0]?.job_id} />
          )}
          components={{
            Footer,
          }}
        />
      </Grid2>

      {recoveryPointId ? (
        <Grid2 height={'100%'} minWidth={0} flexGrow={1}>
          <VolumeTableContainer />
        </Grid2>
      ) : null}
    </Grid2>
  )
}

export default ScanLog
