import React from 'react'
import { Skeleton, Stack } from '@mui/material'

const LoadingContent = () => (
  <Stack spacing={0.5}>
    <Stack spacing={0.5}>
      <Skeleton variant="text" width={60} height={20} />
      <Skeleton variant="text" width={100} height={20} />
    </Stack>
    <Stack spacing={0.5}>
      <Skeleton variant="text" width={60} height={20} />
      <Skeleton variant="text" width={120} height={20} />
    </Stack>
    <Stack spacing={0.5}>
      <Skeleton variant="text" width={60} height={20} />
      <Skeleton variant="text" width={120} height={20} />
    </Stack>
  </Stack>
)

export default LoadingContent
