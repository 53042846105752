/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import {
  NotificationRule,
  RuleStatus,
} from 'blues-corejs/dist/models/notifications/rule'
import ActionMenu, {
  MenuInterface,
} from 'ui-v2/src/components/ui/data-table/action-menu'
import { useAlertsActions } from '../use-alerts-actions'
import ConfirmationDialog from 'ui-v2/src/components/dialogs/confirmation'
import {
  CancelOutlined,
  DeleteForeverOutlined,
  TaskAltOutlined,
  ModeEditOutline,
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useAlertsActiveRuleStore } from '../store/alerts-active-rule-store'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { useNavigate } from 'react-router-dom'

const iconStyles = {
  color: 'icon.dark',
}

const boxStyle = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
}

interface Props {
  rule: NotificationRule
}

const updateLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.ALERTS}/${GENERAL_SETTINGS_ALERTS_ROUTE_SEGMENTS.ALERTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.UPDATE_ALERT_RULE}`

function AlertsCellActions({ rule }: Props) {
  const navigate = useNavigate()
  const { handleDisableRule, handleEnableRule, handleDeleteRule } =
    useAlertsActions()
  const { setActiveRule } = useAlertsActiveRuleStore()
  const [isOpenConfirmation, setOpenConfirmation] = useState(false)

  const { id, status } = rule

  const options: Array<MenuInterface> = [
    {
      name: (
        <Box sx={boxStyle}>
          <DeleteForeverOutlined fontSize="small" sx={iconStyles} /> Delete
        </Box>
      ),
      action: () => {
        setOpenConfirmation(true)
      },
    },
  ]

  if (status === RuleStatus.disabled) {
    options.unshift({
      name: (
        <Box sx={boxStyle}>
          <TaskAltOutlined fontSize="small" sx={iconStyles} />
          Enable
        </Box>
      ),
      action: () => {
        handleEnableRule([id])
      },
    })
  } else {
    options.unshift({
      name: (
        <Box sx={boxStyle}>
          <CancelOutlined fontSize="small" sx={iconStyles} />
          Disable
        </Box>
      ),
      action: () => {
        handleDisableRule([id])
      },
    })
  }

  options.unshift({
    name: (
      <Box sx={boxStyle}>
        <ModeEditOutline fontSize="small" sx={iconStyles} /> Edit
      </Box>
    ),
    action: () => {
      setActiveRule(rule)
      navigate(updateLink)
    },
  })

  return (
    <>
      <ActionMenu menuItems={options} />
      <ConfirmationDialog
        open={isOpenConfirmation}
        title="Confirmation"
        onClose={() => setOpenConfirmation(false)}
        onConfirm={() => handleDeleteRule([rule.id])}
        confirmText="Yes, Proceed"
        cancelText="No, Cancel"
      >
        <Typography>
          You are going to remove the Policy {rule.name} Please confirm the
          deletion.
        </Typography>
      </ConfirmationDialog>
    </>
  )
}

export default AlertsCellActions
