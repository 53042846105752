import { useSelector } from 'react-redux'
import { DEFAULT_TIME_ZONE } from 'ui-v2/src/lib/constants/time.constant'
import { RootState } from 'ui-v2/src/store'

export function useUserTimezoneValue() {
  const userConfig = useSelector((state: RootState) => state.user.userConfig)

  if (!userConfig || !userConfig.TimeZone || !userConfig.TimeZone.value) {
    return DEFAULT_TIME_ZONE().value
  }

  try {
    const decoded = atob(userConfig.TimeZone.value)
    const parsed = JSON.parse(decoded)
    return parsed && parsed.value !== undefined
      ? parsed.value
      : DEFAULT_TIME_ZONE().value
  } catch {
    return DEFAULT_TIME_ZONE().value
  }
}
