interface FolderIconProps extends React.SVGProps<SVGSVGElement> {
  isHealthy: boolean
  atRisk: boolean
}

const FolderIcon = ({ isHealthy = true, atRisk }: FolderIconProps) => {
  if (isHealthy) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <g clipPath="url(#clip0_40001516_68015)">
          <path
            d="M10.59 5.09C10.21 4.71 9.7 4.5 9.17 4.5H4C2.9 4.5 2.01 5.4 2.01 6.5L2 18.5C2 19.6 2.9 20.5 4 20.5H20C21.1 20.5 22 19.6 22 18.5V8.5C22 7.4 21.1 6.5 20 6.5H12L10.59 5.09Z"
            fill="url(#paint0_linear_40001516_68015)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_40001516_68015"
            x1="2"
            y1="1604.5"
            x2="1562.98"
            y2="-346.72"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1B660F" />
            <stop offset="1" stop-color="#6CAE3E" />
          </linearGradient>
          <clipPath id="clip0_40001516_68015">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    )
  } else if (atRisk) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <g clip-path="url(#clip0_40003839_70974)">
          <path
            d="M10.59 5.09C10.21 4.71 9.7 4.5 9.17 4.5H4C2.9 4.5 2.01 5.4 2.01 6.5L2 18.5C2 19.6 2.9 20.5 4 20.5H20C21.1 20.5 22 19.6 22 18.5V8.5C22 7.4 21.1 6.5 20 6.5H12L10.59 5.09Z"
            fill="#FFBA54"
          />
        </g>
        <defs>
          <clipPath id="clip0_40003839_70974">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <g clipPath="url(#clip0_40001516_69900)">
          <path
            d="M10.59 5.09C10.21 4.71 9.7 4.5 9.17 4.5H4C2.9 4.5 2.01 5.4 2.01 6.5L2 18.5C2 19.6 2.9 20.5 4 20.5H20C21.1 20.5 22 19.6 22 18.5V8.5C22 7.4 21.1 6.5 20 6.5H12L10.59 5.09Z"
            fill="#BA0B0B"
          />
        </g>
        <defs>
          <clipPath id="clip0_40001516_69900">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default FolderIcon
