import React, { ReactNode } from 'react'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { Tooltip, TooltipProps } from '@mui/material'
import CircleSuccessIcon from 'ui-v2/src/assets/icons/circle-success-icon'
import CircleRejectIcon from 'ui-v2/src/assets/icons/circle-reject-icon'
import TriangleWarningIcon from 'ui-v2/src/assets/icons/triangle-warning-icon'
import CirclePendingIcon from 'ui-v2/src/assets/icons/circle-pending-icon'
import {
  AccountStatusConstant,
  ActiveDeploymentTableData,
  AssetsAccountTableData,
} from './types'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import MenuItemsExpanded from './table-cell/menu-items-expanded'
import MenuItemsConnector from './table-cell/menu-items-connectors'

function CustomTooltip({ title, children, ...props }: TooltipProps) {
  return (
    <Tooltip
      placement="right"
      slotProps={{
        tooltip: {
          sx: {
            bgcolor: (theme) => theme.palette.background.elevation3,
            color: (theme) => theme.palette.text.primary,
          },
        },
      }}
      title={title}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

function drawAWSStatus(
  status: AccountStatusConstant,
  messages: Array<string> = []
): ReactNode {
  let IconComponent: React.FC
  switch (status) {
    case AccountStatusConstant.CONNECTED:
      IconComponent = CircleSuccessIcon
      break
    case AccountStatusConstant.DISCONNECTED:
      IconComponent = CircleRejectIcon
      break
    case AccountStatusConstant.MIXED:
      IconComponent = CirclePendingIcon
      break
    case AccountStatusConstant.UPGRADE_REQUIRED:
      IconComponent = TriangleWarningIcon
      break
    default:
      IconComponent = () => null
  }

  if (!messages?.length && status !== AccountStatusConstant.UPGRADE_REQUIRED) {
    return <IconComponent />
  }

  const tooltipContent =
    status === AccountStatusConstant.UPGRADE_REQUIRED ? (
      <>
        <div>Upgrade Available, select Upgrade from the menu to proceed.</div>
        {messages?.map((m) => <div key={m}>{m}</div>)}
      </>
    ) : (
      messages?.map((m) => <div key={m}>{m}</div>)
    )

  return (
    <CustomTooltip title={tooltipContent}>
      <div>
        <IconComponent />
      </div>
    </CustomTooltip>
  )
}

export const getActiveDeploymentsColumn = (): Array<
  Column<ActiveDeploymentTableData>
> => [
  {
    key: 'status',
    header: '',
    width: '10px',
    render: (row) => drawAWSStatus(row.status, row.messages),
  },
  {
    key: 'awsAccount',
    header: 'Cloud Connector',
    width: '180px',
    render: (row) => <>{row.awsAccount}</>,
  },
  {
    key: 'accountAlias',
    header: 'Account Alias',
    width: '230px',
    render: (row) => <>{row.accountAlias}</>,
  },
  {
    key: 'awsRegions',
    header: 'Regions',
    width: '235px',
    render: (row) => {
      const text = row.awsRegions.join(', ')

      return <LongTextTooltip text={text} maxLength={30} fontSize="14px" />
    },
  },
  {
    key: 'updatedAt',
    header: 'Last Update',
    width: '210px',
    render: (row) => formatDateToUtc(row.updatedAt),
  },
  {
    key: 'assetsAccount',
    header: 'Protected Accounts',
    render: (row) => <>{row.assetsAccount?.length ?? 0}</>,
  },
  {
    key: 'messages',
    header: '',
    width: '40px',
    render: (row) => {
      return <MenuItemsConnector row={row} />
    },
  },
]

export const getActiveDeploymentsExpandedColumn = (): Array<
  Column<AssetsAccountTableData>
> => [
  {
    key: 'awsAccountId',
    header: 'Protected Account',
    width: '140px',
    render: (row) => <>{row.awsAccountId}</>,
  },
  {
    key: 'stackRegion',
    header: 'Region Name',
    width: '235px',
    render: (row) => {
      const text = [...row.stackRegion].join(', ')

      return <LongTextTooltip text={text} maxLength={30} fontSize="14px" />
    },
  },
  {
    key: 'updatedAt',
    header: 'Last Update',
    width: '210px',
    render: (row) => formatDateToUtc(row.updatedAt),
  },
  {
    key: 'createdAt',
    header: 'Date Deployed',
    render: (row) => formatDateToUtc(row.createdAt),
  },
  {
    key: 'id',
    header: '',
    width: '40px',
    render: (row) => {
      return <MenuItemsExpanded row={row} />
    },
  },
]
