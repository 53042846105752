import { useEffect } from 'react'
import Grid from '@mui/material/Grid2'
import { FormControl, Typography } from '@mui/material'

import { Controller, useFormContext } from 'react-hook-form'

import { DEFAULT_TIME_ZONE } from 'ui-v2/src/lib/constants/time.constant'
import { getDatePeriod } from 'ui-v2/src/lib/helpers/time.helper'

import { DateRangeTuple } from 'ui-v2/src/lib/engine-types'
import Format from '../format'
import OvaAccount from '../ova-account'
import BackupProvider from '../backup-provider'
import { ReportForm, TimeRangeSelection } from 'ui-v2/src/lib/models/reports'
import TimeRange, { TIME_RANGE_MENU_ITEMS } from '../time-range'
import dayjs from 'dayjs'
import DateRangePicker from 'ui-v2/src/components/ui/inputs/date-range-picker'

const BackupConnectorForm = ({
  availableFormats,
}: BackupConnectorFormProps) => {
  const timezone = DEFAULT_TIME_ZONE().value
  const { control, watch, setValue, trigger } = useFormContext<ReportForm>()
  const timeRange = watch('timeRange')
  const selectDateRange = watch('dateRange')

  const selectedDateRange = getDatePeriod(timeRange.value, timezone)
  const dateRange: DateRangeTuple =
    timeRange.value === TimeRangeSelection.CUSTOM_DATE
      ? selectDateRange
      : selectedDateRange

  useEffect(() => {
    setValue('dateRange', dateRange)
  }, [timeRange])

  return (
    <Grid container spacing={2}>
      <Grid
        size={{
          xs: 12,
          sm: 10,
          md: 9,
          lg: 8,
        }}
      >
        <FormControl fullWidth size="small">
          <Typography variant="body1" mb={1}>
            Accounts
          </Typography>
          <Controller
            control={control}
            // rules={{
            //   validate: (value) => {
            //     if (!value || value.length === 0) {
            //       return 'Please select at least one ova account'
            //     }
            //     return true
            //   },
            // }}
            name="ovaAccounts"
            render={({ field, fieldState }) => (
              <OvaAccount
                value={field.value}
                onChange={(options) => {
                  field.onChange(options)
                  trigger('ovaAccounts')
                }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 10,
          md: 9,
          lg: 8,
        }}
      >
        <FormControl fullWidth size="small">
          <Typography variant="body1" mb={1}>
            Backup Provider
          </Typography>
          <Controller
            control={control}
            name="backupProvider"
            render={({ field, fieldState }) => (
              <BackupProvider
                value={field.value}
                onChange={(options) => {
                  field.onChange(options)
                  trigger('backupProvider')
                }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid size={12}>
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              sm: 4,
              md: 4,
            }}
          >
            <FormControl fullWidth size="small">
              <Typography variant="body1" mb={1}>
                Report Period
              </Typography>
              <Controller
                control={control}
                name="timeRange"
                render={({ field }) => (
                  <TimeRange
                    value={field.value.value.toString()}
                    onChange={(reportPeriod) => {
                      field.onChange(reportPeriod)
                    }}
                    isSelectedObj
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 8,
              md: 8,
            }}
          >
            <FormControl fullWidth size="small">
              <Typography variant="body1" mb={1}>
                Date Range
              </Typography>
              <Controller
                control={control}
                name="dateRange"
                render={({ field }) => (
                  <DateRangePicker
                    key={
                      (field.value[0]?.toString() ?? '') +
                      (field.value[1]?.toString() ?? '')
                    }
                    initialDateRange={{
                      startDate: dayjs(field.value[0]),
                      endDate: dayjs(field.value[1]),
                    }}
                    onChange={(range) => {
                      field.onChange([range.startDate, range.endDate])
                      trigger('dateRange')
                      const customTimeRange = TIME_RANGE_MENU_ITEMS.find(
                        (datePickerRange) =>
                          datePickerRange.value ===
                          TimeRangeSelection.CUSTOM_DATE
                      )
                      if (customTimeRange) {
                        setValue('timeRange', {
                          label: customTimeRange.label,
                          value: customTimeRange.value,
                        })
                      }
                    }}
                    label={false}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12}>
        <FormControl>
          <Typography variant="body1" mb={1}>
            Format:
          </Typography>
          <Controller
            control={control}
            rules={{
              validate: (value) => {
                if (!value || value.length === 0) {
                  return 'Please select at least one format'
                }
                return true
              },
            }}
            name="format"
            render={({ field, fieldState }) => (
              <Format
                options={availableFormats.map((format) => ({
                  label: format,
                  value: format,
                }))}
                selectedOptions={field.value}
                onChange={(selected) => {
                  field.onChange(selected)
                  trigger('format')
                }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
interface BackupConnectorFormProps {
  availableFormats: Array<string>
}
export default BackupConnectorForm
