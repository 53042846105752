/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { Asset, EC2 } from 'blues-corejs/dist'
import { useAssetsListScansQuery } from 'ui-v2/src/hooks/queries/scans'

import { ScanTableData } from 'ui-v2/src/lib/use-cases/assets/infected-assets/scan-table-data'
import { getScanColumns } from './column-config'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useScanListFilters } from 'ui-v2/src/hooks/filters/assets/scan'

export function getScansGrpcConfig(asset: Asset) {
  if (asset instanceof EC2) {
    return {
      assetIdList: [...asset.ebsIds, asset.id],
    }
  }

  return {
    assetIdList: [asset.id],
  }
}
const Scans = ({ asset }: ScansProps) => {
  const { filters, setPageSize, setPageToken } = useScanListFilters(
    asset ? getScansGrpcConfig(asset).assetIdList : []
  )
  const { data: assetsListScans, isLoading } = useAssetsListScansQuery(
    {
      ...filters,
      assetIdList: filters.assetIdList ?? [],
    },
    {
      enabled: !!asset,
    }
  )

  const data = useMemo(() => {
    return new ScanTableData({
      scanLists: assetsListScans?.scansRunList ?? [],
    }).getScanList()
  }, [assetsListScans])

  const columns = useMemo(getScanColumns, [])

  return (
    <CursorTable
      checkable={false}
      data={data}
      columns={columns}
      isLoading={isLoading}
      pageSize={filters.pageSize}
      pageToken={filters.pageToken}
      paginationCursors={{
        nextPageToken: assetsListScans?.pagination?.nextPageToken,
        prevPageToken: assetsListScans?.pagination?.prevPageToken,
      }}
      setPageSize={setPageSize}
      setPageToken={setPageToken}
    />
  )
}
interface ScansProps {
  asset: Asset
}
export default Scans
