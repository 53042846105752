/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import {
  Asset,
  EC2,
  EBS,
  GenericHost,
  OvaServer,
  Backup,
} from 'blues-corejs/dist'

import {
  useAssetsListScansQuery,
  useDownloadScansMutation,
  useListScansQuery,
} from 'ui-v2/src/hooks/queries/scans'

import { useRansomwareScan } from 'ui-v2/src/hooks/assets-download/useRansomwareScan'
import { useMalwareScan } from 'ui-v2/src/hooks/assets-download/useMalwareScan'
import { useFsCheckScan } from 'ui-v2/src/hooks/assets-download/useFsCheckScan'
import { useAssetsListBackupsQuery } from 'ui-v2/src/hooks/queries/backups'
import { ScansForBackup } from 'blues-corejs/dist/use_cases/list_recovery_points_for_asset_item_generic/types'
import { Nullable } from '@lib/engine-types'
import DownloadReportsDropdown from './download-reports-dropdown'
import { useToast } from 'ui-v2/src/hooks/toast'
import { Scan } from 'ui-v2/src/lib/models/scans'

function getLastBackupByTimestamp(backups: Array<Backup>) {
  return backups.reduce((previousBackup, currentBackup) =>
    previousBackup.timestamp > currentBackup.timestamp
      ? previousBackup
      : currentBackup
  )
}

function convertDataScanToScans(dataScans: Nullable<ScansForBackup>) {
  if (dataScans) {
    return [
      ...dataScans.malwareScansForBackup,
      ...dataScans.ransomWareScansForBackup,
      ...dataScans.fsCheckScansForBackup,
    ]
  } else {
    return []
  }
}

const DownloadReports = ({ asset, scan }: DownloadReportsProps) => {
  const { mutateAsync, isPending } = useDownloadScansMutation()

  const toast = useToast()

  let threatAsset = asset ? [asset.id] : []
  if (asset instanceof EC2) {
    threatAsset = asset.ebsIds as Array<string>
  }

  const { data: scanData } = useAssetsListScansQuery({
    assetIdList: threatAsset,
  })

  const { data: backupData } = useAssetsListBackupsQuery(
    {
      assetIdList: asset ? [asset.id] : [],
    },
    {
      enabled:
        !(asset instanceof GenericHost) &&
        !(asset instanceof EBS) &&
        !(asset instanceof EC2) &&
        !(asset instanceof OvaServer),
    }
  )

  const lastScanData = useMemo(() => {
    if (!scanData) {
      return []
    }
    const lastScansRun = scanData.scansRunList[0]

    if (!lastScansRun) {
      return []
    }

    return [
      ...lastScansRun.filesystemChecksList,
      ...lastScansRun.malwareScansList,
      ...lastScansRun.ransomwareScansList,
    ]
  }, [scanData])

  const lastBackupForAsset = useMemo(() => {
    if (!backupData) {
      return
    }
    const backupsList = [
      ...backupData.awsbRpsList,
      ...backupData.elastioRpsList,
      ...backupData.ebsSnapshotsList,
      ...backupData.ovaBackupList,
    ]

    if (backupsList.length === 0) {
      return
    }

    return getLastBackupByTimestamp(backupsList)
  }, [backupData])

  const lastBackupScanId = lastBackupForAsset
    ? [...lastBackupForAsset.scanIds]
    : []
  const { data: listScanData } = useListScansQuery(lastBackupScanId, {
    enabled: !!lastBackupScanId.length,
  })

  const dataScans = useMemo(() => {
    if (!listScanData) {
      return {
        malwareScansForBackup: [],
        ransomWareScansForBackup: [],
        fsCheckScansForBackup: [],
      }
    }

    const scansBackup: ScansForBackup = {
      malwareScansForBackup: [],
      ransomWareScansForBackup: [],
      fsCheckScansForBackup: [],
    }

    listScanData.malwares.forEach((scanItem) =>
      scansBackup.malwareScansForBackup.push(scanItem)
    )
    listScanData.ransomwares.forEach((scanItem) =>
      scansBackup.ransomWareScansForBackup.push(scanItem)
    )
    listScanData.filesystemChecks.forEach((scanItem) =>
      scansBackup.fsCheckScansForBackup.push(scanItem)
    )

    return scansBackup
  }, [listScanData])
  const ransomwareScanOptions = useRansomwareScan(
    lastScanData,
    'Scan Ransomeware'
  )
  const malwareScanOptions = useMalwareScan(lastScanData, 'Scan Malware')
  const fsCheckScanOptions = useFsCheckScan(lastScanData, 'Scan Filesystem')

  const scans = convertDataScanToScans(dataScans)

  const ransomwareBackupScanOptions = useRansomwareScan(
    scans,
    'Recovery Point Ransomeware'
  )
  const malwareBackupScanOptions = useMalwareScan(
    scans,
    'Recovery Point Malware'
  )
  const fsCheckBackupScanOptions = useFsCheckScan(
    scans,
    'Recovery Point Filesystem Check'
  )

  const downloadAll = [
    {
      scanId: 'All',
      displayName: 'All',
    },
  ]
  const latestScan = scan?.id
    ? [
        {
          scanId: scan.id,
          displayName: 'Latest Scan',
        },
      ]
    : []
  const downloadOptions = [
    ...ransomwareScanOptions,
    ...malwareScanOptions,
    ...fsCheckScanOptions,
    ...ransomwareBackupScanOptions,
    ...malwareBackupScanOptions,
    ...fsCheckBackupScanOptions,
  ]

  const handleDownloadReports = async (scanId: string) => {
    let scanIds: Array<string> = []
    if (scanId === 'All') {
      const allScanIds: Array<string> = []

      downloadOptions.forEach((scanItem) => {
        if (scanItem?.scanId) {
          allScanIds.push(scanItem.scanId)
        }
      })
      scanIds = allScanIds
    } else {
      scanIds = [scanId]
    }

    try {
      const scanReportLinks = await Promise.all(
        scanIds.map(async (id) => {
          const scanReportLink = await mutateAsync(id)
          return scanReportLink
        })
      )
      if (scanReportLinks?.length > 0) {
        scanReportLinks.forEach((scanReportLink) => {
          window.open(scanReportLink, '_blank')
        })
      }
    } catch (error: any) {
      toast.error(error.message ?? 'Failed to download scan report')
    }
  }

  return (
    <>
      {downloadOptions?.length > 0 && (
        <DownloadReportsDropdown
          isLoading={isPending}
          options={[...downloadAll, ...latestScan, ...downloadOptions]}
          handleDownloadReports={handleDownloadReports}
        />
      )}
    </>
  )
}

interface DownloadReportsProps {
  asset: Asset
  scan: Nullable<Scan>
}

export default DownloadReports
