import Box from '@mui/material/Box'
import ActiveDeploymentTable from './active-deployment-table'
import AwsDrawer from '../aws-drawer'
import UnlinkProtectedAccount from './drawers/unlink-protected-account'
import UnlinkCloudConnector from './drawers/unlink-cloud-connector'
import LinkProtectedAccount from './drawers/link-protected-account'
import UpdateProtectedAccount from './drawers/update-protected-account'
import ScanCluster from './drawers/scan-cluster'
import UpgradeCloudConnector from './drawers/upgrade-cloud-connector'
import ModifyCloudConnector from './drawers/modify-cloud-connector'
import { SETTINGS_ACTIONS_ROUTE_SEGMENTS } from '../../../../lib/constants/route-segments.constant'
import { useParams } from 'react-router-dom'
import BindLinkedProtectedAccount from './drawers/bind-linked-protected-account'

const ActiveDeployments = () => {
  const { action } = useParams()
  const isDeployDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_SOURCE
  return (
    <Box>
      <ActiveDeploymentTable />
      {isDeployDrawerOpen && <AwsDrawer />}
      <UpdateProtectedAccount />
      <UnlinkProtectedAccount />
      <LinkProtectedAccount />
      <UnlinkCloudConnector />
      <ScanCluster />
      <UpgradeCloudConnector />
      <ModifyCloudConnector />
      <BindLinkedProtectedAccount />
    </Box>
  )
}

export default ActiveDeployments
