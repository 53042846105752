import { z } from 'zod'
import {
  ALERT_ON_EVENTS,
  FORM_FIELDS,
} from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/consts'
import { SUPPRESS_EVENTS } from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/consts/mode'

const FormSchemaType1 = z.object({
  [FORM_FIELDS.RULE_NAME]: z
    .string()
    .min(1, { message: 'Rule name is required' }),
  [FORM_FIELDS.DESCRIPTION]: z.string().optional(),
  [FORM_FIELDS.MODE]: z.enum([ALERT_ON_EVENTS]),
  [FORM_FIELDS.SUPPRESS_UNTIL]: z.any().optional(),
  [FORM_FIELDS.REASON_SUPPRESSION]: z.string().optional(),
})

const FormSchemaType2 = z.object({
  [FORM_FIELDS.RULE_NAME]: z
    .string()
    .min(1, { message: 'Rule name is required' }),
  [FORM_FIELDS.DESCRIPTION]: z.string().optional(),
  [FORM_FIELDS.MODE]: z.enum([SUPPRESS_EVENTS]),
  [FORM_FIELDS.SUPPRESS_UNTIL]: z
    .number()
    .min(1, { message: 'Suppress until is required' }),
  [FORM_FIELDS.REASON_SUPPRESSION]: z
    .string()
    .min(1, { message: 'Reason of suppression is required' }),
})

export const FormSchema = z.discriminatedUnion('mode', [
  FormSchemaType1,
  FormSchemaType2,
])

export type FormData = z.infer<typeof FormSchema>
