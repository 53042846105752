import { useMemo } from 'react'
import { useAwsThreatStatsQuery } from '../queries/aws-stats'
import { NormalizedStatCardData } from 'ui-v2/src/lib/models/aws-stats'
import { STAT_CARD_SLUG } from 'ui-v2/src/lib/constants/stats.constant'
import {
  BASE_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

const useAwsThreatStatsData = () => {
  const { data: awsThreatStatsData, isLoading } = useAwsThreatStatsQuery({})

  const normalizedData: Array<NormalizedStatCardData> = useMemo(() => {
    if (!awsThreatStatsData) {
      return []
    }

    return [
      {
        slug: STAT_CARD_SLUG.CORRUPTED_FILESYSTEMS,
        count: awsThreatStatsData.corruptedFilesystemsCount,
        link: `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.ASSET_THREATS}?t=${awsThreatStatsData.corruptedFilesystemsPageToGo}&filesystem=all`,
      },
    ]
  }, [awsThreatStatsData])

  return {
    data: normalizedData,
    isLoading,
  }
}

export default useAwsThreatStatsData
